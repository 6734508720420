.minibag {
  width: 50%;
  &.has-items {
    height: calc(var(--view-height) - #{$header-height-mobile});
    max-height: auto;
    transform: translateX(100%);
    overflow-y: auto;
    &.visible {
      transform: translateX(var(--scrollbar-width));
    }
  }
}
body.has-ticker {
  .minibag.has-items {
    height: calc(var(--view-height) - #{$header-height-mobile} - #{$ticker-bar});
    max-height: auto;
  }
}
