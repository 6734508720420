.store-page {
  .store-detail__title {
    padding-top: $space-unit * 16;
  }
  .spaced-row {
    > div:nth-child(2) {
      order: initial;
      padding-bottom: initial;
    }
    .store-locator__map {
      height: 100%;
    }
    .google-map {
      width: calc(100% - 2.8rem);
    }
  }
  .picture {
    padding-bottom: ($space-unit * 8);
  }
}
