.text-on-image {
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 66.81%;
  }
  &__image {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    img {
      position: absolute;
      left: ($space-unit * 8);
      top: ($space-unit * 8);
      right: ($space-unit * 8);
      bottom: ($space-unit * 8);
      width: calc(100% - (#{$space-unit} * 16));
      height: calc(100% - (#{$space-unit} * 16));
      object-fit: cover;
      z-index: 1;
    }
  }

  &__text {
    @extend %primary-xxs;
    position: absolute;
    left: ($space-unit * 16);
    bottom: ($space-unit * 16);
    width: 28rem;
    max-width: 100%;
    z-index: 2;
  }

  &--black-text {
    color: $black;
  }

  &--white-text {
    color: $white;
  }

  &--two-images {
    .text-on-image {
      &__image {
        width: 50%;
        right: initial;

        &:nth-child(2) {
          left: initial;
          right: 0;
        }

        img {
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }
      &__text {
        left: ($space-unit * 8);
        bottom: ($space-unit * 8);
      }
    }
  }
}
