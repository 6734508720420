.modal {
  &.shop-the-look-modal {
    padding: 0 1.6rem;
    // display: flex;
    // align-items: center;
    .carousel-button__shop-the-look {
      display: none;
    }
    .modal__close {
      //   top: 1rem;
      right: 0;
    }
    .container-shop-the-look {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 1.6rem;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      .product-tile {
        background-color: black;
      }
      .swiper-wrapper {
        max-height: 90vh;
      }
      .product-image {
        &::before {
          padding-top: 100%;
        }
        img {
          padding: 0 4rem;
          height: auto;
        }
      }
      .product-tile__info {
        .product-tile__name {
          height: 4rem;
        }
        .product-tile__price {
          margin-bottom: 1.6rem;
        }
      }
    }
  }
}
