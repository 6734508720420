.selector-quantity {
  display: flex;
  align-items: center;

  &__controls {
    display: flex;
    align-items: center;
  }

  .minus-button,
  .plus-button {
    @include touchtarget-area();
    &:focus {
      @include touchtarget-focus();
    }
  }

  .minus-button {
    @include fixedSprite('minus');
  }
  .plus-button {
    @include fixedSprite('plus');
  }

  &__value {
    &--outofstock {
      color: $black_secondary;
      @include strokeThrough;
    }
    margin-top: 0.2rem;
    margin-right: $space-unit;
    margin-left: $space-unit;
  }

  &__msg {
    @extend %primary-xxs;
    margin-left: ($space-unit * 3);
    color: $black_secondary;
  }
}
