.monclercurators-hero {
  .monclercurators-hero__logo {
    padding-top: 7.2rem;
    padding-bottom: 4rem;
    i {
      height: 4rem;
      width: 36rem;
    }
  }
  .d-none-desk {
    display: none;
  }
  .d-none-mobile {
    display: block;
  }
}
