.tabs {
  .react-tabs__tab-list {
    column-gap: $space-unit;
    display: grid;
    // grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: column;
  }
  &.tabs--fluid {
    .react-tabs__tab {
      &:not(:first-child) {
        padding-left: 0;
      }
    }
  }
  &.tabs--padding {
    .gradient-wrapper .react-tabs__tab-list {
      column-gap: 0.4rem;
    }
    .react-tabs__tab {
      text-align: center;
    }
  }
}
