.grid-test {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - #{$header-height-desktop});
  [class^='container'] {
    background-color: $grey_input;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin-top: ($space-unit * 3);
    margin-bottom: ($space-unit * 10);

    --fluid {
      // &--fluid
      background-color: black;
      color: white;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
    }
  }

  [class^='col'] {
    @extend %primary-xs;
    background-color: rgba(255, 0, 0, 0.5);
    padding-top: 1em;
    padding-bottom: 1em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;

    &:nth-child(odd) {
      background-color: rgba(255, 0, 0, 0.4);
    }
  }
}
