.editorial-multi-text__text-column {
  &.mbm-promo {
    .editorial-multi-text__btn-container {
      .button {
        min-width: 226px;
        max-width: 226px;
      }
    }
  }
  .editorial-multi-text__quote {
    padding-bottom: 8rem;
  }
}
