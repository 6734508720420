.component-box {
  text-align: center;
  margin-bottom: ($space-unit * 16);
  img {
    width: 100%;
  }
  &__title {
    @extend %primary-l-u;
    margin-top: ($space-unit * 10);
    margin-bottom: ($space-unit * 5);
  }
  &__text {
    margin-bottom: ($space-unit * 5);
    width: 66.66%;
    margin-left: auto;
    margin-right: auto;
  }
  &__secondarycta {
    width: 66.66%;
    margin: auto;
    .button {
      margin-left: $space-unit * 4;
      margin-right: $space-unit * 4;
      margin-bottom: ($space-unit * 5);
    }
  }
  &__maincta {
    width: 66.66%;
    margin: auto;
  }
}
