.product-reserve {
  position: relative;
  max-height: inherit;
  .product-reserve__detail {
    &.zoom-product {
      .dialog_subtitle {
        display: none;
      }
    }
  }
  &__main {
    padding: 2rem ($space-unit * 4);
    background-color: black;
    display: flex;
    flex-direction: column;
    max-height: inherit;
    .product-reserve-review {
      display: none;
      color: white;
      text-align: center;
    }
    &.zoom-product {
      &.is-login {
        display: none;
      }
      .product-reserve-review {
        display: block;
      }
      .product-reserve__main__name,
      .product-reserve__main__wrapper {
        display: none;
      }
    }

    .product-image {
      img {
        width: auto;
        margin: 0 auto;
        height: 100%;
        object-fit: contain;
      }
    }

    > img {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }

    .selector__button,
    .selector__menu__item {
      color: white;
    }

    .dialog__title {
      color: $white;
      padding-bottom: 0;
    }

    &__name {
      @extend %primary-s;
      color: $white;
    }

    &__wrapper {
      &.product-selection {
        padding-top: 0;
        background: initial;
      }

      .selector-size-expanded .selector__header {
        padding-top: 1.6rem;
      }
    }
  }

  &__detail {
    position: relative;
    min-height: 16.4rem;
    padding: ($space-unit * 2) ($space-unit * 4) ($space-unit * 6);

    .store-info {
      margin-right: 0;
    }

    .accordion__group {
      .accordion__header {
        border-top: initial;
        @include border('bottom');
        &[aria-expanded='true'] {
          border-bottom: initial;
        }
      }
    }

    &__line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 1.6rem;
      padding-bottom: 0.6rem;
      span {
        @extend %primary-xs;
        color: $black_secondary;
      }
      .use-location {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        height: 1.6rem;

        &::before {
          content: '';
          @extend .icon--pin-full;
          display: block;
          margin-top: -0.2rem;
        }
      }
    }
    .react-tabs__tab-list {
      padding: 0;
    }

    // .dialog__title {
    //   padding-top: 0;
    //   padding-bottom: 2.4rem;
    // }

    &__text {
      margin-top: $space-unit * 4;
      margin-bottom: 3.2rem;
      p {
        padding-bottom: $space-unit * 4;
      }
      > button {
        margin-top: $space-unit * 2;
      }
    }

    &__selected-store {
      position: relative;
      padding-top: $space-unit * 4;
      padding-bottom: $space-unit * 8;
      // padding-left: $space-unit * 4;

      &.with-border {
        @include border('bottom');
      }

      &.has-back {
        padding-left: 2.8rem;
      }

      .back-button {
        position: absolute;
        left: 0;
        top: 1.6rem;
        @include fixedSprite('chevron-left-big');
      }

      .name {
        @extend %primary-s;
        margin-bottom: $space-unit * 2;
      }

      .content {
        @extend %primary-xs;
      }
    }

    &__form {
      &__legend {
        font-size: 1.6rem;
        line-height: 2rem;
      }

      &__submit {
        margin-top: ($space-unit * 3);
        align-items: center;
      }

      &__note {
        margin: ($space-unit * 6) 0 0;
      }
    }

    sup {
      vertical-align: super;
      font-size: 0.9rem;
      color: $black_secondary;
    }

    &__resend-prompt {
      margin-bottom: $space-unit * 2;
    }

    &__reserve-review {
      margin-top: 1rem;
    }

    &__review {
      margin-bottom: $space-unit * 8;
      overflow-wrap: break-word;
    }

    &__review-grid {
      display: grid;
      column-gap: 1.2rem;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-areas:
        'product product'
        'divider divider'
        'store store'
        'customer customer';
      // gap: $space-unit * 4;
      padding: 1.2rem 0;
    }

    &__review-header {
      @extend %primary-xxs-u;
      // margin-bottom: $space-unit * 2;
      margin: 1.6rem 0;
      &.boutique-message {
        margin-top: 2.6rem;
      }
    }

    &__review-content {
      @extend %primary-xs;
      margin-bottom: $space-unit;
      display: block;
      white-space: pre-line;
    }

    &__review-product {
      grid-area: product;
      p {
        margin-top: $space-unit;
      }
      &__name {
        display: flex;
        justify-content: space-between;
        margin-bottom: $space-unit * 2;
        p {
          font-size: 1.6rem;
        }
      }
    }

    &__review-store {
      grid-area: store;

      .details {
        .tel {
          margin-top: ($space-unit * 3);
        }
      }
    }

    &__review-customer {
      grid-area: customer;
    }

    &__review-divider {
      grid-area: divider;
      @include border('top');
      height: $space-line;
      margin-top: 1.6rem;
      &.booked-appointment {
        margin: 1.6rem 0;
      }
    }

    &__review-divider-top {
      grid-area: divider-top;
      @include border('top');
      height: $space-line;
    }

    &__review-divider-bottom {
      grid-area: divider-bottom;
      @include border('top');
      height: $space-line;
    }

    .dialog_subtitle {
      padding-top: 6px;
      padding-bottom: 0;
      font-weight: 300;
    }
    .divider {
      margin: 2.4rem 0;
    }
  }

  .scrollable {
    overflow-y: auto;
    padding-right: 1rem;
  }

  .accordion__panel.accordion__panel_open {
    margin-bottom: $space-unit * 4;
  }
  .login-registration__registration-successful {
    h2 {
      margin-top: 0;
    }
  }
}

.reserve-form-modal {
  .modal__header {
    margin-left: -1.6rem;
    padding-left: 1.6rem;
    margin-right: -1.6rem;
    padding-right: 1.6rem;
    border-bottom: $space-line solid $grey_line;
  }
}
.wrapper-color {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.8rem;
}
.product-reserve__detail__review-product {
  .wrapper-color {
    justify-content: flex-start;
  }
}
#reserveinboutique {
  overflow-x: hidden;
  .modal__close {
    right: var(--scrollbar-width);
  }
}
