.product-gallery {
  width: 50%;

  &-vertical {
    &--list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.8rem;
    }
    &__image {
      aspect-ratio: 2/3;
      &:nth-child(3n + 1) {
        grid-column: span 2;
      }
      .product-image {
        height: auto;
        background-color: transparent;
      }
    }
    .product-image-button {
      height: 100%;
      .product-image {
        height: 100%;
      }
    }
  }
}
.product-gallery-vertical--list {
  &.last-image-full {
    .product-gallery-vertical__image {
      &:last-child {
        // display: none;
        grid-column: span 2;
      }
    }
  }
}
