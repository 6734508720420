.promo-50-50 {
  grid-column: span 2;
  .row {
    margin: 0;
  }
  &__picture {
    display: block;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__img-col {
    padding: 0;
    &--top {
      order: 0;
    }
    &--bottom {
      order: 1;
    }
  }
  &__video-col {
    padding: 0;
    position: relative;
    padding-top: 100%;
  }
  &__text-col {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__text-wrapper {
    text-align: center;
    width: 48.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &.left-align {
      text-align: left;
      align-items: flex-start;
    }
  }
  &__logo {
    margin: 0.8rem auto 2rem;
  }
  &__title {
    margin: 0.8rem 0;
  }
  &__description {
    margin: 0.8rem 0;
    &.primary-l {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  }
  &__cta-wrapper {
    // display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 2.4rem 0 0.8rem;
    width: 100%;
    .left-align & {
      width: 100%;
      margin-right: -0.4rem;
      margin-left: -0.4rem;
    }
    .button {
      &--secondary,
      &--primary {
        // display: inline-block;
        // display: flex;
        // justify-content: center;
        min-height: 4.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        text-decoration: none;
        padding: 0 1.6rem;
        margin: 0.2rem 0;
        .left-align & {
          flex-grow: 1;
          padding: 0 0.4rem;
          margin: 0.2rem 0.4rem;
        }
      }
      &--tertiary {
        text-decoration: none;
        text-transform: uppercase;
        position: relative;
      }
    }
  }
  &__download-cta-wrapper {
    margin: 1.6rem 0 0.8rem;
    display: flex;
    column-gap: 1.6rem;
    .download-app-button {
      height: 4.4rem;
      width: auto;
      object-fit: contain;
    }
  }
  &__padded {
    &.promo-50-50__container {
      height: 66.8rem;
      padding: 3.2rem 0;
    }
    .promo-50-50 {
      &__picture {
        height: 28rem;
      }
      &__video-col {
        padding-top: 0;
        height: 28rem;
      }
      &__text-col {
        &--padding-top {
          padding-top: 3.2rem;
        }
        &--padding-bottom {
          padding-bottom: 3.2rem;
        }
      }
      &__text-wrapper {
        padding: 0 1.6rem;
        max-width: 57.7rem;
        min-height: 29.2rem;
      }
    }
  }
  &__not-padded {
    .promo-50-50 {
      &__picture {
        position: relative;
        height: 0;
        padding-top: 100%;
        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
      &__text-wrapper {
        height: 100vw;
        padding: 1.6rem;
      }
    }
  }
  &__eyebrows {
    display: block;
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-bottom: 2rem;
  }

  .video-container {
    .btn-video-pause {
      position: absolute;
      bottom: 1.6rem;
      right: 1.6rem;
      z-index: 2;
      &:focus-visible {
        background-color: $black;
        border: 1px solid $white;
      }
    }

    .video-wrapper {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }
  }

  &__modal {
    &--small {
      &.modal {
        width: 37.5rem;
        min-width: 37.5rem;
        padding: 1.8rem 1.6rem 2.4rem;
      }
      .modal {
        &__header {
          padding-top: 0;
        }
      }
      .react-tabs {
        &__tab-list {
          column-gap: initial;
          display: flex;
          text-align: center;
          padding-left: 0;
          .react-tabs__tab {
            display: block;
            flex: 1 0 50%;
            padding: 1.3rem 0 1.6rem;
            @extend %primary-s;
            letter-spacing: 0.05em;
            border-bottom: solid 0.1rem;
            border-bottom-color: $grey_line;
            cursor: pointer;
            &--selected {
              font-weight: bold;
              border-bottom-color: $black;
            }
          }
        }
      }
    }
  }
  &__download-app-qrcode {
    width: 10rem;
    height: auto;
    margin: 0 auto;
    display: block;
  }
  &__bg {
    &--grey-gradient {
      background: $gradient-separator;
    }
    &--black {
      background: $black;
      color: $white;
      .button--secondary,
      .button--secondary:link:hover {
        color: $white;
        border: 1px solid $white;
      }
      .button--secondary:visited:hover {
        color: $white;
        border: 1px solid $white;
      }
      .button--tertiary,
      .button--tertiary:link:hover {
        color: $white;
        opacity: 1;
        &::after {
          @include fixedSprite('arrow-right-white');
        }
      }
    }
  }
  // Traceability - AGEC Law (FR) => OE-15869

  .traceability-page-content & {
    &__description {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
}
