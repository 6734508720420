.component_materials_and_product_design {
  &__content {
    padding: 22rem 0 0 12rem;
  }
  .technical-video-wrapper {
    .btn--volume {
      left: 5rem;
      bottom: 5rem;
    }
  }
}
