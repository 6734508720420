.order {
  // text-align: left;
  margin-bottom: ($space-unit * 16);

  &__title {
    @extend %primary-s-u;
    text-align: center;
    padding: ($space-unit * 10) 0 ($space-unit * 2);
  }

  .total-row {
    .text-section {
      padding: 1.6rem 0;
    }
  }

  .editorial-row {
    .experience-main {
      margin-top: 6.4rem;
      .experience-component_single_banner {
        &:last-child {
          .single-banner {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
