.monclercurators-hero {
  .monclercurators__container_down {
    .monclercurators__text {
      row-gap: 1.2rem;
      @media (orientation: landscape) {
        bottom: 6.4rem;
      }
      .monclercurators__title {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }
      .monclercurators__message {
        font-style: normal;
        font-weight: 300;
        font-size: 60px;
        line-height: 56px;
        padding: 0 2rem;
      }
    }
  }
}
