.single-designer__caption-wrapper {
  &:hover {
    .single-designer__caption {
      opacity: 1;
    }
    .single-designer__caption-overlay {
      opacity: 0;
    }
  }
}
