// set default color of button text (override browsers one)
button,
.button {
  color: $black;
}

.button {
  position: relative;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  @include animate(0.2);
  cursor: pointer;

  &:disabled {
    cursor: none;
    pointer-events: none;
    opacity: 0.25;
  }

  // PRIMARY & SECONDARY

  &[class*='primary'],
  &[class*='secondary'] {
    min-height: $button-height-mobile;
    padding: 0 ($space-unit * 4);
    text-align: center;
    width: 100%;

    span {
      @extend %primary-xs-u;
    }

    &:focus {
      @include focus-underline;
    }
  }

  // PRIMARY

  &--primary,
  &--primary:link,
  &--primary:visited {
    background-color: $black;
    color: $white;

    text-transform: uppercase; // force in case of lowercase properties

    &:focus {
      span {
        text-decoration-color: $white;
      }
    }

    &:hover {
      color: rgba($white, 0.6);
      text-decoration: none;

      &:focus {
        span {
          text-decoration-color: rgba($white, 0.6);
        }
      }
    }
  }

  &--primary-inverted,
  &--primary-inverted:link,
  &--primary-inverted:visited {
    background-color: $white;
    color: $black;
    text-transform: uppercase; // force in case of lowercase properties

    &:focus {
      span {
        text-decoration-color: $black;
      }
    }

    &:hover {
      color: rgba($black, 0.6);
      text-decoration: none;

      &:focus {
        span {
          text-decoration-color: rgba($black, 0.6);
        }
      }
    }
  }

  // SECONDARY

  &--secondary,
  &--secondary:link,
  &--secondary:visited {
    color: $black;
    border: $space-line solid $black;
    text-transform: uppercase; // force in case of lowercase properties

    &:active {
      text-decoration: none;
      color: rgba($black, 0.6);
      border-color: rgba($black, 0.6);

      &:focus {
        span {
          text-decoration-color: rgba($black, 0.6);
        }
      }
    }
    &:focus {
      span {
        text-decoration-color: $black;
      }
    }
  }

  &--secondary-inverted,
  &--secondary-inverted:link,
  &--secondary-inverted:visited {
    color: $white;
    border: $space-line solid $white;

    text-transform: uppercase; // force in case of lowercase properties

    &:hover {
      // box-shadow: 0 0 0 .1rem $white;
      text-decoration: none;
      // opacity: 0.6;
      background-color: rgba($white, 0.25);
    }
  }

  // TERTIARY

  &[class*='tertiary'] {
    @extend %primary-xs-u;
    @include hover(0.2);
    &[data-animated] {
      opacity: 0;
      &[data-animated='true'] {
        opacity: 1;
        @include animate(0.4, 'opacity', $easeInOutQuart);
      }
    }
  }

  &--tertiary,
  &--tertiary:link,
  &--tertiary:visited {
    color: $black;

    [class^='icon'] {
      color: $black;
    }

    &:hover {
      &:focus {
        text-decoration-color: rgba($black, $hover-opacity);
      }
    }

    &:focus {
      @include focus-underline;
    }
  }

  &--tertiary-inverted,
  &--tertiary-inverted:link,
  &--tertiary-inverted:visited {
    color: $white;
    [class^='icon'] {
      color: $white;
    }

    &:hover {
      &:focus {
        text-decoration-color: rgba($white, $hover-opacity);
      }
    }

    &:focus {
      @include focus-underline;
    }
  }

  &--plus-right {
    padding-right: ($space-unit * 6);

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 52%; // vertical adjustment
      transform: translateY(-50%);
      width: ($space-unit * 2);
      height: ($space-unit * 2);
      @include fixedSprite('plus');
      @include fix-font-space(0.1rem);
    }

    @include hover(0.2);
  }

  &--minus-right {
    padding-right: ($space-unit * 6);

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 52%; // vertical adjustment
      transform: translateY(-50%);
      width: ($space-unit * 2);
      height: ($space-unit * 2);
      @include fixedSprite('minus');
      @include fix-font-space(0.1rem);
    }

    @include hover(0.2);
  }

  &--fill {
    width: 100%;
    justify-content: center;
  }

  // WITH ARROWS

  &--arrow-right {
    padding-right: 2.4rem;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 52%; // vertical adjustment
      transform: translateY(-50%);
      width: ($space-unit * 2);
      height: ($space-unit * 2);
      @include fixedSprite('arrow-right-black');
      @include fix-font-space(0.1rem);
    }

    &[class*='inverted'] {
      &::after {
        @include fixedSprite('arrow-right-white');
      }
    }

    @include hover(0.2);
  }

  &--arrow-left {
    padding-left: ($space-unit * 4);

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 52%; // vertical adjustment
      transform: translateY(-50%) rotate(180deg);
      width: ($space-unit * 2);
      height: ($space-unit * 2);
      @include fixedSprite('arrow-right-black');
      @include fix-font-space(0.2rem);
    }

    &[class*='inverted'] {
      &::after {
        @include fixedSprite('arrow-right-white');
      }
    }

    @include hover(0.2);
  }

  &--play-left {
    padding-left: ($space-unit * 7);

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 52%; // vertical adjustment
      transform: translateY(-50%);
      width: ($space-unit * 2);
      height: ($space-unit * 2);
      @include fixedSprite('play');
      @include fix-font-space(0.2rem);
    }

    &[class*='inverted'] {
      &::after {
        @include fixedSprite('play-white');
      }
    }

    @include hover(0.2);
  }

  &--arrow-down {
    padding-bottom: ($space-unit * 4);
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 100%) rotate(90deg);
      width: ($space-unit * 2);
      height: ($space-unit * 2);
      @include fixedSprite('arrow-right-black');
    }

    &[class*='inverted'] {
      &::after {
        @include fixedSprite('arrow-right-white');
      }
    }
    @include hover(0.2);
  }

  &--arrow-up {
    padding-top: ($space-unit * 4);
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%) rotate(-90deg);
      width: ($space-unit * 2);
      height: ($space-unit * 2);
      @include fixedSprite('arrow-right-black');
    }

    &[class*='inverted'] {
      &::after {
        @include fixedSprite('arrow-right-white');
      }
    }
    @include hover(0.2);
  }

  // LINK

  &--link {
    @extend %primary-xs;

    &:focus {
      @include focus-underline;
    }
  }

  &--fileupload {
    cursor: pointer;
    label {
      &:focus-within {
        outline: 0.3rem solid #80bdff;
      }
      cursor: pointer;
    }
    input {
      position: absolute;
      opacity: 0;
    }
    .tooltip {
      position: absolute;
      right: 1.2rem;
      display: block;
      z-index: 1;
    }
  }

  &--link-button {
    text-decoration: underline;
  }

  &.image-button {
    margin-bottom: $space-unit;
    border: $space-line solid $black_inactive;
    transition: none;
    display: flex;
    height: ($space-unit * 12);
    width: 100%;
    justify-content: flex-start;
    > label {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0 $space-unit * 7;
      height: 100%;
    }
    &.image-button-add {
      > label {
        &::before {
          content: '';
          @include fixedSprite('plus');
          position: absolute;
          left: $space-unit;
        }
      }
    }
    &.image-button-remove {
      justify-content: space-between;
      padding: ($space-unit * 3);
    }
    &.image-button-progress {
      padding: ($space-unit * 3);
    }
  }

  &--tab {
    position: relative;
    padding: 2rem 0;
    margin: 0 1.6rem;
    text-transform: uppercase;
    &:focus {
      @include focus-underline;
    }
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: -1.6rem;
      width: calc(100% + 3.2rem);
      height: 1px;
      background: #000;
      display: none;
      z-index: 2;
    }
    &.active {
      &::before {
        display: block;
      }
    }
  }
  &.loading {
    overflow: hidden;
    div {
      position: absolute;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      border: 0.3rem solid $black;
      animation: 1.5s ripple infinite;
    }
  }

  &__loader {
    &::after {
      content: '';
      background-image: url(~@moncler/media/loading-black.gif);
      width: 2.4rem;
      height: 2.4rem;
      display: block;
      background-repeat: no-repeat;
      background-size: cover;
    }
    > * {
      display: none;
    }
    &.button--secondary-inverted::after,
    &.button--primary::after {
      filter: invert(1);
    }
  }

  &.findmysize {
    text-transform: uppercase;
    i {
      margin-left: 0.4rem;
    }
  }
}

.button--paypal {
  @include sprite('payment-paypalbutton');
  background-size: 5.6rem 1.8rem;
  background-position: center 54%;
}

.button--googlepay {
  @include sprite('payment-googlepayexpress');
  background-size: 5.6rem 1.8rem;
  background-position: center;
}

.button--amazonpay {
  @include sprite('payment-amazonpay');
  background-size: 8.6rem 2.8rem;
  background-position: center 56%;
  .adyen-checkout__amazonpay,
  .adyen-checkout__amazonpay__button {
    width: 100% !important;
    height: 36px !important;
    overflow: hidden;
    opacity: 0.2;
  }
}

// ROWS
.button-row {
  &--vertical {
    flex-direction: column-reverse;
    gap: 2.4rem;
  }
}

.dw-apple-pay-button.apple-pay-button {
  height: $button-height-mobile;
  // non penso sia legalmente corretto
  margin: 0;
  // border-radius: 0;
  &:focus,
  &:hover {
    margin: 0;
    // border-radius: 0;
  }
}

[data-whatintent='keyboard'] {
  .button.button--paypal,
  .button--googlepay,
  .button--amazonpay,
  .button--applepay {
    &:focus {
      outline: 1px inset $black !important;
      outline-offset: -4px;
    }
  }
}

.use-location {
  position: relative;
  padding: $space-unit;
  margin: 0 $space-unit;

  &::before {
    content: '';
    @extend .icon--pin-full;
    display: block;
    margin-top: -0.2rem;
  }
}

@keyframes ripple {
  from {
    transform: scale(0);
    opacity: 1;
  }

  to {
    transform: scale(1);
    opacity: 0;
  }
}
