.flagship-page {
  &__matterport {
    .content {
      .flagship-store_detail {
        min-height: 76px;
        .container {
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: 1fr;

          .grid-1-1 {
            grid-column: 1 / 1;
            grid-row: 1 / 1;
            display: flex;
            align-items: center;
          }

          .detail__title-wrapper {
            .detail__title {
              font-size: 24px;
            }
          }

          .detail__cta-wrapper {
            justify-content: end;
            margin-top: unset;
          }
        }
      }
    }
  }

  /* ------------------------------ TEXT SQUARES ------------------------------ */
  &__text-squares {
    .text-squares__tile {
      max-height: 360px;

      h3 {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
}
