.component_grenoble_performance {
  &__title-wrapper {
    .title {
      padding-bottom: 8rem;
    }
  }
  &__info-wrapper {
    .info-row {
      .logo {
        padding-right: 4rem;
      }
      &:not(:last-child) {
        padding-bottom: 4.8rem;
      }
    }
  }
}
