.support-section-footer-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.25rem;

  .contacts-card {
    flex-basis: 50%;
    max-width: calc(50% - 0.25rem);
    flex: 1 0 auto;
    padding: 40px 16px;
    border: 1px solid $grey_line;
    display: flex;
    flex-direction: column;
    gap: 40px;
    text-align: center;
    min-height: 18rem;
    background-color: $grey_disabled;

    .top-card {
      .h4 {
        text-transform: uppercase;
        font-size: 24px;
        line-height: 24px;
        padding-bottom: 12px;
      }
    }

    .links {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      gap: 16px 40px;

      .contact-details__contacts {
        flex-wrap: wrap;
      }

      a {
        align-items: center;
        display: flex;
        text-decoration: none;

        &.link-img {
          gap: 4px;
          font-size: 16px;
        }
        &.link-no-img {
          text-transform: uppercase;
        }

        img {
          width: 16px;
        }
      }
    }
  }
}

.experience-account-support-layout {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.25rem;

  .experience-left,
  .experience-right {
    flex-basis: 50%;
    max-width: calc(50% - 0.25rem);
    flex: 1 0 auto;

    [pageid='my_account_support'] {
      height: 100%;

      .support-section-footer-row {
        height: 100%;
        .contacts-card {
          max-width: 100%;

          .links {
            max-width: auto;
          }
        }
      }
    }
  }
}
