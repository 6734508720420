.expresspayment-modal {
  &.cart {
    .modal__header {
      padding-top: 2rem;
    }
    .button--arrow-back {
      display: none;
    }
    .modal__close {
      top: 3rem;
      left: 1.6rem;
      &:before {
        top: 0;
        display: block;
        position: absolute;
        left: 0;
        @include fixedSprite('arrow-left-black');
      }
    }
  }
  .modal__close {
    position: absolute;
    margin-bottom: 0;
    padding-right: 1.6rem;
    padding-top: 1.8rem;
    right: 0;
    left: auto;
    width: fit-content;
    &::before {
      content: '';
      display: block;
    }
  }
  .button--arrow-back {
    z-index: 3;
    position: absolute;
    top: 0;
    padding: 1.8rem 1.6rem 1.8rem 0;
    margin-bottom: 0;
  }
  .modal__header {
    padding-top: 0;
    align-items: center;
    h2 {
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.04em;
    }
  }
  // .expresspayment-modal__intro {
  //   display: none;
  // }
}

.product-box__list {
  max-height: 230px;
  .product-box__product-name,
  .option-product__color {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.express-payments-hk-tax-code {
  .invoice-block {
    .tooltip {
      .tooltip__box {
        left: -0.6rem;
        &::before {
          right: initial;
        }
      }
    }
  }
}
