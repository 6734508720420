.product-details {
  &.landing {
    opacity: 1;
  }
}

.drawers {
  &.measmerize-drawer {
    $margin_left: 16px;
    $content_inline_space: 16px;

    padding-inline: 0;

    .wrapper-tab-list {
      width: auto;
      padding: 29px $content_inline_space 0;
    }

    .react-tabs__tab-list {
      &.container {
        margin-inline: $margin_left;
        overflow-x: auto;
        width: auto;
        @include hide-scrollbar;
      }
    }

    .wrapper-tab-panels {
      height: calc(100% - 74px);
    }

    .product-details-tab .wrapper-product-details {
      .react-tabs__tab-panel--selected {
        transition: none;
        max-height: none;
        padding-right: 0;
      }
    }

    .sizechart, .sizechart--measure {
      margin-inline: $content_inline_space;
    }
  }
  &.details-modal {
    height: 100svh;
  }
}
