.product-image--animate {
  opacity: 0;
  transition: opacity 1s ease;
  img {
    transform: scale(1.1);
    transition: transform 1s ease;
    transition-delay: 0.3s;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: $white;
    transition: transform 1s ease;
    will-change: transform;
  }
  &.animated {
    opacity: 1;
    img {
      transform: scale(1);
    }
    &.from-left {
      &::after {
        transform: translateX(100%);
      }
    }
    &.from-right {
      &::after {
        transform: translateX(-100%);
      }
    }
  }
}
