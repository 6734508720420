.selector-color-link {
  .selector__menu {
    &:not(:hover):not(:focus-within) {
      .selector__menu__item.selected {
        .selector__menu__item__label {
          display: inline-block;
        }
      }
    }
  }
  .selector__menu__item {
    .selector__menu__item__label {
      display: none;
    }
    &:hover {
      .selector__menu__item__label {
        display: inline-block;
      }
    }
  }
}

.product-tile {
  .selector__menu {
    .selector__menu__item__label {
      display: none;
    }
    .selector__menu__item.selected {
      .selector__menu__item__label {
        display: inline-block;
      }
    }
  }

  .selector__menu:hover {
    .selector__menu__item {
      .selector__menu__item__label {
        display: none;
      }
      // verificare se aggiungere condizione con selected
    }
    .selector__menu__item:hover,
    .selector__menu__item.selected:hover {
      .selector__menu__item__label {
        display: inline-block;
      }
    }
  }

  // .selector__menu:not(:hover):focus-within {
  //   .selector__menu__item,
  //   .selector__menu__item.selected {
  //     .selector__menu__item__label {
  //       display: none;
  //     }
  //   }
  //   .selector__menu__item:focus,
  //   .selector__menu__item.selected:focus {
  //     .selector__menu__item__label {
  //       display: inline-block;
  //     }
  //   }
  // }
}
