.product-details-tab {
  .mbm-list {
    .palette-accordion {
      flex-wrap: nowrap;
      .palette-accordion-body {
        flex: 1;
      }
    }
  }
  .composition-care-icons {
    padding: 0 3.6rem 1.2rem 0;
  }
}
.product-selection-wrapper {
  .link-anchor {
    margin-top: 2.6rem;
  }
}
// .product-selection {
//   .row {
//     .wrapper-body-detail {
//       width: calc((100vw - 64px) / 12 * 4);
//     }
//   }
// }
