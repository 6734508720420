.curator-long-copy {
  color: white;
  .curator-long-copy-container {
    margin: 0 auto;
    padding: 16rem 0;
    .long-copy-title {
      padding-bottom: 4rem;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
    }
    .long-copy-text {
      p {
        font-family: $silkSerifLight;
        font-style: normal;
        font-weight: 300;
        font-size: 42px;
        line-height: 50px;
      }
    }
    .long-copy-paragraph {
      padding-top: 4rem;
      column-count: 2;
      p {
        text-transform: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        margin: 0 auto;
      }
    }
  }
}
