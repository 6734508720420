.curators-listing-wrapper {
  &:not(.show-module) {
    display: none;
  }
  .products-list {
    .product-tile--carousel {
      .product-tile__info {
        padding-left: 1.6rem;
        padding-right: 0.8rem;
      }
    }
  }
  .product-image {
    height: fit-content;
  }
  .section-look-container {
    color: white;
    padding-bottom: 28rem;
    .product-tag {
      color: $white_secondary;
      white-space: pre-wrap;
    }
    .product-tile {
      display: flex;
      flex-direction: column;
      .product-tile__image-wrapper {
        .product-label {
          color: $black;
        }
      }
      .selector-color-link {
        .selector__menu.inverted .selector__menu__item {
          &.selected {
            .swatch {
              outline: none;
              &:after {
                content: '';
                width: 100%;
                height: 1px;
                background-color: $white;
                position: absolute;
                bottom: -0.5rem;
                left: 0;
              }
            }
          }
          .swatch {
            border-color: $grey_interactive;
          }
        }
      }
    }
    .product-tile__quick-buy {
      button {
        color: white;
      }
    }
    .row {
      justify-content: space-between;
      margin: 0;
    }
    .icon--lock {
      filter: invert(1);
    }
  }
  .container-without-image {
    padding: 1rem 0;
  }
  .img-container {
    padding: 0;
    img {
      width: 100%;
    }
  }
  .hero-img {
    &::before {
      content: '';
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
    }

    .container-text {
      position: absolute;
      top: 75%;
      left: 50%;
      width: 65%;
      transform: translateX(-35%);
      display: flex;
      flex-direction: column;
      row-gap: 1.6rem;
      .name {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }
      .title {
        font-family: $silkSerifLight;
        font-weight: 300;
        font-size: 92px;
        line-height: 84px;
      }
    }
  }
  .inverted {
    flex-direction: row-reverse;
    .hero-img-overlay {
      .img-container {
        margin-left: auto;
        .container-text {
          width: 85%;
          transform: translateX(-50%);
        }
      }
    }
  }
  .hero-img-overlay {
    position: sticky;
    top: 5.6rem;
    transition: all 1s ease;
    transition-delay: 0.3s;
    height: fit-content;
    padding: 0;
  }
  .products-list {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    padding: 0;
    .product-look {
      padding: 0;
      list-style: none;
    }
  }
  .lock {
    .text-container {
      .col-8 {
        margin: auto;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
    .hero-img {
      opacity: 0.8;
    }
    .text-container {
      .name {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }
      .title {
        font-family: $silkSerifLight;
        font-weight: 300;
        font-size: 92px;
        line-height: 84px;
      }
    }
  }
  .section-look-container {
    .products-list {
      .product-look {
        .product-tile__info {
          padding-right: 1.6rem;
          padding-left: 1.6rem;
        }
      }
    }
  }
}
.btn-modal-login {
  .info-box-curator {
    padding-top: 5rem;
  }
  margin-top: 5rem;
  button {
    background-color: white;
    text-transform: uppercase;
    padding: 1rem 2rem;
    text-decoration: none;
    &:focus {
      outline: 1px solid white;
      outline-offset: 2px;
    }
  }
}
