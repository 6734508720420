.complete-the-look-slides {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: $space-unit;
}
.component_complete_the_look {
  position: relative;
  padding-top: 8.4rem;
  padding-bottom: 6.8rem;
  min-height: initial !important;
  height: auto !important;
  h2 {
    padding-bottom: 3.6rem;
  }
}
