.component_product_intro {
  // $viewportHeight: calc(var(--viewport-height) - #{$contextual-navigation-m});
  // height: $viewportHeight;
  position: relative;
  overflow: hidden;
  text-align: center;
  > .container {
    min-height: inherit;
    > .row {
      min-height: inherit;
    }
  }
  &__parallax {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% + #{$contextual-navigation-m});
    width: 100%;
    object-fit: cover;
    will-change: transform;
    // transform-origin: center top;
    transform: translateY(-#{$contextual-navigation-m});
  }
  &__gif {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    will-change: transform;
    // transform-origin: center top;
    transform: translateY(-#{$contextual-navigation-m});
    mix-blend-mode: screen;
  }
  &__content {
    padding-top: 4rem;
    padding-bottom: 1.6rem;
    color: $white;
    opacity: 0;
    transform: translateY(3.2rem);
    .collection {
      padding-bottom: 2.4rem;
      display: inline-block;
      text-transform: uppercase;
      p {
        @extend %primary-xs;
      }
      img {
        display: inline;
        margin-right: 0.4rem;
      }
    }
    h1 {
      @extend %primary-xxl;
      text-transform: uppercase;
      margin-bottom: 2.4rem;
    }
    p {
      @extend %primary-s;
    }
  }

  // OE-3748 edge case iphone 8
  @media screen and (max-height: 667px) {
    .component_product_intro__content {
      padding-top: 2rem;
      // .collection{

      // }
      h1 {
        margin-bottom: 1.6rem;
      }
    }
  }
}
