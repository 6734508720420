.product-reserve {
  &__detail {
    .accordion__group {
      padding-top: 0;
    }
  }
}
.product-reserve__detail__form__submit {
  &.row {
    flex-direction: column-reverse;
    .button--arrow-left {
      margin-top: 2.4rem;
    }
    .wrapper-button-arrow {
      justify-content: center;
      display: flex;
    }
  }
}
