.header--pdp {
  &.white-bg:not(.scrolled) {
    .navigation {
      .menu-actions {
        [class^='icon'] {
          filter: none;
        }
      }
    }
  }
}
