@font-face {
  font-family: 'BlockGothicRR';
  font-style: normal;
  font-weight: bold;
  font-display: block;
  // prettier-ignore
  src: url('fonts/Block_Gothic/BlockGothicRR-BoldExtraCond.woff2') format('woff2'), 
       url('fonts/Block_Gothic/BlockGothicRR-BoldExtraCond.woff') format('woff');
}
@font-face {
  font-family: 'BlockGothicRR';
  font-style: normal;
  font-weight: normal;
  font-display: block;
  // prettier-ignore
  src: url('fonts/Block_Gothic/BlockGothicRR-MediumExtraCond.woff2') format('woff2'), 
       url('fonts/Block_Gothic/BlockGothicRR-MediumExtraCond.woff') format('woff');
}
@font-face {
  font-family: 'MonclerGothic';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  // prettier-ignore
  src: url('fonts/Moncler_Gothic/MonclerGothicWeb-ExtraLight.woff2') format('woff2'),
       url('fonts/Moncler_Gothic/MonclerGothicWeb-ExtraLight.woff') format('woff');
}

@font-face {
  font-family: 'MonclerGothic';
  font-style: normal;
  font-weight: normal;
  font-display: block;
  // prettier-ignore
  src: url('fonts/Moncler_Gothic/MonclerGothicWeb-Light.woff2') format('woff2'),
       url('fonts/Moncler_Gothic/MonclerGothicWeb-Light.woff') format('woff');
}

@font-face {
  font-family: 'MonclerGothic';
  font-style: normal;
  font-weight: bold;
  font-display: block;
  // prettier-ignore
  src: url('fonts/Moncler_Gothic/MonclerGothicWeb-Medium.woff2') format('woff2'),
       url('fonts/Moncler_Gothic/MonclerGothicWeb-Medium.woff') format('woff');
}

// UNUSED FONTS
// @font-face {
//   font-family: 'MonclerGothic-Regular';
//   font-style: normal;
//   font-weight: normal;
//   font-display: block;
//   // prettier-ignore
//   src: url('fonts/Moncler_Gothic/MonclerGothicWeb-Regular.woff') format('woff2'),
//        url('fonts/Moncler_Gothic/MonclerGothicWeb-Regular.woff2') format('woff');
// }

// @font-face {
//   font-family: 'MonclerGothic-SemiBold';
//   font-style: normal;
//   font-weight: bold;
//   font-display: block;
//   // prettier-ignore
//   src: url('fonts/Moncler_Gothic/MonclerGothicWeb-SemiBold.woff') format('woff2'),
//        url('fonts/Moncler_Gothic/MonclerGothicWeb-SemiBold.woff2') format('woff');
// }

// non usato
@font-face {
  font-family: 'MonclerGothic-Bold';
  font-style: normal;
  font-weight: normal;
  font-display: block;
  // prettier-ignore
  src: url('fonts/Moncler_Gothic/MonclerGothicWeb-Bold.woff2') format('woff2'),
       url('fonts/Moncler_Gothic/MonclerGothicWeb-Bold.woff') format('woff');
}

// da gestire a parte, usato dai titoli giganti
@font-face {
  font-family: 'MonclerGothic-Thin';
  font-style: normal;
  font-weight: normal;
  font-display: block;
  // prettier-ignore
  src: url('fonts/Moncler_Gothic/MonclerGothicWeb-Thin.woff2') format('woff2'),
       url('fonts/Moncler_Gothic/MonclerGothicWeb-Thin.woff') format('woff');
}

@font-face {
  font-family: 'SilkSerifLight';
  font-style: normal;
  font-weight: normal;
  font-display: block;
  // prettier-ignore
  src: url('fonts/Silk_Serif_Light/SilkSerif-Light.woff2') format('woff2'),
       url('fonts/Silk_Serif_Light/SilkSerif-Light.woff') format('woff');
}

// font ko
// @font-face {
//   font-family: 'MonclerNotoSansWebKR-Thin';
//   src: url('fonts/Moncler_Noto_Sans_Web_KR/WOFF2/MonclerNotoSansWebKR-Thin.woff2') format('woff2'),
//        url('fonts/Moncler_Noto_Sans_Web_KR/WOFF/MonclerNotoSansWebKR-Thin.woff') format('woff');
// }
// @font-face {
//   font-family: 'MonclerNotoSansWebKR-Light';
//   src: url('fonts/Moncler_Noto_Sans_Web_KR/WOFF2/MonclerNotoSansWebKR-Light.woff2') format('woff2'),
//        url('fonts/Moncler_Noto_Sans_Web_KR/WOFF/MonclerNotoSansWebKR-Light.woff') format('woff');
// }
// @font-face {
//   font-family: 'MonclerNotoSansWebKR-ExtraLight';
//   src: url('fonts/Moncler_Noto_Sans_Web_KR/WOFF2/MonclerNotoSansWebKR-ExtraLight.woff2') format('woff2'),
//        url('fonts/Moncler_Noto_Sans_Web_KR/WOFF/MonclerNotoSansWebKR-ExtraLight.woff') format('woff');
// }
// test x numeri
// @font-face {
//   font-family: 'MonclerNotoSansWebKR-ExtraLight';
//   src: url('fonts/Moncler_Gothic/MonclerGothicWeb-ExtraLight.woff2') format('woff2'),
//        url('fonts/Moncler_Gothic/MonclerGothicWeb-ExtraLight.woff') format('woff');
//   unicode-range: U+30-39;
// }

// font jp
// @font-face {
//   font-family: 'MonclerNotoSansWebJP-Thin';
//   src: url('fonts/Moncler_Noto_Sans_Web_JP/WOFF2/MonclerNotoSansWebJP-Thin.woff2') format('woff2'),
//        url('fonts/Moncler_Noto_Sans_Web_JP/WOFF/MonclerNotoSansWebJP-Thin.woff') format('woff');
// }
// @font-face {
//   font-family: 'MonclerNotoSansWebJP-Light';
//   src: url('fonts/Moncler_Noto_Sans_Web_JP/WOFF2/MonclerNotoSansWebJP-Light.woff2') format('woff2'),
//        url('fonts/Moncler_Noto_Sans_Web_JP/WOFF/MonclerNotoSansWebJP-Light.woff') format('woff');
// }
// @font-face {
//   font-family: 'MonclerNotoSansWebJP-ExtraLight';
//   src: url('fonts/Moncler_Noto_Sans_Web_JP/WOFF2/MonclerNotoSansWebJP-ExtraLight.woff2') format('woff2'),
//        url('fonts/Moncler_Noto_Sans_Web_JP/WOFF/MonclerNotoSansWebJP-ExtraLight.woff') format('woff');
// }
