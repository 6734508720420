.minibag {
  width: 42rem;
  top: $header-height-desktop;
  &.has-items {
    height: calc(var(--view-height) - #{$header-height-desktop});
    max-height: calc(var(--view-height) - #{$header-height-desktop});
    // overflow-y: hidden;
    .container-minibag-up {
      height: 100%;
    }
    .container-minibag-down {
      position: sticky;
      bottom: 0;
      background: white;
    }
  }
}
body.has-ticker {
  .minibag.has-items {
    height: calc(var(--view-height) - #{$header-height-desktop} - #{$ticker-bar});
    max-height: auto;
  }
}
