.promo-50-50 {
  &__not-padded {
    .promo-50-50 {
      &__video-col {
        position: relative;
        padding-top: 50%;
      }
      &__text-wrapper {
        height: auto;
        padding: 3.2rem;
      }
      &__img-col {
        &--right {
          order: 1;
        }
        &--left {
          order: 0;
        }
      }
    }
  }
  &__cta-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.8rem;
    .button {
      &--secondary,
      &--primary {
        min-height: 3.6rem;
        width: auto;
        margin: 0.2rem 0;
        padding: 0 2.4rem;
        //     display: inline-block;
      }
    }
  }
  &__eyebrows {
    margin-bottom: 0.8rem;
  }
}
