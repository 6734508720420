.component-text-squares {
  text-align: center;
  box-sizing: border-box;
  padding: 4rem 1.6rem 2.4rem;
  overflow: hidden;

  .section-text-squares {
    min-height: 375px;
    padding: 0;

    .text-square {
      &-content {
        list-style: none;
        border: 0.1rem solid $grey_line;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-caption {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 3.2rem;
        margin: 0 auto;
      }
      &-eyebrows,
      &-title {
        display: block;
        text-transform: uppercase;
        padding-bottom: 0.8rem;
      }

      &-title {
        font-size: 2.4rem;
        line-height: 2.8rem;
      }
    }
  }

  .swiper-container {
    overflow: visible;
  }
}

.text-squares-carousel_arrow-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  padding-top: 2.4rem;
  padding-bottom: 4rem;
}
