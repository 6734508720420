.toast-notification {
  &-component {
    margin-bottom: 4.6rem;
    padding: 1.6rem 0.8rem 1.2rem 1.2rem;
    min-height: 5.2rem;
    border: 1px solid #e4e4e4;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    font-size: 1.6rem;
    line-height: 2rem;
    animation: openSwipeLeft 0.3s linear;
    &.close-notification {
      animation: closingSwipeLeft 0.3s linear;
      transform: translateX(-100%);
    }
  }

  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.bag-overview__products {
  list-style-type: none;
}

@keyframes openSwipeLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes closingSwipeLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
