.myaccount-wrapper {
  overflow: hidden;
  margin-bottom: ($space-unit * 10);
  .myaccount-content {
    //margin-top: ($space-unit * 8);
    position: relative;
  }

  .profile-page-single-column {
    .myaccount__section {
      max-width: 100%;
      text-align: center;
      border: 0;

      .deleteAccount-btn {
        height: 48px;
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid $grey_line;
        border-bottom: 1px solid $grey_line;
        .button {
          margin-top: 0;
        }
      }

      .logout-btn {
        min-width: 158px;
        max-width: 158px;
        text-overflow: ellipsis;
        margin: auto;
        margin-bottom: 120px;
        margin-top: -24px;
      }
    }

    &.address-column,
    &.cards-column {
      position: relative;

      &:before {
        content: "";
        width: 200vw;
        height: 100%;
        background-color: $grey_disabled;
        position: absolute;
        left: -50vw;
      }

      .myaccount__section {
        background: $grey_disabled;
        padding: 40px 0;

        &.emptyAddresses {
          padding-top: 80px;
          padding-bottom: 80px;

          .button {
            margin-top: 0;
          }
        }
      }
    }
  }

  .profile-page-two-columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 80px;
    padding-bottom: 40px;
    column-gap: 0.25rem;
    row-gap: 40px;
  }

  .profile-page-three-columns {
    display: flex;
    justify-content: center;
  }
  .myaccount {
    &__nftMapper {
      justify-content: center;
    }
    &__dividerMargin {
      margin-top: 80px;
    }
    &__hrMargin {
      margin: 0;
      border-top: 0;
    }
    &__dividerMarginCards {
      margin-top: calc(80px - 16px);
    }
    &__whatarenftsfor {
      margin-top: 80px;
      font-size: 24px;
      font-weight: 300;
      line-height: 28px;
    }
    &__nftsNext {
      font-size: 14px;
      line-height: 16px;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
    &__emptyWallet {
      font-size: 16px;
      line-height: 20px;
      margin-top: 80px;
      margin-bottom: 24px;
    }
    &__emptyWalletButton {
      width: 227px;
      max-width: 100%;
    }
    &__visitTheFaqs {
      display: flex;
      margin-top: 24px;
      justify-content: center;
      align-items: center;
    }
    &__myCollectionTitle {
      margin-bottom: 80px;
      margin-top: 80px;
      font-size: 24px;
      line-height: 28px;
    }
    &__nftsVisitTheFaqs {
      position: relative;
      font-size: 16px;
      font-weight: 300;
      line-height: 20px;
    }
  }

  .myaccount__emptyOrderButton {
    width: 227px;
    max-width: 100%;
  }
  .myaccount__section {
    max-width: calc(50% - 0.25rem);
    border: 1px solid #e4e4e4;
    padding: 40px 11rem;
    flex: 1 0 auto;
    position: relative;
    // &:not(:last-child) {
    //   padding-bottom: ($space-unit * 10);
    //   @include border('bottom');
    //   margin-bottom: ($space-unit * 4);
    // }

    .title {
      @extend %primary-m;
      margin: 0 0 ($space-unit * 6);
      display: inline-block;
      text-transform: uppercase;
      font-size: 24px;
    }

    .subtitle {
      @extend %primary-m;
      margin: 0 0 40px;
      display: inline-block;
      text-transform: uppercase;
    }

    .subtitle-second {
      @extend %primary-m;
      display: inline-block;
      text-transform: uppercase;
      margin: 40px 0 24px 0;
      font-size: 24px;
    }

    ul {
      @include reset-list;
    }

    .text-section__dl {
      grid-template-columns: 1fr auto;
      width: max-content;
      margin-inline: auto;
      gap: 1rem 4rem;

      dt {
        font-size: 16px;
        font-weight: 300;

        &.w125{
          width: 125px;
        }
      }
      dd {
        font-size: 16px;
        font-weight: bold;

        &.ml-auto{
          margin-left: auto;
        }
      }

      &.flex-section {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        align-items: center;
        width: 100%;
        margin-inline: initial;
      }
    }
    .button {
      margin-top: ($space-unit * 6);
    }
  }
  .accordion__group {
    .accordion__header {
      &[aria-expanded='true'] {
        border-bottom-color: transparent;
      }
    }
    .accordion__panel {
      > div {
        padding-bottom: $space-unit * 8;
      }
      .default-item {
        @extend %primary-xxs-u;
        color: $black_secondary;
        margin-bottom: $space-unit * 2;
      }
    }
  }
  .button-row {
    align-items: center;
    margin-top: ($space-unit * 6);
    .button {
      margin-top: 0;
    }
  }
  .new-item-title {
    @extend %primary-s;
    margin-bottom: ($space-unit * 3);
  }
  .checkoutpage .adyen-checkout__card-input {
    margin-bottom: 0;
  }
  .text-block__inner-container {
    width: 100%;
  }

  .social-toggle-container {
    display: flex;
    justify-content: center;
    gap: 2.5rem;

    .social {
      .social-label {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        font-size: 16px;
      }
    }
  }
}

.myaccount-wrapper,
.modal__body {
  .button-row {
    align-items: center;
    margin-top: ($space-unit * 6);
    .button {
      margin-top: 0;
    }
  }
}

.single-banner {
  display: block;
  background-color: #1b1b1b;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: ($space-unit * 8);
  &.parfum-banner {
    background-image: var(--background-desktop), var(--background-mobile);
    &:before {
      content: '';
      background: linear-gradient(360deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
    }
    .single-banner__text-holder {
      top: auto;
      bottom: 3.8rem;
      transform: translate(-50%, 0);
      text-align: center;
      width: 100%;
    }
    .badge-row {
      margin-top: 0.3rem;
      > a {
        display: inline-block;
        img {
          height: 3.4rem;
          margin: 0 0.6rem;
        }
      }
    }
  }
  &__text-holder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    background: radial-gradient(50% 50% at 50% 50%, rgba(63, 63, 63, 0.25) 0%, rgba(63, 63, 63, 0) 100%);
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__title {
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 300;
    font-size: ($space-unit * 6);
    line-height: ($space-unit * 7);
    margin-bottom: ($space-unit * 2);
  }
}

.list__empty-message {
  width: 100%;
  margin: 8rem auto;
  text-align: center;
  .empty-message__text {
    // display: inline-block;
    margin-bottom: ($space-unit * 6);
    @extend %primary-s;
  }
  .empty-message__title {
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
}

.mt-section {
  margin-top: 40px;
}

.list__empty-message-title {
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
}

.separator-hr {
  border: 1px solid #e4e4e4;
  border-bottom: 0 none;
}

.back-btn-wrapper {
  margin-bottom: 40px;
  text-align: left;
  text-transform: uppercase;

  &.position-static {
    position: static;
  }
}

.order-detail-page {
  .back-btn-wrapper {
    position: absolute;
  }
}

.deleteAccount-aboutToDelete {
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  margin-top: 16px;
  line-height: 16px;
}

.deleteAccount-button {
  margin-top: 16px;
}

.deleteAccount-buttonCancel {
  margin-top: 16px;
  margin-bottom: 8px;
}

.deleteAccount-submitted {
  margin-bottom: 16px;
}

.deleteAccount-noAccess,
.deleteAccount-noTrack,
.deleteAccount-noEvents {
  margin-bottom: 8px;
}
.deleteAccount-redirected {
  margin-bottom: 40px;
}
.deleteAccount-inProgress {
  margin-bottom: 16px;
  line-height: 16px;
  font-weight: 500;
  font-style: normal;
  margin-top: 16px;
  font-size: 14px;
}
.deleteAccount-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  text-align: center;
}

.deleteAccount-after {
  margin-top: 16px;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  margin-bottom: 19px;
}

.deleteAccount-reminder {
  line-height: 16px;
  font-weight: 300;
  font-size: 14px;
  margin-top: 16px;
}

[data-react='appcreditcard'] {
  .button-row {
    margin-top: 2.4rem;
  }
}
