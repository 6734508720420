.text-on-image {
  &::before {
    padding-top: 70%;
  }
  &__image {
    img {
      position: absolute;
      left: 2rem;
      top: 2rem;
      right: 2rem;
      bottom: 2rem;
      width: calc(100% - (#{$space-unit} * 10));
      height: calc(100% - (#{$space-unit} * 10));
      object-fit: cover;
    }
  }

  &__text {
    left: ($space-unit * 10);
    bottom: ($space-unit * 10);
  }

  &--two-images {
    .text-on-image {
      &__text {
        left: 2rem;
        bottom: 2rem;
      }
    }
  }
}
