.aspect-ratio--4-3 {
  &::before {
    @extend %default;
    padding-top: 75%;
  }
}

.aspect-ratio--3-4 {
  &::before {
    @extend %default;
    padding-top: 133%;
  }
}

.aspect-ratio--2-3 {
  &::before {
    @extend %default;
    padding-top: 150%;
  }
}

.aspect-ratio--3-2 {
  &::before {
    @extend %default;
    padding-top: 66.66%;
  }
}

.aspect-ratio--1-1 {
  &::before {
    @extend %default;
    padding-top: 100%;
  }
}

.aspect-ratio--21-9 {
  &::before {
    @extend %default;
    padding-top: 42.86%; // 21:9
    @media (max-width: 1023px) {
      padding-top: 133%; //3:4
    }
  }
}

.aspect-ratio--16-9 {
  &::before {
    @extend %default;
    padding-top: 56.25%; // 16:9
    @media (max-width: 1023px) {
      padding-top: 150%; // 2:3
    }
  }
}

.aspect-ratio--3-1 {
  &::before {
    @extend %default;
    padding-top: 33.3%; // 3:1
    @media (max-width: 599px) {
      padding-top: 100%; // 1:1
    }
  }
}

.aspect-ratio--viewport {
  &::before {
    @extend %default;
    padding-top: calc(var(--viewport-height) - #{$header-height-desktop});
    @media (max-width: 1023px) {
      padding-top: calc(var(--viewport-height) - #{$header-height-mobile});
    }
  }
}

.has-ticker {
  .aspect-ratio--viewport {
    &::before {
      padding-top: calc(var(--viewport-height) - #{$header-height-desktop} - #{$ticker-bar});
      @media (max-width: 1023px) {
        padding-top: calc(var(--viewport-height) - #{$header-height-mobile} - #{$ticker-bar-m});
      }
    }
  }
}

%default {
  content: '';
  display: block;
  width: 100%;
}
