.image-module {
  &--margin {
    margin: ($space-unit * 10) ($space-unit * 4);
  }
  &.swiper-container {
    width: 100%;
    .single-image__wrapper {
      width: 100%;
      .single-image {
        &.swiper-slide {
          flex-grow: 1;
          width: 100%;
        }
      }
    }
  }

  &--potrait-image {
    .swiper-slide {
      width: 100%;
    }
    .single-image {
      &__wrapper {
        flex-wrap: wrap;
      }
    }
  }
}
