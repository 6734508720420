.more-curators {
  // background-color: $black;
  padding: 8rem 0;
  color: $white;
  overflow: hidden;
  // for test
  // margin: 50vh 0;
  &__title {
    text-transform: uppercase;
    text-align: center;
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-bottom: 5.6rem;
  }
  .component-curator-card {
    width: 100%;
    opacity: 0;
    // cursor: pointer;
    .curators-card {
      display: flex;
      position: relative;
      margin-bottom: 8rem;
      &:focus {
        &::after {
          content: '';
          position: absolute;
          top: 1px;
          left: 1px;
          z-index: 2;
          width: calc(100% - 4px);
          height: calc(100% - 4px);
          border: none;
        }
      }
      &:focus-visible {
        &::after {
          content: '';
          position: absolute;
          top: 1px;
          left: 1px;
          z-index: 2;
          width: calc(100% - 4px);
          height: calc(100% - 4px);
          border: 1px solid white;
        }
      }
      &::before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 150%;
      }
      .image-wrapper {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        // max-width: 100vw;
        height: 100%;
        position: absolute;
        overflow: hidden;
        .overlay {
          position: absolute;
          width: 100%;
          height: 70%;
          left: 0;
          bottom: 0;
          opacity: 0;
          background: linear-gradient(360deg, rgba(0, 0, 0, 0.8) 30.46%, rgba(0, 0, 0, 0) 98.22%);
        }
        img {
          object-fit: cover;
          min-height: 100%;
          min-width: 100%;
        }
      }
      .curators-card__inner-container {
        position: absolute;
        padding: 2.4rem;
        left: 0;
        bottom: 0;
        z-index: 2;
        .date {
          text-transform: uppercase;
          font-size: 1.4rem;
          line-height: 1.6rem;
          display: block;
          opacity: 0;
        }
        .curator-card__title {
          font-family: $silkSerifLight;
          text-transform: uppercase;
          font-size: 4.6rem;
          line-height: 4.4rem;
          margin: 1.2rem 0;
          max-width: 56rem;
          text-decoration: none;
          display: block;
        }
        .curator-card__quote {
          font-size: 1.8rem;
          line-height: 2.2rem;
          margin-bottom: 3.4rem;
          display: block;
          max-width: 42rem;
          opacity: 0;
        }
        .button {
          width: auto;
          opacity: 0;
        }
      }
    }
  }
}
