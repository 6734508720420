.sub-category-tiles-component {
  .sub-category-tiles-section {
    flex-direction: row;
    align-items: flex-end;
    .sub-category-tile {
      flex-basis: 33.3333%;
      max-width: 33.3333%;
    }
  }
}
