.product-selection {
  &-content {
    padding-top: ($space-unit * 14);
  }

  .product-selection__ctas {
    flex-direction: column;
    row-gap: 0.4rem;
    position: relative;
    &.is-mbm {
      .button {
        &:first-child {
          width: 100%;
        }
      }
    }
    &.express-payments {
      .button {
        &:first-child {
          width: 100%;
        }
      }
    }
    .wishlist-icon {
      position: absolute;
      right: 0;
    }

    .button {
      margin: 0 $space-unit 0 0;

      &:first-child {
        margin: 0 $space-unit 0 0;
      }

      &:last-child {
        margin: 0;
      }
    }
  }
}
.product-details-tab {
  .mbm-list {
    .palette-accordion {
      flex-wrap: nowrap;
      .palette-accordion-body,
      .palette-modal__image {
        flex: 1;
      }
    }
  }
  .composition-care-icons {
    padding: 0 1.8rem 1.2rem 0;
  }
}

.product-selection-wrapper {
  .link-anchor {
    margin-top: 3.5rem;
  }
}
