.editorial-multi-text.genius {
  .editorial-multi-text__label {
    text-transform: none;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 2rem;
  }
  .editorial-multi-text__title {
    @include geniusTitle();
    padding-bottom: 3.2rem;
  }

  .editorial-multi-text__btn-container {
    margin-top: -2rem;
  }

  .editorial-multi-text__body {
    font-size: 1.4rem;
    line-height: 1.6rem;
    & + .editorial-multi-text__btn-container {
      display: none;
    }
  }
}
