.model-selection {
  .model-button {
    padding-bottom: 10rem;
  }
  &.not-selected {
    cursor: pointer;
  }
  .proceed-to-palette {
    bottom: 3.2rem;
    width: 200px;
    left: 50%;
    transform: translateX(-50%);
  }
}
