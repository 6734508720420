.checkout-packaging {
  margin-top: 3rem;
  .back-button {
    height: auto;
    display: flex;
  }
  &__message {
    width: 100%;
    margin-left: initial;
    &__aspect-ratio {
      padding-bottom: 48.16%;
    }
    &__field {
      // margin: ($space-unit * 16) auto ($space-unit * 16);

      &::before {
        left: ($space-unit * 5);
        top: ($space-unit * 5);
        width: 48px; // a11y: has to be in pixels to avoid resizing
        height: ($space-unit * 9);
      }

      &::after {
        left: $space-unit;
        top: $space-unit;
      }

      .form-control {
        textarea {
          height: 7.8rem;
        }
      }
    }
  }
}
