.previousorders {
  margin: 4rem 0;
  &.in-clientservice {
    .previousorders--intro {
      margin-bottom: $space-unit * 6;
    }
  }
  .accordion__item {
    @include border('bottom');
  }
  .accordion__header > span {
    @extend %primary-s;
    padding: 1.4rem 0;
  }
  &--intro {
    margin-bottom: $space-unit * 4;
    &-title {
      @extend %primary-m-u;
      margin-bottom: ($space-unit * 3);
      text-align: center;
    }
  }
  &--body {
    margin-top: 1rem;
    margin-bottom: $space-unit * 3;
  }
  .button {
    margin-top: $space-unit * 3;
  }
}
