.visual-card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-decoration: none;
  // &::after {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   background-color: #000000;
  //   opacity: 0;
  //   @include animate(0.166, 'opacity');
  // }

  span {
    @extend %primary-l-u;
    position: absolute;
    color: $white;
    text-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.2);
    z-index: 2;
  }

  img {
    width: 100%;
  }

  &:focus {
    outline: none;
    span {
      @include focus-underline;
    }
  }

  // &:hover,
  // &:focus {
  //   &::after {
  //     opacity: 0.1;
  //   }
  // }
}
