.collection-card {
  padding-bottom: 100%;
  &__inner-content {
    img {
      max-height: 10.8rem;
      margin: 0 auto 4.8rem;
    }
  }
  &__title {
    font-size: 2rem;
    line-height: 2.4rem;
    height: 4.8rem;
  }
  .button {
    font-size: 1.6rem;
    margin-top: 5rem;
    &::after {
      top: -0.45rem;
    }
  }
}
