.shop-by-look-module {
  .preview-image {
    .product-image {
      width: 90%;
      margin: 0 auto;
    }
  }
  .look-products {
    padding-top: ($space-unit * 30);
    padding-bottom: ($space-unit * 24);
  }
}
