.plp-heading {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  // &.hasDesc {
  flex-direction: column;
  // }

  .plp-title {
    flex-grow: 1;
  }

  ol {
    @include reset-list;
  }

  li {
    display: inline;
  }

  > p {
    margin: $space-unit 0;
    width: 90%;
  }

  &__category-title,
  &__subcategory-title {
    @extend %primary-s-u;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    @include animate(0.3);
    &.hide {
      opacity: 0;
      transition-duration: 0s;
      transform: translateY(0.25em);
    }
  }

  &__category-title {
    &::after {
      content: '/';
      width: 2.4rem;
      height: auto;
      display: inline-block;
      font-size: 1.6rem;
      line-height: 2rem;
      text-align: center;
    }
  }
}
