.sub-headline {
  &--long {
    .sub-headline__inner-container {
      width: 44.4rem;
    }
  }
}

.centered-text-block {
  &__inner-container {
    width: 66.67%;
  }
}

.two-column-text-block {
  &__inner-container {
    width: 83.33%;
  }

  &__inner-container > * {
    width: calc(50% - 1.8rem);
  }
}

.text-column {
  &__inner-container {
    width: 50%;
  }
}
