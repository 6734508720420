// [data-whatinput='mouse'] {
//   .a11y-action-bar {
//     display: none;
//   }
// }

.a11y-action-bar {
  background-color: $black;
  color: $white;
  min-height: 3.2rem;
  padding: 0 1.6rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  @extend .visible-on-focus;

  @extend %primary-xs-u;
  .button-close {
    @include fixedSprite('close-white');
    width: 2.4rem;
    height: 2.4rem;
    &:focus,
    &:focus-within {
      border: 1px solid white;
    }
  }

  button,
  a,
  a:link,
  a:visited {
    color: $white;
    @include link-focus-underline(true);
    margin-right: 0.8rem;
    * {
      color: $white !important;
      text-transform: uppercase;
    }
  }

  &:focus {
    outline: none;
  }
}

.wrapper-submenu {
  .a11y-action-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    justify-content: center;
    z-index: 3;
    .menu-button-close {
      position: absolute;
      right: 0;
    }
  }
}

.minibag {
  .a11y-action-bar {
    position: relative;
    top: $space-unit * -5;
    left: 0;
    right: 0;
    margin-left: $space-unit * -8;
    margin-right: $space-unit * -8;
    padding-left: $space-unit * 8;
  }
}

.account-dropdown {
  .a11y-action-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    // margin-left: $space-unit * -8;
    // margin-right: $space-unit * -8;
    padding-left: $space-unit * 8;
    z-index: 1;
  }
}

.account-dropdown-logged {
  .a11y-action-bar {
    position: relative;
    top: $space-unit * -8;
    left: 0;
    right: 0;
    margin-left: $space-unit * -8;
    margin-right: $space-unit * -8;
    padding-left: $space-unit * 8;
  }
}

.bag-tabs {
  .a11y-action-bar {
    padding-left: $space-unit * 8;
  }
}

.three-column-product__product-holder {
  .a11y-action-bar {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;

    &[aria-expanded='false'] {
      .expanded--false {
        display: block;
      }

      .expanded--true {
        display: none;
      }
    }

    &[aria-expanded='true'] {
      .expanded--false {
        display: none;
      }

      .expanded--true {
        display: block;
      }
    }
  }
}

.hero {
  .a11y-action-bar {
    position: absolute;
    background-color: transparent;
    left: 3.2rem;
    bottom: 3.4rem;
    z-index: 6;
  }
}

.genius-designers {
  .a11y-action-bar {
    padding: $space-unit * 2 $space-unit * 8;

    flex-direction: column;
    align-items: flex-start;

    .genius-designer__controls {
      position: relative;
      align-self: center;
    }

    ul,
    ol {
      @include reset-list;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      li {
        padding: $space-unit * 2 0;

        a {
          margin-right: 0;
          p {
            display: inline;
          }
        }

        &:not(:last-child) {
          &::after {
            content: '|';
            color: $white;
            padding: 0 $space-unit * 2;
            display: inline-block;
          }
        }
      }
    }
  }
}
