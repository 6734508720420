.visual-navigation {
  padding: 2rem 0;
  &__wrapper {
    &::before,
    &::after {
      content: '';
      width: 2rem;
      min-width: 2rem;
    }
  }
  .aspect-ratio--2-3 {
    min-width: 17.9rem;
  }
  .aspect-ratio--3-2 {
    span {
      font-size: 1.4rem;
      line-height: 1.6rem;
    }
  }
}
