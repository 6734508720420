.modal {
  &--country-selector.modal {
    height: 24.2rem;
    padding: 0;
    &.full-screen {
      .modal {
        &__body {
          .continent {
            .card-header {
              padding-bottom: 2.4rem;
            }
            padding-bottom: 2.4rem;
          }
        }
      }
    }
    .modal {
      // &__header {
      //   border-bottom: 1px solid #fff;
      // }
      &__body {
        .row {
          .button {
            margin-bottom: 2rem;
          }
        }
      }
    }
  }
}
