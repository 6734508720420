.contact-information {
  margin-bottom: 2rem;
  // border-bottom: 1px solid $grey_line;
  // button {
  //   margin-top: 0;
  // }
  .subtitle {
    display: flex;
    column-gap: 0.8rem;
    margin-bottom: 0.4rem;
  }
  .formfields {
    padding-bottom: 1.2rem;
  }
  .text-enter-email {
    padding-bottom: 1.6rem;
  }
  .contact-information-text {
    padding-bottom: 2.4rem;
  }
  .logged-as {
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.6rem;
    column-gap: 1.2rem;
    @extend %primary-xs;
    span:not(.bold) {
      flex-basis: 100%;
    }
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: #e4e4e4e4;
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
  }
  .social-button {
    margin-bottom: 1.6rem;
  }
}
