.product-reserve {
  &__main {
    .product-image {
      &::before {
        padding-top: 100%;
      }
    }
  }
  &__detail {
    z-index: 3;
    margin-top: 3rem;
    &__reserve-review {
      margin-top: 2.4rem;
    }
  }
}
