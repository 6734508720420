.bag-tabs {
  &__title {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  &__header {
    padding-top: 4rem;
    padding-bottom: 0.8rem;
  }
}
