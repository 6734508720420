.component_grenoble_performance {
  $viewportHeight: calc((var(--viewport-height) - #{$contextual-navigation}) * 3);
  height: $viewportHeight;
  &__title-wrapper {
    // top: $contextual-navigation;
    padding-bottom: 3.2rem;
    .title {
      display: inline-block;
      max-width: 42rem;
      font-size: 4.2rem;
      line-height: 5rem;
    }
  }
  &__info-wrapper {
    .description {
      max-width: 42rem;
      display: inline-block;
    }
  }
}
