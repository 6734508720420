.page-template-immersiveparfumes {
  main {
    padding-top: $contextual-navigation + $header-height-desktop;
  }
  .contextual-nav {
    padding: 0 12%;
    .back-button {
      display: block;
    }
    h1 {
      font-size: 1.6rem;
      line-height: 2rem;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

body.has-ticker {
  &.page-template-immersiveparfumes {
    main {
      padding-top: $contextual-navigation + $header-height-desktop + $ticker-bar;
    }
  }
}
