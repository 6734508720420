.traceability-packaging {
  position: relative;
  padding-bottom: 15rem;

  &.myaccount-hero {
    min-height: 37.5rem;
  }
  &-content {
    text-align: center;
    position: absolute;
    top: -8rem;
    bottom: 2.4rem;
    right: 0;
    left: 0;
    outline: 1px solid $grey-line;
    background-color: $white;
    padding: 2.4rem 1.6rem 3.1rem 1.6rem;
    margin: 0 1.6rem;
    max-height: 21.9rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .title {
      text-transform: uppercase;
      padding-bottom: 0.8rem;
      letter-spacing: 0;
    }
  }
}
