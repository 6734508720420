.bottom-module {
  .monclernow-listing {
    padding-top: 0;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
    flex-wrap: nowrap;
    overflow: auto;
    justify-content: flex-start;
    &__article {
      margin-right: 0.4rem;
      &:last-child {
        margin-right: 0;
      }
    }
    &::after {
      content: none;
    }
  }
  &__title {
    @extend %primary-xl;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 3.2rem;
    padding-top: 4.8rem;
    background: linear-gradient(180deg, #f9f9f9 0%, rgba(255, 255, 255, 0) 100%);
  }

  &--text-on-image {
    .article {
      &__info-wrapper {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        z-index: 2;
        width: 32rem;
        max-width: 100%;
      }
    }

    .monclernow-listing {
      &__article {
        position: relative;
        overflow: hidden;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: linear-gradient(0deg, rgba(37, 37, 37, 0.5), rgba(37, 37, 37, 0.5));
          width: 100%;
          height: 100%;
          z-index: 1;
        }
      }
    }
  }
}
