.component-two-column-promo.genius {
  padding: 2.8rem;
  .two-column-promo-item {
    .two-column-promo-label {
      bottom: 4.1rem;
      .two-column-promo-title {
        margin-bottom: 2rem;
      }
    }
  }
}
