.wrapper-sticky-mobile {
  &.fixed {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}

.bag-summary {
  .tooltip.packaging-info {
    .tooltip__box {
      right: -10rem;
      &:before {
        right: 09.4rem;
      }
    }
  }
}
