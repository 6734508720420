.bag-bottom-sheet {
  [data-rsbs-overlay] {
    z-index: 5;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  [data-rsbs-backdrop] {
    z-index: 5;
  }
  .bottom-sheet-content {
    padding: 1.6rem 1.6rem 0.8rem;
    .button {
      height: $button-height-mobile;
      margin-bottom: 1.6rem;
      span {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
    .express-payment {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 3.4rem;
      &__title {
        font-size: 1.6rem;
      }
      i {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
    .button--arrow-back {
      position: absolute;
      top: 0.6rem;
      .icon--chevron-left {
        height: 2.4rem;
        width: 2.4rem;
      }
    }
    .data-processing {
      margin-bottom: 1.6rem;
    }
    .checkout-privacyflags {
      margin-top: 0;
    }
  }
}
