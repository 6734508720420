.plppromo {
  &.plppromo__promo-small {
    .plppromo {
      &__title {
        font-size: 2rem;
      }
      &__div {
        &.bottom {
          .plppromo__text-wrapper {
            //bottom (mobile)
            transform: translateY(-50%);
          }
        }
      }
    }
  }
  &.plppromo__promo-large {
    .plppromo {
      &__div {
        .plppromo {
          &__picture {
            &:before {
              padding-top: 125%;
            }
          }
        }
      }
    }
  }
}
.grid-container {
  li {
    // FIX for iOS Safari
    .product-tile--carousel:not(.allow-transform) {
      .swiper-wrapper {
        transform: none !important;
      }
    }
  }
}
