.rich-video {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50.6rem;
  position: relative;

  &--portrait {
    .grid-container & {
      grid-column: span 2;
      grid-row: span 2;
      min-height: 66.8rem;
    }
    .rich-video {
      &__text-wrapper {
        width: 66%;
      }
    }
  }
  &--landscape {
    .grid-container & {
      grid-column: span 2;
    }
  }

  &__block {
    position: relative;
    width: 100%;
    height: 100%;
    &.light {
      border: 1.6rem solid $white;
    }
    &.dark {
      border: 1.6rem solid $black;
    }
  }
  &__video {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .btn--pause {
    position: absolute;
    bottom: ($space-unit * 6);
    left: 2rem;
  }
  .btn--volume {
    position: absolute;
    bottom: ($space-unit * 6);
    left: 5.6rem;
  }
  &__text-wrapper {
    color: $white;
    position: absolute;
    z-index: 1;
    padding: ($space-unit * 4);
  }
  &__tagline {
    @extend %primary-s-u;
  }
  &__title {
    @extend %primary-m;
    text-transform: uppercase;
    margin-bottom: ($space-unit);
  }
  &__description {
    @extend %primary-xxl;
    text-transform: uppercase;
    letter-spacing: normal;
    max-width: 31.1rem;
  }
  &__title,
  &__description {
    margin-bottom: ($space-unit * 2);
  }

  .rich-video__inner-container {
    .video-wrapper {
      opacity: 1;
      pointer-events: visible !important;
    }
  }

  .video-wrapper.image-background--bright {
    &:not(.video-started) {
      &:after {
        background: none;
      }
    }
    .th-button {
      .th-svg-container {
        pointer-events: visible !important;
      }
    }
    .th-controls {
      .th-control-bar {
        // &.th-bottom {
        //   visibility: visible;
        //   opacity: 1;
        // }
        .th-group {
          &.th-main {
            display: flex;
          }
          .th-button-color {
            &:hover {
              color: white;
              fill: white;
            }
          }
          .th-play-button {
            order: 1;
          }
          .th-time-seek {
            order: 3;
            max-width: 100%;
          }
          .th-volume-button {
            order: 2;
            margin-left: -0.9rem;
          }
          .th-time-rail {
            background-color: transparent;
            pointer-events: visible !important;
            .th-slider-bg-color {
              background-color: rgba(255, 255, 255, 0.25);
              stroke: rgba(255, 255, 255, 0.25);
              .th-time-current {
                background-color: white;
                stroke: white;
              }
            }
          }
          .th-hd-button {
            display: none;
          }
          .th-speed-button {
            display: none;
          }
          .th-downloadable-button {
            display: none;
          }
          .th-time-info-text {
            display: none;
          }
          .th-fullscreen-button {
            display: none;
          }
        }
      }
    }
  }

  .no-volume {
    .video-wrapper {
      .th-controls {
        .th-control-bar {
          .th-group {
            .th-volume-button {
              display: none;
            }
          }
        }
      }
    }
  }

  .no-timerail {
    .video-wrapper {
      .th-controls {
        .th-control-bar {
          .th-group {
            .th-time-seek {
              display: none;
            }
          }
        }
      }
    }
  }

  .th-video .th-video-player {
    .th-big-play-btn {
      display: none;
    }
  }
  .th-control-bg-color {
    background-color: transparent;
  }

  .th-reload-icon.th-display-icon.th-svg-container {
    display: none;
  }

  .th-video .th-video-player .th-video-poster {
    background-size: cover;
  }

  .th-button-color-active {
    fill: $white;
    color: $white;
  }

  [data-anim] {
    &.delay-3000 {
      transition-delay: 3s;
    }
  }

  [data-anim^='fade'] {
    opacity: 0;
    visibility: hidden;
    transition-property: opacity, transform, visibility;
    transition-duration: 0.3s;
  }
  [data-anim='fade-up'] {
    transform: translate3d(0, 50px, 0);
  }
  [data-anim^='fade'].aos-animate {
    opacity: 1;
    visibility: visible;
    transform: translateZ(0);
  }
  [data-anim].aos-animate {
    transition-delay: 0.1s;
  }
}

// the volume slider is rendered in fixed position outside the component itself
.th-tooltipster-base {
  display: none !important;
}
