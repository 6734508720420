.wishlist-count {
  // font-size: 14px;
  display: inline-flex;
  padding-right: 2px;
  &::before {
    content: '';
    @include sprite('heart-filled-black');
    width: 2rem;
    height: 2rem;
    display: inline-block;
  }
}
.account-dropdown {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  position: absolute;
  right: 0;
  padding: 0 28px 28px;
  top: $header-height-desktop;
  width: 348px;
  max-width: 100%;
  height: calc(100vh - #{$header-height-desktop});
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  background-color: $white;
  transform: translateX(100%);
  overflow: hidden;
  overflow-y: auto;
  @include animate(0.3);
  @include gray-gradient-pseudo(after);

  &:focus-visible {
    outline: 1px solid $black;
    outline-offset: -2px;
  }

  .menu-logged{
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: auto;
    li{
      &:first-of-type{
        border-bottom: solid 1px $grey_line;
        padding-bottom: 8px;
        margin-bottom: 8px;
      }
      a{
        font-size: 16px;
        text-decoration: none;
        display: inline-block;
        margin: 8px 0;
        .wishlist-count{
          display: inline-flex;
          padding-right: 2px;
          font-size: 13px;
          align-items: center;
          &::before {
            width: 14px;
            height: 12px;
            display: inline-block;
          }
        }
      }
    }
    .dropdown-banner-wrapper{
      margin-top: auto;
    }
    .dropdown-banner{
      p, .text-label, .text-main, .text-cta {
        z-index: 0;
      }

      img{
        z-index: -1;
      }
    }

    .bottom-menu-section {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .wallet-connect__btn {
        text-decoration: underline;
      }
    }
  }

  &.visible {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
    transform: translateX(var(--scrollbar-width));
  }
  .myaccount-hero {
    margin-left: -2.8rem;
    margin-right: -2.8rem;
    height: 139px;
    flex-shrink: 0;
    margin-bottom: 3.2rem;
    .myaccount-hero__title {
      @extend %primary-l-u;
      margin-bottom: 3.4rem;
    }
  }
  .wishlist-desktop-button {
    flex-shrink: 0;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: initial;
  }
  .login-registration__registration {
    margin-top: 1.6rem;
  }
  .registered-in-store-send-email {
    .row {
      row-gap: 1.2rem;
    }
  }
  .register_form__comunication-channels .channel-flags {
    display: grid;
    grid-template-columns: 2fr 2fr;
    background-color: rd;
  }

  // Button 'BACK' - account access from navigation desktop [OE-15621]
  .register_form {
    form {
      .row-reverse-mobile {
        display: flex;
        flex-direction: column-reverse;
        .col-md-6 {
          width: 100%;
          text-align: center;
          max-width: 100%;
          flex-basis: 100%;
        }
        .col-md-6:first-child {
          padding-top: 1.6rem;
        }
      }
    }
  }
  arn-connect {
    // display: flex;
    // flex-grow: 1;
    // align-items: flex-end;
    // justify-content: flex-end;
    // margin-top: 4rem;
  }
  .wallet-connect {
    &__btn {
      // position: absolute;
      //text-decoration: underline;
      // right: 3.3rem;
      // bottom: 3.5rem;
      margin: 8px 0;
      font-size: 16px;
    }
  }
}
.account-dropdown-logged-new{
  .wrapper-header-modal{
    .modal__close{
      right: 0;
    }
  }
}
.wallet-connect-btn {
  text-align: right;
  padding-top: 52px;

  .account-wrapper__item {
    justify-content: flex-end;
  }

  .wallet-connect__btn {
    text-decoration: underline;
  }
}
.account-dropdown-logged {
  position: absolute;
  top: calc(100% - (0.4rem * 4));
  right: 2.8rem;
  padding: 3.2rem;
  padding-top: 4.8rem;
  min-width: 22.6rem;
  opacity: 0;
  visibility: hidden;
  background-color: #fff;
  transform: translateY(-3rem);
  z-index: -1;
  overflow: hidden;
  @include animate(0.3);
  @include gray-gradient-pseudo(after);

  &[aria-modal='true'] {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  .menuheading {
    display: inline-block;
    margin-bottom: ($space-unit * 4);
    small {
      display: block;
      color: $black_secondary;
      @extend %primary-xxs;
      margin-bottom: 0.2rem;
    }
  }
  .menuheading,
  .menuitem {
    @extend %primary-s;
  }
  .menuitem {
    display: inline-flex;
    text-decoration: none;
  }
  li:not(:last-child) {
    margin-bottom: $space-unit;
  }
  .wishlist-count {
    font-size: 14px;
  }
  .wallet-connect {
    &__btn {
      text-decoration: underline;
      margin-top: 2rem;
    }
  }
}

.account-dropdown__social {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.2rem;
  row-gap: 0.8rem;
}

[data-whatintent='keyboard'] {
  .account-dropdown,
  .account-dropdown-logged {
    transition-property: transform;
  }
}

.minibag,
.account-dropdown {
  .modal__close {
    right: 1.2rem;
  }
}
.login-register-header {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  height: 6.4rem;
  .modal__back {
    position: absolute;
    left: 1.2rem;
    top: 0;
    padding: 1.6rem;
    &::before {
      content: '';
      @include fixedSprite('chevron-left-big');
      display: block;
    }
    &:focus-visible {
      outline: initial;
      &::before {
        outline: $outline-width solid $outline-color;
        outline-offset: -1px;
      }
    }
  }
}

.account-dropdown {
  &__wallet-connect {
    &__button {
      position: absolute;
      text-decoration: underline;
      right: 3.3rem;
      bottom: 3.5rem;
    }
  }
}

body.has-ticker {
  .account-dropdown {
    height: calc(var(--viewport-height) - #{$ticker-bar} - #{$header-height-desktop});
  }
}
.logoPeaks{
  position: relative;
  height: 20px;
  width: 100%;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url('./../../media/moncler-peaks.svg');

  &.black {
    filter: invert(1);
    height: 24px;
    width: auto;
    background-size: auto 100%;
  }
}
.logoPeaks2{
  position: relative;
  height: 56px;
  width: 100%;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin-top: 50px;
  background-image: url('./../../media/moncler-peaks-2.svg');
}
.myaccount-hero-wrapper-a{
  width: 100%;
  height: 100%;
  position: absolute;
  text-decoration: none;
}
.myaccount-hero-wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
[data-react="account-menu-desktop"]{
  .myaccount-hero-wrapper{
    justify-content: end;
    padding-bottom: 32px;
  }
}
.myaccount-hero__title-new {
  color: $white;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  position: relative;
}
.menu-mobile-banner-region{
  .menu-mobile-banner {
    background-size: cover;
    background-repeat: no-repeat;
  }
  .myaccount-hero-wrapper{
    gap: 16px;
  }
  .myaccount-hero__title-new {
    font-size: 18px;
    line-height: 22px;
  }
  .logoPeaks{
    height: 24px;
  }
}

.dropdown-banner{
  aspect-ratio: 310/212;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  color: $white;
  gap: 20px;
  margin-top: 42px;
  margin-bottom: 33px;
  padding: 15px;
  text-align: center;

  p, .text-label, .text-main, .text-cta {
    z-index: 1;
  }

  img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  p{
    text-shadow: 0 0 5px rgba(0,0,0,0.3);
    margin: 0;
  }
  .text-label{
    font-size: 12px;
    line-height: 14px;
  }
  .text-main{
    font-size: 32px;
    line-height: 38px;
  }
  .text-cta{
    a{
      margin: 0;
      line-height: 20px;
      font-weight: bold;
      color: $white;
      display: flex;
      align-items: center;
      &::after{
        content: '';
        margin: 0 ($space-unit * 2);
        @include fixedSprite('arrow-right-white');
      }
    }
  }
}

.container-sidebar-experience {
  width: 50%;
  margin: auto;
  border: 2px solid red;
}
