// grid settings
// prettier-ignore
$grid-breakpoints: (
  xs: 375px,
  sm: 600px,
  md: 1024px,
  lg: 1200px, //dovrebbe esserci un'altro breakpoint (1200px)
  xl: 1440px,
  xxl: 1920px,
);
$grid-columns: 12;
$grid-gutter-width: 0.4rem;
$grid-container-padding: (
  xs: 1.6rem,
  sm: 2rem,
  md: 2.8rem,
  lg: 3.2rem,
);
// $grid-max-width: (
//   xl: 1440px,
// );
$grid-overlay-base-color: rgba(2, 187, 156, 0.12);
$grid-overlay-colors: (
  xs: rgba(0, 0, 255, 0.2),
  sm: rgba(241, 126, 126, 0.2),
  md: rgba(130, 241, 126, 0.2),
  lg: rgba(218, 126, 241, 0.2),
  xl: rgba(203, 241, 126, 0.2),
);
$repeating-width: calc(100% / #{$grid-columns});
$column-width: calc((100% / #{$grid-columns}) - #{$grid-gutter-width});
$product-ratio: 150%; // 127.4%; // oldimages
