.sizechart {
  // margin-bottom: ($space-unit * 6);
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  &::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba($black, 0);
    background-clip: padding-box;
    background-color: rgba($black, 0.2);
    border-radius: 7px;
    box-shadow: inset -1px -1px rgba($black, 0.05), inset 1px 1px rgba($black, 0.05);
  }
  &::-webkit-scrollbar-button {
    display: none;
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 14px;
    height: 18px;
    -webkit-appearance: none;
  }
  .table {
    margin-bottom: 6rem;
    table-layout: fixed;
    width: auto;
    th,
    td {
      min-width: 10rem;
      padding-right: $space-unit * 4;
    }

    @media (max-width: 768px) {
      margin-bottom: 3.5rem;
    }
  }
  .custom-table {
    margin-top: 2.4rem;
    margin-bottom: 1.6rem;
    display: flex;
    .text-uppercase {
      padding-bottom: 8px;
    }
  }
  .column {
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem;
    flex-shrink: 0;
    min-width: 10rem;
  }
  .fixed-col {
    padding-right: 2.4rem;
    border-right: 1px solid $grey_line;
    flex-shrink: 0;
    flex-basis: auto;
  }
  .scrollable-area {
    display: flex;
    flex-grow: 1;
    column-gap: 2.4rem;
    padding-bottom: 2.4rem;
    margin-left: 2.4rem;
    @include scrollbar;
  }
  .country-select {
    max-width: 377px;
  }
}
.sizechart-tabs {
  .react-tabs__tab-panel {
    display: none;
    &--selected {
      display: block;
    }
  }
}

.sizechart.measmerizecontainer {
  width: 100%;
  position: relative;
  margin-top: 0;
  padding-top: 0;
  > iframe {
    height: 0;
  }
}

#tmp-measmerize-iframe {
  display: none;
}

.sizechart__mannequin {
  width: 100%;

  &--man,
  &--woman,
  &--kid,
  &--baby,
  &--children, //come fallback
  &--hand,
  &--dog {
    @extend .sizechart__mannequin;
  }

  &--man {
    @include fixedSprite('sizeguide-man');
  }
  &--woman {
    @include fixedSprite('sizeguide-woman');
  }
  &--kid {
    @include fixedSprite('sizeguide-kid');
  }
  &--baby,
  &--children {
    @include fixedSprite('sizeguide-baby');
  }
  &--hand {
    @include fixedSprite('sizeguide-hand');
  }
  &--dog {
    @include fixedSprite('sizeguide-dog');
  }
}

.sizechart__legend {
  span {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    border: $space-line solid black;
    border-radius: 100%;
    text-align: center;
    line-height: 2rem;
    margin-right: $space-unit * 2;
  }

  dd,
  dt {
    break-inside: avoid-column;
  }

  dd {
    margin: ($space-unit * 3) 0 ($space-unit * 6);
  }
}

.sizechart__filters {
  display: flex;
  align-items: center;
  min-height: ($space-unit * 10);
  align-self: baseline;

  // .metricselector {
  //   margin-right: ($space-unit * 12);
  //   list-style-type: none;
  // }
}

.sizechart {
  &.measmerizecontainer {
    font-family: 'MonclerGothic';
    div {
      font-family: 'MonclerGothic';
    }
  }
}
