.video-module.simplified.genius {
  &:not(.full-screen) {
    .video-module__inner-container {
      aspect-ratio: 16 / 9;
    }
  }
  .video-module__title {
    font-size: 6.2rem;
    line-height: 4.96rem;
    margin-bottom: 4rem;
  }
  .video-module__cta button {
    font-size: 1.6rem;
  }
}
