.mbm-entrypoint {
  background: linear-gradient(186.4deg, #000000 32.97%, #616161 160.38%);
  position: relative;
  text-align: center;
  .btn--pause {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    z-index: 1;
  }
  .container {
    position: absolute;
    top: 5rem;
    z-index: 2;
  }
  .logo {
    width: 23rem;
    height: 4.6rem;
    filter: invert(100%);
    margin: 0 auto;
  }
  .links-wrapper {
    margin-top: 2.6rem;
    margin-bottom: 3rem;
  }
  .big-image {
    position: absolute;
    bottom: 0;
    overflow: hidden;
    z-index: 2;
    max-height: 38rem;
    img {
      width: 100%;
    }
  }
  .button--secondary-inverted {
    &:hover {
      color: $white;
      border: 1px solid $white;
    }
  }
}
