.product-tile {
  .product-labels {
    justify-content: space-between;
    :not(:first-child).product-label--inverted.vto-icon {
      font-size: 0;
      border: initial;
      padding: 0;
      &::before {
        left: initial;
        right: -4px;
        top: -4px;
      }
    }
  }
  .selector-color-link {
    padding-left: 1.6rem;
    height: auto;
    padding-top: 1.6rem;
    .selector__menu {
      flex-wrap: wrap;
      row-gap: 1.2rem;
    }
  }
  &--carousel {
    .selector-color-link {
      padding-left: 0;
    }
  }
}
