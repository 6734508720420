.curator-carousel {
  padding: 16rem 0 16rem 0;
  .curator-carousel__title {
    font-size: 9rem;
    padding: 0 7rem;
  }
  .curator-carousel__zoom-container {
    .curator-carousel__zoom-general-slide {
      .zoom-image-wrapper {
        width: 100%;
      }
    }
    .curator-carousel__zoom-arrow-container {
      padding: 0 0.8rem;
    }
  }
}
