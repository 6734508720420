.client-service-content {
  .link-section {
    padding: ($space-unit * 20) 0;
  }
  .link-box__button.button {
    margin-top: ($space-unit * 8);
  }
  .link-box {
    &__desc {
      padding: 0 20%;
    }
  }
}
