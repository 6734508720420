.items-container {
  margin-top: ($space-unit * 4);
  &__exchanging-options {
    margin-bottom: ($space-unit * 10);
  }
  .bag-product {
    border: none;
    flex-wrap: wrap;
    &__reasons-select {
      width: 100%;
      margin-top: ($space-unit * 3);
      font-size: 1.4rem;
      &.reasons-select--inline {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        & > * {
          width: calc(50% - 0.2rem);
        }
        .form-group {
          margin-bottom: ($space-unit * 4);
        }
        .full-width {
          width: 100%;
        }
      }
    }
  }
  .bag-product__image {
    max-width: 11.2rem;
    flex-basis: 11.2rem;
  }
  &.items-container--box {
    .bag-product {
      @include border();
      box-sizing: border-box;
      padding: ($space-unit * 3);
      margin-bottom: ($space-unit * 4);
      cursor: pointer;
      &.bag-product--selected {
        border-color: $black;
      }
      &.bag-product--disabled {
        background-color: $grey_line;
        cursor: default;
        .product-image {
          opacity: 0.5;
        }
      }
    }
    .bag-product__image {
      max-width: 10rem;
      flex-basis: 10rem;
    }
  }

  &.items--form-wrapper {
    .bag-product {
      margin-bottom: 0;
      padding-bottom: 0;
      &:not(:first-child) {
        @include border('top');
        margin-top: ($space-unit * 4);
        padding-top: ($space-unit * 4);
      }
    }
    .form-group {
      &:last-child {
        margin-bottom: 0;
      }
    }
    + .items-step__btn-container {
      margin-top: ($space-unit * 6);
    }
  }

  .bag-product__name-price__name {
    @extend %primary-s-u;
  }

  .bag-product__name-price__price {
    @extend %primary-s-u;
    margin-bottom: ($space-unit * 3);
  }

  .bag-product__content {
    @extend %primary-xs;
    margin-left: ($space-unit * 3);
  }

  + .items-step__btn-container {
    margin-top: ($space-unit * 3);
    margin-bottom: ($space-unit * 10);
  }
}

.bank-step__btn-container {
  margin-bottom: $space-unit * 10;
}

.review-text-holder {
  @extend %primary-xs;

  &__payment {
    margin: ($space-unit * 6) 0;
    display: block;
  }
  &__form-title {
    @extend %primary-s;
    margin: ($space-unit * 6) 0 ($space-unit * 3);
  }
}

.description--reason-summary {
  &:not(:first-child) {
    margin-top: ($space-unit * 4);
  }
  span:last-child {
    opacity: 0.55;
    display: block;
    margin-top: $space-unit;
  }
}

.bag-product__reasons-detail {
  width: 100%;
  margin-top: ($space-unit * 4);
}

.reasons-detail__title {
  @extend %primary-xxs;
  text-transform: uppercase;
  margin-bottom: $space-unit;
}

.return-summary {
  .items-container.items--form-wrapper {
    @include border('bottom');
    padding-bottom: ($space-unit * 4);
    margin-bottom: ($space-unit * 4);
    .bag-product {
      border-top: none;
      &:not(:first-child) {
        padding-top: 0;
        margin-top: ($space-unit * 6);
      }
    }
  }
  &__title {
    @extend %primary-s-u;
    margin-bottom: ($space-unit * 4);
  }
  &__returns-general-info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include border('bottom');
    margin-bottom: ($space-unit * 10);
  }

  .returns-general-info {
    &__column {
      width: 50%;
      text-align: left;
      margin-bottom: ($space-unit * 4);
      @extend %primary-xs;
      padding-right: 1rem;
      overflow: hidden;
      .dt {
        display: block;
        @extend %primary-xxs;
        text-transform: uppercase;
        margin-bottom: $space-unit;
      }

      .dd {
        display: block;
        font-weight: 300;
        @extend %primary-xs;
      }
    }
  }
}

.info-modal {
  &.modal {
    min-width: 45rem;
    /* width: 45.rem; no, questo forza a 450 tutte le modali */
    max-width: 100%;
  }
  .checkout-address-suggestion {
    padding-top: 4.2rem;
  }
  &.exchange-modal {
    .info-modal__single-column {
      display: inline-flex;
      span {
        width: 100%;
      }
    }
  }
  &__description {
    padding-bottom: ($space-unit * 6);
    @include border('bottom');
    margin-bottom: ($space-unit * 6);
    @extend %primary-xs;
  }

  &__single-column {
    width: 50%;

    span {
      display: block;
      margin-top: $space-unit;

      &:first-child {
        margin-bottom: ($space-unit * 3);
      }
    }

    &.right {
      text-align: right;
    }
    &.title {
      @extend %primary-xs-u;
    }
  }
}

.no-order-found {
  padding-top: 1.6rem;
  padding-bottom: 7.2rem;
  .button {
    width: 22.6rem;
    margin: 0 auto;
    display: flex;
  }
}

.items-container__reason-select {
  margin-top: ($space-unit * 4);

  .form-group {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.2rem;
  }

  .radio {
    margin-right: ($space-unit * 4);
    display: flex;
    align-items: center;
    margin-bottom: 0 !important; // todo properly fix spare in form-radio.default for horizontal radios

    input[type='radio'] {
      margin-top: -0.2rem;
    }
  }
}
.items-container__advice {
  @extend %primary-xs;
  color: $black_secondary;
}

.items-step__changeyourmind {
  margin-bottom: $space-unit * 4;
  margin-top: $space-unit * 4;
  p {
    margin-bottom: $space-unit * 4;
  }
}

.items-step__btn-container {
  margin-bottom: $space-unit * 8;
  display: flex;
  justify-content: space-between;
  .button {
    max-width: 34rem;
    margin: 0;
  }
}

.orderlisting {
  &-item {
    border: 1px solid $grey_line;
    width: 100%;
    padding-left: $space-unit * 4;
    padding-right: $space-unit * 4;
    margin-bottom: $space-unit * 4;
    &__selected {
      border: 1px solid $black;
    }
    &__errormessage {
      text-align: left;
      color: $status_error;
      margin-bottom: $space-unit * 4;
      button {
        color: $status_error;
      }
    }
  }
}
