.text-on-image {
  &__image {
    img {
      left: ($space-unit * 4);
      top: ($space-unit * 4);
      right: ($space-unit * 4);
      bottom: ($space-unit * 4);
      width: calc(100% - (#{$space-unit} * 8));
      height: calc(100% - (#{$space-unit} * 8));
    }
  }

  &__text {
    left: ($space-unit * 8);
    bottom: ($space-unit * 8);
  }

  &--two-images {
    &::before {
      padding-top: 300%;
    }
    .text-on-image {
      &__image {
        width: 100%;
        height: 50%;
        &:nth-child(2) {
          top: 50%;
        }
      }
      &__text {
        left: ($space-unit * 4);
        bottom: ($space-unit * 4);
      }
    }
  }
}
