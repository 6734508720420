.page-template-technicaldetails {
  overflow-y: scroll;
  // override
  --viewport-height: 100vh;
  .spacer-mobile {
    height: 100vh;
    width: 1px;
    position: absolute;
    z-index: -1;
  }
  main {
    padding-top: $contextual-navigation-m + $header-height-mobile;
    --fontextralight: MonclerGothic;
  }
  .contextual-nav {
    #technical-back {
      display: none;
    }
    #shop-now-button {
      background-color: $black;
      padding: 0.5rem 1.2rem;
      color: $white;
    }
    > .button:not(:last-child) {
      margin-right: 1.2rem;
    }
    .button {
      color: $black_secondary;
      &.active {
        pointer-events: none;
      }
      &.active,
      &.always-active {
        color: $black;
      }
    }
  }
  .custom-anchor {
    position: absolute;
    margin: 0;
    left: 0;
    top: -#{$contextual-navigation-m};
    display: block;
    width: 100%;
    height: $contextual-navigation-m;
  }
  .section-wrapper {
    position: relative;
    // &:not(:nth-child(2)):not(:nth-last-child(-n + 2)) {
    //   section {
    //     height: 200vh !important;
    //     padding-bottom: 100vh !important;
    //   }
    // }
  }
  section {
    background: white;
    $viewportHeight: calc(var(--viewport-height) - #{$contextual-navigation-m});
    min-height: $viewportHeight;
    // margin-bottom: 0 !important;
  }

  .technical-details__wrapper {
    position: relative;
    overflow: hidden;
    .section-wrapper:last-child {
      section:not(.component_complete_the_look) {
        max-height: calc(var(--viewport-height) - #{$contextual-navigation-m});
      }
    }
  }

  &.editmode {
    section {
      margin-bottom: 0 !important;
    }
    .component_product_key_technical_details {
      .product-key-technical-details__background {
        position: absolute;
      }
      .key-details-container {
        background-size: cover;
        background-attachment: fixed;
      }
    }
    .key-step {
      position: relative;
      border-bottom: 1px solid;
    }
    .component_product_intro__content {
      opacity: 1;
      transform: initial;
    }
    .component_product_range_carousel {
      .swiper-wrapper {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 0.4rem;
      }
    }
    .component_complete_the_look {
      .complete-the-look-slides {
        @include reset-list;
      }
    }
  }
}
body.has-ticker {
  &.page-template-technicaldetails {
    main {
      padding-top: $contextual-navigation-m + $header-height-mobile + $ticker-bar-m;
    }
  }
}
