#addresses, #payments {
  .swiper-container {
    &.address-center {
      .swiper-wrapper {
        justify-content: center;
      }
    }
  }
}

// modal size
.modal--fixed {
  &.personalinfo-edit-modal, &.addressbook-edit-modal {
    width: 100%;
    max-width: calc(83.8% - 56px);
    min-width: initial;
  }

  &.change-field-modal, &.modal-width, &.delete-account {
    width: 100%;
    max-width: calc(50% - 30px);
    min-width: initial;
  }
}

#privacysettings {
  .checkbox-wrapper {
    .newsletter-flag {
      .text-normal {
        flex-direction: row;
        column-gap: 32px;
      }
    }
  }
}