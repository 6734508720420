header {
  .container-submenu {
    padding: ($space-unit * 16) ($space-unit * 8) ($space-unit * 4);
    .main-submenu-wrapper {
      .highlights-submenu {
        margin-right: clamp(1.6rem, 7vh, 8rem);
      }
    }
  }
  .banners-submenu {
    grid-template-columns: repeat(2, 50%);
    .experience-menu_banner,
    .experience-menu_banner:last-child {
      padding: 0;
    }
    .menu-assets {
      .banner_text {
        .button-wrapper {
          margin-top: 2.5rem;
        }
      }
    }
  }
}
