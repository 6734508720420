.store-details {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // height: 100%;

  .name {
    @extend %primary-s;
    margin-bottom: $space-unit;
  }

  .address,
  .address2,
  a {
    @extend %primary-xs;
  }

  .email,
  .telephone {
    margin-bottom: $space-unit;
    @include hoverablelink;
    &:first-of-type {
      margin-top: ($space-unit * 3);
    }
  }

  .detail-title {
    @extend %primary-xxs-u;
    margin-bottom: $space-unit;
  }

  &__hours {
    dl {
      display: flex;
      flex-wrap: wrap;
      width: 20rem;
    }
    dd,
    dt {
      width: 50%;
    }
  }
}
