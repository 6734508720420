#addresses, #payments {
  .swiper-container {
    .carousel-controls {
      display: none;
    }
  }
}

#personalinfo_rpy {
  padding-bottom: 88px;
}
