.editorial-text {
  &--4x1 {
    grid-column: span 4;
  }
  &__left {
    .editorial-text {
      &__description {
        max-width: 66%;
      }
    }
  }
  &__link {
    text-decoration: none;
    position: relative;
  }
  // &__picture {
  //   max-width: 22%;
  //   max-height: 66%;
  // }
}
