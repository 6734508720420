.collection-intro {
  &__name {
    font-size: 3.2rem;
    line-height: 3.8rem;
  }
  &__content {
    .with-image & {
      margin-top: -8.2rem;
    }
  }
  &__links {
    .collections-intro__tab {
      .collection-intro__link-wrapper {
        .with-image & {
          width: calc(100vw - 6rem);
        }
      }
    }
  }
}
.collection-intro-sticky {
  &__heading {
    // justify-content: flex-start;
    &--desktop {
      display: flex;
    }
  }
  &__btn {
    display: none;
  }
}
