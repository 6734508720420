.curator-carousel {
  padding: 6rem 0 7rem 0;
  .curator-carousel__title {
    font-size: 6rem;
  }
  .curator-carousel__description {
    font-size: 1.8rem;
    margin: 0 4.5rem 4.5rem 4.5rem;
  }
  .curator-carousel__carousel-wrapper {
    .swiper-wrapper {
      margin-left: 0;
    }
  }
}
.curator-carousel__zoom-container {
  .curator-carousel__zoom-general-slide {
    .zoom-image-wrapper {
      width: 100%;
    }
    .curator-carousel__zoom-arrow-container {
      padding: 0 0.8rem;
    }
  }
}
