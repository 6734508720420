.cs-editorial-video {
  width: 83.3333%;
  margin: 6.1rem auto;

  &:before {
    content: '';
    padding-top: calc(133.3333% + 8.1rem);
  }
  .hero-promo-video {
    &__title {
      font-size: 3.2rem;
      line-height: 3.8rem;
    }
    &__cta-wrapper {
      flex-direction: row;
    }
    &__cta {
      min-width: 1.54rem;
      width: auto;
    }
  }
}
