.editorial-multi-text {
  display: flex;
  @include appearAnimation();
  .btn--pause {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    z-index: 1;
  }
}

.editorial-multi-text__inner-container {
  width: 100%;
}

.editorial-multi-text--inverted {
  flex-direction: row-reverse;
}
.editorial-multi-text__img-column {
  width: 50%;
  display: block;
  overflow: hidden;
  position: relative;
  img {
    left: 0;
    top: 0;
    width: 100%;
    position: absolute;
    max-width: 100vw;
    object-fit: cover;
    right: 0;
    bottom: 0;
    height: 100%;
  }
}

.editorial-multi-text__btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  &.at-bottom {
    .editorial-multi-text__cta {
      margin-top: 1.6rem;
      margin-bottom: 0;
    }
  }
}

.editorial-multi-text__text-column {
  width: 50%;
  padding: 7.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  &.mbm-promo {
    position: relative;
    > i {
      height: 4.8rem;
      width: 21.5rem;
      position: absolute;
      top: 4.8rem;
      left: 50%;
      transform: translateX(-50%);
    }
    .editorial-multi-text__title {
      padding-bottom: 8px;
      font-size: 24px;
      line-height: 28px;
    }
    .editorial-multi-text__body {
      padding-bottom: 2.4rem;
    }
    .editorial-multi-text__btn-container .button {
      width: auto;
      white-space: nowrap;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .editorial-multi-text__btn-container .button.button--tertiary.button--arrow-right {
      position: static;
      min-height: 3.4rem;

      &::after {
        position: static;
        transform: translate(0);
      }
    }

    &.background--black {
      background: linear-gradient(180deg, rgba(63, 63, 63, 0) 0%, rgba(63, 63, 63, 0.4) 100%);
    }
  }
  .editorial-multi-text__quote {
    padding-bottom: 7rem;
  }
}

.editorial-multi-text__body {
  @extend %primary-xs;

  &.body--justified {
    text-align: justify;
  }
}

.editorial-multi-text__title {
  display: block;
  text-transform: uppercase;
  padding-bottom: ($space-unit * 3);
}

.editorial-multi-text__label {
  display: block;
  @extend %primary-xs;
  text-transform: uppercase;
  margin-bottom: ($space-unit * 2);
}

.editorial-multi-text__cta {
  .button {
    color: $black;
    font-size: 1.6rem;
  }
  margin-bottom: ($space-unit * 8);
}

.text-column--mode-white {
  background: $white;
  * {
    color: $black !important;
  }
}

.text-column--mode-black {
  background: $black;
  * {
    color: $white !important;
  }
}

.cta-position-- {
  &on-top,
  &under,
  &on-image {
    display: none;
  }
}

.title-position-- {
  &on-top,
  &on-image,
  &under {
    display: block;
  }
}

.editorial-multi-text__title-on-top {
  display: none;
}

.editorial-multi-text__title-on-image {
  display: none;
}

.all-caps {
  text-transform: uppercase;
}
