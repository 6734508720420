.myfeed-container {
  margin-top: 40px;

  .hero-column__content-container {
    padding: 0 38px;
  }
  .hero {
    .hero-column__title-visible {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 16px;
    }
    .hero-column__title {
      margin-bottom: 16px;
    }
    .hero-column__label {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 8px;
    }
  }
  .themed-products__title {
    margin-top: 0;
  }

  .benefits-section {
    background-color: #fbfbfb;
    margin-top: -40px;
    padding: 40px 32px;
  }
  .hero-carousel.hero--hero-with-columns {
    aspect-ratio: 21/9;
  }
  .hero-carousel__main-wrapper,
  .swiper-container {
    aspect-ratio: 21/9;
    overflow: hidden;
  }
}

.myfeed-cta-nocursor {
  cursor: default;
}

.myfeed-mainTitle {
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
}

.myfeed-containerImg {
  aspect-ratio: 456/195;
}

.myfeed-image {
  object-fit: cover;
  width: 100%;
  height: 100%;

  &-placeholder {
    width: 100%;
    height: 100%;
    background-color: $grey_skeleton;
    animation: fadeInOut 2s infinite;
  }

  @keyframes fadeInOut {
    0% {
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.3;
    }
  }
}

.myfeed-TitleCarouselControlsContainer {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.myfeed-TitleCarouselControlsContainer :first-child {
  margin-inline: auto;
}

.myfeed-title {
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 12px;
  margin-top: 16px;
}

.myfeed-description {
  font-weight: 300;
  size: 16px;
  line-height: 20px;
  margin-bottom: 12px;
  padding-inline: 28px;

  p {
    font-size: 16px;
    line-height: 20px;
  }
}

.myfeed-cta {
  font-size: 16px;
  line-height: 20px;
  position: relative;
  text-decoration: none;
}

.icon--arrow-left-rpy {
  @include fixedSprite('arrow-left-black');
}

.icon--arrow-right-rpy {
  @include fixedSprite('arrow-left-black');
  transform: scaleX(-1);
}

.myfeed_carousel-controls {
  position: absolute;
  left: calc(100% - 72px);
  width: max-content;

  &.d-none {
    display: none;
  }

  i {
    width: 2.4rem;
    height: 2.4rem;

    &[class*='icon--arrow-left-rpy'] {
      @include fixedSprite('arrow-left-black');
      transform: scale(1.5);
    }

    &[class*='icon--arrow-right-rpy'] {
      @include fixedSprite('arrow-left-black');
      transform: scale(1.5) scaleX(-1);
    }

    &.swiper-button-disabled {
      opacity: 0.4;
    }

    &:last-child {
      margin-left: 24px;
    }
  }
}

.my-feed-exclusive-products-wrapper {
  .themed-products__title.mb-19 {
    margin-bottom: 19px;
  }

  .tabs.tabs--products {
    display: flex;
    flex-direction: column;
    align-items: center;

    .react-tabs__tab-panel {
      width: 100%;

      &--selected {
        min-height: clamp(300px, 35vw, 500px);
      }
    }
  }

  .tabs {
    .react-tabs__tab-list {
      justify-content: center;
      gap: 24px;
      margin-bottom: 24px;
      padding: 0;
      border-bottom: 1px solid $grey_line;

      &::before {
        content: none;
      }

      .react-tabs__tab {
        font-size: 14px;
      }
    }
  }
}

.account-dashboard {
  .experience-region {
    margin-left: -32px;
    margin-right: -32px;
  }
}
