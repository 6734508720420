.order-confirm {
  .d-sm-none {
    display: none;
  }
  .mobile-mb-20 {
    margin-bottom: 2rem;
  }
  .text-section {
    padding: 1.6rem 0;
  }
  .wrapper-info {
    padding-top: 1.6rem;
  }
}
