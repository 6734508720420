.editorial-text--4x1.genius {
  color: $white;
  .editorial-text__text-wrapper {
    padding: 8rem 1.6rem;
  }
  .editorial-text__title {
    text-transform: none;
    font-family: 'BlockGothicRR';
    font-size: 5.4rem;
    line-height: 4.644rem;
    margin-bottom: 1.6rem;
  }
  .editorial-text__description {
    margin-bottom: 0;
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
  .editorial-text__link {
    color: $white;
    border: 1px solid $white;
    padding: 1.2rem 5.7rem;
    margin-top: 2.4rem;
    width: 100%;
  }
}
