.component_product_intro {
  &__image-wrapper {
    .component_product_intro__image {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: bottom;
    }
  }
}
