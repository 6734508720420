.contact-details {
  &__dropdown-container {
    margin-bottom: 3.4rem;
  }
  &__description {
    .contact-details--enhanced & {
      font-size: 2rem;
    }
  }
  .call {
    &:hover {
      opacity: 0.6;
    }
  }
}

// PDP MODAL - CONTACT US

.contactus-modal {
  &.modal {
    padding: 0 8.2rem 10.5rem;
    min-width: 918px;
  }
  .modal__header {
    padding-top: 1.2rem;
  }
  .dialog__title {
    margin-top: 4rem;
  }

  .contact-us__desc.container {
    padding-bottom: 4rem;
    .col-md-7 {
      max-width: 46rem;
    }
  }

  .contact-details {
    &__contacts-contact {
      flex-direction: row;
      &:last-child {
        padding-bottom: 4rem;
      }

      .col-sm-6 {
        flex-basis: 50%;
        max-width: 50%;
        padding-bottom: 0;
      }
      .col-sm-5 {
        flex-basis: 33.3333333333%;
        max-width: 33.3333333333%;
      }
    }
    &__dropdown-container {
      padding-bottom: 3.6rem;
      margin-bottom: 0;
    }
  }
}
