.curator-image-time {
  &.time {
    .container-curator-time {
      height: 8.2rem;
      .curator-time {
        color: white;
        position: absolute;
        left: 16%;
        font-family: $silkSerifLight;
        font-style: normal;
        font-weight: 300;
        font-size: 150px;
        line-height: 128px;
      }
    }
  }
}
