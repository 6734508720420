.intro-plp {
  &.results-row.image-bg {
    min-height: 48rem;
    .plp-heading__collection-logo {
      height: 8rem;
      margin-bottom: 4rem;
    }
    .intro-plp__breadcrumbs {
      transform: none;
    }
  }

  &__image {
    height: 100%;
  }

  &__text-tags-wrapper {
    flex-direction: row;
    padding-bottom: 0;
  }
  &__tags-container {
    justify-content: flex-end;
    flex-wrap: wrap;
    &::after {
      content: none;
    }
  }
  &__tags-list {
    margin-top: 2rem;
  }
  &__tags-list {
    display: block;
    text-align: right;
    white-space: normal;
  }
}
