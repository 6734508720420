.curator-newsletter-container {
  padding-bottom: 10rem;
  .curator-newsletter {
    padding: 4rem;
    .curator-newsletter__title {
      margin-bottom: 0.8rem;
    }
    p {
      max-width: 55rem;
      margin: 0 auto 3.2rem auto;
    }
  }
}
