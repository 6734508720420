#page-client-service {
  .myaccount-hero:not(.hero-skinny) {
    height: 21rem;
    .myaccount-hero__title {
      margin-bottom: 7.2rem;
    }
  }
}

.image-background--whole,
.image-background--top,
.image-background--bottom {
  .myaccount-hero__title,
  .hero-search__search-container {
    z-index: 3;
  }
}

#page-client-service-redesign {
  --stickyHeaderHeight: $header-height-mobile;
  body.has-ticker {
    --stickyHeaderHeight: $header-height-mobile + $ticker-bar-m;
  }
  .hash-anchor {
    position: relative;
    &__absolute-item {
      position: absolute;
      margin-top: --stickyHeaderHeight;
    }
  }
}

.client-service-content {
  .image-wrapper {
    padding-top: ($space-unit * 7);
  }
  .image-box {
    margin-bottom: $space-unit;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      max-width: 100vw;
    }
    a {
      display: block;
      position: relative;
      @include hover(0.2);

      &:focus {
        outline: none;
        .image-box__title {
          @include focus-underline;
        }
      }
    }
    &__title {
      @extend %primary-l;
      color: $white;
      text-align: center;
      text-transform: uppercase;
      width: 75%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include z;
    }
    &__image {
      width: 100%;
    }
  }
  .link-section {
    padding: ($space-unit * 16) 0;
  }
  .link-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    &__title {
      @extend %primary-l-u;
      margin-bottom: ($space-unit * 4);
    }
    &__desc {
      padding: 0 5%;
    }
    &__button.button {
      margin-top: ($space-unit * 4);
      width: auto;
      padding-left: 4.2rem;
      padding-right: 4.2rem;
      &::before {
        margin-top: -($space-unit);
      }
    }
  }
}

.client-service,
.cs-form-container {
  .accordion-step {
    .step__header {
      &.is-done {
        color: $black;
      }
    }
  }
  .orders-listing {
    .accordion__item {
      .accordion__header {
        // TODO REFACTOR: this is not an accordion
        @include border();
        padding-left: ($space-unit * 4);
        padding-right: ($space-unit * 4);
        margin-bottom: ($space-unit * 4);
        &.header--selected {
          border-color: $black;
        }

        &::after,
        &::before {
          right: ($space-unit * 4);
        }

        .header__close,
        .header__open {
          top: 1.6rem;
          right: 0;
          min-height: initial;
          height: 1.6rem;
        }
      }
    }
  }
  &__section {
    margin-bottom: ($space-unit * 12);
    margin-top: ($space-unit * 10);
    &.track-anotherone {
      margin-top: ($space-unit * 20);
      margin-bottom: ($space-unit * 16);
    }
  }
  .client-service {
    &__column-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;
      &.justify-end {
        justify-content: flex-end;
      }
      > * {
        width: calc(50% - 1rem);
      }
    }
  }

  &__modal {
    &--offline {
      &.modal {
        top: 50%;
        height: auto;
        &__close {
          position: absolute;
        }
      }
      &__time {
        font-weight: bold;
        margin-top: 1.6rem;
      }
    }
    &--enhanced {
      .modal__header {
        height: 4rem;
      }
    }
  }

  .section {
    &__title {
      @extend %primary-m-u;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: ($space-unit * 3);
    }
    &__description {
      @extend %primary-xs;
      margin-bottom: ($space-unit * 4);
    }
    &--border-bottom {
      @include border('bottom');
      margin-bottom: ($space-unit * 4);
    }
    &--no-margin {
      margin: 0;
    }
    &--little-snippet {
      display: flex;
      align-items: center;
      text-align: left;
      justify-content: space-between;
      .button {
        width: 22.5rem;
        min-width: 22.5rem;
        max-width: 100%;
      }

      > div {
        width: 34rem;
        max-width: calc(100% - 26rem);
      }

      .section {
        &__title {
          text-align: left;
        }
      }
      + .single-banner {
        margin-top: ($space-unit * 10);
      }
    }
    &--intro-section {
      .section {
        &__description {
          text-align: center;
          margin-bottom: ($space-unit * 6);
        }
        .client-service {
          &__column-wrapper {
            & > * {
              width: calc(50% - 0.2rem);
            }
          }
        }
      }
    }
    &--title-only {
      @include border('bottom');
      padding-bottom: ($space-unit * 6);

      .section__title {
        margin-bottom: 0;
      }
    }
  }
  .tracking-list {
    margin-bottom: ($space-unit * 10);
    &__title {
      text-align: center;
      @extend %primary-m-u;
      margin-bottom: ($space-unit * 3);
    }
    &__description {
      text-align: center;
      @extend %primary-s;
      margin-bottom: ($space-unit * 6);
    }
  }
  .tabs {
    .react-tabs__tab-list {
      justify-content: center;
      column-gap: 2rem; // 2rem + 0.4rem of tabs other than the first = 2.4rem total as per design
      &:before {
        content: none;
      }
      .react-tabs__tab {
        flex: 0;
      }
    }
  }
}
.cs-form-steps {
  &-tab {
    display: none;
    &.active {
      display: block;
    }
  }
}
.tracking-item {
  margin-bottom: ($space-unit * 2);
  @include border();
  height: ($space-unit * 16);
  align-content: center;
  &__item {
    text-align: left;
    padding-left: ($space-unit * 4);
    padding-right: ($space-unit * 4);
  }
  &__title {
    margin-bottom: $space-unit;
    text-transform: uppercase;
  }
}

.client-service-search,
.hero-search__search-container {
  position: relative;
  &[data-search-active] {
    + .client-service-content {
      display: none;
    }
  }
  &[data-search-active='sugg'] {
    .faq_suggestions {
      visibility: visible;
      height: auto;
      padding-bottom: ($space-unit * 6);
    }
  }
  .search-wrapper {
    position: relative;
    width: 100%;
  }
  .faq_suggestions {
    visibility: hidden;
    height: 0;
  }
  .faq-search {
    position: relative;
  }
  .search-input {
    position: absolute;
    width: 100%;
    bottom: calc(100% + (#{$space-unit} * 6));

    &::before {
      content: '';
      @include fixedSprite('search');
      position: absolute;
      left: 0.6rem;
      top: 50%;
      transform: translateY(-50%);
      @include z;
    }
    input {
      @extend %primary-s;
      height: 4.8rem;
      padding: 0;
      border: none;
      padding-left: 3.4rem;
      padding-right: 3.4rem;
      width: 100%;
      appearance: initial;
      -webkit-appearance: initial;
      border-radius: initial;
    }
    button {
      position: absolute;
      right: 0.6rem;
      top: 1.2rem;
    }
  }
  .faq-component {
    > button {
      text-transform: uppercase;
    }
    .title {
      margin-top: ($space-unit * 16);
      margin-bottom: ($space-unit * 6);
      + button {
        margin-bottom: 2.4rem;
      }
    }
    .details {
      margin-bottom: ($space-unit * 16);
    }
    .subtitle {
      margin-bottom: ($space-unit * 10);
    }
  }
}

.hero-search {
  &[data-search-active] {
    ~ .client-service-content {
      display: none;
    }
  }
  &[data-search-active='sugg'] {
    .faq_suggestions {
      visibility: visible;
      height: auto;
      padding-bottom: ($space-unit * 6);
    }
  }
  &__search-container {
    position: static;
    width: 100%;
    padding: 0 8rem;
    .search-input {
      position: relative;
      max-width: 68.6rem;
    }
    .search-wrapper {
      position: static;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    [data-react='client-service-landing'],
    [data-react='faq-search-input'] {
      width: 100%;
      max-width: 68.6rem;
    }
    .faq-search {
      position: static;
    }
    .faq-component {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      z-index: 1;
      padding: 0 1.6rem;
      margin: 0 auto;
      &__container {
        @extend .col-sm-8;
        @extend .col-lg-6;
      }
    }
  }
}

.faq-component {
  &__container {
    text-align: center;
    .faq-component & {
      .faq-results & {
        padding-top: 4rem;
      }
    }
  }
}

.cs {
  &__tag-list {
    display: flex;
    row-gap: 1.5rem;
    column-gap: 1.5rem;
    flex-wrap: wrap;
    justify-content: center;
    padding: 3.2rem 0 0;
  }
  &__tag-item {
    list-style: none;
  }
  &__tag-link {
    display: inline-block;
    background-color: $white;
    height: 3.2rem;
    padding: 0.8rem 1.6rem;
    border: 0.1rem solid $grey_line;
    text-decoration: none;
    text-transform: uppercase;
  }
}

.cs-form-container {
  padding-top: 4rem;
  background-color: #fbfbfb;
  padding-bottom: 9rem;
  scroll-margin-top: 20vh;
  .container-button-back {
    margin: auto;
    margin-top: 4rem;
  }
}

.myaccount-content {
  .return-logged {
    padding-top: 80px;
  }
}
