.login-registration {
  margin-top: $space-unit * 12;
  margin-bottom: $space-unit * 16;
  .main-container {
    justify-content: center;
    // margin-bottom: $space-unit * 2;
  }
  &__login {
    .error-input {
      input {
        border-bottom: 1px solid $status_error;
      }
    }
  }
  // &__login {
  //   margin-right: $space-unit * 2;
  // }

  &__social {
    border-top: 1px solid $grey_line;
    margin-top: 4.8rem;
    padding-top: 4.8rem;
    text-align: center;
    .button {
      margin-bottom: 1.6rem;
    }
  }
  &__wallet-connect {
    border-top: 1px solid $grey_line;
    margin-top: 1.6rem;
    padding-top: 3.2rem;
    text-align: center;
    .wallet-connect__btn {
      text-decoration: underline;
    }
  }
  .send-email-clothing {
    .link {
      margin-top: 0;
    }
  }
  .link {
    margin-top: 1.2rem;
  }

  &__join-moncler {
    border: 1px solid $grey_line;
    padding: 2.4rem;
    .register-list {
      list-style: none;
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      padding: 0;
      li {
        padding: 0;
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        img {
          margin-right: 0.4rem;
          width: 2.4rem;
        }
      }
    }
  }

  .forgot-pw-error {
    margin-top: -($space-unit * 2);
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    // invalid selected commented out
    // & > {
    //   flex-basis: 50%;
    //   max-width: 50%;
    // }

    &__pw {
      @extend %primary-xxs;
      white-space: nowrap;
      margin-right: ($space-unit * 4);
    }

    &__error {
      .invalid-feedback {
        text-align: right;
        margin-top: 0;
        margin-bottom: $space-unit * 3;
      }
    }
  }

  .formfields {
    margin-top: ($space-unit * 6);

    &.has-password-error {
      input[type='password'] {
        border-bottom-color: $status_error;
      }
    }

    .form-control {
      margin-bottom: ($space-unit * 3);
    }

    .privacy-link {
      color: $black_secondary;
      text-decoration: none;
      margin-left: ($space-unit * 4);
      position: relative;
    }
  }

  .login-registration__registration {
    // margin-left: $space-unit * 2;
    position: relative;
    height: 100%;

    // .button {
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    // }
  }

  .login-registration__title {
    @extend %primary-s;
    margin-bottom: $space-unit * 4;
  }

  .login-tab {
    .tabs {
      // .react-tabs__tab {
      //   &:nth-child(1) {
      //     margin-right: 0.2rem;
      //     max-width: calc(50% - 0.2rem);
      //   }
      //   &:nth-child(2) {
      //     margin-left: 0.2rem;
      //     max-width: calc(50% - 0.2rem);
      //   }
      // }
      .react-tabs__tab-panel {
        margin-top: ($space-unit * 8);
      }
    }
  }
  &.in-modal {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0;
    .invalid-feedback.globalerrors {
      margin-top: 0;
    }
  }
  .login-registration__join-moncler {
    margin-top: 4.8rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
.registered-in-store {
  .link {
    margin-top: 0;
  }
}
.registered-in-store-after-email {
  margin: auto;
}
.registered-in-store-send-email {
  .link {
    margin-top: 0;
  }
  .row {
    justify-content: space-between;
    align-items: flex-start;
    row-gap: 1.2rem;
  }
}
.login-registration__login__social {
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  column-gap: 2.7rem;
  row-gap: 2.4rem;
  margin-top: ($space-unit * 6);
  margin-bottom: ($space-unit * 4);
  a {
    display: block;
  }
}
.login-modal-buttons {
  margin-top: 1.6rem;
}
.remember-me {
  display: flex;
  margin-top: ($space-unit * 4);
  margin-bottom: ($space-unit * 4);
  justify-content: space-between;
  align-items: flex-start;
  .privacy-link {
    margin-top: $space-unit;
  }
}

.forgot-pw {
  @extend %primary-xxs;
  display: block;
  margin-top: $space-unit;
  text-decoration: initial;
}

.account-dropdown {
  .forgot-pw-error__pw {
    display: flex;
    align-items: baseline;
    margin-top: -($space-unit * 2);
    .forgot-pw {
      @extend %primary-xxs;
      display: block;
      margin-top: $space-unit;
      text-decoration: initial;
    }
  }
}

.social-button {
  [class*='icon--'] {
    margin-right: $space-unit;
  }
}

.login-register-social.links-view {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.2rem;
  row-gap: 0.8rem;
  margin: 2.4rem 0;
}

.other-login-methods {
  margin-top: 2.4rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
}

.login-register-social {
  position: relative;
  .loading-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $white;
    z-index: 2;
  }
}

.loading-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.4rem;
  padding: 1.6rem 0 4rem;
  min-height: 8rem;

  img {
    height: 2.5rem;
    width: 2.5rem;
  }
  h4 {
    font-size: 1.4rem;
    text-transform: uppercase;
  }
}
