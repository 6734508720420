.selector-color {
  .selector__menu {
    flex-direction: column;
    align-items: flex-start;
    bottom: initial;
    //padding-top: 3.8rem;

    .label {
      @include reset-visually-hidden();
      padding-top: 0.3rem;
      padding-bottom: 2rem;
    }

    .selector-wrapper,
    ul {
      flex-direction: column;
      align-items: flex-start;

      .selector__menu__item,
      li {
        &:first-child {
          margin-left: 0;
        }
      }
    }

    &__item {
      padding-bottom: 2rem;

      &__label {
        padding-right: $space-unit * 2;
      }
    }
  }
}
