.component_grenoble_performance {
  $viewportHeight: calc((var(--viewport-height) - #{$contextual-navigation}) * 2);
  height: $viewportHeight;
  padding-bottom: calc(var(--viewport-height) - #{$contextual-navigation});
  //display: flex;
  //align-items: center;
  text-align: left;
  &__title-wrapper {
    top: auto;
    position: relative;
    padding-top: 8rem;
    padding-bottom: 0;
    .title {
      padding-bottom: 4.8rem;
      &:before {
        display: none;
      }
    }
  }
  &__image-wrapper {
    order: 2;
    position: relative;
    top: initial;
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
    img {
      position: absolute;
      top: 50%;
      transform: translateY(-55%);
      max-height: 70vh;
      right: 0;
    }
  }
  &__info-wrapper {
    margin-top: 0;
    .info-row {
      &:not(:last-child) {
        padding-bottom: 2.8rem;
      }
      .logo-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .logo {
        margin-bottom: 0;
        padding-right: 2.4rem;
      }
      p {
        font-size: 1.6rem;
      }
    }
  }
  .experience-performance_info {
    margin-bottom: 0;
  }
}

// section.grenoble-performance {
//   height: calc(100vh - #{$contextual-navigation});
//   margin-bottom: 50vh;
//   display: flex;
//   align-items: center;
// }
