.component_product_key_technical_details {
  $viewportHeight: calc(var(--viewport-height) - #{$contextual-navigation-m});
  color: $white;
  overflow: hidden;
  position: relative;
  .progress-bar {
    border-top: 0.5px solid $black;
    border-bottom: 0.5px solid $black;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $space-unit;
    -webkit-appearance: none;
    appearance: none;
    background: $white;
    z-index: 1;
    &::-webkit-progress-bar {
      background: $white;
    }
    &::-webkit-progress-value {
      background: $black;
    }
    &::-moz-progress-bar {
      background: $black;
    }
  }
  .product-key-technical-details__background {
    position: relative;
    width: 100%;
    height: 100vw;
    object-fit: cover;
    object-position: center bottom;
  }
  .product-key-technical-details__gif {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    mix-blend-mode: screen;
  }
  .product-key-technical-details-text {
    text-align: center;
  }
  .product-key-technical-details-hero {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;

    .image-wrapper {
      position: relative;
    }

    .product-key-technical-details-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      padding: 4rem 0;
    }
    .key-details-container {
      margin-top: 4rem;
    }
    .key-detail-bar-container {
      font-size: 1.6rem;
      margin-bottom: 2.2rem;
      display: flex;
      flex-flow: wrap;
      justify-content: space-between;
      .key-detail-name {
        text-transform: uppercase;
      }
      .key-detail-bar {
        height: 4px;
        margin-top: 10px;
        border: 0.5px solid $white;
        position: relative;
        width: 100%;
      }
    }
    .key-detail-bar-full {
      --percentage: 0;
      position: absolute;
      height: 3px;
      background: $white;
      width: 0;
      transition: width 2s ease;
    }
    &.animate {
      .key-detail-bar-full {
        width: var(--percentage);
      }
    }
  }
  .product-key-part__title {
    @extend %primary-xxl;
    text-transform: uppercase;
    margin-bottom: 2.4rem;
  }
  .product-key-part__desc {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .grenoble-details {
    margin-top: 4.8rem;
    padding: 0;
    justify-content: space-between;
    &.less-items {
      justify-content: space-evenly;
      .grenoble-details__item {
        margin-right: 3.2rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &.two-rows {
      justify-content: center;
      .grenoble-details__item {
        width: 30%;
      }
    }
    &__item {
      margin: 0;
      span {
        padding-top: 1.2rem;
      }
      > div[class^='icon'] {
        filter: invert(1);
      }
    }
  }

  .key-step {
    position: absolute;
    height: $viewportHeight;
    min-height: $viewportHeight;
    width: 100%;
    top: 0;
  }

  .key-steps {
    .key-step:not(:first-child) {
      opacity: 0;
    }
  }

  .product-key-part-text,
  .product-key-part-image {
    position: relative;
  }

  .product-key-part-image {
    .container {
      height: 100%;
      > .row {
        height: inherit;
      }
    }
    img {
      display: block;
      height: 100%;
      width: auto;
      max-width: none;
    }
  }
  .product-key-part-text-wrapper {
    position: relative;
    z-index: 1;
  }

  @media screen and (max-height: 700px) {
    .product-key-part__title {
      font-size: 2.4rem;
      line-height: 3rem;
      margin-bottom: 1.2rem;
    }
  }
  // OE-3755 edge case iphone 8
  @media screen and (max-height: 667px) {
    .product-key-part__title {
      font-size: 1.8rem;
      line-height: 2.2rem;
      margin-bottom: 1.2rem;
    }
    .product-key-part__desc {
      line-height: 1.8rem;
    }
  }
}
