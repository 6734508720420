.remove-margin {
  margin: 0 -2.8rem;
}
.bag-overview {
  .bag-info-box {
    margin-top: 4.4rem;
    margin-bottom: -2rem;
  }
  &__products {
    padding: 4.4rem 0 4rem;
  }
}
.bag-tabs .tabs .gradient-wrapper {
  top: -3rem;
  padding-top: 4.6rem; // solo per le tab principali e non quelle delle whishlist
}
