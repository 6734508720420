.product-tile {
  &__swatch-change {
    .selector-color-link {
      .selector__menu__item {
        &.selected {
          .selector__menu__item__label {
            color: $black_secondary;
          }
        }
      }
    }
  }
  &.in-look {
    .product-tile__info {
      padding-bottom: 0;
      z-index: 2;
      &.lookinfo {
        .product-tile__price {
          margin-bottom: 2.4rem;
        }
      }
      .shop-now {
        margin-top: 0;
      }
    }
    .selector--size {
      @include border('top');
      margin-top: ($space-unit * 6);
      .selector__menu {
        background-color: $white;
      }
    }
  }
  .product-tag {
    &:not(:last-child):after {
      padding: 0 0.7rem;
    }
  }
  &.product-tile--simplified {
    .product-tile__info {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
  }
}
