.orders-listing {
  .aftercare-tile-wrap {
    padding: 32px 40px;
    text-align: center;
    .request-image-wrap {
      margin-right: 0;
      margin-bottom: 24px;
    }
    .request-text-wrap {
      .request-text-left-wrap,
      .af-status-wrap {
        display: block;
        margin-bottom: 24px;
      }
    }
  }
}

.aftercare__descriptionTitles {
  flex-direction: column;
}
