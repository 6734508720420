.faq-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    @extend %primary-m-u;
    margin-bottom: ($space-unit * 3);
    text-align: center;
  }
  .subtitle {
    @extend %primary-xs;
    margin-bottom: ($space-unit * 5);
    text-align: center;
  }
  .button {
    margin-bottom: ($space-unit * 6);
  }
  .details {
    margin-bottom: ($space-unit * 12);
    width: 100%;
  }

  mark {
    background-color: $grey_input;
    position: relative;
    padding: 0.2rem;
  }
}
.faq--card {
  .faq--card-body {
    @extend %primary-xs;
    padding-top: ($space-unit * 2);
    padding-bottom: ($space-unit * 6);
    ul,
    ol {
      p {
        display: inline;
      }
    }
  }
}

.faq-component-module {
  margin: 4rem 0;
  .title {
    margin-bottom: 2.4rem;
  }
  .details {
    margin-bottom: 2.6rem;
  }
  .button {
    margin-bottom: 0;
  }
  .faq-back-btn {
    align-self: flex-start;
    margin-bottom: 4rem;
  }
}

.faq-dropdown {
  &__container {
    margin-top: 4rem;
    margin-bottom: 4rem;
    text-align: center;
  }
  &__title {
    margin-bottom: 4rem;
  }
  &__form {
    display: flex;
    column-gap: 0.8rem;
  }
  &__select-container {
    &.form-select {
      width: auto;
      display: inline-flex;
      flex-grow: 1;
      align-items: center;
      &:after {
        top: auto;
        right: 1.6rem;
        width: 0.3rem;
        height: 0.3rem;
      }
    }
  }
  &__select {
    .form-select & {
      height: 5.6rem;
      padding-left: 1.6rem;
      text-transform: uppercase;
      border-bottom: none;
    }
  }
  &__submit {
    &.button {
      display: none;
      width: auto;
      padding: 2rem 1.65rem;
      border: none;
      min-width: 5.6rem;
      font-size: 1.4rem;
    }
  }
}

.cs-detail-page {
  background: $grey_disabled;

  .faq-component {
    .title {
      font-size: 2.4rem;
    }
  }
}
