.optionbox {
  &--horizontal-two-items,
  &--address {
    .optionbox__item {
      @include col-width(6);
    }
  }
}

.client-service.account-appointments {
  .optionbox {
    &__item {
      &__checkbox,
      &__radio {
        &:checked + .optionbox__item__content,
        &:checked + .optionbox__item__content {
          padding-right: 1.2rem;
          padding-left: 1.2rem;
        }

        &:not(:checked) + .optionbox__item__content,
        &:not(:checked) + .optionbox__item__content {
          padding-left: 1.2rem;
          padding-right: 1.2rem;
        }
      }
    }
  }
}

.optionbox--horizontal-three-items {
  .optionbox__item {
    @include col-width(6);
  }
}
