.component_complete_the_look {
  padding-top: 3.2rem;
  padding-bottom: 4rem;
  h2 {
    font-size: 1.8rem;
    line-height: 2.2rem;
    padding-bottom: 1.6rem;
  }
  .product-look__image {
    margin-bottom: 1.6rem;
  }
}
