.component_materials_and_product_design {
  &__content {
    width: 60%;
    text-align: left;
    padding: 7rem 0 0 7rem;
    background: initial;
    .text {
      width: 100%;
      max-width: 300px;
      margin: 0;
    }
  }
}
