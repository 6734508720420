.order-history-info-box{
  column-gap: clamp(8px, 5%, 10%);

  &-labels{
    gap: 80px;
    justify-content: flex-start;

    .flex-label {
      display: flex;
      align-items: flex-end;
      column-gap: 1ch;
      justify-content: flex-start;
      row-gap: 4px;
      
      .order-history-section-p{
        margin-top: 0;
      }

      &--align-top {
        display: flex;
        column-gap: 1ch;
        align-items: baseline;
        justify-content: flex-start;
        row-gap: 4px;

        .order-history-section-p{
          margin-top: 0;
        }
      }
    }
  }
}