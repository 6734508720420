.membership-module {
  &__frame {
    padding: 3.2rem 2.8rem;
  }
  // OE-16934 - Optimize Editorial Module Borders/Paddings
  &__frame.pd-no__padding {
    padding-block:0;
  }
  &__container {
    background-color: $white;
    padding: 4.8rem 4rem;
  }
  &__row {
    align-items: center;
  }
  &__text-col {
    padding-left: 4rem;
  }
  &__img-col {
    padding: 0;
    .video-container {
      position: relative;
      .btn--pause {
        position: absolute;
        left: 1.6rem;
        bottom: 1.6rem;
        z-index: 2;
      }
    }
  }
  &__long-title {
    margin-bottom: 2.4rem;
  }
  &__divider {
    width: 100%;
    height: 1px;
    background-color: $grey_line;
    margin: 2.4rem 0;
  }
  &__description {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }
  &__pillar {
    display: flex;
    gap: 0.8rem;
    padding-bottom: 2.4rem;
    &:last-of-type {
      padding-bottom: 0;
    }
    .pillar-text-container {
      &-first,
      &-third {
        padding-top: 0.4rem;
      }
    }
    .pillar {
      &-title {
        font-weight: bold;
      }
      &-description {
        margin-top: 0.8rem;
      }
    }
    .icon-container {
      img {
        width: 2.4rem;
        height: 2.4rem;
        max-width: none;
      }
    }
  }
  &__cta-wrapper {
    width: 100%;
    display: flex;
    gap: 1.2rem 0.4rem;
    margin-top: 4rem;
    & > div {
      flex: 1;
    }
  }
}
