.mbm-box {
  margin-bottom: 6.4rem;
  padding: 2.4rem;
  h2 {
    margin-top: 0;
    margin-bottom: 1.5rem;
  }
  .button {
    width: 210px;
    margin: 0 auto;
  }
  p {
    margin-bottom: 2.4rem;
  }
  &.first-block p {
    margin-bottom: 2.4rem;
  }
}
.mbm-logo-big {
  height: 5.6rem;
}
.image-big {
  width: 70%;
}
.mbm-finish__buttons {
  margin-top: 5rem;
  > :first-child {
    order: initial;
  }
  .button {
    margin-bottom: 1.6rem;
  }
}
.mbm-finish {
  max-height: calc(var(--viewport-height) - #{$header-height-desktop});
}
