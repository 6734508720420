.checkout-review {
  padding-bottom: 1.6rem;
  border-bottom: 1px solid $grey_line;
  // .border-top {
  //   position: relative;
  //   &::before {
  //     content: '';
  //     position: absolute;
  //     top: 0;
  //     left: 0.2rem;
  //     width: calc(100% - #{$space-unit});
  //     height: $space-line;
  //     background-color: $grey_line;
  //   }
  // }
  .bag-review {
    padding-top: $space-unit * 2;
    padding-bottom: ($space-unit * 8);
  }
  .shipping-review,
  .payment-review {
    h3 {
      @extend %primary-s;
      padding-top: ($space-unit * 6);
      padding-bottom: ($space-unit * 4);
    }
    p {
      @extend %primary-xs;
    }
    p:not(:last-child) {
      margin-bottom: $space-unit;
    }
  }
  .card_expiration,
  .card_cvv {
    display: inline-block;
  }
  .card_cvv {
    margin-left: 2rem;
  }
  .paybylinkwait {
    margin-top: $space-unit * 8;
    text-align: center;
    line-height: 3.2rem;
  }
  .changebutton {
    margin-top: $space-unit * 4;
    margin-bottom: $space-unit * 4;
  }
  .tax-disclaimer--wrapper {
    margin-top: 4.4rem;
    .tax-disclaimer {
      color: $black;
      font-weight: 600;
    }
  }
}
.checkout-express {
  padding-bottom: 7rem;
  .checkout-express-mbm-info {
    margin-top: 3.2rem;
  }
}
.wrapper-checkout-express {
  width: 100%;
  justify-content: space-between;
  .shipping-review {
    display: block;
  }
  .checkout-review {
    border-bottom: none;
    padding-bottom: 4.8rem;
  }
}
.container-shipping-review {
  align-items: flex-end;
}
.container-edit-shipping {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.edit-button-container {
  text-align: end;
  .btn-edit {
    text-decoration: underline;
  }
}
.express-review-container {
  justify-content: space-between;

  &__gconly {
    justify-content: flex-start;
    column-gap: 4rem;
  }
}
.shipping-review {
  display: flex;
}

.checkout-express {
  .checkout-review {
    margin-bottom: 3.2rem;
  }
  .shipping-review {
    display: block;
  }
}

.container-customer {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.6rem;
  row-gap: 4px;
}
.container-info {
  display: flex;
  flex-direction: column;
  row-gap: ($space-unit * 7);
}
.review-title {
  color: rgba(0, 0, 0, 0.55);
}
.container-info-customer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: ($space-unit * 2);
}
