.search-container {
  .assistant-links {
    flex-direction: column;
    align-items: center;
    > a:not(:last-child) {
      margin-right: 0;
      margin-bottom: 1.2rem;
    }
    div {
      margin-right: 0;
      margin-bottom: 1.2rem;
    }
  }
  .search-overlay {
    background: linear-gradient($white 25%, $black 50%, $black 100%);
    // Prevents glimpses of page below with safari's dynamic address bar
    @supports (-webkit-appearance: none) {
      height: 100dvh;
    }
    .search-icons {
      .icon--close {
        display: none;
      }
    }
  }
  .no-results-container {
    @include animate;
    .collection-submenu {
      .collection-card {
        width: 100%;
        height: 100%;
        margin: 0;
      }
    }

    .react-tabs__tab-panel--selected {
      min-height: 50vw;
    }
  }
}
.has-ticker {
  .search-container {
    .search-overlay {
      height: auto;
    }
  }
}
