// mobile first, to make desktop changes, view the *.md-min.scss file

.myfeed-highlights {
  .text-decoration-none {
    text-decoration: none;
  }

  .client-service__section {
    margin-top: 80px;
    margin-bottom: 16px;

    .section__title {
      width: fit-content;
      margin-inline: auto;
      font-size: 24px;
      font-weight: 300;
      text-transform: uppercase;
    }
  }

  .optionbox {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    white-space: nowrap;

    &__item {
      width: auto;
      margin-inline: -5px; // remove border-bottom space btw items;
      flex: 0 0 0;
      flex-grow: 1;
      &__radio {
        @extend .visually-hidden;
        &:checked + .optionbox__item__content {
          border-top-color: transparent;
          border-right-color: transparent;
          border-left-color: transparent;
          font-weight: bold;
          border-bottom: 1px solid;
        }
      }
      &__content {
        text-transform: uppercase;
        border-color: transparent;
        padding-inline: 16px;
        justify-content: center;
        border-bottom: 1px solid $grey-input;
      }
    }
  }

  /* remove scroolbar on ternary menu */
  .no-scroolbar {
    overflow: auto;
    justify-content: flex-start;

    /* chrome */
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  &__wrapper {
    display: flex;
    // justify-content: space-between;
    // margin-top: 24px;
    flex-direction: column;
    row-gap: 24px;
    margin-top: 24px;
    margin-bottom: 80px;

    .wrapper__left,
    .wrapper__right {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-inline: 24px;
      border: 1px solid $grey-line;
    }

    .wrapper__left {
      width: 100%;
      padding-block: 40px;
      background-color: $grey_disabled;

      h4 {
        font-size: 12px;
        font-weight: 300;
        text-transform: uppercase;
        margin-bottom: 24px;
      }

      .left__details {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        column-gap: 1ch;

        div + div {
          margin-top: 12px;
        }

        h6 {
          font-weight: 300;
          text-transform: uppercase;
        }

        &__flex {
          display: flex;
          flex-direction: column;
          align-items: center;
          column-gap: 1ch;
        
          &.wrap-center {
            flex-wrap: wrap;
            justify-content: center;
          }

          p {
            font-size: 14px;
            font-weight: bold;
          }

          .capitalize {
            text-transform: capitalize;
          }

          .uppercase {
            text-transform: uppercase;
          }
        }
      }

      .btn-group {
        display: flex;
        flex-direction: column;
        margin-top: 24px;

        &--no-margin {
          @extend .btn-group;
          margin-top: 0;
        }

        .button--tertiary {
          display: flex;
          justify-content: center;
          padding-block: 14px;
        }
      }
    }

    .wrapper__right {
      width: 100%;
      padding-block: 40px;
      padding-inline: 40px;
      border-left: 1px solid $grey_line;
      border-top: none;
    }

    .hide-mobile {
      display: none;
    }

    .orders-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .carousel {
      h4,
      p {
        text-transform: uppercase;
      }

      h4 {
        font-size: 20px;
        font-weight: 300;
        line-height: 24px;
      }
      p {
        margin-top: 16px;
        margin-bottom: 24px;
      }

      // carousel
      .order-history-container {
        height: 100%;
        display: flex;
        position: relative;

        &-canceled {
          @extend .order-history-container;
          display: grid;

          .order-history-canceled-label, .order-history-img {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
            max-width: 130px;
            max-height: 193px;
          }

          .order-history-img {
            opacity: 0.5;
          }

          .order-history-canceled-label {
            padding: 5px;
            align-self: end;
            margin-inline: auto;
            margin-bottom: 12px;
            background-color: $white;
            text-transform: uppercase;
            opacity: 0.99;
            text-align: center;
            max-width: 100%;
            overflow-x: auto;
    
            /* chrome */
            &::-webkit-scrollbar {
              display: none;
            }
    
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
          }
        }
      }

      .order-history-img {
        object-fit: cover;
      }

      .order-history-section {
        text-transform: uppercase;
        color: $black;

        &-title {
          @extend .order-history-section;
          font-size: 20px;
          line-height: 24px;
        }

        &-subtitle {
          @extend .order-history-section;
          margin-top: 8px;
          font-size: 14px;
          line-height: 16px;
          color: $black_secondary;
        }
        &-p {
          @extend .order-history-section-subtitle;
          color: $black;
        }
        &-listing-subtitle {
          @extend .order-history-section-subtitle;
          margin-top: 0;
          // margin-left: 8px;
        }

        &-number {
          @extend .order-history-section;
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
        }

        &-listing-box {
          @extend .order-history-section;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        }

        &-listing-title {
          @extend .order-history-section;
          font-size: 16px;
          line-height: 20px;
        }

        &-map {
          @extend .order-history-section;
          margin-top: 16px;
          overflow: hidden;

          .swiper-slide {
            max-width: 130px;
            min-width: 130px;
            max-height: 193px;
          }

          .carousel-controls {
            justify-content: center;
            margin-top: 16px;
          }
        }
      }
    }

    .appointment-card {
      width: 100%;
      text-align: center;

      &__main,
      &__secondary {
        border: 1px solid $grey_line;
      }

      &__main {
        padding-block: 32px;
        padding-inline: 16px;
        .appointment-number {
          color: $black_secondary;
          margin-bottom: 0;
        }
        .appointment-store {
          font-size: 20px;
          line-height: 24px;
          font-weight: 300;
          text-transform: uppercase;
          margin-block: 32px;
        }
        .left__details + .left__details {
          margin-top: 12px;
        }
        .left__details {
          h6 {
            color: $black_secondary;
          }
          &.appointment-type {
            h6 {
              font-weight: 600;
            }
          }
        }
      }

      &__secondary {
        width: 100%;
        padding-block: 40px;
        padding-inline: 16px;
        height: fit-content;
        border-top: none;

        .title {
          font-size: 20px;
          font-weight: 300;
          line-height: 24px;
          margin-bottom: 8px;
          text-transform: uppercase;
        }

        .text {
          font-size: 16px;
          margin-top: 16px;
          margin-bottom: 24px;
        }

        .btn-group {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 12px;
        }
      }
    }

    .order-returns-card {
      align-items: normal;

      .title,
      .text {
        text-transform: uppercase;
      }

      .uppercase {
        text-transform: uppercase;
      }

      .return-type-title {
        font-size: 20px;
        margin-block: 40px 28px;
      }

      &__secondary {
        height: auto;
        justify-content: center;

        .title {
          font-size: 20px;
          margin-bottom: 16px;
          text-transform: uppercase;
          line-height: 24px;
        }

        .info-text {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
}

// controls the graphical tracking
.myfeed-order-track {
  width: 100%;
  max-width: 600px;
  padding-inline: 20px;
  margin-bottom: 42px;

  &__graphic {
    width: 100%;
    display: grid;
    grid-template-columns: 24px 1fr 7px 1fr 7px;
    place-items: center;

    &--centralized {
      @extend .myfeed-order-track__graphic;
      grid-template-columns: auto;
    }

    svg {
      min-width: 24px;
      min-height: 24px;
      background-color: $grey_disabled;
      opacity: 0.99; // create a fake "z-index" to stay in front of the lines
    }

    .svg--no-margin {
      min-width: 8px;
      min-height: 8px;
      background-color: $grey_disabled;
      opacity: 0.99; // create a fake "z-index" to stay in front of the lines
    }

    .line {
      width: 100%;
      border: none;
      border-bottom: 1px solid $black_secondary;
    }
  }

  &__legend {
    width: 100%;
    display: grid;
    grid-template-columns: 24px 1fr 7px 1fr 7px;
    place-items: center;
    margin-top: 4px;
    text-transform: uppercase;

    &--centralized {
      @extend .myfeed-order-track__legend;
      grid-template-columns: auto;
    }

    p {
      text-align: center;
    }

    .current {
      font-weight: bold;
    }
    .upcoming {
      color: $black_secondary;
    }
  }
}
