.three-column-product__title {
  margin-top: ($space-unit * 8);
}

.three-column-product__product-holder {
  overflow: auto;
  scroll-snap-type: x mandatory;
}

.three-column-product__title,
.three-column-product__subtitle {
  max-width: 100%;
  padding: 0 ($space-unit * 4);
}

.three-column-product__single-column {
  min-width: calc(100vw - 3.2rem);
  padding-top: 150%;
  scroll-snap-align: center;
}
.three-column-product {
  margin-bottom: 2.8rem;
}

//alignment
.three-column-product--centered-bottom {
  padding-top: ($space-unit * 4);
}
