.promo-50-50 {
  grid-column: span 4;
  &__text-wrapper {
    width: 45.6rem;
  }
  &__description {
    &.primary-l {
      font-size: 2.4rem;
      line-height: 2.8rem;
    }
  }
  &__padded {
    &.promo-50-50__container {
      height: 60.4rem;
      padding: 3.2rem 0;
    }
    .promo-50-50 {
      &__container {
        height: 60.4rem;
        padding: 3.2rem 0;
      }
      &__text-col {
        &--padding-top {
          padding-top: 0;
        }
        &--padding-bottom {
          padding-bottom: 0;
        }
      }
      &__img-col {
        &--right {
          order: 1;
        }
        &--left {
          order: 0;
        }
      }
      .row {
        height: 100%;
      }
      &__video-col {
        position: relative;
        height: 54rem;
        padding-top: 0;
      }
      &__picture {
        height: 54rem;
      }
      &__text-wrapper {
        width: 43.8rem;
      }
    }
  }
  &__eyebrows {
    margin-bottom: 2rem;
  }
}
