.product-zoom {
  &-thumbs {
    $slideW: 5.8rem;
    $slideH: 8.8rem;
    max-height: ($slideH * 5) + (1.6rem * 4);
    &--wrapper {
      width: unset;
      padding: 0 3.2rem 0 5.7rem;
    }

    .swiper-slide {
      width: $slideW;
      height: $slideH;
    }
  }
}

.product-zoom-modal {
  .product-gallery__link {
    font-size: 1.6rem;
  }
}
