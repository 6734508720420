.collections-inpage-navigation {
  &.with-borders {
    margin: ($space-unit * 8);
  }
  .collection-open {
    .inpage-collection {
      padding: 2rem 0;
    }
    .inpage-collection:not(.expanded) {
      padding: 1rem 0 2rem;
      .inpage-collection__name {
        font-size: 2.4rem;
        line-height: 2.8rem;
      }
    }
    > .inpage-collection-component:not(.resized) {
      & + .resized {
        margin-top: 1.6rem;
      }
    }
  }

  &__title {
    font-size: 2rem;
    line-height: 2.4rem;
    top: 6.4rem;
  }

  .inpage-collection {
    &:not(.expanded) {
      padding: 1.5rem 0;
    }
    // margin-bottom: 4.8rem;
    // display: inline-block;
    padding: 2rem 0;
    &.expanded {
      .inpage-collection__toggle-icon {
        display: none;
      }
      .inpage-collection {
        &__name {
          margin-top: 6.4rem;
          margin-bottom: 12.2rem;
        }
        &__info-box {
          top: 15.4rem;
          .button {
            margin: 2rem 0;
          }
        }
        &__logo {
          // top: 9rem;
          top: 3rem;
        }
      }
    }
    &__logo {
      top: -3rem;
    }
    &__name {
      font-size: 5.4rem;
      line-height: 6.4rem;
      // margin-bottom: 0.8rem;
    }
    &__toggle-icon {
      display: none;
    }
  }
}
