[data-aos][data-aos-delay='100'].aos-animate,
body[data-aos-delay='100'] [data-aos].aos-animate {
  transition-delay: 0.1s;
}
[data-aos][data-aos-duration='400'],
body[data-aos-duration='400'] [data-aos] {
  transition-duration: 0.4s;
}
[data-aos][data-aos-easing='ease'],
body[data-aos-easing='ease'] [data-aos] {
  transition-timing-function: ease;
}
[data-aos^='fade'] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^='fade'].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}

[data-aos='fade-up'] {
  transform: translate3d(0, 50px, 0);
}

[data-aos='fade-up-min'] {
  transform: translate3d(0, 20px, 0);
}

[data-aos='fade-up-10'] {
  transform: translate3d(0, 20px, 0);
  transition-duration: 0.8s !important;
}
[data-aos='fade-up-15'] {
  transform: translate3d(0, 20px, 0);
  transition-duration: 0.8s !important;
}
[data-aos='fade-up-20'] {
  transform: translate3d(0, 20px, 0);
  transition-duration: 0.8s !important;
}
