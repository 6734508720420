.monclercurators-hero {
  .monclercurators-hero__logo {
    padding-top: 5.6rem;
    padding-bottom: 3.2rem;
  }
  .monclercurators-hero__intro {
    margin-bottom: 6.4rem;
  }
  .monclercurators__container_down {
    .monclercurators__text {
      bottom: 8rem;
    }
  }
}
