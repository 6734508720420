header {
  .wrapper-submenu {
    .container-submenu {
      .main-submenu,
      .main-submenu-wrapper {
        .submenu-group,
        .submenu-column {
          width: 21.4rem;
        }

        .highlights-submenu {
          width: 21.4rem;
        }
      }

      &.with-3-items,
      &.with-4-items {
        .main-submenu {
          margin-left: 8.3333333333%;
        }

        .banner-wrapper {
          flex: 2;
        }
      }

      &:not(.with-2-items) {
        .main-submenu-wrapper {
          margin-left: 8.3333333333%;
          .main-submenu {
            margin-left: 0;
          }
        }
      }

      .main-submenu-wrapper {
        .highlights-submenu {
          margin-right: clamp(1.6rem, 3vh, 20rem);
        }
      }
    }
  }
}
