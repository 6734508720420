.featured-products-carousel {
  position: relative;
  &.product-carousel {
    background-color: #efefef;
    .product-carousel {
      &__carousel-container {
        margin-top: ($space-unit * 10);
      }
      &__text {
        margin-top: ($space-unit * 4);
      }
      &__title,
      &__text {
        position: relative;
      }
      &__carousel-container {
        max-width: 76.8rem;
      }
    }
    &.video {
      .product-carousel {
        &__title,
        &__text {
          color: $white;
        }
      }
    }
  }
}
