// LAYOUT FOR TABLET

.discovery-layout {
  .gallery-region {
    .gallery-section {
      &.fluid {
        & > * {
          padding-bottom: 12rem;
        }
      }
      &.grid {
        .grid-section {
          grid-template-columns: repeat(6, calc(100% / 6 - 5px / 6));
        }
      }
    }
  }
}

.discover-image-wrapper {
  // variations
  &.single {
    .picture-wrapper {
      aspect-ratio: 16 / 9;
    }
    &.squared {
      .picture-wrapper {
        aspect-ratio: 1 / 1;
      }
    }
  }

  &.double {
    display: flex;
    &.container {
      flex-direction: row;
      &:not(.no-padding) {
        padding-right: 10vw;
        padding-left: 10vw;
      }
    }
    &.column {
      flex-direction: row;
    }
    & > * {
      flex-basis: 50%;
    }
    img {
      aspect-ratio: 4 / 5;
    }
  }

  &.triple {
    .picture-wrapper:nth-child(1) {
      aspect-ratio: 16 / 9;
    }
    .picture-wrapper:nth-child(2),
    .picture-wrapper:nth-child(3) {
      aspect-ratio: 5 / 4;
    }

    &.horizontal {
      .picture-wrapper:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 3;
        aspect-ratio: 1 / 1;
      }
      .picture-wrapper:nth-child(2),
      .picture-wrapper:nth-child(3) {
        aspect-ratio: 18 / 9;
      }
    }
  }

  &.quad {
    padding: 0 10vw;
  }
}
