.special-sales {
  p {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
  .intro {
    p {
      max-width: 40rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .employee-summary {
    .button {
      width: auto;
    }
  }
  .employee-summary {
    margin-top: 5.6rem;
    &__add-guest {
      .button {
        display: inline-block;
        width: auto;
        min-width: 34rem;
      }
    }
  }
  .special-sale-form-wrapper {
    .button-row {
      &.sm-invert-col {
        flex-direction: row-reverse;
      }

      .skip-button {
        margin: 0;
      }
    }
  }
}
