.selector-color {
  .selector__menu {
    &:not(:hover) {
      .selector__menu__item.selected {
        .selector__menu__item__label {
          display: inline-block;
        }
      }
    }
    .focused {
      .selector__menu__item {
        &.selected {
          .selector__menu__item__label {
            display: none;
          }
        }
        &:focus {
          .selector__menu__item__label {
            display: inline-block;
          }
        }
      }
    }
  }
  .selector__menu__item {
    .selector__menu__item__label {
      position: absolute;
      display: none;
      right: 0;
      white-space: nowrap;
      padding-left: 0.8em;
      transform: translateX(100%);
    }
    &:hover {
      .selector__menu__item__label {
        display: inline-block;
      }
    }
  }
}
