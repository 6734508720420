.tooltip {
  .tooltip__box {
    left: initial;
    right: -1.2rem;
    transform: initial;
    &::before {
      left: initial;
      right: 0.8rem;
    }
    &.last-tooltip {
      min-width: 19rem;
    }
  }
}
//AFTERCARE
.aftercare {
  .tooltip {
    padding: 2.4rem;
    right: -0.8rem;
    .tooltip__box {
      right: 0.8rem;
      bottom: calc(100% + (-#{$space-unit * 3}));
    }
  }
}
// LIVE BOUTIQUE
.live-boutique-selected-store {
  .tooltip {
    .tooltip__box {
      min-width: 19rem;
      right: initial;
      &::before {
        left: 0.8rem;
      }
    }
  }
}

.tax-refund-container {
  .tooltip {
    .tooltip__box {
      left: auto;
      right: 50%;
      transform: translate(50%);
      &::before {
        left: auto;
        right: 50%;
        transform: translate(50%, 100%);
      }
    }
  }
}
