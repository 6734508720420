.curator-image-shop-the-look-container {
  .image {
    .img-mobile {
      display: block;
    }
    &.d-none-mobile {
      .img-desk {
        display: none;
      }
    }
  }
  .large-quote,
  .long-quote {
    width: 100%;
    padding: 0 4.5rem 4rem;
  }
  .large-quote {
    font-size: 46px;
    line-height: 44px;
  }
  .long-quote {
    font-size: 24px;
    line-height: 28px;
  }
}
