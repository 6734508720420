.featured-products-carousel {
  &.product-carousel {
    .has-gutter {
      .swiper-slide {
        margin-right: ($space-unit * 5);
      }
    }
    .swiper-slide {
      max-width: calc(33.3333% - 13.5px);
    }
    .product-carousel {
      &__text {
        max-width: 480px;
      }
    }
  }
}
