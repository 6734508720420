/** overwrites external styles for smooch chat **/

#web-messenger-container {
  display: none;
  z-index: 5;
  bottom: 13rem;
}

#mount {
  #messenger-button {
    border-radius: 0;
    width: 4.8rem !important;
    height: 4.8rem !important;
    transform: translateY(19px) !important;
    z-index: 1;
    box-shadow: 0 0 15px rgba(15, 15, 15, 0.15);
    border: 1px solid #fff;
    bottom: 13rem;
    .default-button-container {
      @include fixedSprite('chat-white');
      height: 4.8rem;
      width: 4.8rem;
      margin: 0;
      background-size: 2.4rem;
      svg {
        display: none;
      }
    }
  }

  #wrapper {
    border-radius: 0;
    .text-input-container {
      border-radius: 0;
      background-color: $grey_input;
    }
    .message-input {
      background-color: $grey_input;
    }
    #conversation {
      background-color: $white;
    }
    .logo {
      display: none;
    }
    // per embedd live chat
    .intro-pane.fixed {
      display: none;
    }
  }

  #header {
    // per embedd live chat
    &.fixed-intro {
      display: none;
    }
    .header-icon {
      display: none;
    }

    margin: 0;
    padding: 1.6rem;
    height: auto;

    .header-content .header-title,
    .settings-content .header-title {
      @extend %primary-s-u;
      padding: 0;
      margin: 0;
      height: auto;
      font-weight: 400;
    }
    .back-handle {
      margin-right: 1rem;
    }
    .header-content,
    .settings-content {
      justify-content: center;
      margin: 0;
      height: auto;
    }
  }

  #settings-header {
    .header-icon {
      display: none;
    }
    .settings-content div:only-child {
      @extend %primary-s-u;
      padding: 0;
      margin: 0;
      height: auto;
    }
  }

  #footer {
    display: flex;
    border-radius: 0;
    padding: 1.6rem;
    background: #fff;
    height: auto;
    align-items: stretch;
    .input-container {
      margin: 0;
      letter-spacing: 0.04rem;
      width: 100%;
      border: none;
      padding: 0;
      height: 3.2rem;
      textarea {
        overflow: auto;
        margin: 0;
        font-size: 1.6rem;
        line-height: 1.6rem;
        height: 3.2rem;
        border: none;
        width: 100%;
        color: $black_secondary;
        padding: 0.8rem 1rem;
      }
    }

    .send {
      @include fixedSprite('arrow-right-white', '#000');
      position: absolute;
      right: 1.6rem;
      margin: 0;
      border-radius: 0;
      width: 3.2rem;
      height: 3.2rem;
      img {
        display: none;
      }
    }

    .image-upload {
      @include fixedSprite('plus-white', '#000');
      width: 3.2rem;
      height: 3.2rem;
      margin: 0;
      border-radius: 0 !important;
      padding: 0;
      background-size: 4.6rem;

      &.toggled {
        @include fixedSprite('close-white', '#000');
        width: 3.2rem;
        height: 3.2rem;
        background-size: 2rem;
      }

      img,
      .close-icon {
        display: none;
      }
    }

    > * {
      flex: initial !important;
    }

    form {
      width: calc(100% - 3.2rem);
    }
  }
}

.conversation-group .conversation-group-list .icon {
  display: flex;
}

#conversation {
  .messages-container {
    padding: 0 1.6rem;
  }
  .row {
    &.left-row + .right-row,
    &.right-row + .left-row {
      margin-top: 1.6rem !important;
    }
    &.right-row {
      flex-direction: row-reverse;
      margin: 0;
      .msg-wrapper {
        .msg,
        .msg-image {
          background: #efefef !important;
          border-color: #efefef !important;
          overflow-wrap: inherit;
          word-break: initial;
          word-wrap: initial;
        }
      }
    }
  }
}

#container {
  z-index: 2;
  #conversation {
    .from {
      display: none !important;
    }
    .row .msg-wrapper .message-item {
      width: 100%;
    }
    .upload-container {
      border-radius: 0;
      left: 0;
      box-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.15);
      .upload-menu {
        margin: 0;
        max-width: 100%;
        .upload-menu-item {
          border-radius: 0;
          max-width: 100%;
          padding: 0 0.5rem;
          &:hover {
            background-color: #efefef;
          }
        }
      }
    }
  }
}

.right-row .message-status-indicator,
.right-row .retry {
  margin-right: 2rem;
}

.msg-avatar {
  width: 3.2rem;
  height: 3.3rem;
  background-image: url('https://media.yoox.biz/ytos/resources/MONCLER/accessibility/icons/png/logo-moncler.png');
  background-size: 74%;
  background-color: #000;
  border-radius: 100px;
  background-repeat: no-repeat;
  background-position: 50% 55%;
  img {
    display: none;
  }
}

.chat-message {
  font-size: 1.6rem;
  line-height: 2rem;
  padding: 0 1.6rem 0 1.24rem;
  position: fixed;
  background-color: #efefef;
  z-index: 4;
  opacity: 0;
  height: 4.8rem;
  align-items: center;
  justify-content: flex-start;
  min-width: 29.5rem;
  right: 68px;
  filter: drop-shadow(0 0 15px rgba(15, 15, 15, 0.15));
  display: flex;
  animation: messenger-button-hide 0.4s cubic-bezier(0.62, 0.28, 0.23, 0.99);
  visibility: hidden;
  bottom: 13rem;
  &.visible:not(.collapsed) {
    visibility: visible;
    animation: messenger-button-show 0.4s 1s forwards cubic-bezier(0.62, 0.28, 0.23, 0.99);
  }
  &.collapsed {
    visibility: visible;
  }
}

@keyframes messenger-button-show {
  0% {
    transform: scale(0);
    opacity: 0;
    right: 0;
  }

  1% {
    transform: scale(1);
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes messenger-button-hide {
  0% {
    opacity: 1;
    transform: scale(1);
    right: 67px;
  }

  99% {
    right: 0;
    opacity: 0;
    transform: scale(1);
  }

  to {
    right: 0;
    opacity: 0;
    transform: scale(0);
  }
}
