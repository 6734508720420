.product-highlight {
  display: flex;
  align-items: center;
  @include appearAnimation();
}

.product-highlight__img-holder {
  position: relative;
  overflow: hidden;
  width: 50%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    max-width: 100vw;
    width: 100%;
    height: 100%;
  }
}

.product-highlight__text-holder {
  width: 50%;
  padding: 10rem ($space-unit * 4) ($space-unit * 8);
}

.product-highlight__title {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: ($space-unit * 2);
}

.product-highlight__text {
  @extend %primary-xs;
  text-align: center;
  width: 45.6rem;
  margin: 5.2rem auto 0;
  max-width: 100%;
}

.product-slide__img {
  display: block;
  margin: 0 auto;
  // width: 45.6rem;
  // height: auto;
  width: auto;
  height: 44.7rem;
  max-width: 100%;
}

.product-highlight--img-bigger {
  .product-slide__img {
    width: 55.7rem;
  }
}

.product-highlight__products-slider {
  @include animate(1);
  transition-delay: 0.1s;
}

.product-slide__caption {
  text-align: center;
  @extend %primary-xxs;
  margin-top: ($space-unit * 4);
}

.swiper-slide .product-slide__caption {
  display: none;
}

.product-highlight__cta {
  text-align: center;
  margin-bottom: 6.4rem;
}

.product-highlight__logo-holder {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  img {
    width: 12rem;
  }
}

.img-holder--external-border {
  img {
    padding: ($space-unit * 8);
  }
}

.swiper-container .products-slider__product-slide:not(.swiper-slide-active) {
  visibility: hidden;
}
