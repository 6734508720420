.product-refill {
  display: flex;
  flex-direction: column;

  .dialog__title {
    position: absolute;
    color: $white;
    top: 1.8rem;
  }
  .dialog__white-title {
    position: absolute;
    color: $white;
    top: 4.5rem;
  }

  &__main {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-height: 90vh;

    .product-image {
      img {
        width: auto;
        margin: 0 auto;
      }
    }
  }

  &__detail {
    flex: 1;
    padding: 2.2rem $space-unit * 4;
    display: flex;
    flex-direction: column;
    max-height: 90vh;
    overflow: auto;
    justify-content: space-between;

    button {
      margin-bottom: $space-unit * 4;
    }

    h3 {
      font-size: 1.6rem;
      margin-bottom: 0.8rem;
      margin-top: 2, 4rem;
    }
  }
}
