.component-two-column-info-block {
  .container-two-column-info-block {
    padding: 0 8.8rem;
    &-border {
      padding: 0 6rem 4rem 6rem;

      .two-column-info-description-block {
        line-height: 2.4rem;
        align-items: flex-start;

        &_description {
          font-size: 2rem;
        }

        &_description-link {
          font-size: 1.8rem;
        }
      }
    }
  }
}
