.modal {
  &.shop-the-look-modal {
    .container-shop-the-look {
      max-width: 92rem;
      .product-tile__image-wrapper {
        max-width: 30vh;
        margin: 0 auto;
      }
      .product-image {
        max-width: 30vh;
        margin: 0 auto;
      }
    }
    .modal__close {
      top: 6rem;
      right: 6rem;
    }
  }
}
.curator-image-shop-the-look-container {
  .container-shop-the-look {
    .button-shop-the-look-open {
      top: 6rem;
      right: 6rem;
    }
  }
}
.carousel-button__shop-the-look {
  padding: 0 3.2rem;
}
.overlay-shop-the-look-modal {
  &.color-black {
    background: rgba(0, 0, 0, 0.35);
  }
  &.color-white {
    background: rgba(0, 0, 0, 0.16);
  }
}
