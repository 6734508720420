.curator-flp-video {
  position: relative;
  overflow: hidden;
  width: 100%;
  .curator-video__inner-container {
    position: relative;
    height: 100%;
    filter: grayscale(1);
  }
  .curator-video__text-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $white;
    z-index: 4;
    text-align: center;
    width: calc(100% - 32px);
    // default before gsap init
    opacity: 0;
    &.show-text {
      opacity: 1;
    }
    .date {
      text-transform: uppercase;
      font-size: 1.4rem;
      line-height: 1.6rem;
      display: inline-block;
      margin-bottom: 1.6rem;
    }
    .curator-video__title {
      font-family: $silkSerifLight;
      font-size: 4.6rem;
      line-height: 4.4rem;
      margin-bottom: 1.2rem;
    }
    .curator-video__quote {
      font-size: 1.6rem;
      line-height: 2rem;
      margin: 0 auto;
      margin-bottom: 4rem;
      max-width: 68rem;
    }
    .button {
      width: auto;
    }
  }
  .btn--pause {
    position: absolute;
    bottom: 1.3rem;
    left: 1.3rem;
    z-index: 2;
  }
  .curator-video-wrapper {
    // height: calc(var(--viewport-height) - #{$header-height-mobile});
    &::before {
      content: '';
      height: 5rem;
      width: 100%;
      bottom: 0;
      left: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      position: absolute;
      z-index: 1;
    }
    &::after {
      content: '';
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
      position: absolute;
      z-index: 1;
    }
  }
}
