.carousel-slide__inner-slide,
.carousel-slide__caption-holder {
  width: 100%;
  &::before {
    padding-top: 56.25%;
    height: auto;
  }
}

.editorial-carousel {
  &__swiper-container {
    overflow: visible;
  }
  .editorial-carousel__carousel-wrapper {
    &.aspect-ratio--16-9 {
      &::before {
        padding-top: 56.25%;
      }
    }
  }
  .editorial-carousel__carousel-slide {
    &:not(.carousel-slide--text-slide) {
      width: 80rem;
    }
  }
}

.editorial-carousel__swiper-container {
  &::before,
  &::after {
    content: none;
  }
}

// .editorial-carousel__arrow {
//   display: none;
// }

// .swiper-container {
//   button.swiper-button-disabled {
//     display: block;
//   }
// }

.swiper-slide--mobile-gab {
  display: block;
}

.carousel-slide--text-slide + .swiper-slide--mobile-gab {
  display: none;
}

.carousel-slide__text-holder {
  width: 60.6rem;
}

.editorial-carousel__arrow {
  top: 100%;
  width: ($space-unit * 6);
  height: ($space-unit * 6);
  left: ($space-unit * 2);
  transform: translateY(80%);
  z-index: 4;
  &::after {
    width: ($space-unit * 6);
    height: ($space-unit * 6);
  }
  &.editorial-carousel__arrow--arrow-right {
    left: initial;
    right: ($space-unit * 2);
  }
}
