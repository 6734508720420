.account-appointments {
  .virtual-message {
    margin-top: 3.2rem;
  }
  .optionbox {
    text-align: center;
    justify-content: space-around;
    white-space: normal;
    &__item {
      flex: 1;
      &__content {
        justify-content: center;
      }
    }
  }
}

$boxPadding: 40px 20px;

.appointments-card {
  &__body {
    text-align: center;

    padding: $boxPadding;

    .appointment-number,
    .appointment-date,
    .appointment-time {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .appointment-btn-group {
      width: 100%;
      max-width: none;
      margin-inline: auto;
    }
  }
}

.appointments__book-banner {
  margin-top: 40px;

  .banner-content {
    padding-inline: 1.6rem;
  }

  .banner-img {
    aspect-ratio: 1/1.5;
    object-fit: cover;
  }
}

.account-appointments-detail {
  margin-top: 40px;

  &__more-details {
    flex-direction: column;
  }

  &__btn-group {
    flex-direction: column;
    gap: 16px;
  }
}

.edit-appointment-confirm {
  &__body {
    .info {
      &__description {
        margin-bottom: 16px;
      }

      &__description {
        margin-bottom: 16px;
        margin-top: 0;
      }
      &__hr {
        margin-top: 17px;
      }
    }
  }
}

.edit-appointment {
  &__right {
    .optionbox {
      padding-top: 16px;
    }
  }

  &__btn-group {
    display: flex;
    flex-direction: column-reverse;
    gap: 12px;

    .btn--cancel {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.appointment-edit-form {
  .optionbox {
    white-space: normal;
  }
  .edit-appointment-form__details {
    .wrapper {
      display: block;
      h4 {
        margin-top: 40px;
        margin-bottom: 16px;
      }

      :last-child {
        h4 {
          margin-top: 24px;
        }
      }
    }
  }
}

.edit-appointment-confirm__body {
  .info {
    &__btn-group {
      flex-direction: column;
    }
  }
}
