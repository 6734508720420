.flagship-page__breadcrumb.hero-breadcrumb {
  height: 7.4rem;
  padding-bottom: 16px;
}

.flagship-page {
  .flex-full-height {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__full-height {
    position: relative;
    height: 100%;
    min-height: calc(var(--view-height) - var(--header-height) - 85px);
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  /* ------------------------------- MATTERPORT ------------------------------- */
  &__matterport {
    color: $white;
    .content {
      height: 100%;
      .container {
        height: inherit;
        .wrapper {
          position: relative;
          height: inherit;
        }

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: inherit;
          min-height: 240px;

          &.fade-out {
            animation: fadeOut 0.3s linear;
            opacity: 0;
          }

          &__background {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: inherit;
            min-height: 240px;

            img {
              object-fit: cover;
              object-position: 50% 50%;
              width: 100%;
              height: 100%;
            }
          }

          .content__wrapper {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: inherit;
            min-height: 240px;
            backdrop-filter: blur(5px);
            background-color: rgba(0, 0, 0, 0.55);
            z-index: 1;
            display: grid;
            place-items: center;
          }

          .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1.2rem;
            max-width: 345px;
            padding-inline: 16px;

            .title {
              font-size: 2rem;
              text-transform: uppercase;
              text-align: center;
              line-height: 24px;
            }

            .description {
              text-align: center;
            }

            .cta {
              margin-top: 1.2rem;
              padding-block: 1rem;
              padding-inline: 2.4rem;
              display: block;
              text-decoration: none;
            }
          }
        }

        .matterport-showcase {
          height: inherit;
          iframe {
            flex: 1;
            min-height: 240px;
            width: 100%;
            height: inherit;
          }
        }
      }

      .flagship-store_detail {
        display: flex;
        align-items: center;
        min-height: 90px;
        color: $black;
        background: linear-gradient(180deg, #f9f9f9 0%, #fff 100%);

        .container {
          .grid-1-1 {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .detail__title-wrapper {
            display: flex;

            .detail__title {
              font-size: 18px;
              text-transform: uppercase;
              text-align: center;
            }
          }

          .detail__cta-wrapper {
            margin-top: 8px;
            .detail__cta {
              display: flex;
              align-items: center;
              color: var(--Content-Secondary, rgba(0, 0, 0, 0.55));
              font-size: 14px;
              text-decoration: none;
              i {
                opacity: 0.55;
              }
            }
          }
        }
      }
    }
  }

  /* -------------------------- LOCATION DESCRIPTION -------------------------- */
  &__location-description {
    padding: 5.6rem 1.6rem;
    background: linear-gradient(180deg, #f9f9f9 0%, #fff 100%);
    color: $black;
    text-align: center;

    p {
      max-width: 720px;
      margin: 0 auto;
      font-size: 16px;
      line-height: 20px;
    }
  }

  /* ------------------------------ TEXT SQUARES ------------------------------ */
  // .section-text-squares-list {
  //   list-style: none;
  //   padding: unset;
  // }

  &__text-squares {
    list-style: none;
    padding: unset;

    .experience-component_text_square_grid {
      padding: unset;
      background-color: #fbfbfb;
      border-width: 1px 1px 1px 0;
      border-style: solid;
      border-color: $grey_line;

      // Remove top border for tiles in the bottom row
      &:nth-child(n + 4) {
        border-top: none;
      }
    }

    display: grid;
    grid-template-columns: repeat(3, 1fr);

    color: $black;
    text-transform: uppercase;

    .text-squares__tile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      width: 100%;
      max-height: 256px;
      aspect-ratio: 1;

      .button {
        font-size: 12px;
        padding-right: 16px;
      }

      i {
        height: 24px;
      }

      img {
        max-height: 24px;
      }

      h3 {
        font-size: 18px;
        line-height: 22px;
        text-align: center;
      }

      button {
        text-transform: uppercase;
      }
    }

    @media (max-width: 720px) {
      grid-template-columns: 1fr;
      .text-squares__tile {
        max-height: 164px;
        border-width: 1px;
      }

      .text-squares__tile + .text-squares__tile {
        border-top: 0;
      }
    }
  }
}

.digitalflagship-promocard-small {
  display: flex;
  width: 100%;
  height: 124px;

  &__image {
    aspect-ratio: 1;
    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-block: 1.6rem;
    padding-left: 1.6rem;
    border-width: 1px 1px 1px 0;
    border-style: solid;
    border-color: $grey_line;
    width: 100%;

    &__details {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;

      h3 {
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      p {
        font-size: 12px;
      }
    }

    &__cta {
      text-transform: uppercase;
    }
  }
}
