.contact-details {
  padding-bottom: 4rem;
  &.clothing-contact {
    padding-bottom: 6.4rem;
  }
  &__description {
    line-height: 2.4rem;
  }
  &.clothing-contact-details {
    padding: 0;
    .contact-details {
      padding: 0;
    }
  }
  &__alternate {
    .contact-details__contacts {
      padding-top: 1.6rem;
      padding-bottom: 1.6rem;
      gap: 1.2rem;
    }
    .contact-details__dropdown-container {
      padding-bottom: 1.6rem;
      margin-bottom: 2.4rem;
    }
  }
  &__contacts {
    flex-direction: row;
    gap: 3.2rem;
    margin-top: 1.8rem;
  }

  &__button {
    &--disabled {
      .contact-details--enhanced & {
        margin-top: 0;
        padding: 0 0.6rem;
      }
    }
  }

  .button {
    width: auto;
    padding: 0 0.6rem;
    min-height: 3.6rem;
  }
  &__dropdown-container {
    margin-bottom: 1.6rem;
  }
}

// PDP MODAL - CONTACT US

.contactus-modal {
  .modal__header {
    padding-top: 4rem;
  }
  .dialog__title {
    margin-top: 0;
  }

  .contact-us__desc.container {
    .col-sm-9 {
      max-width: 34.7rem;
    }
  }
  .contact-details {
    &__contacts-contact {
      flex-direction: column;
      &:last-child {
        padding-bottom: 15rem;
      }

      .col-sm-6 {
        flex-basis: auto;
        max-width: 100%;
        padding-bottom: 2.4rem;
      }
      .col-sm-5 {
        flex-basis: auto;
        max-width: 100%;
      }
    }
  }
}

.drawers {
  .contact-us {
    padding: 3.2rem 6.4rem;
    .contact-us__desc {
      padding-bottom: 3.2rem;
    }
    .contact-details__contacts-contact {
      row-gap: 2.4rem;
    }
  }
}
