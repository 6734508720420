.book-appointment-trebaseleghe {
  margin-bottom: 12rem;
  &-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 38rem;
    margin: 6.4rem auto 4rem;
    gap: 2.4rem;
    .title {
      text-transform: uppercase;
      font-size: 2.4rem;
      line-height: 2.8rem;
    }
    .title,
    p {
      text-align: center;
      b {
        display: block;
        margin-top: -1.2rem;
      }
    }
  }
  &-wrapper {
    max-width: 69.5rem;
    margin: 0 auto;
    .optionbox {
      border-bottom: 1px solid $grey_input;
      justify-content: center;
      column-gap: 2rem; // optionboxes have got 0.2rem padding both left and right, so 2rem + 0.2rem + 0.2rem = 2.4rem
      white-space: nowrap;
      width: fit-content;
      margin: 0.8rem auto 0 auto;
      &__item {
        min-width: 17.5rem;
        width: auto;
        flex: 0 0 0;
        &__radio {
          @extend .visually-hidden;
          &:checked + .optionbox__item__content {
            color: $black;
            border-top-color: transparent;
            border-right-color: transparent;
            border-left-color: transparent;
            font-weight: bold;
            border-bottom: 1px solid;
          }
          & + .optionbox__item__content {
            color: rgba($black, 0.55);
          }
        }
        &__content {
          text-transform: uppercase;
          border-color: transparent;
          padding-right: 0;
          padding-left: 0;
          justify-content: center;
        }
      }
    }

    .book-form-no-slot {
      border-top: 1px solid #e4e4e4;
      padding-top: 5.4rem;
      margin-bottom: 5.4rem;
      .title {
        text-transform: uppercase;
        margin-bottom: 1.4rem;
        font-size: 1.6rem;
      }
      .description {
        margin-bottom: 2.6rem;
      }
    }

    .baa-login-form,
    .baa-book-form,
    .baa-edit-form,
    .thank-you-wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 3.2rem;
      gap: 2.4rem;
      &-title {
        font-size: 1.6rem;
        text-transform: uppercase;
      }
      &-subtitle {
        font-weight: 600;
        margin-bottom: 2.4rem;
      }
      .ctas-wrapper {
        display: flex;
        justify-content: flex-end;
        gap: 1.2rem;
        margin-top: 2.4rem;

        &.no-flex {
          display: block;
        }

        .button {
          flex: 1;
          flex-basis: 50%;
          max-width: 50%;
        }
      }
    }

    .baa-book-form,
    .baa-edit-form {
      border-top: 1px solid #e4e4e4;
      padding-top: 5.4rem;
      margin-bottom: 5.4rem;
      .family-members-wrapper {
        .row {
          [class^='col'] {
            display: flex;
            flex-direction: column;
            gap: 1.2rem;
          }
        }
      }
      .ctas-wrapper {
        .button--tertiary {
          justify-content: flex-start;
          align-items: center;
          padding-left: 2.4rem;
        }
      }
    }

    .baa-edit-form {
      padding-bottom: 3.2rem;
      padding-top: 0;
      border-top: 0;
      .booking-number {
        border-bottom: 1px solid #e4e4e4;
        padding-bottom: 1.2rem;
      }

      .detail-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2.4rem;
        margin-bottom: 8rem;
        .detail-cell {
          display: flex;
          flex-direction: column;
          gap: 0.4rem;
          .title {
            font-size: 1.2rem;
            text-transform: uppercase;
            margin-bottom: 0.2rem;
          }
        }
      }
      .ctas-wrapper {
        &.with-border {
          padding-bottom: 3.2rem;
          border-bottom: 1px solid #e4e4e4;
          .button--tertiary {
            opacity: 0.5;
            padding: 0;
            align-items: flex-end;
          }
        }
      }
    }

    .thank-you-wrapper {
      gap: 0;
      .booking-number {
        width: 100%;
        padding-bottom: 1.2rem;
      }
      .detail-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2.4rem;
        padding-top: 4.2rem;
        padding-bottom: 1.2rem;
        border-top: 1px solid #e4e4e4;
        border-bottom: 1px solid #e4e4e4;
        .detail-cell {
          display: flex;
          flex-direction: column;
          gap: 0.4rem;
          .title {
            font-size: 1.2rem;
            text-transform: uppercase;
            margin-bottom: 0.2rem;
          }
          .button--tertiary {
            width: fit-content;
            margin-top: 3.2rem;
          }
        }
      }
      .ctas-wrapper {
        gap: 0.8rem;
        &.no-flex {
          margin-top: 5.4rem;
        }
      }
    }

    .manage-appointments-wrapper {
      .appointments-list {
        margin: 6.4rem 0 5.3rem;
        display: flex;
        flex-direction: column;

        .appointment-item {
          border-bottom: 1px solid #e4e4e4;
          padding: 1.6rem 0;
          display: flex;
          justify-content: flex-start;

          &.view-more {
            display: flex;
            justify-content: center;
            button {
              text-decoration: underline;
              text-transform: none;
            }
          }

          .info {
            min-width: 24rem;
            .appointment-item__title {
              text-transform: uppercase;
              margin-bottom: 1.6rem;
            }
          }
          .view-button-wrapper {
            flex: 1;
            padding: 0 3rem;
            &.guest {
              justify-content: flex-end;
              align-items: flex-start;
              display: flex;
            }
            .view-button {
              text-decoration: underline;
            }
          }
        }

        .empty-banner {
          display: flex;
          flex-direction: column;
          gap: 1.2rem;
          max-width: 34rem;
          margin: 0 auto;
          h3 {
            text-transform: uppercase;
            font-size: 1.6rem;
          }
          h3,
          p {
            text-align: center;
          }
          .button--primary {
            margin-top: 8rem;
          }
        }
      }
      .ctas-wrapper {
        display: flex;
        justify-content: center;
        &.flex-start {
          justify-content: flex-start;
        }
      }
    }
  }

  .remove-appointment {
    background-color: red;
  }
}

.confirm-cancel-appointment {
  .remove-appointment {
    .item-actions {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}
