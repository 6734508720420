.similar-product {
  &-description > span {
    opacity: 0.55;
  }
  &-btn {
    text-decoration: underline;
    &:disabled {
      color: $grey_interactive;
      text-decoration: none;
      cursor: default;
    }
  }

  &-modal {
    height: auto;
    bottom: 0;
    top: initial;

    .modal__close.is__sticky {
      padding: 1.2rem;
      padding-right: 1.2rem;
      padding-bottom: 0;
    }
    .similar-product {
      &-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        overflow: hidden;
        box-sizing: border-box;
        padding-left: 1.6rem;
        opacity: 1;
        .similar-product-slide {
          width: 42.8%;
        }
      }
    }
  }

  &-carousel {
    &:not(.carousel-enabled) {
      .similar-product-carousel--cards-wrapper {
        .similar-product-slide {
          flex-basis: calc(50% - 0.2rem);
        }
      }
    }
    &--cards-wrapper {
      & > ul {
        padding: 0;
        list-style: none;
        padding-bottom: 0.6rem;
        li {
          padding: 0;
        }
      }
    }
    &--controllers {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-top: 1.5rem;

      // in case of label SEE SIMILAR visible
      &-label-visible {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 2rem;
      }
      &-arrows {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 0.8rem;
        padding: 1rem 1.6rem;
        .swiper-button-disabled {
          > span {
            opacity: 0.35;
          }
        }
      }
      &-label {
        font-size: 1.2rem;
        line-height: 1.4rem;
        opacity: 0.5;
        text-transform: uppercase;
      }
    }
  }
  &-text-container {
    padding-left: 1.6rem;
  }
  &-title {
    text-transform: uppercase;
    // padding-top: 3rem;
    padding-bottom: 1.8rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
  &-subtitle {
    padding-bottom: 2.4rem;
    font-size: 1.6rem;
    line-height: 2rem;
  }
  &-slide {
    list-style: none;
    .product-tile--carousel {
      .wishlist-icon {
        display: none;
      }
      .members-only-badge.product-tile__members-unlock {
        display: none;
      }
    }
  }
}

.similar-product-floating {
  position: fixed;
  z-index: 3;
  bottom: 0;
  left: 0;
  background-color: transparent;
  opacity: 0;
  &.visible {
    opacity: 1;
    transition: background-color 0.2s ease-in-out;
    animation: fadeIn 0.2s;
    -webkit-animation: fadeIn 0.2s;
    -moz-animation: fadeIn 0.2s;
    -o-animation: fadeIn 0.2s;
    -ms-animation: fadeIn 0.2s;
  }
  &-inner {
    padding: 0.4rem 0.8rem;
    background-color: $white;
    margin: 1.6rem;
    overflow: hidden;
    filter: drop-shadow(0.5px 0.5px 12px rgba(0, 0, 0, 0.2));
    box-shadow: 0.5px 0.5px 12px rgba(0, 0, 0, 0.2);
    -webkit-filter: drop-shadow(0.5px 0.5px 12px rgba(0, 0, 0, 0.2));
    &.floating-margin-bottom {
      margin-bottom: $sticky-tray-bar + 6.8rem;
    }
    &-text {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.8rem;
      min-height: 3.15rem;
      .similar-product_btn {
        display: contents;
        font-size: 1.2rem;
        color: rgba(0, 0, 0, 0.55);
      }
      .icon--close {
        width: 2rem;
        height: 2rem;
      }
    }
  }
  &--icon-images {
    gap: 0.4rem;
    padding: 0;
    list-style: none;
    flex-wrap: nowrap;
    & > li {
      padding: 0;
      width: 2.1rem;
      & > img {
        object-fit: contain;
      }
    }
  }
  &.expanded {
    right: 0;
    top: 0;
    background-color: $overlay;
    display: flex;
    flex-direction: column-reverse;
    .similar-product-floating-inner {
      padding: 0 1.2rem 0 0.8rem;
      &-text {
        justify-content: flex-end;
      }
      .similar-product {
        &-container {
          .similar-product-carousel {
            &--cards-wrapper {
              padding-right: initial;
            }
            .similar-product-carousel--controllers-label-visible {
              padding-top: 0;
            }
          }
        }
      }
    }
    .similar-product-carousel {
      &--arrow-container {
        display: flex;
        align-items: center;
        .button.close {
          padding: 1.2rem 0 1.3rem 1rem;
          .icon--close {
            width: 2rem;
            height: 2rem;
          }
        }
        .similar-product-carousel {
          &--controllers {
            &-arrows {
              padding: 0;
              & > button {
                &:focus-visible,
                &:focus {
                  outline: 0;
                }
                [data-whatintent='keyboard'] & {
                  &:focus-visible {
                    outline: 1px solid;
                  }
                }
              }

              .icon--arrow {
                &-left,
                &-right {
                  width: 1.8rem;
                  height: 1.8rem;
                }
              }
            }
          }
        }
      }
      .product-tile__info {
        padding: 0.8rem 0;
      }
      .product-tile__price {
        display: none;
      }
      .product-tag-wrapper {
        display: none;
      }
      .product-tile__name {
        margin-bottom: 0;
        font-size: 1.2rem;
        line-height: 1.4rem;
        height: auto;
      }
      .product-tile__card {
        .product-tile__image-wrapper {
          .product-labels {
            display: none;
          }
        }
        .collection-in-tile {
          display: none;
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
