.remove-margin {
  margin: 0 -2rem;
}
.bag-product {
  position: relative;
  &__no_refundable {
    position: absolute;
    bottom: 2.8rem;
    p {
      margin-left: 1.4rem;
    }
  }
}
.bag-overview--empty {
  &__button-container {
    width: 32rem;
    padding: 0;
  }
}
