.curator-image-time {
  &.time {
    .container-curator-time {
      height: 3.3rem;
      .curator-time {
        font-size: 60px;
        left: 13%;
        line-height: 56px;
      }
    }
  }
}
