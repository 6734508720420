.search-container {
  #searchResults {
    flex-grow: 1;
  }
  .icon--search {
    height: 3rem;
    width: 3rem;
  }
  .suggested-container {
    .row > div:not(:first-child) {
      padding-top: ($space-unit * 8);
    }
  }
  .search-icons {
    .search-query {
      margin-right: $space-unit;
    }
  }
  .search-maincategories {
    position: relative;
    left: 0;
    transform: initial;
    flex-shrink: 1;
    overflow-x: auto;
  }
  .refine {
    padding-top: 0;
  }
  .search-suggestions {
    li:first-child {
      padding-top: 1.2rem;
    }
    li:not(:last-child) {
      padding-bottom: 0.8rem;
    }
  }
  // overlay x scrollable categories area
  .refine__toggle::before {
    content: '';
    height: 30px;
    width: 53px;
    position: absolute;
    top: 50%;
    left: 0;
    background: linear-gradient(270deg, rgb(255 255 255) 0%, rgb(255 255 255 / 0%) 100%);
    transform: translate(-100%, -50%);
    pointer-events: none;
  }
  .logo-header {
    display: none;
  }
  .no-results-container {
    .collection-submenu {
      img {
        margin-bottom: 3.5rem;
      }
      .collection-card .collection-card__inner-content {
        min-width: 90%;
        .collection-submenu__item {
          .collection-card__title {
            padding-bottom: 1rem;
            height: auto;
          }
        }
      }
    }
  }
}
