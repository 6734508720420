.curator-carousel {
  background-color: $black;
  color: $white;
  position: relative;
  font-family: $silkSerifLight;
  padding: 18rem 0 28rem 0;
  overflow: hidden;
  .curator-carousel__title {
    font-size: 15rem;
    font-weight: 300;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    line-height: initial;
    z-index: 2;
    user-select: none;
    pointer-events: none;
  }
  .curator-carousel__description {
    font-size: 2.4rem;
    font-weight: 300;
    line-height: 2.8rem;
    margin: 0 auto 6rem auto;
  }
  .curator-carousel__carousel-wrapper {
    .swiper-wrapper {
      align-items: center;
      transition-timing-function: linear;
      -webkit-backface-visibility: hidden;
    }

    .curator-carousel-slide__img-holder {
      cursor: pointer;
      img {
        width: 100%;
        margin: auto;
      }
    }
    .curator-carousel-slide__text-holder {
      display: none;
    }
  }
  .curator-carousel-arrow-row {
    margin-top: 3.8rem;
    .col-3 {
      > button[aria-pressed='true'] {
        @include fixedSprite('play-white');
      }

      > button[aria-pressed='false'] {
        @include fixedSprite('pause-white');
      }
    }
    .curator-carousel__arrow-container {
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      .curator-carousel__arrow-txt {
        font-size: 1.6rem;
        margin: 0 0.8rem;
        color: white;
      }
    }
    .autoplay-carousel__play {
      margin-left: 3vw;
      &:focus {
        outline: 1px solid white;
        outline-offset: 2px;
      }
    }
    .display-none-button {
      display: none;
    }
  }
  .curator-carousel__zoom-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $black;
    z-index: 99;
    .gallery-wrapper,
    .gallery-wrapper {
      width: 100%;
      height: 100%;
    }
    .js-curator-carousel__zoom,
    .js-curator-carousel__zoom *:not(img) {
      width: 100%;
      height: 100%;
      position: relative;
    }
    .curator-carousel-slide__text-holder {
      width: 100%;
      height: auto;
      bottom: 8rem;
      position: absolute;
      display: block;
      font-size: 1.6rem;
      margin-top: 1.6rem;
      text-align: center;
    }
    .curator-carousel__carousel-wrapper {
      &.swiper-wrapper {
        margin-left: unset;
      }
    }
    .curator-carousel-slide__img-holder {
      img {
        max-height: 65rem;
        max-width: 65rem;
        margin: auto;
      }
    }

    .curator-carousel-slide__inner-slide {
      text-align: center;
    }

    .close-carousel-curator-zoom {
      @include fixedSprite('close-white');
      margin: 0;
      width: 3.2rem;
      height: 3.2rem;
    }
    .curator-carousel__zoom-control {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 1.6rem;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 5;
      .carousel-curator-zoom-button {
        &--increase {
          @include fixedSprite('plus-big');
        }
        &--decrease {
          @include fixedSprite('minus-big');
        }
      }
    }
    .swiper-container {
      height: 100%;
      .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .curator-carousel__slide {
    cursor: pointer;
  }
}
.modal-carousel-curator-open {
  overflow: hidden;
  header {
    z-index: -1;
  }
}
