.single-banner {
  &.parfum-banner {
    background-image: var(--background-mobile), var(--background-desktop);
    &:before {
      padding-top: 134%;
    }
  }
}

.myaccount-wrapper {
  .myaccount__nftMapper {
    justify-content: flex-start;
  }

  .back-btn-wrapper {
    text-align: center;
    position: static;
  }

  .myaccount__nftsNext {
    width: 50%;
  }
  .myaccount {
    &__emptyWalletButton {
      width: 343px;
    }
  }

  .myaccount__section {
    max-width: 100%;
    width: 100%;

    .profile-table-section {
      text-align: center;

      .text-section__dl {
        margin-left: auto;
        margin-right: auto;

        dt,
        dd {
          text-align: left;
        }
      }
    }
  }
  .wishlist__intro {
    margin-top: 40px;
  }

  .swiper-pagination-bullets {
    padding-bottom: 0;
  }
}
