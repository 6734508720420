.nft-landing {
  .nft-image {
    margin-top: 0;
    width: auto;
    margin-bottom: 1.6rem;
    // border: 3.5px solid;
    // border-image: repeating-linear-gradient(11deg, #d0d0ff, #ffcef9, #ffd7bf, #fff2bb, #ecffba, #acffc5, #b1f6ff 60%) 1;
  }
  .badges-wrapper {
    margin-top: 0;
  }

  &.proof-of-ownership {
    .nft-row {
      flex-direction: row-reverse;
      align-items: center;
    }
    .result-wrapper {
      .result-content {
        max-width: 80%;
        margin: 0 auto;
      }
      h1 {
        margin-bottom: 3.2rem;
        margin-top: 2.6rem;
      }
      border: none;
      padding: 0;
      #proof-result {
        border-top: 1px solid $grey_line;
        padding-top: 3.4rem;
      }
    }
  }

  .nft-landing-bottom {
    padding: 4.8rem 0;

    margin-bottom: 0;
    .col-12 {
      border-top: 1px solid $grey_line;
      padding: 3.2rem 0;
      h2 {
        font-size: 1.8rem;
        line-height: 2.2rem;
        margin-bottom: 1.6rem;
      }
      p {
        font-size: 1.4rem;
        line-height: 1.6rem;
      }
    }
  }
}
