.emailmybag {
  min-height: 80vh;
  padding: 4 * $space-unit;
  &__intro {
    margin-bottom: 6 * $space-unit;
  }
  &__buttons {
    margin-top: 2 * $space-unit;
    button {
      margin-bottom: 2 * $space-unit;
    }
  }
}
