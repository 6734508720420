.contact-us {
  .register_form {
    .login-register-social {
      justify-content: center;
    }
  }
  .button--secondary {
    &:hover {
      &::before {
        opacity: 0.7;
      }
    }
  }
  &__other {
    margin-top: ($space-unit * 12);
  }

  &__title {
    text-align: center;
    margin-bottom: ($space-unit * 3);
    @extend %primary-m-u;
  }
  &__form-title {
    @extend %primary-m-u;
    text-align: center;
    margin-bottom: ($space-unit * 3);
  }
  &__description {
    text-align: center;
    margin-bottom: ($space-unit * 6);
  }
  &__faqs {
    margin-bottom: ($space-unit * 6);
    // .accordion__group {
    //   @include border('top');
    // }
  }
  &__submit-message {
    text-align: center;
    margin-top: ($space-unit * 3);
    .capitalize {
      text-transform: capitalize;
    }
  }
  .line {
    margin-top: ($space-unit * 4);
    @include border('top');
  }
  .link-box {
    &__title {
      @extend %primary-s-u;
      margin-top: ($space-unit * 4);
      margin-bottom: ($space-unit * 3);
    }

    &__button.button {
      margin-top: ($space-unit * 4);
    }
  }
  .register_form {
    border-top: 1px solid #e4e4e4;
    .form-section-title {
      &.faster-social {
        text-align: center;
      }
    }
  }
}
// tfn section
[aria-controls='tfn-accordion'] {
  display: block;
  margin-top: 2rem;
  font-weight: bold;
}
#tfn-accordion {
  > p {
    padding-top: 2rem;
    padding-bottom: 0.8rem;
  }
  .button.call {
    margin-top: 2.4rem;
  }
}
