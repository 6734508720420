.product-image-button {
  height: auto;
}

body.has-ticker {
  .product-gallery-vertical {
    .product-image-button {
      height: 100%;
      .product-image {
        height: 100%;
      }
    }
  }
  .product-image-button {
    height: auto;
  }
}

.product-zoom {
  flex: 1;
  height: 100%;

  &-thumbs {
    $slideW: 5.4rem;
    $slideH: 8rem;
    // max-height: 43.2rem;
    max-height: ($slideH * 5) + (0.8rem * 4);
    max-width: unset;
    height: 100%;
    &.swiper-container {
      margin: unset;
      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 3.2rem;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 85.56%);
        z-index: 2;
        opacity: 1;
        transition: opacity 0.3s ease-out;
        pointer-events: none;
      }
      &::before {
        top: 0;
        transform: rotate(180deg);
      }
      &::after {
        bottom: 0;
      }
      &.start::before {
        opacity: 0;
      }
      &.end::after {
        opacity: 0;
      }
    }

    &--wrapper {
      height: 100vh;
      width: $slideW + (2.75rem * 2);
      padding: 0;
      flex-direction: column;
      gap: 1.7rem;
      align-items: flex-start;
      .product-zoom-thumbs__navbutton-prev,
      .product-zoom-thumbs__navbutton-next {
        width: 5.4rem;
      }
    }

    .swiper-slide {
      width: $slideW;
      height: $slideH;
    }

    &__navbutton {
      display: block;
      filter: invert(100%);
      &:focus-visible {
        outline-color: $black;
      }
      &:disabled {
        opacity: 0;
      }

      &-prev {
        @include fixedSprite('chevron-up');
        width: 2.4rem;
        height: 2.4rem;
      }
      &-next {
        @include fixedSprite('chevron-down');
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }
}

.product-zoom-modal {
  .modal__body {
    display: flex;
  }
}
