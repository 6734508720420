.membership-module {
  &__frame {
    padding: 1.6rem;
  }
  &__long-title {
    font-size: 1.8rem;
    margin: 2.4rem auto 0;
  }
  &__text-wrapper {
    margin-top: 2.4rem;
  }
}
