.stories-carousel {
  align-items: center;
  // padding: 4rem 0;
  overflow-x: hidden;

  .swiper-slide {
    background-color: $white;
  }
  .isActiveState {
    // transition: all 0.5s linear;
    transition-duration: 0.5s !important;
    transition-timing-function: linear !important;
    // transform: translateX(calc((((100% - (2px * 2)) / 3) - 62.639vw) / 2));
    .swiper-slide {
      &:not(.open-slide) {
        transform: scaleY(0.9);
        // height: 54.1rem;
        .stories-card {
          &__image {
            transition: all 0.5s linear !important;
            img {
              transform: scaleY(1.1);
            }
          }
        }
      }
    }
  }

  .mille {
    transition-duration: 1000ms !important;
  }
  .flex {
    display: flex;
  }
  &.row {
    margin-right: 0;
    margin-left: 0;
  }
  .card-swiper-container {
    justify-content: center;
  }
  &.inverted-colours {
    background-color: $black;
    color: $white;
    .scrollable-selector-swiper {
      &:after {
        border-color: transparent transparent $white transparent;
      }
      .swiper-slide {
        background-color: $white;
        &:before {
          background-color: $white;
        }
        .timeline-separator {
          background-color: $white;
        }
      }
    }
    .stories-card__arrow-txt {
      color: $white;
      margin: 0 1rem;
    }
  }
  &__intro {
    padding: 0 1.6rem;
    margin-bottom: 4rem;
    p {
      text-align: center;
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
  // &__title {
  //   text-transform: uppercase;
  //   font-size: 2.4rem;
  //   line-height: 2.8rem;
  //   text-align: center;
  //   margin-bottom: 1.2rem;
  // }

  &__top-column {
    position: relative;
    .inverted-colours & {
      &:after {
        border-color: transparent transparent $white transparent;
      }
    }
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 11px 11px 11px;
      border-color: transparent transparent $black transparent;
      position: absolute;
      left: calc(50% - 11px);
      bottom: 0;
    }
  }

  .stories-card__arrow-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2.4rem 0;
  }

  &__container {
    // background-color: #1e1e1e;
    padding: 6.4rem 0;
    // overflow-x: hidden;
    position: relative;

    // height: 733px;
    height: calc(46.682vw + 200px);
    justify-content: center;
    // align-items: center;
    // display: flex;

    // &:before {
    //   content: '';
    //   position: absolute;
    //   width: 100%;
    //   height: 20%;
    //   background: linear-gradient(0, rgba(0, 0, 0, 0) 25.89%, rgba(0, 0, 0, 0.5) 100%);
    //   top: 0;
    //   left: 0;
    // }
    // &:after {
    //   content: '';
    //   position: absolute;
    //   width: 100%;
    //   height: 20%;
    //   background: linear-gradient(180deg, rgba(0, 0, 0, 0) 25.89%, rgba(0, 0, 0, 0.5) 100%);
    //   bottom: 0;
    //   left: 0;
    // }
  }

  .cards-swiper {
    .swiper-wrapper {
      align-items: center;
    }
    .swiper-slide {
      transition: all 0.5s linear !important;
      // .swiper-slide-next,
      // .swiper-slide-prev,
      // .swiper-slide-active {
      //   transition: all 0.5s linear !important;
      // }
      width: auto;
      // height: 605px;
      height: 46.682vw;
      overflow: hidden;
      &.swiper-slide-duplicate-active {
        opacity: 1;
        z-index: 2;
        &.open-slide {
          // transition: all 0.5s linear;
          width: 62.639vw !important;
          // background-color: yellowgreen;
          .stories-card {
            padding: 7.6rem 5.6rem;
            &__image {
              width: 23.2422vw;
              opacity: 1;
            }
            &__text-column {
              opacity: 1;
            }
          }
        }
        &.noTransition {
          transition: none !important;
          // transition: all 0.5s linear, width 0s, opacity 0s !important;
          .stories-card {
            transition: none !important;
            &__image {
              transition: none !important;
              img {
                transition: none !important;
              }
            }
            &__text-column {
              transition: none !important;
            }
          }
        }
      }

      &.swiper-slide-active:not(.swiper-slide-duplicate-active) {
        transition: all 0.5s linear !important;
        opacity: 1;
        z-index: 2;
        &.open-slide {
          width: 62.639vw !important;
          // background-color: yellowgreen;
          .stories-card {
            padding: 7.6rem 5.6rem;
            &__image {
              width: 23.2422vw;
              opacity: 1;
            }
            &__text-column {
              opacity: 1;
            }
          }
        }
        &.noTransition {
          transition: none !important;
        }
      }
    }
  }

  .stories-card {
    padding: 0;
    display: flex;
    align-items: center;
    // max-width: 33vw;
    // width: 33vw;
    overflow: hidden;
    // height: 605px;
    height: 46.682vw;
    width: auto;
    transition: all 0.5s linear;
    &.swiper-slide-active.cursor-pointer {
      .stories-card__image {
        cursor: pointer;
      }
    }
    &__text-column {
      transition: all 0.5s linear;
      // max-width: 50.5rem;
      width: 35vw;
      left: 400px;
      opacity: 0;
      text-align: left;
      padding: 0 4.6rem;
      position: static;
    }
    &__image {
      display: flex;
      position: relative;
      // width: 100%;
      // width: 454px;
      height: 100%;
      max-width: 33vw;
      width: 33vw;
      flex-shrink: 0;
      z-index: 1;
      transition: all 0.5s linear;
      &:before {
        content: '';
        padding-top: 133.236%;
        display: block;
      }
      img {
        position: absolute;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transform: scaleY(1);
        transition: transform 0.5s linear;
      }
    }
    // &__title {
    //   text-transform: uppercase;
    //   font-size: 2.4rem;
    //   line-height: 2.8rem;
    //   margin-bottom: 0.8rem;
    // }
    &__external-title {
      // position: absolute;
      // bottom: 2rem;
      // z-index: 1;
      // color: $white;
    }
    &__data {
      text-transform: uppercase;
      font-size: 2rem;
      line-height: 2.4rem;
      margin-bottom: 1.6rem;
      font-weight: bold;
    }
    p {
      font-size: 1.4rem;
      line-height: 1.6rem;
      margin-bottom: 2rem;
    }
    .button-row {
      display: flex;
      justify-content: center;
      margin-top: 3.2rem;
      .button {
        display: inline-flex;
        margin: 0 0.4rem;
        width: auto;
        flex-grow: 1;
        flex-basis: 0;
      }
    }
  }
}
