.sticky-tray {
  &__name-price {
    justify-content: flex-start;
  }
  &__wrapper {
    padding-top: $space-unit * 2;
    padding-bottom: $space-unit * 2;
  }
  &__button .button {
    height: 4.8rem;
  }
}

.product-image-button {
  height: 100%;
}
body.has-ticker {
  .product-image-button {
    height: 100%;
  }
}
.product-hero-gallery {
  .container-product-button-video-temp {
    display: none;
    &.d-block {
      display: block;
    }
    &.fixed {
      .product-video-pause {
        position: fixed;
        bottom: 8.8rem;
      }
    }
  }
  .container-pagination {
    position: absolute;
    width: 100%;

    .swiper-pagination {
      &.swiper-pagination-bullets {
        bottom: 2.4rem;
      }
    }
    &.fixed {
      .swiper-pagination {
        &.swiper-pagination-bullets {
          position: fixed;
          bottom: 8.8rem;
        }
      }
    }
  }
  .container-product-button-video {
    .product-video-pause {
      bottom: 2.4rem;
    }
  }
}
