.product-refill {
  flex-direction: row;
  .dialog__title {
    position: relative;
    color: $black;
    top: 0;
    padding-bottom: 4rem;
  }
  .dialog__white-title {
    left: 3.2rem;
    top: 3.2rem;
  }
  &__main {
    flex: 0 0 50%;
    display: flex;
    padding: 0;
    align-items: center;
    background-color: $black;

    .selector__button,
    .selector__menu__item {
      color: $white;
    }

    p {
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: 300;
      color: $white;
    }
  }

  &__detail {
    flex: 0 0 50%;
    padding: $space-unit * 8;
  }
}
