.component_grenoble_performance {
  $viewportHeight: calc((var(--viewport-height) - #{$contextual-navigation-m}) * 3);
  height: $viewportHeight;
  // padding-bottom: calc((var(--viewport-height) - #{$contextual-navigation-m}) / 2);
  // padding-bottom: calc((var(--viewport-height) - #{$contextual-navigation-m}) / 2);
  text-align: center;
  position: relative;
  > .container {
    z-index: 0;
  }
  &__title-wrapper {
    padding-top: 3.2rem;
    .title {
      position: relative;
      font-size: 3.2rem;
      line-height: 3.8rem;
      padding-bottom: 2.4rem;
    }
  }
  &__image-wrapper {
    padding-bottom: 2.4rem;
    position: relative;
    z-index: 2;
    img {
      max-height: 50vh;
      margin: 0 auto;
    }
  }
  &__info-wrapper {
    .info-row {
      .logo {
        display: inline-block;
        margin-bottom: 1.6rem;
      }
      p {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
  }
  .experience-performance_info {
    margin-bottom: 5.6rem;
  }
  // OE-3802 edge case iphone 8
  @media screen and (max-height: 667px) {
    .component_grenoble_performance__title-wrapper {
      padding-top: 2.4rem;
      .title {
        padding-bottom: 1.6rem;
      }
    }
    .component_grenoble_performance__image-wrapper img {
      max-height: 45vh;
    }
  }
}
.page-template-technicaldetails .component_grenoble_performance {
  --bg-color: #{$grey_disabled};
  background-color: var(--bg-color);
  // transform: translateZ(0);
  // -webkit-backface-visibility: hidden;
  // -webkit-transform: translateZ(0) scale(1, 1);
}
