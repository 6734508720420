.form-select {
  @extend %primary-xs-u;
  width: 100%;
  position: relative;

  &::after {
    border-style: solid;
    border-width: $space-line $space-line 0 0;
    content: '';
    display: inline-block;
    height: 0.45em;
    right: 1rem;
    position: absolute;
    vertical-align: top;
    width: 0.45em;
    top: ($space-unit * 3);
    transform: rotate(135deg);
    pointer-events: none;
  }

  select {
    @extend %primary-xs;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: $input-height;
    color: $black;
    text-align: left;
    padding: 0.9rem 2.5rem 0.7rem 0.5rem;
    width: 100%;
    box-shadow: none;
    background-color: $grey_input;
    border: $space-line solid $grey_input;
    border-bottom: $space-line solid $grey_bottom;
    // @include animate;

    &:focus {
      border-bottom-color: $outline-color;
      outline: none;
    }

    &:disabled {
      cursor: none;
      pointer-events: none;
      opacity: $input-disabled-opacity;
      // background: $grey_disabled;
      // border-color: $grey_disabled;
      // color: $black_secondary;
    }

    &[aria-invalid='true'] {
      border-bottom-color: $status_error;
    }
  }
  .invalid-feedback {
    text-align: right;
  }
}
