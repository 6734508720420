.bottom-module {
  .monclernow-listing {
    padding: 0;
    padding-left: 0;
    padding-right: 0;
    &__article {
      width: 36.2rem;
      min-width: 36.2rem;
      margin-right: 0.2rem;
    }
    &::before,
    &::after {
      content: '';
      width: 2rem;
      min-width: 2rem;
      display: inline-block;
    }
  }
  &__title {
    text-transform: uppercase;
    margin-bottom: 4.2rem;
  }
}
