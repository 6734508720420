.contact-details {
  padding: 5.6rem 0 5rem;
  text-align: center;
  &.clothing-contact {
    padding-top: 0;
  }
  &.clothing-contact-details {
    padding: 0;
    .contact-details {
      padding: 0;
    }
  }
  &__alternate {
    // @extend .container;
    background-color: #efefef;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
    max-height: 5.6rem;

    .contact-details {
      &__column {
        width: 100%;
      }
      &__title {
        white-space: nowrap;
      }
      &__contacts {
        white-space: nowrap;
      }
      &__dropdown-container {
        display: flex;
        width: 100%;
        justify-content: flex-end;
      }
    }
  }

  &--modal {
    padding-top: 0;
  }

  &--footer {
    a,
    button {
      &:before {
        margin-right: 0;
      }
      &.whatsapp {
        &:before {
          height: 2.1rem;
        }
      }
      font-size: 1.6rem;
      line-height: 2rem;
      text-decoration: none;
      display: flex;
      align-items: flex-start;
      margin-bottom: 0.4rem;
    }
  }

  &__column {
    display: flex;
    align-items: center;
    gap: 1.2rem;
  }

  &__selector {
    font-weight: 800;
    text-decoration: underline;
    position: relative;
    cursor: pointer;

    &-wrapper {
      position: relative;
      display: inline-block;
      font-size: 1.6rem;
    }

    &-country {
      font-weight: 800;
      text-decoration: underline;
      position: relative;
      z-index: 1;
      display: inline-flex;
      align-items: center;
      gap: 0.2rem;

      &::after {
        content: '';
        @include fixedSprite('chevron-down');
      }
    }

    &-select {
      border: none;
      padding: 0;
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include z('select-over-menu');
      cursor: pointer;
    }

    &-label {
      font-size: 1.6rem;
      white-space: nowrap;
    }
  }

  &__title {
    @extend %primary-s-u;
  }

  &__alternate {
    .contact-details__contacts {
      margin: 0;
    }
  }

  &__contacts {
    padding: 0;
    margin: 2.6rem 0;
    font-size: 1.6rem;
    .contact-details--enhanced & {
      padding: 0;
      border-top: 0.1rem solid $grey_line;
      gap: 0;
      margin: 0;
      .contactus-modal & {
        border-top: none;
      }
    }
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;

    &-contact {
      list-style: none;
      padding: 0;

      .contact-details--enhanced & {
        justify-content: space-between;
        align-items: center;
        text-align: left;
        padding: 2.4rem 0;
        border-bottom: 0.1rem solid $grey_line;
        .contactus-modal & {
          &:last-child {
            border-bottom: none;
          }
        }
      }

      a,
      button {
        display: flex;
        text-decoration: none;
        align-items: center;
        .contact-details--enhanced & {
          margin-top: 2.4rem;
          &.wechat {
            width: 100%;
          }
        }
        &::before {
          width: 2rem;
          height: 2rem;
          position: relative;
        }
      }
      div {
        &::before {
          width: 2.4rem;
          height: 2.4rem;
          position: relative;
        }
      }
    }
  }
  &__title {
    .contact-details--enhanced & {
      margin-bottom: 0.8rem;
    }
  }
  &__description {
    margin-bottom: 4rem;
    line-height: 2.2rem;
    .contact-details--enhanced & {
      font-size: 1.8rem;
    }
  }
  &__dropdown-container {
    margin-top: 4rem;
    margin-bottom: 2.4rem;
    .contactus-modal & {
      margin: 0;
      margin-bottom: 2.6rem;
    }
  }
  &__contact-label {
    &--service {
      display: flex;
      align-items: center;
      margin-bottom: 0.8rem;
      text-transform: uppercase;
      font-size: 1.6rem;
    }
    &--time {
      font-size: 1.4rem;
    }
  }
  &__dot-status {
    margin-left: 1.6rem;
    &:before {
      content: '';
      width: 0.7rem;
      height: 0.7rem;
      border-radius: 50%;
      display: inline-block;
      margin-right: 0.6rem;
    }
    &--offline {
      color: #737373;
      &:before {
        background-color: #737373;
      }
    }
    &--online {
      color: $status_success;
      &:before {
        background-color: $status_success;
      }
    }
  }
  &__button {
    &--disabled {
      display: flex;
      color: #aaa;
      border-color: #aaa;
      cursor: default;
      &:hover {
        color: #aaa;
        border-color: #aaa;
        opacity: 1;
      }
      &.whatsapp,
      &.call,
      &.sms {
        &:hover {
          color: #aaa;
          border-color: #aaa;
          opacity: 1;
        }
        &:before {
          opacity: 0.3;
        }
      }
      .contact-details--enhanced & {
        margin-top: 2.4rem;
      }
    }
  }

  &__modal-btn {
    &.button--secondary {
      min-height: 3.6rem;
      white-space: nowrap;
    }
  }
  .call {
    &:hover {
      opacity: 1;
    }
  }
}

// PDP MODAL - CONTACT US

.contactus-modal {
  .modal__header {
    position: relative;
  }

  .dialog__title {
    font-size: 2.4rem;
    text-transform: uppercase;
    justify-content: center;
    margin-top: 5.8rem;
  }
  .contact-us__desc.container {
    padding-bottom: 3.2rem;
    line-height: 2.2rem;
    margin-bottom: 0;
    font-size: 1.8rem;
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
  .contact-details.contact-details--enhanced {
    padding: 0;
  }
  .contact-details {
    padding: 0;
    .container {
      padding: 0;
    }
    .container-clothing {
      width: 100%;
    }
  }
}

.drawers {
  .contact-us {
    padding: 3.2rem 1.6rem;
    overflow-y: auto;
    height: 100%;

    position: sticky;
    top: 5.6rem;
    transition: all 1s ease;
    transition-delay: 0.3s;
    .contact-us__desc {
      padding-bottom: 3.2rem;
      border-bottom: 1px solid #e4e4e4;
    }
    .contact-details {
      padding: 0;
      .wrapper-contact-us {
        width: 100%;
      }
    }
    .contact-details__dropdown-container {
      text-align: left;
    }
  }
}
.drawers {
  .contact-details__contact-label--service {
    flex-wrap: wrap;
    column-gap: 1.6rem;
    row-gap: 0.4rem;
    .contact-details__dot-status {
      margin: 0;
    }
  }
}
