// https://www.w3.org/TR/wai-aria-practices-1.1/examples/radio/radio-1/radio-1.html

.optionbox {
  &--address {
    .optionbox__item {
      @include col-width(4);
    }
  }
}
.optionbox--horizontal-three-items {
  max-height: 436px;
  @include scrollbar;
  .optionbox__item {
    @include col-width(4);
  }
}
