.modal {
  padding: 0 ($space-unit * 4) ($space-unit * 4);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $white;
  height: 100%;
  width: 100%;
  overflow: auto;
  // overflow-x: hidden;
  &.modal--no-padding {
    padding: 0;
  }

  &__body {
    max-height: inherit;
  }

  &.modal--centered {
    text-align: center;
    min-height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 68.6rem;
    min-width: 40%;
  }

  &.modal--minheight {
    min-height: 90vh;
    display: flex;
    align-items: stretch;
    .modal__body {
      width: 100%;
    }
  }

  &.taxmodal {
    min-height: auto;
    padding-bottom: 4rem;
    .vertex-error {
      color: $black;
      text-align: left;
      padding-top: 0.8rem;
      padding-bottom: 2.4rem;
      margin-bottom: 0.8em;
      font-size: 1.8rem;
      .title {
        display: flex;
        align-items: left;
        justify-content: center;
        text-transform: uppercase;
        padding-bottom: 2rem;
        i {
          margin-right: $space-unit;
        }
      }
    }
  }

  &.discover-more {
    .modal__body {
      padding-top: 3.2rem;
    }
    .withimage__left {
      background: initial;
      padding-right: 2.8rem;
      padding-top: 0.8rem;
    }
  }

  &.confirm-changepassword {
    .modal__body {
      > p {
        padding-top: 2.4rem;
        padding-bottom: 5rem;
      }
    }
  }

  &.subscribe-overlay,
  &.countdown-modal {
    min-height: 70vh;
    .modal__intro {
      margin-top: 0.8rem;
    }
    .modal__body,
    .product-notify {
      min-height: inherit;
    }
    form {
      padding-top: 2.4rem;
    }
  }

  &.safari-alert-modal {
    section {
      margin: 3rem 0;
    }
    img {
      margin: 3.2rem auto 0;
    }
  }

  &.login-modal {
    .login-registration__registration-successful {
      h2 {
        margin-top: 0;
        border-bottom: 1px solid $grey_line;
        padding-bottom: 2.4rem;
        margin-bottom: 2.4rem;
      }
    }
  }

  &__header-sticky {
    padding-top: 2.2rem;
    position: sticky;
    top: 0;
    background-color: $white;
    z-index: 1;
  }

  &.text-guidelines {
    .mbm-dark-mode & {
      background-color: #1e1e1e;
      color: $white;
      border-color: 1px solid #404040;
      .modal__header {
        background-color: #1e1e1e;
        color: $white;
      }
      .modal__close {
        filter: invert(1);
      }
    }
  }

  .register_form {
    .register_form__comunication-channels .channel-flags {
      display: grid;
      grid-template-columns: 2fr 2fr;
    }
  }

  &.continue-to-store {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    .mbm-dark-mode & {
      background-color: $black;
      color: $white;
      .button.button--primary {
        @extend .button--primary-inverted;
      }
      .button.button--secondary {
        @extend .button--secondary-inverted;
      }
      .modal__close {
        filter: invert(1);
        border: 1px solid $black;
      }
      .icon--mbm-black {
        filter: invert(1);
      }
      .divider {
        background-color: rgba($white, 0.25);
      }
      .stores-available {
        span {
          color: rgba($white, 0.55);
        }
      }
    }
    .modal__close {
      top: 7rem;
      right: 50%;
      transform: translateX(50%);
      background: $white;
      box-shadow: 0 3px 20px rgba(0, 0, 0, 10%);
      border-radius: 50%;
      padding: 8px;
      position: absolute;
    }
    .stores-available {
      text-align: left;
      margin-bottom: 3.2rem;
      @extend %primary-s;
      ul {
        list-style: disc;
        padding-left: 2.4rem;
      }
      span {
        color: $black_secondary;
        margin-left: $space-unit;
      }
    }
    .modal__body {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      .icon--mbm-black {
        width: 135px;
        height: 30px;
        margin: 2rem 0;
        flex-shrink: 0;
      }
    }
    .continue-to-store__container {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding-top: 56px;
      h3 {
        @extend %primary-m-u;
      }
    }
    .continue-to-store__container__column {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      text-align: center;
      .button {
        margin-bottom: ($space-unit * 2);
      }
      .button--tertiary {
        margin-top: 1.2rem;
        margin-bottom: 0;
      }
      .divider {
        width: 100%;
        margin: 2.4rem 0;
      }
    }
    .QRCodeImg {
      margin: 4rem auto;
      shape-rendering: crispedges;
      background: white;
      // width: 206px;
    }
    .qrcode-desc {
      margin: 0 auto;
      margin-top: 1.6rem;
    }
    .image-share-circle {
      width: 30rem;
      height: 30rem;
      border-radius: 50%;
      background-color: $black;
      margin: 5.2rem auto;
      overflow: hidden;
      border: 1px solid #404040;
      img {
        width: 100%;
      }
    }
    .url-copy-box {
      word-break: break-all;
      background-color: $grey_input;
      padding: 1.4rem;
      text-align: center;
      font-size: 1.4rem;
      line-height: 1.6rem;
      position: relative;
      margin-bottom: 1.6rem;
      .mbm-dark-mode & {
        color: $black;
      }
      .copy-button {
        position: absolute;
        right: 1.1rem;
        top: 1rem;
      }
    }
    .share-buttons {
      list-style: none;
      padding: 0;
      display: flex;
      justify-content: center;
      .mbm-dark-mode & {
        li {
          a,
          button {
            filter: invert(1);
          }
        }
      }
      li {
        margin: 0 1.8rem;
      }
    }
    .copied-alert {
      position: absolute;
      background: rgba($black, 0.8);
      color: $white;
      text-align: center;
      width: 100%;
      font-size: 1.4rem;
      line-height: 1.6rem;
      padding: 1.2rem;
      .mbm-dark-mode & {
        background: rgba($white, 0.9);
        color: $black;
        .icon--close-white {
          filter: invert(1);
        }
      }
      button {
        position: absolute;
        right: 1.2rem;
        top: 0.7rem;
      }
    }
  }

  &.clientservice-modal {
    .dialog__desc {
      padding-bottom: 2.4rem;
    }
    .link-box {
      padding-top: 2.4rem;
      padding-bottom: 2.4rem;
      &:not(:last-child) {
        border-bottom: 1px solid $grey_line;
      }
      .link-box__title {
        margin-top: 0;
      }
      .link-box__button {
        margin-bottom: 0;
      }
    }
  }

  &.mbm-modal {
    .bookanappointment__right {
      overflow-y: auto;
      // padding-bottom: 3.2rem;
    }
  }

  &.tfn-modal {
    // min-width: 60%;
    .modal__body {
      padding-top: 2.4rem;
      p {
        margin-bottom: 2.4rem;
      }
    }
    .row.spacing {
      margin-bottom: 2.8rem;
    }
  }

  // -- measmerizemodal css fix
  &.measmerizemodal {
    display: flex;
    flex-direction: column;
    max-height: none;
    .modal__body {
      flex: 1;
      .accordion__group,
      .tabs {
        height: 100%;
        display: flex;
        flex-direction: column;
        .react-tabs__tab-list {
          @media only screen and (max-width: get($grid-breakpoints, md)) {
            display: flex;
            flex-direction: column;
            .react-tabs__tab {
              margin: 0;
              text-align: left;
              display: flex;
              &:after {
                display: none;
              }
              &.react-tabs__tab--selected {
                font-weight: bold;
              }
            }
          }
        }
        // .accordion__item.measmerizeitem,
        // .container:not(.react-tabs__tab-list) {
        //   height: 100%;
        //   display: flex;
        //   flex-direction: column;
        //   .accordion__panel,
        //   .react-tabs__tab-panel {
        //     flex: 1;
        //     > div {
        //       height: 100%;
        //     }
        //     .measmerizecontainer {
        //       height: 100%;
        //       #meas-widget-iframe {
        //         height: 100%;
        //       }
        //     }
        //     .sizechart__filters {
        //       bottom: -2rem;
        //     }
        //   }
        // }
      }
    }
  }

  &.nft-modal {
    min-width: auto;
    max-width: 45rem;
    &.transfer-ethereum {
      width: 45rem;
      max-width: 100%;
    }
    .dialog__description {
      width: 100%;
    }
    .QRCodeImg {
      margin-top: 2.4rem;
      max-width: 20rem;
    }
    .proof-of-ownership {
      .button {
        margin-top: 1.6rem;
      }
    }
    .ethereum-address-box {
      position: relative;
      &.address-valid {
        &::after {
          content: '';
          right: $space-unit * 2;
          width: ($space-unit * 2);
          height: ($space-unit * 2);
          @include fixedSprite('checkmark');
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      input {
        background-color: $grey_input;
        border: none;
        border-bottom: 1px solid #949494;
        padding: 1rem 0.8rem;
        display: block;
        width: 100%;
        position: relative;
        text-align: left;
        margin-top: 0.4rem;
      }
    }

    .url-copy-box {
      position: relative;
      .copied-label {
        position: absolute;
        right: 0;
        bottom: -2rem;
        font-weight: bold;
      }
      button {
        background-color: $grey_input;
        border-bottom: 1px solid #949494;
        padding: 1rem 0.8rem;
        display: block;
        width: 100%;
        position: relative;
        text-align: left;
        margin-top: 0.4rem;
        word-break: break-all;
        padding-right: 4rem;
        i {
          position: absolute;
          right: 0.8rem;
          top: 0.7rem;
        }
      }
    }
    &.nft-utility {
      .dialog__description {
        width: 100%;
      }
      h3 {
        margin-top: 2.4rem;
        margin-bottom: 2rem;
        text-transform: uppercase;
      }
    }
    &.nft-history {
      padding: 0;
      .modal__header {
        padding: 3.2rem 3.2rem 0 3.2rem;
      }
      .nft-detail-section {
        padding: 2.4rem 3.2rem 1.6rem 3.2rem;
        .image-wrapper {
          padding-right: 1.6rem;
        }
      }
      .nft-history-section {
        background: linear-gradient(180deg, #f9f9f9 0%, #ffffff 100%);
        border-top: 1px solid $grey_line;
        padding: 2.4rem 3.2rem 3.2rem 3.2rem;
        &__item {
          border-bottom: 1px solid $grey_line;
          padding-bottom: 0.8rem;
          margin-bottom: 0.8rem;
          h4 {
            font-size: 1.6rem;
            line-height: 2rem;
          }
          p {
            font-size: 1.2rem;
            line-height: 1.6rem;
            color: $black_secondary;
          }
        }
      }
    }
  }

  &.nft-transfer-ownership {
    .transfer-ownership-section {
      padding: 1.6rem 0;
      border-bottom: 1px solid $grey_line;
      align-items: center;
      &:last-child {
        border-bottom: none;
      }
      &__title {
        font-size: 1.6rem;
        margin-bottom: 1.6rem;
      }
      .button {
        margin-top: 2rem;
      }
      .coming-soon {
        text-transform: uppercase;
        display: block;
        text-align: right;
        font-size: 1.6rem;
        color: $black_secondary;
      }
      .error {
        color: $status_error;
        display: block;
        margin-top: 0.8rem;
      }
    }
  }

  &.cross-country-info {
    .dialog__title {
      font-weight: bold;
    }
  }

  &.vto-modal {
    .vto-modal__detail {
      padding: 2.4rem 1.6rem;
      ol {
        list-style: decimal;
        padding-top: 4rem;
        li {
          padding-top: 0.8rem;
          padding-bottom: 0.8rem;
        }
      }
    }
    .modal__close {
      padding: 1.2rem;
    }
    .modal__body {
      display: grid;
      // grid-template-columns: repeat(2, 1fr);
      > img {
        display: none;
      }
    }
    .qr-code-container {
      display: flex;
      justify-content: center;
      margin-top: 6.4rem;
      margin-bottom: 6.4rem;
      shape-rendering: crispedges;
    }
  }

  &.vto-enabled {
    max-width: initial;
    max-height: initial;
    width: 100%;
    height: 100%;
    background: initial;
    .modal__body {
      width: 100%;
      height: 100%;
      overflow: hidden;
      &::before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 6.4rem;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
      }
    }
    .modal__close {
      &::before {
        @include fixedSprite('close-white');
      }
    }
  }
  &.suggested-country-modal {
    min-width: auto;
    max-width: 45.8rem;
    padding: 0 3.2rem 3.6rem;
    .button {
      margin-bottom: 1rem;
      &.button--tertiary {
        line-height: 3.6rem;
      }
    }
    .modal__header {
      padding-top: 3.6rem;
    }
    .button:last-child {
      margin-bottom: -0.6rem;
    }
  }
  &.curator-modal {
    .product-notify__detail {
      padding-top: 0;
    }
    .modal__intro {
      margin-top: 0;
    }
    .newsletter-modal__gender-wrapper {
      margin-top: 2.4rem;
      margin-bottom: 2.8rem;
      .fieldset--plain {
        margin: 1.6rem 0 1.6rem 0;
      }
    }

    .newsletter-modal__radio-wrapper {
      .checkbox-wrapper {
        .checkbox {
          padding-bottom: 0;
        }
        .checkbox__field {
          .form-group__label {
            &::after {
              top: -0.5rem;
            }
          }
        }
      }
    }

    .newsletter-modal__privacy-flag {
      .checkbox {
        padding-bottom: 0;
      }
      .checkbox__field {
        .form-group__label {
          padding-bottom: 0;
        }
      }
    }
    .newsletter-modal__required-label {
      margin-top: 2rem;
    }
  }
  &.bookanappointment {
    overflow: hidden;
  }
  &.black {
    background-color: $black;
    &.subscribe-overlay,
    &.countdown-modal {
      min-height: 100vh;
    }
    .modal__close {
      filter: invert(1);
    }
    .product-notify__detail {
      background-color: $black;
      color: $white;
      .modal__header-sticky {
        padding-top: 2.5rem;
        background-color: transparent;
      }
      .modal__intro {
        margin-bottom: 4.1rem;
      }
      .modal__separator {
        border-top: 0.5px solid rgba(255, 255, 255, 0.3);
        button.button {
          margin-top: 4rem;
        }
      }
      form {
        padding-top: 2.9rem;
      }
      input {
        color: $white;
        border-color: white;
        &[type='email'] {
          background-color: rgba(255, 255, 255, 0.1);
          border-width: 0 0 1px 0;
        }
      }
      .radio input[type='radio']::after {
        background-color: white;
      }
      fieldset {
        margin-bottom: 7.2rem;
        .privacy-flag-container {
          margin-bottom: 0;
        }
      }
      .newsletter-modal__gender-wrapper {
        .fieldset--plain {
          margin-bottom: 2.6rem;
        }
        .form-group {
          margin-bottom: 0;
        }
      }
      .note-required {
        display: block;
      }
      .note {
        color: rgba(255, 255, 255, 0.5);
      }
      button.button {
        background-color: $white;
        color: $black;
        margin-top: 1.8rem;
      }
    }
  }
  &.privacy-confirm-modal {
    .modal {
      &__divisor {
        display: block;
        position: static;
      }
      &__body {
        padding: 2.4rem 0 1.6rem;
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
      }
      &__footer {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
      }

      &__header {
        flex-direction: row-reverse;
        align-items: center;

        h2 {
          flex: 1;
        }
      }

      &__close {
        position: static;
        padding: 0;
      }
    }
  }
  .note-required {
    display: none;
  }
  .newsletter-modal__gender-wrapper {
    legend {
      text-transform: uppercase;
    }
    .form-group {
      display: flex;
      flex-wrap: wrap;
      row-gap: $space-unit;
      label {
        width: 50%;
      }
    }
  }
}

.modal__header {
  display: flex;
  // position: relative;
  flex-direction: column;
  padding-top: ($space-unit * 8);
}

.modal__divisor {
  position: absolute;
  width: 100%;
  left: 0;
  margin: 0;
  border: none;
  @include border('top');
  &.noabs {
    position: relative;
  }
}

.dialog__message {
  max-width: 29.3rem;
  margin-bottom: 2.4rem;
}

.dialog__title {
  @extend %primary-m;
  //padding-top: ($space-unit * 8);
  padding-bottom: 0.8rem;
  //font-weight: 300;
}

.dialog__description {
  // line-height: 1.6rem;
  // margin-top: -0.6rem;
  margin-bottom: ($space-unit * 6);
}

.modal__close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 1.6rem;
  z-index: 3;
  &::before {
    content: '';
    @include fixedSprite('close');
    display: block;
  }
  &:focus-visible {
    outline: initial;
    &::before {
      outline: $outline-width solid $outline-color;
      outline-offset: -1px;
    }
  }
  &.is__sticky {
    position: sticky;
    left: 100%;
    margin-right: -3.2rem;
    padding-right: 0;
  }
}

.modal--button-spacing {
  top: 20px;
  right: 16px;
}

.modal__intro {
  margin: ($space-unit * 6) 0;

  .status {
    @extend %primary-s;
    display: block;
    margin-bottom: ($space-unit * 2);
  }
}

.overlay-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: $black_secondary;
  @include z(modal);
}

.login-modal-overlay {
  z-index: 11;
}

.vto-overlay {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.country-modal,
.language-wrapper {
  ul,
  ol {
    @include reset-list;
    li {
      a {
        @extend %primary-s;
        @include del-link;
        padding: ($space-unit) 0;
        // text-transform: capitalize;
        display: inline-flex;
        align-items: center;

        .countryname {
          margin-right: 0.6rem;
          display: inline-block;
        }

        .icon--bag {
          width: 1.8rem;
          height: 1.8rem;
        }
      }
    }
  }
}

.ReactModal__Overlay {
  opacity: 0;
  @include animate(0.4, 'opacity');
  &--after-open {
    opacity: 1;
  }
  &--before-close {
    opacity: 0;
  }
}

.ReactModal__Content {
  transform: translate(-50%, -50%) translateY(3.2rem);
  @include animate(0.4, 'transform');
  &--after-open {
    transform: translate(-50%, -50%);
  }
  &--before-close {
    transform: translate(-50%, -50%) translateY(3.2rem);
  }
}

.ReactModal__Content--after-open {
  transform: translate(-50%, -50.1%); // https://jira.industries-group.com/browse/ONE-32708
}

.ReactModal__Content {
  &.drawers {
    .contact-details {
      visibility: hidden;
      opacity: 0;
      transform: translateY(125px);
      transition: all 1s 0.3s cubic-bezier(0.25, 0.25, 0.5, 1);
    }

    &.ReactModal__Content--after-open {
      .contact-details {
        opacity: 1;
        visibility: visible;
        pointer-events: initial;
        transform: translateY(0);
        transition-property: all;
      }
    }
  }
}

#notifyme {
  .modal__body,
  .product-notify {
    height: 100%;
  }
  .dialog__title {
    display: flex;
    align-items: center;
    > button {
      padding-bottom: 0;
      margin-bottom: 0;
      margin-right: $space-unit * 2;
    }
  }
}

.palette-modal {
  display: flex;
  &__image {
    position: relative;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__body {
    padding: 2.2rem 1.6rem;
    h2 {
      margin-top: 2.4rem;
    }
    h3 {
      margin-bottom: 2.4rem;
    }
  }
}

#mbm-email-qr {
  .product-notify__main {
    position: relative;
    overflow: hidden;
    > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .product-notify__detail {
    form {
      border-top: initial;
      font-size: 1.4rem;
    }
    .modal__intro {
      font-size: 1.4rem;
      font-family: 'MonclerGothic-Light', arial, sans-serif;
    }

    .mt-24 {
      p.mt-12.mb-24,
      p.bold.mb-12 {
        font-size: 1.4rem;
      }
    }
  }
}

#mbm-baa {
  .modal__body {
    .bookanappointment__body {
      .bookanappointment__right {
        font-family: 'MonclerGothic-Light', arial, sans-serif;
        .container-body-bookanappointiment {
          .bookanappointment-mbm__descstores {
            font-size: 1.4rem;
          }
          .bookanappointment-mbm__stores {
            li > .store-features {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }
}

.withimage {
  &__body {
    // display: grid;
    // grid-template-columns: 50% 50%;
    // grid-template-rows: 100%;
    // height: 100%;
    min-height: 300px;
    display: flex;
  }
  &__right {
    overflow: hidden;
    overflow-y: auto;
    // margin-top: 2.2rem;
    // padding-left: $space-unit * 4;
    // padding-right: $space-unit * 4;
    padding: 2.2rem 1.6rem;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  &__left {
    display: none;
  }
}
.drawers {
  position: absolute;
  height: 100vh;
  background: white;
  top: 0;
  right: 0;
  transform: translate(0);
  width: 100%;
}

.client-service__modal--offline {
  .contacts-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2.4rem 0;
  }
}

.modal__body {
  .mandatory-title {
    color: $grey_interactive;
  }
}
