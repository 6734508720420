.curator-long-copy {
  .curator-long-copy-container {
    padding: 8rem 0;
    .long-copy-text {
      p {
        font-weight: 300;
        font-size: 24px;
        line-height: 28px;
      }
    }
    .long-copy-paragraph {
      column-count: 1;
      p {
        text-transform: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        margin: 0 auto;
      }
    }
  }
}
