.cta {
  @extend %primary-xs;
  position: relative;
  color: $black;

  &--edit {
    padding-right: 1.8rem;
    &::after {
      content: '';
      @include fixedSprite('chevron-right');
      @include center('vertical');
      right: 0;
    }
  }

  &--editpencil,
  &--remove,
  &--saveforlater {
    display: flex;
    align-items: center;
    flex-shrink: 1;
    text-align: left;
  }

  &--editpencil {
    &::after {
      content: '';
      @include fixedSprite('edit');
    }
  }

  &--remove {
    &::after {
      content: '';
      @include fixedSprite('close-2');
    }
  }

  &--saveforlater {
    &::after {
      content: '';
      @include fixedSprite('chevron-left-big');
      transform: rotate(180deg);
    }
  }
}
