.promo-module {
  @include appearAnimation();
}

.promo-module__inner-container {
  display: flex;
  align-items: flex-end;
}

.promo-module__promo-column {
  width: 100%;
  position: relative;
  text-decoration: none;
  display: block;

  &:focus {
    outline: none;
    .promo-column__cta {
      @include focus-underline;
    }
  }

  &.image-background {
    width: 100%;
    overflow: hidden;
    position: relative;
    display: block;
    &--top {
      --gradient-angle: 180deg;
      --opacity: 0;
    }
    &--whole,
    &--half {
      --gradient-angle: 360deg;
      --opacity: 0.2;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }
    &--darker {
      &::after {
        background: linear-gradient(var(--gradient-angle), rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, var(--opacity)) 100%);
      }
    }
    &--bright {
      &::after {
        background: linear-gradient(0deg, rgba(63, 63, 63, 0.7) 0%, rgba(63, 63, 63, 0) 100%);
      }
    }
  }

  &.text-shadow-active {
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.5), 0 0 2px rgba(0, 0, 0, 0.25);
  }
}

.promo-column__img-holder {
  width: 100%;
  overflow: hidden;
  position: relative;
  display: block;

  .img-desk-none {
    display: none;
  }
  img {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 100vw;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }
}

.promo-column__title {
  display: block;
}

.promo-column__cta {
  margin-top: ($space-unit * 3);
  display: inline-block;
  & ~ .promo-column__cta {
    margin-left: 2rem;
  }
}

.promo-column__text-holder {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: center;
  color: $white;
  width: 100%;
}

// Margin property
.promo-module--internal-border {
  margin: ($space-unit * 8) 3.2rem;
  .promo-module__promo-column {
    margin: 0 0.8rem;
  }
}

.promo-module--external-border {
  padding: ($space-unit * 8);
}

//alignment
.promo-module--center-bottom {
  .promo-column__text-holder {
    transform: translateX(-50%);
    bottom: 4.8rem;
    top: initial;
  }
}

.promo-module--center-top {
  .promo-column__text-holder {
    transform: translateX(-50%);
    top: 10rem;
  }
}

.promo-module--center-lower-cta {
  .promo-column__text-holder {
    transform: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 4.8rem;
  }

  .promo-column__title {
    margin: auto 0;
  }

  .promo-column__cta {
    margin-top: -4.8rem;
  }
}

.promo-module--center-upper-title-cta,
.promo-module--center-lower-title-cta {
  .promo-module__promo-column {
    display: flex;
  }
  .promo-column__text-holder {
    color: $black;
    position: static;
    transform: none;
  }
}

.promo-module--center-upper-title-cta {
  .promo-module__promo-column {
    flex-direction: column;
  }
  .promo-column__text-holder {
    margin-bottom: 2.5rem;
  }
}

.promo-module--center-lower-title-cta {
  align-items: flex-start;
  .promo-module__promo-column {
    flex-direction: column-reverse;
  }
  .promo-column__text-holder {
    padding-top: ($space-unit * 8);
    background-color: white;
  }
}
