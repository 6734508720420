.product-highlight--img-bigger {
  .product-slide__img {
    width: 65rem;
  }
}
.product-highlight {
  display: block;
}

.product-highlight__text-holder {
  width: 100%;
  padding: 10rem ($space-unit * 4) 8rem;
}

.product-highlight__img-holder {
  width: 100%;
}

.product-highlight__text {
  margin-top: ($space-unit * 9);
}

.product-highlight__pagination {
  margin-top: 2rem;
}

.product-slide__caption {
  margin-top: ($space-unit * 10);
}

.img-holder--external-border {
  img {
    padding: ($space-unit * 5);
  }
}
