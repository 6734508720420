.wishlist {
  &__single-product {
    &__remove-btn,
    &__share-btn {
      transform: none;
      opacity: 1;
      .icon-btn__text {
        display: none;
      }
    }
  }
}
