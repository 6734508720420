.modal {
  &.shop-the-look-modal {
    padding: 0;
    background-color: transparent;
    color: white;
    min-height: 100%;
    .modal__body {
      max-width: 100%;
    }
    .modal__header {
      z-index: 2;
      .dialog__title {
        display: none;
      }
    }
    .modal-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    .product-tile {
      .selector-size {
        color: white;
        height: auto;
        .selector__button {
          color: white;
          &:hover,
          &:focus,
          &:active {
            color: white;
          }
          span {
            color: white;
          }
          &:disabled {
            color: white;
          }
        }
        .selector__menu {
          span,
          li {
            color: white;
            display: block;
          }
          &__item {
            &:hover:not(.selected) {
              .selector__menu__item__label {
                &::after {
                  transform: translateX(-50%) scaleX(1);
                }
              }
            }
            &__label {
              @extend %primary-s-u;
              position: relative;
              display: inline-block;
              &::after {
                content: '';
                height: 0.1rem;
                bottom: 0;
                background-color: $grey_line;
                width: 100%;
                min-width: 1.6rem;
                @include center('horizontal');
                @include animate(0.3);
                visibility: visible;
                transform: translateX(-50%) scaleX(0);
              }
            }
            &.selected {
              .selector__menu__item__label {
                &::after {
                  background-color: $white;
                  transform: translateX(-50%) scaleX(1);
                }
              }
            }
          }
        }
      }
      .product-tile__info {
        color: white;
        padding-right: 1.6rem;
        padding-left: 1.6rem;
        background: $black;
        z-index: 1;
        .button {
          border: 1px solid white;
          background: $black;
          color: white;
        }
        .button--added-item {
          background-color: $white;
          color: black;
          border: 1px solid black;
        }
        .product-label {
          display: none;
        }
        .product-tile__quick-buy {
          display: none;
        }
        .product-tile__name,
        .product-tile__price {
          text-align: center;
        }
        .product-tile__price {
          margin-bottom: 2.4rem;
        }
      }
    }
    .modal__close {
      @include fixedSprite('close-white');
      position: absolute;
      top: 2rem;
      right: 2rem;
      &::before {
        display: none;
      }
    }
  }
  .overlay-shop-the-look-close {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.overlay-shop-the-look-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: $black_secondary;
  @include z(modal);
}
.carousel-button__shop-the-look {
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 2.4rem;
  .shop-the-look__arrow {
    margin-top: initial;
    width: 2.4rem;
    height: 2.4rem;
    z-index: 1;
    outline-color: $white;
  }

  .shop-the-look-next {
    @include fixedSprite('arrow-right-white-l');
  }
  .shop-the-look-prev {
    @include fixedSprite('arrow-left-white');
  }
}
.curator-image-shop-the-look-container {
  position: relative;
  .container-shop-the-look {
    color: black;
    .button-shop-the-look-open {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 20px;
      text-decoration: none;
      display: flex;
      align-items: center;
      column-gap: 1.2rem;
      position: absolute;
      top: 2rem;
      right: 2rem;
      &::after {
        content: '';
        @include fixedSprite('plus-big-black');
        width: 3rem;
        height: 3rem;
      }
      span {
        display: none;
      }
    }
  }
}
.curator-image-shop-the-look {
  &.white {
    .container-shop-the-look {
      .button-shop-the-look-open {
        color: $white;
        &::after {
          content: '';
          @include fixedSprite('plus-big');
          width: 2.4rem;
          height: 2.4rem;
        }
      }
    }
  }
}
.container-shop-the-look {
  .swiper {
    overflow: hidden;
  }
}
