.component-two-column-info-block {
  text-align: center;
  box-sizing: border-box;
  padding: 4rem 0;
  overflow: hidden;
  .container-two-column-info-block {
    // &.accordion__group .opened-accordion {
    //   .view-more_button {
    //     &[aria-expanded='true'] {
    //       display: block;
    //       .header__close {
    //         display: flex;
    //       }
    //     }
    //   }
    // }
    &-border {
      border: 0.1rem solid #e4e4e4;
      padding: 0 2.05rem;
      padding-bottom: 4rem;

      .two-column-info-block_title-content {
        text-align: center;

        .button.two-column-info-block_cta {
          margin-bottom: 2.4rem;
        }
        .two-column-info-block {
          &_title {
            padding-top: 6rem;
            padding-bottom: 1.6rem;
          }

          &_description {
            padding-bottom: 2.4rem;
          }

          &_cta {
            width: auto;
            padding: 0 2.4rem;
            align-self: center;
            margin-bottom: 0.4rem;
          }
        }
      }

      .accordion__panel {
        transition: all 0.5s 0s linear;
        & > * {
          padding-top: 0;
        }
      }

      .view-more_button {
        padding: 0;
        width: 100%;
        border: none;
        background-color: $grey_disabled;
        justify-content: center;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

        .header__open,
        .header__close {
          right: 0;
          -webkit-transform: none;
          transform: none;
          position: relative;
        }
      }

      .two-column-info-single-block {
        &-items {
          list-style: none;
          padding-top: 1.6rem;
          text-align: left;
          padding-left: 0;
          padding-bottom: 0;
        }
        &-item {
          display: flex;
          flex-direction: column;
          &:last-child {
            padding-bottom: 4rem;
          }
        }

        &-title {
          display: block;
          line-height: 2.2rem;
          padding-bottom: 0.8rem;

          &-main {
            text-transform: uppercase;
            font-size: 1.8rem;
            flex: 1 1 0;
            white-space: nowrap;
            display: inline;
          }
        }

        &-label {
          font-size: 1.8rem;
          padding-bottom: 0.8rem;
          flex: 1 1 0;
          text-align: right;
          white-space: nowrap;
          display: inline;

          &-subtitle {
            display: inline;
            float: right;
            opacity: 0.55;
            text-transform: uppercase;
          }

          &-link {
            opacity: 0.55;
          }
        }
      }

      .two-column-info-description-block {
        font-size: 1.8rem;
        line-height: 2.2rem;
        // padding-bottom: 4rem;
        display: flex;
        flex-direction: column;

        &_description {
          padding-bottom: 4rem;
        }

        &_description-link {
          text-align: center;
          opacity: 0.55;
          padding-bottom: 4rem;
        }
      }

      .please-note {
        text-align: left;
        // padding-top: 4rem;
        font-size: 1.6rem;
        line-height: 2rem;
        opacity: 0.55;
        position: relative;
        bottom: 4rem;
        left: 0;
        right: 0;
        border-top: 1px solid $grey_input;
        // padding-bottom: 1.6rem;
        padding-top: 4rem;
        &-title {
          text-transform: uppercase;
        }
      }
    }
  }

  .accordion-collapsed .two-column-info-block_cta {
    display: none;
  }

  .accordion-collapsed .accordion__panel {
    visibility: hidden;
    height: 0;
  }

  .accordion-collapsed {
    .two-column-info-block_title-content {
      .two-column-info-block_description {
        padding-bottom: 4rem;
      }
    }
  }
}
