.interactive-cards {
  &__top-column {
    padding-top: ($space-unit * 33);
    padding-bottom: ($space-unit * 33);
  }
  &__intro {
    margin-bottom: 18rem;
    p {
      font-size: 2rem;
      line-height: 2.4rem;
      max-width: 45rem;
      margin: 0 auto;
      span {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
  .interactive-card__arrow-container {
    margin-top: 18rem;
  }
  &__container {
    padding: 9.5rem;
  }
  &:not(.no-timeline) {
    .scrollable-selector-swiper {
      .swiper-slide {
        .scrollable-selector {
          max-width: 25rem;
          font-size: 5.4rem;
          margin: 0 1.5rem;
        }
      }
    }
    .interactive-card {
      .button-row {
        margin-top: 2.8rem;
        display: flex;
        flex-direction: row;
        align-items: baseline;
        column-gap: 2.4rem;
        .button {
          &:first-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .scrollable-selector-swiper {
    .swiper-slide {
      .timeline-separator {
        margin: 0 0.9rem;
        &:first-child {
          margin: 0 0.9rem 0 1.8rem;
        }
        &:last-child {
          margin: 0 1.8rem 0 0.9rem;
        }
      }
    }
  }

  .cards-swiper {
    .swiper-slide {
      width: 38.5rem;
    }
  }
}
