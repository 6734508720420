.faq-component {
  .title {
    margin-bottom: 2.4rem;
  }
  .details {
    margin-bottom: 4.8rem;
  }
}
.cs-detail-page {
  .faqs-container {
    .faq-component-module {
      margin: 5.6rem 0;
      .title {
        margin-bottom: 5.6rem;
      }
    }
  }
}
:not(.cs-detail-page) {
  .faqs-container {
    margin-bottom: 2.4rem;
    .faq-component-module {
      margin: 8rem 0;
      .details {
        margin-bottom: 2.6rem;
      }
    }
  }
}
