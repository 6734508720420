.refine {
  position: sticky;
  top: $header-height-mobile - 0.1rem;
  width: 100%;
  @include z(overlay);

  &.expanded {
    // Fix for OE-25972: transform: translateY is injected by element style, the only way to override is this "!important" when is expanded :(
    transform: none !important;
  }

  .query-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 30vw;
    height: 1.6rem;
  }
  .search-query {
    position: absolute;
    text-align: left;
    width: 100%;
    @include text-shorten;
  }

  sup {
    position: relative;
    top: -$space-unit;
    margin-left: 0.2rem;
    font-size: 1rem;
    // color: $black_secondary;
  }

  .modal__close {
    display: none;
  }

  .refine__footer,
  .refine__header {
    display: none;
  }

  .refine__header__title {
    @extend %primary-m;
  }

  .results-row {
    display: flex;
    align-items: flex-end;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-top: 4rem;
    padding-bottom: 1.6rem;
    position: relative;
    @include gray-gradient;
    background-size: 100%;
    &.in-plp,
    &.with-refinements {
      flex-direction: row;
    }
    @at-root &.intro-plp {
      flex-direction: column;
    }
  }
  &.from-app .results-row {
    padding: 0;
    .plp-heading__title-bar {
      display: none;
    }
    .app-header-bar {
      padding: 2rem;
    }
    .breadcrumb.listing {
      display: none;
    }
    .plp-heading__subcategory-title {
      text-align: center;
    }
    .refine__toggle {
      padding: 1.2rem;
      span {
        display: none;
      }
    }
    .intro-plp__text-tags-wrapper {
      padding: 4rem 1.6rem 1.6rem;
    }
    &.intro-plp {
      .wrapper-refine-button::before {
        display: none;
      }
      .app-header-bar {
        background-color: $grey_gradient;
      }
      &.image-bg {
        $app-header-bar-height: 6.4rem;
        .intro-plp__image {
          height: calc(100% - $app-header-bar-height); // app-header-bar height
        }
        .wrapper-refine-button::before {
          display: none;
        }
        .app-header-bar {
          height: $app-header-bar-height;
        }
      }
    }
  }

  .refine__container {
    justify-content: space-between;
    align-items: center;
    display: flex;

    height: $refine-options-height;
    &.no-repeat-bg {
      background-repeat: no-repeat;
      background-size: 100% $refine-options-height;
    }
  }
  .refine__options {
    display: flex;
    left: 0;
    right: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    opacity: inherit;
    @include animate(0.3);

    &.is-visible {
      height: 5.6rem;
      visibility: visible;
    }
  }
  .search-maincategories {
    display: flex;
    position: absolute;
    left: 50%;
    text-transform: uppercase;
    transform: translateX(-50%);
    fieldset {
      margin: 0;
      &::before {
        display: none;
      }

      label {
        display: inline-block;
        min-width: 3rem;
        padding: 0 1.6rem 0.9rem;
        cursor: pointer;
        white-space: nowrap;
        border-bottom: 1px solid $grey_line;
        &.active {
          border-bottom: 1px solid $black;
        }

        [data-whatintent='keyboard'] & {
          &:focus-within {
            outline: 1px solid;
          }
        }
        input {
          -webkit-appearance: initial;
          margin: initial;
          border: initial;
          outline: none;
          width: 0;
          height: 0;
        }
      }
    }
  }
}

body.has-ticker {
  .refine {
    top: $header-height-mobile - 0.1rem + $ticker-bar-m;
  }
}

.wrapper-refine-button {
  flex-shrink: 0;
}

.refine__toggle {
  @extend %primary-s;
  position: relative;
  padding: 0 2.4rem 0 2.7rem;
  @include hover;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    @include fixedSprite('plus');
  }

  &[aria-expanded='true'] {
    &::after {
      @include fixedSprite('minus');
    }
  }
}

.refine.from-app {
  .refine__toggle {
    &::after {
      @include fixedSprite('filter');
    }
  }
}
