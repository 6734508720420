.bag-overview {
  &--empty {
    padding-bottom: ($space-unit * 20);
  }
}

.wishlist-overview {
  .bag-overview__products {
    padding-top: 2.4rem;
    padding-bottom: 0.8rem;
  }
}
// .bag-overview__products {
//   border-bottom: 1px solid $grey_line;
// }
.bag-tabs .react-tabs__tab-list {
  // padding: 0;
  grid-template-columns: repeat(2, 1fr);
}
.container-bag-overview {
  .wrapper-bag-overview {
    margin-bottom: 0;
    .bag-summary {
      padding-bottom: 1.2rem;
    }
  }
}
