.store__summary__directions.get-directions,
.store__moreinfo .button {
  width: 100%;
}
.search-store {
  &__resultslist {
    ul {
      padding: 0 3.2rem;
    }
  }
  &__regions {
    .wrapper-regions {
      padding: 0 3.2rem;
    }
    .region-col {
      padding: 3.2rem 0;
      row-gap: 0.8rem;
      p {
        margin-bottom: $space-unit * 2;
      }
    }
  }
}
.store-locator {
  .col-lg-4 {
    flex-basis: calc(33.33333% + 0.8rem);
    max-width: calc(33.33333% + 0.8rem);
  }
  .col-lg-8 {
    flex-basis: calc(66.66667% - 0.8rem);
    max-width: calc(66.66667% - 0.8rem);
  }
}
