.component-client-service {
  .category_tiles_section {
    display: flex;
    flex-wrap: wrap;
    gap: 0.4rem;
    padding-top: 4rem;
    padding-bottom: 8rem;
    justify-content: center;
    // align-items: center;

    .client-service-module_single-category-tile {
      flex: calc(50% - 0.4rem);
      flex-grow: 0;
      padding-bottom: 4rem;
    }

    .client-service-card {
      &::after {
        content: none;
      }
    }

    .client-service-description {
      color: $black;
      position: relative;
      margin: 1.6rem 1rem 0 1rem;

      .client-service_title {
        margin-bottom: 1.2rem;
      }
    }
  }
}
