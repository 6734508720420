.order-history-info-box {

  &-labels {
    margin-right: 30px;
    .flex-label, .flex-label--align-top {
      justify-content: flex-start;
    }

    .text-nowrap {
      text-wrap: nowrap;
      padding: 0;
    }
  }
}

.order-detail__column{
  &-map{
    flex-direction: row;

    &__single{
      flex-direction: row;
    }

    &__multi{
      flex-direction: column;
    }
  }
}