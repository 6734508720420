.product-mbm-notify {
  padding: 5.6rem 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .form-group {
    margin: 2.4rem 0;
    width: 100%;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .mbm-finish__buttons {
    display: flex;
    column-gap: 1.6rem;
    flex-direction: column;
    width: 100%;
  }
}
.mbm-notify-image {
  background: linear-gradient(204.81deg, #000000 17.42%, #808080 94.85%);
  width: 226px;
  height: 226px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2.4rem;
  img {
    position: relative;
    max-width: 120%;
  }
}
