.register_form {
  &__comunication-channels {
    .channel-flags {
      display: flex;
      .checkbox {
        margin-right: 5rem;
      }
    }
  }
}
