.size-selection {
  &.modal {
    .modal__header {
      padding: 0 1.6rem;
    }
    #size-selector-container {
      padding: 0 1.6rem;
    }
  }
}
