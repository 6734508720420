.product-carousel {
  width: 100%;
  padding: 4.8rem 0 ($space-unit * 8);
  overflow: hidden;
  background-color: $white;
  @include appearAnimation();
  &.pd-no__padding {
    padding-block: 0;
  }
  .swiper-container {
    overflow: visible;
  }
  .single-slide__img {
    position: relative;
    display: block;
    transition: none !important;
    opacity: 1 !important;
    overflow: hidden;
  }
  .swiper-wrapper {
    @include animate(1);
    @include reset-list;
    li {
      height: auto;
      .product-tile-carousel {
        height: 100%;
      }
    }
  }
  &.skeleton-loading {
    .product-carousel__title,
    .product-carousel__cta-container {
      @include skeleton-text;
    }
    .skeleton-tile {
      .product-image {
        @include animate-skeleton();
      }
      .product-tile__name,
      .product-tile__price {
        position: relative;
        color: transparent;
        &::before {
          content: '';
          position: absolute;
          width: 80%;
          height: 100%;
          border-radius: 2px;
          @include animate-skeleton();
          z-index: 1;
        }
      }
    }
  }
  .has-gutter {
    .swiper-slide {
      margin-right: 4px;
    }
  }
}

.product-tile-carousel-css {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  .product-tile-carousel-css-image {
    scroll-snap-align: start;
    scroll-snap-stop: always;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
  }
  .swiper-pagination-bullets {
    opacity: 1;
  }
}

.product-carousel__title {
  text-align: center;
  text-transform: uppercase;
  padding: 0 ($space-unit * 4);
}

.product-carousel__cta-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ($space-unit * 2);
}

.product-carousel__cta {
  margin: 0 1rem;
}

.product-carousel__text {
  @extend %primary-xs;
  width: 43.8rem;
  max-width: calc(100% - 3.2rem);
  margin: 2.8rem auto 0;
  text-align: center;
}

.product-carousel__carousel-container {
  margin-top: ($space-unit * 6);
  position: relative;
  &.no-container {
    .product-tile--carousel .product-tile__info {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
  }
  &.container {
    .carousel-container__navigation-button {
      left: -3.2rem;
      &.swiper-button-next {
        left: initial;
        right: -3.2rem;
      }
    }
  }
  .hide {
    visibility: hidden;
    position: absolute;
    width: 100%;
    opacity: 0;
  }
}

button.carousel-container__navigation-button,
.carousel-container__navigation-button[role='button'] {
  position: absolute;
  top: 0;
  bottom: 0;
  // width: 26%;
  z-index: 2;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  left: 0;
  width: auto;
  height: auto;
  right: initial;
  color: #000;
  background: transparent;
  // Prevents arrows appearing on mobile
  [data-whatintent='mouse'] &:hover {
    .carousel-container__button__wrapper {
      width: 14rem;
      transform: translateX(0);
    }
    .button__text {
      display: block;
      opacity: 1;
    }
  }
  [data-whatintent='keyboard'] &:focus {
    .button {
      text-decoration: underline;
      text-underline-offset: 4px;
    }
  }
  &::after {
    content: none !important;
  }
  &.swiper-button-disabled {
    display: none;
  }
}

.carousel-container__button__wrapper {
  @include animate(0.3);
  height: 100%;
  width: 3.2rem;
  transform: translateX(-100%);
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  z-index: 1;
  .button {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    &__text {
      opacity: 0;
      display: none;
      @include animate(0.3, 'opacity');
      white-space: initial;
      text-align: center;
    }
  }
}

.swiper-button-next.carousel-container__navigation-button {
  left: initial;
  right: 0;
  justify-content: flex-end;
  .carousel-container__button__wrapper {
    transform: translateX(100%);
  }
}

// labels position
.labels-position--on-img {
  .single-slide__labels {
    position: absolute;
    padding: 0;
    left: ($space-unit * 4);
    bottom: ($space-unit * 4);
    color: $white;
  }
  .product-tile .product-tile__info {
    .product-labels .product-label {
      color: rgba(255, 255, 255, 0.55);
      background-color: transparent;
    }
    .product-tile__price {
      color: $white;
    }
    .selector-color-link .selector__menu__item.selected .swatch {
      outline-color: transparent;
      &:focus-visible {
        outline-color: $white;
      }
      &:after {
        background-color: $white;
      }
    }
  }
}

//hover style
.product-carousel__headline-hover {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  text-transform: uppercase;
  color: $white;
  font-size: 3.2rem;
  line-height: 3.8rem;
  width: 90%;
  display: none;
  pointer-events: none;
}

.single-slide__img {
  &:hover {
    .product-carousel__headline-hover {
      display: block;
    }
  }
}

.product-carousel__filters {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: 1px solid $grey_line;
    z-index: 1;
    width: 100%;
  }
}
.product-carousel__is-hidden {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  display: none;
}

.skeleton-carousel {
  opacity: 0;
}
.products-carousel__arrows {
  display: flex;
  justify-content: space-between;
}
