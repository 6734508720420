.collections-inpage-navigation {
  height: 56.2rem;
  &.with-borders {
    margin: ($space-unit * 4) 0;
    padding: 0 ($space-unit * 4);
  }
  overflow: hidden;
  &-wrapper {
    max-width: 62rem;
    width: 100%;
  }
  .collection-open {
    .collections-inpage-navigation__title {
      display: none;
    }
    .inpage-collection:not(.expanded) {
      opacity: 0.5;
      .inpage-collection__name {
        font-size: 1.8rem;
        line-height: 2.2rem;
      }
    }
    .inpage-collection-component:not(.resized) {
      & + .resized {
        // margin-top: 104px;
        margin-top: 1.6rem;
      }
    }
    .resized {
      z-index: 0;
    }
    // .resized:first-child {
    //   // margin-top: -60px;
    // }
    .opened {
      z-index: 10;
      .inpage-collection__collection-bg {
        opacity: 1;
        transform: scale(1);
        transition: all 0.3s 0s linear;
      }
    }
  }
  .inpage-collection-component {
    transition: all 0.3s linear;
    // z-index: 1000;
    // please note that the below z-index assignment was correct but a strange Safari Bug prefers it written like below... no question asked
    &:first-child {
      z-index: 1000;
    }
    & + .inpage-collection-component {
      z-index: 1000;
    }
    .resized {
      z-index: 0;
    }
  }
  &__inner-container {
    min-height: 56.2rem;
    background-size: cover;
    background-position: center;
    color: $white;
    text-align: center;
    position: relative;
    padding: 4rem 1.6rem;
    background-image: var(--background-mobile), var(--background-desktop);
    overflow: hidden;

    display: flex;
    flex-direction: column;
    transform: scale(1);
    justify-content: center;
    align-items: center;
    .with-borders & {
      margin: 1.6rem 0;
    }
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      position: absolute;
      background: rgba(0, 0, 0, 0.5);
    }
  }

  &__title {
    text-transform: uppercase;
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin-bottom: 9rem;
    z-index: 2;

    position: absolute;
    display: block;
    left: 0;
    right: 0;
    top: 4rem;
  }

  .inpage-collection {
    transition: all 0.3s 0s linear;
    position: relative;
    padding: 2.4rem 0 1.2rem;
    margin: 0;
    overflow: hidden;
    &__logo {
      left: 0;
      opacity: 0;
      height: 4.3rem;
      width: auto;
      transition: all 0.3s linear;
    }
    // &__name {
    //   // margin-top: 53px;
    //   // margin-bottom: 88px;
    // }
    &__info-box {
      z-index: 0;
      text-transform: none;
      width: 100%;
      top: 10.8rem;
      position: absolute;
    }
    &.expanded {
      // > .inpage-collection {
      //   margin-top: 20rem;
      // }
      overflow: visible;
      z-index: 999;
      padding: 0;
      .inpage-collection {
        &__logo {
          // height: 4.3rem;
          // top: 0.4rem;
          display: block;
          z-index: 2;
          visibility: visible;
          top: 1.4rem;
        }
        &__info-box {
          display: block;
          // top: 12.8rem;
          top: 11.6rem;
        }
        &__name {
          // margin-top: 7rem;
          // margin-bottom: 8.2rem;
          margin-top: 8rem;
          margin-bottom: 6.2rem;
        }
        &__toggle-icon-wrapper {
          margin-top: 7.4rem;
        }
      }
      .inpage-collection__info-box,
      .inpage-collection__logo {
        opacity: 1;
        visibility: visible;
      }
      .button {
        display: flex;
        transform: scale(1);
      }
      .inpage-collection__description {
        opacity: 1;
      }
      .inpage-collection__collection-bg {
        opacity: 1;
        transition: all 0.3s 0s linear;
      }
      .inpage-collection__toggle-icon {
        @extend .icon--close-white;
      }
    }
    &__toggle-icon-wrapper {
      margin-top: ($space-unit * 2);
    }
    &:not(.expanded) {
      transition: all 0.3s 0s linear;
      z-index: 2;
    }
    &__collection-bg {
      position: absolute;
      background-size: cover;
      background-position: center;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      opacity: 0;
      z-index: -1;
      background-image: var(--background-mobile), var(--background-desktop);
      transition: all 0.3s 0s linear;
      backdrop-filter: contrast(2.5);
      transform: scale(1.2);
      &:after {
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        background: rgba(0, 0, 0, 0.5);
      }
    }
    &__name {
      text-transform: uppercase;
      font-size: 2.4rem;
      line-height: 2.8rem;
      position: relative;
      z-index: 2;
      display: inline-flex;
      transition: all 0.3s 0s linear;
      cursor: default;

      button {
        color: $white;
        cursor: default;
        &:focus {
          outline-color: $white;
        }
      }
    }
    &__logo {
      opacity: 0;
      height: 4.3rem;
      top: -4rem;
      overflow: hidden;
      z-index: 2;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
      visibility: hidden;
      img {
        margin: 0 auto;
        margin-bottom: 2.4rem;
        max-height: 100%;
        width: auto;
        max-width: auto;
      }
    }
    &__info-box {
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
      transition: all 0.3s linear;
    }
    &__description {
      position: relative;
      z-index: 2;
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
    .button {
      margin: 2.4rem 0;
      position: relative;
      z-index: 2;
      transform: scale(0.8);
      transition: all 0.3s 0s linear;
    }

    &__toggle-icon {
      position: relative;
      z-index: 2;
      @extend .icon--plus-big-white;
    }
  }
}
