.mbm-entrypoint {
  .container {
    top: 7rem;
  }
  .big-image {
    max-height: 64rem;
  }
  .logo {
    width: 25rem;
    height: 5.2rem;
  }
}
