.download-app {
  &__modal {
    &--small {
      &.modal {
        width: 37.5rem;
        min-width: 37.5rem;
        padding: 1.8rem 1.6rem 2.4rem;
      }
      .modal {
        &__header {
          padding-top: 0;
        }
      }
      .react-tabs {
        &__tab-list {
          column-gap: initial;
          display: flex;
          text-align: center;
          padding-left: 0;
          .react-tabs__tab {
            display: block;
            flex: 1 0 50%;
            padding: 1.3rem 0 1.6rem;
            @extend %primary-s;
            letter-spacing: 0.05em;
            border-bottom: solid 0.1rem;
            border-bottom-color: $grey_line;
            cursor: pointer;
            &--selected {
              font-weight: bold;
              border-bottom-color: $black;
            }
          }
        }
      }
    }
  }
  &__download-app-qrcode {
    width: 10rem;
    height: auto;
    margin: 0 auto;
    display: block;
  }
  &__bg {
    &--grey-gradient {
      background: $gradient-separator;
    }
    &--black {
      background: $black;
      color: $white;
    }
  }
}
