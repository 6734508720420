.quote-with-image {
  flex-direction: column-reverse;
  padding: ($space-unit * 4) ($space-unit * 4) ($space-unit * 10);
  & > * {
    width: 100%;
  }
  &__quote-column {
    padding: 0;
    margin-top: ($space-unit * 8);
  }
}

.quote-column {
  &__inner-container {
    width: 100%;
  }
}
