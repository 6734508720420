.quote-with-image {
  display: flex;
  padding: ($space-unit * 8);
  align-items: center;
  &--centered {
    text-align: center;
  }

  &--reverse {
    flex-direction: row-reverse;
  }
  & > * {
    width: 50%;
    position: relative;
  }
  &__quote-column {
    padding: 0 ($space-unit * 10);
  }
  &__image-column {
    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.quote-column {
  &__inner-container {
    width: 45.6rem;
    margin: 0 auto;
    max-width: 100%;
  }
  &__quote {
    margin-bottom: ($space-unit * 8);
    &,
    & p {
      font-size: 2.4rem;
      line-height: 2.8rem;
    }
  }

  &__author {
    text-transform: uppercase;
  }
}
