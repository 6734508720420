.monclercurators-plp {
  .breadcrumbs-category {
    background-color: $grey_gradient;
    .category {
      padding: 6.4rem 0 1.6rem 3.2rem;
      display: flex;
      flex-direction: row;
      column-gap: 0.8rem;

      .link-category {
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 350;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  .monclercurators-hero__logo {
    padding: 8rem 0 9rem;
  }
  .monclercurators__text {
    text-align: center;
    color: white;
    display: flex;
    flex-direction: column;
    row-gap: 2.4rem;
    .monclercurators__title {
      font-family: $silkSerifLight;
      font-style: normal;
      font-weight: 300;
      font-size: 80px;
      line-height: 76px;
      text-transform: uppercase;
    }
    .monclercurators__message {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
    }
  }
  .monclercurators__container {
    padding-bottom: 10rem;
  }
}
