body.app {
  &.has-ticker main {
    padding-top: 0;
    background-position-y: 0;
  }
  #DW-SFToolkit {
    display: none; // old toolkit, just use the new one
  }
  main {
    min-height: 810px;
    &.main--checkout {
      min-height: auto;
      border-bottom: none;
    }
  }
  .noapp {
    display: none !important;
  }
  .noapplink {
    text-decoration: none;
  }
  &:not(.black-page) {
    main {
      background-color: #fff;
      background-image: none;
      border-bottom: initial;
    }
  }
  .checkout-container {
    .checkout-login {
      margin-top: $space-unit * 4;
    }
    #customerStep {
      .step__header {
        border-top: none;
      }
    }
    .autocomplete {
      width: calc(100% - 32px);
      min-width: calc(100% - 32px);
      max-width: 343px;
      .attribution {
        display: none;
      }
      li {
        background-color: $grey_input;
        border: 1px solid $white;
        text-align: left;
      }
    }
    #packagingStep {
      .step__panel {
        // min-height: 731px;
        .optionbox__item__content {
          min-height: 258px;
          img {
            width: 140px;
            height: 102px;
          }
        }
      }
    }
    #shippingStep {
      .step__panel {
        min-height: 500px;
      }
      .shippingstepdetail {
        min-height: 400px;
        .address_form {
          min-height: 600px;
        }
      }
    }
    #paymentStep {
      .step__panel {
        min-height: 324px; // minimo con 2 metodi di pagamento abilitati e no form carta
        #component-container {
          > div {
            display: none;
          }
          .adyen-checkout__card-input {
            display: block;
          }
        }
      }
    }
  }
  .loader.full {
    background-color: rgba(255, 255, 255, 1);
    flex-direction: column;
    @keyframes ldio-hn0fflew9qp {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
    .ldio-hn0fflew9qp div {
      left: 49.5px;
      top: 42px;
      position: absolute;
      animation: ldio-hn0fflew9qp linear 1s infinite;
      background: #000000;
      width: 1px;
      height: 4px;
      border-radius: 0;
      transform-origin: 0.5px 8px;
    }
    .ldio-hn0fflew9qp div:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -0.875s;
      background: #000000;
    }
    .ldio-hn0fflew9qp div:nth-child(2) {
      transform: rotate(45deg);
      animation-delay: -0.75s;
      background: #000000;
    }
    .ldio-hn0fflew9qp div:nth-child(3) {
      transform: rotate(90deg);
      animation-delay: -0.625s;
      background: #000000;
    }
    .ldio-hn0fflew9qp div:nth-child(4) {
      transform: rotate(135deg);
      animation-delay: -0.5s;
      background: #000000;
    }
    .ldio-hn0fflew9qp div:nth-child(5) {
      transform: rotate(180deg);
      animation-delay: -0.375s;
      background: #000000;
    }
    .ldio-hn0fflew9qp div:nth-child(6) {
      transform: rotate(225deg);
      animation-delay: -0.25s;
      background: #000000;
    }
    .ldio-hn0fflew9qp div:nth-child(7) {
      transform: rotate(270deg);
      animation-delay: -0.125s;
      background: #000000;
    }
    .ldio-hn0fflew9qp div:nth-child(8) {
      transform: rotate(315deg);
      animation-delay: 0s;
      background: #000000;
    }
    .loadingio-spinner-spinner-a3as68esyai {
      width: 100px;
      height: 100px;
      display: inline-block;
      overflow: hidden;
      background: none;
    }
    .ldio-hn0fflew9qp {
      width: 100px;
      height: 100px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      transform: translateZ(0) scale(1);
      backface-visibility: hidden;
      transform-origin: 0 0; /* see note above */
    }
    .ldio-hn0fflew9qp div {
      box-sizing: content-box;
    }
    .loadingmessage {
      @extend %primary-s;
      text-align: center;
    }
  }
}

.text-color--black {
  .promo-column__title,
  .hero-column__title,
  .hero-column__title-visible,
  .hero-column__cta .button {
    color: #000 !important;
  }
}

// .text-shadow-active {
//   .promo-column__title,
//   .hero-column__title {
//     filter: drop-shadow(0 0 4px rgba(15, 15, 15, 1));
//   }
// }

.monclernow-hero__title {
  word-break: break-word;
}

.app-header-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
}

body.page-template-textpage .app-header-bar {
  position: sticky;
  top: 0;
  z-index: 1;
  background: $white;
  border-bottom: 1px solid $grey_line;
  h2 {
    text-transform: uppercase;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
