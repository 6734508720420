.page-template-technicaldetails {
  main {
    padding-top: $contextual-navigation + $header-height-desktop;
  }
  .contextual-nav {
    #technical-back {
      position: absolute;
      left: 10.5%;
      z-index: 1;
      min-width: 15rem;
      height: 2rem;
      display: flex;
      justify-content: flex-start;
      padding-left: 2.4rem;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        @include fixedSprite('arrow-left-black');
      }
    }
  }
}
body.has-ticker {
  &.page-template-technicaldetails {
    main {
      padding-top: $contextual-navigation + $header-height-desktop + $ticker-bar;
    }
  }
}
