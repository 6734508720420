.component-two-column-promo.genius {
  padding: 1.6rem;
  .two-column-promo-container {
    gap: 1.6rem;
  }
  .two-column-promo-item {
    .two-column-promo-card {
      padding-bottom: 100%; // 1:1 ratio
    }
    .two-column-promo-label {
      top: auto;
      bottom: 3.3rem;
      transform: translate(-50%);
      .two-column-promo-title {
        padding-bottom: 0;
        margin-bottom: 1.6rem;
        @include geniusTitle();
      }
    }
  }
}
