.support-section-footer-row {
  gap: .5rem;

  .contacts-card {
    flex-basis: 100%;
    max-width: 100%;

    .links {
      flex-direction: column;
      align-items: center;
      gap: 1.5rem;
    }
  }
}

.experience-account-support-layout {

  .experience-left, .experience-right {
    flex-basis: 100%;
    max-width: 100%;
  }
}
