.rich-video {
  &--portrait {
    .grid-container & {
      min-height: 125.6rem;
    }
  }
  &__block {
    &.light {
      border: 3.2rem solid $white;
    }
    &.dark {
      border: 3.2rem solid $black;
    }
  }
  &__description {
    max-width: 89.2rem;
  }
}
