.slim-banner {
  .wrapper-image {
    padding: 0;
  }
  .right {
    .wrapper-image {
      margin-right: 0;
    }
  }
  .left {
    .wrapper-image {
      margin-left: 0;
    }
  }
  .wrapper-info {
    padding: 0;
    text-align: left;
    .row {
      flex-wrap: nowrap;
      column-gap: 0.8rem;
    }
  }
}
