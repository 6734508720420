.perfume-product-highlight-slide {
  &__text-column {
    padding: ($space-unit * 8) ($space-unit * 4);
  }

  &__product-image-wrapper {
    width: 28.3rem;
  }

  &__body {
    height: 96px;
  }
}
