a.a11y-action-bar,
a.a11y-action-bar:visited {
  &.a11y-action-bar--navigation {
    span {
      width: calc(21.8rem * 4 + 3.2rem * 3);
    }
  }
}

.wrapper-submenu {
  .a11y-action-bar {
    display: none !important;
  }
}

[data-whatinput='touch'] {
  .a11y-action-bar {
    display: none;
  }
}
