.membership-small-banner {
  &__image {
    min-height: 9.6rem;
    width: 8.7rem;
    img {
      max-width: 85%;
    }
  }
  &__content {
    padding: 1.2rem;
    text-align: left;
    .button-login {
      &.justify-initial {
        justify-content: initial;
      }
    }
  }
}
