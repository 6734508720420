.nft-info-box {
  padding: 1.4rem;
  margin-top: 3.6rem;
  text-align: center;
  border: 2px solid;
  border-image: repeating-linear-gradient(11deg, #d0d0ff, #ffcef9, #ffd7bf, #fff2bb, #ecffba, #acffc5, #b1f6ff 60%) 1;
  h2 {
    text-transform: uppercase;
    margin-bottom: 1.4rem;
  }
  p {
    font-size: 1.2rem;
    a {
      text-decoration: underline;
    }
  }
}
