.tabs {
  .react-tabs__tab-list {
    list-style: none;
    position: relative;
    display: flex;
    padding-top: 0;
    padding-bottom: 0;
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      @include border('bottom');
    }
  }

  [role='tab'] {
    @extend %primary-s-u;
    cursor: pointer;
    padding: 1.6rem 0 1.4rem;
    position: relative;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    > div {
      position: relative;
    }

    &[aria-selected='true'] {
      font-weight: bold;
      &::after {
        content: '';
        position: absolute;
        height: $space-line;
        background-color: black;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }

  .client-service__tablist {
    & > li {
      &[aria-selected='false'] {
        &::after {
          content: '';
          position: absolute;
          height: $space-line;
          background-color: $grey_line;
          bottom: 0;
          left: -1rem;
          right: -1rem;
        }
      }
      &[aria-selected='true'] {
        &::after {
          left: -1rem;
          right: -1rem;
        }
      }
    }
  }

  .react-tabs__tab--disabled {
    pointer-events: none;
    cursor: initial;
    opacity: 0.55;
  }

  .apex {
    display: inline-block;
    font-size: 1rem;
    line-height: 1.4rem;
    position: absolute;
    top: -6px;
    right: -2px;
    transform: translateX(100%);
    &.big {
      font-size: 1.2rem;
      line-height: 1.6rem;
      top: -4px;
    }
  }

  &.tabs--fluid {
    .react-tabs__tab {
      flex: 1 0 50%;
      &:not(:first-child) {
        padding-left: $space-unit;
      }
    }
  }
  &.tabs--capitalize {
    .react-tabs__tab {
      text-transform: capitalize;
    }
  }
  &.tabs--nocapitalize {
    .react-tabs__tab {
      text-transform: none; // forced default is uppercase
    }
  }
  &.tabs--uppercased {
    .react-tabs__tab {
      text-transform: uppercase;
      letter-spacing: 0.05em;
    }
  }
  &.tabs--centered {
    .react-tabs__tab {
      text-align: center;
    }
  }
  &.tabs--padding {
    .react-tabs__tab-list {
      column-gap: 2.4rem;
    }
    // .react-tabs__tab:not(:last-of-type) {
    //   margin-right: ($space-unit * 8);
    // }
  }
  &.tabs--wishlist {
    display: flex;
    flex-direction: column;
    align-items: center;
    .react-tabs__tab-list {
      position: relative;
      padding: 0;
      background: transparent;
    }
    .react-tabs__tab-panel {
      width: 100%;
    }
    [role='tab'] {
      @extend %primary-xxs;
    }
  }
}
