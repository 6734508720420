// SIZE SELECTOR USED IN: PDP, PLP Tile, Reserve in boutique, Shop by Look

.selector-size-expanded {
  position: relative;
  --selector-color: #{$black};
  color: var(--selector-color);

  // programmatic focus to selector
  &:focus-visible {
    outline: 1px solid $white;
  }
  .recommended {
    flex: 1;
    color: $black_secondary;
    margin-top: ($space-unit * 1);

    // span:only-of-type {
    //   display: none;
    // }
  }

  .btn-label-find-size {
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
    text-transform: uppercase;
  }

  .selector__header {
    display: flex;
    align-items: center;
    padding: 1.1rem 0 0;
    justify-content: space-between;
    white-space: pre;
    &.justify-start {
      justify-content: flex-start;
    }
  }

  .fitting {
    padding-bottom: 1.7rem;
    font-size: 1.2rem;
    line-height: 1.4;
  }

  .selector__error {
    margin-top: 1rem;
  }

  .selector__label {
    margin-right: ($space-unit * 4);

    &__name {
      @extend %primary-xs-u;
      display: inline-block;
    }

    &__value {
      @extend %primary-xs;
      margin-left: ($space-unit * 4);
      display: inline-block;
    }
  }

  .errormessage {
    color: $status_error;
    margin-right: ($space-unit * 2);
    @include text-shorten;
  }

  .selector__menu {
    display: flex;
    align-items: center;
    padding: 1.4rem 0 1.7rem;

    .label {
      @extend .visually-hidden;
    }

    ul {
      @include reset-list;
      display: flex;
      flex-wrap: wrap;
      bottom: initial;
      min-width: 100%;
      row-gap: $selector-size-inpage-rowgap-desktop;
      column-gap: 2.7rem;
    }

    &__item {
      &:hover:not(.selected) {
        .selector__menu__item__label {
          &::after {
            transform: translateX(-50%) scaleX(1);
          }
        }
      }
      &__label {
        @extend %primary-s-u;
        position: relative;
        display: inline-block;
        &::after {
          content: '';
          height: 0.1rem;
          bottom: 0;
          background-color: $grey_line;
          width: 100%;
          min-width: 1.6rem;
          @include center('horizontal');
          @include animate(0.3);
          visibility: visible;
          transform: translateX(-50%) scaleX(0);
        }
      }

      &.is-disabled {
        color: $grey_interactive;
        position: relative;

        // line stroke
        &::after {
          content: '';
          position: absolute;
          width: 2.2rem;
          height: 0.1rem;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
          background-color: var(--selector-color);
        }
      }

      &.selected {
        .selector__menu__item__label {
          &::after {
            background-color: var(--selector-color);
            transform: translateX(-50%) scaleX(1);
          }
        }
      }

      @include touchtarget-area();

      &:focus {
        outline: none;

        @include touchtarget-focus();
      }
    }
  }
  // for reworked size selector
  fieldset {
    margin: 0;
    &::before {
      content: none;
    }
  }
  .selector__menu--size {
    display: flex;
    align-items: center;
    padding: 1.4rem 0 1.7rem;
    row-gap: 1rem;
    column-gap: 1rem;
    flex-wrap: wrap;
    .on-pdp & {
      padding-top: 1.5rem;

      label:only-child {
        margin-left: 0.6rem;
      }
    }
  }

  .selector-size-item {
    position: relative;
    min-width: 3.2rem;
    height: 3.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    &__label {
      @extend %primary-s-u;
      position: relative;
      display: flex;
      cursor: pointer;
      width: 100%;
      align-items: center;
      height: 100%;
      justify-content: center;
      &::after {
        content: '';
        height: 0.1rem;
        bottom: 0;
        background-color: $grey_line;
        width: 100%;
        min-width: 1.6rem;
        @include center('horizontal');
        @include animate(0.3);
        visibility: visible;
        transform: translateX(-50%) scaleX(0);
      }
    }
    &__input {
      @include hidden-input();
    }

    &:not(.disabled) {
      .selector-size-item__label {
        cursor: pointer;
      }
    }

    [data-whatintent='keyboard'] & {
      &:focus-within {
        outline: 1px solid;
      }
    }

    &.selected {
      .selector-size-item__label {
        &::after {
          background-color: var(--selector-color);
          transform: translateX(-50%) scaleX(1);
        }
      }
    }
    &:hover:not(.selected) {
      .selector-size-item__label {
        &::after {
          transform: translateX(-50%) scaleX(1);
        }
      }
    }
    &.disabled {
      color: $grey_interactive;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 2.2rem;
        height: 0.1rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        background-color: var(--selector-color);
      }
    }
  }

  //INVERTED VARIANT

  &.inverted {
    --selector-color: #{$white};
    border-color: $white_inactive;

    .recommended {
      color: $white_secondary;
    }

    // da verificare
    .button--link {
      color: $white;

      i {
        filter: invert(1);
      }

      &::after {
        filter: invert(1);
      }
    }

    .selector__menu__item {
      &:focus {
        @include touchtarget-focus($outline-color-inverted);
      }
    }
  }

  //INTILE VARIANT
  &.intile {
    .selector__menu {
      padding: $selector-size-intile-padding;
      ul {
        row-gap: $selector-size-intile-rowgap;
        grid-template-columns: repeat($selector-size-intile-cols, 1fr);
      }
    }
  }

  &.format-selector {
    .selector__menu__item::before {
      width: calc(100% + 1.6rem);
    }
  }

  &.out-of-stock {
    margin-top: 1.1rem;
    border: none;
  }
}
