.hero {
  .btn--pause {
    left: 3.2rem;
    bottom: 3.2rem;
  }

  // pause button for carousel
  > .btn--pause {
    left: initial;
    right: 3.2rem;
  }

  .btn--volume {
    left: 7.6rem;
    bottom: 2.7rem;
  }
}

.hero--hero-with-columns {
  &.fixed-ratio--16-9 {
    aspect-ratio: 16/9;
  }
  &.fixed-ratio--21-9 {
    aspect-ratio: 21/9;
  }
}

.product-video-pause {
  left: 2.6rem;
}

.myaccount-hero__title {
  margin-bottom: ($space-unit * 16);
}
