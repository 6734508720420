@use 'sass:math';

@mixin lhCrop($top-crop: 0.3rem, $bottom-crop: 0.3rem) {
  &::before,
  &::after {
    content: '';
    display: block;
    height: 0;
    width: 0;
  }
  &::before {
    margin-bottom: $top-crop;
  }
  &::after {
    margin-top: $bottom-crop;
  }
}

// Media Queries
@mixin breakpoint($point, $type: max) {
  $width: get($grid-breakpoints, $point);
  @if $point == xl {
    $type: min;
  }
  @if $type == max {
    $width: $width - 1px;
  }
  @media only screen and (#{$type}-width: $width) {
    @content;
  }
}

// New gradients mixin
// USAGE:
// type: shape of the gradient
// position: center, middle or left
// invert: switch on top the gradient
// intensity: min | mid | max -> opacity of the gradient 33% | 66% | max
@mixin gradient($module: hero, $type: peak, $type-mobile: peak, $position: center, $invert: false, $intensity: mid) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in;
    @if ($intensity == min) {
      opacity: 33%;
    }

    @if ($intensity == mid) {
      opacity: 66%;
    }

    @if ($intensity == max) {
      opacity: 100%;
    }

    background-image: url('../../media/gradients/#{$module}-gradient-#{$type}-#{$position}-desktop.svg');
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    @if ($invert == true) {
      transform: rotate3d(1, 0, 0, 180deg);
    }
    $width: get($grid-breakpoints, sm);
    @media only screen and (max-width: $width) {
      @if ($type-mobile == circle) {
        $position: center;
      }
      background-image: url('../../media/gradients/#{$module}-gradient-#{$type-mobile}-#{$position}-mobile.svg');
    }
  }
}

@mixin increased-contrast($z-index: 0) {
  &::after {
    content: '';
    position: absolute;
    background-image: none;
    background-color: $black;
    opacity: 55% !important;
    height: 100%;
    width: 100%;
    left: 0;
    bottom: 0;
    pointer-events: none;
    z-index: $z-index;
  }
}

@mixin plp-gradient($position: center, $intensity: mid) {
  @include gradient(plp, peak, circle, $position, false, $intensity);
}

@mixin hero-gradient($position: center, $intensity: mid) {
  @include gradient(hero, peak, peak, $position, false, $intensity);
}

// Arrow mixin
@mixin arrow($direction: down, $size: 0.5rem, $color: #555) {
  width: 0;
  height: 0;
  @if ($direction == left) {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
  } @else if ($direction == right) {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  } @else if ($direction == down) {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
  } @else {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  }
}

// Trim text
@mixin text-shorten {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Define vertical, horizontal, or both position
@mixin center($position: '') {
  position: absolute;

  @if $position == 'vertical' {
    top: 50%;
    transform: translateY(-50%);
  } @else if $position == 'horizontal' {
    left: 50%;
    transform: translateX(-50%);
  } @else {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@mixin animate($time: 0.5, $props: 'all', $easing: $easeOutQuart, $delay: 0, $important: '') {
  @if $props == 'all' {
    transition: all #{$time}s #{$delay}s $easing #{$important};
  } @else {
    transition: #{$props} #{$time}s #{$delay}s #{$easing} #{$important};
  }
}

@mixin hover($time: 0.5) {
  @include animate($time);
  &:hover {
    opacity: $hover-opacity;
  }
}

@mixin appearAnimation($duration: 0.4, $delay: 0) {
  // opacity: 0;
  // &.reveal-animations {
  //   @include animate($duration, 'all', $easeOutQuart, $delay, '!important');
  //   opacity: 1;
  // }
}

@mixin labelIcon($name, $type: left) {
  content: '';
  position: absolute;
  top: 0.1rem; // non in percentuale per gestire il caso in cui c'è il messaggio di errore sotto
  transform: translateY(50%);
  @if $type == 'right' {
    right: $space-unit * 2;
  } @else {
    left: $space-unit * 2;
  }
  display: block;
  height: $space-unit * 3;
  width: $space-unit * 3;
  @include sprite($name);
  background-size: 100%;
}

// setting defaults values to current images ratio
@mixin fixedRatio() {
  position: relative;
  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-top: $product-ratio;
  }
  > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@mixin geniusRatio() {
  aspect-ratio: 9 / 16;
  @include breakpoint(sm, min) {
    aspect-ratio: 3 / 4;
  }
  @include breakpoint(md, min) {
    aspect-ratio: 4 / 3;
  }
  @include breakpoint(lg, min) {
    aspect-ratio: 16 / 9;
  }
}

@mixin geniusTitle() {
  text-transform: none;
  font-family: 'BlockGothicRR';
  font-size: 4.2rem;
  line-height: 3.612rem;
  font-weight: 500;
  @include breakpoint(md, min) {
    font-size: 6.2rem;
    line-height: 5.332rem;
  }
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, $number * 0 + 1);
  }

  @return $number;
}

@mixin fixedSprite($name, $color: transparent) {
  $sprite: map-get($sprites, $name);
  background: #{$color} url($sprite) center no-repeat;
  @if map-has-key($sizes, $name) {
    $size: map-get($sizes, $name);
    width: (strip-unit(map-get($size, width)) * 0.1) * 1rem;
    height: (strip-unit(map-get($size, height)) * 0.1) * 1rem;
  }
  display: inline-block;
}

@mixin z($name: default) {
  z-index: get($z-layers, $name);
}

@mixin visually-hidden {
  position: absolute !important;
  height: 0.1rem;
  width: 0.1rem;
  overflow: hidden;
  clip: rect(0.1rem 0.1rem 0.1rem 0.1rem);
  white-space: nowrap;
}

@mixin reset-visually-hidden {
  position: relative !important;
  height: initial;
  width: initial;
  overflow: initial;
  clip: initial;
  white-space: initial;
}

@mixin reset-list {
  list-style: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  > li {
    list-style-position: outside;
    padding-top: 0;
    padding-bottom: 0;
  }
}

@mixin fix-font-space($s: 0.2rem) {
  // margin-top: (-$s);
}

@mixin del-link {
  text-decoration: none;
  color: inherit;
}

@mixin gray-gradient {
  background: $gradient-separator;
  background-size: 100% $gradient-height;
  background-repeat: no-repeat;
}

@mixin gray-gradient-pseudo($type: before) {
  &::#{$type} {
    content: '';
    height: $gradient-height;
    background: $gradient-separator;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    @include z(neg);
  }
}

@mixin scrollbar {
  overflow: auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0.2rem;
    height: 0.2rem;
  }

  &::-webkit-scrollbar-track {
    background: $grey_line;
  }

  &::-webkit-scrollbar-thumb {
    background: $black;
  }
}

@mixin hide-scrollbar {
  /*chrome */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@mixin hoverablelink {
  text-decoration: none;
  &:focus,
  &:hover {
    text-decoration: underline;
  }
}

// mixin for interactions

@mixin focus-underline {
  [data-whatintent='keyboard'] & {
    outline: transparent;
    text-decoration: underline;
    -webkit-text-underline-position: under;
    text-underline-position: under;
  }
}

//Previous one is glitchy on chrome in long list ( ie. footer )
@mixin focus-underline-alt {
  [data-whatintent='keyboard'] & {
    position: relative;
    outline: transparent;
    &:before {
      content: '';
      position: absolute;
      bottom: -0.3rem;
      width: 100%;
      height: 1px;
      background-color: $black;
    }
  }
}

// default touch area
@mixin touchtarget-area($size: $target-size-desktop) {
  position: relative;

  &::before {
    content: '';
    @include center();
    height: $size;
    width: $size;
  }
}

// on focus
@mixin touchtarget-focus($color: $outline-color) {
  [data-whatintent='keyboard'] & {
    outline: none;

    &::before {
      outline: $outline-width solid $color;
    }
  }
}
@mixin link-focus-underline($crop: false) {
  position: relative;
  text-decoration: none;

  &::after {
    @include animate;
    content: '';
    height: 1px;
    width: 100%;
    background: $white;
    position: absolute;
    left: 0;
    opacity: 0;

    @if $crop {
      bottom: -0.2rem;
    } @else {
      bottom: 0;
    }
  }

  &:focus {
    outline: none;

    &::after {
      opacity: 1;
    }
  }
}

@mixin border($where: '') {
  @if ($where == 'top') {
    border-top: $space-line solid $grey_line;
  } @else if ($where == 'bottom') {
    border-bottom: $space-line solid $grey_line;
  } @else {
    border: $space-line solid $grey_line;
  }
}

@mixin checkbox {
  color: $black;
  width: $checkbox-size;
  height: $checkbox-size;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: $space-line solid $black;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 0;
  // to avoid user agent IOS styling issue
  background-color: initial;
  &:disabled {
    border: $space-line solid $black_inactive;
    cursor: initial;
    & + label {
      color: $black_inactive;
      cursor: initial;
    }
  }

  &::after {
    position: relative;
    display: block;
    height: 0.4rem;
    width: 0.6rem;
    border-left: $space-line solid;
    border-bottom: $space-line solid;
    transform: rotate(-45deg);
    left: 0.3rem;
    top: 0.3rem;
    box-sizing: border-box;
    content: none;
  }

  &:checked::after {
    content: '';
  }
}

@mixin radio {
  color: $black;
  width: $radio-size;
  height: $radio-size;
  border-radius: 100%;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: $space-line solid $black;
  cursor: pointer;
  flex-shrink: 0;

  &::after {
    display: block;
    height: 0.2rem;
    width: 0.2rem;
    background-color: $black;
    border-radius: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    content: none;
    position: relative;
  }

  &:checked::after {
    content: '';
  }
}

@mixin hidden-input {
  appearance: initial;
  -webkit-appearance: initial;
  margin: initial;
  border: initial;
  outline: none;
  width: 0;
  height: 0;
}

@mixin skeleton-text {
  position: relative;
  color: transparent;
  &::before {
    content: '';
    position: absolute;
    width: 50%;
    min-width: 240px;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
    @include animate-skeleton();
    z-index: 1;
  }
}

@mixin animate-skeleton {
  animation: skeleton 0.7s ease-out infinite alternate;
}

@keyframes skeleton {
  from {
    background: #c2c2c2;
  }
  to {
    background: #f3f3f3;
  }
}

@mixin strokeThrough($parent: '&') {
  position: relative;
  &::after {
    #{$parent} {
      content: '';
      position: absolute;
      height: 0.1rem;
      width: 1.4em;
      background: black;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}
