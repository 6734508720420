.checkout-payment {
  // padding: ($space-unit * 2) 0 ($space-unit * 16);

  &__options {
    margin-bottom: $space-unit * 4;

    &__option {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      text-align: left;

      &:not(:last-child) {
        margin-bottom: $space-unit * 4;
      }
    }

    &__option-name {
      @extend %primary-xs;
    }
  }

  .disclaimer-single-byte-jp {
    display: none;
    margin-top: -($space-unit * 4);
    margin-bottom: ($space-unit * 10);
    &.show {
      display: block;
    }
  }
  .subtitle {
    margin-bottom: $space-unit * 3;
  }
  .paymentmethods__preorder {
    margin-top: $space-unit * 4;
    margin-bottom: $space-unit * 8;
  }
  .checkout-safari-alert {
    $icon-alert-width: 1.8rem;
    margin-bottom: 1.5rem;
    .icon--alert {
      position: absolute;
    }
    .checkout-safari-alert--text {
      padding-left: $icon-alert-width + 0.9rem;
      display: inline-block;
    }
    button.link {
      margin-bottom: 1rem;
    }
  }
}
.wrapper-payment-methods {
  display: flex;
  justify-content: space-between;
  padding: 1.6rem 0 2.4rem;
}
.btn-change {
  display: flex;
  text-transform: uppercase;
  align-items: center;
  column-gap: 0.8rem;
  &::after {
    content: '';
    @include fixedSprite('arrow-right-black');
    display: block;
  }
}
.checkout-payment-preorder {
  margin-bottom: 1.6rem;
}
.error-tax-refound {
  .globalerrors {
    &.invalid-feedback {
      display: flex;
    }
  }
}
