.rich-video {
  &--portrait {
    .rich-video {
      &__text-wrapper {
        width: 100%;
      }
    }
  }
  &__description {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }
}
