.component-two-column-info-block {
  .container-two-column-info-block {
    padding: 0 10.9rem;
    &-border {
      max-width: 80.6rem;
      padding: 0 8rem 4rem;

      .two-column-info-block_title-content {
        .two-column-info-block {
          &_title {
            padding-top: 8rem;
          }
        }
      }

      .two-column-info-single-block {
        &-item {
          flex-direction: row;
          gap: 0.3rem;
          padding-bottom: 3.2rem;
          &:last-child {
            padding-bottom: 4rem;
          }
        }

        &-title {
          display: flex;
          flex-direction: column;
          flex-basis: 50%;
          width: 50%;
          align-items: flex-start;
          line-height: 2.4rem;

          &-main {
            display: block;
            flex: 0 1 0;
            line-height: 2.4rem;
          }
        }

        &-label {
          display: flex;
          flex-direction: column;
          text-align: left;

          &-subtitle {
            text-align: left;
            display: block;
            clear: both;
          }
        }
      }

      .two-column-info-description-block {
        width: 50%;

        &_description {
          // padding-bottom: 3.2rem;
          &:last-child {
            padding-bottom: 0;
          }
        }
      }

      .please-note {
        bottom: 0;
        padding-bottom: 6rem;
      }
    }
  }
  .accordion-collapsed {
    .two-column-info-block_title-content {
      .two-column-info-block_description {
        padding-bottom: 6rem;
      }
    }
  }
}
