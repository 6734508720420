.text-on-image {
  &__image {
    img {
      position: absolute;
      left: ($space-unit * 7);
      top: ($space-unit * 7);
      right: ($space-unit * 7);
      bottom: ($space-unit * 7);
      width: calc(100% - 5.6rem);
      height: calc(100% - 5.6rem);
      object-fit: cover;
    }
  }

  &__text {
    left: 5.6rem;
    bottom: 5.6rem;
  }

  &--two-images {
    .text-on-image {
      &__text {
        left: ($space-unit * 7);
        bottom: ($space-unit * 7);
      }
    }
  }
}
