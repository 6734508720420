header {
  .contextual-nav {
    padding-top: 0.8rem;
    padding-bottom: 0.6rem;
    height: $contextual-navigation;
  }
  .collection-submenu {
    justify-content: flex-start;
    .collection-submenu__item {
      min-height: 30rem;
    }
  }
}
