.special-sale-hero {
  background-image: url('./../../media/special-sale-header-md.jpg');
  &__title {
    font-size: 3.2rem;
    line-height: 3.8rem;
  }
}
.special-sales {
  padding: 6.4rem 0;
  &__title {
    font-size: 2rem;
    margin-bottom: 1.6rem;
  }
  .checkbox-spacing {
    .checkbox {
      margin-left: 1rem;
    }
  }
  .checkbox {
    top: 0.6rem;
  }
}
.modal.remove-guest-modal {
  max-width: 45.8rem;
}
