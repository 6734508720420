.component_product_key_technical_details {
  .product-key-technical-details__gif {
    height: 100vh;
    bottom: initial;
    top: 0;
  }
  .progress-bar {
    border-top: 1px solid $white;
    border-bottom: 1px solid $white;
    height: 0.6rem;
  }
  .key-detail {
    .key-detail-bar-container {
      .key-detail-name {
        width: 100%;
      }
      .key-detail-number {
        width: 4rem;
        margin-left: 2rem;
        order: 2;
      }
      .key-detail-bar {
        flex-grow: 1;
        width: auto;
      }
    }
  }
  .product-key-part-image {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    img {
      margin-left: auto;
    }
  }
  .product-key-technical-details-hero {
    padding-top: 0;
    padding-bottom: 0;
    // height: 100%;
    .key-details-container {
      margin-top: 4rem;
    }
    > .row {
      height: 100%;
      overflow-y: auto;
    }
  }

  .product-key-part-text-wrapper {
    padding-top: 7rem;
  }

  .product-key-technical-details-text {
    padding-top: 7rem;
    text-align: left;
  }

  .grenoble-details {
    &.less-items {
      justify-content: flex-start;
      .grenoble-details__item {
        margin-right: 4.8rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .product-key-technical-details__background {
    height: calc(var(--viewport-height) - #{$contextual-navigation});
  }
}
