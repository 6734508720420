.product-zoom {
  height: calc(var(--viewport-height) - 24.1rem);

  &-thumbs {
    $slideW: 11.8rem;
    $slideH: 17.5rem;
    max-width: ($slideW * 5 + ($slideW / 2) + (0.4rem * 5));

    &--wrapper {
      height: 24.1rem;
      padding: 0 1.6rem;
    }

    .swiper-slide {
      max-width: $slideW;
      max-height: $slideH;
      img,
      video {
        max-height: $slideH;
      }
    }
  }
}
.product-zoom-modal {
  &.modal {
    .modal {
      &__close {
        width: 4.4rem;
        height: 4.4rem;
      }
      &__zoom {
        &-controls {
          bottom: 2.1rem;
          right: unset;
          padding: 0;
          left: 50%;
          transform: translateX(-50%);
        }
        &-in,
        &-out {
          &:focus-visible {
            outline-offset: -0.4rem;
          }
        }
      }
    }
  }

  .gallery-wrapper {
    &::after {
      height: 16.1rem;
    }
  }
}
