// todo refactor

fieldset {
  margin: $space-unit * 8 0;
  position: relative;

  &::before {
    content: '';
    background-color: $grey_line;
    position: absolute;
    height: $space-line;
    width: 100%;
    top: 0;
  }

  &.fieldset--plain {
    margin: 0;
    &::before {
      content: none;
    }
    // > .form-group {
    //   margin-bottom: 0;
    // }
  }

  &.fieldset--extra-top-space {
    margin-top: $space-unit * 3;
  }
}

legend {
  @extend %primary-xs-u;
  padding: $space-unit * 4 0;
  display: block;
}

.note {
  @extend %primary-xxs;
  color: $black_secondary;
}

/* form group */

.form-group-title {
  display: block;
  @extend %primary-xxs;
  padding: 0 0 $space-unit;
  margin-bottom: $space-unit * 3;
}

.form-group {
  position: relative;
  margin-bottom: $space-unit * 3;

  &.fullwidth {
    width: 100%;
  }

  &__legend {
    display: block;
    @extend %primary-xxs;
    padding: 0 0 $space-unit * 3;
    text-align: left;
    text-transform: none;
  }

  &__label {
    display: block;
    @extend %primary-xxs;
    padding: 0 0 $space-unit;
    text-align: left;
    text-transform: none;
    &:not(.spacer) {
      &:empty {
        // debugger for empty labels
        &::before {
          content: 'visually hidden label needed';
          color: royalblue;
        }
      }
    }
    &.spacerlabel {
      &::before {
        content: '\00a0'; // non breaking space
      }
    }
  }

  &__label-radio {
    width: 100%;
  }

  &__label--floating {
    position: absolute;
    left: 1px;
    top: 1px;
    right: 1px;
    bottom: 1px;
    background: $white;
    padding: 0 ($space-unit * 3);
    align-items: center;
    z-index: 2;
    pointer-events: none;
    @extend %primary-xs-u;
  }

  input:focus + &__label--floating {
    display: none;
  }

  &--cols {
    margin-bottom: 0;
    display: grid;
    grid-template-columns: 50% 50%;
  }

  &--gender-radio {
    display: flex;
    flex-wrap: wrap;
    column-gap: 3.2rem;
    row-gap: 4px;
    // .radio {
    //   margin-bottom: ($space-unit * 4);
    //   padding-right: ($space-unit * 8);
    // }
  }

  &--inline {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:focus-within {
      .form-group__label--floating {
        display: none;
      }
    }
  }

  &__helper-text {
    display: block;
    @extend %primary-xxs;
    margin: $space-unit 0 $space-unit * 3;
  }

  &--cols {
    .invalid-feedback {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

.invalid-feedback {
  color: $status_error;
  display: flex;
  @extend %primary-xxs;
  margin: $space-unit 0 $space-unit * 3;
  align-items: baseline;

  &::before {
    content: '';
    @include sprite('alert-red');
    position: relative;
    width: 1rem;
    height: 1rem;
    color: $status_error;
    margin-right: $space-unit;
    display: block;
    top: 0.1rem;
  }

  &--float {
    position: absolute;
  }
}

@keyframes cssAnimation {
  to {
    opacity: 0;
  }
}

.alert {
  display: inline-block;
  margin: 0 0 0 $space-unit * 4;

  &--success {
    color: $status_success;
  }

  &--error {
    color: $status_error;
  }

  &--temp {
    animation: cssAnimation 0.5s $easeOutQuart 5s forwards;
    animation-fill-mode: forwards;
  }
}
