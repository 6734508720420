.sm-text-right {
  text-align: right;
  .link {
    margin-top: 0;
  }
}

.registered-in-store-after-email {
  min-width: 36.6rem;
}
