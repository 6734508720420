.three-column-product {
  display: flex;
  text-align: center;
  flex-direction: column;
  margin-bottom: 1.6rem;
  overflow: hidden;
  
  // OE-16934 - Optimize Editorial Module Borders/Paddings
  &.pd-no__padding {
    padding-block:0;
  }
  @include appearAnimation();
}

.three-column-product__product-holder {
  display: flex;
  &.has-gutter {
    margin: 0 -2px;
    width: auto;
    .three-column-product__single-column {
      margin: 0 2px;
    }
  }
}

.single-column__base-image,
.single-column__base-text {
  display: block;
  width: 100%;
}

.three-column-product__single-column {
  position: relative;
  padding-top: 50%;
  width: 100%;
  // overflow: hidden;

  > a {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    outline-offset: 2px;
  }

  img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    max-width: 100vw;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  [aria-expanded='false'] + a {
    display: none;
  }

  [aria-expanded='true'] + a {
    display: block;
  }

  [aria-expanded='true'] + .single-column__image-hover,
  [aria-expanded='true'] + a > .single-column__image-hover,
  [aria-expanded='true'] + .single-column__text-hover,
  [aria-expanded='true'] + a > .single-column__text-hover {
    display: flex;
  }
}

.single-column__base-image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.single-column__base-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @extend %primary-xs;
  text-align: center;
  width: 100%;
  padding: 0 ($space-unit * 8);
}

.single-column__image-hover,
.single-column__text-hover {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.single-column__text-hover {
  background-color: $white;
  @extend %primary-xs;
  align-items: center;
  justify-content: center;
  padding: 0 ($space-unit * 8);
}

.single-column__text-hover,
.single-column__image-hover {
  display: none;
}

.three-column-product__title {
  text-transform: uppercase;
  order: 1;
  max-width: calc(100% - (#{$space-unit} * 8));
  margin: ($space-unit * 12) auto ($space-unit * 3);
}

.three-column-product__cta {
  margin-bottom: ($space-unit * 6);
  order: 3;
}

.three-column-product__subtitle {
  @extend %primary-xs;
  text-align: center;
  margin: 0 auto ($space-unit * 4);
  width: 44rem;
  max-width: calc(100% - (#{$space-unit} * 8));
  order: 2;

  &:last-child {
    margin-bottom: 0;
  }
}

// alignment
.three-column-product--centered-top {
  padding-bottom: 3.2rem;
  .three-column-product__product-holder {
    order: 4;
  }
}

.three-column-product--centered-bottom {
  padding-top: ($space-unit * 8);
  padding-bottom: ($space-unit * 7);
  .three-column-product__cta {
    margin-bottom: 0;
  }
  .three-column-product__product-holder {
    order: 0;
  }
}
