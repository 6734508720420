.editorial-text {
  &__text-wrapper {
    padding-right: ($space-unit * 8);
    padding-left: ($space-unit * 8);
  }
  // &__picture {
  //   max-width: 40%;
  // }
  &--quotes {
    .editorial-text {
      &__description {
        font-size: 2.4rem;
        line-height: 2.8rem;
      }
    }
  }
}
