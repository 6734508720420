.product-highlight--img-bigger {
  .product-slide__img {
    width: 34.3rem;
  }
}

.product-highlight__text-holder {
  padding: ($space-unit * 8) ($space-unit * 4) ($space-unit * 10);
}

.product-highlight__logo-holder {
  width: 8rem;
}

.product-highlight__pagination,
.product-slide__caption {
  margin-top: ($space-unit * 4);
}

.product-highlight__cta {
  margin-bottom: 1.4rem;
}

.img-holder--external-border {
  img {
    padding: ($space-unit * 4);
  }
}
