.aftercare {
  &.confirm-aftercare {
    .wrapper {
      .detail-title {
        margin-bottom: 1.6rem;
      }
    }
    .request-received {
      text-align: center;
    }
    .aftercare__title {
      @extend %primary-m;
      text-align: left;
      text-transform: none;
      margin-top: 2.4rem;
    }
    .aftercare__description {
      text-align: left;
    }
    .aftercare__subtitle {
      @extend %primary-xs;
      margin-top: 2.4rem;
    }
    .setup-password {
      button {
        text-transform: none;
      }
    }
  }
  &__title {
    text-align: center;
    margin-bottom: ($space-unit * 6);
    @extend %primary-m-u;
  }
  &__description {
    text-align: center;
    margin-bottom: 40px;

    &.mb-0 {
      margin-bottom: 0;
    }
  }
  .line {
    margin-top: ($space-unit * 4);
    @include border('top');
    &.divider-ty-page {
      margin-top: ($space-unit * 8);
    }
  }
  .link-box {
    &__title {
      @extend %primary-s-u;
      margin-top: ($space-unit * 4);
      margin-bottom: ($space-unit * 3);
    }

    &__button.button {
      margin-top: ($space-unit * 4);
      width: auto;
      padding-left: 4.2rem;
      padding-right: 4.2rem;
      align-self: center;
    }
  }
  &_accordion {
    &__capitalize {
      text-transform: capitalize;
    }
  }
  &__form {
    margin-bottom: 120px;

    &__step-description {
      margin-bottom: ($space-unit * 6);
    }
    &__button-link {
      margin-top: ($space-unit * 4);
      margin-bottom: ($space-unit * 10);
    }
    .submit-button {
      margin-top: ($space-unit * 10);
    }
    .login-button {
      margin-bottom: ($space-unit * 4);
    }
    .accordion__group {
      .accordion__item {
        .accordion__header {
          height: 5.8rem;
        }
      }
    }
    .wrapper-cta {
      row-gap: 1.2rem;
    }
    .tooltip {
      z-index: 4;
    }
    .image-button {
      label {
        padding: 0 0 0 2.8rem;
      }
    }
    #service-error.invalid-feedback {
      position: initial;
    }
  }
  &__subtitle {
    margin-top: ($space-unit * 3);
    @extend %primary-m-u;
  }
  .bag-product {
    margin-top: ($space-unit * 4);
    &__product-content {
      &__image {
        margin-left: $space-unit * 4;
      }
      display: flex;
      flex: 1;
      flex-direction: column;
    }
  }
  .wrapper {
    margin-top: ($space-unit * 6);
    .detail-address {
      p {
        margin-bottom: $space-unit;
      }
    }
    .detail-row {
      margin-bottom: ($space-unit * 8);
    }
    .detail-title {
      text-transform: uppercase;
      margin-bottom: $space-unit;
    }
  }
  .submit-button {
    margin-top: ($space-unit * 10);
  }
  .mobile-button {
    margin-bottom: ($space-unit * 2);
  }
  .order-detail__column {
    margin-bottom: 0;
  }
  .orders-listing__order-detail {
    justify-content: start;
  }
  .faster-social {
    text-align: center;
  }
  .container-register-form {
    .register_form {
      .login-register-social {
        justify-content: center;
      }
    }
    .title,
    .subtitle {
      text-align: center;
    }
    .subtitle {
      display: flex;
      justify-content: center;
    }
  }
}
