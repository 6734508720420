.product-look {
  .product-look__image {
    margin-bottom: 3.2rem;
  }
  .selector-size {
    .selector__button__info {
      margin-left: 0.5rem;
    }
    .selector__button__value {
      &.selector__button__value--outofstock {
        margin-left: 0.5rem;
      }
    }
  }
}
