.members-only-badge {
  display: flex;
  align-items: center;
  [class^='icon'] {
    margin: 0;
  }
  .label {
    @extend %primary-xs-u;
    padding-left: 2px;
  }
  .product-selection & {
    margin-left: 1.2rem;
    .label {
      color: $black_secondary;
    }
  }
}
