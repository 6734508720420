.product-tile-featured {
  .product-tile {
    &__link {
      height: 573px;
      .product-tile {
        &__info {
          max-height: 400px;
        }
        &__description {
          visibility: visible;
          opacity: 1;
        }
        &__cta {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    &__image-wrapper {
      max-height: 471px;
    }
    .simplified & {
      &__link {
        &:hover {
          img {
            transform: none;
          }
        }
      }
    }
  }
}
