.modal.mbm-palette-modal {
  top: 50%;
  max-height: initial;
  max-width: 750px;
  height: auto;
  width: calc(100% - 160px);
  &::before {
    content: '';
    width: 100%;
    padding-bottom: 98%;
    display: block;
  }
  .modal__close {
    top: 0;
  }
  .modal__body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.palette-details {
  margin-bottom: 0;
  width: 60%;
  max-width: 345px;
}
.palette-bg-modal {
  height: 100%;
}
