.orders-listing__order-detail {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: ($space-unit * 4) ($space-unit * 4) ($space-unit * 3);
  background-color: $grey_disabled;

  &-white{
    @extend .orders-listing__order-detail;
    background-color: transparent;
  }
}

.orders-listing {
  padding-bottom: 40px;

  .accordion__header {
    display: flex;
    flex-direction: column;
    border: none;
  }
  .accordion__item {
    border: 1px solid #e4e4e4;
    margin-bottom: 32px;
  }
}

.order-history-wrapper{
  width: 100%;
  border: 1px solid $grey_line;
}

$boxPadding: 32px 64px;

.order-history-info-box{
  display: flex;
  width: 100%;
  padding: $boxPadding;
  border-bottom: 1px solid $grey_line;
  background-color: $grey_disabled;

  .mb-16{
    margin-bottom: 16px;
  }

  &-labels{
    display: flex;
    gap: clamp(8px, 60px, 80px);
    width: 100%;
    text-align: left;

    .flex-label, .flex-label--align-top {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      column-gap: 4px;
      row-gap: 4px;
      height: fit-content;
    }
  }

  .button.text-nowrap {
    white-space: nowrap;
    padding-inline: clamp(10px, 24px, 60px);
  }
}

.order-history-info-listing{
  padding: $boxPadding;
}

.text-decoration-none {
  text-decoration: none;
}

.order-detail__column {
  width: 33%;
  overflow: hidden;
  text-align: left;

  &-sm{
    @extend .order-detail__column;
    width: 19%;
  }

  &-lg{
    @extend .order-detail__column;
    width: 27%;
  }

  &-vertical{
    @extend .order-detail__column;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-sm{
      @extend .order-detail__column-vertical;
      width: 19%;
    }
  }

  &-map{
    @extend .order-detail__column;
    display: flex;
    gap: 80px;
    width: 100%;
    flex-direction: column;

    &__single{
      @extend .order-detail__column-map;
      flex-direction: row;
    }

    &__multi{
      @extend .order-detail__column-map;
      flex-direction: column;
    }
  }

  .dt {
    display: block;
    @extend %primary-xxs;
    text-transform: uppercase;
    margin-bottom: $space-unit;
  }

  .dd {
    @extend %primary-xs;
    display: block;
  }

  .order-history-container{
    height: 100%;
    display: flex;
    position: relative;
    min-height: 168px;
    width: 126px;

    &-canceled{
      @extend .order-history-container;
      display: grid;

      .order-history-canceled-label, .order-history-img {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
      }

      .order-history-img {
        opacity: 0.5;
      }

      .order-history-canceled-label {
        padding: 5px;
        align-self: end;
        margin-inline: auto;
        margin-bottom: 12px;
        background-color: $white;
        text-transform: uppercase;
        opacity: 0.99;
        text-align: center;
        max-width: 100%;
        overflow-x: auto;

        /* chrome */
        &::-webkit-scrollbar {
          display: none;
        }

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
    }
  }

  .order-history-img{
    object-fit: cover;
  }
}


.bag-product__image {
  max-width: 11.5rem;
  flex-basis: 11.5rem;
  height: 162px;
  position: relative;
}

.bag-product--returned {
  .bag-product__image,
  .bag-product__content {
    opacity: 0.55;
  }
}
.bag-product--returned {
  &.cancelled {
    .bag-product__image,
    .bag-product__name-price,
    .option-product__quantity,
    .option-product__color,
    .option-product__size {
      opacity: 0.55;
    }
    .bag-product__content {
      opacity: 1;
    }
  }
}

.myaccount-wrapper {
  .orders-listing {
    .accordion__header {
      //to do: refactor
      .header__open,
      .header__close {
        top: 1.4rem;
        bottom: initial;
        transform: none;
      }
    }

    .bag-product {
      flex-wrap: wrap;
      &__content {
        max-width: calc(100% - 11.1rem);
        flex-basis: calc(100% - 11.1rem);
      }
      &__details {
        margin-top: ($space-unit * 2);
        div:not(:last-child) {
          margin-bottom: $space-unit;
        }
      }
    }
  }
}

.order-history-section{
  text-transform: uppercase;
  color: $black;

  &-title{
    @extend .order-history-section;
    font-size: 20px;
    line-height: 24px;
    margin-top: -3px;
    span[class^="icon"] {
      margin-bottom: -5px;
      margin-right: 8px;
      margin-left: 8px;
    }
  }

  &-subtitle{
    @extend .order-history-section;
    margin-top: 8px;
    font-size: 14px;
    line-height: 16px;
    color: $black_secondary;
  }

  &-p{
    @extend .order-history-section-subtitle;
    margin-top: 0;
    color: $black;
    text-wrap: nowrap;

    span{
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
    }
  }
  &-listing-subtitle{
    @extend .order-history-section-subtitle;
    margin-top: 0;
    // margin-left: 8px;
  }

  &-number{
    @extend .order-history-section;
    font-size: 16px;
    // line-height: 20px;
    font-weight: bold;
    text-wrap: nowrap;
  }

  &-listing-box{
    @extend .order-history-section;
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &-listing-title{
    @extend .order-history-section;
    font-size: 16px;
    line-height: 20px;
  }

  &-map{
    @extend .order-history-section;
    margin-top: 16px;
    overflow: hidden;

    .swiper-slide {
      //forced, as MYA figma
      min-width: 126px;
      max-width: 126px;
    }
  }
}

.carousel-controls {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;

  &.d-none {
    display: none;
  }

  i {
    width: 2.4rem;
    height: 2.4rem;

    &[class*="icon--arrow-left-rpy-"] {
      @include fixedSprite('arrow-left-black');
    }

    &[class*="icon--arrow-right-rpy-"] {
      @include fixedSprite('arrow-left-black');
      transform: scaleX(-1);
    }

    &.swiper-button-disabled {
      opacity: .4;
    }
  }
}

.order-history-multiship{
  gap: 80px;

  &-info{
    display: flex;
    align-items: baseline;
    gap: 8px;
    flex-wrap: wrap;
  }
}

.order-history-track-title{
  font-size: 20px;
  line-height: 24px;
  color: $black;
}

.order-history-track-subtitle{
  margin-top: 8px;
  padding-bottom: 36px;
  font-size: 14px;
  border-bottom: 1px solid $grey_line;
  color: $black;
}

.order-history-track{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid $grey_line;


  .order-history-track-info{
    margin-right: 20px;
  }

  h2{
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
  }

  p{
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: $black_secondary;
  }

  a{
    width: auto;
    margin-left: 40px;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: $black;
  }

  .disabled {
    color: $black_secondary;
  }

  a.disabled {
    pointer-events: none;
    color: $black_secondary;
    cursor: default;
  }

  span.disabled {
    opacity: 0.5;
  }

  .text-nowrap {
    text-wrap: nowrap;
  }
}

