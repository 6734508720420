.single-banner {
  &.banner-ty-page {
    &::before {
      padding-top: 100%;
    }

    .wrapper-link {
      flex-direction: column;
      row-gap: 1.6rem;
    }
  }

  &::before {
    height: auto;
    padding-top: 66.66%;
  }
}

.myaccount-wrapper {
  .myaccount__emptyOrderButton {
    width: 343px;
  }
  .profile-page-two-columns {
    gap: 2.5rem;
  }

  .myaccount__nftsNext {
    width: 100%;
  }

  .myaccount__section {
    max-width: 100%;
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    .profile-table-section {
      .text-section__dl {
        margin-left: 0;
      }
    }

    .text-section__dl.flex-section {
      flex-wrap: wrap;

      .primary-xxs {
        width: 10ch;
      }

      .last-on-mobile {
        width: 100%;
        order: 3;
      }
    }

    &#addresses {
      .accordion__header {
        .header__close,
        .header__open {
          position: relative;
          transform: translateY(0);
          top: 0;
        }
      }
    }

    .social-toggle-container {
      flex-direction: column;

      .social {
        width: 100%;

        .social-label {
          flex-direction: row-reverse;
          justify-content: space-between;
        }
      }
    }
  }
}
