.component_product_key_technical_details {
  .progress-bar {
    top: 100vw;
  }
  .product-key-part {
    display: flex;
    flex-flow: column;
  }
  .product-key-part-text {
    background-color: $white;
    color: $black;
    text-align: center;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // padding: 0;
  }
  .product-key-part-image {
    overflow: hidden;
    text-align: right;
    flex: 0 0 100vw;
    position: relative;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      object-fit: contain;
      object-position: center bottom;
    }
  }
  .grenoble-details {
    flex-wrap: wrap;
    &__item {
      span {
        font-size: 1.2rem;
      }
    }
  }
  .product-key-technical-details-hero {
    position: relative;
    height: auto;
    margin-bottom: 100vh;
    .image-wrapper {
      padding-bottom: 4.2rem;
    }
    .product-key-technical-details-image {
      position: relative;
      padding: 0;
      width: 70%;
      height: auto;
      margin: 0 auto;
      // left: 50%;
      // transform: scale(1.4);
      transform-origin: center top;
    }
    .product-key-technical-details__background {
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
    }
    .product-key-technical-details-text {
      position: relative;
    }
  }

  .key-steps {
    background-color: white;
    .product-key-technical-details__background {
      z-index: -1;
    }
  }
}
