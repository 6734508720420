.traceability-packaging {
  padding-bottom: 11rem;
  &-content {
    padding: 4rem 3.2rem;
    margin: 0 3.2rem;
    height: 16.6rem;
    max-height: auto;
    .title {
      font-size: 3.2rem;
      line-height: 3.8rem;
    }
    .subtitle {
      line-height: 2rem;
      width: 55%;
    }
  }
}
