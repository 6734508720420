.editorial-carousel__title {
  margin-bottom: -($space-unit * 10);
}

.editorial-carousel {
  // OE-16934 - Optimize Editorial Module Borders/Paddings
  &.pd-no__padding {
    padding-block: 0;
  }
}
