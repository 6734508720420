.collection-intro {
  &__name {
    margin-bottom: 1.2rem;
  }
  &__description {
    max-width: 50%;
    margin: 0 auto 3.2rem;
  }
  .tabs-collection-links {
    padding-left: 0;
  }
  &__links {
    .collections-intro__tab {
      .collection-intro__link-wrapper {
        .with-image & {
          width: calc(100vw - 4.6rem);
        }
      }
    }
    .button {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
}
