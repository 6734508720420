.bag-product{
  &__content{
    justify-content: space-between;
  }

  &__name-price h3{
    margin-bottom: 12px;
  }
}

.order-detail-page{
  .order-info-box-wrapper{
    padding: 0;
  }
}