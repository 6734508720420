.component_product_range_carousel {
  $viewportHeight: calc((var(--viewport-height) - #{$contextual-navigation}) * 2);
  padding-bottom: calc(var(--viewport-height) - #{$contextual-navigation});
  height: $viewportHeight;
  &__title {
    font-size: 4.2rem;
    line-height: 5rem;
  }
  .product-tile__image-wrapper {
    padding: 3.2rem 0;
  }
  &__pagination {
    padding-top: 2.4rem;
  }
}
