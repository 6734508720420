.sticky-tray {
  top: $header-height-desktop;
  bottom: initial;
  padding-top: $space-unit;
  @include border('top');
  padding-bottom: $space-unit;
  @include border('bottom');
  @include z('stickybar');
  transition-delay: 0s, 0.3s;
  transition-property: transform, opacity;
  transition-duration: 0.3s, 0s;
  transform: translateY(-100%);
  &.show {
    box-shadow: initial;
    transform: translateY(0);
    transition-delay: 0.3s, 0s;
  }
  &::before {
    content: initial;
  }
  &__name-price {
    justify-content: flex-end;
    font-size: font-property($moncler-gothic-desktop, 'xs', 'font-size');
    line-height: font-property($moncler-gothic-desktop, 'xs', 'line-height');
    letter-spacing: font-property($moncler-gothic-desktop, 'xs', 'letter-spacing');
  }
  &__price {
    margin-left: ($space-unit * 4);
    margin-right: 3rem;
  }
  &__button {
    .button {
      height: $button-height-desktop;
      span {
        font-size: font-property($moncler-gothic-desktop, 'xs', 'font-size');
        line-height: font-property($moncler-gothic-desktop, 'xs', 'line-height');
        letter-spacing: font-property($moncler-gothic-desktop, 'xs', 'letter-spacing');
      }
    }
  }
  &__modal.modal {
    width: calc(50% + var(--scrollbar-width) / 2);
    top: $header-height-desktop + 4.6rem;
    left: initial;
    bottom: initial;
    padding: 0.8rem 2.8rem 2rem 2.8rem;
    &.ReactModal__Content {
      transform: translateY(-3.2rem);
    }
    &.ReactModal__Content--after-open {
      transform: translateY(0);
    }
    &.ReactModal__Content--before-close {
      transform: translateY(-3.2rem);
    }
    .modal__close {
      padding: 2.2rem;
    }
    .selector-size-expanded .selector__menu ul {
      min-width: initial;
    }
  }
  &__overlay {
    &.overlay-modal {
      @include z;
    }
  }
}

body.has-ticker {
  .sticky-tray {
    top: $header-height-desktop + $ticker-bar;
  }
  .sticky-tray__modal.modal {
    top: $header-height-desktop + 4.6rem + $ticker-bar;
  }
}
