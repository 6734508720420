// ******************
// ******************
// OPTIONBOX (RADIO OR CHECKBOX) https://storybook.local.ows.farm:6006/?path=/story/form-optionbox-radiobox-and-checkbox--shipping-selection
// note that the option content is a molecule indipendent from the checkbox and radiobox
// ******************
// ******************

.optionbox {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  row-gap: ($space-unit * 2);
  margin-right: -0.2rem;
  margin-left: -0.2rem;

  label {
    display: block;
    cursor: pointer;
    position: relative;
  }

  &__item {
    @include col;
    // @extend %primary-s; removed by @vala
    // manually add it to the inner titles
    // margin-bottom: $space-unit * 3;
    // removed from generic, add it on specific cases
    position: relative;

    &__checkbox,
    &__radio {
      position: absolute;
      top: 1.7rem;
      right: 2rem;
      &--centered {
        top: 50%;
        transform: translateY(-50%);
      }

      &:checked + .optionbox__item__content,
      &:checked + .optionbox__item__content {
        border-color: $black;
      }

      &:checked + .optionbox__item__content--no-outline,
      &:checked + .optionbox__item__content--no-outline {
        color: $black;
      }

      &:focus {
        outline-offset: 0.2rem;
        & + .optionbox__item__content,
        & + .optionbox__item__content {
          @include focus-underline;
        }
      }
    }

    &__radio {
      @include radio();
    }

    &__checkbox {
      @include checkbox();
    }

    &__content {
      border: $space-line solid $black_inactive;
      padding: 1.2rem 3.4rem 1.2rem 1.2rem;
      @include animate;
      display: flex;
      height: 100%;

      .bag-product__details {
        margin-bottom: 0;
      }

      &--bordered {
        border: 1px solid $grey_line !important;
        margin-bottom: 0.4rem;
      }

      &--no-outline {
        color: $black_secondary;
        @include animate;
        padding: 0 0 $space-unit * 4;
        border: none;
      }
      &--disabled {
        .bag-product__image,
        .bag-product__name-price,
        p:not(.error-message) {
          opacity: 0.55;
        }
      }

      &.flex-wrap {
        flex-wrap: wrap;
      }
    }
  }

  // optionbox modifiers

  &--horizontal-two-items {
    .optionbox__item {
      @include col-width;
    }
  }

  &--address {
    .optionbox__item {
      @include col-width;
    }
  }

  &--vertical {
    flex-direction: column;
    margin-bottom: 1.6rem;
  }

  &--product {
    .optionbox__item {
      &__radio,
      &__checkbox {
        @include center('vertical');
      }
    }
  }
}

.client-service.account-appointments {
  .optionbox {
    column-gap: 0;
    &__item {
      padding-left: 0;
      padding-right: 0;
      &__checkbox,
      &__radio {
        &:checked + .optionbox__item__content,
        &:checked + .optionbox__item__content {
          border-bottom-color: $black;
        }

        &:not(:checked) + .optionbox__item__content,
        &:not(:checked) + .optionbox__item__content {
          border-bottom-color: $grey_line;
        }
      }
    }
  }
}

.is-invalid {
  .optionbox {
    color: $status_error;
  }
}

// ******************
// ******************
// OPTIONS molecules
// ******************
// ******************

// ******************
// OPTION PRODUCT
// ******************

.option-product {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  @extend %primary-s;
  padding-right: $space-unit * 4;

  &__image {
    max-width: 5.6rem;
    flex-basis: 5.6rem;
    margin-right: $space-unit * 4;
  }

  &__quantity,
  &__size,
  &__color {
    @extend %primary-xs;
    margin-top: $space-unit;
  }

  &__price {
    margin-bottom: $space-unit * 3;
  }
}

// ******************
// OPTION STORE
// ******************

.option-store {
  // @extend %primary-s;
  padding-left: 1.4rem;

  &__index {
    display: inline-block;
  }
  &__title {
    @extend %primary-s;
    display: inline-block;
    margin-left: $space-unit * 2;
    margin-bottom: ($space-unit * 2);
  }
  // &__address {
  //   @extend %primary-xxs;
  // }
}

// ******************
// OPTION SAVED CARDS - ADDRESSES
// ******************

.option-card,
.option-address {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  row-gap: 0.8rem;

  &__name {
    @extend %primary-s;
    display: flex;
    align-items: center;
    [class^='icon'] {
      position: relative;
      @include fix-font-space;
      margin-left: $space-unit * -2;
    }
  }
}
.optionbox--horizontal-three-items {
  margin-bottom: 4rem;
  max-height: 436px;
  @include scrollbar;
  .optionbox__item {
    @include col-width;
  }
}
