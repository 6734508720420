.myaccount-wrapper {
  //margin-top: ($space-unit * 16);
  .myaccount-content {
    margin-top: 0;
  }
  // .wishlist__products-listing {
  //   grid-template-columns: repeat(2, 1fr);
  // }
}
.single-banner {
  &.parfum-banner {
    .badge-row {
      margin-top: 2rem;
      > a {
        display: inline-block;
      }
    }
    .single-banner__title {
      font-size: ($space-unit * 6);
      line-height: ($space-unit * 7);
    }
  }
  &__title {
    font-size: 4.2rem;
    line-height: 5rem;
  }
}
.mt-section {
  margin-top: 80px;
}
