.text-assets {
  padding: ($space-unit * 10) ($space-unit * 4);
}

.sub-headline {
  &--short {
    .sub-headline__inner-container {
      width: 100%;
    }
  }
  &--long {
    .sub-headline__inner-container {
      width: 100%;
    }
  }
}

.centered-text-block {
  &__inner-container {
    width: 100%;
  }
}

.two-column-text-block {
  &__inner-container {
    width: 100%;
    display: block;
  }

  &__inner-container > * {
    width: 100%;
    &:first-child {
      margin-bottom: ($space-unit * 4);
    }
  }
}

.text-column {
  &__inner-container {
    width: 100%;
    & > *:first-child {
      margin-bottom: ($space-unit * 4);
    }
  }
}
