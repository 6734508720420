.editorial-multi-text__text-column {
  &.mbm-promo {
    .editorial-multi-text__btn-container {
      column-gap: 1.6rem;
      // .button--tertiary {
      //   display: none;
      // }
      .button {
        min-width: 175px;
        max-width: 175px;
      }
    }
  }
  .editorial-multi-text__quote {
    padding-bottom: 4.8rem;
  }
}

.editorial-multi-text__inner-container {
  width: 100%;
  margin: 0;
  max-width: 100%;
}
