.product-hero,
.product-hero--placeholder {
  position: relative;
  padding-bottom: $product-ratio;
  overflow: hidden;
  background-color: $black;
}
.product-hero {
  width: 100%;
  text-align: left;
  display: block;
  // picture,
  img[src] {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    object-fit: cover;
    // top: 48%;
    // transform: translateY(-50%);
  }
}
.cursor {
  display: none;
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  @include z(cursor);
  @include animate;
  &--hidden {
    @extend .cursor;
    opacity: 0;
  }
}

.th-player-external-element .th-controls,
.th-player .th-controls {
  display: none !important;
}

.product-hero-gallery {
  .swiper-slide {
    @include animate(0.3);
    opacity: 0 !important;
    pointer-events: none;
    &[data-swiper-slide-index='0'] {
      .product-image img {
        object-position: top;
      }
    }
    &.swiper-slide-active {
      opacity: 1 !important;
      pointer-events: auto;
    }
  }

  .swiper-pagination {
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 6.6rem;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;

    &.swiper-pagination-bullets {
      bottom: 2rem;
    }
  }

  .button--arrow-left,
  .button--arrow-right {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 2;
  }
  .button--arrow-left {
    left: 1.2rem;
    &::after {
      @include fixedSprite('arrow-right-white');
      width: 2.8rem;
      height: 2.8rem;
    }
  }

  .button--arrow-right {
    right: 1.2rem;
    &::after {
      @include fixedSprite('arrow-right-white');
      width: 2.8rem;
      height: 2.8rem;
    }
  }

  .swiper-slide {
    // &::before {
    //   content: '';
    //   background: linear-gradient(181.75deg, rgba(0, 0, 0, 0.65) 1.48%, rgba(0, 0, 0, 0.1) 65.72%, rgba(0, 0, 0, 0) 84.39%);
    //   opacity: 0.65;
    //   transform: rotate(-180deg);
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   right: 0;
    //   height: 10.3rem;
    //   z-index: 2;
    // }
  }
  .swiper-pagination-bullet {
    width: 5.8rem;
    height: 0.1rem;
    border-radius: 0;
    background: #fff;
    position: relative;
    margin: 0.2rem;
    opacity: 1;
    &.swiper-pagination-bullet-active {
      background: #fff;
      height: 0.2rem;
    }
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 4.4rem;
    }
  }
}

.contactus-modal {
  text-align: center;
  .contact-us {
    .line {
      margin-top: 3.2rem;
      display: flex;
      align-items: flex-end;
    }
    &__desc {
      // margin-top: 1.5rem;
      margin-bottom: 3.34rem;
    }
  }
}
