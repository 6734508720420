.inline-alert-message {
  padding: 2rem 4.9rem 2rem ($space-unit * 4);
  &__text-holder {
    align-items: flex-start;
  }
  &__icon {
    margin-right: ($space-unit * 4);
  }
  &__close-btn {
    transform: none;
    top: 1.4rem;
  }
}
