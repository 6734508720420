.curators-look__component-wrapper {
  .curator-look__slider {
    flex-direction: column;
  }
  .info-box-curator {
    button {
      width: 100%;
      margin: 0 1.6rem;
    }
  }
  .curator-look-card {
    margin-bottom: 4.4rem;
  }
}
