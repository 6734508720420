.collection-intro {
  &__link-wrapper {
    bottom: 1.9rem;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      width: 2rem;
      height: 100%;
      top: 0;
      right: 0;
      // background: linear-gradient(270deg, #ffffff 9.78%, rgba(255, 255, 255, 0) 72.13%);
      background: linear-gradient(270deg, #ffffff 20%, rgb(255 255 255 / 50%) 100%);
    }
  }
  &__links {
    &:not(.tabs) {
      // display: flex;
      overflow-x: scroll;
      white-space: nowrap;
      padding-right: 1.6rem;
      padding-left: 1.6rem;
      -ms-overflow-style: none;
      scrollbar-width: none;
      .collections-intro__link {
        flex: none;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .collections-intro__tab {
    .tabs-collection-links {
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        display: none;
      }
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      white-space: nowrap;
      padding-right: ($space-unit * 4);
      padding-left: ($space-unit * 4);
      padding-bottom: ($space-unit * 2);
      .collections-intro__link {
        flex: none;
      }
    }
  }
}

// ::-webkit-scrollbar {
//   display: none;
// }
