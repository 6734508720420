.editorial-carousel {
  position: relative;
  padding: 4.8rem 0 7.2rem;
  overflow: hidden;
  @include appearAnimation();
}

.editorial-carousel__title {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 2.4rem;
  font-weight: 300;
}

.editorial-carousel__carousel-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.carousel-slide__img-holder {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  img {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.carousel-slide__inner-slide,
.carousel-slide__caption-holder {
  width: 79%;
  margin: 0 auto;
}

.editorial-carousel__swiper-container {
  position: relative;
  &::before,
  &::after {
    content: '';
    position: absolute;
    display: inline-block;
    pointer-events: none;
    background: $white;
    z-index: 3;
    top: -1rem;
    bottom: -1rem;
    width: 10.5%;
    left: 0;
  }

  &::after {
    left: initial;
    right: 0;
  }
}

.carousel-slide__inner-slide {
  position: relative;
  margin-bottom: 1.6rem;
}

.carousel-slide__caption-holder {
  @extend %primary-xxs;
}

.editorial-carousel__carousel-slide {
  min-width: 100%;
}

.carousel-slide__text-holder {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 2.4rem;
  line-height: 2.8rem;
  font-weight: 300;
  width: 66.6rem;
  max-width: calc(100% - 9rem);
}

.editorial-carousel__arrow {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: ($space-unit * 8);
  height: ($space-unit * 8);
  padding: 0;
  left: ($space-unit * 8);
  z-index: 4;
  &::after {
    width: ($space-unit * 8);
    height: ($space-unit * 8);
  }
  &.editorial-carousel__arrow--arrow-right {
    left: initial;
    right: ($space-unit * 8);
  }
}

.swiper-slide--mobile-gab {
  min-width: ($space-unit * 4);
  width: ($space-unit * 4);
  display: none;
  max-width: ($space-unit * 4);
}
