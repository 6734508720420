.rich-video {
  height: 61.7rem;
  &--portrait {
    .grid-container & {
      min-height: 115.1rem;
      height: 100%;
    }
  }
  &--landscape {
    .grid-container & {
      grid-column: span 4;
    }
  }
  &__block {
    &.light {
      border: 2.8rem solid $white;
    }
    &.dark {
      border: 2.8rem solid $black;
    }
  }
  &__description {
    max-width: 62rem;
  }
}
