.collection-intro-sticky {
  .page-scrolled-up & {
    transform: translateY(var(--headerHeight));
    visibility: visible;
  }
  .sticky-intro-disabled & {
    transform: translateY(-100%);
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $white;
    top: 0;
  }
  &__links {
    .expanded & {
      .page-scrolled &,
      .page-scrolled-up & {
        transform: translateY(0);
      }
      .sticky-intro-disabled & {
        transform: translateY(-200%);
      }
    }
    position: absolute;
    // top: calc(100% + 1.6rem);
    top: 100%;
    width: 100%;
    background-color: $white;
    left: 0;
    border-top: 1px solid $grey_line;
    border-bottom: 1px solid $grey_line;
    padding: 1.6rem 2rem;
    @include animate(0.4);
    transform: translateY(-200%);
    z-index: -1;
    text-align: left;
    li {
      display: block;
      padding: 0;
    }
  }
}
