#addresses, #payments {
  // &.myaccount__section{
  //   .swiper-pagination {
  //       display: none!important;
  //     }
  // }
  .swiper-container {
    .swiper-pagination-bullets {
      display: none;
    }
  }
  .empty-state-element {
    .button{
      margin-top: 0;
    }
  }
}

#payments {
  .swiper-container {
    .swiper-wrapper {
      justify-content: center;
    }
  }
}

#privacysettings {
  padding-top: 120px;
}

// modal size
.modal--fixed {
  &.personalinfo-edit-modal, &.addressbook-edit-modal {
    width: 100%;
    max-width: calc(68.4% - 64px);
    min-width: initial;
  }

  &.change-field-modal, &.modal-width, &.delete-account {
    width: 100%;
    max-width: calc(33.4% - 32px);
    min-width: initial;
  }
}