.product-tile-featured {
  .product-tile {
    &__link {
      height: 415px;
    }
    &__image-wrapper {
      max-height: 343px;
    }
    &__info {
      min-height: 10rem;
      max-height: 10rem;
      padding: ($space-unit * 7) ($space-unit * 3) ($space-unit * 7);
    }
  }
}
