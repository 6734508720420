body {
  &.no-scroll {
    overflow-y: hidden;
  }
}

main {
  &.main--autoheight {
    min-height: calc(100vh - #{$footer-clientservice-height-mobile} - #{$header-height-mobile});
    padding-bottom: $space-unit * 6;
  }

  &.main--checkout {
    min-height: calc(100vh - #{$footer-clientservice-height-mobile} - #{$header-height-mobile});
    padding-bottom: $space-unit * 9;
    padding-top: 0;
  }
}
