.product-selection {
  padding-top: 0.8rem;
  &.container {
    padding-top: 1.6rem;
  }

  .product-selection__line {
    // margin: 0;
    // padding: 0;
    // border: none;
    &-name_exclusive {
      display: block;
      margin: 0 0 ($space-unit * 3) 0;
    }
  }

  // .product-selection__heading {
  //   padding-bottom: 0;
  //   padding-top: $space-unit;
  // }

  .product-selection__ctas {
    &.last-cta {
      margin-bottom: 0.4rem;
    }
  }
  .info-box {
    margin-top: 2rem;
    margin-bottom: 2.4rem;
  }

  .product-selection__actions-shipping {
    &__actions {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .link-anchor {
    margin-bottom: 4.5rem;
  }
  .product-selection__ctas {
    display: block;
    margin-bottom: 0;

    .button {
      margin-right: 0;
      &:not(:only-child):not(:last-of-type) {
        margin-bottom: $space-unit * 2;
      }
    }
  }
  .link-anchor {
    &.no-margin-bottom {
      margin-bottom: 2.2rem;
    }
  }
  .mbm-icons {
    margin-bottom: 8.2rem;
  }
}

.collection-modal {
  &.modal {
    padding: 0;
  }
  &__title-wrapper {
    height: 6rem;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 2.4rem;
    padding: 0 1.6rem;
  }
  &__description {
    padding: 0 1.6rem;
  }
}
.product-details-tab {
  padding: 3rem 1.6rem;
  &.drawer-shipping-returns,
  &.drawer-details-composition {
    &.no-margin-right {
      padding-right: 0;
      .react-tabs__tab-list {
        margin-right: 1.6rem;
        border-bottom: 1px solid #e4e4e4;
      }
      .wrapper-tab-panels {
        margin-right: 1.6rem;
      }
      .wrapper-tab-list {
        display: inline-flex;
        border-bottom: 0;
      }
    }
    .grenoble-details {
      align-items: start;
      flex-wrap: wrap;
      row-gap: 2.4rem;
      .grenoble-details__item {
        flex: none;
        flex-basis: calc(100% / 3);
      }
      //only for the first three elements
      .grenoble-details__item:nth-child(-n + 3) {
        padding-bottom: 1rem;
      }
    }
  }
  .wrapper-product-details {
    .react-tabs__tab-panel--selected {
      max-height: 85vh;
      overflow-x: hidden;
      .product-details {
        overflow-x: hidden;
      }
    }
  }
  .composition-care-icons-container {
    grid-template-columns: 100%;
    gap: 0.8rem;
  }
  .composition-care-icons {
    padding: 0 2rem 0 0;
  }
  .composition-description {
    margin-top: 3.2rem;
  }
  .care-description {
    margin-top: 1.2rem;
  }
}
