.curators-listing-wrapper {
  .product-tile--carousel {
    .selector-color-link {
      // padding-left: 1.6rem;
      .selector__menu__item__label {
        display: none;
      }
    }
  }
}
