.visual-navigation {
  padding: 1.6rem 0;
  &__item {
    min-width: 84.5%;
  }
  &__wrapper {
    &::before,
    &::after {
      content: '';
      width: 1.6rem;
      min-width: 1.6rem;
    }
  }
  .aspect-ratio--2-3 {
    min-width: 11.2rem;
  }
}
