.product-container {
  &:first-child {
    padding-top: $header-height-desktop;
  }
  &--hero {
    flex-wrap: unset;
  }

  .a11y-action-bar {
    top: 5.6rem;
    body.has-ticker & {
      top: 8.8rem;
    }
  }

  .product-col {
    // width: 50%;
    flex: 1;
  }

  .product-col--gutter-dx {
    padding-right: ($space-unit * 7);
  }

  .product-col--gutter-sx {
    padding-left: ($space-unit * 7);
  }
  .toggle-vto {
    bottom: 0;
    right: 0;
    left: auto;
    flex-direction: row;
    column-gap: 0.4rem;
    padding-right: 1rem;
    padding-bottom: 1.2rem;
    transform: translate(0);
    span {
      border-bottom: 1px solid;
    }
  }
}

body.has-ticker {
  .product-container:first-child {
    padding-top: $header-height-desktop + $ticker-bar;
  }
}
