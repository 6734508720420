.curators-look__component-wrapper {
  background-color: $black;
  color: $white;
  padding: 10rem 0;
  .swiper-wrapper {
    height: auto;
  }
  &.unlocked {
    .info-box-curator {
      display: none;
    }
  }

  .info-box-curator {
    padding-top: 5rem;
    display: flex;
    justify-content: center;
    button {
      background-color: white;
      text-transform: uppercase;
      padding: 1rem 2rem;
      text-decoration: none;
      &:focus {
        outline: 1px solid white;
        outline-offset: 2px;
      }
    }
  }

  .curator-looks__title {
    font-family: $silkSerifLight;
    font-size: 4.6rem;
    line-height: 4.4rem;
    margin-bottom: 4rem;
  }
  .curator-look-card {
    &.locked {
      img {
        opacity: 0.3;
      }
    }
    .curator-card__title {
      font-family: $silkSerifLight;
      margin-top: 1.6rem;
      font-size: 2.4rem;
      line-height: 2.8rem;
      border-bottom: 1px solid $white;
      padding-bottom: 1.6rem;
      margin-bottom: 1.6rem;
    }
    .curator-card__description {
      font-size: 1.4rem;
      line-height: 1.6rem;
    }
    .button {
      margin-top: 1.2rem;
      &.locked-button {
        i {
          margin-right: 1.6rem;
        }
      }
    }
  }
  .login-cta {
    margin-top: 5.6rem;
    width: auto;
  }
}
.wrapper-card-up {
  position: relative;
  .container-btn-login {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 2;
    .button {
      height: 100%;
      width: 100%;
      display: block;
      margin: 0;
    }
  }
}

.curator-carousel__carousel-wrapper {
  .swiper-wrapper {
    height: auto;
  }
  .swiper-slide {
    &:focus,
    &:focus-visible,
    &:focus-within {
      img {
        outline: 3px solid white;
        outline-offset: 1px;
      }
    }
  }
}
