.collections-inpage-navigation {
  height: 56.2rem;
  &.with-borders {
    margin: ($space-unit * 5);
    padding-left: 0;
    padding-right: 0;
  }
  &__inner-container {
    min-height: 56.2rem;
    padding: 4rem 2.8rem;
  }
  .collection-open {
    // .resized:first-child {
    //   // margin-top: -6rem;
    // }
    .inpage-collection-component:not(.resized) {
      & + .resized {
        margin-top: 3rem;
      }
    }
  }
  .inpage-collection {
    padding: 1.8rem 0;
    &.expanded {
      .button {
        display: inline-flex;
      }
      .inpage-collection {
        // &__info-box {
        //   top: 14rem;
        // }
        &__logo {
          top: 2.2rem;
        }
        &__name {
          // margin-bottom: 14rem;
          // margin-top: 7rem;
          margin-bottom: 12.2rem;
          margin-top: 6.8rem;
        }
        &__info-box {
          top: 13.1rem;
        }
        &__toggle-icon-wrapper {
          margin-top: 0.9rem;
        }
      }
    }
    &__description {
      // max-width: 46rem;
      margin: 0 auto;
    }
    &__name {
      font-size: 3.2rem;
      line-height: 3.8rem;
      margin-bottom: 3rem;
    }
    &__info-box {
      top: 6rem;
      .button {
        min-height: 3.6rem;
      }
    }
    &__toggle-icon-wrapper {
      // margin-top: 0;
      position: absolute;
      transition: all 0.3s linear;
      left: 50%;
      transform: translateX(-50%);
      margin-top: -2.2rem;
    }
    .button {
      width: auto;
    }
  }
}
