.product-selection {
  position: sticky;

  height: auto;
  transition: 1s ease;
  transition-property: opacity, transform;
  transition-delay: 0.3s;
  &-content {
    padding-top: ($space-unit * 4);
  }

  .product-selection__line {
    margin-bottom: 1.3rem;
    // margin: 0 0 ($space-unit * 2) 0;
    // padding: 0 0 ($space-unit * 2) 0;

    [class^='icon'] {
      margin-right: ($space-unit * 2);
      width: 1.4rem;
      height: 2rem;
    }
  }

  .product-selection__line-name {
    text-align: left;
    display: flex;
    align-items: center;
    padding-bottom: ($space-unit * 5);
    flex-wrap: wrap;
    row-gap: 0.4rem;

    a {
      font-size: 1.2rem;
      line-height: 1.4rem;
      text-transform: uppercase;
      text-decoration: none;
      display: flex;
      align-items: center;
      white-space: nowrap;
      &.in-pdp {
        &:last-child {
          &::after {
            display: none;
          }
        }
        &::after {
          content: '';
          margin-right: 8px;
          margin-left: 8px;
          @include fixedSprite('arrow-right-breadcrumbs');
        }
      }
    }
    .icon--lock {
      width: 2.4rem;
      height: 2.4rem;
      margin-right: 0.3rem;
    }
  }
  .fitting-caption {
    strong {
      font-weight: normal;
      text-transform: uppercase;
    }
  }
  .product-labels {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .product-selection__line-labels {
    // padding-top: 1.2rem;
    .product-label {
      @extend %primary-xxs-u;
      display: inline-flex;
      padding-bottom: 0;
      margin-top: 1.1rem;
      color: $black_secondary;
      text-decoration: none;
      &.grenoble {
        display: flex;
        column-gap: 0.4rem;
        align-items: center;
        &::before {
          content: '';
          @include fixedSprite('grenoble');
          opacity: 0.55;
          width: 1.6rem;
          height: 1.6rem;
        }
      }
      &.genius {
        display: flex;
        column-gap: 0.4rem;
        align-items: center;
        &::before {
          content: '';
          @include fixedSprite('genius');
          opacity: 0.55;
          width: 1.6rem;
          height: 1.6rem;
        }
      }
      &:not(:first-child):before {
        content: '|';
        padding: 0 0.7rem;
      }
    }
  }
  .link-anchor {
    text-transform: uppercase;
    align-items: center;
    display: flex;
    text-decoration: underline;
    column-gap: 0.2rem;
    &::before {
      content: '';
      @include fixedSprite('plus-large-black');
      margin-left: -0.3rem;
    }
  }
  .product-selection__description {
    @extend %primary-xs;
    margin: ($space-unit * 8) 0;
    margin-bottom: ($space-unit * 6);
    opacity: 0.55;
  }
  .style-wrapper {
    display: flex;
    column-gap: 1rem;
    padding-bottom: 2rem;
    p {
      opacity: 0.55;
    }
    .link {
      text-decoration: none;
      border-bottom: 1px solid;
    }
  }
  .product-selection__preorder {
    @extend %primary-xs-u;
    margin-bottom: $space-unit * 4;
    text-align: left;
  }

  .product-selection__name-price {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    // align-items: center;
    // padding-top: ($space-unit * 2);
  }
  .selector-color-expanded {
    .selector__label {
      padding-top: 0;
      .selector__label__name {
        opacity: 0.55;
        margin-right: 1rem;
      }
      .selector__label__value {
        text-transform: uppercase;
      }
    }
    .selector__menu {
      padding: 0.6rem 0 1.5rem;
      flex-wrap: wrap;
    }
  }
  .selector-size-expanded {
    .selector__header {
      padding-top: 1.4rem;
      padding-bottom: 0.8rem;
      align-items: flex-start;
    }
    .selector__menu--size {
      padding-bottom: 1rem;
      column-gap: 0.8rem;
      .selector-size-item {
        min-width: 2rem;
        height: auto;
        cursor: pointer;
      }
    }
    .selector__label__name {
      opacity: 0.55;
    }
    .selector__label__value {
      margin-left: 1rem;
    }
    .btn-label-find-size {
      flex-direction: row-reverse;
      text-decoration: underline;
      .icon--new-ruler {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }

  .product-selection__name-price__name {
    @extend %primary-l-u;
    text-align: left;
    padding-right: ($space-unit * 8);
    line-height: 1.2;
    padding-bottom: 1.3rem;
  }

  .product-selection__name-price__price {
    @extend %primary-xs;
    flex-shrink: 0;
  }

  .errormessage {
    display: flex;
    color: $status_error;
    margin-bottom: 0.8rem;
    align-items: center;
    column-gap: 0.4rem;
    position: absolute;
    bottom: 0;
    &:before {
      content: '';
      @include sprite('info-red');
      width: 1.2rem;
      height: 1.2rem;
      fill: red;
    }
  }
  .info-box {
    margin-top: 1.4rem;
    border: none;
    padding: 0;
    margin-bottom: 1.8rem;
    opacity: 0.55;
    b {
      font-weight: normal;
    }
  }
  .product-label-vto {
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 3.2rem;
  }

  .product-selection__ctas {
    display: flex;
    margin-top: 3.4rem;
    margin-bottom: ($space-unit * 4);
    &.last-cta {
      margin-bottom: 0.4rem;
    }
    .button {
      margin-right: $space-unit;
      @include text-shorten;

      &:last-child {
        margin-right: 0;
      }
    }
    .wishlist-icon:focus {
      outline-offset: -4px;
    }
  }

  .product-selection__actions-shipping {
    display: flex;
    flex-wrap: wrap;
    // align-items: center;
    justify-content: space-between;
    row-gap: 1.6rem;
    .product-selection__shipping-disclaimer {
      margin: 0;

      & + .wrapper-cta {
        flex-grow: 0;
        button {
          white-space: nowrap;
        }
      }
    }

    .wrapper-cta,
    .product-selection__shipping-disclaimer,
    .product-selection__shipping {
      flex-basis: 34%;
      flex-grow: 1;
    }

    div {
      &:nth-child(even) {
        text-align: end;
      }

      .shipping-disclaimer_description {
        width: 100%;
        // max-width: 16.6rem;
        text-align: start;
        padding-right: 1.2rem;
      }
    }
    .button--arrow-right {
      &::after {
        top: 54%;
      }
    }
    &:not(.on-pdp) {
      > .button {
        color: $white;
      }
    }

    &__contact-us {
      position: relative;
      height: 1.6rem;
    }

    &__shipping {
      @extend %primary-xs;
      text-transform: capitalize;
    }
  }

  .product-selection__modelinfo {
    position: relative;
    margin: $space-unit * 9 0 $space-unit * 1;
    padding-left: $space-unit * 6;
    @include sprite('info');
    background-position: left;
    background-repeat: no-repeat;
    background-size: $space-unit * 4 $space-unit * 4;
    color: $black_secondary;
    min-height: $space-unit * 4;
    display: flex;
    align-items: center;

    span {
      @extend %primary-xs;
    }
  }

  .mbm-icons {
    @include reset-list;
    display: flex;
    column-gap: 1.6rem;
    margin-bottom: 1.2rem;
    margin-top: -0.4rem;
    > li {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 6rem;
      white-space: nowrap;
      span {
        margin-top: 0.6rem;
        font-size: 1.2rem;
        line-height: 1.4rem;
      }
    }
  }
  .product-selection__wrapper {
    &.on-pdp {
      position: relative;
      .selector-color-expanded {
        .swatch {
          width: $swatch-pdp-size-desktop;
          height: $swatch-pdp-size-desktop;
          background-size: contain;
          border: 1px solid grey;
          cursor: pointer;
          &::after {
            width: $swatch-pdp-size-desktop;
          }
        }
        .selector__menu {
          column-gap: 2rem;
        }
        .selector__menu__item {
          margin-right: 0;
          padding: 0.5rem 0;
          &--link:focus-visible {
            outline: none;
          }
          &.is-disabled {
            .stroke {
              width: 2.4rem;
            }
          }
        }
      }
      .selector__error {
        display: none;
        position: absolute;
        bottom: 0;
        width: 100%;
      }
    }
  }
  .members-only-badge {
    &.product-label {
      margin-left: 0;
      .label {
        @extend %primary-xxs;
      }
      .icon--lock {
        margin-right: 0.2rem;
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }
  &__shipping-disclaimer {
    width: 100%;
    margin: 1.2rem 0;
    .shipping-disclaimer__title {
      display: flex;
      align-items: center;
      margin-bottom: 0.2rem;
      i {
        margin-right: 0.4rem;
      }
    }
    .shipping-disclaimer_description {
      font-size: 1.2rem;
      span,
      a,
      button {
        display: inline;
        color: $black_secondary;
      }
    }
  }
}

.collection-modal {
  &.modal {
    min-width: 0;
    width: 54.9rem;
    max-width: 100%;
    padding: 3.2rem 3.6rem;
  }
  &__title-wrapper {
    @extend %primary-m;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    [class^='icon'] {
      width: 1.4rem;
      height: 2rem;
    }
  }

  &__title {
    margin-left: ($space-unit * 2);
  }
  &__btn-container {
    text-align: center;
    margin-top: 2.7rem;
  }
  .button {
    display: inline-flex;
    width: auto;
    min-width: 22.6rem;
  }
}
.product-label-vto {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #e4e4e4;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  text-transform: uppercase;
  column-gap: 4px;
  .icon--vto {
    filter: invert(1);
  }
}
.product-details-tab {
  padding: 6.6rem;
  height: 100%;
  .row,
  .wrapper-product-details,
  .tabs,
  .wrapper-tab-panels {
    height: 100%;
  }
  .react-tabs__tab-panel--selected {
    height: 100%;
    overflow-y: auto;
    padding-right: 0.5rem;
    @include scrollbar;
  }
  .product-details {
    position: relative;
    top: 0;
  }
  .row,
  .wrapper-product-details {
    width: 100%;
    .react-tabs__tab-panel {
      visibility: hidden;
      opacity: 0;
      transform: translateY(125px);
      transition: all 0.75s cubic-bezier(0.25, 0.5, 0.25, 1);
    }
    .react-tabs__tab-panel--selected {
      opacity: 1;
      visibility: visible;
      pointer-events: initial;
      transform: translateY(0);
      transition-property: all;
    }
  }
  .react-tabs__tab-list {
    padding-left: 0;
    column-gap: 1.6rem;
    width: fit-content;
  }
  .wrapper-tab-list {
    padding: 0;
    .react-tabs__tab {
      @extend %primary-xxs;
    }
  }
  .product-details {
    padding-top: 3.2rem;
    padding-left: 0;
    padding-right: 0;
  }
  .composition-care {
    img {
      width: 3rem;
      height: 3rem;
      filter: brightness(0);
      display: inline-block;
      margin: 0 0.8rem 0.8rem 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .container-composition-care {
    padding-bottom: 2.4rem;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 2.4rem;
  }
  .composition-care-icons-container {
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 1.2rem;
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .composition-care-icons {
    display: flex;
    align-items: center;
  }

  .wrapper-sizeandfit {
    ul {
      list-style-type: none;
      padding-left: 0;
      &.mb-6 {
        padding-bottom: 0;
        margin-bottom: 0.6rem;
      }
      li {
        padding: 0;
        &.mb-18 {
          margin-bottom: 1.8rem;
        }
      }
    }
    &.giftcard {
      ul {
        list-style-type: disc;
        padding-left: 1.6rem;
      }
    }
  }
  .wrapper-detail-composition {
    ul {
      list-style-type: disc;
      padding-left: 1.6rem;
      margin-right: 0.5rem;
      li {
        padding: 0;
      }
    }
  }
  .grenoble-details {
    justify-content: flex-start;
    align-items: start;
    padding: 3.2rem 0;
    flex-wrap: wrap;
    row-gap: 3.2rem;
    .icon--grenoble-lightweight,
    .icon--grenoble-waterrepellent,
    .icon--grenoble-waterproof,
    .icon--grenoble-windproof,
    .icon--grenoble-insulated,
    .icon--grenoble-breathable,
    .icon--grenoble-packable,
    .icon--grenoble-performancecomfortfit {
      width: 4rem;
      height: 4rem;
    }
    .grenoble-details__item {
      margin: 0;
      min-width: auto;
      max-width: 100%;
      flex: none;
      flex-basis: calc(100% / 6);
      &.is-logo {
        padding-top: 0;
      }
      span {
        padding-bottom: 0;
      }
    }
  }
  .mbm-list {
    padding: 3.2rem 0;
    .palette-accordion {
      row-gap: 2.4rem;
      column-gap: 2.4rem;
      flex-wrap: wrap;
      margin: 0;
    }
    .mbm-palette {
      display: flex;
      flex-direction: row-reverse;
      column-gap: 0.8rem;
    }
  }
  .bottom-product-details {
    row-gap: 3.2rem;
    border-top: 1px solid #e4e4e4;
    padding-top: 3.2rem;
  }
  .wrapper-product-details {
    .react-tabs__tab-panel--selected {
      overflow-x: hidden;
      .product-details {
        overflow-x: hidden;
      }
    }
  }
}
.product-selection-wrapper {
  .sticky-tray {
    .btn-express-checkout {
      .button--secondary {
        display: block;
      }
    }
    .button--secondary {
      display: none;
    }
  }
  .product-selection__preorder {
    color: $black_secondary;
    font-weight: bold;
    margin-bottom: 0;
  }
  .product-selection__description {
    margin-bottom: 0;
  }

  .link-anchor {
    margin-top: 2.1rem;
  }
}
