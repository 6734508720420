.footer {
  &.checkout {
    position: relative;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    padding: ($space-unit * 10) 0;
    border-top: 0;
    &::before {
      content: '';
      background: linear-gradient(180deg, #f9f9f9 0%, #fafafa 13.89%, #ffffff 91.95%);
      height: 12rem;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
    .container:nth-of-type(1) {
      padding-top: 0;
    }
    .wrapper-footer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: $space-unit * 8;
      row-gap: 4rem;
    }
    .footer-item {
      &:nth-child(2) {
        grid-column-start: 1;
        grid-row-start: 2;
      }
      h3 {
        @extend %primary-xs-u;
        padding-bottom: ($space-unit * 2);
      }
      p {
        @extend %primary-s;
        margin-bottom: ($space-unit);
      }
      div {
        margin-top: ($space-unit);
        > a,
        button,
        .custom-link {
          @extend %primary-s;
          text-decoration: none;
          display: flex;
          align-items: center;
        }
        .chat-button {
          span {
            text-align: initial;
          }
        }
      }
      .button {
        &.chat-button {
          text-transform: none;
        }
      }
    }

    .start-chat {
      @extend %primary-s;
      display: flex;
      align-items: center;

      i {
        margin-right: $space-unit;
      }
    }
    .accepted-payments {
      @include reset-list;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;

      li {
        margin-right: ($space-unit * 2);
        margin-bottom: ($space-unit * 2);
      }

      li[class^='icon--'] > span {
        display: block;
        @extend .visually-hidden;
      }
    }
  }
}

.grecaptcha-badge {
  z-index: 2;
}
