.order-confirm {
  .login-form .form-group {
    margin-bottom: ($space-unit * 2);
  }
  .forgot-pw-error {
    margin-bottom: ($space-unit * 8);
  }
  .custom-message-thankyou {
    padding-bottom: 2.4rem;
  }
  .row {
    position: relative;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: $gradient-separator;
  }
  .text-section {
    padding: 3.2rem 0;
    &__title {
      @extend %primary-s;
    }
  }
  .text-capitalize {
    text-transform: capitalize;
  }
  .btn-create-account {
    text-decoration: none;
    display: flex;
    align-items: center;
    row-gap: 0.8rem;
    &::after {
      content: '';
      display: block;
      @include fixedSprite('arrow-right-black');
    }
  }
  .registered-in-store {
    .col-sm-6 {
      p {
        width: 90%;
      }
    }
    .row {
      justify-content: space-between;
      align-items: center;
      row-gap: 2.4rem;
      margin-bottom: 3.2rem;
    }
  }
}
.mbm-disclaimer {
  padding: 0.4rem 0 1.6rem;
}
.banner-ty-page {
  .container-icon {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    column-gap: 3.2rem;
  }
  .wrapper-icon {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0 2rem;
    row-gap: 0.8rem;
    flex: 1;
    p {
      color: white;
    }
    .icon {
      height: 2.4rem;
      width: 2.4rem;
    }
  }
  .single-banner__text-holder {
    height: 100%;
  }
  .single-banner__title {
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-bottom: 2rem;
  }
  .wrapper-link {
    justify-content: center;
    column-gap: 2rem;
    a {
      font-weight: 600;
    }
  }
}
