.product-tile {
  &.white-bg {
    .swiper-slide {
      &::before {
        background-color: transparent;
      }
    }
    .swiper-pagination {
      .swiper-pagination-bullet {
        background: $black;
        height: 0.1rem;
        &-active {
          height: 0.2rem;
        }
      }
    }

    .selector-color-link {
      .selector__menu {
        &__item {
          &__label {
            color: $black;
          }
          .swatch {
            outline: none;
            border: 1px solid #737373;

            .stroke {
              width: 1.414rem;
              border-radius: 1px;
            }
          }
        }
      }
    }
    .product-tile-carousel__button {
      &.button--arrow-left,
      &.button--arrow-right {
        &::after {
          filter: invert(1);
        }
      }
    }
  }
}

.product-container {
  &.white-bg {
    .product-hero-gallery,
    .product-col--gallery {
      .swiper-slide,
      .product-gallery-vertical__image {
        &::before {
          background: transparent;
        }
        .product-video-pause {
          filter: invert(1);
          bottom: 1.1rem;
        }
      }
      .swiper-pagination {
        .swiper-pagination-bullet {
          background: $black;
          height: 0.1rem;
          &-active {
            background: $black;
            height: 0.2rem;
          }
        }
      }
      .button--arrow-left,
      .button--arrow-right {
        filter: invert(1);
      }
    }
  }
}

.wishlist__single-product {
  $self: &;
  &.white-bg {
    .wishlist__share-close {
      .wishlist__single-product__remove-btn {
        .icon-btn__icon {
          @include fixedSprite('close');
        }
      }
      .wishlist__single-product__share-btn {
        filter: invert(1);
      }
    }
    .button--arrow-left,
    .button--arrow-right {
      filter: invert(1);
    }
    .swiper-pagination {
      .swiper-pagination-bullet {
        background: rgba(0, 0, 0, 0.35);
        height: 0.1rem;
        &-active {
          background: rgba(0, 0, 0);
          height: 0.2rem;
        }
      }
    }
  }
  & &__product-info.always-white {
    background: $white;
    color: $black;

    &__container {
      .cta--edit {
        color: $black;
        &::after {
          filter: invert(0);
        }
      }
    }

    .product-labels {
      span {
        border: 1px solid $grey_background;
        color: #737373;
        padding: 0.1rem 0.3rem;
      }
    }

    .selector-size {
      border-color: #e4e4e4;
      .selector__button {
        color: $black;
        &::after {
          filter: invert(0);
        }
      }
      .selector__button__value {
        color: $black;
        &--outofstock {
          color: $black_inactive;
        }
        &::before {
          background-color: $black;
        }
      }
      .selector__menu {
        .selector__menu__item__label {
          color: $black_inactive;
        }
        .selector-wrapper {
          .selector__item__radio {
            color: $black;
            &.is-disabled {
              color: $black_inactive;
            }
            &.selected {
              .selector__menu__item__label {
                &::after {
                  background-color: $black;
                }
              }
            }
            .selector__menu__item__label {
              &::after {
                background-color: $black;
              }
            }
            &::after {
              background-color: $black;
            }
          }
        }
      }
    }
  }
}

.reserveinboutique {
  &.white-bg {
    .product-reserve__main {
      background-color: #e4e4e4;
      .dialog__title,
      .product-reserve__main__name {
        color: $black;
      }
      .product-reserve__main__wrapper {
        .bottom-divider {
          border-color: #00000059;
        }
      }
      .product-reserve-review {
        color: $black;
      }

      .product-image {
        background-color: $grey_background;
      }
    }
    .selector__menu {
      .selector__menu__item {
        color: $black;
      }
    }
  }
}

.product-notify {
  &__main {
    &.white-bg {
      background-color: $grey_background;
      .product-image {
        background-color: $grey_background;
      }
    }
  }
}
