.mbm-header .mbm-logo {
  height: 3.2rem;
}
.mbm-title {
  font-size: 2rem;
  line-height: 2.4rem;
  margin-top: 2.4rem;
  margin-bottom: 0.8rem;
}
.mbm-box {
  margin-bottom: 5.6rem;
}
