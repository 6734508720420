@each $font-type, $font-data in $moncler-gothic-desktop {
  %primary-#{$font-type} {
    font-family: nth($font-data, 1), Arial, sans-serif;
    font-size: nth($font-data, 2);
    line-height: nth($font-data, 3);
    letter-spacing: nth($font-data, 4);
    @if str-index($font-type, '-u') {
      text-transform: uppercase;
    }
    @if map-has-key($moncler-gothic-mobile, $font-type) {
      $mobile-font-data: map-get($moncler-gothic-mobile, $font-type);
      @include breakpoint(sm) {
        font-family: nth($mobile-font-data, 1), Arial, sans-serif;
        font-size: nth($mobile-font-data, 2);
        line-height: nth($mobile-font-data, 3);
        letter-spacing: nth($mobile-font-data, 4);
      }
    }
  }
}

@each $font-type, $font-data in $genius-desktop {
  %secondary-#{$font-type} {
    font-family: BlockGothicRR, Impact, sans-serif;
    font-weight: bold;
    font-size: nth($font-data, 1);
    line-height: nth($font-data, 2);
    letter-spacing: nth($font-data, 3);
    text-transform: uppercase;
    @if map-has-key($genius-mobile, $font-type) {
      $mobile-font-data: map-get($genius-mobile, $font-type);
      @include breakpoint(sm) {
        font-size: nth($mobile-font-data, 1);
        line-height: nth($mobile-font-data, 2);
        letter-spacing: nth($mobile-font-data, 3);
      }
    }
  }
}
