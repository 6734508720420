.hero-banner-slim {
  position: relative;
  overflow: hidden;
  width: 100%;
  &__inner-container {
    height: 100%;
    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-top: 360px;
    }
    > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__text-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 4;
    text-align: center;
  }
  &__headline,
  &__subtitle {
    text-transform: uppercase;
    display: block;
  }
  &__logo,
  &__headline,
  &__subtitle,
  &__description {
    margin: ($space-unit * 3) auto;
  }
}
