.myaccount-wrapper {
  .wishlist__share-button {
    right: 0;
  }
}

.list__empty-message {
  width: 80%;
  max-width: 430px;
  .button {
    max-width: 343px;
  }
}
