.single-designer__name {
  font-size: 13.5rem;
  line-height: 10.4rem;
  padding-left: 7rem;
}
.genius-designers__designers-row {
  height: 10.4rem;
}
.single-designer__name img {
  top: $space-unit;
  height: 88%;
}
.genius-designers {
  padding: 5.5rem 0 ($space-unit * 20);
}

.genius-designers {
  &.dragging {
    .single-designer__caption {
      opacity: 1;
    }
    .single-designer__caption-overlay {
      opacity: 0;
    }
  }
}

.genius-designer__controls {
  &--bottom {
    margin: 2.7rem 0 3.3rem;
  }
}

.genius-designer__title {
  display: block;
}

.designer-tile {
  &__name {
    left: 2rem;
    bottom: 2rem;
  }
}
