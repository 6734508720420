// SIZE SELECTOR USED IN: cart
.selector-size-inline {
  position: relative;
  // height: 1.6rem;
  color: $black;
  @include animate(0.5, height);

  .selector__menu {
    .selector-wrapper,
    ul {
      @include reset-list;
      display: flex;

      .selector__menu__item:first-child,
      li:first-child {
        margin-left: $space-unit;
      }
    }
  }

  .selector__button {
    position: relative;
    padding-right: $space-unit * 6;
    overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    text-align: left;
    @extend %primary-xs;
    // alert: do not change, it may affect focus management
    // @include animate(0.5, 'opacity');

    &__value {
      .bag-product & {
        display: inline-block;
        position: relative;
        margin-left: 1.1rem;
      }
      &--outofstock {
        color: $black_secondary;
        @include strokeThrough;
      }
    }

    &::after {
      content: '';
      right: 0;
      @include fixedSprite('chevron-right');
      position: absolute;
      top: -0.1rem;
    }

    &:disabled {
      color: $black;
      &::after {
        display: none;
      }
    }

    &.expanded {
      @extend .visually-hidden;

      + .selector__menu {
        opacity: 1;
        visibility: visible;
      }
    }

    &__value--outofstock {
      color: $black_secondary;
    }

    &__info {
      margin-left: 1.2rem;
      color: $black_secondary;
    }
  }

  .selector__menu {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    align-items: flex-start;
    outline: none;
    // alert: do not change, it may affect focus management
    // @include animate(0.5, 'opacity');

    .label {
      @extend %primary-xs;
      margin: 0 2.4rem 0 0;
    }

    ul {
      @include reset-list;
      display: flex;
      flex-wrap: wrap;
      // display: grid;
      // bottom: initial;
      // grid-template-columns: repeat($selector-size-inline-cols-desktop, 1fr);
      row-gap: $selector-size-inline-rowgap-desktop;
      flex: 1;
    }

    &__item {
      @extend %primary-xs;
      margin: 0 2.4rem 0 0;
      position: relative;

      &.selected {
        text-decoration: underline;
        text-underline-offset: 0.5rem;
      }

      &.is-disabled {
        color: $black_secondary;
        cursor: default;
        @include strokeThrough($parent: '.bag-product &');
      }
      &:not(.is-disabled) {
        &:hover {
          // ONE-23809
          text-decoration: underline;
        }
      }
      &:not(.selector__item__radio) {
        @include touchtarget-area();

        &:not(.is-disabled) {
          &:focus-within,
          &:focus {
            @include touchtarget-focus();
          }
        }
      }
    }
  }
}
