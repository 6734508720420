.modal {
  &.shop-the-look-modal {
    .container-shop-the-look {
      width: 100%;
      max-width: 102.4rem;
    }
  }
}
.carousel-button__shop-the-look {
  padding: 0 5.6rem;
}
