.tooltip {
  position: relative;
  padding: $space-unit;
  display: flex;

  button[aria-expanded='true'] {
    + .tooltip__box {
      opacity: 1;
      visibility: visible;
    }
  }
  .tooltip__box {
    position: absolute;
    @extend %primary-xxs;
    left: 50%;
    transform: translateX(-50%);
    bottom: calc(100% + (#{$space-unit} * 2));
    padding: ($space-unit * 4);
    opacity: 0;
    visibility: hidden;
    background-color: $grey_input;
    white-space: pre-line;
    min-width: 25rem;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
    @include animate(0.15);
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 100%);
      width: 0;
      height: 0;
      border-left: ($space-unit * 2) solid transparent;
      border-right: ($space-unit * 2) solid transparent;
      border-top: ($space-unit * 2) solid $grey_input;
    }
  }
}
