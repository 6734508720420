.order {
  .total-row {
    .text-section {
      padding-bottom: 1.2rem;
    }
  }
}

.text-section {
  &__description {
    width: 90%;
  }
}
