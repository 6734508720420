.editorial-text {
  &--4x1 {
    grid-column: span 2;
  }
  &--quotes {
    .editorial-text {
      &__description {
        font-size: 3.2rem;
        line-height: 3.8rem;
      }
    }
  }
}
