.size-selection {
  .fitting-caption {
    background-color: $grey_disabled;
    padding: 0.8rem 1.6rem;
    .fitting {
      padding: 0;
    }
  }
  &.size-selection-combobox {
    top: 50%;
  }
}
#size-selector-combobox-container {
  position: relative;

  .size-selection-combobox.size-selection {
    --selector-item-height: 3.4rem;
    position: absolute;
    top: auto;
    // bottom: 100%;
    left: 0;
    right: 0;
    border: 1px solid $grey_line;
    background-color: $white;
    #size-selector-container {
      max-height: calc((var(--selector-item-height) * 5) - 1px);
      overflow-y: auto;
      @include scrollbar;
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      .size-selector-line {
        margin: 0 1.6rem;
        .selector-size-item__label {
          font-size: 1.2rem;
        }
      }
    }
    .modal__header {
      padding: 0;
    }
  }
}
