.countryselector-modal {
  &__description {
    @extend %primary-xs;
    padding-bottom: 1.4rem;
  }
}

.countryselector {
  a {
    text-decoration: underline;
  }
}

.modal {
  &--country-selector.modal {
    min-width: 45.6rem;
    width: 45.6rem;
    height: 24.2rem;
    padding: 0;
    &.full-screen {
      width: 91.6rem;
      height: 65.3rem;
      .modal {
        &__header {
          padding-bottom: 2.4rem;
          @include border('bottom');
        }
        &__body {
          padding: 4rem 0;
          .row {
            align-items: flex-start;
          }
          .continent {
            padding: 0 3.2rem;
          }
          .accordion__panel_disabled {
            ol li > a {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .modal {
      &__header {
        padding: 3.2rem 3.2rem 0.8rem;

        .dialog__title {
          margin: 0;
          padding: 0;
        }
      }

      &__body {
        // padding: 0 3.2rem;
        .countryselector-modal {
          &__description {
            padding-left: 3.2rem;
            padding-right: 3.2rem;
            padding-bottom: 2.4rem;
            @include border('bottom');
            &:last-child {
              padding: 2.4rem 3.2rem 1.2rem 3.2rem;
              border-bottom: none;
            }
          }
        }
        .row {
          justify-content: center;
          align-items: center;
          margin-right: 0;
          margin-left: 0;
          .col-6 {
            padding-left: 3.2rem;
            &:last-child {
              padding-left: 0;
              padding-right: 3.2rem;
            }
          }
          button {
            min-height: 4.4rem;
          }
        }
        .continent {
          padding: 1.6rem 3.2rem;
        }
      }
    }
  }
}
