.component_product_intro {
  &__parallax {
    height: calc(100% + #{$contextual-navigation});
    transform: translateY(-#{$contextual-navigation});
  }
  &__content {
    padding-top: 7rem;
    padding-bottom: 2.4rem;
    .collection {
      padding-bottom: 3.2rem;
      p {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
    h1 {
      font-size: 4.2rem;
    }
  }
}
