.product-container {
  position: relative;
  .a11y-action-bar {
    position: absolute;
    text-align: center;
    gap: 1rem;
    width: 100%;
    justify-content: center;
    top: 6rem;
    @include z('stickybar-m');
    body.has-ticker & {
      top: 9.2rem;
    }
  }
  &:first-child {
    transition: padding-top 0.2s ease-out;
    .product-image--animate {
      &::after {
        background: $gradient-separator;
      }
    }
  }
  padding-top: 0;
  display: flex;
  flex-wrap: wrap;
  .selector-color-expanded {
    border-top: 0;
  }
  .product-col {
    flex: 1 0 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }
  .product-selection-wrapper {
    flex-grow: 1;
  }
  .discover-more {
    position: relative;
    top: initial;
    padding-top: 0;
    padding-bottom: 4rem;
    .button {
      margin-top: 3.6rem;
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
  .product-video-wrapper {
    .wrapper-product-video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      height: 16rem;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
      z-index: 1;
    }
  }
  .mbm-list {
    padding-left: 1.6rem;
    list-style-type: disc;
    padding-right: 0.4rem;
  }
  .mbm-palette {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 2px;
    span {
      text-decoration: underline;
    }
  }
  .palette-available {
    position: absolute;
    top: 3.2rem;
    right: 3.2rem;
    z-index: 2;
    pointer-events: none;
    color: $white;
    text-transform: uppercase;
  }
  .palette-label {
    position: absolute;
    top: 2.8rem;
    left: 2.8rem;
    z-index: 1;
    pointer-events: none;
    color: $white;
    text-transform: uppercase;
    font-size: 1.6rem;
    line-height: 2rem;
    span {
      display: block;
      margin-bottom: $space-unit * 2;
    }
  }
  .toggle-vto {
    position: absolute;
    bottom: 7rem;
    left: 50%;
    z-index: 3;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    text-transform: uppercase;
    row-gap: 1.2rem;
  }
}

body.has-ticker {
  .product-container:first-child {
    padding-top: $ticker-bar-m;
  }
}

.spott-iframe {
  width: 100%;
  height: 0;
  padding-bottom: 150%;
  position: relative;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
.spott-image-plugins-container {
  z-index: 0;
}
.spott-embed {
  position: relative;
  height: 100%;
}
