.product-tile {
  // @include animate;
  // OE-17878
  // .product-image {
  //   width: calc(100% + 1px);
  // }
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  &__swatch-change {
    .selector-color-link {
      .selector__menu__item {
        &:focus {
          .swatch {
            @include touchtarget-focus($outline-color);
          }
        }
      }
      .selector__menu.inverted {
        .swatch {
          border: 1px solid rgba($black, 0.5);
        }
      }
    }
  }
  &__image-wrapper {
    position: relative;
    & > img {
      transform: none;
    }
    .product-tile--carousel {
      overflow: hidden;
    }
  }
  &--carousel {
    @extend .product-tile;
    &.product-tile--simplified.product-tile {
      &:not(.quickshopactive) .product-tile__info {
        padding-right: 0;
        padding-left: 0;
        padding-top: 1.6rem;
      }
    }
  }
  &__with-carousel {
    .swiper-pagination-bullets {
      --bullets-bottom-offset: 1.8rem;
      position: absolute;
      bottom: var(--bullets-bottom-offset);
      z-index: 3;
      width: 100%;
      text-align: center;
      // &:before {
      //   content: '';
      //   width: 100%;
      //   height: 12rem;
      //   position: absolute;
      //   bottom: calc(var(--bullets-bottom-offset) * -1);
      //   left: 0;
      //   background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) -29.66%, rgba(0, 0, 0, 0) 66.95%);
      //   pointer-events: none;
      // }
      .swiper-pagination-bullet {
        width: 2.8rem;
        height: 0.1rem;
        background-color: $white;

        display: inline-flex;
        margin: 0 0.4rem;
        position: relative;
        &:only-child {
          display: none;
        }
        &:before {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 4.4rem;
        }
        &.swiper-pagination-bullet-active {
          height: 0.2rem;
        }
      }
    }
    .product-tile__card .product-labels {
      bottom: 4.4rem;
    }
    .product-tile-carousel__button {
      @include animate;
      opacity: 0;
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      width: 4.4rem;
      height: 4.4rem;
      display: none;
      &:focus-visible {
        outline: 1px solid $white;
        border: 1px solid $black;
      }
      &.button--arrow-left {
        left: 0;
        padding-left: initial;
        &::after {
          transform: translateY(-50%) rotate(180deg);
          @include fixedSprite('arrow-right-white');
          left: 0;
          width: 2.8rem;
          height: 2.8rem;
        }
      }
      &.button--arrow-right {
        right: 0;
        padding-right: initial;

        &:after {
          right: 0;
          @include fixedSprite('arrow-right-white');
          width: 2.8rem;
          height: 2.8rem;
        }
      }
    }
  }
  &__card {
    position: relative;
    .collection-in-tile {
      display: block;
      position: absolute;
      width: auto;
      top: ($space-unit * 4);
      left: ($space-unit * 4);
      .icon-mbm {
        &::after {
          content: '';
          @include fixedSprite('mbm-logo');
        }
      }
      .icon-mbm-black {
        &::after {
          content: '';
          @include fixedSprite('mbm-logo-black');
        }
      }
      @include z;
      span[class^='icon--'] {
        height: 3rem;
        width: 2rem;
      }
      &:focus {
        outline-color: $white;
        outline-offset: 2px;
      }
      .icon--mbm-white {
        @include fixedSprite('mbm-white');
        width: 8rem !important; // temporaneo, da rivedere secondo grafica
      }
    }
    .product-image {
      background-color: none;
      animation: skeleton 0.7s ease-out infinite alternate;
    }
  }
  &__link {
    display: block;
    transition: none !important;
    opacity: 1 !important;
    text-decoration: initial;
    // [data-whatintent='keyboard'] & {
    //   &:focus {
    //     &::before {
    //       content: '';
    //       position: absolute;
    //       top: 2px;
    //       left: 2px;
    //       right: 2px;
    //       bottom: 2px;
    //       border: 1px solid $white;
    //       outline: 1px solid $black;
    //       z-index: 2;
    //     }
    //   }
    // }
  }

  &__image {
    height: 100%;
    left: 0;
    top: 0;
    object-fit: unset;
    right: initial;
    transform-origin: top center;
    @include animate;
  }
  &__color-link {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-left: ($space-unit * 4);
    padding-right: ($space-unit * 4);
    z-index: 3;
  }
  .selector-color-link {
    .selector__menu {
      &__item {
        [data-whatintent='keyboard'] & {
          &:focus-within {
            outline: none;
            .swatch {
              outline: 1px solid $black;
              outline-offset: 4px;
            }
          }
        }
        .swatch {
          margin-right: $swatch-tile-margin-desktop;
          width: $swatch-tile-size-desktop;
          height: $swatch-tile-size-desktop;
          cursor: pointer;
        }

        &--link {
          [data-whatintent='keyboard'] & {
            &:focus-within {
              .swatch {
                outline: none;
              }
            }
          }
        }
      }
    }
  }
  &__quick-buy-panel {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    padding-left: ($space-unit * 4);
    padding-right: ($space-unit * 4);
    z-index: 3;
    background-color: black;
  }
  $product-tile-info-padding-bottom: $space-unit * 6;
  &__info {
    flex-grow: 1;
    position: relative;
    padding: ($space-unit * 4) 0 $product-tile-info-padding-bottom;
    .shop-now {
      margin-top: $space-unit * 4;
    }
    &.lookinfo {
      background: $white;
      @include animate(0.5, margin);
      bottom: 0;
      width: 100%;
      > .button.button--secondary {
        position: relative;
        background-color: $white;
      }

      .selector__menu:hover {
        .selector__menu--size--radio-btn {
          .selector__menu__item {
            .selector__menu__item__label {
              display: inline-block;
            }
          }
        }
      }
    }
    .icon--close-white {
      background-color: $black;
      position: absolute;
      bottom: 100%;
      right: 0;
      z-index: 3;
      &:focus {
        outline-color: $outline-color-inverted;
      }
    }
  }
  &__name {
    @extend %primary-s;
    display: block;
    margin-bottom: $space-unit;
    &.badge-card {
      padding-right: 2.6rem;
    }
  }
  &:not(.in-plp) &__name {
    @include text-shorten;
  }
  .wishlist-icon {
    width: auto;
    height: auto;
    position: absolute;
    top: 16px;
    right: 16px;
    border: none;
    z-index: 1;
  }
  &__price {
    @extend %primary-xs;
    display: inline-block;
    letter-spacing: 0.015em;
  }
  &__quick-buy {
    position: absolute;
    display: inline-block;
    right: 1.6rem;
    bottom: 3.4rem;
    @include z;
    .button {
      // temporary selector to override specific media queries
      border: none;
      // border: $space-line solid transparent;
      box-shadow: none;
      // margin-right: -#{$space-unit};
      // margin-bottom: -#{$space-unit};
      // align-items: flex-end;
      display: inline-flex;
      margin: 0;
      padding: 0;
      min-height: auto;
      width: 100%;
      text-align: right;
      height: auto;
      outline-offset: $space-unit;
      // &[class] {
      .members-only-badge {
        position: relative;
        top: 0.6rem;
        span[class^='icon--'] {
          display: none;
        }
      }
      span {
        @extend %primary-xxs-u;
      }
      // }
      &:hover {
        opacity: 0.7;
        // border-color: black;
      }
      // .members-only-badge {
      //   align-items: flex-end;
      //   position: relative;
      //   top: 0.2rem;
      // }
    }
    .mbm-button {
      display: flex;
      align-items: flex-end;
      // span {
      //   @extend %primary-xxs;
      // }
    }
  }
  &__members-unlock {
    position: absolute;
    right: ($space-unit * 4);
    top: 1.4rem;
    opacity: 55%;
    .label {
      display: none;
    }
  }
  .button {
    transition: opacity 166ms $easeOutQuart, color 0.5s $easeOutQuart;
  }

  .product-labels {
    position: absolute;
    left: 1.6rem;
    right: 1.6rem;
    bottom: 1.2rem;
    z-index: 1;
    display: flex;
    align-items: center;
    column-gap: 1.2rem;
    row-gap: 0.8rem;
    flex-wrap: wrap;
    .product-label {
      @extend %primary-xxs-u;
      background-color: $white;
      color: $black;
      padding: 0.5rem 0.6rem;
      // text-transform: uppercase;
    }
    .product-label--inverted {
      @extend %primary-xxs-u;
      border: 1px solid $white;
      color: $white;
      padding: 0.4rem 0.6rem;
      position: relative;
      &.vto-icon {
        padding-left: 2.8rem;
      }
    }
  }

  &.is-look {
    @include animate;
    .look-toggle {
      position: relative;
      display: block;
      width: 100%;
      &:focus,
      &:hover {
        .single-slide__hover {
          opacity: 1;
          transform: translateY(0);
        }
      }
      &:focus {
        outline: none;
        &::before {
          content: '';
          @include z;
          border: $space-line solid $black;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          box-sizing: border-box;
        }
      }
      .product-tile__image-wrapper {
        &::before {
          content: '';
          position: absolute;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.65) 1.48%, rgba(0, 0, 0, 0.1) 65.72%, rgba(0, 0, 0, 0) 84.39%);
          width: 100%;
          height: 10.4rem;
          left: 0;
          bottom: 0;
          z-index: 1;
          opacity: 0.65;
        }
      }
      .single-slide__labels {
        display: flex;
        align-items: center;
        justify-content: space-between;
        right: 1.6rem;
        margin: 0;
      }
      .single-slide__look {
        text-transform: capitalize;
      }
      .single-slide__coming-soon {
        text-transform: uppercase;
      }
      // &.no-looks {
      //   cursor: initial;
      // }
    }
    .no-cursor {
      cursor: default;
    }
    .look-number__wrapper {
      position: absolute;
      display: flex;
      justify-content: space-between;
      padding: ($space-unit * 4);
      bottom: 0;
      left: 0;
      width: 100%;
      pointer-events: none;
      color: $white;
      z-index: 2;
    }
    .look-number {
      @extend %primary-xxs;
    }
  }
  &.quickshopactive {
    background-color: $black;
    color: $white;
    .product-labels {
      display: none;
    }
    .button {
      color: $white;
    }
    .selector--color {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: $space-line;
        background-color: $white_inactive;
      }
    }
    .product-tag-wrapper {
      color: $white_secondary;
    }
  }

  &:focus {
    outline-color: $outline-color-inverted;
  }
  &.in-look {
    position: relative;
    .product-tile__info {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .product-tag-wrapper {
    display: block;
    color: $black_secondary;
    padding-bottom: 4px;
    width: 100%;
  }
  .product-tag {
    @extend %primary-xxs-u;
    &:not(:last-child):after {
      content: '|';
      display: inline-block;
      padding: 0 0.4rem;
    }
  }
}

@keyframes loading-pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

// Simplified tile
.product-tile--simplified.product-tile {
  &.white-bg .product-tile__info {
    color: $black;
    background-color: $white;
  }
  .product-labels {
    position: static;
    .product-label {
      text-transform: none;
      color: $black_secondary;
      padding-top: 2.1rem;
      &:first-of-type {
        padding-left: 0;
      }
    }
  }
  .product-tile__name {
    height: 3.2rem;
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin-bottom: 0.6rem;
    padding-right: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  .product-tile__info {
    padding: 1.6rem 0.8rem 3.2rem;
  }
  .product-tile__price {
    color: $black_secondary;
  }
  .selector-color-link {
    height: auto;
    padding-top: 1.3rem;
    padding-left: 0;
    .selector__menu__item .swatch {
      margin-right: 1.2rem;
      height: 1.2rem;
      width: 1.2rem;
      &::before {
        height: 1.2rem;
        width: 1.2rem;
      }
    }
    .selector__menu__item {
      .swatch {
        margin-right: 1.2rem;
        height: 1.2rem;
        width: 1.2rem;
        &::before {
          height: 2.4rem;
          width: 2.4rem;
        }
      }
      &.selected {
        .swatch {
          position: relative;
          &:after {
            content: '';
            width: 100%;
            height: 1px;
            background-color: $black;
            position: absolute;
            bottom: -0.5rem;
            left: 0;
          }
        }
      }
    }
    .selector__more-colors {
      color: $black;
    }
  }
  .swiper-button-disabled {
    opacity: 0.3;
  }
  .swiper-pagination-horizontal {
    --bullets-bottom-offset: -0.3rem;
    --bullets-number: 3;
    --bullets-active-offset: 0;
    background-color: #cccccc;
    display: flex;
    height: 0.1rem;
    left: 0.3rem;
    width: 100%;
    transform: translateX(-0.3rem);
    &::before {
      content: '';
      width: calc(100% / var(--bullets-number));
      height: 0.1rem;
      display: block;
      background-color: $black;
      position: absolute;
      z-index: 1;
      left: var(--bullets-active-offset);
      transition: left 0.3s ease-in-out;
    }
    .swiper-pagination-bullet {
      width: calc(100% / var(--bullets-number));
      background-color: transparent;
    }
  }
  .product-tile__quick-buy,
  .product-tag-wrapper {
    display: none;
  }
}
