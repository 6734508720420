#page-client-service-redesign {
  --stickyHeaderHeight: $header-height-desktop;
  body.has-ticker {
    --stickyHeaderHeight: $header-height-desktop + $ticker-bar;
  }
}

.client-service-content {
  .image-box {
    &:focus {
      outline: none;
      .image-box__title {
        @include focus-underline;
      }
    }

    &.big {
      .image-box__title {
        font-size: 3.2rem;
        line-height: 3.8rem;
      }
    }
  }
}

.collection-intro-sticky {
  &__toggle-icon {
    display: none;
  }
  &__links {
    li {
      margin-right: 2.5rem;
    }
  }
}

.cs-faq-module {
  .myaccount-content {
    .back-btn-wrapper {
      margin-left: 8.3333333%;
    }
  }
}
