.modal__header {
  position: sticky;
  top: 0;
  right: 0;
  background-color: $white;
  justify-content: center;
  padding-top: 0;
  @include z;
  /*&::after {
    content: '';
    position: absolute;
    height: $space-line;
    width: 100vw;
    left: 50%;
    background-color: $grey_line;
    transform: translateX(-50%);
    bottom: 0;
  }*/
  .dialog__title {
    padding: 0;
    height: 6rem;
    display: flex;
    align-items: center;
  }
  .dialog__description {
    margin-top: ($space-unit * 6);
    margin-bottom: ($space-unit * 4);
  }
}
// .modal__close {
//   position: fixed;
// }

// .modal__body {
//   .accordion__group {
//     padding-top: ($space-unit * 7);
//   }
// }
.modal__divisor {
  display: none;
}

.modal {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transform: none;
  &.login-modal {
    .dialog__title {
      max-width: 90%;
    }
  }
  &.taxrefund {
    height: fit-content;
    bottom: 0;
    top: auto;
    .modal__divisor {
      display: block;
    }
    .modal__header {
      .dialog__title {
        height: fit-content;
        padding: 2.2rem 0 1rem;
      }
      .dialog__description {
        margin-top: 0;
      }
    }
    .modal__close {
      position: absolute;
    }
  }

  &.modal--fixed-small {
    height: auto;
    top: initial;
    opacity: 1;
    transform: translateY(100%);
    transition-property: transform;
    transition-delay: 0s;
    padding-bottom: 3.6rem;
    .wishlist-share__modal-header {
      padding: 2rem 0;
    }
    &.ReactModal__Content--after-open {
      transform: translateY(0);
    }
    &.mobile--full-height {
      height: 100%;
      display: flex;
      flex-direction: column;
      .modal__body {
        flex: 1;
        &:has(.buttons-section) {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
  }
  &.modal--bottom-flexible-height {
    top: auto;
    height: auto;
  }
  &.modal--bottom-small {
    height: 45%;
    top: 55%;

    &.delete-account {
      height: auto;
      bottom: 0;
      top: auto;
    }
  }
  &.suggested-country-modal {
    padding: 0;
    .modal__header {
      border-bottom: 1px solid $grey_line;
      padding: 0 1.6rem;
    }
    .modal__body {
      padding: 2.4rem 1.6rem 3rem 1.6rem;
    }
  }
}

.ReactModal__Content {
  transition-property: opacity;
  transition-delay: 150ms;
  opacity: 0;
  &--after-open {
    opacity: 1;
  }
  &--before-close {
    opacity: 0;
  }
}
.modal-clothing-doubleoptin {
  height: fit-content;
  bottom: 0;
  top: auto;
  .modal__close {
    position: absolute;
  }
}
.overlay-modal:not(.mobile-visible):not(.overlay-visible) {
  background-color: $white;
  transition-duration: 0s;
}

.taxmodal {
  .modal__header {
    .dialog__title {
      padding-bottom: 1rem;
    }
  }
}

.modal {
  &.rpy {
    .button-row {
      flex-direction: column-reverse;
      gap: 15px;

      div {
        width: 100%;
        max-width: 100%;
        text-align: center;
        min-height: 44px;
        display: flex;
        justify-content: center;

        .button {
          width: 100%;
        }
      }
    }
  }
  &.black {
    .product-notify__detail form + p {
      padding-bottom: 2rem;
    }
  }
}

.ReactModal__Overlay {
  &.drawers-overlay-modal {
    transform: translateY(4rem);
    @include animate(0.4, 'all');
    &.overlay-modal:not(.mobile-visible) {
      transition-duration: 0.4s;
      &.backdrop--bg-transparent {
        background-color: $black_secondary;
      }
    }
  }

  &--after-open {
    &.drawers-overlay-modal {
      transform: translateY(0);
    }
  }
}
