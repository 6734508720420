.collection-intro {
  padding-bottom: ($space-unit * 6);
  &__content {
    padding: 4rem 1.6rem;
    .with-image & {
      margin-top: -12rem;
    }
  }
  &__links {
    .collections-intro__tab {
      .collection-intro__link-wrapper {
        .with-image & {
          width: calc(100vw - 7.6rem);
        }
      }
    }
  }
}

.collection-intro-simplified {
  padding-bottom: 0;
}
