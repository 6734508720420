.membership-module {
  &__frame {
    padding: 3.2rem;
  }
  &__container {
    padding: 7rem 11.5rem;
  }
  &__text-col {
    padding-left: 12rem;
  }
}
