.component_product_range_carousel {
  $viewportHeight: calc((var(--viewport-height) - #{$contextual-navigation-m}) * 2);
  height: $viewportHeight;
  padding-top: 3.2rem;
  padding-bottom: calc(var(--viewport-height) - #{$contextual-navigation-m});
  // display: flex;
  // align-items: center;
  // margin-bottom: calc((var(--viewport-height) - #{$contextual-navigation-m}) / 2);

  // OE-16934 - Optimize Editorial Module Borders/Paddings
  &.pd-no__padding {
    padding-block:0 !important;
  }
  .page-template-flp &,
  .page-template-homepage & {
    height: auto;
    padding-bottom: 3.2rem;
  }
  &__title {
    @extend %primary-xxxl;
    text-transform: uppercase;
    display: inline-block;
  }
  &__pagination {
    @include reset-list;
    display: flex;
    list-style: none;
    justify-content: center;
    padding-top: 1.6rem;
    > li:not(:last-child) {
      margin-right: 1.4rem;
    }
    .bullet {
      margin: 0 0.1rem;
      padding: 0.6rem;
      border-radius: 50%;
      border: 1px solid transparent;
      &.active {
        border: 1px solid $black;
      }
      span {
        display: block;
        width: 15px;
        height: 15px;
        border-radius: 50%;
      }
    }
  }
  .product-tile__image-wrapper {
    padding-top: 2.4rem;
    padding-bottom: 1.6rem;
    img {
      max-height: 46vh;
      margin: 0 auto;
    }
  }
  .product-tile__info {
    padding: 0;
  }
  // edge case per gestire risoluzioni mobile con altezza maggiore di 700px
  @media only screen and (max-width: get($grid-breakpoints, md)) and (min-height: 700px) {
    padding-top: 4rem;
    .component_product_range_carousel__pagination {
      padding-top: 2.4rem;
    }
    .product-tile__image-wrapper {
      padding-top: 3.2rem;
      padding-bottom: 2.4rem;
    }
  }
  // edge case iphone 5/SE
  @media only screen and (max-width: get($grid-breakpoints, sm)) and (max-height: 650px) {
    .product-tile__image-wrapper {
      img {
        max-height: 40vh;
      }
    }
  }
}
