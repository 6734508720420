/* checkout forgot password flow */

.checkoutpage {
  .checkout-forgot-password-form {
    .btn-send {
      margin-bottom: ($space-unit * 3);
    }
    .actions-wrapper {
      flex-wrap: wrap-reverse;
    }
  }
}
