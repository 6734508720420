.client-service {
  .accordion-step {
    &:focus-visible {
      outline: none;
    }
    .step__header {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      @include border('top');
      min-height: ($space-unit * 12);
      padding: ($space-unit * 4) 0;
      width: 100%;
      flex-wrap: wrap;
      @include animate;
      color: $black_secondary;

      &.is-active {
        color: $black;
      }

      .step__header__content {
        display: flex;
        width: 100%;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        height: 100%;
      }

      &__title {
        @extend %primary-s-u;
        color: inherit;
      }
      &__description {
        @extend %primary-xs;
        margin-top: ($space-unit * 4);
        .spaced {
          margin-right: $space-unit * 12; // totally randomic space in checkout shipping description from design
        }
      }

      &__edit {
        color: $black;
      }
    }
  }
}
