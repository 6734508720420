.filters {
  @include reset-list;
  .filters__menu__menuitem {
    position: relative;
    // width: 100%;

    &.disabled {
      opacity: 0.55;
    }

    label {
      cursor: pointer;
      position: relative;
      @include text-shorten;
    }

    input {
      @extend .visually-hidden;

      &:disabled {
        + label {
          cursor: initial;
        }
      }

      &:focus + label {
        @include focus-underline;
        overflow: initial;
      }

      & + label {
        display: flex;
        align-items: center;
        &::before {
          content: '';
          position: relative;
          // top: 50%;
          // transform: translateY(-50%);
          // margin-top: -0.2rem;
          margin-right: 0.9rem;
          // background: white;
          border: 1px solid #737373;
          @include animate;
          width: 1.3rem;
          height: 1.3rem;
          min-width: 1.3rem;
        }
      }

      &:checked + label {
        font-weight: bold;
        &::before {
          content: '';
          // position: absolute;
          // top: 50%;
          // transform: translateY(-50%);
          // margin-top: -0.2rem;
          // margin-left: $space-unit;
          border-color: black;
          // @include animate;
          @include fixedSprite('checkmark');
          width: 1.3rem;
          height: 1.3rem;
        }
      }
    }

    a {
      text-decoration: none;
    }

    .swatch {
      display: inline-block;
      margin-right: ($space-unit * 2);
      width: $space-unit * 2;
      height: $space-unit * 2;
    }
  }
}
.filters__menuitem {
  position: relative;
  display: inline-block;
  height: $refine-options-height;
  padding: 2rem 1.6rem 2rem 0;
  margin-right: ($space-unit * 4);
  @extend %primary-xs;

  &:focus {
    @include focus-underline;
  }

  // per animare prima la freccietta
  &.animate-arrow {
    &[aria-expanded='true'] {
      &::after {
        transform: translateY(-50%);
      }
    }
  }

  &[aria-expanded='true'] {
    &::after {
      transform: translateY(-50%) rotate(-180deg);
    }

    & + .filters__menu {
      visibility: visible;
      opacity: 1;
    }
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    @include fixedSprite('arrow-down');
    @include animate(0.3);
  }
}

.filters__menu {
  position: absolute;
  padding-top: 0.8rem;
  padding-bottom: 3.2rem;
  top: $refine-options-height;
  right: 0;
  z-index: 1;
  display: block;
  visibility: hidden;
  opacity: 0;
  text-align: left;
  transition: opacity 0.2s ease-in-out;
  ul {
    @include reset-list;
    padding-bottom: 1.4rem;
    display: grid;
    column-gap: 4.8rem;
    row-gap: 1.2rem;
    justify-content: start;
    overflow: auto;
  }
  .filters__menu__wrapper-group {
    margin-bottom: 2rem;
    width: 100%;
    overflow: auto;
    display: flex;
    column-gap: 4.8rem;
  }
  .filters__menu__group-name {
    padding-top: 0;
    text-transform: initial;
  }
  .filters__menu__group {
    position: relative;
    &:not(:first-child) {
      border-left: 1px solid $grey_line;
      padding-left: ($space-unit * 4);
    }
    ul {
      padding-bottom: 0;
    }
  }
}
