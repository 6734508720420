.curators-listing-wrapper {
  .selector-color-link {
    padding-left: 3.2rem;
    .selector__menu__item__label {
      display: none;
    }
  }
  .product-tile__link {
    padding: 0 1.6rem;
  }
  .section-look-container {
    padding-bottom: 5rem;
    .hero-img-overlay {
      position: relative;
      top: 0;
      transition: none;
    }
  }
  .hero-img {
    .container-text {
      position: relative;
      top: -8rem;
      padding-left: 5rem;
      width: 100%;
      left: 0;
      transform: translateX(0);
      .title {
        font-size: 60px;
        line-height: 56px;
      }
    }
  }
  .lock {
    .text-container {
      position: relative;
      top: -8rem;
      .title {
        font-size: 60px;
        line-height: 56px;
      }
    }
  }
}
