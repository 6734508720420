.monclernow-hero {
  &::before {
    padding-top: 105%;
  }
  &--text-before-img {
    .monclernow__img {
      &::before {
        padding-top: 105%;
      }
    }
  }
  &__text {
    bottom: 2rem;
  }
}
