.product-notify {
  flex-direction: row;
  max-width: 124.8rem;
  &__main {
    flex: 0 0 50%;
    display: flex;
    padding: 0;
    align-items: center;
    background-color: $black;
    position: sticky;
    top: -0.5px;
    max-height: 66rem;
    .selector__button,
    .selector__menu__item {
      color: $white;
    }

    p {
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: 300;
      color: $white;
    }
  }
  &__thankyou,
  &__detail {
    max-height: 66rem;
  }
  &__detail {
    flex: 0 0 50%;
    padding: 0 $space-unit * 8 $space-unit * 8 $space-unit * 8;
    // min-height: 66vh;

    .modal__product-details {
      margin-bottom: ($space-unit * 5);
    }

    form {
      .newsletter-modal {
        &__required-label {
          margin-top: 2.4rem;
        }
      }

      fieldset {
        .curator-modal & {
          margin: 1.2rem 0 0.8rem 0;
        }
        .checkbox {
          .checkbox__field {
            .form-group__label {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
  &__thankyou {
    max-width: 46rem;
  }
  .notifyme-modal-contactus {
    text-align: left;
    .contact-details__contacts {
      gap: 2.4rem;
    }
  }
}
