.client-service-content {
  .link-box__button.button {
    width: 100%;
  }
}
.client-service {
  // .tabs {
  //   margin-top: -3.2rem;
  // }
  &__section {
    margin-top: ($space-unit * 10);
  }
  .section {
    &--little-snippet {
      display: block;
      margin: 0;
      padding: ($space-unit * 4) 0;
      .button {
        width: 100%;
      }
      > div {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  &__modal {
    &--offline {
      padding-top: 1.9rem;
      &.modal {
        top: unset;
        height: auto;
        .modal__close {
          position: absolute;
          padding: 0;
        }
      }
    }
  }
}

.tracking-item {
  &__item {
    text-align: left;
    padding-left: ($space-unit * 2);
    padding-right: ($space-unit * 2);
    word-break: break-word;
  }
}

.hero-search {
  &__search-container {
    padding: 0;
    [data-react='faq-search-input'] {
      padding: 0 1.6rem;
    }
  }
}

.cs {
  &__tag-list {
    width: 100%;
    overflow-x: scroll;
    flex-wrap: nowrap;
    white-space: nowrap;
    padding: 2rem 0 0;
    display: block;
    text-align: center;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__tag-item {
    display: inline;
    padding: 0 1.5rem 0 0;
    &:first-child {
      padding-left: 1.6rem;
    }
  }
}
