:root {
  --shelf-height: 6.8rem;
}

// temporaneo solo per riuscire a navigare nelle vecchie category page non stilate
.main-filter-wrapper {
  margin-top: 6rem;
  margin-bottom: 2rem;
  border: 0.2rem solid red;
  width: 100%;

  .refinement-bar-wrapper {
    width: 100%;

    .refinements {
      width: 100%;
      display: flex;
    }
  }
}

#search-show,
#product-show {
  main {
    padding-top: 0;
  }
}

[data-react='catalog-app'] {
  position: relative;
  min-height: var(--viewport-height);
}

body.has-ticker {
  .listing-container {
    padding-top: $header-height-mobile - 0.1rem + $ticker-bar-m;
    &.is-shelf {
      padding-top: calc(#{$header-height-mobile} + var(--shelf-height) - 0.1rem + #{$ticker-bar-m});
    }
  }
}

body.app {
  .listing-container {
    padding-top: 0;
  }
}

.listing-container {
  transition: padding-top 0.2s ease-out;
  padding-top: $header-height-mobile - 0.1rem;
  &.is-shelf {
    padding-top: calc(#{$header-height-mobile} + var(--shelf-height) - 0.1rem);
  }
  &.loading {
    pointer-events: none;
    .product-tile__image {
      transition: initial;
    }
  }
  // .wrapper-refine-button {
  //   margin-top: $space-unit;
  // }
  .refine {
    @include animate(0.4);
    transition-property: transform, opacity;
  }
  .shelf-container {
    &:first-child {
      .refine {
        position: fixed;
      }
    }
    &:not(:first-child) {
      .refine {
        position: relative;
        top: initial;
        z-index: 1;
      }
    }
  }
}

.product-grid__item {
  border: $space-line solid red;
  padding: 1rem;
  margin: 1rem;
}

.grid-container {
  @include reset-list;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: auto;
  width: 100%;
  overflow: hidden;
  .product-tile {
    &:not(.in-plp) {
      .product-tile__name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    &.in-plp {
      .product-tile--carousel .swiper-slide {
        min-width: 100%;
      }
    }
  }
  li {
    min-width: 0;
    // margin-left: -0.5px;  // [OE-22600]
  }
  &.grid-2 {
    grid-template-columns: 100%;
  }
  .plppromo {
    &--2x2 {
      @extend .plppromo;
      grid-column: span 2;
      grid-row: span 2;
    }
    &--2x1 {
      @extend .plppromo;
      grid-column: span 2;
    }
  }
  &.has-look {
    li:nth-child(2n) {
      .shop-by-look-module {
        left: -100%;
      }
    }
    .product-tile {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        @include z(overlay);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        @include animate;
        pointer-events: none;
      }
    }
  }
  &.look-expanded {
    li {
      z-index: 1;
      &.look-expanded {
        z-index: 2;
      }
      &:not(.look-expanded) {
        .product-tile::before {
          background-color: rgba(#000000, 0.5);
        }
      }
    }
  }
  .margin-extra-top {
    margin-top: ($space-unit * 8);
  }
  .margin-extra-bottom {
    margin-bottom: ($space-unit * 8);
  }
  .margin-extra-right {
    margin-right: ($space-unit * 8);
  }
  .margin-extra-left {
    margin-left: ($space-unit * 8);
  }
}

.plp-loading,
.plp-error,
.plp-a11y__loadmore {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3.4rem;
  padding-bottom: 3.4rem;
  @extend %primary-s-u;
}

.plp-loading {
  .wrapper-loading {
    position: relative;
    min-width: 154px;
  }
  span {
    display: block;
    padding-bottom: 1.2rem;
    text-align: center;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: $grey_line;
      left: 0;
    }
  }
  .progress-bar {
    position: relative;
    display: block;
    width: 100%;
    height: 1px;
    background-color: black;
  }
}

.plp-error {
  &::before {
    content: '';
    @include fixedSprite('alert');
    width: $space-unit * 4;
    height: $space-unit * 4;
    margin-right: $space-unit * 2;
  }
}

.plp-a11y__loadmore {
  flex-direction: column;
  gap: 1.2rem;
  button[class*='button'] {
    width: auto;
  }
}

[data-whatintent='keyboard'] {
  .plppromo__link:focus::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    border: 1px solid $white;
    @include z;
  }
}
.plppromo {
  position: relative;
  .plppromo__link {
    display: block;
    position: relative;
    height: 100%;
    outline: none;
    .wrapper-product-video:before {
      content: '';
      display: block;
      width: 100%;
      padding-top: 150%; // trick to set min heigth
    }
    .product-card-info {
      position: absolute;
      left: 0;
      top: 0;
      padding: 1.6rem;
      display: flex;
      width: 100%;
      justify-content: space-between;
      z-index: 1;
      color: white;
      text-shadow: 0 0 5px rgba(15, 15, 15, 0.15);
      .product-label {
        display: block;
        color: $white_secondary;
        @extend %primary-xxs-u;
        margin-bottom: ($space-unit * 2);
      }
      i {
        height: 2.4rem;
        width: 10.8rem;
      }
    }
    &--product {
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 20%;
        bottom: 0;
        left: 0;
        background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
        opacity: 0.7;
      }
    }
  }

  .plppromo__title {
    position: absolute;
    top: $space-unit * 4;
    left: $space-unit * 4;
    text-transform: uppercase;
    word-break: break-all;
    @include z;
    &.white-title {
      color: $white;
    }
  }
  picture {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  img {
    position: relative;
    width: 100%;
    height: auto;
  }
  &.plppromo {
    &__promo-banner {
      .plppromo__div {
        min-height: 100vw;
        position: relative;
        display: flex;
        align-items: center;
        .plppromo__text-wrapper {
          padding: 1.6rem;
          position: relative;
          z-index: 2;
          text-align: center;
          color: $white;
          &.video-card {
            padding-bottom: 4.4rem;
          }
          .plppromo__logo {
            width: 4.4rem;
            margin: 1rem auto;
          }
          .plppromo__collection-name {
            text-transform: uppercase;
            margin-bottom: 1.6rem;
          }
          .plppromo__title {
            position: relative;
            top: auto;
            left: auto;
            font-size: 3.2rem;
            line-height: 3.8rem;
            margin-bottom: 0.8rem;
          }
          .plppromo__description {
            font-size: 1.8rem;
            line-height: 2.2rem;
            margin-bottom: 1rem;
          }
          .plppromo__cta-wrapper {
            .button {
              margin-bottom: 0.8rem;
            }
          }
        }
        .plppromo__bottom-cta {
          position: absolute;
          bottom: 2.6rem;
          left: 50%;
          transform: translateX(-50%);
          border-bottom: 1px solid $white;
          z-index: 2;
        }
        .plppromo__picture-container {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          .plppromo__picture {
            &:after {
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              right: 0;
              left: 0;
              background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.55) 54.77%);
            }
            img {
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        .video-container {
          &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.55) 54.77%);
          }
          .btn--pause {
            position: absolute;
            bottom: 2rem;
            left: 2rem;
            z-index: 5;
          }
        }
      }
      &__badge-logo {
        &__wrapper {
          display: flex;
          justify-content: center;
          margin: ($space-unit * 8) 0 ($space-unit * 12);
        }
        &__col {
          // flex: 0 1 33.3333%;
          flex: 0 1 20%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        &__img {
          position: relative;
          width: 30px;
          flex: 0;
        }
        &__text {
          margin-top: ($space-unit * 2);
        }
      }
    }
    &__promo-large,
    &__promo-medium,
    &__promo-small {
      .plppromo {
        &__text-wrapper {
          color: $white;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: 1;
          text-align: center;
          padding: ($space-unit * 4);
          .plppromo {
            &__title {
              position: static;
            }
          }
          &.no-pointer {
            pointer-events: none;
            a {
              pointer-events: auto;
            }
          }
        }
        &__logo {
          position: relative;
          width: auto;
          height: auto;
          margin: 0 auto ($space-unit * 6);
        }
        &__title {
          margin-bottom: ($space-unit);
          word-break: break-word;
          &.BlockGothicRR {
            font-family: 'BlockGothicRR';
            font-weight: bold;
            $mobile-font-data: map-get($genius-mobile, 's');
            font-size: nth($mobile-font-data, 1);
            line-height: nth($mobile-font-data, 2);
            letter-spacing: nth($mobile-font-data, 3);
          }
        }
        &__collection-name {
          margin-bottom: ($space-unit * 4);
          strong {
            font-family: var(--fontbold);
          }
        }
      }
      .plppromo__div {
        &.plppromo__video {
          .video-container {
            .video-wrapper {
              position: relative;
              &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.55) 54.77%);
              }
            }
          }
        }

        &.simplified {
          .plppromo__text-wrapper {
            display: flex;
            flex-direction: column;
            padding: 3.2rem;
            height: 100%;
            width: 100%;
            align-items: center;
            justify-content: center;
            .plppromo__title,
            .plppromo__description {
              text-align: center;
            }

            &.bottom-center {
              align-items: center;
              justify-content: flex-end;
              .plppromo__title,
              .plppromo__description {
                text-align: center;
              }
              .plppromo__cta {
                transform: translateX(1.2rem);
              }
            }

            &.bottom-left {
              align-items: flex-start;
              justify-content: flex-end;
              .plppromo__title,
              .plppromo__description {
                text-align: left;
              }
            }

            &.top-left {
              align-items: flex-start;
              justify-content: flex-start;
              .plppromo__title,
              .plppromo__description {
                text-align: left;
              }
            }

            &.top-center {
              align-items: center;
              justify-content: flex-start;
              .plppromo__title,
              .plppromo__description {
                text-align: center;
              }
              .plppromo__cta {
                transform: translateX(1.2rem);
              }
            }
            .plppromo__description {
              font-size: 1.6rem;
              max-width: 48rem;
            }
            .plppromo__cta-wrapper {
              max-width: 48rem;
              display: flex;
              gap: 2rem;
              margin: 4rem 0 0;
              .button--arrow-right {
                min-width: auto;
              }
            }
          }
        }

        .plppromo__picture-container {
          position: relative;
          display: block;
          width: 100%;
          height: 100%;
          overflow: hidden;
        }
        .plppromo {
          &__description {
            @extend %primary-xs;
          }
          &__cta-wrapper {
            // display: flex;
            // justify-content: center;
            margin-top: ($space-unit * 6);
          }
          &__cta {
            // width: auto;
            margin: $space-unit 0;
            font-size: 1.4rem;
            line-height: 2rem;
          }
        }
      }
    }
    &__promo-large {
      .plppromo {
        &__div {
          height: 100%;

          &.simplified {
            .plppromo__text-wrapper {
              .plppromo__title {
                @extend %primary-xxxl;
                margin-bottom: 0;
              }
              .plppromo__cta-wrapper {
                margin-bottom: 4.8;
              }
            }
          }

          .plppromo {
            &__title {
              @extend %primary-xxxl;
            }
            &__logo {
              margin-bottom: ($space-unit * 3);
            }
          }
          &.plppromo__video {
            padding-top: 162.24%;
            .btn--pause {
              position: absolute;
              bottom: 2.8rem;
              left: 2.6rem;
            }
          }
        }
        &__link,
        &__div {
          // height: auto;
          position: relative;
          .plppromo {
            &__picture {
              // @extend .aspect-ratio--4-3;
              &:before {
                content: '';
                position: relative;
                padding-top: 162.24%;
                display: block;
              }
              &--gradient {
                &:after {
                  content: '';
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  right: 0;
                  left: 0;
                  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.55) 54.77%);
                }
              }
              img {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
        &__badge-logo {
          &__wrapper {
            display: flex;
            justify-content: center;
            margin: ($space-unit * 8) 0 ($space-unit * 12);
          }
          &__col {
            // flex: 0 1 33.3333%;
            flex: 0 1 20%;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          &__img {
            position: relative;
            width: 30px;
            flex: 0;
          }
          &__text {
            margin-top: ($space-unit * 2);
          }
        }
      }
    }

    &__promo-medium {
      .plppromo {
        &__div {
          &.simplified {
            .plppromo__text-wrapper {
              .plppromo__title {
                @extend %primary-xxl;
                margin-bottom: 2rem;
              }
            }
            .plppromo__cta-wrapper {
              margin-bottom: 4rem;
            }
            .plppromo__picture-container {
              .plppromo__picture--gradient {
                @include plp-gradient(center, max);
                &::after {
                  background-size: cover;
                }

                &.bottom-left {
                  @include plp-gradient(left, max);
                  &::after {
                    background-size: cover;
                  }
                }

                &.top-left {
                  @include plp-gradient(left, max);
                  &::after {
                    background-size: cover;
                  }
                }

                &.top-center {
                  @include plp-gradient(center, max);
                  &::after {
                    background-size: cover;
                  }
                }
              }
            }
          }
          .plppromo {
            &__title {
              @extend %primary-xxxl;
            }
          }
        }
        &__link,
        &__div {
          position: relative;
          .plppromo {
            &__picture {
              @extend .aspect-ratio--4-3;
              &--gradient {
                &:after {
                  content: '';
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  right: 0;
                  left: 0;
                  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.55) 54.77%);
                }
              }
              img {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
    &__promo-small {
      .plppromo {
        &__logo {
          margin-bottom: ($space-unit * 4);
          height: 4.2rem;
          width: auto;
        }
        &__div {
          display: inline;

          &.simplified {
            .plppromo__text-wrapper {
              .plppromo__cta-wrapper {
                margin-top: 0;
              }
            }
          }

          .plppromo__text-wrapper {
            .plppromo__cta-wrapper {
              margin-top: 1.2rem;
            }
          }
          &.centered {
            .plppromo__text-wrapper {
              top: 40%;
            }
          }
          &.bottom {
            .plppromo__text-wrapper {
              //bottom (tablet)
              transform: none;
            }
          }
        }
        &__title {
          @extend %primary-l;
        }
        &__cta-wrapper {
          // display: flex;
          // justify-content: center;
          margin-top: ($space-unit * 5);
        }
        &__link,
        &__div {
          .plppromo {
            &__picture {
              @extend .aspect-ratio--2-3;
              &--gradient {
                &:after {
                  content: '';
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  right: 0;
                  left: 0;
                  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.55) 54.77%);
                }
              }
              img {
                position: absolute;
                left: 0;
                top: 0;
                right: initial;
                height: 100%;
                transform-origin: top center;
                transition: all 0.5s 0s cubic-bezier(0.25, 1, 0.5, 1);
                object-fit: cover;
              }
              &-container {
                height: auto;
              }
            }
          }
        }
        // &__logo {
        //   position: relative;
        //   width: auto;
        //   height: auto;
        //   margin: 0 auto ($space-unit * 6);
        // }
      }
    }
  }
}

.shelf-container {
  .btn-wrapper {
    text-align: center;
  }
  .view-all {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-top: $space-unit * 3;
    padding-bottom: $space-unit * 6;
    text-decoration: initial;
    min-width: 22.6rem;
    i {
      margin-top: -0.2rem;
    }
  }
}

.editmode {
  .grid-container {
    pointer-events: none;
  }
  [data-component='plpEditorialCards'] {
    > .product-tile {
      display: none;
    }
  }
  .editorial-cards-tooltip {
    position: fixed;
    background: yellow;
    padding: 12px;
    right: 12px;
    top: 102px;
    z-index: 1;
  }
  .experience-region.experience-editorialcards {
    display: none;
  }
}
.intermediary-image {
  display: none;
  max-width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  transform: scale(1);
  z-index: 11;
  transition: left 0.3s ease-in, transform 0.3s ease-in;
  &.shown {
    display: block;
  }
}
.intermediary-wrapper {
  .fade-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9;
    pointer-events: none;
    background-color: transparent;
    transition: none;
    &.transitioning {
      background-color: $white;
      transition: background-color 0.3s ease-in-out;
    }
  }
}
