.order-detail-page {
  .heading {
    margin-bottom: 40px;
    text-align: center;
    &__title {
      text-transform: uppercase;
      @extend %primary-xs;
    }
    &__subtitle {
      margin: 8px 0;
      text-transform: uppercase;
      font-size: 24px;
      line-height: 28px;
      @extend %primary-m-u;
    }
    &__date {
      font-size: 16px;
      color: $black_secondary;
      text-transform: uppercase;
    }
    &__shipstatus {
      margin-bottom: 8px;
      font-size: 20px;
      line-height: 24px;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      column-gap: 8px;
      @extend %primary-m-u;
    }
    &__expected {
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;

      .tooltip{
        display: inline-block;
      }
    }
    &__tracking{
      font-weight: 500;
    }
  }

  $boxPadding: 40px 115px;

  .product-wrapper{
    margin-top: 32px;
    margin-bottom: 4px;

    .detail-multishipment {
      width: 100%;
    }

    .detail-shipment {
      padding: $boxPadding;
      padding-bottom: 0;
      border: 1px solid $grey_line;
      border-bottom: none;

      &-title{
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
      }

      &-delivery{
        margin-left: 8px;
        color: $black_secondary;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  .price-wrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 32px 115px; //$boxPadding;
    border: 1px solid $grey_line;
    background-color: $grey_disabled;

    .price-info{
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      line-height: 16px;
    }
  }

  .total-wrapper{
    display: flex;
    justify-content: space-between;
    padding: 24px 115px;
    border: 1px solid $grey_line;
    border-top: none;
    background-color: $grey_disabled;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
  }

  .invoice-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 8px;
  }

  .invoice-btn{
    &-top{
      margin-top: 32px;
    }
    margin-bottom: 32px;
    margin-left: 0;
    margin-right: 0;
  }

  .order-info-box-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4px;

    &--no-ship {
      grid-template-columns: repeat(2, 1fr);
    }

    &.multi-payments {
      .order-info-box {
        max-width: 24%;
        min-width: 24%;
      }
    }

    .order-info-box{
      width: 100%;
      max-width: 33%;
      min-width: 33%;
      padding: 24px;
      border: 1px solid $grey_line;
      background-color: $grey_disabled;
      text-align: center;

      .title{
        margin-bottom: 40px;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 16px;
      }

      .label-wrap{
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      .label{
        font-size: 14px;
        line-height: 16px;

        &-highlight{
          @extend .label;
          text-decoration: underline;
          font-weight: 300;
          text-transform: uppercase;
        }
      }
    }
  }

  .return-btn-wrap{
    display: flex;
    justify-content: center;
    gap: 4px;
    margin: 40px 0;

    a{
      width: auto;
    }
  }

  .wrapper {
    margin-top: ($space-unit * 2);
    .detail-address {
      p {
        margin-bottom: $space-unit;
      }
    }
    .detail-row {
      margin-bottom: ($space-unit * 8);
      p {
        margin-top: $space-unit;
      }
    }
    .detail-title {
      text-transform: uppercase;
      margin-bottom: $space-unit;
    }
    .detail-trackinglink {
      margin-top: $space-unit * 2;
    }
  }
  .line {
    @include border('top');
    margin-top: ($space-unit * 4);
    margin-bottom: ($space-unit * 4);
  }

  .row-info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: ($space-unit * 6);

    &__button {
      width: auto;
      display: inline-block;
      margin-bottom: ($space-unit * 6);
      margin-top: ($space-unit * 7);
    }
    &__row {
      width: 100%;
      @extend %primary-xs;
      display: flex;
      justify-content: space-between;
      margin-bottom: $space-unit;
      &__main {
        @extend %primary-s;
        margin-bottom: ($space-unit * 4);
      }
      &:last-child {
        margin-bottom: 0;
      }
      .desired-date-suggestion {
        font-size: 1.2rem;
      }
    }
  }

  .link-button {
    margin-top: ($space-unit * 5);
  }

  .tax-disclaimer--wrapper {
    margin-bottom: 1.6rem;
    .tax-disclaimer {
      color: $black;
      font-weight: 600;
      line-height: 1.4rem;
      letter-spacing: 0.03rem;
    }
  }

  .bag-product{
    margin-bottom: 0;
    padding: $boxPadding;
    border: 1px solid $grey_line;
    border-top: none;

    &__content{
      margin-left: 24px;
    }

    &__details{
      margin-top: 16px;
      margin-bottom: 0;

      .error-message {
        margin-top: 1.6rem;
      }
    }

    &__first{
      border-top: 1px solid $grey_line;
    }

    &__name-price__name{
      font-size: 16px;
    }

    .option-product{
      &__quantity{
        margin-top: 0;
      }

      &__size{
        margin-top: 8px;
      }

      &__color{
        margin-top: 8px;
      }
    }

    div:not(:last-child) {
      margin-bottom: 0;
    }

  }
  .returnabledisclaimer {
    display: block;
    width: 100%;
    margin-top: $space-unit * 4;
    font-weight: 600;

    &.mt-auto {
      margin-top: auto;
    }
  }
}
