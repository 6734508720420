.nft-landing {
  .nft-image {
    max-width: 46rem;
  }
  .nft-landing-bottom {
    .border {
      border-top: 1px solid $grey_line;
    }
    .col-12 {
      border: none;
    }
  }
  &.proof-of-ownership {
    .result-content {
      max-width: 42.6rem;
      margin: 0 auto;
    }
  }
}
