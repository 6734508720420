.wishlist {
  position: relative;
  &__intro {
    text-align: center;
    margin: 40px 0;
    &-title {
      @extend %primary-l;
    }
    &-text {
      @extend %primary-s;
      margin-bottom: $space-unit * 8;
    }
    &-button {
      @extend %primary-s;
      text-decoration: underline;
      margin-bottom: $space-unit * 8;
    }
  }
  &__single-product {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    background-color: $black;
    color: $white;
    &.white-bg {
      background-color: transparent;
      .wishlist__single-product__product-info__to-add {
        & > a {
          color: $black;
          font-size: 1.6rem;
          line-height: 1.8rem;
          &.cta--edit {
            &::after {
              @include sprite('edit');
              filter: invert(0);
            }
          }
        }
      }
    }
    &.anim {
      max-width: 100%;
      transition: max-width 1s ease-in-out;

      &.delete {
        opacity: 0;
        max-width: 0 !important;
      }
    }

    .swiper-container {
      z-index: 0;

      .swiper-pagination-bullets {
        --bullets-bottom-offset: 3.2rem;
      }
      .swiper-button-disabled {
        cursor: pointer;
        pointer-events: fill;
      }
    }

    .product-labels {
      display: flex;
      margin-bottom: 0.4rem;
      .product-label--inverted {
        border: 1px solid rgb(255, 255, 255);
        color: rgb(255, 255, 255);
        padding: 0.2rem 0.4rem;
        position: relative;
        text-transform: uppercase;
        font-size: 1.2rem;
      }
    }

    &__product-info {
      &__container {
        margin-top: 2.2rem;
        &.has-label {
          margin-top: 0;
        }
      }
    }

    .wishlist-icon {
      width: auto;
      height: auto;
      position: absolute;
      top: 16px;
      right: 16px;
      border: none;
      z-index: 2;
    }
    .product-tile__image-wrapper {
      position: static;
    }
    &__img-holder {
      position: relative;
      overflow: hidden;
      .product-image {
        background: initial;
        &:before {
          padding-top: 138.909%;
        }
      }
      > a {
        &:focus::before {
          content: '';
          position: absolute;
          top: 1px;
          left: 1px;
          width: calc(100% - 2px);
          height: calc(100% - 2px);
          z-index: 1;
        }
        &:focus-visible::before {
          content: '';
          position: absolute;
          top: 1px;
          left: 1px;
          width: calc(100% - 2px);
          height: calc(100% - 2px);
          box-shadow: inset 0 0 0 0.1rem white;
          z-index: 1;
        }
      }
    }
    &__image {
      @include animate();
    }
    &__product-info {
      position: relative;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      padding-bottom: 1.6rem;
      &__name {
        @extend %primary-s;
        text-decoration: none;
      }
      &__to-show {
        .wishlist__single-product__product-info__name {
          margin-bottom: 0.4rem;
        }
        > p {
          margin-bottom: 0.4rem;
        }
        // display: flex;
        // justify-content: space-between;
        // width: 100%;
        // margin-bottom: $space-unit;
        // > p {
        //   flex-shrink: 0;
        // }
      }
      &__to-add {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .oos-label {
          color: $status_error;
        }
      }
      &__color {
        display: inline-flex;
        align-items: center;
        // margin-bottom: $space-unit;
        &.cta--edit {
          color: $white;
          text-decoration: initial;
          padding-right: 2.4rem;
          &::after {
            @include sprite('edit');
            filter: invert(1);
            width: 2.4rem;
            height: 2.4rem;
          }
        }
        .swatch {
          width: 12px;
          height: 12px;
          border: 1px solid $white;
          margin-left: ($space-unit * 2);
        }
      }
    }
    &__remove-btn,
    &__share-btn {
      position: absolute;
      top: 1.6rem;
      //transform: translateY(-5rem);
      //opacity: 0;
      @include animate(0.2);
      > span {
        display: flex;
        align-items: center;
        text-transform: initial;
        @extend %primary-xxs;
      }
      .icon-btn__text {
        padding: 0 $space-unit;
      }
      .label-hover {
        @include animate;
        display: inline-block;
        //opacity: 0;
        //transform: translateX(1rem);
      }

      // &:hover,
      // &:focus {
      //   .label-hover {
      //     opacity: 1;
      //     transform: initial;
      //   }
      // }
    }
    &__remove-btn {
      right: 1.6rem;
      .icon-btn__icon {
        @include fixedSprite('close-white');
      }
    }
    &__share-btn {
      left: 1.6rem;
      .icon-btn__icon {
        @include fixedSprite('share');
        filter: invert(1);
      }
    }
    &.type-mbm {
      overflow: hidden;
      position: relative;
      img.wishlist__single-product__image {
        padding: 2rem 2rem 4rem;
      }
    }

    .selector-size {
      border-top-color: #404040;
      margin-top: ($space-unit * 2);
      .selector__menu__item {
        color: $white;
        [data-whatintent='keyboard'] & {
          input[type='radio']:focus-visible + label {
            outline-color: $outline-color-inverted;
          }
        }
      }
      .selector__menu .label {
        color: $white;
      }
      .selector__button {
        color: $white;
        text-align: left;
        margin-top: 0.8rem;
        margin-bottom: 1.6rem;
        padding: 0;
        &:after {
          filter: invert(1);
        }

        &.no-size {
          &::after {
            display: none;
          }
        }
      }
      .selector__button__info {
        text-transform: capitalize;
        font-size: 1.2rem;
        color: $status_error;
        display: inline-flex;
        padding-right: 2rem;
        justify-content: flex-end;
        flex-grow: 1;
      }
      .selector__button__value--outofstock {
        color: $white_secondary;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          width: 2.2rem;
          height: 0.1rem;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
          background-color: rgb(255, 255, 255);
        }
      }
      .selector__menu {
        [data-whatintent='keyboard'] &:focus-visible {
          outline: $outline-width solid $outline-color-inverted;
          outline-offset: 0.4rem;
        }
        .selector-wrapper {
          .selector__menu--size--radio-btn {
            .selector__menu__item {
              .selector-size-item__input {
                &:focus-visible {
                  outline: $outline-width solid $outline-color-inverted;
                  outline-offset: 1rem;
                }
              }
              &:hover:not(.selected) {
                .selector__menu__item__label {
                  &::after {
                    transform: translateX(-50%) scaleX(1);
                  }
                }
              }

              &.selected {
                .selector__menu__item__label {
                  &::after {
                    background-color: $grey_line;
                    transform: translateX(-50%) scaleX(1);
                  }
                }
              }
              &__label {
                @extend %primary-s-u;
                position: relative;
                display: inline-block;
                cursor: pointer;
                @include touchtarget-area(3.2rem);
                &::after {
                  content: '';
                  height: 0.1rem;
                  bottom: 0;
                  background-color: $grey_line;
                  width: 100%;
                  min-width: 1.6rem;
                  @include center('horizontal');
                  @include animate(0.3);
                  visibility: visible;
                  transform: translateX(-50%) scaleX(0);
                }
              }
            }
          }
        }
      }
      .is-disabled {
        color: $white_secondary;
        &::after {
          content: '';
          position: absolute;
          width: 2.2rem;
          height: 0.1rem;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
          background-color: $white;
        }
      }
    }
    .wishlist__single-product__product-info {
      width: 100%;
      padding: 1.2rem 1.6rem 2.4rem;
      background: $black;
      justify-content: end;
    }
    &.with-label {
      .wishlist__single-product__product-info {
        justify-content: space-between;
      }
    }
    &.absolute-info {
      padding-bottom: var(--wishlist-height); // Defined by WishlistProduct.tsx
      .wishlist__single-product__product-info {
        position: absolute;
        bottom: 0;
        left: 0;
        // height: var(--wishlist-height);
      }
    }
  }
  &__products-listing {
    @include reset-list;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    //grid-template-columns: 1fr;
    column-gap: $space-unit;
    row-gap: $space-unit * 4;
  }
  &__share-button {
    @extend %primary-s-u;
    display: inline-flex;
    align-items: center;
    column-gap: $space-unit;
    margin-top: 1.6rem;
    &::before {
      content: '';
      @include fixedSprite('share');
    }
  }
  .wishlist-wrapper {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .wishlist-login-box {
    padding: 24px 80px;
    border: 1px solid $grey_line;
    background-color: $white;

    .wrapper-buttons {
      margin-top: 24px;
      display: flex;
      justify-content: center;

      button {
        max-width: 300px;
        width: auto;
      }
    }
  }
  &--empty {
    padding: ($space-unit * 12) 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      @extend %primary-s;
      text-align: center;
      margin-bottom: $space-unit * 6;
    }

    &__button-container {
      width: 100%;
      padding: 0 1.6rem;
    }
  }
}

.wishlist-skeleton {
  min-height: 50vh;
}

.wishlist-icon {
  width: 3.6rem;
  height: 3.6rem;
  padding: 0;
  flex-shrink: 0;
  border: 1px solid black;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:focus {
    outline-offset: 2px;
  }
  &--mobile {
    position: absolute;
    right: 0;
  }
  &[data-color='white'] {
    outline-color: white;
  }
}

.wishlist-share {
  &__modal-header {
    display: flex;
    align-items: center;
    padding-top: ($space-unit * 8);
    padding-bottom: ($space-unit * 2);
    // margin-top: $space-unit;
  }
  &__description {
    margin-top: ($space-unit * 6);
    margin-bottom: ($space-unit * 9);
  }
  &__copy-btn,
  &__mail-share-btn {
    &:not(:last-child) {
      margin-bottom: ($space-unit * 3);
    }
  }
  form {
    margin-top: ($space-unit * 5);
  }
  &__btn--email-step-back {
    margin-right: ($space-unit * 2);
  }
}

[data-whatintent='keyboard'] {
  .wishlist__single-product .product-tile-carousel__button:focus,
  .wishlist__single-product__share-btn:focus,
  .wishlist__single-product__notify-btn:focus,
  .wishlist__single-product__remove-btn:focus {
    outline: 1px solid white;
    text-decoration: initial;
    text-underline-position: initial;
  }
  .product-tile__image-wrapper {
    > a {
      &:focus::before,
      &:focus-visible::before {
        content: '';
        position: absolute;
        top: 0.8rem;
        left: 0.8rem;
        width: calc(100% - 1.6rem);
        height: calc(100% - 1.6rem);
        z-index: 1;
        box-shadow: inset 0 0 0 0.1rem white;
        pointer-events: none;
      }
    }
  }
  .wishlist__single-product.focused {
    .product-tile-carousel__button,
    .swiper-pagination-bullets {
      &:not([disabled]) {
        opacity: 1;
      }
    }
  }
}

.wishlist-title {
  @extend %primary-s-u;
  background: $gradient-separator;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  text-align: center;
  border-bottom: 1px solid $grey_line;
}

.empty-wishlist-mbm {
  @extend %primary-s;
  text-align: center;
  margin-bottom: 4rem;
}

.container.container-support {
  margin-bottom: 4rem;
}

.wishilist-size-popup {
  .modal__header {
    .dialog__title {
      text-transform: uppercase;
      height: auto;
      margin-top: 3rem;
    }
    .dialog__description {
      margin-top: 1.6rem;
    }
  }
  .selector-size-expanded .selector__header {
    padding-top: 1.6rem;
    .selector__label__name {
      color: $black_secondary;
    }
    .selector__label__info {
      color: $status_error;
    }
  }
  &__button {
    padding-top: 1rem;
    .button {
      @extend .button--primary;
      &:hover {
        background-color: $black;
      }
    }
  }
}
