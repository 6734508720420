.myfeed-containerImg {
  aspect-ratio: 362/195;
}

.myfeed_carousel-controls {
  left: calc(100% - 72px);
}

.myfeed-description {
  padding-inline: 12px;
}

.account-dashboard {
  .experience-region{
    margin-left: -20px;
    margin-right: -20px;
  }
}
.myfeed-container {
  .editorial-multi-text{
    flex-direction: column-reverse;
  }
  
  .benefits-section{
    padding: 40px 20px;
  }

  .hero-carousel__main-wrapper,.swiper-container{
    aspect-ratio: 21/14;
  }
}

.my-feed-exclusive-products-wrapper {
  .tabs.tabs--products {
    .react-tabs__tab-panel {
      
      &--selected{
        min-height: 110vw;
      }
    }
  }
}