.quote-with-image {
  padding: 2rem;
}

.quote-column {
  &__inner-container {
    width: 28.2rem;
  }
  &__quote,
  &__quote p {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}
