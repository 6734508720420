.curator-quote {
  .curator-quote-container {
    padding: 12rem 0;
    .quote-text {
      p {
        font-weight: 300;
        font-size: 24px;
        line-height: 28px;
      }
    }
    .quote-paragraph {
      p {
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
      }
    }
    &.quotations {
      .quote-paragraph {
        padding-top: 3.2rem;
        p {
          width: 80%;
        }
      }
    }
  }
}
