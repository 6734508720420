.slim-banner {
  .black {
    background-color: black;
    color: white;
  }
  .grey {
    background-color: $grey_line;
  }
  .white {
    background-color: white;
    color: black;
  }
  .wrapper-slim-banner {
    padding: 4rem 0;
    margin: auto;
    .row {
      justify-content: space-between;
    }
  }
  .wrapper-image {
    position: relative;
    margin: auto;
    padding: 0 0 4rem 0;
  }
  .wrapper-info {
    padding: 0 1.6rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    .row {
      column-gap: 1rem;
      row-gap: 1rem;
    }
  }
}
