.flagship-page__breadcrumb.hero-breadcrumb {
  padding-bottom: 20px;
  // Hide the first and last breadcrumb items only if there are exactly 3 breadcrumb items
  ol {
    li:nth-of-type(1):nth-last-of-type(3),
    li:nth-of-type(3):nth-last-of-type(1) {
      display: none;
    }
    li:nth-of-type(2):nth-last-of-type(2) {
      a.breadcrumb__item {
        &::after {
          content: unset;
        }
      }
      &:before {
        content: '';
        margin: 0 ($space-unit * 2);
        @include fixedSprite('arrow-right-breadcrumbs');
        transform: rotate(180deg);
      }
    }
  }
}

.flagship-page {
  &__full-height {
    // min-height: calc(var(--view-height) - var(--header-height) - 50px);
    min-height: calc(100dvh - var(--header-height) - 74px);
  }

  &__matterport {
    .content {
      .flagship-store_detail {
        min-height: 102px;
      }

      .matterport-showcase {
        min-height: max(calc(100dvh - var(--header-height) - 74px - 102px), 66vh);
      }
    }
  }
}
