.component-text-squares {
  padding: 4rem 2rem 2.4rem 2rem;

  .section-text-squares {
    min-height: 100%;
    .text-square {
      &-content {
        padding: 0;
        align-items: normal;
        justify-content: normal;

        &::before {
          content: '';
          display: block;
          padding-top: calc(66.6666% - 0.2rem);
        }
      }

      &-caption {
        justify-content: normal;
        padding: 18.84% 2.4rem 0;
      }

      &-eyebrows,
      &-title {
        padding-bottom: 1.2rem;
      }
    }
  }
}
