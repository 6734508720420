.curator-image-shop-the-look-container {
  .large-quote,
  .long-quote {
    padding: 0 10.5% 6rem 42%;
  }
  .large-quote {
    font-size: 46px;
    line-height: 44px;
  }
  .long-quote {
    font-size: 24px;
    line-height: 28px;
  }
}
