.product-container {
  .discover-more {
    padding-top: 2.4rem;
    padding-bottom: 0;
    .text-center::before {
      content: '';
      border-top: 1px solid $grey_line;
      display: block;
      padding-bottom: 3.2rem;
    }
    .button {
      margin-top: 2rem;
    }
  }
  .product-video-wrapper {
    &.is-parfum {
      &::after {
        content: initial;
      }
    }
  }
  .palette-available {
    position: absolute;
    top: 7rem;
    right: 1.6rem;
    max-width: 150px;
    text-align: right;
  }
  .palette-label {
    top: 1.6rem;
    left: 1.6rem;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
}
