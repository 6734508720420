.component_grenoble_performance {
  &__title-wrapper {
    padding-top: 5.4rem;
  }
  &__image-wrapper {
    padding-bottom: 5.4rem;
    &:after {
      content: '';
      background: linear-gradient(180deg, rgba(var(--bg-color), 1) 0%, rgba(var(--bg-color), 1) 85%, rgba(var(--bg-color), 0) 100%);
      position: absolute;
      height: 50%;
      width: 100%;
      left: 0;
      bottom: 2.4rem;
      z-index: -1;
    }
  }
  &__info-wrapper {
    position: relative;
    .info-row {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transform: translateY(50%);
    }
  }
}
