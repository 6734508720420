.checkoutpage {
  .bag-product__preorderinfo {
    display: none;
  }
  .row-reverse {
    flex-direction: row-reverse;
  }
  .wrapper-checkout {
    justify-content: center;
  }
  // padding-bottom: 10rem;
  min-height: 20rem;

  &__title {
    margin-top: ($space-unit * 8);
    margin-bottom: ($space-unit * 8);
    text-align: center;
    @extend %primary-l-u;
  }

  .accordion__group {
    > .accordion-step:first-child {
      .step__header {
        border-top: initial;
      }
    }
  }
  .paymentmethods {
    margin-bottom: 2.4rem;
    .terminalselection {
      margin-top: $space-unit * -2;
      margin-bottom: $space-unit * -2;
      .form-group {
        margin-bottom: 0;
      }
    }
    .radio__icon {
      column-gap: 1.5rem;
    }
  }
  .radio.radio--outline {
    .inner-label {
      .radio__icon {
        & > [class^='icon--payment'] {
          & > div {
            display: none;
          }
        }
        .paymentmethods-cards {
          & > [class^='icon--payment'] {
            & > div {
              display: block;
              @extend .visually-hidden;
            }
          }
        }
      }
    }
  }

  .radioo:not(.radio--outline) {
    align-items: flex-start;
    input[type='radio'] {
      margin-top: 0.2rem;
    }
    .inner-label {
      padding-left: 0.4rem;
      .checkout-shipping__options__option {
        div {
          > p.bold {
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  .gc-checkout-tooltip {
    width: 20px;
    // height: 20px;
    margin-left: 8px;
  }

  .gc-disablepayments {
    opacity: 0.35;
    pointer-events: none;
  }

  #paymentMethod-error {
    margin-top: ($space-unit * 8); // avoid overlapping with the last option
  }
  .billingAddressForm-bankdetails {
    margin-bottom: $space-unit * 4;
  }
  .billingAddressForm {
    margin-top: 0.8rem;
    overflow: hidden;
    margin-bottom: 1.2rem;
  }
  .billing-heading {
    display: flex;
    justify-content: space-between;
  }
  .checkout-navigations {
    margin-top: ($space-unit * 8);
    align-items: center;
    &.request-tax-refund-button {
      align-items: flex-start;
      row-gap: 3rem;
    }
    .button {
      &.d-none {
        display: none;
      }
    }
  }
  .alert--success {
    display: flex;
    align-items: center;
    column-gap: 0.4rem;
    &::before {
      content: '';
      @include fixedSprite('alert-green');
      width: $space-unit * 4;
      height: $space-unit * 4;
      fill: $status_success;
    }
  }
  .adyen-checkout__paypal__button {
    margin-bottom: 0;
  }

  .checkout-login {
    .subtitle {
      margin-top: ($space-unit * 4);
    }
    .formfields {
      margin-top: ($space-unit * 3);
    }

    .pw-error {
      margin-top: -($space-unit * 2);
      .invalid-feedback {
        margin: 0;
        justify-content: flex-end;
      }
    }

    .forgot-pw-error {
      margin-top: -($space-unit * 2);
      display: flex;
      align-items: baseline;
      margin-bottom: ($space-unit * 8);
    }

    .guest-button {
      margin-top: ($space-unit * 2);
    }
    .dev-button {
      margin-top: ($space-unit * 10);
    }
  }
  .adyen-checkout__paywithgoogle {
    text-align: right;
  }
  .placeorderdisabled {
    > .adyen-checkout__paypal,
    > .adyen-checkout__paywithgoogle,
    > .adyen-checkout__amazonpay {
      position: relative;
      display: block;
      pointer-events: none;
      &::after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $white;
        opacity: 0.5;
      }
    }
  }
  .checkout-privacyflags {
    margin: 0;
    .checkbox {
      margin-bottom: 0;
      margin-top: 0.8rem;
    }
  }
  .mbm-login {
    .loginsplit__subtitle {
      margin-bottom: 2.4rem;
    }
    .loginsplitsingle .forgotpwd-section {
      margin-bottom: 2.4rem;
    }
    form {
      margin-bottom: 0.8rem;
    }
  }
  .wrapper-tab-list-checkout {
    position: sticky;
    top: -7.9rem;
    z-index: 5;
    background-color: white;
    @include animate(0.3);
    &.show-summary-mobile {
      top: 0;
    }
    .react-tabs__tab-list {
      column-gap: initial;
      display: flex;
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      text-align: center;
      .react-tabs__tab {
        flex: 1 0 50%;
        @extend %primary-xxs;
        letter-spacing: 0.05em;
        &:last-child {
          pointer-events: none;
        }
      }
    }
  }
  .wrapper-tab-panels-checkout {
    padding-top: 3.2rem;
    padding-bottom: 8rem;
  }

  // for h2
  .checkout-subtitle {
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 1.6rem;
    font-weight: bold;
  }

  // for h3 - Packaging options
  .checkout-packaging {
    .checkout-subtitle {
      padding: 1.6rem 0;
    }
  }

  .tax-refund {
    margin: 2.4rem 0 0 0;
  }
  .tax-refund-container {
    display: flex;
    align-items: center;
    .checkbox {
      margin-bottom: 1rem;
    }
  }
  .disclaimer-tax-refund {
    padding-bottom: 2.4rem;
  }
  .taxrefund-info {
    padding: 0 0.4rem 0.8rem;
    .icon--info {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
  .tax-refund-disabled {
    opacity: 0.5;
  }
  .invoice-block {
    margin-bottom: 2.4rem;
    position: relative;
    .tooltip {
      position: absolute;
      left: 100px;
      top: -3px;
      z-index: 4;
    }
  }

  .expected-delivery-pickup {
    display: block;
    margin-top: 2.4rem;
    margin-bottom: 4.8rem;
  }
}

.checkout-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .main--checkout {
    flex-grow: 1;
  }
}

#checkoutappapi-renderexpresspayments {
  min-height: initial;
  main {
    min-height: initial;
    padding: 1.6rem 0;
  }
  .expresstype {
    > button {
      display: block;
      &:not(:last-child) {
        margin-bottom: ($space-unit * 2);
      }
      &:hover {
        border-radius: 0;
        min-height: 4.4rem;
      }
    }
  }
}
.checkout-privacyflags {
  margin: 2.4rem 0;
  .text-disclaimer {
    margin-bottom: 0;
  }
  label a {
    font-weight: bold;
  }
}
.app-link {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-decoration: underline;
}
#component-container > * {
  display: block;
  margin-bottom: 2.4rem;
}
.taxrefund-text {
  margin: 4rem 0 1.2rem;
}
.btn-mdal-taxrefund {
  flex-wrap: nowrap;
  column-gap: 0.5rem;
}
.overlay-mobile-order-summary {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.checkout {
  &.no-scroll {
    overflow-y: hidden;
    position: relative;
    height: 100%;
  }
}
#component-container-button {
  &.dnone {
    display: none;
  }
}
.one-to-pickup-paragraph {
  margin: 2rem 0 1.6rem;
}
.container-onetopickup {
  margin-top: 1.6rem;
}
.disclaimer-taxrefund {
  margin-top: 2.6rem;
}
.checkout-privacyflags {
  &.disabled-input {
    .checkbox {
      .checkbox__field {
        pointer-events: none;

        label {
          a {
            pointer-events: auto;
          }
        }
      }
    }
  }
}
.checkout-login-button {
  margin-top: 1.6rem;
}

.pfas-restriction-modal {
  @include breakpoint(sm, max) {
    .modal__header {
      .dialog__title {
        align-items: flex-end;
        padding-bottom: 0.8rem;
      }
      .dialog__description {
        margin-top: 0;
        padding-bottom: 2.4rem;
        border-bottom: 1px solid #e4e4e4;
      }
    }
  }

  .modal__divisor {
    width: calc(100% - 6.4rem);
    left: 3.2rem;
  }
  .modal__body {
    margin-top: 3.2rem;
    .product-scroll {
      @include scrollbar;
      max-height: 27rem;
      @include breakpoint(sm, max) {
        max-height: calc(100vh - 36.4rem);
      }
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
      padding-right: clamp(2.4rem, 6vw, 6.8rem);
      .product-line {
        display: flex;
        gap: 1.5rem;
        .product-image {
          height: 9rem;
          width: 6.7rem;
        }
        .product-info {
          flex: 1;
          .info-row {
            &.delete {
              margin-top: 1.5rem;
              .button {
                span {
                  margin: 0.5rem 0;
                }
                i {
                  margin-left: 0.3rem;
                }
              }
            }
            display: flex;
            justify-content: space-between;
          }
          .option-product__size,
          .option-product__color {
            font-size: 1.2rem;
          }
        }
      }
      .pfas-info {
        font-size: 1.2rem;
        color: #737373;
      }
      hr {
        width: 100%;
        left: 0;
        margin: 0;
        border: none;
        border-top: 1px solid #e4e4e4;
      }
    }
    .buttons-section {
      display: flex;
      flex-direction: column;
      gap: 1.4rem;
      margin-top: 2.4rem;
    }
  }
}
