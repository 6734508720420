.page-template-monclernowarticle main iframe {
  max-width: 100%;
}

.monclernow-hero {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    width: 100%;
    padding-top: 43%;
    display: inline-block;
  }

  img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__text {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: #fff;
    bottom: $space-unit * 16;
    max-width: 100%;
    z-index: 2;
    padding: 0 $space-unit * 4;
    width: 100%;
  }

  &__title {
    margin-bottom: 0.8rem;
    text-transform: uppercase;
    @extend %primary-xxl;
  }

  &__description {
    max-width: 100%;
    width: 47.7rem;
    margin: 0 auto;
    @extend %primary-s;
  }

  &__info {
    margin-bottom: 0.8rem;
    & > * {
      margin: 0 $space-unit;
    }
  }
  &--text-before-img {
    .monclernow-hero {
      &__title {
        margin-bottom: 2.4rem;
      }
      &__text {
        position: static;
        color: #000;
        transform: none;
        padding-top: 5.6rem;
      }
    }

    .monclernow {
      &__img {
        position: relative;
        &::before {
          content: '';
          position: relative;
          display: inline-block;
          width: 100%;
          padding-top: 44.05%;
        }
      }
    }

    &::before {
      content: none;
    }
  }

  &.portal {
    &::before {
      padding-top: 14.58%;
    }
    .monclernow-hero__text {
      bottom: initial;
      top: 50%;
      transform: translate(-50%, -50%);
      text-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.2);
    }
  }
}
