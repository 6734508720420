.page-template-immersiveparfumes {
  .contextual-nav {
    justify-content: space-between;
    padding: 0 1.6rem;
    .back-button {
      display: none;
    }
    .links-wrapper {
      .button:not(:last-child) {
        margin-right: 1.2rem;
      }
    }
  }
  main {
    padding-top: $contextual-navigation-m + $header-height-mobile;
    --fontextralight: MonclerGothic;
    z-index: 2;
  }
}

body.has-ticker {
  &.page-template-immersiveparfumes {
    main {
      padding-top: $contextual-navigation-m + $header-height-mobile + $ticker-bar-m;
    }
  }
}
