.product-selection {
  min-height: calc(100vh - 5.6rem);
  display: flex;
  justify-content: center;
  padding: 0;

  &-content {
    padding-bottom: ($space-unit * 14);
    // padding-top: 10vh;
    //padding-top: 6vh;
    padding-top: 5.6rem;
    display: flex;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    &.center-alignment {
      .row {
        .wrapper-body-detail {
          justify-content: center;
        }
      }
    }
  }

  .product-selection__ctas {
    .button {
      &:first-child {
        width: calc(100% - 4rem);
      }
    }
  }
  .row {
    .wrapper-body-detail {
      // height: 100%;
      justify-content: space-between;
      min-height: min(100%, 76.8rem);
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      width: calc((100vw - 64px) / 12 * 5);
      padding: 0 0.2rem 0 0;
    }
  }
  &__top-half {
    // padding-bottom: 12vh;
    // padding-bottom: 8vh;
    padding-bottom: 8rem;
  }
  body.has-ticker & {
    min-height: calc(100vh - 8.8rem);
  }
  &.landing {
    opacity: 0;
    transform: translateY(32px);
  }
  .product-selection__actions-shipping {
    min-height: 4.8rem;
    align-items: flex-start;

    @include breakpoint(lg) {
      .product-selection__shipping-disclaimer {
        & + .wrapper-cta {
          flex-grow: 1;
        }
      }
    }

    .button {
      height: auto;
    }
  }

  .product-selection__modelinfo {
    position: absolute;
    bottom: $space-unit * 8;
    margin-bottom: 0;
  }

  .mbm-icons {
    margin-top: 2.2rem;
    margin-bottom: 0;
  }
}
.product-details-tab {
  .mbm-list {
    .palette-accordion {
      flex-wrap: wrap;
    }
  }
}
.product-selection-wrapper {
  .link-anchor {
    margin-top: 2.4rem;
  }
}
