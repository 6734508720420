.similar-product {
  // &-title {
  //   padding-top: 5rem;
  // }
  &-modal {
    height: 100vh;
    overflow-y: scroll;
    padding: 0 3.2rem 0 3.2rem;
    .modal__close.is__sticky {
      padding-right: 0;
    }
    .similar-product {
      &-container {
        padding: 0;
        &-title {
          font-size: 2rem;
          line-height: 2.4rem;
        }
      }
      &-carousel {
        &--controllers {
          padding-top: 3rem;
          &-arrows {
            display: none;
          }
          &-label {
            padding-bottom: 1.3rem;
          }
        }
        &--cards-wrapper {
          padding-right: 0;
          & > ul {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-content: center;
            gap: 0.4rem;
            width: 100%;
          }
        }
        .product-tile__info {
          padding-left: 0.5rem;
        }
      }
      &-slide {
        width: auto;
      }
      &-text-container {
        padding-left: 0;
      }
      &-title {
        font-size: 2rem;
        line-height: 2.4rem;
      }
    }
  }
}
