.order-detail-page{
  .heading {
    &__expected {
      .mobile-wrap {
        display: block;
      }
    }
  }

  .order-info-box-wrapper{
    padding: 0 122px;

    &.multi-payments {
      .order-info-box {
        max-width: 100%;
        min-width: 100%;
      }
    }

    .order-info-box {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }
  }

  $boxPadding: 40px 16px;

  .product-wrapper{

    .detail-shipment{
      padding: $boxPadding;
    }

    .bag-product{
      padding: $boxPadding;
    }
  }

  .price-wrapper,
  .total-wrapper{
    padding: 32px 16px;//$boxPadding;
  }
}
