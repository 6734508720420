.three-column-multimedia {
  position: relative;
  .btn {
    &--pause {
      position: absolute;
      bottom: 1.8rem;
      left: 1.8rem;
    }
    &--volume {
      position: absolute;
      bottom: 1.8rem;
      left: 5rem;
    }
  }
  &__region {
    display: flex;
    padding: 0;
  }
}

.multimedia-square {
  &__container {
    position: relative;
    list-style: none;
    flex: 1 1 0;
    padding: 0;
    .video-wrapper {
      height: 0;
      min-height: 0;
      padding-top: 100%;
    }
  }
  &__pic {
    display: block;
    position: relative;
    width: 100%;
    padding-top: 100%;
  }
  &__img {
    object-fit: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
