main {
  margin-bottom: 0 !important;
}
.sizeguide-enabled {
  &,
  body {
    overflow: hidden !important;
    overscroll-behavior-y: none;
    overscroll-behavior: none;
  }
}
