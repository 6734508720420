.search-container {
  display: flex;
  #searchResults {
    display: flex;
    flex-direction: column;
    background: $white;
  }
  .infinite-scroll-component__outerdiv {
    flex-grow: 1;
    background: $white;
    color: $black;
    .grid-container.white-bg {
      background: $white;
      color: $black;
    }
  }
  .absolute-wrapper {
    position: relative;
    flex-grow: 1;
  }
  .refine {
    position: initial;
    // top: 0;
    // z-index: 2;
    // @include animate(0.4);
    // > .container {
    //   padding-top: 0;
    //   padding-bottom: 0;
    //   height: $header-height-mobile;
    //   background: white;
    //   align-items: center;
    // }
  }

  .results-row {
    padding-top: initial;
    padding-bottom: initial;
    height: $header-height-mobile;
    background: white;
    align-items: center;
  }

  input[type='search'] {
    @extend %primary-s;
    border: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: initial;
    border-bottom: 1px solid $grey_line;
    padding-bottom: 0.6rem;
    width: 100%;
    outline: none;
    &:focus-visible {
      [data-whatintent='keyboard'] & {
        outline: $outline-width solid;
      }
    }
    &::placeholder {
      color: $black_secondary;
    }
  }
  .search-icons {
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    top: 0;
    [class^='icon'] {
      filter: invert(0) !important;
    }
    .search-query {
      color: $black_secondary;
      margin-right: ($space-unit * 3);
    }
  }
  .search-overlay {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 2;
    overflow: hidden;
    overflow-y: auto;
    will-change: top;
    @include animate(0.5, top);
    &.show-results {
      #searchResults {
        flex-grow: 1;
      }
    }
  }
  [role='search'] {
    position: relative;
    flex-grow: 1;
  }
  .search-box {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding: 2.4rem 0;
    .icon--chevron-left-large {
      margin-right: 0.6rem;
    }
  }
  .suggested-container {
    padding-top: 1.6rem;
    padding-bottom: 4rem;
    z-index: 3;
    .suggested-title {
      @extend %primary-xs-u;
      font-weight: bold;
      padding-bottom: $space-unit * 3;
    }
    .suggested-list {
      li {
        @extend %primary-s;
        text-transform: capitalize;
        &:not(:last-child) {
          margin-bottom: $space-unit * 2;
        }
        a {
          text-decoration: none;
          @include hover(0.2);
        }
      }
    }
  }
  .no-results-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: white;
    @include animate;
    .collection-submenu {
      -ms-overflow-style: none;
      scrollbar-width: none;
      overflow-x: auto;
      justify-content: initial;
      padding-top: 0.4rem;
      padding-bottom: 2.4rem;
      height: auto;
      flex-shrink: 1;
      .collection-submenu__item {
        flex-shrink: 0;
        flex-basis: 100%;
        position: relative;
        &:before {
          content: '';
          display: block;
          width: 100%;
          padding-bottom: 150%; // Aspect ratio 2:3
        }
      }
      @media screen and (max-width: 767px) {
        .collection-submenu__item {
          max-width: 28rem;
          img {
            margin-bottom: 7.1rem;
          }
        }
      }
      .collection-card {
        opacity: 1;
        position: absolute;
        .collection-card__inner-content {
          max-width: none;
          padding-bottom: 3.2rem;
          .collection-card__title {
            padding-bottom: 0.8rem;
            height: auto;
          }
          .button {
            padding-bottom: 0;
          }
        }
      }
    }
    .products-carousel__arrows {
      padding: 0 0.8rem;
      .swiper-button-disabled {
        opacity: 0.35;
      }
    }
    .react-tabs__tab-panel--selected {
      min-height: 50vw;
    }
  }
  .no-result-bar {
    align-items: flex-end;
    height: 6rem;
    width: 100%;
    padding-bottom: ($space-unit * 4);
  }
  .no-result-content {
    overflow: hidden;
    flex-grow: 1;
    padding-bottom: 2.4rem;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    h2 {
      @extend %primary-m-u;
      margin-bottom: 1.6rem;
    }
    p {
      @extend %primary-s;
    }
    .react-tabs__tab-list {
      padding: 2.4rem 0;
      &::before {
        display: none;
      }
    }
    .tabs [role='tab'] {
      font-size: 14px;
      line-height: 16px;
      padding: 0.9rem 1.6rem;
      border-bottom: 1px solid $grey_line;
      &[aria-selected='true']::after {
        bottom: -1px;
      }
    }
    .container {
      padding-right: 0;
      padding-left: 0;

      .swiper-initialized {
        padding: 0 1.6rem;

        .swiper-slide {
          max-width: 28rem;
        }
      }
      .products-carousel__arrows {
        padding: 0 1.6rem;
      }
    }
  }
  .assistance {
    @extend %primary-s;
    padding-bottom: ($space-unit * 4);
  }
  .assistant-links {
    display: flex;
    justify-content: center;
    > a,
    div a {
      @extend %primary-s;
      text-decoration: initial;
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-right: ($space-unit * 6);
      }
    }
    div {
      margin-right: ($space-unit * 6);
    }
  }
  .need-assistance {
    background: $gradient-separator;
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
    text-align: center;
  }
  .reset-button {
    display: flex;
    text-transform: uppercase;
  }
  .search-suggestions {
    @extend %primary-s;
    button {
      color: $black_inactive;
    }
    mark {
      background-color: initial;
      color: $black;
    }
  }
  .text-content-noresults {
    text-align: center;
    padding: 1.6rem 1.6rem 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  // .product-tile .product-tag-wrapper {
  //   color: $white_secondary;
  // }
  .search-input-container {
    background: $white;
    position: sticky;
    top: 0;
    z-index: 2;
    will-change: top;
    @include animate(0.1, top);

    .container.refine__container {
      background-color: $white;
    }
  }
  .stick-top .search-input-container {
    top: -5.6rem;
  }
  .search-as-you-type {
    overflow: hidden;
    transition: height 0.5s 0s cubic-bezier(0.25, 1, 0.5, 1);
  }
  &.search-grid-bigger {
    .grid-container {
      grid-template-columns: repeat(6, 1fr);
    }
  }
}
.has-ticker {
  .search-container {
    .search-overlay {
      top: $ticker-bar;
    }
    .search-refine-expanded {
      top: 0;
    }
  }
}
