.checkoutpage {
  .adyen-checkout__input {
    @extend %primary-s; // minimum input size is s, should never be xs
    border-radius: 0;
    border: $space-line solid $grey_input;
    border-bottom: $space-line solid $grey_bottom;
    color: $black;
    padding: 0.9rem 0.7rem;
    background-color: $grey_input;
    &--focus,
    &:focus,
    &:active {
      box-shadow: none;
      outline: none;
    }
  }

  #component-container {
    .adyen-checkout__button.adyen-checkout__button--pay {
      display: none;
    }
  }

  .adyen-checkout__button.adyen-checkout__button--pay {
    border-radius: 0;
  }
  .adyen-checkout__label {
    .adyen-checkout__input {
      box-shadow: none;
      outline: none;
      border-color: transparent;
    }
    &--focused {
      .adyen-checkout__label__text {
        @extend %primary-xs;
        color: $black;
      }
      .adyen-checkout__input {
        border: $space-line solid $grey_input;
        border-bottom-color: $outline-color;
      }
    }
    .adyen-checkout__label__text {
      @extend %primary-xs;
      &--error {
        color: $black;
      }
    }

    .adyen-checkout__input--error,
    .adyen-checkout__input--invalid {
      border-bottom-color: $status_error;
    }
  }
  // OE-11530 Input fields increase A11y - added a general border bottom to input fields
  // .adyen-checkout__card__holderName__input {
  // border: $space-line solid $grey_input;
  // }
  .adyen-checkout__card-input {
    margin-bottom: ($space-unit * 5);
  }

  .adyen-checkout__field--storedCard {
    margin-left: 0 !important; // since date is hidden there should be no margin
    &:not(.adyen-checkout__field__cvc) {
      display: none; // hide expiry date
    }
  }
  .adyen-checkout__card__cardNumber__brandIcon,
  .adyen-checkout__icon,
  .adyen-checkout__spinner__wrapper {
    filter: grayscale(100%);
  }
  .adyen-checkout__field--error {
    .adyen-checkout__label.adyen-checkout__label--filled {
      .adyen-checkout__error-text {
        &::before {
          content: '';
          @include sprite('alert-red');
          position: relative;
          width: 1rem;
          height: 1rem;
          color: $status_error;
          margin-right: $space-unit;
          display: block;
          top: 0.1rem;
        }
      }
    }
  }

  .adyen-checkout__button--pay {
    @extend .button;
    @extend .button--primary;
    height: $button-height-desktop;
    padding: 0 ($space-unit * 4);
    width: 100%;
  }
  .adyen-checkout__amazonpay__button--changeAddress {
    @extend .button;
    @extend .button--secondary;
    height: $button-height-desktop;
    padding: 0 ($space-unit * 4);
    margin-top: $space-unit * 4;
    width: 100%;
  }

  .adyen-checkout__store-details {
    margin-top: 1.6rem;
  }

  .adyen-checkout__checkbox {
    display: flex;
    justify-content: flex-start;
    &__input {
      color: black;
      width: 1.4rem;
      height: 1.4rem;
      margin: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      border: 1px solid black;
      cursor: pointer;
      flex-shrink: 0;
      border-radius: 0;
      position: relative;
      opacity: 1;
      &:focus {
        outline-offset: 0.2rem;
      }
      &:checked::after {
        position: relative;
        display: block;
        height: 0.4rem;
        width: 0.6rem;
        border-left: 1px solid;
        border-bottom: 1px solid;
        transform: rotate(-45deg);
        left: 0.3rem;
        top: 0.3rem;
        box-sizing: border-box;
        content: '';
      }
      + .adyen-checkout__checkbox__label {
        &::before {
          content: '';
          display: inline-block;
          position: absolute;
          width: 2.4rem;
          height: 2.4rem;
          left: -0.5rem;
          top: -0.5rem;
          transition: all 0.5s 0s cubic-bezier(0.25, 1, 0.5, 1);
          transform: initial;
          border: initial;
          z-index: 0;
        }
        &::after {
          content: initial;
        }
      }
    }
    &__label {
      @extend %primary-xs;
      display: inline-block;
      padding-left: 1.1rem;
      padding-bottom: $space-unit;
      cursor: pointer;
    }
  }
}

#componentaction-container {
  width: 100%;
  .adyen-checkout__threeds2__challenge {
    width: 100%;
    min-height: 452px;
    iframe {
      width: 100%;
      overflow: hidden;
      height: 452px;
    }
  }
  .adyen-checkout__qr-loader {
    text-align: center;
    img {
      display: inline;
    }
  }
}
#threedscontainer {
  width: 100%;
  .adyen-checkout__threeds2__challenge {
    width: 100%;
    min-height: 452px;
    iframe {
      width: 100%;
      overflow: hidden;
      height: 452px;
    }
  }
}
