.breadcrumb {
  padding-top: ($space-unit * 8);
  &.listing:not(.hero-breadcrumb) {
    padding-bottom: 1.2rem;
    li {
      &:not(:last-child) {
        display: none;
      }
      &:last-child {
        .breadcrumb__item {
          display: inline-flex;
          align-items: center;
          &::before {
            content: '';
            margin-right: ($space-unit * 3);
            @include fixedSprite('arrow-right-breadcrumbs');
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.hero-breadcrumb {
  height: 7.4rem;
}
