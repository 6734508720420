.curator-bio {
  .img-mobile {
    display: none;
  }
  .container-bio-down {
    padding: 6rem 0 10rem 0;
    text-align: center;
    color: white;
    .bio-message {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      row-gap: 1.6rem;
      .bio-title {
        font-size: 24px;
        line-height: 54px;
        text-transform: uppercase;
      }
      .bio-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  .curator-bio-container {
    .bio-img {
      img {
        width: 100%;
      }
    }
  }
}
