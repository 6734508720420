input[type='checkbox'][role='switch'],
.checkbox input[type='checkbox'][role='switch'] {
  appearance: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  height: 30px;
  width: 50px;
  border-radius: 100px;
  border: 0;
  background-color: #c8c8c8;
  vertical-align: middle;
  transition: background-color 0.2s;
  cursor: pointer;
  &:disabled {
    cursor: initial;
  }
}
input[type='checkbox'][role='switch']:after,
.checkbox input[type='checkbox'][role='switch']:after {
  content: '';
  display: block;
  width: 22px;
  min-width: 22px;
  max-width: 22px;
  height: 22px;
  min-height: 22px;
  max-height: 22px;
  border-radius: 45px;
  border: 0;
  margin: 4px;
  background-color: $white;
  transition: margin 0.2s;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
}

input[type='checkbox'][role='switch']:checked:after,
.checkbox input[type='checkbox'][role='switch']:checked:after {
  margin-left: 24px;
}
input[type='checkbox'][role='switch']:checked,
.checkbox input[type='checkbox'][role='switch']:checked {
  background-color: $black;
}

.empty-state-element {
  margin: auto;
  .button {
    width: 40%;
  }
}

.not-clickable-section {
  cursor: initial;

  input#personalinfo,
  input#trackingbrowsing {
    opacity: $input-disabled-opacity;
  }
}
