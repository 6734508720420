.promo-50-50 {
  &__eyebrows {
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin-bottom: 2.4rem;
  }
  &__not-padded {
    .promo-50-50 {
      &__text-wrapper {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}
