$boxPadding: 40px 20px;

.order-history-info-box,
.order-history-info-listing {
  padding: $boxPadding;
}

.order-history-info-box{
  flex-direction: column;
  
  &-labels{
    flex-direction: column;
    gap: 24px;
    margin-bottom: 32px;
    text-align: center;
  }

  .wrap-mobile {
    display: block;
  }
}