.sort {
  width: 100%;
  .sort__list--wrapper {
    height: auto;
    position: static;
  }
  .sort__list {
    position: relative;
    right: initial;
    left: initial;
    padding: initial;
    background-color: initial;
    padding-top: 0.8rem;
    padding-bottom: 2.8rem;
    display: none;
  }
  .sort__option {
    padding-top: $space-unit;
    padding-bottom: $space-unit;
    margin-bottom: $space-unit;
    width: 100%;
    &:last-of-type {
      margin-bottom: 0;
    }

    &:focus {
      @include focus-underline;
    }
  }
  .sort__button {
    &[aria-expanded='true'] + .sort__list--wrapper .sort__list {
      display: flex;
      @include border('bottom');
    }

    span {
      text-transform: uppercase;
    }
  }
}
