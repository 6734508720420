.component_product_range_carousel {
  &__pagination {
    .bullet {
      margin: 0 0.5rem;
    }
  }
  .swiper-container {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}
