//spacings
$space-unit: 0.4rem;
$space-line: 1px;
$header-height-desktop: 5.6rem;
$header-height-mobile: 6rem;
$footer-clientservice-height-desktop: 31.6rem; // arbitrary height, could vary depending on content
$footer-clientservice-height-mobile: 57rem; // arbitrary height, could vary depending on content
$ticker-bar: 3.2rem;
$ticker-bar-m: 3.2rem;
$sticky-tray-bar: 5.8rem;
$sticky-tray-bar-desktop: 4.5rem;
$contextual-navigation: 4rem;
$contextual-navigation-m: 5rem;
$gradient-height: 12rem;
$spacing-multiplier: (0, 1, 2, 3, 4, 5, 6, 7, 8, 10, 12, 16, 20, 30, 40);
$refine-options-height: 5.6rem;
