#mbmNotifymeModal {
  .modal__body {
    display: flex;
    justify-content: center;
    padding-left: 13rem;
    padding-right: 13rem;
  }
}
.mbm-notify-text {
  padding: 0 6rem;
}

.product-mbm-notify {
  max-width: 600px;
  padding-left: 0;
  padding-right: 0;
  min-width: 550px;

  .form-group {
    width: 32rem;
  }
  .button.button--primary {
    width: 22.6rem;
  }
}
