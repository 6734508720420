.component_product_intro {
  &__image-wrapper {
    height: inherit;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .component_product_intro__image {
      max-height: 100%;
      width: auto;
    }
  }
  .relative-col {
    position: relative;
  }
  &__content {
    padding-bottom: 0;
    position: absolute;
    text-align: left;
    .collection {
      padding-bottom: 4.5rem;
    }
  }
}
