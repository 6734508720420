.bookanappointment {
  &__body {
    grid-template-columns: 100%;
  }
  &__left {
    display: none;
  }
  &__right {
    .dialog__title {
      margin-left: -1.6rem;
      padding-left: 1.6rem;
      margin-right: -1.6rem;
      padding-right: 1.6rem;
      padding-bottom: 1.6rem;
      @include border('bottom');
    }
    // .product-reserve__detail__selected-store {
    //   margin-top: 3.2rem;
    // }
  }
}
.container-body-bookanappointiment {
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 -1.6rem;
  padding: 0 1.6rem;
}
.modal {
  &.mbm-modal {
    .bookanappointment__right {
      overflow-y: hidden;
    }
  }
}
