// PDP MODAL - CONTACT US

.contactus-modal {
  &.modal {
    padding: 0 11.6rem 9.9rem;
  }
  .contact-details {
    &__contacts-contact {
      &:last-child {
        padding-bottom: 6rem;
      }
    }
  }
}
.drawers {
  .contact-us {
    .contact-details__contacts-contact {
      row-gap: 0;
      div:nth-child(2) {
        padding-left: 2rem;
      }
    }
  }
}
