.rich-video {
  &--portrait {
    .grid-container & {
      min-height: 94.4rem;
    }
  }
  &__block {
    &.light {
      border: 2rem solid $white;
    }
    &.dark {
      border: 2rem solid $black;
    }
  }
  &__description {
    max-width: 59rem;
  }
}
