.gc-config-modal{

  .modal__body{
    .tooltip{
      .tooltip__box {
        left: -86px;
        right: auto;
      }
      .tooltip__box::before {
        left: 98px;
      }
    }

    .modal__separator{
      margin-top: 14px;
    }
  }
}

.tooltip.gc-tooltip {
  .tooltip__box {
    left: 50%;
    right: unset;
    transform: translateX(-50%);
    &::before {
      left: 50%;
      right: 0.8rem;
    }
  }
}

.gc-error-modal {
  padding-inline: 2.5rem;
}