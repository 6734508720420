.bag-product {
  &__content {
    > .bag-product__preorderinfo {
      &.is-pfas {
        position: relative;
      }
      display: block;
      position: absolute;
      bottom: 0;
    }
  }

  > .row > .bag-product__preorderinfo {
    display: none;
  }
  &__name-price {
    &__message {
      margin-top: $space-unit * 4;
    }
  }
  .selector-size-inline {
    min-height: 1.6rem;
  }
}
