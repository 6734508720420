.bag-product {
  &__details {
    margin-bottom: 1.6rem;
  }
  &__actions {
    position: initial;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
    .cta:not(:last-child) {
      margin-bottom: 0.8rem;
    }
  }
}
