#accountsettings,
#personalinfo_rpy {
  padding-block: 40px 24px;
  padding-inline: 24px;
}

#personalinfo,
#accountsettings,
#personalinfo_rpy {
  position: relative;
  dd.birth {
    text-transform: capitalize;
  }
  .personalinfo-edit {
    position: absolute;
    top: 0;
    right: -#{$space-unit};
    margin: 0;
    padding: $space-unit;
  }

  .personalinfo-edit-rpy {
    position: absolute;
    bottom: 24px;
    right: 48px;
    margin: 0;
    padding: $space-unit;
    display: flex;
    align-items: center;

    button {
      font-size: 16px;
      text-transform: uppercase;
    }
  }

  .personalinfo-edit-inline-rpy {
    padding: $space-unit;
    display: flex;
    align-items: center;
    position: relative;

    button {
      font-weight: 400;
      text-transform: uppercase;
    }
  }

  .form-group__label {
    height: 1.8rem;
  }
  .form-control,
  .form-select {
    margin-bottom: ($space-unit * 3);
  }
  .register_form__label {
    margin-bottom: 0;
  }
  .email-slot {
    position: relative;
    > button {
      position: absolute;
      @include z;
      bottom: 1.6rem;
      right: 0;
    }
    .form-group {
      margin-top: 1.2rem;
      margin-bottom: 1.6rem;
    }
    .form-control {
      input {
        opacity: 1;
        background-color: initial;
        border: none;
        padding: 0;
        height: auto;
        @extend %primary-s;
      }
    }
  }
  .edit-email-form {
    margin-top: $space-unit;
  }
}

#addresses,
#payments {
  .accordion__panel {
    p:not(.default-item) {
      @extend %primary-xs;
      text-transform: capitalize;
    }
  }

  .swiper-container {
    position: static;
    overflow: visible;

    .carousel-controls {
      display: flex;
      justify-content: flex-end;
      gap: 24px;
      padding-bottom: 0.5rem;
      position: absolute;
      top: 40px;
      left: calc(100% - 90px);

      &.d-none {
        display: none;
      }

      i {
        width: 2.4rem;
        height: 2.4rem;

        // &.icon--arrow-left-rpy {
        //   @include fixedSprite('arrow-left-black');
        // }

        // &.icon--arrow-right-rpy {
        //   @include fixedSprite('arrow-left-black');
        //   transform: scaleX(-1);
        // }

        &[class*='icon--arrow-left-rpy'] {
          @include fixedSprite('arrow-left-black');
          transform: scale(1.5);
        }

        &[class*='icon--arrow-right-rpy'] {
          @include fixedSprite('arrow-left-black');
          transform: scale(1.5) scaleX(-1);
        }

        &.swiper-button-disabled {
          opacity: 0.4;
        }
      }
    }

    .swiper-pagination-bullet {
      border-radius: 0;
    }

    .swiper-wrapper {
      padding-bottom: 40px;
      //justify-content: center;
    }

    .address-payment-slide-rpy {
      border: 1px solid $grey_line;
      padding: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 40px;
      background-color: $white;

      .default-item {
        text-transform: uppercase;
        font-size: 14px;

        &.no-visible {
          opacity: 0;
        }
      }

      .details {
        display: flex;
        flex-direction: column;
        gap: 12px;
        font-size: 14px;
        text-transform: capitalize;

        .name {
          text-transform: uppercase;
          font-size: 24px;
          line-height: 28px;
        }
      }

      .item-actions {
        justify-content: space-between;
        padding: 0 24px;

        .cta {
          margin: 0;
          text-transform: uppercase;
        }
      }
    }

    .swiper-pagination-bullets {
      position: relative;
      z-index: 3;
      width: 100%;
      height: 13px;
      margin-top: -11px;
      text-align: center;
      &:before {
        content: '';
        width: 100%;
        height: 12rem;
        position: absolute;
        bottom: -1.8rem;
        left: 0;
        pointer-events: none;
      }
      .swiper-pagination-bullet {
        width: 2.8rem;
        height: 0.2rem;
        background: rgba(0, 0, 0, 0.35);
        display: inline-flex;
        margin: 0 0.4rem;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 4.4rem;
        }
        &.swiper-pagination-bullet-active {
          background-color: $black;
        }
      }
    }
  }

  .empty-state-element {
    .button {
      margin-top: 40px;
    }
  }
}
#recsizes {
  .sizes-table {
    margin-top: $space-unit * 6;
  }
  .sizes-table__headings,
  .sizes-table__values {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: $space-unit;
  }
  .sizes-table__headings {
    margin-bottom: $space-unit;
    li {
      @extend %primary-xs;
    }
  }
  .sizes-table__values {
    li {
      @extend %primary-s;
    }
  }
}
#mybotique {
  .mybotique-content {
    position: relative;
  }
  .mybotique-getdirections {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.remove-item-modal-account {
  .remove-item {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    .item-actions {
      padding-bottom: 0;
    }
  }
}

#privacysettings {
  padding: 80px 0 40px;

  h2.title {
    margin-bottom: 40px;
  }

  .checkbox-wrapper {
    .checkbox {
      &__field {
        font-size: medium;

        & input + .custom-label-toggle {
          margin-top: 8px;
          margin-left: 10px;
        }

        .self-top {
          align-self: flex-start;
        }

        .custom-label-toggle {
          padding-top: 0;

          .alt-label {
            line-height: 20px;
          }

          br + .form-group__label {
            margin-top: 16px;
          }
        }

        & input:disabled + label {
          color: $black;
        }

        .form-group__label {
          font-size: medium;
          line-height: 20px;

          &.wrap-pretty {
            text-wrap: pretty;
          }

          &::after {
            scale: 1.4;
          }
        }
      }
    }

    .newsletter-checkbox-description,
    .register_form__subtitle,
    .preferences-marketing,
    .info-text {
      font-size: medium;
      line-height: 20px;
    }

    .register_form__subtitle {
      margin-block: 32px;
    }

    .newsletter-checkbox-description {
      margin-top: 10px;
      padding-left: 20px;
    }

    .newsletter-flag {
      &.registration {
        margin-top: 32px;
      }

      .text-normal {
        padding-left: 22px;
        margin-top: 24px;
        margin-bottom: 32px;
        flex-direction: column;
        row-gap: 24px;
      }
    }

    .preferences-marketing {
      .radio-wrapper {
        margin-top: 24px;
      }
    }
  }
}

#countrylanguage {
  padding: 0;
}

#logout {
  padding-top: 40px;
}
