@each $name, $uri in $sprites {
  .icon--#{$name} {
    @include fixedSprite($name);
  }
}
// override gpay
.icon--payment-paywithgoogle {
  height: 2rem;
  width: 3.8rem;
  background-size: 140%;
}
[class^='icon--'] {
  border: none;
  min-width: initial;
  padding: 0;
  // height: auto;
  > span {
    display: none;
  }
}
.icon--grenoble-lightweight,
.icon--grenoble-waterrepellent,
.icon--grenoble-waterproof,
.icon--grenoble-windproof,
.icon--grenoble-insulated,
.icon--grenoble-breathable,
.icon--grenoble-packable,
.icon--grenoble-performancecomfortfit {
  width: 4.6rem;
  height: 4.6rem;
}

.icon--small {
  width: 2rem;
  height: 2rem;
}

.vto-icon {
  &::before {
    content: '';
    @include fixedSprite('vto');
    position: absolute;
    top: -1px;
    left: 4px;
  }
}
