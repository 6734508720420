.expresspayment-modal {
  .product-quantity {
    margin-top: 0.4rem;
  }
  .product-box__image {
    width: 67px;
  }
  .product-box__content-aside {
    position: absolute;
    bottom: -0.4rem;
    right: 0;
  }
  .product-box__content {
    position: relative;
  }
  &.cart {
    .dialog__title {
      text-align: left;
      padding-bottom: 1rem;
    }
    .modal__header {
      padding-top: 3.6rem;
    }
    .modal__close {
      padding-top: 1.3rem;
    }
    .button--arrow-back {
      top: 0;
      padding-top: 1.3rem;
    }
  }
  .expresspayment-modal__intro {
    margin-bottom: $space-unit * 2;
  }
  .dialog__title {
    text-align: center;
    padding-bottom: 2.2rem;
  }
  .modal__header {
    padding-top: 2.6rem;
  }
  .modal__close {
    padding-top: 2.6rem;
    padding-right: 3.2rem;
  }
  .button--arrow-back {
    position: absolute;
    top: 2.6rem;
    .icon--chevron-left {
      height: 2.4rem;
      width: 2.4rem;
    }
  }
  .button {
    display: block;
    &:not(:last-child) {
      margin-bottom: $space-unit * 3;
    }
    &.dw-apple-pay-button {
      border-radius: 0;
      -webkit-appearance: none;
      margin-top: 0;
      margin-bottom: $space-unit * 3 !important;
      &:hover {
        border-radius: 0;
      }
    }
  }
  .button-payment-container {
    height: 3.6rem;
    // border: $space-line solid get($black, default);
    overflow: hidden;
    margin-top: 0;
    margin-bottom: $space-unit * 3;
    .adyen-checkout__spinner__wrapper {
      display: none;
    }
    .adyen-checkout__paypal {
      margin-left: -0.2rem;
      margin-right: -0.2rem;
      margin-top: -0.1rem;
    }
  }

  #container-paywithgoogle {
    display: none;
  }

  .gc-cart-disclaimer{
    opacity: 0.55;
  }
}

.product-box__list {
  max-height: 350px;
  margin-right: -8px;
  padding-right: 8px;
  margin-bottom: 3.2rem;
  @include scrollbar;
}

.product-box {
  display: flex;
  padding: 1.2rem;
  border: 1px solid $black_inactive;
  column-gap: 1.2rem;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  &__image {
    width: 56px;
    flex-shrink: 0;
  }
  &__name {
    @extend %primary-s;
  }
  &__price {
    display: block;
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.product-box__content {
  flex: 1;
  flex-direction: row;
  height: fit-content;
  .product-box__content-main {
    flex: 1;
    align-items: end;
  }
  .product-box__content-aside {
    display: flex;
    align-items: end;
    padding-left: 1.2rem;
    .cta {
      margin-bottom: 0;
    }
  }
}

.express-payments-hk-tax-code {
  .invoice-block {
    margin-bottom: 2.4rem;
    position: relative;
    .tooltip {
      position: absolute;
      left: 100px;
      top: -3px;
      z-index: 4;
    }
  }
}