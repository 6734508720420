.more-curators {
  &__title {
    margin-bottom: max(5.6rem, 4.3vw);
  }
  .component-curator-card {
    .curators-card {
      .image-wrapper {
        width: calc(100% - 8rem);
        height: calc(100% - 8rem);
      }
      .curators-card__inner-container {
        padding: 8.8rem;
        .date {
          font-size: 1.6rem;
          line-height: 2rem;
        }
        .curator-card__title {
          font-size: 9.2rem;
          line-height: 8.4rem;
          margin: 1.6rem 0;
        }
        .curator-card__quote {
          font-size: 1.6rem;
          line-height: 2rem;
          margin-bottom: 3.4rem;
        }
      }
    }
  }
}
