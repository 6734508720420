.wishlist {
  &__products-listing {
    grid-template-columns: repeat(2, 1fr);
  }

  &__single-product {
    width: calc(50% - $space-unit * 2);
    &__product-info {
      &__to-show {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: $space-unit;
        .wishlist__single-product__product-info__name {
          margin-bottom: 0;
        }
        > p {
          flex-shrink: 0;
          margin-bottom: 0;
        }
      }
    }
    &.type-mbm {
      img.wishlist__single-product__image {
        padding: 2rem 3rem 6rem;
      }
    }
  }
  &--empty {
    &__button-container {
      width: 32rem;
      padding: 0;
    }
  }
}
