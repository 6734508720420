// COLOR SELECTOR USED IN: PDP, Tile, Reserve in boutique

.selector-color-link {
  position: relative;
  width: 100%;
  color: $black;
  height: 4.2rem;
  display: flex;
  align-items: center;

  .selector__more-colors {
    font-size: 1rem;
    line-height: 1.2rem;
    color: $white;
  }

  fieldset.selector__menu {
    margin: 0;
    &:before {
      display: none;
    }
  }
  .selector__menu {
    display: flex;
    align-items: center;
    position: relative;

    .label {
      @extend %primary-xs-u;
      @extend .visually-hidden;
    }

    ul {
      @include reset-list;
      display: flex;
    }

    &__item {
      @extend %primary-xs;
      display: flex;
      align-items: center;

      &.is-disabled {
        color: $black_secondary;
        .stroke {
          content: '';
          position: absolute;
          width: 2rem;
          height: 0.1rem;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
          background-color: #fff;
          mix-blend-mode: difference;
        }
      }

      &.selected {
        .swatch {
          outline: 0.1rem solid $white;
          outline-offset: 0.3rem;
        }
      }

      input {
        -webkit-appearance: initial;
        margin: initial;
        border: initial;
        outline: none;
        width: 0;
        height: 0;
      }

      .swatch {
        @include touchtarget-area();
        margin-right: $swatch-margin-desktop;
        background-size: contain;
      }

      &:focus {
        outline: none;

        .swatch {
          @include touchtarget-focus($outline-color-inverted);
        }
      }

      &__label {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(100%);
        color: $white;
        text-decoration: none;
        white-space: nowrap;
        padding-left: 0.4em;
        line-height: $swatch-size-desktop;
        &.has-hidden-colors {
          padding-left: 1.2em;
        }
      }
    }

    &.inverted {
      .swatch {
        border: 1px solid $white;
      }
    }
  }
}
