.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  /* Fix of Webkit flickering */
  z-index: 1;

  /* a11y */
  .swiper-notification {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    z-index: -1000;
  }

  button.swiper-button-disabled {
    display: none;
  }

  &.swiper-container--gallery {
    .swiper-slide:not(.swiper-slide-active) {
      visibility: hidden;
    }
    .carousel-gallery__arrow-container {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 3.2rem;
      z-index: 1;

      &.disabled {
        display: none;
      }
    }

    .carousel-gallery__arrow {
      @include fixedSprite('arrow-right-white-l');
      transform: rotate(180deg);
      &--arrow-right {
        @include fixedSprite('arrow-right-white-l');
        transform: rotate(0deg);
      }
    }
  }
}
.swiper-container-vertical > .swiper-wrapper {
  flex-direction: column;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}
.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0, 0, 0);
}
.swiper-container-multirow > .swiper-wrapper {
  flex-wrap: wrap;
}
.swiper-container-multirow-column > .swiper-wrapper {
  flex-wrap: wrap;
  flex-direction: column;
}
.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto;
}
.swiper-container-pointer-events {
  touch-action: pan-y;
  &.swiper-container-vertical {
    touch-action: pan-x;
  }
}
.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  // height: 100%;
  position: relative;
  transition-property: transform;
}

.swiper-pagination--bars {
  position: static;
  display: flex;
  justify-content: center;
  margin: ($space-unit * 4) 0 $space-unit;
  .swiper-pagination-bullet {
    height: 0.2rem;
    width: 6.6rem;
    border-radius: 0;
    background: $grey_line;
    opacity: 1;
    transition: all 0.4s;
    cursor: pointer;
    margin: 0 0.2rem;
    position: relative;
    @include animate(0.3);

    [data-whatintent='keyboard'] & {
      &:focus {
        outline: none;

        &::before {
          content: '';
          position: absolute;
          top: -0.3rem;
          bottom: -0.3rem;
          right: -0.3rem;
          left: -0.3rem;
          border: $outline-width solid $outline-color;
          outline: $outline-width solid $outline-color-inverted;
        }
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: -0.5rem;
      bottom: -0.5rem;
      right: 0;
      z-index: 2;
    }

    &.swiper-pagination-bullet-active {
      background: $black;
    }
  }
}

.swiper-vertical {
  .swiper-wrapper {
    flex-direction: column;
  }
}
