.sizechart {
  &.sizechart--measure {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}

.sizechart__mannequin {
  margin-bottom: ($space-unit * 6);
}

.sizechart__filters {
  flex-direction: column;
  align-items: flex-start;
  justify-items: flex-start;
}
