.store-info {
  margin-right: -3.2rem;
  grid-template-columns: auto 3fr;

  dd {
    &.hasDOSService-disclaimer {
      margin-right: 2.8rem;
    }
  }
}
