%strike-through {
  position: relative;
  &::before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #737373;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
.size-selection {
  --selector-item-height: 4.4rem;
  #size-selector-container {
    max-height: calc((var(--selector-item-height) * 7) - 1px);
    padding: 0;
  }
  .fitting-caption {
    font-size: 1.2rem;
    color: #737373;
    padding: 0.8rem 0;
    border-bottom: 1px solid $grey_line;
    strong {
      font-weight: normal;
      text-transform: uppercase;
    }
  }
  .fitting-title {
    text-transform: uppercase;
  }
  .selector__menu--size {
    margin: 0 0 0.4rem 0;
    max-height: 30.2rem;
    ::before {
      content: none;
    }
  }
  .size-selector-line {
    display: flex;
    justify-content: space-between;
    padding: 0.6rem 0 0.5rem;
    align-items: center;
    &.disabled {
      .left-side {
        color: #737373;
        @extend %strike-through;
      }
    }
    &:not(:last-of-type) {
      border-bottom: 1px solid $grey_line;
    }
    .selector-size-item {
      font-weight: 600;
      font-size: 1.2rem;
      line-height: normal;
      padding: 0.4rem 0;
    }
    .left-side {
      display: flex;
    }
    .selector-size-item__label-text {
      display: inline-block;
      &.with-extra-label {
        min-width: var(--size-label-length);
      }
    }
    input {
      @include hidden-input();
    }
    .selector-size-item-notify-me {
      line-height: 0.9rem;
    }
    &:focus-visible {
      outline-offset: 0.2rem;
    }
  }
  .btn-label {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    i {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
  //This selector is only there for higher specificity
  &.overlay-modal:not(.mobile-visible):not(.overlay-visible) {
    background-color: $black_secondary;
  }
}
.button-modal-container {
  margin: 1rem 0;
  .size-selection-button {
    padding: 1.1rem 1.6rem;
  }
  button:hover {
    border: 1px solid $grey_line;
  }
}
.size-selection-button,
.size-selection-button[role='button'] {
  width: 100%;
  min-height: 4rem;
  padding: 1rem 1.6rem;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid $grey_line;
  &,
  & span {
    font-size: 1.2rem;
  }
  .chevron {
    margin-left: 1rem;
  }
  .alert {
    margin: 0 1rem 0 0;
  }
  &.disabled .size-selection-button-label {
    color: #737373;
    @extend %strike-through;
  }
  span.size-selector-line-label {
    text-transform: none;
  }
  .size-limited-availability {
    position: absolute;
    right: 1.6rem;
  }
  &.blocked {
    background-color: $grey_input;
    display: flex;
    justify-content: space-between;
    border: none;
    > div {
      display: flex;
      align-items: center;
    }
    > button {
      text-transform: uppercase;
    }
  }
}

.size-selector-line-label {
  color: $black_secondary;
  margin-left: 1.8rem;
  font-weight: normal;
  & + .size-selector-line-label {
    margin-left: 1rem;
  }
}
