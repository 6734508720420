.perfume-product-highlight-slide {
  display: flex;

  &.perfume-product-highlight-slide--inverted {
    flex-direction: row-reverse;
  }

  &__img-column {
    width: 50%;
    display: block;
    overflow: hidden;
    position: relative;
    img {
      left: 0;
      top: 0;
      width: 100%;
      position: absolute;
      max-width: 100vw;
      object-fit: cover;
      right: 0;
      bottom: 0;
      height: 100%;
    }
  }

  &__btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .editorial-multi-text__cta:nth-child(2) {
      margin-left: 3.2rem;
    }
  }

  &__text-column {
    width: 50%;
    padding: 4.6rem 1.6rem 3.2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__body {
    @extend %primary-xs;
    width: 45.6rem;
    margin: 5.2rem auto 0;
    max-width: 100%;
    height: 64px;
    overflow: hidden;
  }

  &__title {
    display: block;
    text-transform: uppercase;
    padding-bottom: ($space-unit * 3);
  }

  &__cta {
    .button {
      color: $black;
    }
    margin-bottom: ($space-unit * 8);
  }

  &__product-image-wrapper {
    position: relative;
    display: block;
    overflow: hidden;
    width: 45.6rem;
    .perfume-product-highlight-slide__product-image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__caption {
    text-align: center;
    margin-top: 1.6rem;
  }

  .swiper-slide-active {
    transition-delay: 400ms;
    &.swiper-slide--custom-active {
      transition: transform 0.4s 0.4s $pseudoOutQuart;
      transform: translate3d(0, 0, 0);
    }
  }
}

.swiper-container .perfume-product-highlight-slide:not(.swiper-slide-active) {
  visibility: hidden;
}
