.product-tile {
  &:not(.in-plp) &__name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: initial;
  }
  &__info {
    padding-top: ($space-unit * 3);
  }
  &__members-unlock {
    top: ($space-unit * 3);
  }
  &:not(.product-tile__swatch-change) {
    .inverted {
      .swatch {
        &--white {
          outline: 1px solid rgba(0, 0, 0, 0.55);
        }
      }
    }
  }
  &__with-carousel {
    .product-labels {
      z-index: 2;
    }
  }
}
