.clothing-details {
  &__select {
    text-align: center;
    .form-select {
      display: inline-block;
      width: auto;
      min-width: 34rem;
      select {
        text-transform: uppercase;
      }
    }
  }
  &__campaign {
    h4 {
      text-transform: uppercase;
      margin-bottom: 0.4rem;
    }
    .button {
      width: auto;
      min-width: 22.5rem;
    }
  }
  &__orders {
    h4 {
      text-transform: uppercase;
      font-weight: bold;
    }
    .orders-listing__order-detail {
      padding-left: 0;
    }
  }
  .order-detail__column {
    width: 25%;
  }

  .returnabledisclaimer {
    width: 100%;
    margin-top: $space-unit * 4;
    font-weight: 600;
  }
  .bag-product {
    &.disabled {
      opacity: 0.5;
    }
  }
}
.container-disclaimer {
  display: flex;
  justify-content: space-between;
}
.cancelleddisclaimer {
  font-weight: 600;
  margin-top: 0.4rem;
  text-transform: uppercase;
}
