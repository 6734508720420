.switch {
  display: inline-flex;
  @include reset-list;

  .switch__item {
    @extend %primary-xxs-u;

    margin-right: ($space-unit * 4);
    color: $black_secondary;

    &.selected {
      color: $black;
    }
  }
}
