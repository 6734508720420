.appointments-mandatoryField {
  font-weight: 300;
  size: 12px;
  line-height: 14px;
  color: #0000008c;
}

.appointments-loading-screen {
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10rem;
  img {
    width: 2.4rem;
    margin: 0 auto;
    margin-bottom: 0.8rem;
  }
  h4 {
    max-width: 15rem;
    text-align: center;
    text-transform: uppercase;
  }

  &__form {
    @extend .appointments-loading-screen;
    padding-top: 0;
    margin-top: 28px;

    h4 {
      max-width: 100%;
      margin-top: 16px;
    }
  }
}

$boxPadding: 32px 64px;

.account-appointments__textual-banner,
.account-prevappointments__textual-banner {
  .list__empty-message {
    max-width: none;

    &-title {
      line-height: 24px;
    }
  }
}

.appointments-card {
  $border: 1px solid $grey_line;
  margin-bottom: 24px;

  width: 100%;
  border: $border;
  background-color: $grey_disabled;

  &__header {
    padding-block: 1.2rem;
    border-bottom: $border;

    color: $black_secondary;
    font-weight: 600;
    text-transform: uppercase;
  }

  &__body {
    $mb: 0.8rem;

    display: flex;
    justify-content: space-between;
    gap: 24px;
    // column-gap: 20px;
    padding: $boxPadding;
    text-transform: uppercase;

    .appointment-details {
      h3 {
        font-size: 2rem;
        line-height: 2.4rem;
        margin-bottom: $mb;
      }

      p {
        color: $black_secondary;
        font-weight: 300;
      }
    }

    .appointment-date,
    .appointment-time {
      h4 {
        margin-bottom: $mb;
        font-size: 1.4rem;
      }
      p {
        text-transform: none;
        font-size: 1.6rem;
        font-weight: 600;
      }
    }

    .appointment-btn-group {
      display: flex;
      flex-direction: column;
      row-gap: 12px;

      :first-child {
        margin-top: 0;
      }

      .appointment-btn-group {
        max-width: 400px;
      }
    }
  }
}

.appointments__book-banner {
  margin-top: 80px;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  place-items: center;

  .banner-img {
    grid-column: 1;
    grid-row: 1;
    min-height: 50rem;
  }

  .banner-content {
    grid-column: 1;
    grid-row: 1;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;

    .list__empty-message {
      max-width: 45rem;
      color: $white;
      font-size: 1.6rem;
      font-weight: 300;
      margin: 0 auto;
    }

    .book-title {
      font-size: 3.2rem;
      color: $white;
      font-weight: 300;
      line-height: 3.8rem;
      text-transform: uppercase;
      margin-bottom: 1.6rem;
    }

    &__btn-group {
      margin-inline: auto;
      width: 100%;
      max-width: 300px;
    }
  }
}

.account-appointments-detail {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  position: relative;

  h4 {
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
    margin-top: 40px;
  }

  p {
    font-size: 16px;
  }

  .font-weight-bold {
    font-weight: bold;
  }

  &__number {
    .detail-back-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px;

      .back-btn {
        position: absolute;
        left: 18%;
      }

      h4 {
        margin-top: 0;
      }
    }

    p {
      font-size: 24px;
      text-transform: uppercase;
    }
  }

  &__store {
    p {
      margin-top: 4px;
      font-size: 20px;
      text-transform: uppercase;
    }
  }

  &__more-details {
    display: flex;
    gap: 4px;
    margin-top: 80px;
    justify-content: center;

    h4 {
      margin-top: 0;
      margin-bottom: 40px;
    }

    div {
      padding-block: 24px;
      background-color: $grey_disabled;
      border: 1px solid $grey_line;

      p + p {
        margin-top: 12px;
      }
    }

    a {
      text-decoration: none;
    }
  }

  &__btn-group {
    display: flex;
    gap: 4px;
    margin-block: 40px;
  }
}

.edit-appointment {
  .wrapper-info {
    .row {
      row-gap: 0.8rem;
      span {
        padding: 0 0.2rem;
      }
    }
  }

  .register_form {
    padding-bottom: 2rem;
  }
  .line {
    margin-top: ($space-unit * 6);
    margin-bottom: ($space-unit * 6);
    @include border('top');
  }

  &__body {
    height: 100%;

    overflow-y: auto;
    display: flex;
    flex-direction: column;

    @include scrollbar;

    .appointments-loading-screen {
      padding-block: 10rem;
    }

    .optionbox__item__content {
      border: 0;
    }
    .detail-title {
      text-transform: uppercase;
      margin-bottom: 1.6rem;
    }
    .product-reserve__detail__reserve-review {
      margin-bottom: 2rem;
      .container-button {
        width: 100%;
        max-width: 100%;
        margin-left: 0;
      }
    }
    .product-reserve__detail__selected-store {
      .option-store__live-boutique {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        column-gap: 0.6rem;
        margin-bottom: 0.7rem;
        .live-boutique-info {
          padding: 0;
          height: 1.4rem;
          .icon--info {
            width: 1.4rem;
            height: 1.4rem;
            & + .tooltip__box {
              position: fixed;
              transform: translate(38%, -115%);
              left: 0;
              right: auto;
              bottom: auto;
              text-transform: initial;
              width: min-content;
              margin: 0 -3.5rem;
              &::before {
                left: 6.5rem;
                right: initial;
              }
            }
          }
        }
      }
      .name {
        font-weight: bold;
        margin: 0;
        margin-bottom: 0.4rem;
      }
      border: 1px solid;
      padding: 1.2rem;
      margin: 2.4rem 0 1.2rem 0;
    }
    .registered-in-store-send-email {
      .row {
        flex-direction: column;
        row-gap: 1.2rem;
      }
    }
    .login-registration__registration-successful {
      h2 {
        margin-top: 0;
      }
    }

    &.live-boutique {
      overflow: scroll;
      padding-top: 0;
      .dialog__title {
        padding-top: 3.2rem;
      }
      .modal__header-sticky {
        border-bottom: 1px solid $grey-line;
        padding-bottom: 1.6rem;
        .dialog__title {
          padding-top: 0;
        }
        &.no-border {
          border-bottom: none;
        }
        h2 {
          text-transform: uppercase;
          text-align: center;
          font-weight: bold;
        }
      }
    }
  }

  .scrollable {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    margin-top: 10px;

    form {
      height: 100%;
    }
    .wrapper-content-scrollable {
      overflow-y: auto;
      height: 100%;
      @include scrollbar;
    }
  }

  .edit-appointment__details {
    padding-top: 40px;
  }

  &__btn-group {
    // display: flex;
    // flex-direction: column-reverse;
    // gap: 12px;

    display: flex;
    flex-direction: row;
    gap: 4;

    .btn--cancel {
      span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.appointment-edit-form {
  .optionbox {
    flex-wrap: nowrap;
    justify-content: center;
    column-gap: 2rem; // optionboxes have got 0.2rem padding both left and right, so 2rem + 0.2rem + 0.2rem = 2.4rem
    white-space: nowrap;
    // width: 60%;
    padding-top: 32px;
    padding-bottom: 24px;
    border-top: 1px solid $grey_input;
    &__item {
      width: auto;
      flex: 0 0 0;
      &__radio {
        @extend .visually-hidden;
        &:checked + .optionbox__item__content {
          border-top-color: transparent;
          border-right-color: transparent;
          border-left-color: transparent;
          font-weight: bold;
          border-bottom: 1px solid;
        }
      }
      &__content {
        text-transform: uppercase;
        border-color: transparent;
        padding-right: 0;
        padding-left: 0;
        justify-content: center;
        border-bottom: 1px solid $grey-input;
      }
    }
  }

  .edit-appointment-form__details {
    margin-block: 24px;

    .wrapper {
      display: flex;
      h4 {
        font-size: 14px;
        font-weight: 300;
        text-transform: uppercase;
        margin-block: 24px;
      }

      p + p {
        margin-top: 12px;
      }

      p > a {
        text-decoration: none;
      }
    }
  }
}

.edit-appointment-confirm {
  &__body {
    margin-inline: auto;
    padding-bottom: 4px;
    .info {
      margin-top: 36px;
      &__hr {
        margin-top: 24px;
        margin-bottom: 24px;
      }
      &__sure {
        font-size: 20px;
        font-weight: 300;
        line-height: 24px;
        padding-right: 30px;
      }
      &__description {
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 26px;
        margin-top: 8px;
      }
      &__btn-group {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}

.account-appointments {
  .wrapper-box-login {
    text-align: center;
    .button-login {
      margin-top: 2rem;
      justify-content: center;
    }
  }
  .bookanappointment-wrapper {
    .optionbox {
      display: block;
      .option-store {
        text-align: start;
        &__live-boutique {
          display: flex;
          align-items: center;
          margin-bottom: 0.7rem;
          h4 {
            display: inline-block;
          }
          .live-boutique-info {
            display: inline-block;
            margin-left: 1rem;
            text-transform: initial;
            padding: 0;
            height: 1.4rem;
            .icon--info {
              width: 1.4rem;
              height: 1.4rem;
            }
          }
        }
        &__virtual {
          .option-store__title {
            margin-left: 0;
            font-weight: bold;
          }
          .store-details {
            flex-direction: row;
          }
        }
      }
      .option-store__title {
        margin-bottom: 0.4rem;
      }
      .option-store__title,
      .option-store__address {
        text-transform: none;
        white-space: pre-wrap;
        max-width: 90%;
      }
    }
  }
  &__list {
    margin-bottom: $space-unit * 6;
    .button.button--secondary {
      width: auto;
    }
    .detail-row {
      .dt {
        @extend %primary-xxs-u;
        margin-bottom: $space-unit;
        display: block;
      }
      .dd {
        > p {
          @include text-shorten;
          margin-bottom: $space-unit;
        }
      }
    }
  }
  .detail-row {
    .dd {
      > p {
        @include text-shorten;
      }
    }
  }
  .virtual-message {
    margin-bottom: 3.2rem;
  }
  .detail-title {
    margin-bottom: 0.8rem;
  }
  .optionbox {
    justify-content: center;
    column-gap: 2rem; // optionboxes have got 0.2rem padding both left and right, so 2rem + 0.2rem + 0.2rem = 2.4rem
    white-space: nowrap;
    // width: 60%;
    margin: 0 auto;
    &__item {
      width: auto;
      flex: 0 0 0;
      &__radio {
        @extend .visually-hidden;
        &:checked + .optionbox__item__content {
          border-top-color: transparent;
          border-right-color: transparent;
          border-left-color: transparent;
          font-weight: bold;
        }
      }
      &__content {
        text-transform: uppercase;
        border-color: transparent;
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
  .product-reserve__detail__reserve-review {
    h2 {
      text-align: center;
    }
    .divider {
      margin: 2.4rem 0;
    }

    .digital-flaship-promocard {
      margin-top: 2.4rem;
    }

    .detail-title {
      text-transform: uppercase;
    }
  }
  .checkout-pickup {
    .optionbox__item {
      .optionbox__item__content {
        padding-top: 1.2rem;
        .option-store__title {
          font-weight: bold;
          margin-left: 0;
        }
      }
    }
    .store-details {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}
.item-actions {
  display: flex;
  padding-top: ($space-unit * 4);
  .cta,
  button {
    margin-right: ($space-unit * 3);
  }
}
.remove-item {
  position: relative;
  .item-actions {
    @extend %primary-s;
    padding-top: ($space-unit * 2);
    padding-bottom: ($space-unit * 12);
  }
}
.detail-wrapper {
  position: relative;
  .remove-appointment {
    position: absolute;
    @include z;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
  }
}

.product-reserve__detail__form:not(.appointment-edit-form),
.product-reserve__detail__reserve-review:not(.scrollable) {
  padding-bottom: 0.8rem;
}

.product-reserve__detail__form {
  .disclaimer {
    margin-top: -4px;
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 300;
  }
}

.tabs-single-tab-title {
  padding: 12px;
  text-transform: uppercase;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
}
