.bag-summary {
  &-outer-container{
    z-index: 3;
  }
  &.checkout {
    .container-bag-summary {
      &.close-onscroll {
        .checkout--summary {
          transition: none;
        }
        .collapsed-row {
          transition: all 0.1s 0s cubic-bezier(0, 0, 0, 1);
        }
      }

      .checkout-products {
        margin-bottom: 0;
        &::-webkit-scrollbar {
          display: none;
        }
        .minibag__productcontainer {
          margin-bottom: 3.9rem;
        }
      }

      .wrapper-info-total {
        padding-inline: 0.4rem;
        padding-bottom: 1.6rem;
      }
    }
    .container-button {
      box-shadow: -1px 0 0 0 #e4e4e4, 1px 0 0 0 #e4e4e4, 0 -1px 0 0 #e4e4e4, 0 1px 0 0 #e4e4e4;
      &.expanded {
        box-shadow: 0 0 10px #e4e4e4, 0 0 3px #e4e4e4, 0 0 5px #e4e4e4, 0 0 3px #e4e4e4;
        border: 0;
      }
    }
    .checkout--summary {
      border: 0;
      &[aria-expanded='true'] {
        border: 0;
        top: 2px;
        position: relative;
        background: white;
        z-index: 1;
      }
    }
    .checkout--summary .items__value {
      flex-grow: 1;
      text-align: right;
    }
    .tax-disclaimer--wrapper {
      margin: 1.6rem 0 2rem;
    }

    .items--totals:last-child {
      padding-top: 2rem;
    }
  }
  &__ctasmobile {
    .button {
      margin-bottom: $space-unit * 4;
      span {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
  }
  .button-row {
    &__spacing {
      margin-bottom: $space-unit * 2;
    }
  }
}

.wrapper-sticky-mobile {
  background: white;
  width: 100%;
  left: 0;
  z-index: 3;
  padding-bottom: 2.4rem;
  // border-top: 1px solid $white;
  &.fixed {
    position: fixed;
    bottom: 0;
    padding-left: 2rem;
    padding-right: 2rem;
    border-top: 1px solid $grey_line;
    .items--totals.border-top {
      border-top: initial;
    }
  }
}
