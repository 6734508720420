.perfume-product-highlight-slide {
  &.perfume-product-highlight-slide--inverted {
    .perfume-product-highlight-slide__img-column img {
      padding-left: 0;
    }
  }
  &:not(.perfume-product-highlight-slide--inverted) {
    .perfume-product-highlight-slide__img-column img {
      padding-right: 0;
    }
  }
}
