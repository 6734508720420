.bottom-module {
  .monclernow-listing {
    padding: 0 1.6rem;
    flex-wrap: wrap;
    &__article {
      width: 100%;
      min-width: 100%;
      margin-right: 0;
      margin-bottom: 3.2rem;
    }
    &::before,
    &::after {
      content: none;
    }
  }
  &__title {
    padding-top: 3.6rem;
    margin-bottom: 2.4rem;
  }
}
