:root {
  --shelf-height: 10rem;
}

body.has-ticker {
  .listing-container {
    padding-top: $header-height-desktop - 0.1rem + $ticker-bar;
    &.is-shelf {
      padding-top: calc(#{$header-height-desktop} + var(--shelf-height) - 0.1rem + #{$ticker-bar});
    }
  }
}

.listing-container {
  padding-top: $header-height-desktop - 0.1rem;
  &.is-shelf {
    padding-top: calc(#{$header-height-desktop} + var(--shelf-height) - 0.1rem);
  }
  .product-list {
    .product-tile {
      flex-basis: 25%;
    }
  }
}

.grid-container {
  grid-template-columns: repeat(4, 1fr);
  &.grid-2 {
    grid-template-columns: repeat(2, 50%);
  }
  &.grid-6 {
    grid-template-columns: repeat(6, 1fr);
  }
  .firstcol {
    .product-tile__info {
      padding-left: get($grid-container-padding, md);
    }
    .product-tile__card {
      .product-tile__info {
        padding-left: get($grid-container-padding, lg);
      }
    }
  }
  &.has-look {
    li {
      &:nth-child(4n + 2) {
        .shop-by-look-module {
          left: -100%;
        }
      }
      &:nth-child(4n + 3) {
        .shop-by-look-module {
          left: -200%;
        }
      }
      &:nth-child(4n + 4) {
        .shop-by-look-module {
          left: -300%;
        }
      }
    }
  }
}

.plppromo {
  .plppromo__link .product-card-info {
    padding: 3.2rem;
    i {
      height: 3.6rem;
      width: 16rem;
    }
  }

  .plppromo__title {
    top: $space-unit * 8;
    left: $space-unit * 8;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    object-fit: cover;
  }

  &.is-double {
    picture {
      height: 100%;
    }
    img {
      position: relative;
      top: initial;
      transform: initial;
    }
  }
  &.plppromo__promo-large {
    .plppromo__div {
      &.plppromo__video {
        padding-top: 0;
      }
    }
  }
  &.plppromo__promo-medium {
    .plppromo__div {
      .plppromo {
        &__picture {
          &:before {
            padding-top: 75%;
          }
        }
        &__cta {
          min-height: 4.4rem;
        }
      }
    }
  }
  &.plppromo__promo-small {
    .plppromo__div {
      &.bottom {
        .plppromo__text-wrapper {
          transform: translateY(-50%);
        }
      }
    }
  }
  &.plppromo__promo-medium,
  &.plppromo__promo-large {
    .plppromo__div {
      .plppromo {
        &__text-wrapper {
          padding: ($space-unit * 16);
        }
      }
    }
  }
  &.plppromo__promo-banner {
    .plppromo__div {
      min-height: auto;
      height: 100%;
      .plppromo__text-wrapper {
        padding: 3rem;
        .plppromo__logo {
          width: 6.4rem;
          position: relative;
          margin: 1rem auto;
        }
        .plppromo__title {
          font-size: 3.2rem;
          line-height: 3.8rem;
        }
      }
    }
  }
}
