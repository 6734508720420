.cookie-bar {
  padding: 2.4rem 0;
}
.cookie-center {
  max-height: 80vh;
  .buttons-wrapper {
    grid-area: submit;
    padding-bottom: 2.4rem;
    padding-top: 1.2rem;
  }
}
