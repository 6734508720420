.lunettes__component-wrapper {
  position: relative;
  z-index: 1;
  overflow: hidden;
  @include appearAnimation();
  padding-top: 3.2rem;
  margin-bottom: 1.6rem;

  // OE-16934 - Optimize Editorial Module Borders/Paddings
  &.pd-no__padding {
    padding-block:0;
  }
  &.pd-no__margin {
    margin-block:0;
  }
  &.open {
    .lunettes__expanded-content {
      transform: translateY(0%);
      .lunettes__toggle button::after {
        transform: translateX(-50%) rotate(270deg);
      }
    }
  }
}

.lunettes__component {
  transition: all 0.4s;
  .lunettes__title {
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: 1.2rem;
    text-align: center;
  }
  .lunettes__cta-container {
    text-align: center;
    margin-bottom: 2.4rem;
    .button--tertiary:not(:last-child) {
      margin-right: 2rem;
    }
  }
  .lunettes__image-wrapper {
    position: relative;
    max-height: 745px;
    @extend .aspect-ratio--2-3;
    img {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .lunettes__expanded-content {
    transition: all 0.4s;
    background-color: $black;
    padding: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    transform: translateY(100%);
    .lunettes__toggle {
      position: absolute;
      left: 50%;
      top: -2.6rem;
      transform: translate(-50%, -100%);
      z-index: 4;
      // width: 100%;
      // padding: 0 1.6rem;
      text-align: center;
      text-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.2);
      button {
        &::after {
          transition: all 0.4s;
          transform: translateX(-50%) rotate(90deg);
        }
      }
    }
    .lunettes__product-slider {
      padding: 0;
      .swiper-slide {
        list-style: none;
        .product-tile {
          .product-tile__info {
            display: none;
          }
        }
      }
      .swiper-wrapper {
        &.slide-center {
          justify-content: center;
        }
      }
    }
  }
}
