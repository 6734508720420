.bookanappointment {
  .wrapper-info {
    .row {
      row-gap: 0.8rem;
      span {
        padding: 0 0.2rem;
      }
    }
  }
  .bookanappointment__right {
    overflow-y: auto;
    @include scrollbar;
    .optionbox__item__content {
      border: 0;
    }
    .detail-title {
      text-transform: uppercase;
      margin-bottom: 1.6rem;
    }
    .product-reserve__detail__reserve-review {
      margin-bottom: 2rem;

      .digital-flaship-promocard {
        margin-top: 1.6rem;
        margin-bottom: -1.6rem;
      }

      .container-button {
        width: 100%;
        max-width: 100%;
        margin-left: 0;
      }
    }
    .product-reserve__detail__selected-store {
      .option-store__live-boutique {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        column-gap: 0.6rem;
        margin-bottom: 0.7rem;
        .live-boutique-info {
          padding: 0;
          height: 1.4rem;
          .icon--info {
            width: 1.4rem;
            height: 1.4rem;
            & + .tooltip__box {
              position: fixed;
              transform: translate(38%, -115%);
              left: 0;
              right: auto;
              bottom: auto;
              text-transform: initial;
              width: min-content;
              margin: 0 -3.5rem;
              &::before {
                left: 6.5rem;
                right: initial;
              }
            }
          }
        }
      }
      .name {
        font-weight: bold;
        margin: 0;
        margin-bottom: 0.4rem;
      }
      border: 1px solid;
      padding: 1.2rem;
      margin: 2.4rem 0 1.2rem 0;
    }
    .registered-in-store-send-email {
      .row {
        flex-direction: column;
        row-gap: 1.2rem;
      }
    }
    .optionbox {
      justify-content: center;
      column-gap: 2rem; // optionboxes have got 0.2rem padding both left and right, so 2rem + 0.2rem + 0.2rem = 2.4rem
      white-space: nowrap;
      // width: 60%;
      margin: 0.8rem auto 0 auto;
      border-bottom: 1px solid $grey_input;
      &__item {
        width: auto;
        flex: 0 0 0;
        &__radio {
          @extend .visually-hidden;
          &:checked + .optionbox__item__content {
            border-top-color: transparent;
            border-right-color: transparent;
            border-left-color: transparent;
            font-weight: bold;
            border-bottom: 1px solid;
          }
        }
        &__content {
          text-transform: uppercase;
          border-color: transparent;
          padding-right: 0;
          padding-left: 0;
          justify-content: center;
        }
      }
    }
    .login-registration__registration-successful {
      h2 {
        margin-top: 0;
      }
    }
  }
  .register_form {
    padding-bottom: 2rem;
  }
  .line {
    margin-top: ($space-unit * 6);
    margin-bottom: ($space-unit * 6);
    @include border('top');
  }
  &__body {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
    height: 100%;
  }
  &__right {
    overflow: hidden;
    padding: $space-unit * 4;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    &.live-boutique {
      overflow: scroll;
      padding-top: 0;
      .dialog__title {
        padding-top: 3.2rem;
      }
      .modal__header-sticky {
        border-bottom: 1px solid $grey-line;
        padding-bottom: 1.6rem;
        .dialog__title {
          padding-top: 0;
        }
        &.no-border {
          border-bottom: none;
        }
        h2 {
          text-transform: uppercase;
          text-align: center;
          font-weight: bold;
        }
      }
    }
  }
  &__left {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .divider {
    @include border('top');
    height: 1px;
    margin-top: $space-unit * 8;
    margin-bottom: $space-unit * 4;
  }
  .scrollable {
    height: 100%;
    padding-bottom: $space-unit * 8;
    margin-right: -($space-unit * 4);
    padding-right: $space-unit * 4;
    overflow: hidden;
    overflow-y: auto;
  }
  &.mbm-modal {
    .modal__body {
      height: 100svh;
      .scrollable {
        .wrapper-content-scrollable {
          padding-bottom: 0;
        }
      }
    }
  }
}
.bookanappointment-mbm {
  &__desc {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
  &__descstores {
    display: block;
    padding-top: 0.8rem;
    padding-bottom: 0.4rem;
  }
  &__stores {
    @include reset-list;
    li:not(:last-child) {
      border-bottom: 1px solid $grey_line;
    }
  }
  &__store {
    @extend %primary-s;
    padding: ($space-unit * 4) 0;
    width: 100%;
    text-align: left;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 1.6rem;
      right: 0;
      @include fixedSprite('chevron-right');
    }
    span {
      display: block;
      text-transform: uppercase;
      margin-bottom: $space-unit;
    }
  }
}
.account-appointments {
  //margin-block: 80px 24px;
  .client-service__section {
    margin-top: 0;
    margin-bottom: 2.4rem;
  }
  .virtual-message {
    margin-top: 3.2rem;
  }
  .countryselector {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
  }
  .checkout-pickup {
    margin-top: $space-unit * 8;
    margin-bottom: $space-unit * 8;
    > .row {
      height: 100%;
      position: relative;
      > div {
        &.overflow-visible {
          overflow: visible;
        }
        overflow: hidden;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
      }
    }
    &__map {
      height: 248px;
      margin-top: 1.8rem;
      margin-bottom: 0;
    }
    .storelist {
      &.overflow-visible {
        overflow: visible;
      }
      &.disabled {
        .optionbox__item {
          opacity: 0.5;
          pointer-events: none;
        }
      }
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;
      flex-grow: 10;
      .optionbox__item__content {
        padding: 1.2rem;
      }
      .optionbox__item__radio {
        width: 1.4rem;
        height: 1.4rem;
        clip: auto;
        top: 50%;
        transform: translateY(-50%);
        &:checked + .optionbox__item__content {
          font-weight: normal;
          &.selected {
            border-color: black !important;
          }
        }
      }
    }
  }
  .button {
    margin-top: $space-unit * 4;
  }
  .button-row {
    .button--primary,
    .button--secondary {
      width: 100%;
    }
    .button {
      margin-bottom: $space-unit * 2;
    }
  }
  .title-edit {
    @extend %primary-s;
    margin-top: $space-unit * 4;
    margin-bottom: $space-unit * 4;
  }
  .dateselection-row {
    margin-bottom: $space-unit * 4;
  }
  .alert--success {
    width: 100%;
    text-align: right;
  }
}
.ReserveForm {
  .checkbox {
    margin-top: 1.2rem;
    margin-bottom: 2.8rem;
  }
}
.store-features {
  display: flex;
  align-items: center;
  color: $black_secondary;
  column-gap: 0.8rem;
  margin-bottom: 1.6rem;
}
.store-feature {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1.4rem;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  .tooltip {
    padding: 0;
    .tooltip__box {
      left: calc(50% + var(--tooltip-offset));
      transform: translateX(-50%);
      right: initial;
      &::before {
        left: calc(50% - var(--tooltip-offset));
      }
    }
  }
  .icon--info {
    display: flex;
    height: 2.4rem;
    width: 2.4rem;
    background-size: 1rem;
  }
}
.product-reserve__detail__reserve-review {
  .container-row {
    row-gap: 2.4rem;
  }
}

.live-boutique-selected-store {
  border: 1px solid $black;
  padding: 1rem;
  .name {
    margin-bottom: 0.4rem;
  }
  .live-boutique {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 1.6rem;
    margin-bottom: 0.6rem;
    h4 {
      text-transform: uppercase;
    }
    .live-boutique-info {
      height: 1.6rem;
      padding: 0;
    }
    .icon--info {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  .live-boutique-info {
    display: inline-block;
    margin-left: 1rem;
    text-transform: initial;
    .tooltip__box {
      z-index: 11;
    }
  }
}
.mbm-modal {
  .bookanappointment__right {
    .product-reserve__detail__selected-store {
      &.has-back {
        padding-left: 2.4rem;
      }
    }
  }
}
.bookanappointment {
  .modal__body {
    .bookanappointment__body {
      .bookanappointment__right {
        .optionbox {
          white-space: pre-wrap;
          .optionbox__item {
            flex: 1;
            text-align: center;
          }
        }
      }
    }
    .scrollable {
      overflow-y: hidden;
      form {
        height: 100%;
      }
      .wrapper-content-scrollable {
        margin-right: -3.2rem;
        padding-right: 3.2rem;
        padding-bottom: 5rem;
        overflow-y: auto;
        height: 100%;
        @include scrollbar;
      }
    }
  }
}
