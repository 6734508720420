.product-tile-featured {
  .product-tile {
    &__link {
      height: 593px;
    }
    &__image-wrapper {
      max-height: 47.1rem;
    }
    &__info {
      min-height: 12.2rem;
      max-height: 12.2rem;
      padding: ($space-unit * 12) ($space-unit * 3) ($space-unit * 7);
    }
  }
}
