.orders-listing {
  .aftercare-tile-wrap {
    padding: 40px;
    .request-text-wrap {
      display: block;
      .request-text-left-wrap {
        margin-bottom: 35px;
      }
    }
  }
}

.aftercare__text {
  margin-top: 8px;
}
