.three-column-product {
  margin-bottom: 0;
}
//  Similar Product - remove product labels hovering
.similar-product-carousel {
  .product-tile {
    .product-tile__image-wrapper {
      .product-labels {
        &.hovering {
          bottom: 1.2rem;
          transition: none;
        }
      }
    }
  }
}

.product-tile {
  &__members-unlock {
    .label {
      // margin-left: 4px;
      display: inline-block;
    }
  }
  &__swatch-change {
    .product-tile__color-link {
      background-color: $white;
      color: black;
      .selector-color-link {
        height: 3.8rem;
        color: $black_secondary;
        .selector__menu__item {
          .swatch {
            border: 1px solid rgba($black, 0.5);
            &:before {
              height: 2.4rem;
              width: 2.4rem;
            }
          }
          .selector__menu__item__label {
            color: $black_secondary;
          }
        }
      }
    }
  }

  // .product-tile__image-wrapper {
  //   .product-labels {
  //     &.hovering {
  //       bottom: 7.5rem;
  //       @include animate;
  //     }
  //   }
  // }

  &__with-carousel {
    .product-tile__image-wrapper {
      .product-labels {
        z-index: 4;
        bottom: 1.2rem;
      }
    }
    .product-tile-carousel-css,
    .product-tile--carousel {
      .product-tile-carousel__button {
        display: inline-block;
      }
      &:hover {
        .swiper-pagination-bullets {
          opacity: 1;
        }
        .product-tile-carousel__button {
          opacity: 1;
        }
      }
      &.hovering {
        .product-tile-carousel__button {
          opacity: 1;
          // OE-18373 - opacity rules provided in the Jira
          &[disabled] {
            opacity: 0.7;
            cursor: auto;
            pointer-events: auto;
          }
        }
      }
    }
    // .product-tile-carousel__button {
    //   @include animate;
    //   opacity: 0;
    //   position: absolute;
    //   z-index: 1;
    //   top: 50%;
    //   transform: translateY(-50%);
    //   width: 4.4rem;
    //   height: 4.4rem;
    //   &:focus-visible {
    //     outline: 1px solid $black;
    //   }
    //   &.button--arrow-left {
    //     left: 0.8rem;
    //     padding-left: initial;

    //     &:after {
    //       left: auto;
    //     }
    //   }
    //   &.button--arrow-right {
    //     right: 0.8rem;
    //     padding-right: initial;

    //     &:after {
    //       right: auto;
    //     }
    //   }
    // }
    .swiper-pagination-bullets {
      @include animate;
      --bullets-bottom-offset: 5.2rem;
      opacity: 0;
      &:before {
        height: 18rem;
      }
      .swiper-pagination-bullet {
        width: 4rem;
        cursor: pointer;
      }
    }
  }
  &.product-tile--simplified {
    .product-tile__info {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
  }
}
