.sticky-tray {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $white;
  @include z('stickybar-m');
  // padding: ($space-unit * 4) 0;
  @include animate(0.4);
  transition-property: opacity;
  opacity: 0;
  // transform: translateY(100%);
  &.show {
    // transform: translateY(0);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    opacity: 1;
  }
  // &::before {
  //   content: '';
  //   position: absolute;
  //   top: -1px;
  //   left: 0;
  //   width: 100%;
  //   height: $space-line;
  //   background-color: $white_secondary;
  // }
  &__name-price {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: font-property($moncler-gothic-mobile, 'm', 'font-family');
    font-size: font-property($moncler-gothic-mobile, 'm', 'font-size');
    line-height: font-property($moncler-gothic-mobile, 'm', 'line-height');
    letter-spacing: font-property($moncler-gothic-mobile, 'm', 'letter-spacing');
  }
  &__button {
    visibility: hidden;
    display: flex;
    .show & {
      visibility: visible;
    }
    .button {
      height: $button-height-mobile;
      span {
        font-size: font-property($moncler-gothic-desktop, 's', 'font-size');
        line-height: font-property($moncler-gothic-desktop, 's', 'line-height');
        letter-spacing: font-property($moncler-gothic-desktop, 's', 'letter-spacing');
      }
      &:nth-child(2) {
        margin-left: $space-unit;
      }
    }
  }
  &__name {
    @include text-shorten;
  }
  &__modal.modal {
    width: 100%;
    min-width: initial;
    max-width: initial;
    height: auto;
    right: 0;
    left: 0;
    bottom: 4.8rem;
    top: initial;
    padding-top: 5.5rem;
    transition-duration: 0.3s;
    transition-property: transform;
    transition-delay: 0s;
    opacity: 1;
    &.ReactModal__Content {
      transform: translateY(3.2rem);
    }
    &.ReactModal__Content--after-open {
      transform: translateY(0);
    }
    &.ReactModal__Content--before-close {
      transform: translateY(3.2rem);
    }
    .selector-color-expanded {
      // border-top: initial;
      .selector__label {
        padding-top: 1.5rem;
      }
      .selector__menu {
        padding-top: 1.5rem;
        padding-bottom: 2.1rem;
      }
    }
    .selector-size-expanded {
      .selector__header {
        padding-top: 1.5rem;
      }
      .selector__menu {
        padding-top: 1.5rem;
        padding-bottom: 0.8rem;
      }
    }
  }
  &__overlay {
    &.overlay-modal {
      @include z('stickybar-o-m');
      transition-duration: 0.3s;
      background: $black_secondary;
    }
  }
}

.container-product-button-video {
  &.fixed {
    .product-video-pause {
      position: fixed;
    }
  }
}
