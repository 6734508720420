.perfume-product-highlight-slide {
  flex-direction: column;

  &.perfume-product-highlight-slide--inverted {
    flex-direction: column;
    &.perfume-product-highlight-slide--inverted-mobile {
      flex-direction: column-reverse;
    }
  }
  &.perfume-product-highlight-slide--inverted-mobile {
    flex-direction: column-reverse;
  }

  .img-hidden {
    display: none !important;
  }

  &__img-column {
    width: 100%;
  }

  &__text-column {
    width: 100%;
    padding: ($space-unit * 8);
  }

  &__product-image-wrapper {
    width: 58.6rem;
  }
}
