.curator-flp-video {
  .curator-video__text-wrapper {
    .curator-video__title {
      font-size: 15rem;
      line-height: 12.8rem;
      margin-bottom: 3.2rem;
    }
    .curator-video__quote {
      font-size: 1.6rem;
      line-height: 2rem;
      margin-bottom: 2.4rem;
    }
  }
  .curator-video-wrapper {
    height: calc(var(--viewport-height) - #{$header-height-desktop});
  }
}
