.product-reserve {
  &__main,
  &__detail {
    width: 50%;
  }

  &__detail {
    padding: $space-unit * 8;
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    overflow-x: hidden;

    .dialog_subtitle {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &__main {
    padding: $space-unit * 8;
    &__wrapper {
      .selector-color-expanded .selector__menu__item {
        margin-right: 1.6rem;
        .swatch {
          width: 2.4rem;
          height: 2.4rem;
          &::after {
            width: 2.4rem;
          }
        }
      }
    }
  }
}
#reserveinboutique {
  overflow-y: hidden;
  .product-reserve__main {
    &.zoom-product {
      &.is-login {
        display: flex;
      }
      height: 90vh;
    }
  }
}
