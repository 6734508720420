.interactive-cards {
  align-items: center;
  padding: 4rem 0;
  &.row {
    margin-right: 0;
    margin-left: 0;
  }
  &.inverted-colours {
    background-color: $black;
    color: $white;
    .scrollable-selector-swiper {
      &:after {
        border-color: transparent transparent $white transparent;
      }
      .swiper-slide {
        &:before {
          background-color: $white;
        }
        .timeline-separator {
          background-color: $white;
        }
      }
    }
  }
  &.no-timeline {
    .scrollable-selector-swiper {
      .swiper-slide {
        &:before {
          display: none;
        }
        .timeline-separator {
          display: none;
          visibility: hidden;
        }
      }
    }
    .interactive-card {
      .button-row {
        display: flex;
        justify-content: center;
        margin-top: 3.2rem;
        .button {
          display: inline-flex;
          margin: 0 0.4rem;
          width: auto;
          flex-grow: 1;
          flex-basis: 0;
        }
        &.modal-row {
          display: block;
        }
      }
    }
  }
  &:not(.no-timeline) {
    &.inverted-colours {
      .scrollable-selector-swiper {
        .swiper-slide {
          .scrollable-selector {
            .text {
              background-color: $black;
              color: #666666;
            }
          }
        }
      }
    }
    .scrollable-selector-swiper {
      .swiper-slide {
        display: inline-flex;
        width: fit-content;
        &.swiper-slide-active {
          .scrollable-selector {
            .text {
              color: inherit;
            }
          }
        }
        .scrollable-selector {
          max-width: 18.5rem;
          height: 4rem;
          font-size: 4.2rem;
          line-height: 4.2rem;
          flex: none;
          margin: 0 0.7rem;
          .text {
            position: relative;
            color: #999;
            background-color: $white;
            z-index: 1;
            &:before {
              left: -1rem;
            }
            &:after {
              right: -1rem;
            }
          }
        }
      }
    }
    .interactive-card {
      padding-bottom: 2.8rem;
      .button-row {
        margin-top: 2.8rem;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        .button {
          &:first-child {
            margin-bottom: 2.2rem;
          }
        }
      }
      &__description {
        p {
          margin-bottom: 0;
        }
      }
    }
  }
  &__intro {
    padding: 0 1.6rem;
    margin-bottom: 4rem;
    p {
      text-align: center;
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
  &__title {
    text-transform: uppercase;
    font-size: 2.4rem;
    line-height: 2.8rem;
    text-align: center;
    margin-bottom: 1.2rem;
  }

  &__top-column {
    position: relative;
    padding-bottom: 4.8rem;
    .inverted-colours & {
      &:after {
        border-color: transparent transparent $white transparent;
      }
    }
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 11px 11px 11px;
      border-color: transparent transparent $black transparent;
      position: absolute;
      left: calc(50% - 11px);
      bottom: 0;
    }
  }

  .scrollable-selector-swiper {
    position: relative;
    cursor: pointer;
    .inverted-colours & {
      &:after {
        border-color: transparent transparent $white transparent;
      }
    }
    &:after {
      // content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 11px 11px 11px;
      border-color: transparent transparent $black transparent;
      position: absolute;
      left: calc(50% - 11px);
      bottom: ($space-unit * -7);
    }
    .swiper-wrapper {
      align-items: center;
      cursor: default;
    }
    .swiper-slide {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      // &.swiper-slide-active {
      //   .scrollable-selector {
      //     opacity: 1;
      //   }
      // }
      &:before {
        content: '';
        height: 3.7rem;
        width: 1px;
        background-color: $black;
        position: absolute;
        left: 0;
      }
      .timeline-separator {
        height: 1.8rem;
        width: 1px;
        background-color: $black;
        display: inline-flex;
        margin: 0 0.5rem;
        flex-shrink: 0;
        &:first-child {
          margin: 0 0.5rem 0 1rem;
        }
        &:last-child {
          margin: 0 1rem 0 0.5rem;
        }
        &.d-none {
          display: none;
        }
      }
      .scrollable-selector {
        display: inline-flex;
        font-size: 3.2rem;
        line-height: 1.2;
        overflow: hidden;
      }
    }
  }

  &__container {
    background-color: #1e1e1e;
    padding: 3.2rem 0;
    overflow-x: hidden;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 20%;
      background: linear-gradient(0, rgba(0, 0, 0, 0) 25.89%, rgba(0, 0, 0, 0.5) 100%);
      top: 0;
      left: 0;
    }
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 20%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 25.89%, rgba(0, 0, 0, 0.5) 100%);
      bottom: 0;
      left: 0;
    }
  }

  .cards-swiper {
    -webkit-perspective: 120rem;
    perspective: 120rem;

    &-container {
      z-index: 1;
      position: relative;
    }

    .swiper-wrapper,
    .swiper-slide {
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
    }

    .swiper-slide {
      $factor: 1.15;
      @include animate(0.3);
      width: calc(100vw - 3.2rem);
      .inactive-overlay {
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: 0;
        right: 0;
        background-color: #1e1e1e;
      }

      &.swiper-slide-active {
        z-index: 2;
      }
    }
  }

  .interactive-card {
    padding: 16px;
    background-color: $white;
    padding-bottom: 6rem;
    color: $black;
    &:not(.swiper-slide-active) {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
    .interactive-card__title {
      text-transform: uppercase;
      font-size: 2.4rem;
      line-height: 2.8rem;
      margin-bottom: 0.8rem;
      display: flex;
      flex-direction: column;
      span {
        margin-top: 0.8rem;
      }
    }
    &.swiper-slide-active.cursor-pointer {
      .interactive-card__image {
        cursor: pointer;
      }
    }
    &.large-image {
      text-align: center;
      padding-bottom: 1.6rem;
      .interactive-card__image {
        &:before {
          content: '';
          padding-top: 150%;
        }
      }
      .interactive-card__title {
        text-align: center;
      }
    }
    &__image {
      display: flex;
      margin-bottom: 2.4rem;
      position: relative;
      &:before {
        content: '';
        padding-top: 56.25%;
      }
      img {
        position: absolute;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
      .btn--pause {
        position: absolute;
        bottom: 0;
        z-index: 1;
      }
    }
    &__title {
      text-transform: uppercase;
      font-size: 2.4rem;
      line-height: 2.8rem;
      margin-bottom: 0.8rem;
    }
    &__data {
      text-transform: uppercase;
      font-size: 2rem;
      line-height: 2.4rem;
      margin-bottom: 0.8rem;
      font-weight: bold;
    }
    p {
      font-size: 1.4rem;
      line-height: 1.6rem;
      margin-bottom: 2rem;
    }

    &__button {
      --arrow-background-color: white;
      --perc-background: 57.86%;
      --movement: -0.2rem;
      &.arrow-inverted {
        --arrow-background-color: black;
      }
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 7.3rem;
      height: 8rem;
      padding: 0 2rem;
      z-index: 99;

      &--prev {
        left: var(--movement);
        text-align: left;
        background: linear-gradient(90deg, var(--arrow-background-color) var(--perc-background), rgba(255, 255, 255, 0) 100%);
      }
      &--next {
        right: var(--movement);
        background: linear-gradient(270deg, var(--arrow-background-color) var(--perc-background), rgba(255, 255, 255, 0) 100%);
        text-align: right;
      }
    }
    // .button-row {
    //   display: flex;
    //   justify-content: center;
    //   margin-top: 3.2rem;
    //   .button {
    //     display: inline-flex;
    //     margin: 0 0.4rem;
    //     width: auto;
    //     flex-grow: 1;
    //     flex-basis: 0;
    //   }
    // }
  }
  .arrow-disabled {
    cursor: default;
  }
  .arrow-inverted {
    outline-color: $white;
  }
}
