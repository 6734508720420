.hero.genius {
  &.hero--with-border {
    margin: 2.8rem;
  }
  .hero-column__inner-container {
    padding: 7.8rem 11.15rem;
  }
  .hero-column__title {
    font-size: 14.4rem;
    line-height: 12.384rem;
  }
  .hero-column__label {
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: 1.7rem;
  }
  .btn--pause {
    left: 2.6rem;
    bottom: 2.7rem;
  }
}
