.video-wrapper {
  position: absolute !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  // transform: scale(1.1);
  &:not(.controlled) {
    &,
    * {
      pointer-events: none !important;
    }
    .th-controls {
      display: block !important;
      .th-caption {
        display: none;
      }
    }
  }
  video {
    object-fit: cover;
  }
  &:not(.video-started) {
    &::after {
      background: none;
    }
    .th-media-bg-color {
      background-color: transparent;
    }
  }
}
.wrapper-menu-video {
  width: 100%;
  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 75%;
  }
  .video-wrapper {
    transform: initial;
  }
  video {
    object-fit: cover;
  }
}
