.mbm-entrypoint {
  .container {
    top: 7.4rem;
  }
  .links-wrapper {
    margin-top: 3.8rem;
    margin-bottom: 4rem;
  }
  .big-image {
    max-height: 80rem;
  }
}
