.themed-products {
  &.row {
    margin-right: 0;
    margin-left: 0;
  }
  align-items: center;

  &.isLoading {
    animation: loading 3000ms;
    animation-iteration-count: infinite;
    pointer-events: none;
  }

  &.inverted-colours {
    background-color: $black;
    color: $white;
    .form-select {
      color: $white;
      border: none;
      select {
        border: none;
        color: $white;
        background: $white_inactive;

        &:focus {
          border: 1px solid $white;
        }

        option {
          color: initial;
        }
      }
    }
    .product-tile__quick-buy {
      .button {
        color: $white;
      }
    }
    .themed-products-next {
      &:before {
        background: linear-gradient(270deg, #000000 3.4%, rgba(0, 0, 0, 0) 96.82%);
      }
    }
    .themed-products-prev {
      &:before {
        background: linear-gradient(90deg, #000000 3.4%, rgba(0, 0, 0, 0) 96.82%);
      }
    }
  }
  .selector-color-link .selector__menu.inverted {
    color: $white; //Moved from generic tile style, seems redundant though
  }

  &__image-columns {
    position: relative;
    padding-bottom: 60%;
  }
  &__image-column {
    padding-top: 56.25%; // aspect ratio 16:9 [OE-12764]
    position: relative;
    width: 100%;
  }
  &__image {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    overflow: hidden;
    img {
      width: 100%;
      object-fit: cover;
      animation: fadein 300ms;
    }
  }

  &__carousel-wrapper {
    position: relative;
    &:after {
      // content: '';
      width: 12.8rem;
      // height: 100%;
      left: -1.6rem;
      top: 0;
      z-index: 1;
      position: absolute;
      background: linear-gradient(94.04deg, #ffffff 3.4%, rgba(255, 255, 255, 0) 96.82%);
      opacity: 0.3;
      padding-top: 82.6%;
    }
    &:before {
      // content: '';
      width: 12.8rem;
      // height: 100%;
      height: 0;
      right: -1.6rem;
      top: 0;
      background-color: red;
      position: absolute;
      background: linear-gradient(274.04deg, #ffffff 3.4%, rgba(255, 255, 255, 0) 96.82%);
      opacity: 0.3;
      z-index: 2;
      padding-top: 82.6%;
    }
    .themed-products-prev,
    .themed-products-next {
      &:after {
        @include fixedSprite('chevron-left-big-white');
        content: '';
      }
      position: absolute;
      // top: 50%;
      width: 3.2rem;
      // height: 3.2rem;
      transition: all 0.4s cubic-bezier(0.25, 1, 0.25, 1);
      cursor: pointer;
      z-index: 2;
      &.swiper-button-disabled {
        opacity: 0;
        visibility: hidden;
      }
    }
    .themed-products-next {
      top: 0;
      right: -1rem;
      bottom: 0;
      &:before {
        content: '';
        width: 12.8rem;
        right: -1.6rem;
        top: 0;
        bottom: -9rem;
        // z-index: 1;
        position: absolute;
        background: linear-gradient(270deg, #ffffff 3.4%, rgba(255, 255, 255, 0) 96.82%);
        opacity: 0.3;
        padding-top: 82.6%;
        pointer-events: none;
      }
      &:after {
        transform: rotate(180deg);
      }
    }
    .themed-products-prev {
      top: 0;
      left: -1rem;
      bottom: 0;
      &:before {
        content: '';
        width: 12.8rem;
        left: -1.6rem;
        top: 0;
        bottom: -9rem;
        position: absolute;
        background: linear-gradient(90deg, #ffffff 3.4%, rgba(255, 255, 255, 0) 96.82%);
        opacity: 0.3;
        // z-index: 2;
        padding-top: 82.6%;
        pointer-events: none;
      }
      // &:after {
      //   transform: translateY(-50%);
      // }
    }
    .product-tile {
      .product-tile__info {
        padding-right: ($space-unit);
        padding-left: ($space-unit);
        .product-tile {
          &__price {
            display: inline-block;
          }
          &__quick-buy {
            display: inline-block;
            top: auto;
            right: 4px;
            bottom: auto;
            left: auto;
            padding: 0;
            > button {
              display: inline-block;
              margin: 0;
              padding: 0;
              min-height: auto;
              width: 100%;
              text-align: right;
              height: auto;
            }
          }
        }
        .product-tile__name {
          max-width: 15rem;
          -webkit-line-clamp: 1;
        }
      }
      .product-tag:not(:first-child) {
        display: none;
        color: inherit;
      }
    }
  }
  &__inner-container {
    padding: 3.2rem 1.6rem;
    overflow: hidden;
  }
  &__title {
    text-transform: uppercase;
    font-size: 2.4rem;
    line-height: 2.8rem;
    text-align: center;
    margin-bottom: 2.4rem;
  }

  &__select {
    margin-bottom: 2.4rem;
  }

  .button {
    margin-bottom: 0.8rem;
  }

  &__navigation {
    position: absolute;
    top: 0;
    padding-top: 82.6%;
    left: 0;
    right: 0;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes loading {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.5;
  }
}

//skeleton
.component-themed-products {
  .themed-products {
    &.myfeed-containerImg {
      background-color: $white;

      .myfeed-image-placeholder {
        aspect-ratio: 768/330;
        animation: fadeInOut 2s infinite;
        background-color: $grey_skeleton;
        width: 100%;
      }

      .myfeed-description-placeholder {
        display: flex;
        height: 100%;
        align-items: center;
        gap: 18px;
        width: 100%;
        padding: 20px;

        .tile-placeholder {
          aspect-ratio: 210/316;
          width: 60%;
          animation: fadeInOut 2s infinite;
          background-color: $grey_skeleton;
        }
      }
    }
  }
}
