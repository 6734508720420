.component-two-column-info-block {
  .container-two-column-info-block {
    padding: 0 26.1rem;
    &-border {
      max-width: 91.7rem;
      padding: 0 11.5rem 4rem;

      .two-column-info-block {
        &_description {
          padding: 0 13rem 2.4rem 13rem;
        }
      }
    }
  }
}
