.markup-block {
  // overflow: auto;
  h1,
  h2,
  h3,
  h4 {
    margin-bottom: ($space-unit * 2);
  }
  h1 {
    @extend %primary-xxl;
  }
  h2 {
    @extend %primary-xl;
  }
  h3 {
    @extend %primary-l;
  }
  h4 {
    @extend %primary-m;
  }
  strong {
    font-weight: bold;
  }
  table {
    margin: 1.6rem 0;
  }
  td {
    min-width: 8rem;
    padding-right: 2rem;
  }

  a {
    color: $black !important;
  }
}

.markup-block:has(table) {
  overflow-x: scroll;
}
