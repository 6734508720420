header {
  // &.overlay {
  //   &::before {
  //     opacity: 1;
  //     pointer-events: initial;
  //   }
  // }
  &.header--pdp {
    background: transparent;
    &.transitioning {
      .navigation {
        opacity: 0;
      }
      &::after {
        opacity: 0;
      }
    }
    &.no-transition {
      .listing-container .refine,
      .navigation {
        transition: none;
      }
    }
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      background: transparent;
      // background: linear-gradient(181.75deg, rgba(0, 0, 0, 0.65) 1.48%, rgba(0, 0, 0, 0.1) 65.72%, rgba(0, 0, 0, 0) 84.39%);
      opacity: 0.65;
      height: 100px;
      top: 0;
      z-index: -1;
      @include animate(0.3);
    }
    + main {
      background: initial;
      .refine {
        opacity: 0;
      }
    }
    .navigation {
      background-color: transparent;
    }
    .menu-actions {
      [class^='icon'] {
        filter: invert(1);
      }
    }
    &.scrolled:not(.transitioning) {
      .back-button {
        filter: invert(0);
      }
      .navigation {
        background-color: $white;
      }
      .menu-actions {
        [class^='icon'] {
          filter: invert(0);
        }
      }
    }
  }
  &.checkout {
    + main {
      min-height: calc(var(--viewport-height) - #{$header-height-mobile});
    }
    .navigation {
      h1 {
        bottom: 49%;
        transform: translate(-50%, 50%);
      }
    }
  }
  + main {
    padding-top: $header-height-mobile;
    background-position-y: $header-height-mobile;
  }
  .navigation {
    height: $header-height-mobile;
    .menu-heading {
      &[aria-expanded]::after {
        content: '';
        @include fixedSprite('chevron-right-big');
      }
      &:focus-visible {
        outline: 1px solid $black;
      }
    }
    .menu-wrapper,
    .account-wrapper,
    .menu-close,
    .menu-title {
      visibility: hidden;
    }
    .menu-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2.2rem 1.6rem 1rem 1.6rem;
      background-color: $white;
      z-index: 5;
      > a {
        text-decoration: none;
        font-size: 2.4rem;
        line-height: 2.8rem;
      }
    }
    &.open {
      .banner-wrapper {
        display: none;
      }
      .menu-title {
        visibility: visible;
        opacity: 1;
        .icon--arrow-left-menu {
          display: none;
        }
        > a {
          visibility: hidden;
        }
        .menu-close {
          position: absolute;
          top: 2.2rem;
          right: 1.6rem;
        }
      }
      .menu-wrapper-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: flex-end;
      }
      &.first-level,
      &.second-level {
        .menu-title {
          .icon--arrow-left-menu {
            display: block;
          }
          > a {
            visibility: visible;
          }
          .menu-close {
            position: initial;
          }
        }
      }

      .menu-wrapper,
      .account-wrapper,
      .menu-close {
        transform: translateX(0);
        visibility: visible;
      }
      // tolgo gli elementi fuori dalla modale dalla navigazione da tastiera
      // a.icon--client-service,
      // [data-react='minicart-app'],
      // [data-menu-open] {
      //   visibility: hidden;
      // }
    }

    .menu-heading {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }

    &.first-level {
      .menu-heading,
      .submenu-heading {
        &[aria-expanded='false']::after {
          content: '';
          @include fixedSprite('plus');
        }
        &[aria-expanded='true']::after {
          content: '';
          @include fixedSprite('minus');
        }
        &:focus-visible {
          outline: 1px solid $black;
        }
      }

      .menu-heading {
        transform: translateX(-3.2rem);
        opacity: 0;
      }
      .menu-heading {
        visibility: hidden;
      }
    }
  }
  .logo-header {
    img {
      height: 68px; // ok to be in pixels
      width: auto;
    }

    width: auto;
  }
  .menu-actions {
    margin-right: -0.6rem;
    .account-wrapper,
    .menu-title {
      @include animate(0.4);
      position: fixed;
      top: 0;
      display: flex;
      width: 50%;
      right: 0;
      height: $header-height-mobile;
      padding: 0 ($space-unit * 4);
      align-items: center;
      transform: translateX(100%);
      z-index: 4;
      [class^='icon'] {
        filter: invert(0);
      }
      &.mobile-wrapper {
        position: relative;
        width: 100%;
        margin-top: 2.4rem;
        display: flex;
        gap: 1.2rem;
      }
    }
    .account-wrapper {
      top: initial;
      bottom: 0;
      background-color: $white;
      flex-direction: column;
      height: auto;
      &__item {
        position: relative;
        width: 100%;
        &.logged {
          justify-content: space-between;
        }
      }

      // &.wishlist-mobile {
      //   bottom: 5.6rem;
      // }

      // &.account-login {
      //   bottom: 11.2rem;
      // }
      &.hide {
        display: none;
      }
      // .account-cta__label {
      //   display: inline-block;
      // }

      // .account-cta {
      //   &.large-only {
      //     display: none;
      //   }
      // }

      .account-cta {
        width: 100%;
      }

      .account-logout {
        width: 100%;
        display: inline-flex;
        justify-content: flex-end;
      }
    }

    .menu-title {
      opacity: 0;
      // visibility: hidden;
      background-color: $white;
      justify-content: center;
      button {
        position: absolute;
        left: ($space-unit * 4);
      }
      span,
      a {
        font-size: font-property($moncler-gothic-desktop, 's', 'font-size');
        line-height: font-property($moncler-gothic-desktop, 's', 'line-height');
        letter-spacing: font-property($moncler-gothic-desktop, 's', 'letter-spacing');
        text-decoration: none;
      }
      &.show {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
      }
    }
    .menu-close {
      pointer-events: none;
      justify-content: flex-end;
      button {
        pointer-events: auto;
        // background-color: white;
        &.hide {
          display: none;
        }
      }
    }
    .icon--bag span {
      top: 50%;
      transform: translateY(-45%);
      left: 85%;
    }
    [data-react='minicart-app'] {
      margin-right: 7px;
      order: -1;
    }
    .icon--menu {
      width: 3.6rem;
      height: 3.6rem;
      background-size: 2.4rem;
    }
    .icon--search {
      width: 3.6rem;
      height: 3.6rem;
      background-size: 2.4rem;
    }
    .icon--bag {
      width: 3.6rem;
      height: 3.6rem;
      background-size: 2.4rem;
    }
  }
  .menu-wrapper {
    position: fixed;
    width: 375px;
    right: 0;
    background-color: $white;
    flex-direction: column;
    justify-content: flex-start;
    top: 0;
    height: 100vh;
    // padding-top: ($header-height-mobile + ($space-unit * 8));
    align-items: stretch;
    transform: translateX(100%);
    overflow: hidden;
    overflow-y: auto;
    z-index: 4;
    @include animate(0.4);
    .menu-heading {
      color: $black;
    }
    .menu-close {
      button {
        pointer-events: auto;
        &.hide {
          display: none;
        }
      }
    }

    nav {
      margin-top: 4.2rem;
    }
    nav,
    .menu-actions {
      padding: 0 ($space-unit * 4);
    }
    .menu-actions {
      padding-top: 4rem;
      margin: 0;
      .account-wrapper {
        padding: 0;
      }
    }
  }
  .visual-submenu {
    flex-direction: column;
  }
  .visual-submenu__item {
    flex-basis: initial;
    max-width: 100%;
    margin-bottom: ($space-unit * 4);
    span {
      font-size: font-property($moncler-gothic-mobile, 'xxl', 'font-size');
      line-height: font-property($moncler-gothic-mobile, 'xxl', 'line-height');
      letter-spacing: font-property($moncler-gothic-mobile, 'xxl', 'letter-spacing');
    }
  }

  .menu-heading {
    &,
    a {
      font-family: font-property($moncler-gothic-desktop, 'l-u', 'font-family');
      font-size: font-property($moncler-gothic-desktop, 'l-u', 'font-size');
      line-height: font-property($moncler-gothic-desktop, 'l-u', 'line-height');
      letter-spacing: font-property($moncler-gothic-desktop, 'l-u', 'letter-spacing');
      text-transform: initial;

      span {
        @include lhCrop(font-property($moncler-gothic-desktop, 'l-u', 'top-crop'), font-property($moncler-gothic-desktop, 'l-u', 'bottom-crop'));
      }
    }
  }
  .menu-heading,
  .submenu-heading {
    &,
    a {
      height: ($space-unit * 10);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      width: 100%;
    }

    &.d-none {
      display: none;
    }

    // &[aria-haspopup]::after {
    //   transform: rotate(180deg);
    //   margin-right: -0.6rem;
    // }
    &[aria-expanded='true'] ~ .wrapper-submenu,
    &[aria-expanded='true'] ~ .main-submenu,
    &[aria-expanded='true'] ~ ul {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
      > .submenu-group.coming-soon ul {
        visibility: visible;
      }
      // visibility: hidden;
    }
  }
  .submenu-group {
    .subcategories-list {
      height: 0;
      overflow: hidden;
      transition: height 0.5s ease-in-out;
    }
  }
  .submenu-group,
  .submenu-group:last-of-type {
    padding-bottom: 0;
  }
  .submenu-group.coming-soon {
    .submenu-heading {
      height: auto;
      padding-top: 3.2rem;
      padding-bottom: 1.6rem;
      font-size: 1.6rem;
      line-height: 2rem;
      + ul {
        position: relative;
        opacity: 1;
        transform: initial;
        height: auto;
        padding: 0;
      }
    }
  }
  .submenu-heading {
    @include animate(0.3, 'width');
    &,
    a {
      font-family: font-property($moncler-gothic-mobile, 'm', 'font-family');
      font-size: font-property($moncler-gothic-mobile, 'm', 'font-size');
      line-height: font-property($moncler-gothic-mobile, 'm', 'line-height');
      letter-spacing: font-property($moncler-gothic-mobile, 'm', 'letter-spacing');
      text-transform: uppercase;
      margin-bottom: 0;
      cursor: pointer;
      width: 100%;

      span {
        @include lhCrop(font-property($moncler-gothic-mobile, 'm', 'top-crop'), font-property($moncler-gothic-mobile, 'm', 'bottom-crop'));
      }
    }
    + ul {
      height: 0;
      // display: none;
      // position: absolute;
      opacity: 0;
      visibility: hidden;
      // top: 0;
      // left: 0;
      // width: 100%;
      // background-color: $white;
      // // transform: translateX(3.2rem);
      // flex-direction: column;
      // height: 100vh;
      // padding: 9.2rem ($space-unit * 4) 6rem;
      // overflow: auto;
      // justify-content: flex-start;
      // @include z;
      // @include animate(0.3);
      li {
        margin-bottom: 0;
      }
    }
  }

  .wrapper-submenu {
    &.full-width {
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 0;
      padding-top: 6rem;
    }
  }

  .navigation:not(.first-level) {
    .menu-wrapper-list {
      .wrapper-submenu {
        &#mbmmodule-navdialog {
          padding-top: 9.2rem;
        }
      }
    }
  }

  .wrapper-submenu__collection {
    display: flex;
    flex: 1;
    height: 100%;
  }

  .collection-submenu {
    height: 100%;
    width: 100%;
    position: relative;
    padding: 0 1.6rem;
    .collection-submenu__item {
      .collection-card-tab {
        font-weight: bold;
        span {
          &:after {
            content: '';
            height: 2px;
            width: 100%;
            background-color: $white;
            position: absolute;
            bottom: -1px;
            left: 0;
          }
        }
      }
      .collection-card {
        opacity: 1;
        z-index: 2;
      }
    }
    .collection-card-tab {
      position: relative;
      z-index: 3;
      text-transform: uppercase;
      display: block;
      text-align: center;
      color: $white;
      font-weight: 400;
      height: 8rem;
      span {
        position: relative;
        display: block;
        padding: 3.4rem 1rem 1.4rem 1rem;
        border-bottom: 1px solid rgba($white, 0.5);
      }
    }
  }

  .wrapper-submenu,
  .submenu-column .main-submenu {
    top: 0;
    left: 0;
    transform: translateX(3.2rem);
    flex-direction: column;
    height: 100vh;
    padding: ($header-height-mobile + ($space-unit * 8)) ($space-unit * 4) ($space-unit * 4);
    overflow: hidden;
    overflow-y: auto;
    justify-content: flex-start;
    pointer-events: all;
    @include z;
  }

  .navigation {
    &.second-level,
    &.first-level {
      .menu-wrapper-list {
        .wrapper-submenu,
        .wrapper-submenu.has-banner {
          padding: ($header-height-mobile + ($space-unit * -1) 0 0);
          .container-submenu {
            .main-submenu {
              border-top: 1px solid $grey-line;
            }
          }
          &#collections-navdialog {
            padding: ($header-height-mobile + 0 0 0);
          }

          &__banners {
            .banners-submenu {
              .experience-menu_banner {
                aspect-ratio: 4 / 3;
                .banner {
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }
        }
        .submenu-group {
          .submenu-heading {
            font-size: 1.4rem;
            line-height: 1.6rem;
          }
          .subcategories-list {
            border-bottom: 1px solid $grey-line;
          }
          .submenu-heading,
          .subcategories-list {
            padding: 0 1.6rem;
            > ul {
              padding-bottom: 1.6rem;
            }
          }
          .submenu-item {
            font-size: 1.6rem;
            line-height: 2rem;
          }
        }
      }
      .menu-wrapper-content {
        visibility: hidden;
      }
    }
  }

  .container-submenu {
    padding: 0;
    flex-direction: column;
    min-height: 100%;
    justify-content: flex-start;
    &::before {
      content: none;
    }
    &.banner-mobile {
      .main-submenu {
        flex-grow: 1;
      }
      .menu-assets {
        display: block;
        margin: 0;
        z-index: 1;
        // margin-bottom: 3rem;
      }
    }
  }
  .highlights-submenu,
  .main-submenu {
    display: flex;
    flex-direction: column;
  }
  .submenu-item {
    display: flex;
    align-items: center;
    height: ($space-unit * 10);
    font-size: 2rem;
    line-height: ($space-unit * 6);
    letter-spacing: 0;
    .launch-date {
      display: none;
    }
  }

  .submenu-column {
    .main-submenu {
      position: absolute;
      width: 100%;
      opacity: 0;
      visibility: hidden;
      @include animate(0.3);
      background-color: $white;
    }
  }
  // .menu-title,
  // .menu-close {
  //   z-index: 4;
  // }

  &.sm-hide-on-scroll {
    transition-duration: 0.8s;
    .navigation {
      @include animate(0.5);
      &:hover {
        @include animate(0);
      }
    }
    &:not(.video-full-screen)[data-scroll='up'] {
      transform: translateY(0);
      .logo-header {
        opacity: 1;
        visibility: visible;
      }
    }
    &[data-scroll='down'] {
      transform: translateY(-100%);
      .logo-header {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}

body.has-ticker {
  .header--pdp::after {
    top: $ticker-bar-m;
  }
}
