.sort {
  position: relative;

  .sort__list--wrapper {
    height: 0;
    overflow-y: hidden;
    transition: height 0.2s ease-in-out;
    position: absolute;
    right: -2rem;
  }
  .sort__button {
    margin-right: 0;
    // min-width: 11rem;
    text-align: left;
  }
  .sort__list {
    padding: ($space-unit * 2) ($space-unit * 8) 2rem;
    display: flex;
    background-color: $white;
    z-index: 1;
    flex-direction: column;
    align-items: baseline;
  }

  .sort__option {
    display: inline-flex;
    white-space: nowrap;
    margin-bottom: ($space-unit * 3);
    @include hover;
    span {
      position: relative;
      padding-right: 1.8rem;
    }

    &[aria-selected='true'] {
      span::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        margin-top: -0.2rem;
        @include animate;
        @include fixedSprite('checkmark');
      }
    }
    &:hover:focus-visible {
      outline-color: white;
    }
  }
}
