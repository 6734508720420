header {
  .wrapper-submenu {
    .container-submenu {
      .main-submenu-wrapper {
        .highlights-submenu {
          margin-right: clamp(1.6rem, 8vh, 15rem);
        }
      }
    }
  }
}
