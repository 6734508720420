.info-modal {
  &.modal {
    min-width: 0;
    max-width: 100%;
  }
  &__description {
    padding-top: ($space-unit * 6);
  }
}

.items-step__btn-container {
  .button {
    max-width: 100%;
    &:first-child {
      display: none;
    }
  }
}
