.themed-products {
  position: relative;
  // &__inner-container {
  //   padding: 3.2rem 0;
  // }
  &__image-column {
    padding: 0;
    position: absolute;
    width: 50%;
    height: 100%;
    left: 0;
    top: 0;
  }
  &__select {
    margin-top: 2.4rem;
    margin-bottom: 4.8rem;
  }
  &__image {
    max-height: none;
    height: 100%;
  }

  &__carousel-wrapper {
    padding-left: ($space-unit * 4);
    &:after {
      left: 0;
      padding-top: 48.5%;
    }
    &:before {
      right: 0;
      padding-top: 48.5%;
    }
    .themed-products-next {
      right: 1rem;
    }
    .themed-products-prev {
      left: 1rem;
    }
    .product-tile {
      .product-tile__info {
        padding-bottom: 5rem;
      }
    }
  }
  &__navigation {
    padding-top: 48.5%;
  }
  &__ctas-wrapper {
    margin-top: 5rem;
  }
  .selector-color-link {
    .selector__menu {
      flex-wrap: wrap;
      row-gap: 0.4rem;
    }
  }
}

.component-themed-products {
  .my-feed-exclusive-products-wrapper {
    .themed-products {
      aspect-ratio: 1024/503;
      .themed-products__inner-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        padding: 0;
        .themed-products__title,
        .themed-products__select,
        .themed-products__ctas-wrapper {
          margin: 0 auto;
        }
      }
      .themed-products__image-column {
        aspect-ratio: 512/503;

        .themed-products__image {
          width: 100%;
          aspect-ratio: 512/503;
        }
      }
    }
  }
  .themed-products {
    aspect-ratio: 1024/567;

    .themed-products__image-column {
      aspect-ratio: 512/567;

      .themed-products__image {
        width: 100%;
        aspect-ratio: 512/567;
      }
    }

    &.myfeed-containerImg {
      display: flex;
      justify-content: center;
      align-items: center;

      .myfeed-image-placeholder {
        aspect-ratio: 512/503;
        width: 50%;
      }

      .myfeed-description-placeholder {
        width: 50%;
      }
    }

    .themed-products__inner-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-evenly;
      padding-top: 4rem;

      &.only-carousel {
        justify-content: space-around;
      }

      .themed-products__title,
      .themed-products__ctas-wrapper {
        margin: 0 auto;
      }
    }
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
