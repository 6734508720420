.curator-video {
  // .video-module__inner-container {
  //   height: 84.9rem;
  // }
  .video-container {
    height: 3.2rem;
    .time-text {
      font-size: 60px;
      line-height: 56px;
      position: absolute;
      left: 13%;
    }
  }
}
