.sub-category-tiles-component {
  .sub-category-tiles-section {
    .sub-category-tile {
      flex-basis: 50%;
      max-width: 50%;
      &_link {
        width: auto;
        padding-left: 4.2rem;
        padding-right: 4.2rem;
      }
    }
  }
}
