// FIX for iOS Safari
.product-hero-gallery.fix-flicker-ios {
  .swiper-wrapper {
    transform: none !important;
  }
  .swiper-slide.swiper-slide-active {
    transform: none !important;
    position: fixed;
  }
}
