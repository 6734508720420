.client-service-content {
  .link-section {
    padding: ($space-unit * 20) 0;
  }
  .link-box__button.button {
    margin-top: ($space-unit * 8);
  }
  .link-box {
    &__desc {
      padding: 0 20%;
    }
  }
}

.client-service {
  .client-service {
    &__description {
      text-align: center;
    }
    &__column-wrapper {
      display: block;
      > * {
        width: 100%;
        margin-bottom: ($space-unit * 8);
      }
    }
  }
}

.section--intro-section {
  .client-service {
    &__column-wrapper {
      & > * {
        width: 100%;
        margin-bottom: ($space-unit * 4);
      }
    }
  }
}

.section--only-title {
  .client-service__title {
    margin: 0;
  }
}

.section--only-title {
  @include border('bottom');
  padding-bottom: ($space-unit * 6);
  margin-bottom: ($space-unit * 4);
}
