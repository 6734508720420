#mybotique {
  .mybotique-getdirections {
    display: block;
    position: relative;
    padding-top: ($space-unit * 8);
    padding-bottom: ($space-unit * 4);
  }
}

#personalinfo_rpy {
  padding-bottom: 88px;

  dd.dd--40vw {
    width: 45vw;
    .personalinfo-edit-rpy {
      right: 5%;
    }
  }
}
