.store-info {
  display: grid;
  grid-template-columns: auto 2fr;
  column-gap: $space-unit * 3;
  row-gap: $space-unit * 2;
  word-wrap: break-word;
  .store-services {
    p {
      margin-bottom: $space-unit;
    }
  }
  + button {
    margin-top: ($space-unit * 6);
  }
  dt,
  dd {
    min-width: 0;

    &.hasDOSService-disclaimer {
      font-weight: bold;
      margin: 0.8rem 1.6rem 1.2rem 0;
      font-size: ($space-unit * 3);
      line-height: 1.4rem;
    }
  }
  dt {
    @extend %primary-xxs-u;
  }
  a {
    @include hoverablelink;
  }
  p {
    margin-bottom: $space-unit * 2;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
