.card-placeholder {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-desc {
    margin-bottom: 24px;
  }

  &::after {
    content: '';
    width: 221px;
    height: 130px;
    @include fixedSprite('giftcard-placeholder');
  }
}

.balance {
  &-container {
    text-align: center;

    .check-another {
      margin-top: 40px;
    }
  }
}

.gc-error-box {
  margin-top: 0 !important;

  &.gc-checkout {
    margin-top: -9px !important;
  }
}

.gc-havecard {
  margin-top: 20px;
  opacity: 0.55;

  button {
    text-decoration: underline;
  }
}

.gc-error-modal {
  display: grid;
  place-items: center;

  h2 {
    font-size: 2rem;
    line-height: 2.4rem;
    margin-top: 8.4rem;
    margin-bottom: 2.2rem;
    text-transform: uppercase;
  }

  p {
    text-align: center;
    margin-bottom: 12.4rem;
  }
}

.gc-icons {
  display: flex;
  gap: 8px;
  margin-top: 26px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 10px;
    line-height: 12px;
    flex: 0 1 95px;
  }

  .gc-icon {
    &-amount::after {
      @include fixedSprite('giftcard-amount');
      content: '';
      width: 40px;
      height: 40px;
    }

    &-onlinestore::after {
      @include fixedSprite('giftcard-onlinestore');
      content: '';
      width: 40px;
      height: 40px;
    }

    &-deliverydate::after {
      @include fixedSprite('giftcard-deliverydate');
      content: '';
      width: 40px;
      height: 40px;
    }

    $svgs: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 'unlimited';

    @each $svg in $svgs {
      &-validity--#{$svg}::after {
        content: '';
        width: 40px;
        height: 40px;
        @include fixedSprite('giftcard-validity-#{$svg}');
      }
    }
  }

  img {
    width: 40px;
    height: 40px;
  }

  p {
    margin-top: 6px;
    text-align: center;
    font-size: 14px;
  }
}

.gc-return-info {
  gap: 4px;
  margin-bottom: 14px;
  opacity: 0.55;
}

.gc-config-modal {
  .d-none {
    display: none !important;
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }

  .modal__header {
    padding: 0;
    h2 {
      padding: 0;
    }
  }

  .modal__body {
    .product-notify__detail {
      overflow-x: hidden;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }

      .optionbox__item {
        max-width: 50% !important;

        &__content {
          padding-block: 1.7rem;
        }
      }
    }

    .giftcard-form {
      .optionbox__item__radio {
        top: calc(50% - 0.7rem);
      }
    }

    h2 {
      margin-top: 32px;
      font-size: 20px;
    }

    .modal__separator {
      margin-top: 16px;
      margin-bottom: 12px;
    }

    .gc-amount {
      margin-top: 32px;
      font-size: 12px;
      opacity: 0.55;
      text-transform: uppercase;
    }

    .gc-amount-btn {
      margin: 12px 0 0;
      margin-right: 19px;
      padding: 0;
      padding-bottom: 2px;
      border-bottom: 1px solid transparent;
      text-wrap: nowrap;

      &-alt {
        @extend .gc-amount-btn;
        padding-bottom: 2px;
        border-bottom: 1px solid $black;
      }
    }

    .gc-custom-check {
      margin-top: 22px;
    }

    .field-label {
      padding-bottom: 8px;
      font-size: 12px;
    }

    .field-sublabel {
      margin-top: 4px;
      font-size: 12px;
      opacity: 0.55;
    }

    .cancel-button {
      margin-top: 0;
      text-align: center;
    }

    .tooltip {
      margin-top: 24px;
      button {
        margin-bottom: 0;
      }
      .tooltip__box {
        left: 12px;
        min-width: 12rem;

        .nowrap {
          white-space: nowrap;
        }
      }
    }

    .button-row {
      margin-top: 40px;
    }

    .giftcard-update-button-row {
      margin-top: 0;
    }
  }
}

.gc-checkout {
  &-checkbox {
    color: rgb(0, 0, 0);
    width: 1.4rem;
    height: 1.4rem;
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid rgb(0, 0, 0);
    cursor: pointer;
    flex-shrink: 0;
    border-radius: 0;
    background-color: initial;

    &:disabled {
      border: 1px solid rgba(0, 0, 0, 0.35);
      cursor: initial;

      + label {
        color: rgba(0, 0, 0, 0.35);
        cursor: initial;
      }
    }

    &:checked {
      &::after {
        content: '';
      }
    }

    &::after {
      position: relative;
      display: block;
      height: 0.4rem;
      width: 0.6rem;
      border-left: 1px solid;
      border-bottom: 1px solid;
      transform: rotate(-45deg);
      left: 0.3rem;
      top: 0.3rem;
      box-sizing: border-box;
      content: none;
    }
  }

  &-check-label {
    padding: 0.4rem 0 0.4rem 0.8rem;
  }

  &-apply-btn {
    margin-top: 18px;
  }

  &-remove-btn {
    margin-top: 4rem;
  }

  &-applied {
    &-label {
      margin-bottom: 0.4rem;
      @extend %primary-xxs;
    }
    &-input {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 8px;
      border-bottom: 1px solid $grey_bottom;
      background-color: $grey_input;
      color: #737373;

      &::after {
        @include fixedSprite('checkmark-grey');
        content: '';
      }
    }
  }

  &-another::after {
    @include fixedSprite('plus');
    content: '';
  }
}
