.collection-card {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  background-color: $black;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.1105) 13.04%,
      rgba(0, 0, 0, 0.2635) 29.67%,
      rgba(0, 0, 0, 0.6205) 50.67%,
      rgba(0, 0, 0, 0.765) 76.93%,
      rgba(0, 0, 0, 0.85) 100%
    );
  }
  &:focus-visible {
    // the absolute positioned picture would hide the native outline
    outline: none;
    &::before {
      content: '';
      position: absolute;
      top: 0.2rem;
      left: 0.2rem;
      bottom: 0.2rem;
      right: 0.2rem;
      z-index: 1;
      border: 1px solid $white;
      pointer-events: none;
    }
  }
  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    opacity: 1;
  }
  &__inner-content {
    position: absolute;
    z-index: 2;
    left: 50%;
    padding-bottom: 3.2rem;
    bottom: 0;
    top: auto;
    transform: translateX(-50%);
    text-align: center;
    color: $white;
    max-width: 31rem;

    img {
      max-height: 9rem;
      width: auto;
      margin: 0 auto 2.4rem;
    }
  }
  &__title {
    text-transform: uppercase;
    font-size: 1.8rem;
    line-height: normal;
    margin: 0;
  }
  &__description {
    display: none;
  }
  .button {
    font-size: 1.4rem;
    margin-top: 1.8rem;
    &::after {
      top: -0.3rem;
      transform: none;
    }
    &:hover {
      border-color: $white;
      background: transparent;
      color: rgba($white, 0.6);
    }
  }
}
