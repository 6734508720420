.page-template-immersiveparfumes {
  main {
    padding-top: $contextual-navigation + $header-height-mobile;
  }
}

body.has-ticker {
  &.page-template-immersiveparfumes {
    main {
      padding-top: $contextual-navigation + $header-height-mobile + $ticker-bar-m;
    }
  }
}
