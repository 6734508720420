.bag-summary {
  padding-bottom: 4rem;
  &.checkout {
    // padding: 1.2rem 1.2rem 0;
    position: relative;
    padding: 0;
    // border: 1px solid $grey_line;
    // background-color: white;
    margin-bottom: 1.2rem;
    margin-top: 1.2rem;
    @include z;
    @include animate;
    .items--totals-clothing {
      padding-top: 1.6rem;
      padding-bottom: 0.4rem;
      font-weight: bold;
    }

    .minibag__productcontainer {
      margin-bottom: 2.4rem;
      border-bottom: initial;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .minibag__product__title {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .checkout--summary {
      display: flex;
      align-items: center;
      padding: 1.2rem;
      border: 1px solid $grey_line;
      width: 100%;
      &__block {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
      }
      .items__value {
        padding-left: 2.4rem;
      }
      // .total {
      //   display: flex;
      //   align-items: center;
      //   flex-grow: 1;
      //   .items__label {
      //     flex-grow: 1;
      //   }
      // }
    }
    // .total {
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;
    //   width: 100%;
    //   padding-bottom: 2.4rem;
    //   font-weight: bold;
    // }
    .collapsed-row {
      padding-left: 1.2rem;
      padding-right: 1.2rem;
    }
    .wrapper-info-total {
      font-size: 16px;
      line-height: 20px;
      padding-bottom: 2rem;
    }
    .collapsed-row {
      overflow: hidden;
      @include animate(0.75);

      .divider--margin-slim {
        margin-top: 4px;
        margin-bottom: 16px;
      }
    }
    .checkout-products {
      max-height: 226px;
      list-style: none;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 1rem;
      margin-bottom: 0.7rem;
      width: calc(100% + 1.2rem);
      @include scrollbar;
      .minibag__productcontainer {
        padding: 0;
        margin-bottom: 3.2rem;
        &:last-child {
          margin-bottom: 2.9rem;
        }
        .minibag__product {
          &__image {
            flex-basis: 5.4rem;
            .product-image {
              height: 8.1rem;
            }
            .product-image-button {
              cursor: pointer;
            }
          }
          &__title {
            justify-content: flex-start;
          }
          &__info {
            margin-top: 8px;
          }
        }
      }
    }
    .edit {
      display: flex;
      position: relative;
      @include del-link;
      // color: $black_secondary;
      text-transform: uppercase;
      align-items: center;
      column-gap: 0.8rem;
      padding: 0.4rem 0;
      margin-bottom: 2rem;
      margin-top: 1.2rem;
      // &:before {
      //   content: '';
      //   position: absolute;
      //   top: 0;
      //   width: 100%;
      //   height: 1px;
      //   display: block;
      //   background-color: $grey_line;
      // }
      &::after {
        content: '';
        display: block;
        @include fixedSprite('arrow-right-black');
      }

      &:focus {
        @include focus-underline;
      }
    }
    .details-button {
      height: 2.4rem;
      margin-left: 0.8rem;
      margin-top: initial;
      &__open::after {
        transform: rotate(-180deg);
        @include fixedSprite('arrow-down');

      }
      &__close::after {
        @include fixedSprite('arrow-down');
      }
    }
    .tax-disclaimer--wrapper {
      margin-top: 1.6rem;
      margin-bottom: 1.2rem;
    }

    .items {
      padding-bottom: 0.8rem;
    }

    .items--totals {
      padding-top: 1.6rem;
      padding-bottom: 2.4rem;
      border-top: 1px solid $grey_line;

      &:last-child {
        padding-top: 2.8rem;
        padding-bottom: 1.2rem;
      }

      &.totals-clothing {
        padding-bottom: 0.4rem;
      }

      &.no-borders{
        border: none;
      }

      .capitalize {
        text-transform: capitalize;
      }
    }

    .items--totals + .items--totals {
      padding-top: 0;
      margin-top: -3px;
    }
  }
  .product-selection__shipping-disclaimer {
    margin-top: 0;
    margin-bottom: 1.6rem;
    .shipping-disclaimer_description {
      span {
        color: $black;
      }
    }
  }
  .tooltip.packaging-info {
    display: inline-flex;
    margin-left: 0.6rem;
    .icon--info {
      height: 1.2rem;
      width: 1.2rem;
    }
  }
  .items--totals-available {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2.4rem;
  }
  .items--clothing {
    display: flex;
    justify-content: space-between;
    p {
      @extend %primary-xs;
      padding-bottom: 0.4rem;
    }
  }
  .items {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 0.4rem;
    @extend %primary-xs;
    &--totals {
      @extend .items;
      font-weight: bold;
      padding: 1.6rem 0;
      @extend %primary-s;

      .fw-normal {
        font-weight: normal
      }
    }
    &--summary {
      @extend .items;
      font-weight: bold;
      padding: 1.6rem 0 1.2rem;
      .items__label {
        @extend %primary-s;
      }
      .items__value {
        text-transform: uppercase;
        padding-top: 0.3rem;
        white-space: nowrap;
      }
    }
    &--subtotal {
      @extend .items;
      @extend %primary-s;
      padding: 2.4rem 0;
      &.items--subtotal-clothing {
        padding: 2.4rem 0 0.4rem 0;
      }
      &.exchange-subtotal {
        padding-bottom: 0.4rem;
      }
    }

    &__label {
      text-align: left;
    }
  }
  .accordion-mini {
    padding: 2.4rem 0;
    text-align: left;
  }
  .button-row {
    padding-top: $space-unit * 4;
  }
  .expecteddelivery {
    @include border('top');
    margin-top: $space-unit * 3;
  }
  .tax-disclaimer--wrapper {
    margin-top: 0;
  }
  .bag-summary__ctas {
    display: grid;
    row-gap: 0.8rem;
  }
  .checkout--summary {
    @include animate;
    transition-property: opacity, visibility;
  }
  .fixed-summary-mobile {
    @include animate(1);
    position: fixed;
    opacity: 1;
    visibility: hidden;
    z-index: 1;
    background-color: white;
    box-shadow: -1px 0 0 0 #e4e4e4, 1px 0 0 0 #e4e4e4, 0 0 0 0 #e4e4e4, 0 1px 0 0 #e4e4e4;
    transition-property: opacity, visibility;
    &.visible {
      z-index: 0;
      border: 0;
      opacity: 1;
      visibility: visible;
      box-shadow: 0 0 10px #e4e4e4, 0 0 3px #e4e4e4, 0 0 5px #e4e4e4, 0 0 3px #e4e4e4;
      + .checkout--summary {
        opacity: 1;
        visibility: hidden;
      }
    }
    .checkout--summary {
      border: initial;
    }
  }

  .gc-cart-disclaimer{
    opacity: 0.55;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
      display: block;
    }
    to {
      opacity: 0;
      display: none;
    }
  }

  &.fade-out {
    animation: fadeOut 0.3s linear;
    opacity: 0;
    display: none;
  }
}
.accordion-summary {
  position: absolute;
  right: 0;
  top: 0;
}
.details-button {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  height: 1.4rem;
  align-items: center;
  @extend %primary-xxs;
  &__open {
    @extend .details-button;
    &::after {
      content: '';
      @include fixedSprite('minus');
    }
  }

  &__close {
    @extend .details-button;
    &::after {
      content: '';
      @include fixedSprite('plus');
    }
  }
  &__container {
    position: absolute;
    width: calc(100vw - var(--scrollbar-width));
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: flex-end;
    top: 2rem;
    // @include animate(0.3);
  }
}
.bag-info-box {
  padding: 1.2rem;
  border: 1px solid $grey_line;
  margin-bottom: 1.6rem;
  .button-login {
    display: flex;
    margin-top: 0.8rem;
    text-transform: uppercase;
    height: 2.4rem;
    font-weight: bold;
    align-items: center;
    width: 100%;
  }
}

.membership-small-banner {
  display: flex;
  padding: 0;
  &__image {
    justify-content: center;
    align-items: center;
    display: inline-flex;
    width: 8.8rem;
    min-height: 8.8rem;
    background-size: cover;
    min-width: 8.8rem;
    img {
      max-width: 85%;
    }
  }
  &__content {
    padding: 1.2rem;
    text-align: left;
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    .button-login {
      &.justify-initial {
        justify-content: initial;
      }
    }
  }
}
