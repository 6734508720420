.checkout-pickup {
  .mandatory-fields {
    margin-bottom: 1.6rem;
    color: $black_secondary;
  }
  .optionbox--horizontal-two-items {
    margin-bottom: 1.6rem;
    max-height: 436px;
    @include scrollbar;
  }
  .pickup-city-selector {
    margin-bottom: 2.4rem;
  }
  .checkout-pickup__map {
    margin-bottom: $space-unit * 6;
  }
  .option-store {
    padding-left: 0;
    width: 100%;
    .clothing-office__title {
      margin-left: 0;
    }
  }
  .store-details {
    .email {
      margin-bottom: 0.8rem;
    }
    .store-details__hours {
      width: 100%;
      .store-details__title {
        display: block;
      }
      dl {
        width: auto;
        display: inline-grid;
        grid-template-columns: repeat(2, max-content);
        align-items: center;
        column-gap: 1rem;
        row-gap: 0.4rem;
      }
      dt,
      dd {
        width: auto;
      }
    }
  }
  .globalerrors {
    &.addressban {
      padding-top: 0;
    }
  }
}
