.bag-product__aftercare-info {
  width: 100%;
  .order-detail__column {
    margin-bottom: ($space-unit * 6);
  }
}
.row-info__button-container {
  margin-bottom: ($space-unit * 2);
  &:last-child() {
    margin-bottom: 0;
  }
}

.orders-listing.aftercare-wrapper {
  margin-bottom: 24px;
}

.orders-listing {
  .aftercare-tile-wrap {
    padding: 40px 64px;
    align-items: center;
    border: 1px solid #e4e4e4;
    margin-bottom: 32px;
    justify-content: start;
    .request-image-wrap {
      margin-right: 64px;
      img {
        width: 126px;
        min-width: 126px;
      }
    }
    .tile-description {
      font-size: 16px;
      line-height: 20px;
      font-weight: bold;
    }
    .af-status-wrap {
      .tile-main-title {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 8px;
      }
      .tile-description {
        color: rgba(0, 0, 0, 0.55);
        font-weight: normal;
      }
    }
    .request-text-wrap {
      display: flex;
      gap: 100px;
      .tile-secondary-title {
        margin-bottom: 8px;
      }
      .text-nowrap {
        white-space: nowrap;
      }
      .request-text-left-wrap {
        display: flex;
        gap: 100px;
      }
    }
  }
}

.client-service {
  .aftercare {
    .aftercare_accordion {
      padding-bottom: 120px;
    }
  }
}
.aftercare__text {
  font-size: 16px;
}
.aftercare__descriptionTitles {
  display: flex;
}
