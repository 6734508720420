.product-selection {
  padding-top: ($space-unit * 6);
  padding-bottom: 6rem;

  .product-selection__heading--placeholder {
    width: 100%;
    display: block;
    position: relative;
  }
  .selector-size-expanded {
    .selector__header {
      align-items: flex-start;
    }
  }
  .product-selection__heading {
    // padding-top: ($space-unit * 4);
    // padding-bottom: $space-unit;
    &.container {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      background-color: $white;
      @include z;
    }
  }
  .selector-size-expanded {
    .selector__header {
      padding-top: 2.2rem;
    }
  }
  .link-anchor {
    margin-bottom: 6.5rem;
    &.no-margin-bottom {
      margin-bottom: 3.5rem;
    }
  }
  .mbm-icons {
    margin-bottom: 10rem;
  }

  .product-selection__ctas {
    margin-bottom: 0.8rem;
    button {
      height: ($space-unit * 12);
      span {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
    .wishlist-icon {
      display: none;
    }
  }
  .product-selection__actions-shipping {
    &.on-pdp {
      padding-top: 1.2rem;
      justify-content: space-between;
    }
  }
}
.drawers {
  .wrapper-product-details {
    .react-tabs__tab-panel {
      visibility: hidden;
      opacity: 0;
      transform: translateY(150px);
      transition: all 0.75s cubic-bezier(0.25, 0.5, 0.25, 1);
    }
  }
  &.ReactModal__Content--after-open {
    .wrapper-product-details {
      .react-tabs__tab-panel--selected {
        opacity: 1;
        visibility: visible;
        pointer-events: initial;
        transform: translateY(0);
        transition-property: all;
      }
    }
  }
}

.product-details-tab {
  .composition-care-icons-container {
    width: 80%;
  }
  &.drawer-shipping-returns,
  &.drawer-details-composition {
    .grenoble-details {
      .grenoble-details__item {
        flex-basis: calc(100% / 4);
      }
    }
  }
}
