.search-container {
  .no-results-container {
    .react-tabs__tab-panel--selected {
      min-height: 40vw;
    }
    .collection-submenu {
      padding: 0 3.2rem;
    }
    .container {
      .swiper-initialized {
        padding: 0 3.2rem;
      }
    }
  }
}

.search-overlay {
  .product-tile__image-wrapper {
    .product-labels {
      &.hovering {
        bottom: 1.2rem;
      }
    }
  }
}
