.curator-quote {
  color: white;
  .curator-quote-container {
    margin: 0 auto;
    padding: 28rem 0;
    .quote-img {
      padding-bottom: 2.8rem;
      max-width: 11%;
      min-width: 8rem;
    }
    .quote-title {
      padding-bottom: 4rem;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
    }
    .quote-text {
      p {
        font-family: $silkSerifLight;
        font-style: normal;
        font-weight: 300;
        font-size: 42px;
        line-height: 50px;
      }
    }
    .quote-paragraph {
      p {
        font-family: $silkSerifLight;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 28px;
        width: 80%;
        margin: 0 auto;
      }
    }
    &.quotations {
      .quote-paragraph {
        padding-top: 8rem;
        p {
          width: 60%;
          margin: 0 0 0 auto;
        }
      }
    }
  }
}
