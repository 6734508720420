.page-template-monclernowportal {
  .newsletter-module {
    @include animate(0.5);
    opacity: 0;
  }
}

.monclernow-tabpanel {
  &:focus {
    outline-offset: -0.5rem;
    outline-width: 0.3rem;
  }
}

.monclernow-listing {
  display: flex;
  flex-wrap: wrap;
  padding: 6.4rem 2.8rem 0;
  justify-content: space-between;
  .newsletter-module {
    width: 100%;
  }
  &__number-order {
    @extend %primary-xxxl;
    position: absolute;
    left: 2rem;
    top: 2rem;
    z-index: 2;
    font-weight: 700;
    color: black;
    background: yellow;
    width: 90px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
  }
  &__article {
    position: relative;
    width: calc(33.33% - 0.4rem);
    display: block;
    text-decoration: none;
    margin-bottom: 4rem;
    a {
      text-decoration: none;

      &:focus {
        .article__info-wrapper {
          @include focus-underline;
        }
      }
    }
  }

  &::after {
    content: '';
    display: inline-block;
    width: calc(33.33% - 0.4rem);
  }

  &__btn-row {
    width: 100%;
    text-align: center;
    margin-bottom: 7rem;
    margin-top: 5.2rem;
    text-transform: uppercase;
  }
}

.article {
  &--landscape {
    width: 82.8%;
    margin: 0 auto 6.4rem;
    &:not(.article--two-images) {
      .image-wrapper__img {
        &::before {
          padding-top: 49.83%;
        }
      }
    }
    .article {
      &__info-wrapper {
        .info-wrapper {
          &__title {
            @extend %primary-xl;
          }
        }
      }
    }
  }

  &__image-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &--two-images {
    .image-wrapper {
      &__img {
        width: calc(50% - 0.2rem);
      }
    }
  }
  & {
    &__info-wrapper {
      display: flex;
      padding: 2.4rem 1.6rem 0;
      justify-content: center;
      flex-wrap: wrap;

      .info-wrapper {
        &__title {
          @extend %primary-m;
          text-transform: uppercase;
          display: block;
          width: 100%;
          text-align: center;
          margin-top: 0.8rem;
        }
        &__date {
          @extend %primary-xxs;
        }
      }

      span {
        margin: 0 0.4rem;
        display: block;
        line-height: 1;
      }
    }
  }
}

.image-wrapper {
  &__img {
    position: relative;
    display: block;
    width: 100%;

    &::before {
      content: '';
      display: block;
      position: relative;
      padding-top: 100%;
    }

    img {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
#page-monclernow {
  .newsletter-wrapper {
    display: none;
  }
}
