.product-gallery {
  width: 100%;

  &-vertical {
    display: flex;
    background-color: $white;

    &--list {
      display: flex;
      flex-direction: column;
      padding: 0;
      list-style: none;
      width: 100%;
      li {
        padding: 0;
      }
    }

    &__image {
      .product-video-pause {
        left: 1.6rem;
        bottom: 1.6rem;
      }

      &:not(:first-child) {
        opacity: var(--pdp-start-opacity);
      }

      .product-image {
        &-button {
          width: 100%;
        }
        &::before,
        &::after {
          content: none;
        }
      }
      img,
      .product-video-wrapper video {
        position: relative;
      }
    }

    &--navigation {
      // height: calc(100vh - 5.6rem);
      // body.has-ticker & {
      //   height: calc(100vh - 8.8rem);
      // }
      flex-grow: 1;
      .navigation {
        &--container {
          position: sticky;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          gap: 4rem;
          height: calc(100vh - 5.6rem);
          top: 5.6rem;
          body.has-ticker & {
            height: calc(100vh - 8.8rem);
            top: 8.8rem;
          }
          padding-left: 1rem;
          padding-bottom: 1.6rem;

          // background-color: aqua;
        }
        &__list {
          padding: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0.4rem;
          flex: 1;
          justify-content: flex-end;
          &:hover {
            [data-active='true'] {
              max-height: 2.5rem;
            }
          }
        }
        &__bullet {
          list-style: none;
          width: 100%;
          padding: 0;
          max-height: 2.5rem;
          transition: all 0.3s ease-in-out;
          flex: 1;

          &[data-active='true'],
          &:hover,
          &[data-active='true']:hover {
            max-height: 3rem;
          }

          &[data-active='true'] button span {
            width: 0.2rem;
            background-color: $black;
          }
          // &:hover {
          //   button span,
          //   &[data-active='true'] button span {
          //     height: 3rem;
          //   }
          // }
          button {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              display: block;
              width: 0.1rem;
              transition: all 0.3s ease-in-out;

              // height: 2.5rem;
              height: 100%;
              background-color: #a6a6a6;
            }
          }
        }
        &__button {
          width: 2.4rem;
          height: 2.4rem;
          @include fixedSprite('chevron-down-large');
        }
      }
    }
  }
}

.scrollhandler-wrapper {
  position: relative;
  @media (max-width: 1023px) {
    display: none;
  }
  &.fixed .scrollhandler {
    position: fixed;
  }
  &.landing .scrollhandler {
    opacity: 0;
    transform: translateY(32px);
  }
}

.scrollhandler {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  position: absolute;
  bottom: 1.6rem;
  left: calc(50% + 1.2rem);
  cursor: pointer;
  opacity: 1;
  transition: transform 1s ease, opacity 1s ease;
  transition-delay: 0.3s;
}
.scrollhandler-scrolldown {
  button {
    min-width: 2.4rem;
    min-height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      @include fixedSprite('double-arrow-down');
    }
  }
}
.scrollhandler-track {
  position: relative;
  height: 17.5rem;
  width: 2.4rem;
  // background-color: rgb(249, 180, 180);
  display: block;
  touch-action: none;
  &::after {
    content: '';
    display: block;
    height: 100%;
    width: 0.1rem;
    background-color: $black_secondary;
    position: absolute;
    right: 50%;
    top: 0;
    pointer-events: none;
  }
  .scrollhandler-thumb {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 2.4rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    cursor: grab;
    // touch-action: none;
    &::after {
      content: '';
      display: block;
      background-color: $black;
      width: 0.2rem;
      height: 100%;
      margin-left: -1px;
      // touch-action: none;
    }
    &.active {
      cursor: grabbing;
    }
  }
}
