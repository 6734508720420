.more-curators {
  .component-curator-card {
    .curators-card {
      &:hover,
      &:focus-within {
        .image-wrapper {
          width: 100%;
          height: 100%;
          .overlay {
            opacity: 0.5;
          }
        }
        .curators-card__inner-container {
          .curator-card__quote,
          .button,
          .date {
            opacity: 1;
          }
        }
      }
      .image-wrapper {
        width: calc(100% - 5.6rem);
        height: calc(100% - 5.6rem);
        @include animate(0.5);
        .overlay {
          background-color: $black;
          opacity: 0.2;
          height: 100%;
          @include animate(0.5);
        }
      }
      .curators-card__inner-container {
        padding: 6.4rem;
        .date {
          font-size: 1.6rem;
          line-height: 2rem;
          @include animate(0.5);
        }
        .curator-card__title {
          font-size: 4.2rem;
          line-height: 5rem;
          margin: 1.2rem 0;
        }
        .curator-card__quote {
          font-size: 1.6rem;
          line-height: 2rem;
          margin-bottom: 3.4rem;
          @include animate(0.5);
        }
        .button {
          @include animate(0.5);
        }
      }
    }
  }
}
