.minibag-login {
  position: absolute;
  top: 0;
  height: 100%;
  background: white;
  z-index: 3;
  left: 0;
  width: 100%;
  padding: 0 $space-unit * 8;
  .login-registration__registration {
    margin-top: 1.6rem;
  }
  .wrapper-header-modal {
    position: sticky;
    top: -2rem;
    z-index: 1;
    width: calc(100% + 6.4rem);
    transform: translateX(-3.2rem);
    &:after {
      content: '';
      height: 100%;
      background: linear-gradient(180deg, #f9f9f9 0%, #ffffff 100%);
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
    .modal__close {
      display: block;
      right: 3.2rem;
      padding-right: 0;
    }
  }
  .register_form {
    padding-bottom: 3.2rem;
  }
  .registered-in-store-send-email {
    margin-top: 1.6rem;
    .row {
      flex-direction: column;
      row-gap: 1.2rem;
    }
  }
  .row.row-reverse-mobile {
    align-items: center;
  }
}
.minibag {
  &.has-items {
    height: auto;
    max-height: calc(var(--view-height) - #{$header-height-mobile} - 2rem);
    overflow-y: auto;
  }
}
.minibag {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  right: 0;
  background: $white;
  padding: $space-unit * 5 $space-unit * 8;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  pointer-events: none;
  transform: translateY(-3rem);
  width: 100%;
  height: auto;
  top: $header-height-mobile;
  transition: opacity 0.3s 0s cubic-bezier(0.25, 1, 0.5, 1), transform 0.3s 0s cubic-bezier(0.25, 1, 0.5, 1);
  @include gray-gradient-pseudo(after);
  &:focus-visible {
    outline: 0;
  }
  [data-whatintent='keyboard'] & {
    &:focus-visible {
      outline: 1px solid;
      outline-offset: -2px;
    }
  }
  // > * {
  //   position: relative;
  //   @include z;
  // }

  &__title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.6rem;
    column-gap: 0.4rem;
    text-transform: none;
    @extend %primary-s;

    &--empty {
      display: flex;
      justify-content: space-between;
      @extend %primary-s;
    }
  }

  &__unavailableticker {
    background-color: $black;
    padding: $space-unit * 2;
    margin-bottom: $space-unit * 4;
    text-align: center;
    margin-left: -3.2rem;
    margin-right: -3.2rem;
    margin-top: -1.6rem;
    button {
      color: $white;
    }
  }

  &__disclaimer {
    margin-bottom: 1.2rem;
  }
  .icon--close {
    position: absolute;
    top: 1.8rem;
    right: 1.6rem;
    z-index: 2;
  }

  &__mobile {
    padding-bottom: 1.4rem;
  }

  &__mobile-label {
    @include border('top');
    padding: $space-unit * 4 0;
    text-decoration: none;
  }
  &__available {
    display: flex;
    justify-content: space-between;
    padding-bottom: $space-unit * 4;
  }
  &__total {
    @include border('top');
    display: flex;
    justify-content: space-between;
    padding: $space-unit * 4 0;

    & > * {
      @extend %primary-s;
    }
  }

  &__buttons {
    @include animate;
    position: relative;
    .button:not(:last-child) {
      margin-bottom: $space-unit * 2;
    }
  }

  &__productcontainer {
    .disclaimer-box {
      margin-top: 0.5rem;
      p {
        font-weight: bold;
      }
    }
    // padding-bottom: $space-unit * 0.5;
    .bag-product__preorderinfo {
      .bag-product__preorderinfo__subtitle {
        margin-top: 0.4rem;
        @extend %primary-xxs;
        color: rgba(0, 0, 0, 0.55);
      }
    }
    &:not(:last-of-type) {
      @include border('bottom');
      margin-bottom: $space-unit * 4;
      padding-bottom: $space-unit * 4;
    }
  }
  &__product {
    display: flex;

    &__image {
      flex-basis: 6.7rem;
      .product-image-button {
        height: auto;
      }
      .product-image {
        height: 10rem;
        &:before {
          content: initial;
        }
        // img {
        //   top: 50%;
        //   transform: translateY(-50%);
        // }
      }
    }

    &__content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: $space-unit * 4;
    }

    &__columns {
      display: flex;
      justify-content: space-between;
      height: 100%;
    }

    &__title {
      @extend %primary-xs;
      h3 {
        font-size: 1.6rem;
      }
    }

    &__price {
      text-align: right;
      white-space: nowrap;
      margin-left: $space-unit * 2;
    }

    &__label {
      display: block;
      text-align: right;
      margin-top: $space-unit * 2;
      &--preorder {
        @extend .minibag__product__label;
        color: $black_secondary;
      }
      &--outofstock {
        @extend .minibag__product__label;
        color: $status_error;
      }
    }

    &__info {
      @extend %primary-xxs;
      p {
        margin-top: $space-unit;
      }
    }

    &__actions {
      margin-bottom: -($space-unit * 0.5);
      display: flex;
      justify-content: space-between;
      padding-top: 0.8rem;
      @extend %primary-xs;
      &__qty {
        flex-shrink: 0;
      }
    }
    &__no_refundable {
      font-weight: bold;
      font-size: 1.2rem;
      margin-top: 1.4rem;
      button {
        text-decoration: underline;
        text-underline-offset: 1px;
      }
    }
  }

  &__list {
    // max-height: 28.5rem;
    margin-bottom: 1.6rem;
    padding-right: ($space-unit * 4);
    @include scrollbar;
  }

  &__buttons__wrapper {
    @include animate;
    &.hide {
      transition-delay: 0.2s;
      visibility: hidden;
    }
  }
  &__expresspayment {
    position: absolute;
    width: 100%;
    //min-height: 100%;
    background-color: $white;
    bottom: 0;
    left: 0;
    height: auto;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    @include animate;
    &.open {
      transition-delay: 0.4s;
      transform: translateX(0);
      visibility: visible;
      opacity: 1;
    }
    &__intro.button {
      width: 100%;
      padding-right: 1.6rem;
      padding-bottom: 0.8rem;
      text-align: center;
      font-size: 1.6rem;
      line-height: 2rem;
      text-transform: capitalize;
    }
    #container-paywithgoogle {
      display: none;
    }

    button {
      display: block;
      &.button--arrow-back {
        position: absolute;
        top: 6px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        z-index: 10;
        span {
          i.icon--chevron-left {
            filter: none;
          }
        }
      }
    }
  }
  .btn-view-bag {
    text-transform: uppercase;
    display: flex;
    column-gap: 0.8rem;
    justify-content: center;
    padding: 0.8rem 0;
    &::after {
      content: '';
      display: block;
      @include fixedSprite('arrow-right-black');
    }
  }
  .mbm-discaimer-oos {
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: bold;
    text-align: left;
    margin-top: ($space-unit * 2);
  }
  &.visible {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
    transform: translateY(0);
  }
  &:not(.visible) {
    .minibag__expresspayment.open {
      visibility: hidden;
    }
  }

  .gc-cart-disclaimer {
    opacity: 0.55;
  }
}

[data-whatintent='keyboard'] .minibag {
  transition-property: transform;
}

.minibag-wrapper {
  display: flex;
  .a11y-action-bar {
    justify-content: space-between;
  }
  .minibag__product__image {
    .product-image-button {
      height: auto;
      cursor: pointer;
    }
  }
}

[data-react='minicart-app'] {
  .icon--bag {
    span {
      display: inline;
    }
  }
}
.container-title {
  border-bottom: 1px solid $grey_line;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
  text-transform: uppercase;
  align-items: center;
  .modal__close {
    z-index: 2;
  }
}
.container-minibag-down {
  .product-selection__shipping-disclaimer {
    margin-top: 0;
    margin-bottom: 1.6rem;
    .shipping-disclaimer_description {
      span {
        color: $black;
      }
    }
  }
  //   &:before {
  //     content: '';
  //     display: block;
  //     height: 1px;
  //     position: absolute;
  //     top: 0;
  //     width: 100%;
  //     background-color: $grey_line;
  //   }
}
.container-modal-login-minicart {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: $space-unit * 5 $space-unit * 8 $space-unit * 8;
  background: $white;
  opacity: 0;
  visibility: hidden;
  transform: translateX(100%);
  z-index: 2;
  @include animate(0.3);
  @include gray-gradient-pseudo(after);

  &.show {
    opacity: 1;
    visibility: visible;
    transform: translateX(var(--scrollbar-width));
  }
  .minibag-login-subtitle {
    margin-top: 4rem;
  }
  .container-btn-login {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  // .modal__close {
  //   padding: 0;
  //   position: relative;
  // }
  .minibag__btn-login {
    text-align: center;
    position: relative;
    padding-top: 0.4rem;
    width: 100%;
    &::after {
      content: '';
      top: 0;
      display: block;
      position: absolute;
      left: 0;
      @include fixedSprite('arrow-left-black');
    }
  }
  .login-modal-buttons {
    row-gap: 1rem;
    flex-grow: 1;
    align-content: flex-end;
  }
  // .login-registration {
  //   .formfields {
  //     .remember-me {
  //       align-items: center;
  //       justify-content: space-between;
  //     }
  //   }
  // }
}
// .remove-container-btn {
//   padding-top: 0.8rem;
// }
.container-minibag-up {
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.login-modal {
  .recaptcha {
    display: none;
  }
  .registered-in-store-send-email {
    .row {
      row-gap: 1.2rem;
    }
  }
}
// body.has-ticker {
//   .product-image-button {
//     height: auto;
//   }
// }
