.preferences-changepassword {
  padding-top: 1rem;
  .button-row {
    align-items: center;
  }
}
.preferences-socialaccounts {
  .social-row {
    margin-top: ($space-unit * 4);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .is-connect {
      position: relative;
      .connected-tick {
        content: '';
        right: -($space-unit * 5);
        width: ($space-unit * 2);
        height: ($space-unit * 2);
        @include fixedSprite('checkmark');

        position: absolute;
        top: -($space-unit * 0.5);
      }
    }
    .button-connect {
      color: $black_inactive;
    }
  }
}

.preferences-privacysettings {
  .checkbox-wrapper {
    margin-top: ($space-unit * 6);
    margin-bottom: ($space-unit * 6);
  }
}

.preferences-privacysettings_rpy{
  margin: auto;
  //max-width: 60%;

  .newsletter-section,
  .tracking-section {
    border-bottom: 1px solid $grey_line;
    padding-bottom: 40px;

    &.not-available-section {
      pointer-events: none;
      opacity: .5;
    }

    .info-text {
      color: $overlay;
      padding-top: 40px;
      padding-bottom: 15px
    }
  }

  .tracking-section {
    padding-top: 40px;
  }

  .checkbox-wrapper {
    text-align: left;

    .checkbox__field {
      label {
        text-transform: uppercase;
        font-weight: 300 !important;
      }
    }

    .text-normal {
      .checkbox__field {
        label {
          text-transform: none;
        }
      }
    }

    .custom-label-toggle {
      padding-top: 2rem;
      padding-left: .75rem;

      label {
        font-weight: normal;
        text-transform: none;
      }

      .alt-label {
        text-transform: uppercase;
        font-weight: 300;
        padding-bottom: 1rem;
      }
    }
  }
}

.preferences-countrylanguage {
  padding-bottom: 40px;
  border-bottom: 1px solid $grey_line;

  .spacing {
    margin-top: ($space-unit * 6);
  }

  &-clothing-margin{
    margin-top: 40px;
  }
}

.preferences-marketing {
  padding-top: 40px;

  .pm-options {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }

  .radio-wrapper {
    margin-top: ($space-unit * 4);
  }
}

.preferences-newsletter {
  .email-spacing {
    margin-top: $space-unit;
    margin-bottom: ($space-unit * 4);
  }
  .checkbox-wrapper {
    margin-top: ($space-unit * 2);
    margin-bottom: ($space-unit * 8);
  }
  .legal-label {
    color: $black_secondary;
    margin-bottom: ($space-unit * 4);
  }
}
