.product-tile-featured {
  .product-tile {
    &__card {
      background-color: $black;
      color: $white;
      // .simplified &,
      .inverted & {
        color: $black;
        background-color: $white;
        .product-image {
          background-color: $white;
        }
      }
    }
    &__link {
      height: 640px;
      display: flex;
      flex-direction: column;
      &:hover,
      &:focus-visible {
        .featured-products-carousel:not(.video) & {
          .product-tile {
            &__info {
              max-height: 24rem;
              @include animate(0.6, all);
            }
            &__description {
              visibility: visible;
              opacity: 1;
              transition: all 0.2s linear;
            }
            &__cta {
              visibility: visible;
              opacity: 1;
              transition: all 0.2s linear;
            }
          }
        }
      }
      &:focus {
        .featured-products-carousel:not(.video) & {
          [data-whatinput='keyboard'] & {
            .product-tile {
              &__info {
                max-height: 24rem;
                @include animate(0.6, all);
              }
              &__description {
                visibility: visible;
                opacity: 1;
                transition: all 0.2s linear;
              }
              &__cta {
                visibility: visible;
                opacity: 1;
                transition: all 0.2s linear;
              }
            }
          }
        }
      }
      &:focus {
        .featured-products-carousel:not(.video) & {
          [data-whatinput='keyboard'] & {
            .product-tile {
              &__info {
                max-height: 24rem;
                @include animate(0.6, all);
              }
              &__description {
                visibility: visible;
                opacity: 1;
                transition: all 0.4s linear;
              }
              &__cta {
                visibility: visible;
                opacity: 1;
                transition: all 0.4s linear;
              }
            }
          }
        }
      }
      .simplified:not(.video) & {
        .product-tile {
          &__info {
            max-height: 24rem;
            @include animate(0.6, all);
          }
          &__description {
            visibility: visible;
            opacity: 1;
            transition: all 0.4s linear;
          }
          &__cta {
            visibility: visible;
            opacity: 1;
            transition: all 0.4s linear;
          }
        }
      }
    }

    .simplified & {
      &__image-wrapper {
        overflow: hidden;
        img {
          @include animate(1, all);
          transform: scale(1.2);
          transform-origin: center;
        }
        .product-image {
          img {
            transform: scale(1);
          }
        }
      }
      &__link {
        &:hover {
          img {
            transform: scale(1);
          }
          .product-image {
            img {
              transform: scale(0.8);
            }
          }
        }
      }
    }

    &__image-wrapper {
      flex-grow: 1;
      max-height: 54rem;
      min-height: 0;
    }

    &__editorial-img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      flex-shrink: 0;
      text-align: center;
      min-height: 10rem;
      max-height: 10rem;
      padding: ($space-unit * 9) ($space-unit * 3) ($space-unit * 7);
      @include animate(0.6, all);
      .uppercase {
        text-transform: uppercase;
      }
    }

    &__pd-name {
      margin-bottom: ($space-unit * 2);
      @include animate(0.8, all);
    }

    &__description {
      // display: none;
      visibility: hidden;
      opacity: 0;
      margin-bottom: ($space-unit * 2);
      // @include animate(0.5, all);
      transition: all 0.2s ease-in;
    }
    &__cta {
      // display: none;
      display: flex;
      visibility: hidden;
      opacity: 0;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      // @include animate(0.5, all);
      transition: all 0.2s ease-in;
      &:after {
        content: '';
        position: relative;
        width: 1.4rem;
        height: 2.4rem;
        display: inline-block;
        @include sprite('chevron-right');
      }
    }
  }
}
