// COLOR SELECTOR USED IN: PDP, Tile, Reserve in boutique

.selector-color-link {
  .selector__menu {
    &__item {
      &__label {
        transform: translate(100%, 2px);
      }
    }
  }
}
