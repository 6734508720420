.register_form {
  text-align: left;
  .clothing-register-subtitle {
    justify-content: space-between;
  }
  .form-title {
    margin-bottom: 1.2rem;
  }
  .form-group--gender-radio {
    &.mb-24 {
      margin-bottom: 2.4rem;
    }
    .invalid-feedback {
      width: 100%;
    }
  }
  .social-row {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1.2rem;
    row-gap: 0.8rem;
    margin-bottom: 2.4rem;
  }
  .wrapper-flag {
    .form-group__legend {
      @extend %primary-xs;
    }
  }
  &__email-row {
    .register_form__input-column {
      width: calc(100% - 7.5rem);
      &.full-width {
        width: 100%;
      }
    }
    .register_form__edit-column {
      text-align: right;
      width: 7.5rem;
    }
  }

  &__label {
    color: $black_secondary;
    @extend %primary-xxs;
    margin-top: ($space-unit * 8);
    margin-bottom: ($space-unit * 6);
  }

  &__subtitle {
    margin-bottom: ($space-unit * 3);
  }

  &__info-label {
    @extend %primary-xxs;
    margin-bottom: ($space-unit * 4);
  }

  &__comunication-channels {
    .channel-flags {
      display: grid;
      grid-template-columns: 2fr 2fr;
      .checkbox {
        label {
          font-size: 1.2rem;
        }
      }
    }
  }

  .form-section-title {
    font-size: 1.6rem;
    font-weight: bold;
    margin: 2.4rem 0 1.6rem;
    &.mt-0 {
      margin-top: 0;
    }
  }

  .gendercol .form-group {
    display: flex;
    flex: 0 1 auto;
    flex-wrap: wrap;

    .form-group__label {
      @include col-width(12);
    }

    .radio {
      &:nth-child(even) {
        @include col-width(4);
      }

      &:nth-child(odd) {
        @include col-width(8);
      }
    }
  }

  .form-group__label {
    min-height: ($space-unit * 5);
  }

  .channel-flags {
    padding-left: 2rem;
    margin-top: 8px;
    margin-bottom: 2.4rem;
  }

  .register_form__newslettertitle {
    @extend %primary-s;
    margin-bottom: $space-unit * 4;
  }

  .line {
    @include border('top');
    // margin-top: ($space-unit * 3);
    // padding-top: ($space-unit * 6);
  }
  .password {
    margin-bottom: ($space-unit * 3);
  }

  // KR registration form - OE-15094
  .form {
    .form-section {
      .kr-name-field {
        .form-group {
          margin-bottom: 0;
        }
      }
    }
  }
  &.register-click-from-store {
    .register_form__label {
      margin-top: 2rem;
    }
    .form-section-title {
      margin-top: 1.6rem;
    }
    .register_form__email-row {
      .form-group {
        margin-bottom: 0.8rem;
      }
    }
  }
}
.register_form__email-row {
  display: flex;
  align-items: center;
}

.newsletter-flag {
  &.registration {
    fieldset {
      display: flex;
      flex-wrap: wrap;
      row-gap: $space-unit;
      column-gap: 2rem;
    }
  }
  .checkbox input[type='checkbox'].newsletter-checkbox + label {
    font-weight: bold;
    padding-left: 4rem;
    position: relative;
    &::after {
      content: '';
      background-image: url(~@moncler/media/moncler-collection.png);
      background-size: 100%;
      background-repeat: no-repeat;
      width: 2.4rem;
      height: 2.4rem;
      left: 1rem;
      top: 0.1rem;
      position: absolute;
    }
  }
  .checkbox input[type='checkbox'].newsletter-checkbox.moncler-genius + label {
    &::after {
      content: '';
      background-image: url(~@moncler/media/moncler-genius.png);
    }
  }
  .checkbox input[type='checkbox'].newsletter-checkbox.moncler-grenoble + label {
    &::after {
      content: '';
      background-image: url(~@moncler/media/moncler-grenoble.png);
    }
  }
  .newsletter-checkbox-description {
    padding-left: 5.4rem;
  }
  fieldset {
    margin: 1.2rem 0 2.4rem 0;
    padding-left: 5.4rem;
    &:before {
      display: none;
    }
    .checkbox input[type='checkbox'] + label {
      font-size: 1.4rem;
    }
  }
}
.kr-name-field {
  &.row {
    align-items: start;
  }
}
