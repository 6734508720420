// currently unused
[data-react='vto-camera'] {
  position: fixed;
  top: 0;
  left: 0;
  // background: rgba(0, 0, 0, 55%);
  z-index: 1;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.vyking-sneaker-window {
  width: 100%;
  height: 100%;
  overflow: hidden;
  // opacity: 0;
  // z-index: 2;
}

.vto-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  // z-index: 10;
  color: white;
  text-transform: uppercase;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  > img {
    width: 26px;
    height: 26px;
    object-fit: contain;
  }
  .lf-player-container {
    width: 26px;
    height: 26px;
    margin-bottom: 2rem;
  }
  &:not(.black) {
    .lf-player-container {
      filter: invert(1);
    }
  }
  &.black {
    color: black;
  }
}

.swiper-vto {
  position: absolute;
  top: 0;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  padding: 3.2rem 0;
  transform: translateY(-100%);
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    border: 3px solid rgba(255, 255, 255, 0.8);
    z-index: 2;
    pointer-events: none;
  }
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 5.2rem;
    height: 5.2rem;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.6);
    // z-index: 2;
    pointer-events: none;
  }
}

.photo-group > img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  // z-index: 3;
}

.toggle-infolayer {
  position: absolute;
  left: 0;
  top: 0;
  padding: 1.8rem 1.6rem;
  z-index: 3;
}
.info-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  z-index: 2;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.6rem;
  p {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    text-align: center;
  }
}
.layer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 440px;
  margin-top: 20%;
}
.aspect-ratio--1-1 {
  position: relative;
  width: 100%;
  .lf-player-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.vto-retake {
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  column-gap: 8px;
}

#page-vto {
  display: flex;
  flex-direction: column;
  .maincontent {
    flex-grow: 1;
    border-bottom: initial;
  }
}
