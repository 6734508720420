.account-dropdown {
  padding: 0 32px 32px;
  width: 375px;
  .myaccount-hero {
    margin-left: -3.2rem;
    margin-right: -3.2rem;
    height: 176px;
    .myaccount-hero__title {
      @extend %primary-l-u;
      margin-bottom: 4.8rem;
    }
  }
}
.account-dropdown-logged {
  right: 3.2rem;
}

// .minibag,
// .account-dropdown {
//   .modal__close {
//     right: 1.6rem;
//   }
// }

// .login-register-header {
//   .modal__back {
//     left: 1.6rem;
//   }
// }
