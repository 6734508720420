.product-details {
  padding-top: 9.6rem;
  &.landing {
    transform: translateY(32px);
  }
}
.led-packaging {
  .packaging-row {
    gap: 2.4rem;
  }
}

.measmerize-drawer {
  .sizechart {
    margin-top: 0;
  }
}
