//fonts
$base-font: 10rem;

:root {
  --fontbold: MonclerGothic-Bold;
  --fontthin: MonclerGothic-Thin;
  --fontlight: MonclerGothic;
  --fontextralight: MonclerGothic;
  --fontmedium: MonclerGothic;
}

$silkSerifLight: 'SilkSerifLight', Arial, sans-serif;

$moncler-gothic-desktop: (
  xxs: (
    var(--fontlight),
    1.2rem,
    1.4rem,
    0,
    -0.15rem,
    -0.45rem,
  ),
  xxs-u: (
    var(--fontlight),
    1.2rem,
    1.4rem,
    0.015em,
    -0.15rem,
    -0.45rem,
  ),
  xs: (
    var(--fontlight),
    1.4rem,
    1.6rem,
    0,
    -0.15rem,
    -0.5rem,
  ),
  xs-u: (
    var(--fontlight),
    1.4rem,
    1.6rem,
    0.015em,
    -0.15rem,
    -0.5rem,
  ),
  s: (
    var(--fontextralight),
    1.6rem,
    2rem,
    0,
    -0.3rem,
    -0.65rem,
  ),
  s-u: (
    var(--fontextralight),
    1.6rem,
    2rem,
    0.015em,
    -0.32rem,
    -0.65rem,
  ),
  m: (
    var(--fontlight),
    2rem,
    2.4rem,
    0,
    -0.3rem,
    -0.7rem,
  ),
  m-u: (
    var(--fontlight),
    2rem,
    2.4rem,
    0.015em,
    -0.3rem,
    -0.7rem,
  ),
  l: (
    var(--fontextralight),
    2.4rem,
    2.8rem,
    0,
    -0.4rem,
    -0.8rem,
  ),
  l-u: (
    var(--fontextralight),
    2.4rem,
    2.8rem,
    0.015em,
    -0.4rem,
    -0.8rem,
  ),
  xl: (
    var(--fontextralight),
    3.2rem,
    3.8rem,
    0em,
    -0.6rem,
    -1.1rem,
  ),
  xl-u: (
    var(--fontextralight),
    3.2rem,
    3.8rem,
    0.015em,
    -0.6rem,
    -1.1rem,
  ),
  xxl: (
    var(--fontextralight),
    4.2rem,
    5rem,
    0.015em,
    -0.7rem,
    -1.5rem,
  ),
  xxxl: (
    var(--fontextralight),
    5.4rem,
    6.4rem,
    0.015em,
    -0.9rem,
    -1.9rem,
  ),
  xxxxl: (
    var(--fontthin),
    6.8rem,
    8.2rem,
    0.015em,
    -1.2rem,
    -2.4rem,
  ),
);

$moncler-gothic-mobile: (
  m: (
    var(--fontlight),
    1.8rem,
    2.2rem,
    0em,
    -0.3rem,
    -0.7rem,
  ),
  m-u: (
    var(--fontlight),
    1.8rem,
    2.2rem,
    0.015em,
    -0.3rem,
    -0.7rem,
  ),
  xl: (
    var(--fontextralight),
    2.4rem,
    2.8rem,
    0em,
    -0.3rem,
    -0.9rem,
  ),
  xl-u: (
    var(--fontextralight),
    2.4rem,
    2.8rem,
    0.015em,
    -0.3rem,
    -0.9rem,
  ),
  xxl: (
    var(--fontextralight),
    3.2rem,
    3.8rem,
    0.015em,
    -0.48rem,
    -1.2rem,
  ),
  xxxl: (
    var(--fontextralight),
    3.2rem,
    3.8rem,
    0.015em,
    -0.5rem,
    -1.2rem,
  ),
  xxxxl: (
    var(--fontextralight),
    3.2rem,
    3.8rem,
    0.015em,
    -0.5rem,
    -1.3rem,
  ),
);

$genius-desktop: (
  s: (
    16.5rem,
    12.4rem,
    -0.02em,
  ),
  m: (
    16.5rem,
    12.4rem,
    -0.02em,
  ),
  l: (
    16.5rem,
    12.8rem,
    -0.02em,
  ),
  xl: (
    16.5rem,
    12.8rem,
    -0.02em,
  ),
  xxl: (
    16.5rem,
    12.8rem,
    -0.02em,
  ),
  xxxl: (
    16.5rem,
    12.8rem,
    -0.02em,
  ),
  xxxxl: (
    16.5rem,
    12.8rem,
    -0.02em,
  ),
);

$genius-mobile: (
  s: (
    7.5rem,
    5.6rem,
    -0.005em,
  ),
  m: (
    9rem,
    6.8rem,
    -0.02em,
  ),
  l: (
    10.5rem,
    8rem,
    -0.02em,
  ),
  xl: (
    10.5rem,
    8rem,
    -0.02em,
  ),
  xxl: (
    10.5rem,
    8rem,
    -0.02em,
  ),
  xxxl: (
    10.5rem,
    8rem,
    -0.02em,
  ),
  xxxxl: (
    10.5rem,
    8rem,
    -0.02em,
  ),
);
