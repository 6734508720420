.interactive-cards {
  &__top-column {
    padding-bottom: 6.2rem;
  }
  .scrollable-selector-swiper {
    .swiper-slide {
      .scrollable-selector {
        line-height: 1.2;
        font-size: 4.2rem;
      }
      .timeline-separator {
        margin: 0 0.9rem;
        &:first-child {
          margin: 0 0.9rem 0 1.8rem;
        }
        &:last-child {
          margin: 0 1.8rem 0 0.9rem;
        }
        &.d-none {
          display: none;
        }
      }
    }
  }
  .interactive-card {
    .button-row {
      .button {
        flex-grow: initial;
        flex-basis: unset;
      }
    }
  }
  &:not(.no-timeline) {
    .scrollable-selector-swiper {
      .swiper-slide {
        .scrollable-selector {
          font-size: 5.4rem;
          max-width: 37rem;
          margin: 0 1.6rem;
        }
      }
    }
  }

  .interactive-card {
    &__button {
      --perc-background: 37.5%;
      width: 11.2rem;
      padding: 0 2rem;
    }
  }

  .cards-swiper {
    .swiper-slide {
      width: 36.2rem;
    }
  }
}
