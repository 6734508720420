.bag {
  &-overview {
    &__products {
      padding-top: 4.3rem;
      padding-bottom: 1.6rem;
      // border-bottom: 1px solid #e4e4e4; //removed by OE-27398, conflicts with OE-27218. Keep commented;
    }
  }

  &-summary {
    .items--summary {
      padding: 2.1rem 0 1.1rem;
    }

    .gc-cart-disclaimer {
      padding-bottom: 0;
      margin-bottom: 3.2rem;
    }

    .accordion-mini + .gc-cart-disclaimer {
      margin-top: -0.8rem;
    }
  }

  &-product {
    margin-bottom: 2rem;
    &__content.removing {
      .bag-product__name-price__name {
        max-width: 100%;
      }
      .bag-product__name-price__price {
        display: none;
      }
      .bag-product__actions {
        margin-top: 1.6rem;
      }
    }

    &__name-price {
      flex-wrap: wrap;
      .bag-product__name-price__message {
        margin: 0;
      }
    }

    &__name-price__price-block {
      flex-shrink: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 2rem;
    }
    &__name-price h3 {
      margin-bottom: 0.8rem;
    }
    &__details {
      margin-top: 0.8rem;
      margin-bottom: 0;

      .selector-size-inline {
        margin-bottom: 0.6rem;
      }
    }

    &__actions {
      margin-top: auto;
    }
  }
}
