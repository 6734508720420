.editorial-text {
  &--4x1 {
    grid-column: span 4;
  }
  &__center {
    text-align: center;
  }
  &__block {
    position: relative;
    &:not(.slim) {
      min-height: 56.2rem;
    }
  }
  &__link {
    width: auto !important;
  }
  &__text-wrapper {
    padding-top: ($space-unit * 14);
    padding-bottom: ($space-unit * 14);
  }
  &__with-logo {
    .editorial-text {
      &__text-wrapper {
        padding-bottom: ($space-unit * 26);
      }
    }
  }
  &__with-bg {
    .editorial-text {
      &__text-wrapper,
      &__link {
        color: $white;
      }
    }
  }
  &__bg-picture {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  &__bg-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__title {
    text-transform: uppercase;
  }
  &__title,
  &__description {
    letter-spacing: normal;
    margin-bottom: ($space-unit * 2);
  }
  &__author {
    display: flex;
    align-items: center;
    margin-top: ($space-unit * 7);
  }
  &__author-img {
    width: 4rem;
    height: 4rem;
    object-fit: cover;
    border-radius: 50%;
    margin-right: ($space-unit * 4);
  }
  &__link {
    display: inline-block;
    text-decoration: none;
    position: relative;
    margin-top: ($space-unit * 7);
  }
  &__picture {
    position: absolute;
    bottom: 0;
    right: 0;
    overflow: hidden;
    // max-width: 22%;
    // max-height: 66%;
    width: 23.4rem;
    height: 24.5rem;
  }
  &__logo-bg {
    max-width: none;
    // width: 100%;
    width: 28.5rem;
    height: 100%;
    // object-fit: contain;
    // object-position: bottom right;
    object-position: top left;
    object-fit: cover;
    // animation: fadein 0.5s linear 0.8s both;
    &[data-aos][data-aos-delay='800'].aos-animate {
      transition-delay: 0.8s;
    }
  }
  &--quotes {
    .editorial-text {
      &__text-wrapper {
        padding-top: ($space-unit * 8);
      }
    }
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
