.support-section-footer-row {
  .contacts-card {
    padding: 40px 24px;

    .links {
      max-width: 52rem;
      margin: auto;
    }
  }
}
