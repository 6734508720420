.modal {
  &--country-selector.modal {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
    min-width: 100%;
    width: 100%;
    height: 24.4rem;
    &.full-screen {
      top: 0;
      height: auto;
      width: auto;
      .modal {
        &__header {
          padding: 2rem 1.6rem 2rem 1.6rem;
        }

        &__body {
          padding: 0 1.6rem 2.4rem 1.6rem;
          .continent {
            .card-header {
              padding: 2.4rem 0;
            }
          }
        }
      }
    }
    .modal {
      &__header {
        padding: 2rem 1.6rem;
        margin: 0 1.6rem;
        @include border('bottom');
        .dialog__title {
          margin: 0;
          height: auto;
        }
      }
      &__body {
        padding: 2.4rem 1.6rem 0 1.6rem;
        .countryselector-modal {
          &__description {
            padding: 0 0 1.2rem 0;
            border-bottom: none;
            &:last-child {
              padding: 0 0 2.4rem 0;
              border-bottom: none;
            }
          }
        }
        .row {
          .col-6 {
            padding-left: 0;
            &:last-child {
              padding-right: 0;
            }
            button {
              margin-bottom: 0;
            }
          }
          .continent {
            padding: 0;
          }
        }
      }
    }
  }
}
