.section-menu-new-wrapper {
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  > nav {
    width: max-content;
    min-width: 100%;
    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      height: 52px;
      width: 48px;
      pointer-events: none;
      background: linear-gradient(90deg, hsla(0, 0%, 100%, 0) 0, #fff 99%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00ffffff",endColorstr="#ffffff",GradientType=1);
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      height: 52px;
      width: 48px;
      pointer-events: none;
      background: linear-gradient(270deg, hsla(0, 0%, 100%, 0) 0, #fff 45%, #fff 99%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00ffffff",endColorstr="#ffffff",GradientType=1);
    }
  }
}

.section-menu-new {
  @include reset-list;
  gap: 32px;
  justify-content: center;
  border-bottom: 1px solid $grey_line;
  padding: 0 16px;
  li {
    text-transform: uppercase;
    @extend %primary-s;
    color: $black_secondary;
    white-space: nowrap;
    a {
      color: $black;
      text-decoration: initial;
      padding: 16px 0;
      display: inline-block;
      margin-bottom: -1px;
    }
    &.selected {
      font-weight: bold;
      a {
        border-bottom: solid 1px $black;
      }
    }
  }
}
.section-menu {
  @include reset-list;
  li {
    @extend %primary-s;
    color: $black_secondary;
    padding-right: ($space-unit * 6);
    &.selected {
      color: $black;
    }
    &:not(:last-child) {
      margin-bottom: ($space-unit * 2);
    }
    a {
      text-decoration: initial;
    }
  }
}
.contact-link-button {
  margin-top: ($space-unit * 8);
  margin-left: -($space-unit);
  .remote-call {
    margin-bottom: ($space-unit * 2);
    margin-left: $space-unit;
    button {
      text-decoration: underline;
      text-align: left;
    }
  }
  a {
    @extend %primary-s;
    display: flex;
    align-items: center;
    margin-bottom: ($space-unit * 2);
    text-decoration: none;
    &::before {
      flex-shrink: 0;
    }
  }
}
// .tfn-toggle {
//   padding-left: 6px;
//   text-decoration: underline;
//   margin-top: -6px;
//   display: block;
// }
.call {
  &::before {
    content: '';
    margin-right: $space-unit;
    @include fixedSprite('phone');
  }
  @include hover(0.2);
}

.chat {
  display: inline-flex;
  align-items: center;
  @extend %primary-s;

  &::before {
    content: '';
    margin-right: $space-unit;
    @include fixedSprite('chat');
  }
  @include hover(0.2);
}

.mail {
  &::before {
    content: '';
    margin-right: $space-unit;
    @include fixedSprite('mail');
  }
  @include hover(0.2);
}

.whatsapp {
  &::before {
    content: '';
    margin-right: $space-unit;
    @include fixedSprite('instantmessage-whatsapp');
  }
  @include hover(0.2);
}

.kakao {
  &::before {
    content: '';
    margin-right: $space-unit;
    @include fixedSprite('instantmessage-kakao');
  }
  @include hover(0.2);
}

.linechat {
  &::before {
    content: '';
    margin-right: $space-unit;
    @include fixedSprite('instantmessage-line');
  }
  @include hover(0.2);
}

.wechat {
  &::before {
    content: '';
    margin-right: $space-unit;
    @include fixedSprite('instantmessage-wechat');
    width: 2.4rem;
  }
  @include hover(0.2);
}

.sms {
  &::before {
    content: '';
    margin-right: $space-unit;
    @include fixedSprite('instantmessage-sms');
  }
  @include hover(0.2);
}
