.collections-inpage-navigation {
  height: 61.7rem;
  &.with-borders {
    margin: ($space-unit * 7);
  }
  &__inner-container {
    min-height: 61.7rem;
  }
  .collection-open {
    .inpage-collection {
      padding: 1rem 0 3.2rem;
    }
    .inpage-collection:not(.expanded) {
      padding-top: 1.4rem;
      .inpage-collection__name {
        font-size: 2.4rem;
        line-height: 2.8rem;
      }
    }
    .inpage-collection-component:not(.resized) {
      & + .resized {
        margin-top: 0;
      }
    }
  }
  &__inner-container {
    background-image: var(--background-desktop), var(--background-mobile);
  }

  &__title {
    font-size: 2rem;
    line-height: 2.4rem;
    margin-bottom: 8rem;
  }

  .inpage-collection {
    // padding: 1.4rem 0;
    padding: 2rem 0;
    &.expanded {
      .inpage-collection {
        padding: 2rem 0;
        &__name {
          font-size: 5.4rem;
          line-height: 6.4rem;
          margin-top: 6.2rem;
          margin-bottom: 13.6rem;
        }
        &__info-box {
          top: 14.4rem;
        }
        &__logo {
          top: 0.8rem;
        }
        &__toggle-icon-wrapper {
          margin-top: 0;
        }
      }
    }
    &__collection-bg {
      background-image: var(--background-desktop), var(--background-mobile);
    }
    &__name {
      font-size: 4.2rem;
      line-height: 5rem;
      margin: 0.8rem 0;
    }
    &__info-box {
      top: 10rem;
    }
    &__description {
      font-size: 2rem;
      line-height: 2.4rem;
    }
    &__toggle-icon-wrapper {
      position: absolute;
      transition: all 0.3s linear;
      left: 50%;
      margin-top: 0;
      transform: translateX(-50%);
    }
  }
}
