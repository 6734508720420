.monclernow-listing {
  padding: 2rem 2rem 0;
  &__article {
    width: calc(50% - 0.2rem);
    margin-bottom: 3.2rem;
  }

  &::after {
    content: none;
  }
}

.article {
  &--landscape {
    width: 100%;
    margin: 0 auto 3.2rem;
  }
}
