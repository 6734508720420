.perfume-product-highlight {
  &__wrapper {
    display: flex;
    &::before,
    &::after {
      content: '';
      display: inline-block;
      width: 3.2rem;
      min-width: 3.2rem;
      height: 1rem;
    }
  }

  .swiper-pagination--bars {
    position: absolute;
    top: var(--top);
    z-index: 2;
    left: var(--left);
    transform: translateX(-50%);
    --top: 0;
    --left: 0;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 6rem;
      width: 100%;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
    }
    .swiper-pagination-bullet {
      background: $grey_line;
      z-index: 2;
      transition: initial;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: $black;
        transform: scaleX(0);
        transform-origin: left;
      }

      &.swiper-pagination-bullet-active {
        background: $grey_line;
      }
    }
  }

  .swiper-container.initialized.resizing {
    .swiper-slide {
      transition: initial !important;
    }
  }

  .swiper-container {
    &:not(.initialized) {
      .swiper-slide {
        transform: translate3d(0, 0, 0);
        transition: initial;
      }
    }
    &.initialized {
      .swiper-slide {
        transition-property: all;
        transition-timing-function: linear;
        transition-duration: 400ms !important;
      }
      .swiper-pagination--bars .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
        transition: all 5s linear;
        transform: scaleX(1);
      }
    }
  }

  .btn--pause {
    position: absolute;
    left: 3.2rem;
    bottom: 3.2rem;
    z-index: 2;
  }
}
.perfume-product-highlight {
  .swiper-container {
    &.not-carousel-active {
      .perfume-product-highlight-slide {
        visibility: visible;
      }
    }
  }
}
