.curator-newsletter-container {
  background-color: $black;
  color: $white;
  padding-bottom: 5.6rem;
  .curator-newsletter {
    text-align: center;
    border: 0.1rem solid $white;
    padding: 8rem 2rem;
    .curator-newsletter__title {
      font-size: 1.6rem;
      line-height: 2rem;
      text-transform: uppercase;
      margin-bottom: 2.4rem;
    }
    .curator-newsletter__subtitle {
      font-family: $silkSerifLight;
      font-size: 4.2rem;
      line-height: 5rem;
      margin-bottom: 1.6rem;
    }
    p {
      font-size: 1.6rem;
      line-height: 2rem;
      margin-bottom: 4.8rem;
    }
    .button {
      width: auto;
    }
  }
}

#page-curatorsflp {
  .newsletter-wrapper {
    display: none;
  }
}
