.curator-bio {
  .img-mobile {
    display: block;
  }
  .img-desk {
    display: none;
  }
  .container-bio-down {
    padding: 2.4rem 0 8rem 0;
    .bio-message {
      .bio-title {
        font-size: 20px;
        line-height: 54px;
      }
      .bio-text {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}
