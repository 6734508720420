.product-selection {
  .selector-size {
    height: $selector-size-inpage-height-mobile;

    .selector__menu {
      padding: 1.6rem 0 1.4rem;

      ul {
        row-gap: $selector-size-inpage-rowgap-mobile;
        grid-template-columns: repeat(8, 1fr);
      }
    }
  }
}
.selector-size-expanded {
  .selector-size-item {
    &:hover:not(.selected) {
      .selector-size-item__label {
        &::after {
          display: none;
        }
      }
    }
  }
}
