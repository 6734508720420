.grid-container {
  .firstcol {
    .product-tile__info {
      padding-left: get($grid-container-padding, lg);
    }
    .product-tile__card {
      .product-tile__info {
        padding-left: get($grid-container-padding, lg);
      }
    }
  }
}

.plppromo {
  &.plppromo {
    &__promo-medium {
      .plppromo__div {
        .plppromo {
          &__text-wrapper {
            padding: ($space-unit * 22);
          }
        }
      }
    }
    &__promo-large {
      .plppromo {
        &__picture {
          &:before {
            content: none;
          }
        }
      }
    }
    &__promo-small {
      .plppromo {
        &__div {
          &.bottom {
            .plppromo__text-wrapper {
              top: 68%;
            }
          }
        }
      }
    }
    &__promo-banner {
      .plppromo__div {
        min-height: auto;
        height: 100%;
        .plppromo__text-wrapper {
          padding: 6.4rem;
          .plppromo__logo {
            width: 6.4rem;
            position: relative;
            margin: 1rem auto;
          }
          .plppromo__title {
            font-size: 5.4rem;
            line-height: 6.4rem;
          }
        }
      }
    }
  }
}
