.gc-icons{
  margin-bottom: 60px;
}

.gc-config-modal{

  .modal__body{
    .product-notify__detail{
      max-height: inherit;
    }

    .gc-amount-btn-container {
      padding-bottom: 7.5px;
    }

    .gc-amount-btn{
      &-container{
        flex-wrap: wrap;
      }
    }

    .gc-custom-check{
      margin-top: 10px;
    }

    .modal__separator{
      margin-bottom: 14px;
    }
  }
}

.gc-checkout{
  &-amount-row{
    margin-bottom: 12px;
  }

  &-remove-btn{
    margin-top: 4.6rem;
  }
}

.gc-error-box{
  &.gc-checkout {
    margin-top: 4px !important;
  }
}