.model-selection {
  position: relative;
  width: 100%;
  height: auto;
  z-index: 1;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.25, 1);
  &::before {
    content: '';
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: min(200%, 56vh);
  }
  &.selected {
    transform: scale(1.1);
    .mbm-dark-mode & {
      .model-button {
        border: 1px solid rgba($white, 0.25);
      }
    }
    .proceed-to-palette {
      opacity: 1;
      visibility: visible;
      clip: initial;
    }
  }
  &.palette-selection {
    .model-title {
      margin-bottom: 1.6rem;
    }
  }
  .model-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3.2rem 1.6rem;
    width: 100%;
    height: 100%;
    flex-wrap: nowrap;
    transition: all 1s cubic-bezier(0.25, 1, 0.25, 1);
    padding-bottom: 6.8rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 10%);
    background-color: $white;
    .mbm-dark-mode & {
      border: 1px solid rgba($white, 0.1);
      background-color: #1e1e1e;
      box-shadow: 0 -8px 30px rgba(0, 0, 0, 0.03);
    }
  }
  .model-image {
    position: relative;
    overflow: hidden;
    width: calc(100% - 32px);
    flex-shrink: 1;
    flex-grow: 1;
    min-height: 0;
    transition: all 1s cubic-bezier(0.25, 1, 0.25, 1);
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .model-title {
    margin-bottom: ($space-unit * 2);
  }
  .model-gender {
    margin-bottom: ($space-unit * 10);
  }
  .proceed-to-palette {
    bottom: 0;
    width: 100%;
    @extend .visually-hidden;
    transition: all 0.5s cubic-bezier(0.25, 1, 0.25, 1);
    z-index: 1;
    -webkit-transform-style: preserve-3d;
    -webkit-transform: translate3d(0, 0, 0);
    .mbm-dark-mode & {
      @extend .button--primary-inverted;
    }
  }
  .video-wrapper {
    .th-media-bg-color {
      background: initial;
    }
    video {
      object-fit: contain;
    }
  }
}
