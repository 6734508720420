.hero {
  position: relative;
  display: flex;
  // overflow: hidden;
  color: $white;

  &.rpy {
    min-height: 360px !important;
    background: linear-gradient(180deg, #a5a5a5, transparent);
    margin-top: 56px;

    div[data-auth='false'] .hero-text-wrapper {
      justify-content: center;
      .welcome-msg,
      .link,
      .logout {
        display: none;
      }
    }

    .hero-text-wrapper {
      text-align: center;
      color: $white;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
      z-index: 2;

      &.justify-center {
        justify-content: center;
      }

      .welcome-msg {
        padding-bottom: 16px;
        text-transform: uppercase;
        line-height: 24px;
        font-size: 20px;
        .guest-msg {
          font-size: 32px;
        }
      }

      .logoPeaks {
        height: 32px;
        background-size: 340px auto;
      }

      .link {
        margin-top: 12px;
        width: fit-content;
        margin-inline: auto;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
      }

      .logout {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        margin-top: 72px;

        .icon--logout {
          filter: invert(1);
        }
      }
    }
  }

  &-box__link {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 1;
  }

  .text-color--black {
    color: $black;
  }

  .text-color--white {
    color: $white;
  }

  &.flexible-height {
    > .hero__hero-column,
    .hero-carousel__slider {
      height: var(--main-section-height);
    }
    .image-background--linear::before {
      height: 100%;
    }
  }

  .btn--pause {
    position: absolute;
    background-color: transparent;
    left: 2.4rem;
    bottom: 3rem;
    z-index: 6;
    outline-color: $white;
  }

  // pause button for carousel
  > .btn--pause {
    left: initial;
    right: 2.4rem;
  }

  .btn--volume {
    position: absolute;
    left: 5.6rem;
    bottom: 1.6rem;
    z-index: 6;
    outline-color: $white;
  }

  .hero-column__title,
  .hero-column__title-visible {
    display: block;
    text-transform: uppercase;
    color: inherit;
    position: relative;
    z-index: 2;
    text-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.2);
    b {
      font-weight: 300;
    }
  }

  .hero-column__headline {
    max-width: 100%;
    z-index: 2;
    color: inherit;
    &.text-black {
      color: black;
    }

    &.spacing {
      padding: 0;
      font-size: 20px;
      line-height: 24px;
    }
  }

  //edge cases for page design hero app page
  &.hero--app-page {
    .hero-column__inner-container {
      @media screen and (max-width: 1023px) {
        padding-top: 10rem;
        justify-content: flex-start;
        align-items: center;
        .hero-column__headline {
          .desktop-text {
            display: none;
          }
          .mobile-text {
            display: flex;
            justify-content: center;
            text-align: center;
            span {
              p {
                margin-top: 2.4rem;
              }
            }
          }

          .badge-row {
            margin-top: 3.2rem;
          }

          .hero-column__cta,
          .download-app-btn-wrapper {
            display: flex;
            justify-content: center;
          }
        }
      }
      justify-content: center;
      align-items: flex-start;
      .hero-column__headline {
        @media screen and (min-width: 1024px) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-80%, -50%);
          .mobile-text {
            display: none;
          }
        }
        .badge-row {
          display: flex;
          flex-wrap: wrap;
          margin-top: 4.4rem;

          img {
            height: 4.4rem;
          }

          .badge-column {
            margin-right: 1.6rem;
          }
        }

        .download-app-btn-wrapper {
          button {
            margin-top: 3.2rem;
            font-size: 1.6rem;
            line-height: 20px;
          }
        }
        @media screen and (max-width: 599px) {
          .download-app-btn-wrapper {
            display: none;
          }
        }
      }
    }
  }

  // label
  .hero-column__label {
    color: inherit;
    display: block;
    position: relative;
    z-index: 2;
    // text-shadow: 0 0 0.5rem rgba(15, 15, 15, 0.15);
    margin-bottom: ($space-unit * 2);
  }

  .hero-column__launch {
    display: block;
    @extend %primary-l-u;
    color: inherit;
    text-shadow: 0 0 0.5rem rgba(15, 15, 15, 0.15);
    margin-top: ($space-unit * 2);
  }

  .button {
    color: inherit;
  }

  .hero-column__img {
    object-fit: cover;
    position: absolute !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    .img-desk-none {
      display: none;
    }
    img {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      bottom: 0;
      top: 0;
      object-fit: cover;
      max-width: 100%;
      height: 100%;
    }
  }
}

.hero__hero-column {
  position: relative;
  width: 100%;
  display: block;
  z-index: 3;

  &.new-parallax-animations {
    overflow: hidden;
  }

  &.swiper-slide {
    transition: 0.8s ease-in-out;
    transition-property: opacity, visibility;
  }
  &.swiper-slide-active {
    z-index: 4;
  }
  .swiper-container &:not(.swiper-slide-active) {
    visibility: hidden;
  }
}

.hero-column__inner-container {
  padding: ($space-unit * 12);
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  text-decoration: initial;
  &:focus {
    .button {
      text-decoration: underline;
      text-underline-position: under;
    }
  }
}

// .hero--hero-with-columns:empty {
//   @include animate-skeleton();
// }

.hero--hero-with-columns {
  .parallax-element img {
    transform-origin: top;
  }
  &.full-height {
    height: var(--main-section-height);
  }
  &.fixed-ratio {
    aspect-ratio: var(--fixedRatioMobile);
  }
  // Class names reflects ratio on desktop, on mobile those translate according to _aspect-ratio.scss
  &.fixed-ratio--16-9 {
    aspect-ratio: 2/3;
  }
  &.fixed-ratio--21-9 {
    aspect-ratio: 100/133;
  }
}

// Alignment property

.hero--centered {
  text-align: center;
  .hero-column__inner-container {
    justify-content: center;
    align-items: center;
  }
}

.hero--left-top {
  .hero-column__inner-container {
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.hero--left-bottom {
  .hero-column__inner-container {
    justify-content: flex-end;
    align-items: flex-start;
  }
}
.hero--center-bottom {
  text-align: center;
  .hero-column__inner-container {
    padding-bottom: 4.8rem;
    justify-content: flex-end;
    align-items: center;
  }
}
.hero--center-top {
  text-align: center;
  .hero-column__inner-container {
    padding-top: 10rem;
    justify-content: flex-start;
    align-items: center;
  }
}
.hero--center-left {
  .hero-column__inner-container {
    justify-content: center;
    align-items: flex-start;
  }
}
.hero--center-cta-lower {
  text-align: center;
  .hero-column__inner-container {
    justify-content: space-between;
    align-items: center;
    .hero-column__headline {
      margin: auto 0;
    }
    .hero-column__cta {
      margin-top: 1.9rem;
    }
  }
}

.hero--center-right {
  .hero-column {
    &__inner-container {
      justify-content: center;
      align-items: flex-end;
    }
    &--logo,
    &__label {
      text-align: right;
    }
  }
}

.hero--right-top {
  .hero-column {
    &__inner-container {
      justify-content: flex-start;
      align-items: flex-end;
    }
    &--logo,
    &__label {
      text-align: right;
    }
  }
}
.hero--right-bottom {
  .hero-column {
    &__inner-container {
      justify-content: flex-end;
      align-items: flex-end;
    }
    &--logo,
    &__label {
      text-align: right;
    }
  }
}

// Margin property
.hero--without-border {
  &,
  &.hero__hero-column {
    margin: 0;
  }
}
.hero--with-border {
  margin: ($space-unit * 8) 3rem;
  .hero__hero-column {
    margin: 0 0.2rem;
  }
}

// Button styles

.hero-column__cta {
  // @extend %primary-xs;
  position: relative;
  z-index: 2;
  display: flex;
  margin-top: ($space-unit * 4);
  // OE-15593
  // white-space: nowrap;
  .button {
    color: $white;
    @extend %primary-xs;
    font-size: 16px;
    line-height: 20px;
    width: auto;
    &:not(:first-child) {
      margin-left: 1.6rem;
    }
  }
  &.clothing {
    .button {
      // OE-15593
      white-space: nowrap;
    }
  }
}

// .hero-column__cta,
// .hero-column__headline,
// .swiper-pagination,
// .hero-column__img {
//   opacity: 0;
// }

// .reveal-animations {
//   .hero-column__cta {
//     @include animate(0.4, 'all', $easeOutQuart, 0.25, '!important');
//     opacity: 1;
//   }
//   .hero-column__headline {
//     @include animate(0.4, 'all', $easeOutQuart, 0.25, '!important');
//     opacity: 1;
//   }
//   .swiper-pagination {
//     @include animate(0.4, 'all', $easeOutQuart, 0.35, '!important');
//     opacity: 1;
//   }
//   .hero-column__img {
//     @include animate(0.4, 'all', $easeOutQuart, 0, '!important');
//     opacity: 1;
//   }
// }

.hero--cta-button {
  .button {
    padding: 0 2.3rem;
    height: 3.6rem;
    white-space: nowrap;
  }
}

// logo
.hero-column--logo {
  position: relative;
  margin-bottom: 1rem;
  @include z;
  img {
    display: inline-block;
  }
}

// text with background image
.hero--hero-background {
  position: relative;
  background: $black;
  @include appearAnimation();
}

.hero__img-bg {
  position: relative;
  img {
    top: 0;
    max-width: 100vw;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    position: absolute;
    height: 100%;
    width: 100%;
  }
}

.hero__main-title {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: $white;
  text-transform: uppercase;
  text-align: center;
  font-weight: 300;
  width: 63.6%;
  text-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.myaccount-hero {
  position: relative;
  height: 21rem;
  // prettier-ignore
  // background: linear-gradient(270deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 50%),
  //             radial-gradient(42.86% 42.86% at 50% 57.14%, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0) 100%),
  //             linear-gradient(0deg, rgba(15, 15, 15, 0.1), rgba(15, 15, 15, 0.1)),
  //             var(--url);
  // background-blend-mode: normal, normal, multiply, normal;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 25%;

  &.gradient-rpy--half {
    &::before {
      height: 50%;
    }
  }

  &.gradient-rpy--whole {
    &::before {
      height: 100%;
    }
  }

  &.gradient-rpy--bright {
    &::before {
      background: linear-gradient(180deg, rgba(63, 63, 63, 0) 0%, rgba(63, 63, 63, 0.4) 100%);
    }
  }

  &.gradient-rpy--darker {
    &::before {
      background: linear-gradient(0deg, rgba(63, 63, 63, 0.7) 0%, rgba(63, 63, 63, 0) 100%);
    }
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0%;
  }

  &.client-services {
    // background: var(--url);
    // background-size: cover;
    // background-repeat: no-repeat;
    background-position: 50% 40%;
    // margin-bottom: 6.4rem;
  }

  &.legal-area {
    margin-bottom: 6.4rem;
  }

  &__title {
    @extend %primary-xl-u;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    color: $white;
    margin-bottom: ($space-unit * 12);
    text-align: center;
    width: 100%;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  }
}

.hero-column__title--bigger {
  @extend %secondary-m;
  // for countdown
  // b {
  //   display: inline-block;
  //   width: 4.5rem;
  //   text-align: right;
  // }
}
// .countdown {
//   b {
//     display: inline-block;
//     width: 4rem;
//     text-align: center;
//   }
// }

/* .title--hidden {
  .hero-column {
    &__headline,
    &__cta {
      opacity: 0 !important;
    }
  }
} */

.product-video-pause {
  position: absolute;
  bottom: 2.8rem;
  left: 1.4rem;
  z-index: 2;
  filter: invert(1);
  @include fixedSprite('pause-white');
  &[aria-pressed='true'] {
    @include fixedSprite('play-white');
  }
  &:focus {
    outline-color: $white;
  }
}

.hero-skinny {
  height: 36rem;

  .centered-title {
    transform: translate(-50%, 50%);
    top: auto;
    bottom: 50%;
    margin: 0;
  }

  &--video {
    .btn--pause {
      position: absolute;
      bottom: ($space-unit * 6);
      left: 2rem;
    }
    .btn--volume {
      position: absolute;
      bottom: ($space-unit * 6);
      left: 5.6rem;
    }

    .hero-skinny__inner-container {
      .video-wrapper {
        opacity: 1;
        pointer-events: visible !important;
      }
    }

    .video-wrapper {
      .th-button {
        .th-svg-container {
          pointer-events: visible !important;
        }
      }
      .th-controls {
        .th-control-bar {
          // &.th-bottom {
          //   visibility: visible;
          //   opacity: 1;
          // }
          .th-group {
            &.th-main {
              display: flex;
            }
            .th-button-color {
              &:hover {
                color: white;
                fill: white;
              }
            }
            .th-play-button {
              order: 1;
            }
            .th-time-seek {
              order: 3;
              max-width: 100%;
            }
            .th-volume-button {
              order: 2;
              margin-left: -0.9rem;
            }
            .th-time-rail {
              background-color: transparent;
              pointer-events: visible !important;
              .th-slider-bg-color {
                background-color: rgba(255, 255, 255, 0.25);
                stroke: rgba(255, 255, 255, 0.25);
                .th-time-current {
                  background-color: white;
                  stroke: white;
                }
              }
            }
            .th-hd-button {
              display: none;
            }
            .th-speed-button {
              display: none;
            }
            .th-downloadable-button {
              display: none;
            }
            .th-time-info-text {
              display: none;
            }
            .th-fullscreen-button {
              display: none;
            }
          }
        }
      }
    }

    .no-volume {
      .video-wrapper {
        .th-controls {
          .th-control-bar {
            .th-group {
              .th-volume-button {
                display: none;
              }
            }
          }
        }
      }
    }

    .no-timerail {
      .video-wrapper {
        .th-controls {
          .th-control-bar {
            .th-group {
              .th-time-seek {
                display: none;
              }
            }
          }
        }
      }
    }

    .th-video .th-video-player {
      .th-big-play-btn {
        display: none;
      }
    }
    .th-control-bg-color {
      background-color: transparent;
    }

    .th-reload-icon.th-display-icon.th-svg-container {
      display: none;
    }

    .th-video .th-video-player .th-video-poster {
      background-size: cover;
    }

    .th-button-color-active {
      fill: $white;
      color: $white;
    }

    [data-anim] {
      &.delay-3000 {
        transition-delay: 3s;
      }
    }

    [data-anim^='fade'] {
      opacity: 0;
      visibility: hidden;
      transition-property: opacity, transform, visibility;
      transition-duration: 0.3s;
    }
    [data-anim='fade-up'] {
      transform: translate3d(0, 50px, 0);
    }
    [data-anim^='fade'].aos-animate {
      opacity: 1;
      visibility: visible;
      transform: translateZ(0);
    }
    [data-anim].aos-animate {
      transition-delay: 0.1s;
    }
  }

  // the volume slider is rendered in fixed position outside the component itself
  .th-tooltipster-base {
    display: none !important;
  }

  .hero-search {
    &__container {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &__title {
      position: static;
      transform: none;
      padding: 0 0 4rem;
    }
  }
}

.hero--hero-with-columns {
  .hero-column__cta {
    .button {
      &.button--primary {
        color: $white !important;
      }

      &.button--primary-inverted {
        color: $black;
      }
    }
  }
}

//page designer my_account_hero
.hero-listing-wrapper {
  .hero-card {
    background-color: #efffff;
    margin-bottom: 15px;

    .region-name {
      text-align: center;
      font-weight: bolder;
      text-decoration: underline;
      color: #00b3ff;
      width: 100%;
      display: block;
      padding: 15px;
    }
  }
}

.experience-account-hero.mt__zero {
  .hero.rpy {
    margin-top: 0;
  }
}
