.palette-selection {
  &.selected {
    .palette-label {
      opacity: 1;
      max-height: 3.2rem;
      margin-bottom: 1.2rem;
    }
    .palette-moreinfo {
      visibility: visible;
      opacity: 1;
    }
  }
  .palette-label {
    // padding: 0 1.6rem;
    // width: 70%;
    text-align: center;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    flex-shrink: 0;
    transition: all 1s cubic-bezier(0.25, 1, 0.25, 1);
  }
  .palette-moreinfo {
    text-decoration: underline;
    display: block;
    outline-offset: 0.2rem;
    margin-bottom: 2rem;
    visibility: hidden;
    opacity: 0;
    transition: all 1s cubic-bezier(0.25, 1, 0.25, 1);
    .mbm-dark-mode & {
      color: $white;
    }
  }
}
.palette-img {
  margin-bottom: 2rem;
  height: 1rem;
}
.palette-bg-modal {
  position: absolute;
  top: 0;
  left: 0;
  height: 50%;
  width: 100%;
  object-fit: cover;
}
.palette-details {
  position: relative;
  background: white;
  width: calc(100% - 3.2rem);
  padding: 3.2rem 2.4rem 4rem;
  margin: 0 auto;
  margin-bottom: 4rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1);
  h4 {
    color: $black_secondary;
  }
}
.overlay-modal.mbm-palette-overlay {
  background: rgba(0, 0, 0, 0.55);
  transition-duration: 0.4s;
}
.modal.mbm-palette-modal {
  overflow: initial;
  top: initial;
  max-height: 700px;
  min-width: initial;
  height: 80vh;
  .mbm-dark-mode & {
    background-color: $black;
    .modal__close {
      filter: invert(1);
      border: 1px solid $black;
    }
  }
  .modal__close {
    top: initial;
    right: 50%;
    background: $white;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    transform: translate(50%, -50%);
    width: 4rem;
    height: 4rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal__body {
    // min-height: 70rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    // background-color: black;
  }
}
