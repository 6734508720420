.image-module {
  &--small-square {
    .single-image {
      &__image {
        width: 66.67%;
        padding-top: 66.67%;
      }
      &__caption {
        width: 66.67%;
        margin: ($space-unit * 4) auto;
      }
    }
  }

  &--big-square {
    .single-image {
      &__image {
        width: 83.33%;
        padding-top: 83.33%;
        margin: 0 auto;
        max-width: 100%;
      }
      &__caption {
        width: 83.33%;
        margin: ($space-unit * 4) auto;
      }
    }
  }

  &--small-landscape {
    .single-image {
      &__image {
        width: 83.33%;
        &__caption {
          width: 83.33%;
        }
      }
    }
  }

  &.swiper-container {
    .single-image {
      &__image {
        width: 68.6rem;
      }
    }
  }
}
