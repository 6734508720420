.product-zoom {
  background: #000;
  position: relative;
  overflow: hidden;
  height: calc(var(--viewport-height) - 11.8rem);
  .swiper-slide {
    @include animate();
    opacity: 0 !important;
    pointer-events: none;
    &.swiper-slide-active {
      opacity: 1 !important;
      pointer-events: auto;
    }
    img {
      cursor: grab;
      @include animate(0.5, 'opacity');
      &.dragging {
        cursor: grabbing;
      }
    }
  }

  img {
    position: relative;
    height: auto;
    max-height: 100%;
    width: auto;
    max-width: 100%;
    margin: 0 auto;
  }

  &-thumbs {
    $slideW: 5.3408rem;
    $slideH: 7.941rem;
    max-width: ($slideW * 5 + ($slideW / 2) + (0.47rem * 5));
    width: 100%;
    &--wrapper {
      height: 11.8rem;
      padding: 0 3rem;
      background-color: $black;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__thumb-button {
      pointer-events: all;
      height: 100%;
      width: 100%;
      &:focus-visible {
        outline-offset: -0.1rem;
      }
      .product-video-pause {
        display: none;
      }
    }

    &__button {
      display: none;
    }

    .swiper-slide {
      // opacity: 0.5;
      position: relative;
      pointer-events: none;

      video {
        width: 100%;
        height: 100%;
      }

      img,
      video {
        max-height: $slideH;
        width: 100%;
        height: auto;
        // object-fit: cover;
        opacity: 0.45;
        transition: opacity 0.3s ease-in-out;
      }
      &-thumb-active {
        img,
        video {
          opacity: 1;
        }
      }
    }
  }
}

.product-image-button {
  display: block;
  width: 100%;
  height: calc(var(--viewport-height) - #{$button-height-mobile});
  cursor: url(./../../media/zoom-in-new.svg), zoom-in;
  .product-hero {
    height: 100%;
  }
}

body.has-ticker {
  .product-image-button {
    height: calc(var(--viewport-height) - #{$button-height-mobile} - #{$ticker-bar-m});
  }
}
[data-whatinput='keyboard'] {
  .product-image-button:focus > div::after {
    content: '';
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    outline: 1px solid $white;
    outline-offset: -0.2rem;
    top: 0;
    left: 0;
  }
}

.product-zoom-modal {
  .modal__body,
  .zoom-img-wrapper,
  // .product-zoom,
  .gallery-wrapper,
  .gallery-wrapper *:not(img) {
    width: 100%;
    height: 100%;
    .gallery-wrapper__video {
      .product-video-pause {
        position: absolute;
        z-index: 2;
        bottom: 1.6rem;
        left: 1.6rem;
        width: 2.4rem;
        height: 2.4rem;
        filter: invert(0);
      }
    }
  }

  &.modal {
    background-color: $black;

    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    transform: none;
    .modal {
      &__close {
        // background-color: #000;
        padding: 0;
        width: 4.4rem;
        height: 4.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        &:focus-visible::before {
          outline: 0.1rem solid $white;
        }
        right: 0.6rem;
        top: 0.6rem;
      }
      &__zoom {
        &-controls {
          position: absolute;
          bottom: 2.1rem;
          right: 2.1rem;
          border: 0.1rem solid $white;
          background-color: rgba(0, 0, 0, 0.2);
          z-index: 2;
          width: auto;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.4rem;
        }
        &-in,
        &-out {
          width: 3.6rem;
          height: 3.6rem;
          &[disabled] {
            opacity: 0.25;
          }
          &::before {
            content: '';
            width: 3.6rem;
            height: 3.6rem;
            display: block;
          }
        }
        &-scale {
          width: 3.6rem;
          flex: 1;
          color: $white;
          font-size: 1.2rem;
          text-align: center;
          vertical-align: middle;
          &::before {
            content: none;
          }
        }
      }
      &__close {
        &::before {
          @include sprite('close-white');
        }
      }
      &__zoom-in {
        &::before {
          @include sprite('plus-white');
        }
      }
      &__zoom-out {
        right: 9rem;
        &::before {
          @include sprite('minus-white');
        }
      }
    }
  }

  .product-gallery__link {
    color: $white;
    position: absolute;
    z-index: 2;
    top: 1.6rem;
    left: 1.6rem;
  }
  button.product-gallery__link {
    text-decoration: underline;
  }

  .gallery-wrapper {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 100%);
      width: 100%;
      height: 12rem;
      @include animate();
    }

    &::after {
      transform: rotate(180deg);
      top: unset;
      bottom: 0;
    }
  }

  // .wrapper-product-video {
  //   max-height: 80vh;
  //   top: 10vh;
  //   position: relative;
  // }
}

.zoom-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
