$boxPadding: 32px 40px;

.appointments-card {
  &__body {
    display: flex;
    flex-direction: column;
    text-align: center;

    padding: $boxPadding;

    .appointment-date,
    .appointment-time {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      column-gap: 1ch;
    }

    .appointment-btn-group {
      width: 100%;
      max-width: 362px;
      margin-inline: auto;
    }
  }
}

.appointments__book-banner {
  margin-top: 40px;
}

.account-appointments-detail {
  margin-top: 40px;

  &__number {
    .detail-back-btn {
      display: block;
      .back-btn {
        position: static;
        left: 0;
      }

      h4 {
        margin-top: 40px;
      }
    }
  }
}

.edit-appointment-confirm__body {
  .info {
    margin-top: 18px;
  }
  .info__btn-group {
    flex-direction: row-reverse;
  }
}
