.store-page {
  .store-quote {
    height: auto;
    .quote-wrapper {
      padding: 0 10%;
    }
    img {
      height: auto;
    }
  }
  .instore-services {
    .instore-service-wrapper {
      &:not(.only-child) {
        grid-template-columns: repeat(2, minmax(0, 50%));
        column-gap: ($space-unit * 8);
        row-gap: ($space-unit * 12);
      }
      &.only-child {
        max-width: 50%;
        margin: 0 auto;
      }
    }
  }
  .boutiques-nearby {
    > div:nth-child(2) {
      order: initial;
      padding-top: initial;
    }
    .store-locator__map {
      height: 100%;
    }
    .google-map {
      width: calc(100% - 2.8rem);
    }
  }
  .breadcrumb {
    a {
      font-size: 1.4rem;
      line-height: 1.6rem;
    }
  }
  .picture {
    padding-bottom: ($space-unit * 6);
  }
}
