.hero-promo-video {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 56.2rem;
  position: relative;
  &__video {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  &__picture {
    width: 100%;
    height: 100%;
    position: absolute;
    img {
      position: absolute;
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
  .btn--pause {
    position: absolute;
    bottom: ($space-unit * 6);
    left: 2rem;
  }
  .btn--volume {
    position: absolute;
    bottom: ($space-unit * 6);
    left: 5.6rem;
  }
  &__text-wrapper {
    color: $white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    text-align: center;
    padding: ($space-unit * 4);
  }
  &__tagline {
    @extend %primary-s-u;
  }
  &__logo {
    display: inline-flex;
  }
  &__title {
    @extend %primary-xxxxl;
    text-transform: uppercase;
    margin-bottom: ($space-unit);
    &.BlockGothicRR {
      font-family: 'BlockGothicRR';
      font-weight: bold;
      $mobile-font-data: map-get($genius-mobile, 's');
      font-size: nth($mobile-font-data, 1);
      line-height: nth($mobile-font-data, 2);
      letter-spacing: nth($mobile-font-data, 3);
    }
  }
  &__description {
    @extend %primary-s;
    // max-width: 290px;
    margin: 0.8rem auto;
  }
  &__cta-wrapper {
    margin: ($space-unit * 6) 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .hero-promo-video {
      &__cta {
        margin: $space-unit $space-unit;
        font-size: 1.6rem;
        line-height: 2rem;
        width: 100%;
      }
    }
  }
  &__tagline,
  &__title,
  &__description {
    margin-bottom: ($space-unit * 2);
  }
}

.cs-editorial-video {
  width: 100%;
  position: relative;
  margin: 1.6rem auto;
  display: flex;

  &:before {
    content: '';
    height: 0;
    padding-top: calc(150% + 1.6rem);
  }
  .hero-promo-video {
    &__tagline {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  }
  [data-play] {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
  }
}
