.visual-navigation {
  padding: 3.2rem 0;
  // OE-16934 - Optimize Editorial Module Borders/Paddings
  &.pd-no__padding {
    padding-block: 0;
  }
  @include appearAnimation();
  &__wrapper {
    display: flex;
    &::before,
    &::after {
      content: '';
      display: inline-block;
      width: 3.2rem;
      min-width: 3.2rem;
      height: 1rem;
    }
  }
  &__item {
    padding: 0 0.2rem;
    width: 100%;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 100vw;
    object-fit: cover;
    height: 100%;
  }
  .visual-card {
    height: 100%;
    span {
      font-size: 4.2rem;
      line-height: 5rem;
      text-align: center;
      margin-bottom: 0.25rem;
      &.visual-navigation__cta {
        @extend .hero-column__cta;
        white-space: nowrap;
        text-wrap: nowrap;
        justify-content: center;
        .button {
          width: auto;
        }
        a.button {
          font-size: 1.6rem;
        }
      }
    }
  }
  &--small-font {
    .visual-card {
      span {
        font-size: 2.4rem;
        line-height: 2.8;
      }
    }
  }
}
