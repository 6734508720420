.product-reserve {
  &__detail__review-grid {
    grid-template-areas:
      'product product'
      'divider divider'
      'store customer';
  }
}

.reserve-form-modal {
  height: 90vh;
  .modal__header {
    padding-top: 2.4rem;
    margin-left: -3.2rem;
    padding-left: 3.2rem;
    margin-right: -3.2rem;
    padding-right: 3.2rem;
  }
}
