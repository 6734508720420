.component_grenoble_performance {
  &__title-wrapper {
    padding-top: 3.2rem;
  }
  &__image-wrapper {
    padding-bottom: 2.4rem;
    &:after {
      bottom: 0;
    }
  }
}
