.editorial-text {
  &--4x1 {
    grid-column: span 4;
  }
  &__block {
    &:not(.slim) {
      min-height: 61.7rem;
    }
  }
  &__link {
    text-decoration: none;
    position: relative;
  }
  &__picture {
    // max-width: 22%;
    // max-height: 66%;
    width: 31.3rem;
    height: 35.9rem;
  }
  &__logo-bg {
    width: 41.8rem;
  }
}
