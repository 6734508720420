*:focus-visible {
  // avoid setting color so it fallsback to parent color
  outline: $outline-width solid;
  // outline-offset: 0;
}
.focus-inverted {
  *:focus-visible {
    outline-color: white;
  }
}

// [data-whatintent='mouse'],
// [data-whatintent='touch'] {
//   *:focus {
//     outline: transparent;
//   }
// }

.visually-hidden {
  position: absolute !important;
  height: 0.1rem;
  width: 0.1rem;
  overflow: hidden;
  clip: rect(0.1rem 0.1rem 0.1rem 0.1rem);
  white-space: nowrap;
  padding: 0;
}

.googlebot-content-view {
  @extend .visually-hidden;
}

.visible-on-focus {
  &:not(:focus):not(:active):not(:focus-within) {
    @extend .visually-hidden;
  }
}

body.increased-contrast {
  .hero__hero-column:not(.swiper-slide) .hero-column__inner-container,
  .hero-promo-video__div,
  .promo-module__inner-container .promo-column__img-holder,
  .collection-intro__img-wrapper .collection-intro__img,
  .visual-navigation__wrapper .visual-navigation__card {
    @include increased-contrast(1);
  }
  .hero-carousel__main-wrapper .swiper-slide.swiper-slide-visible {
    @include increased-contrast(0);
  }
  .video-module__inner-container:not(.video-opened) {
    @include increased-contrast(2);
  }
  .intro-plp__image,
  .plppromo__picture,
  .plppromo--2x2 img {
    filter: brightness(45%);
  }
}
