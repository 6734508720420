.cookie-bar {
  .cookie-msg {
    margin-bottom: 1.2rem;
    max-height: 50vh;
    overflow-y: auto;
  }
  .button {
    margin-top: 1.2rem;
  }
}
.cookie-center {
  .buttons-wrapper {
    position: relative;
  }
}
