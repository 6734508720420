.product-look {
  .product-look__title {
    @extend .component__title;
  }

  .product-look__image {
    margin-bottom: 1.6rem;
  }
  .product-tile__info.lookinfo {
    overflow: hidden;
  }
  .product-look__column {
    z-index: 1;
    .row {
      list-style: none;
      padding: 0;
      .product-look__tile {
        .product-image {
          img {
            width: 100%;
            object-fit: cover;
          }
          &:before {
            padding-top: 128.637%;
          }
        }
      }
    }
  }
  .selector-size {
    .selector__menu {
      /* clicking area was too small by using column-gaps */
      .selector__menu--size--radio-btn {
        cursor: auto;
      }
      &__item {
        flex-shrink: 1;
        &.is-disabled {
          color: $black_secondary;
          &::after {
            pointer-events: none;
          }
        }
      }
    }
  }
  .button.button--secondary {
    background-color: $white;
  }
}
