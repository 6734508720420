body#nft-landing,
body#nft-proofofownership {
  main {
    border-bottom: none;
    padding-bottom: 2.4rem;
  }
}

.nft-landing {
  text-align: center;
  &__header {
    .logo-header {
      padding: 1.2rem 0 3.5rem;
      display: inline-block;
      img {
        width: 10.2rem;
      }
    }
  }
  &.proof-of-ownership {
    h1 {
      text-transform: uppercase;
      margin-bottom: 0;
      font-size: 2.4rem;
      line-height: 2.8px;
    }
    .nft-image {
      margin-top: 1.6rem;
    }
    .result-wrapper {
      border-top: 1px solid $grey_line;
      padding-top: 1.8rem;
      h2 {
        font-size: 1.6rem;
        line-height: 20px;
        margin-bottom: 1rem;
        font-weight: bold;
        i {
          position: relative;
          top: 0.3rem;
        }
      }
      p {
        font-size: 1.4rem;
        line-height: 1.6rem;
      }
    }
  }
  h1 {
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-bottom: 2.4rem;
  }
  p {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .qr-image {
    margin: 4rem 0;
  }

  .badges-wrapper {
    display: inline-flex;
    margin-top: 2.4rem;
    a {
      margin: 0 0.6rem;
      img {
        height: 3.6rem;
      }
    }
  }

  .nft-image {
    display: inline-block;
    margin-top: 3.2rem;
    margin-bottom: 1.6rem;
    // border: 3.5px solid;
    // border-image: repeating-linear-gradient(11deg, #d0d0ff, #ffcef9, #ffd7bf, #fff2bb, #ecffba, #acffc5, #b1f6ff 60%) 1;
    width: 100%;
    max-width: 28.5rem;
  }

  .nft-landing-bottom {
    // padding: 4.8rem 0;
    padding-bottom: 4.8rem;
    margin-bottom: 4rem;
    .col-12 {
      border-top: 1px solid $grey_line;
      padding: 3.2rem 0;
      h2 {
        font-size: 1.8rem;
        line-height: 2.2rem;
      }
      p {
        font-size: 1.4rem;
        line-height: 1.6rem;
      }
    }
  }
}

.nft-loading-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10rem;
  img {
    width: 2.4rem;
    margin: 0 auto;
    margin-bottom: 0.8rem;
  }
  h4 {
    max-width: 15rem;
    text-align: center;
  }
}
