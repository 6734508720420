.bag-product {
  display: flex;
  flex-direction: row;
  padding-top: 0;
  padding-bottom: $space-unit * 4;
  margin-bottom: $space-unit * 4;

  //LEAVE :last-of-type CONDITION, OE-27398
  &:not(:last-of-type) {
    @include border('bottom');
  }
  &:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &--product-exchanged {
    .product-image {
      opacity: 0.55;
    }
  }

  &--parfum-disabled {
    .product-image,
    .bag-product__name-price,
    .option-product__quantity,
    .option-product__color,
    .option-product__size {
      opacity: 0.55;
    }
  }

  &--membersonly {
    @extend %primary-xxs-u;
    opacity: 0.55;
  }

  .product-image-button {
    height: auto !important;
    position: relative;
    cursor: pointer;
    i {
      position: absolute;
      // filter: invert(1);
      z-index: 1;
      right: 0.6rem;
      top: 0.6rem;
    }
  }

  .product-image {
    height: auto;
  }

  > .row {
    flex-grow: 1;
  }

  &__divider {
    @include border('top');
    height: $space-line;
    margin-top: $space-unit * 4;
    margin-bottom: $space-unit * 4;
  }

  &__content {
    position: relative;
    margin-left: $space-unit * 3;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    > .bag-product__preorderinfo {
      display: none;
    }
    &.removing {
      .bag-product__actions {
        margin-top: 2rem;
      }
    }
  }

  &__out-of-stock {
    color: $status_error;
    margin-top: 1.8rem;
  }

  &__no_refundable {
    font-weight: bold;
    font-size: 1.2rem;
    margin-top: 1.4rem;
  }

  &__name-price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: -0.15rem;
    &__price-block {
      flex-shrink: 0;
    }
    &__name {
      @extend %primary-s;
      @include del-link;
      flex-shrink: 1;
      padding-right: 1.2rem;
      text-decoration: underline;
    }

    &__price {
      @extend %primary-s;
      flex-shrink: 0;
    }
    &__message {
      @extend %primary-xs;
      color: $black_secondary;
      margin-bottom: -($space-unit * 6);
      text-align: right;
    }
    // h3 {
    //   text-decoration: underline;
    // }
  }

  &__mbmlabel {
    margin-bottom: $space-unit * 5;
    .bag-product--mbm {
      @include fixedSprite('label-monclerbyme');
      width: 90px;
      height: 20px;
      span {
        display: none;
      }
    }
  }

  &__details {
    margin-top: 1rem;
    margin-bottom: $space-unit * 8;

    p {
      @extend %primary-xs;
    }

    .msg {
      max-width: 320px;
      word-wrap: break-word;
    }
    .selector-quantity,
    .selector-size-inline {
      margin-bottom: $space-unit * 3;
    }
    .selector-size-inline {
      min-height: 2.8rem;
    }
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1.6rem;
    row-gap: 0.8rem;
    text-transform: capitalize;
    .cta {
      text-decoration: initial;
    }
  }
  .bag-product__preorderinfo__subtitle {
    margin-top: 1.2rem;
    @extend %primary-xxs;
    color: rgba(0, 0, 0, 0.55);
  }
  &__preorderinfo {
    margin-top: $space-unit * 5;
    &__text {
      margin-top: $space-unit * 2;
      @extend %primary-xxs;
      color: rgba(0, 0, 0, 0.55);
    }
  }

  &__image {
    .product-image {
      height: inherit;
    }
  }

  .disclaimer-box {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    > button {
      text-align: left;
      font-weight: bold;
    }
    p {
      font-weight: bold;
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  &.fade-out {
    animation: fadeOut 0.3s linear;
    opacity: 0;
  }
}
