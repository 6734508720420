.interactive-cards {
  padding: 0;

  &.row {
    align-items: stretch;
  }

  .swiper-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 3.2rem 0;
  }
  &__intro {
    margin-bottom: 15rem;
  }

  &__top-column {
    padding-top: ($space-unit * 18);
    padding-bottom: ($space-unit * 18);
    &:after {
      content: none;
    }
  }
  .scrollable-selector-swiper {
    &:after {
      content: '';
    }
    .swiper-slide {
      .scrollable-selector {
        font-size: 3.2rem;
      }
      .timeline-separator {
        margin: 0 0.8rem;
        &:first-child {
          margin: 0 0.8rem 0 1.6rem;
        }
        &:last-child {
          margin: 0 1.6rem 0 0.8rem;
        }
      }
    }
  }
  &__container {
    padding: 4.2rem 0;
  }
  &:not(.no-timeline) {
    .scrollable-selector-swiper {
      .swiper-slide {
        .scrollable-selector {
          max-width: 21rem;
          margin: 0 1.2rem;
          font-size: 4.2rem;
        }
      }
    }
  }

  .interactive-card {
    &__button {
      --perc-background: 47.35%;
      width: 11.3rem;
      height: 18.8rem;
      padding: 0 2.8rem;
    }
  }

  .cards-swiper {
    .swiper-slide {
      width: 34.3rem;
    }
  }
}
