header {
  // &.overlay {
  //   @include z(headerOpen);
  // }
  // &::before {
  //   background: $white;
  //   transition-duration: 0s;
  // }

  .menu-wrapper {
    width: 100%;
    max-width: initial;
    transition-delay: 150ms;
    transform: translateX(0);
    opacity: 0;
    .banner-wrapper {
      margin-top: 4.8rem;
    }
  }
  .menu-actions {
    .account-wrapper,
    .menu-close {
      transform: translateX(0);
      opacity: 0;
      width: 100%;
      transition-delay: 150ms;
    }
    .menu-close {
      background-color: $white;
      width: auto;
    }
    .menu-title {
      transform: translateX(0);
      opacity: 0;
      width: 100%;
    }
    .account-wrapper {
      a {
        display: flex;
        width: 100%;
        justify-content: flex-start;
      }
    }
  }
  // .container-submenu {
  //   &.banner-mobile {
  //     padding-bottom: 5rem;
  //   }
  // }

  .navigation {
    &.open {
      .menu-wrapper,
      // .search-container,
      .account-wrapper,
      .menu-close {
        opacity: 1;
      }
      .menu-wrapper {
        transition-delay: 0;
        transition-duration: 0.2s;
      }
    }
    &.first-level {
      .menu-wrapper {
        overflow-x: hidden;
        overflow-y: scroll;
        min-height: -webkit-fill-available;
        .wrapper-submenu,
        .wrapper-submenu.has-banner {
          padding: 9.2rem 0 0;
          overflow: initial;
          height: 100%;
        }
      }
    }
    &.second-level {
      .wrapper-submenu.has-banner {
        .promo-image {
          display: none;
        }
      }
    }
  }
  .collection-submenu {
    flex-direction: row;
  }
}
