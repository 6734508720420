.clothing {
  .ticker-bar {
    background-color: $status_error;
    .message {
      font-weight: bold;
    }
    &.button-logout {
      padding: 0 2rem;
      justify-content: space-between;
      .button {
        text-transform: capitalize;
        font-weight: bold;
      }
      .message {
        margin: 0;
      }
    }
  }
}

.ticker-bar {
  width: 100%;
  position: relative;
  background-color: var(--ticker-bgcolor);
  transition: height 0.2s ease-out, background-color 0.2s ease-in-out;
  overflow: hidden;
  @extend %primary-xs-u;
  --ticker-bgcolor: #{$black};
  --ticker-color: #{$white};
  --animation-delay: 5500ms;

  &.single-slide {
    .swiper-slide {
      white-space: initial;
    }
  }

  .swiper-container {
    display: flex;
    align-items: center;
    position: relative;
    .swiper-wrapper {
      min-height: $ticker-bar-m;
    }
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    white-space: nowrap;
    justify-content: start;
  }

  .message-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 0 3.2rem;
  }

  .message {
    -webkit-text-size-adjust: 100%;
    text-align: center;
    position: relative;
    margin: 0 auto;
    color: var(--ticker-color);
    transition: color 0.2s ease-in-out, transform 0s linear 2700ms;
    font-size: 1.2rem;
    padding: 0 0.5rem;
    span,
    a {
      color: var(--ticker-color) !important;
    }
  }

  .swiper-initialized {
    .message::after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      z-index: 1;
      background-color: var(--ticker-bgcolor);
      transition: background-color 0.2s ease-in-out;
    }
  }

  // animations starts on slide active
  .swiper-slide-active {
    .message {
      &::after {
        animation: reveal 300ms ease-in-out 400ms forwards, cover 300ms ease-in-out 5700ms forwards;
        animation-delay: 400ms, var(--animation-delay);
      }
    }
    .message.swipe-animation {
      position: absolute;
      // animation: move 2s linear 2s forwards;
    }
  }

  @mixin tickerIcon($sprite, $color) {
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: $color;
      -webkit-mask-image: url($sprite);
      mask-image: url($sprite);
      mask-size: cover;
      transition: background-color 0.2s ease-in-out;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    // for default ovverride
    margin-top: initial;
    width: 2.4rem;
    height: 2.4rem;
    outline-color: var(--ticker-color);
    z-index: 1;
    $sprite: map-get($sprites, 'arrow-right-white');
    @include tickerIcon($sprite, var(--ticker-color));
  }

  .swiper-button-next {
    right: 0.4rem;
    transform: translateY(-50%);
  }
  .swiper-button-prev {
    left: 0.4rem;
    transform: translateY(-50%) rotate(180deg);
  }

  &.clickfromstore {
    background-color: $status_error;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: $white;
    .button {
      position: absolute;
      right: 1.2rem;
      text-transform: capitalize;
      color: $white;
    }
  }

  .icon--close-white {
    position: absolute;
    right: $space-unit;
    top: 50%;
    transform: translateY(-50%);
    outline-color: $white;
    background: initial;
    $sprite: map-get($sprites, 'close-white');
    z-index: 1;
    // display: block;
    @include tickerIcon($sprite, var(--ticker-color));
  }

  &.close {
    height: 0;
  }
}

@keyframes reveal {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes cover {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
