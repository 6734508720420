.text-block__inner-container {
  text-align: center;
}

.text-block {
  padding: 4.8rem 0;
  // OE-16934 - Optimize Editorial Module Borders/Paddings
  &.pd-no__padding {
    padding-block:0;
  }
  @include appearAnimation();
}
