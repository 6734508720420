//colors
$black: rgb(0, 0, 0);
$black_secondary: rgba(0, 0, 0, 0.55);
$black_inactive: rgba(0, 0, 0, 0.35);
$genious-yellow: #ffeb00;

$white: rgb(255, 255, 255);
$white_secondary: rgba(255, 255, 255, 0.5);
$white_inactive: rgba(255, 255, 255, 0.25);

$status_error: #db3339;
$status_alert: #f1c75a;
$status_success: #2aa361;

$grey_input: #efefef;
$grey_bottom: #949494;
$grey_disabled: #fbfbfb;
$grey_line: #e4e4e4;
$grey_gradient: #f9f9f9;
$grey_loader: #e5e5e5;
$grey_interactive: #767676;
$grey_skeleton: #dddddd;
$grey_background: #e4e4e4;

$overlay: rgba(0, 0, 0, 0.5);
$overlay_base: linear-gradient(360deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);

$outline: #80bdff;
$gradient-separator: linear-gradient(180deg, #f9f9f9 0%, #ffffff 100%);
$input-outline: #333;
