.bag-tabs {
  padding-bottom: 2rem;
  .tabs {
    .gradient-wrapper {
      top: -1.2rem;
      padding-top: 2.6rem;
    }
    .react-tabs__tab {
      padding-top: $space-unit;
      padding-bottom: 1.6rem;
      white-space: pre-wrap;
    }
  }
  &__header {
    display: flex;
    padding-top: ($space-unit * 14);
    padding-bottom: ($space-unit * 4);
    justify-content: center;
    align-items: flex-end;
    background: $gradient-separator;
  }
}

.bag__unavailableticker {
  // position: absolute;
  width: 100%;
  background-color: $black;
  padding: $space-unit * 2;
  text-align: center;
  button {
    color: $white;
  }
}

.globalerrors.invalid-feedback {
  &.addressban {
    padding-top: $space-unit * 8;
    display: inline-block;
    &:before {
      display: inline-block;
    }
    a {
      text-decoration: none;
    }
  }
}

.bag-overview {
  position: relative;
  text-align: left;
  overflow: hidden;
  // margin-bottom: $space-unit * 6;
  &__products {
    padding: $space-unit * 8 0;
  }
  .bag-info-box {
    // padding: $space-unit * 2;
    margin-top: 3.2rem;
    margin-bottom: -0.8rem;
    text-align: center;
    .button-login {
      justify-content: center;
    }
  }
}

.bag-overview--empty {
  padding-top: 11.2rem;
  padding-bottom: ($space-unit * 30);
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    @extend %primary-s;
    text-align: center;
    margin-bottom: $space-unit * 4;
  }

  &__button-container {
    width: 100%;
    padding: 0 1.6rem;
  }
}

.bag-overview__sectiontitle {
  @extend %primary-m;
  margin-bottom: $space-unit * 6;
  margin-top: $space-unit * 2;
}

.remove-margin {
  margin: 0 -1.6rem;
}

.gradient-wrapper {
  position: sticky;
  top: 0;
  z-index: 2;
  background: $gradient-separator;
  .globalerrors.invalid-feedback {
    margin: 0;
    padding-top: 32px;
  }
}
