.hero-promo-video {
  height: 61.7rem;
  &__title {
    font-size: 5.4rem;
    line-height: 6.4rem;
  }
  &__description {
    font-size: 2rem;
    line-height: 2.4rem;
    // max-width: 368px;
  }
  &__cta-wrapper {
    flex-direction: row;
    .hero-promo-video {
      &__cta {
        min-width: 17rem;
        width: auto;
      }
    }
    .button {
      min-width: 17rem;
      width: auto;
      margin: 0.4rem 0.4rem;
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
}

.cs-editorial-video {
  margin: 9.6rem auto;

  &:before {
    padding-top: 56.25%;
  }
  .hero-promo-video {
    &__title {
      font-size: 4.2rem;
      line-height: 5rem;
    }
    &__tagline {
      font-size: 2rem;
      line-height: 2.4rem;
    }
    &__cta {
      font-size: 1.4rem;
    }
  }
}
