.filters__menu {
  ul {
    padding-bottom: 2rem;
    grid-auto-flow: column;
    li:nth-child(4n + 1) {
      grid-row: 1;
    }
    li:nth-child(4n + 2) {
      grid-row: 2;
    }
    li:nth-child(4n + 3) {
      grid-row: 3;
    }
    li:nth-child(4n + 4) {
      grid-row: 4;
    }
  }
}

.filters {
  display: flex;
  flex-direction: row;
  .filters__menu__menuitem:not(.disabled) {
    @include hover(0.3);
  }
  &.expanded {
    .filters__menuitem {
      &:hover {
        color: $black;
        opacity: 1;
      }
    }
  }
}

.filters__menuitem {
  @include hover(0.3);
  &--off {
    opacity: 0.55;
  }
}
