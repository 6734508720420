.product-notify {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__main {
    display: none;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-height: 90vh;

    .product-image {
      img {
        // width: auto;
        margin: 0 auto;
      }
    }
  }

  .checkbox {
    .checkbox__field {
      align-items: flex-start;
      .form-group__label {
        padding-top: 0;
      }
    }
  }

  .modal__separator {
    @include border('top');
  }
  &__thankyou,
  &__detail {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 $space-unit * 4;
  }
  &__detail {
    overflow: auto;
    max-height: none;
    &__form {
      position: relative;
      height: 100%;

      form {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
    .form-group {
      margin-bottom: 2.4rem;
    }

    .modal__product-details {
      margin-bottom: ($space-unit * 6);
      .product-details {
        &__product-name,
        &__product-color,
        &__product-size {
          padding-bottom: $space-unit * 2;
        }
        &__product-color {
          display: inline-flex;
          align-items: center;
          .swatch {
            width: 12px;
            height: 12px;
            margin-left: $space-unit;
          }
        }
      }
    }

    fieldset {
      margin-top: 0;
      margin-bottom: 2.4rem;
      &::before {
        display: none;
      }
    }
    .selector__menu--size {
      display: flex;
      align-items: flex-start;
      column-gap: 0.7rem;
      > p {
        padding: 0.5rem 0;
      }
    }
    .selector-size-expanded {
      display: flex;
      column-gap: 1.6rem;
      flex-wrap: wrap;
      margin: 0;
      .selector-size-item {
        min-width: 0;
        width: auto;
        height: auto;
        padding: 0.4rem 0.7rem;
        &.disabled {
          color: $black;
          &::after {
            display: none;
          }
        }
      }
    }
    .selector__error {
      color: $status_error;
    }

    button {
      margin-bottom: $space-unit * 4;
    }
  }

  // &__resend-prompt {
  //   margin-bottom: $space-unit * 2;
  // }

  &__thankyou {
    overflow: hidden;
  }

  &__confirm {
    margin-top: $space-unit * 6;
    > p:not(:last-of-type) {
      margin-bottom: 1.6rem;
    }
    .similar-product-container {
      padding-top: 4.8rem;
      .similar-product-carousel--cards-wrapper,
      .similar-product-carousel--controllers,
      .similar-product-carousel--controllers--label-visible,
      .similar-product-carousel--controllers-arrows {
        padding: 0;
      }
      .swiper-button-prev,
      .swiper-button-next {
        margin: 0;
      }
      .similar-product-slide {
        max-width: 19.8rem;
        .product-tile__info {
          padding: 1.6rem 0 0;
        }
      }
    }
    div.divider {
      margin-top: $space-unit * 8;
      margin-bottom: $space-unit * 4;
      @include border('top');
      height: $space-line;
    }
  }

  .modal__intro {
    margin-top: 0;
  }
  .notifyme-modal-contactus {
    padding-top: 3.2rem;
    margin-top: 3.2rem;
    border-top: 1px solid $grey_line;
    text-align: center;
    .contact-details__contacts {
      gap: 1.2rem;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: 1.6rem 0;
      &-contact {
        text-transform: uppercase;
        font-size: 1.4rem;
        > button {
          margin-bottom: 0;
          text-transform: uppercase;
        }
        a::before {
          width: 2.4rem;
          height: 2.4rem;
        }
      }
    }
    .call-country {
      display: none;
    }
  }
  .product-notify__confirm {
    .similar-product-carousel--controllers-label-visible {
      padding-bottom: 1.2rem;
    }
    .similar-product-container {
      .icon--arrow {
        &-left,
        &-right {
          width: 1.6rem;
          height: 1.6rem;
        }
      }
    }
  }
}
