.minibag {
  &__title {
    padding-bottom: 1.4rem;
    > p {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  }
  // .modal__close {
  //   padding-bottom: 1.4rem;
  // }

  &__unavailableticker {
    margin-left: -1.6rem;
    margin-right: -1.6rem;
  }
}
