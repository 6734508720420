.special-sale-hero {
  position: relative;
  height: 21rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 25%;
  background-image: url('./../../media/special-sale-header.jpg');
  &__title {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    width: 100%;
    font-size: 2.4rem;
    line-height: 2.8rem;
    letter-spacing: 0.015em;
    text-transform: uppercase;
  }
}
.special-sales {
  .review-step {
    .recap-row {
      border-bottom: none;
    }
  }
}
.special-sales {
  padding: 4rem 0;
  &__title {
    font-size: 2.2rem;
    line-height: 2.8rem;
    margin-bottom: 1.6rem;
  }

  .special-sale-form-wrapper {
    &:not(.guest) {
      margin-top: 4.8rem;
    }
    &__title {
      font-size: 1.6rem;
      margin-bottom: 1.6rem;
    }
    .button-row {
      margin-top: 3.2rem;
    }
    .skip-button {
      text-transform: inherit;
      text-decoration: underline;
      margin: 3.2rem auto;
      display: block;
    }
    .checkbox {
      margin-bottom: 0;
      input[type='checkbox']:disabled {
        color: rgba(0, 0, 0, 0.35);
      }
    }
    .employee-disclaimer {
      margin: 2.4rem 0 2.8rem;
    }
  }
  .employee-summary {
    margin-top: 5.6rem;
    &__title {
      text-transform: uppercase;
      line-height: 2rem;
      font-size: 1.6rem;
      margin-bottom: 1.2rem;
    }
    &__add-guest {
      margin-top: 2.4rem;
      text-align: center;
      border-top: 1px solid $grey_line;
      padding-top: 4rem;

      h3 {
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: bold;
        margin-bottom: 2.4rem;
      }
    }
  }
  .recap-row {
    border-bottom: 0.1rem solid $grey_line;
    padding: 1.6rem 0;
    line-height: 2rem;
    margin-bottom: 1.6rem;
    &:nth-child(7) {
      border-bottom: none;
    }
    // &:not(.edit-mode) {
    //   color: $black_secondary;
    // }
    &.customer-row {
      margin: 4.8rem 0 3.2rem 0;
      &:not(.edit-mode) {
        border-top: 0.1rem solid $grey_line;
      }
      .special-sale-form-wrapper {
        margin-top: 0;
      }
    }
    &__title {
      font-size: 1.6rem;
      margin-bottom: 1.2rem;
    }
  }
  .globalerrors.invalid-feedback {
    display: block;
    padding-left: 22px;
    position: relative;
    &:before {
      position: absolute;
      left: 0;
      top: 0.3rem;
    }
    > div {
      margin-bottom: 10px;
    }
  }
  .general-condition {
    padding-top: 1.6rem;
    .is-invalid {
      color: $status_error;
      input {
        color: $status_error;
        border-color: $status_error;
      }
      .invalid-feedback {
        display: none;
      }
    }
  }
}

.modal.remove-guest-modal {
  min-width: auto;
  .dialog__description {
    width: 100%;
  }
  .button--primary {
    margin-bottom: 1.2rem;
  }
}

.guests-summary {
  margin-top: 4rem;
  border-top: 1px solid $grey_line;
  &__title {
    margin: 4rem 0 1.2rem 0;
    text-transform: uppercase;
    font-size: 2rem;
    line-height: 2.4rem;
  }
  p {
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin-bottom: 1.6rem;
  }
  .recap-row {
    border-bottom: none;
  }
  .employee-summary__add-guest {
    border-top: 1px solid $grey_line;
    padding-top: 4rem;
    margin-top: 0;
  }
}
