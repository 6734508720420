.account-appointments {
  .countryselector {
    margin-top: 3.2rem;
  }
  .wrapper-info {
    .container-info-profile {
      flex-direction: column;
      row-gap: 0.8rem;
    }
  }
  .option-store__live-boutique {
    .live-boutique-info {
      .tooltip__box {
        left: 55%;
        transform: translateX(-45%);
        bottom: calc(100% + (0.4rem * 2));
        right: auto;
        &:before {
          left: 45%;
        }
      }
    }
  }
}
