.myaccount-hero {
  height: 17.6rem;

  &.legal-area {
    margin-bottom: 0;
  }
}

.hero-column__inner-container {
  padding: ($space-unit * 8);
}

.hero__main-title {
  width: 76%;
}

.hero {
  .hero-column__img {
    video {
      transform: scale(1.05);
    }
  }

  &.rpy {
    min-height: 280px !important;
  }

  .hero-column__title {
    &.primary-xxxl {
      font-size: 3.2rem;
      line-height: 3.8rem;
    }
  }

  .hero-column__headline {
    &.spacing {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.hero-skinny {
  height: 28rem;
}
