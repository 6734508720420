.myfeed-containerImg {
  aspect-ratio: 343/195;
}
.myfeed_carousel-controls {
  display: none;
}

.my-feed-exclusive-products-wrapper {
  .themed-products__title {
    padding-inline: 16px;
  }

  .tabs.tabs--products {
    .react-tabs__tab-panel {
      &--selected {
        min-height: 170vw;
      }
    }
  }

  .tabs {
    .react-tabs__tab-list {
      overflow-x: auto;
      overflow-y: hidden;
      width: 100%;
      justify-content: flex-start;
      padding-inline: 16px;

      /* chrome */
      &::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

.account-dashboard {
  .experience-region {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.myfeed-container {
  .benefits-section {
    padding: 40px 16px;
  }

  .hero-carousel__main-wrapper,
  .swiper-container {
    aspect-ratio: 2/3;
  }
}
