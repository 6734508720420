@use 'sass:math';

@each $multiply in $spacing-multiplier {
  $space: $space-unit * $multiply;
  $space-nounit: math.div($space, 0.1rem);
  // margin top
  .mt-#{$space-nounit} {
    margin-top: $space;
  }
  // margin right
  .mr-#{$space-nounit} {
    margin-right: $space;
  }
  // margin bottom
  .mb-#{$space-nounit} {
    margin-bottom: $space;
  }
  // margin left
  .ml-#{$space-nounit} {
    margin-left: $space;
  }
  // padding bottom
  .pb-#{$space-nounit} {
    padding-bottom: $space;
  }
  // padding top
  .pt-#{$space-nounit} {
    padding-top: $space;
  }
  // min-height
  .min-#{$space-nounit} {
    min-height: $space;
  }
}

.d-none {
  display: none;
}
.d-block {
  display: block;
}
.d-flex {
  display: flex;
}
.d-flex-align-center {
  display: flex;
  align-items: center;
}

@each $name, $br in $grid-breakpoints {
  @include breakpoint($name, min) {
    .d-#{$name}-none {
      display: none !important;
    }
    .d-#{$name}-block {
      display: block !important;
    }
    .d-#{$name}-flex {
      display: flex !important;
    }
    .d-#{$name}-inline-flex {
      display: inline-flex !important;
    }
  }
}

.hidden {
  // note that this is hidden to screen reader
  visibility: hidden;
}

.link {
  text-decoration: underline;
}

.block {
  display: block;
}

img[sizes='auto'],
img[data-sizes='auto'] {
  display: block;
  width: 100%;
}

.lazyload,
.lazyloading {
  :not(.product-tile__swatch-change) & {
    opacity: 1;
  }
  opacity: 0;
}
.lazyloaded {
  opacity: 1;
  // transition: opacity 300ms;
}

.link-button {
  text-decoration: underline;
}

.disclaimer-single-byte-jp {
  display: block;
  margin-top: $space-unit;
}
.text-center {
  text-align: center;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.error-message {
  margin-top: $space-unit;
  font-weight: 600;
  // color: $status_error;
}

.info-box {
  border: 1px solid $grey_line;
  padding: 1.2rem;
  width: 100%;
  margin-top: 2.4rem;
  margin-bottom: 0.8rem;
  > b {
    display: block;
    margin-bottom: $space-unit * 2;
  }
}

.image-background--half,
.image-background--whole {
  --gradient-angle: 0deg;
}

.image-background--half {
  &:after {
    content: '';
    width: 100%;
    height: 50%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    pointer-events: none;
  }
}
.image-background--whole {
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    pointer-events: none;
  }
}
.image-background--top {
  --gradient-angle: 180deg;
  &:after {
    content: '';
    width: 100%;
    height: 50%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    pointer-events: none;
  }
}
.image-background--bottom {
  --gradient-angle: 180deg;
  &:after {
    content: '';
    width: 100%;
    height: 50%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    pointer-events: none;
  }
}

.image-background--bright {
  &:after {
    background: linear-gradient(var(--gradient-angle), rgba(63, 63, 63, 0.4) 0%, rgba(63, 63, 63, 0) 100%);
  }
}
.image-background--darker {
  &:after {
    background: linear-gradient(var(--gradient-angle), rgba(63, 63, 63, 0.7) 0%, rgba(63, 63, 63, 0) 100%);
  }
  &-inverted {
    &:after {
      background: linear-gradient(var(--gradient-angle), rgba(63, 63, 63, 0) 0%, rgba(63, 63, 63, 0.7) 100%);
    }
  }
}

.text-shadow-active {
  //text-shadow: 0 0 2.5rem $black_inactive;
  text-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.2);

  .button--arrow-right[class*='inverted']::after {
    filter: drop-shadow(0 0 1px $black);
  }
}

.bold {
  font-weight: bold;
}

.button-underline {
  display: block;
  margin-top: $space-unit;
  font-weight: bold;
  text-decoration: underline;
  outline-offset: 2px;
  text-underline-offset: 2px;
}

.text-disclaimer {
  @extend %primary-xxs;
  margin-bottom: 1.6rem;
  a {
    font-weight: bold;
  }
}
.bag-disclaimer {
  font-weight: bold;
  margin-top: 1.2rem;
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-align: left;
  > * {
    text-align: left;
  }
}

.border-top {
  border-top: 1px solid $grey_line;
}
.border-bottom {
  border-bottom: 1px solid $grey_line;
}

.disabled-text {
  color: $black_secondary;
}

.no-overflow-container {
  overflow: hidden;
}

.no-outline {
  outline: none;
  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: none;
  }
}

.invert {
  filter: invert(1);
}
//   .image-background--radial {
//     .promo-column__img-holder {
//       &::before {
//         position: relative;
//         z-index: 1;
//         background: radial-gradient(50% 50% at 50% 50%, rgba(63, 63, 63, 0.25) 0%, rgba(63, 63, 63, 0) 100%);
//       }
//     }
//     &::before {
//       position: relative;
//       z-index: 2;
//       background: radial-gradient(50% 50% at 50% 50%, rgba(63, 63, 63, 0.25) 0%, rgba(63, 63, 63, 0) 100%);
//     }
//   }

//   .image-background--linear {
//     .promo-column__img-holder {
//       &::before {
//         position: relative;
//         z-index: 1;
//         background: linear-gradient(0deg, rgba(63, 63, 63, 0.25) 0%, rgba(63, 63, 63, 0.25) 0.01%, rgba(63, 63, 63, 0) 100%);
//       }
//     }
//     &::before {
//       position: relative;
//       z-index: 2;
//       background: linear-gradient(0deg, rgba(63, 63, 63, 0.25) 0%, rgba(63, 63, 63, 0.25) 0.01%, rgba(63, 63, 63, 0) 100%);
//     }
//   }
// }

@include breakpoint(md) {
  .row-reverse-mobile {
    flex-direction: column-reverse;
    row-gap: 1.2rem;
    [class^='col-'] {
      display: flex;
      justify-content: center;
    }
  }
}
