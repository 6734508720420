[data-whatintent='keyboard'] {
  header {
    .wrapper-submenu,
    .menu-actions .icon--menu {
      transition: none;
    }
  }
}
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition-property: transform;
  transition-duration: 0.4s;
  transition-timing-function: $easeOutQuart;
  @include z(header);
  // background: #fff;
  + main {
    transition: padding-top 0.2s ease-out;
    padding-top: $header-height-desktop;
    background-position-y: $header-height-desktop;
  }

  [data-react='minicart-app'] {
    .tmploader {
      display: flex;
    }
  }

  ul {
    @include reset-list;
  }

  &.video-full-screen {
    transform: translateY(-150%);
    pointer-events: none;
  }

  &.header--transparent {
    // background: transparent;
    &:hover,
    &:focus-within {
      .navigation {
        &.clothing-header {
          background-color: transparent;
        }
      }
    }
    .navigation {
      &.clothing-header {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s, opacity 0.3s linear;
        &.height__zero {
          height: 0;
        }
      }
      background-color: transparent;
    }
    &.scrolled {
      box-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.1);
      .navigation {
        background-color: $white;
        &.clothing-header {
          visibility: hidden;
          opacity: 0;
          height: 0;
        }
      }
      .menu-heading {
        color: $black;
      }
      .menu-actions {
        [class^='icon'] {
          filter: invert(0);
        }
        // [data-wishlist-count]::after {
        //   filter: invert(0);
        // }
      }
    }

    body:not(.no-overflow) &-white {
      @extend .header--transparent;

      &.header-gradient {
        &-min,
        &-mid,
        &-max {
          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 40rem;
            transform: translateY(-65%);
            pointer-events: none;
            transition: opacity 0.3s ease-in-out;
            background: linear-gradient(
              360deg,
              transparent,
              rgba(0, 0, 0, 0.196) 10.27%,
              rgba(0, 0, 0, 0.294) 18.73%,
              rgba(0, 0, 0, 0.588) 29%,
              rgba(0, 0, 0, 0.686) 37.89%,
              rgba(0, 0, 0, 0.686) 54.72%,
              rgba(0, 0, 0, 0.686) 64.05%,
              rgba(0, 0, 0, 0.686) 76.74%
            );
          }
          &.scrolled,
          &:hover {
            &::before {
              opacity: 0;
            }
          }
        }
        &-min {
          &::before {
            opacity: 33%;
          }
        }
        &-mid {
          &::before {
            opacity: 66%;
          }
        }
        &-max {
          &::before {
            opacity: 100%;
          }
        }
      }

      .menu-heading {
        color: $white;
      }
      .menu-actions {
        [class^='icon'] {
          filter: invert(1);
        }
        // [data-wishlist-count]::after {
        //   filter: invert(1);
        // }
      }
    }
    &-black {
      @extend .header--transparent;
    }
  }
  &.checkout {
    position: relative;
    + main {
      background: $white;
      padding-top: ($space-unit * 8);
      min-height: calc(var(--viewport-height) - #{$header-height-desktop});
      border-bottom: initial;
      z-index: initial;
    }
    .navigation {
      h1 {
        @extend %primary-m-u;
        position: absolute;
        bottom: 1%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  // &.overlay {
  //   .icon--search {
  //     display: none;
  //   }
  // }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(100vh + 90px);
    background: $overlay;
    @include z(neg);
    @include animate(0.3);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  .navigation {
    background-color: $white;
    height: $header-height-desktop;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include animate(0.3);
  }

  .back-button {
    display: none;
  }

  .logo-header {
    align-self: flex-start;
    display: flex;
    width: ($space-unit * 30);
    margin-top: $space-unit * 2;
    @include z(logo);
    @include animate;
    a {
      display: block;
    }
    img {
      display: block;
      width: 83px; // ok to be in pixels
    }
  }
  #mainnav {
    max-width: 75%;
    height: 100%;
  }

  .menu-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-heading,
  .submenu-heading {
    &,
    a {
      display: block;
      @include del-link;
    }
  }
  a.menu-heading {
    display: none;
  }

  nav {
    // height: inherit;
    sup {
      vertical-align: super;
      font-size: 0.9rem;
      display: contents;
    }
  }

  .menu-heading {
    // padding: 2.2rem ($space-unit * 2) 1.8rem;
    padding: 1.8rem ($space-unit * 2);
    text-transform: capitalize;
    @include animate(0.3);
    &,
    a {
      @extend %primary-s;
    }
    &:focus {
      @include focus-underline;
    }
    &.is-live {
      padding-left: 1.6rem;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: 4px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background-color: $status_error;
        z-index: 1;
      }
      &::after {
        content: '';
        position: absolute;
        left: 2px;
        width: 8px;
        height: 8px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        border: 1px solid $status_error;
        animation: pulse 0.8s infinite alternate ease-out;
      }
    }
  }

  .submenu-heading {
    margin-bottom: ($space-unit * 4);

    &,
    a {
      text-transform: uppercase;
      @extend %primary-xs-u;
      &:focus {
        @include focus-underline;
      }
    }
  }

  .submenu-group {
    break-inside: avoid;
    padding: 0;
    padding-bottom: ($space-unit * 8);
    ul li {
      margin-bottom: $space-unit;

      &:last-child {
        margin-bottom: 0;
      }
    }
    // &:last-of-type {
    //   padding-bottom: ($space-unit * 8) + 1;
    // }
  }

  .submenu-item {
    @extend %primary-s;
    @include del-link;
    // @include hover(0.3);
    .launch-date {
      display: block;
      @extend %primary-xs;
      color: $black_secondary;
      padding-bottom: $space-unit;
      padding-left: 1.2rem;
      font-weight: normal;
    }

    &.submenu-item-empty {
      color: red;
    }
  }

  .wrapper-submenu {
    position: absolute;
    top: $header-height-desktop;
    width: 100%;
    left: 0;
    transform: translateY(-3.2rem);
    opacity: 0;
    visibility: hidden;
    @include animate(0.3);
    @include z(neg);
    overflow: hidden;
    pointer-events: none;
    background-color: $white;
    &#collections-navdialog,
    &#mbmmodule-navdialog {
      @include animate(0.2);
    }
  }

  .collection-submenu,
  .visual-submenu,
  .container-submenu {
    display: flex;
    justify-content: center;
  }

  .collection-submenu {
    flex-direction: column;
    justify-content: flex-start;
  }
  .banners-submenu {
    display: grid;
    .experience-menu_banner {
      aspect-ratio: 16 / 9;
      padding: 1.6rem 1.6rem 0;
      &:last-child {
        padding-bottom: 1.6rem;
      }
    }
    .menu-assets {
      display: block;
      margin: 0;
      height: 100%;
      width: 100%;
      max-width: none;
      object-fit: cover;
      .banner_text {
        justify-content: end;
        padding-bottom: 2.2rem;
        .banner_title {
          padding: 0;
        }
        .button-wrapper {
          margin-top: 1.8rem;
        }
      }
    }
  }

  .container-submenu {
    padding: ($space-unit * 16) ($space-unit * 7) ($space-unit * 4);
    @include gray-gradient-pseudo;
  }
  .menu-assets {
    overflow: hidden;
    position: relative;
    display: none;
    margin-bottom: ($space-unit * 4);
    margin-left: ($space-unit * 8);

    align-self: flex-start;
    text-decoration: none;
    max-width: 43.8rem;
    .banner_text {
      @extend %primary-xxs-u;
      color: $white;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      text-align: center;
      top: 0;
      // text color for title and description on menu banner [OE-17804]
      &.text-color {
        &--black {
          color: $black;
        }
        &--white {
          color: $white;
        }
      }
      .banner_title {
        text-transform: uppercase;
        // padding-bottom: 1.4rem;
        z-index: 1;
        font-size: 2.4rem;
        line-height: 2.8rem;
        letter-spacing: 0.015em;
        &.BlockGothicRR {
          font-family: 'BlockGothicRR';
          font-weight: bold;
          font-size: 7.5rem;
          line-height: 5.8rem;
        }
      }
      &.banner-lowercase {
        p {
          text-transform: lowercase;
          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
      p {
        z-index: 1;
      }
      > img {
        margin-bottom: 1rem;
      }
    }
    .button {
      display: flex;
      // color: $white;
      position: absolute;
      bottom: ($space-unit * 3);
      left: 50%;
      transform: translateX(-50%);
      @include z;
      span {
        margin-top: -0.1rem;
      }
    }
    &.curators-banner {
      .banner_text {
        font-family: $silkSerifLight;
        font-size: 61px;
        line-height: 64px;
        letter-spacing: -0.02em;
      }
      &::before {
        content: '';
        @include fixedSprite('curators-white');
        position: absolute;
        top: 2.4rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        pointer-events: none;
      }
    }
    &.mbm-banner {
      width: 100%;
      background: linear-gradient(182.33deg, #000000 1.74%, #616161 109.2%);
      &::before {
        content: '';
        display: block;
        width: 100%;
        padding-top: 64%;
      }
      > img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        object-fit: cover;
      }
      .banner_text {
        top: 0;
        z-index: 2;
        background: radial-gradient(50% 50% at 50% 50%, rgba(63, 63, 63, 0.25) 0%, rgba(63, 63, 63, 0) 100%);
        i {
          height: 6.4rem;
          margin-bottom: 1.2rem;
          width: 70%;
          max-width: 260px;
        }
      }
      .button-wrapper {
        display: flex;
        column-gap: ($space-unit * 2);
        white-space: nowrap;
        &.split-button {
          flex-direction: column;
          width: 100%;
          .button {
            min-width: 20rem;
          }
        }
        .button {
          position: relative;
          bottom: initial;
          left: initial;
          transform: initial;
        }
      }
    }
    &.double-link {
      width: 100%;
      .banner_text {
        justify-content: flex-end;
        padding-bottom: 2.4rem;
        br {
          display: none;
        }
      }
      .button-wrapper {
        display: flex;
        column-gap: ($space-unit * 2);
        row-gap: ($space-unit * 2);
        white-space: nowrap;
        margin-top: 1.6rem;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        &.split-button {
          flex-direction: column;
          width: 100%;
          .button {
            min-width: 20rem;
          }
        }
        .button {
          position: relative;
          bottom: initial;
          left: initial;
          transform: initial;
          width: auto;
          &.text {
            &-white {
              color: $white;
            }

            &-black {
              color: $black;
            }
          }
        }
      }
    }
    &.text-black {
      .banner_text,
      .banner_title,
      .button {
        color: $black;
      }
      &.curators-banner {
        &::before {
          @include fixedSprite('curators');
        }
      }
    }
    .banner-image {
      display: block;
    }
    .menu-countdown {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: $white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &__label {
        text-shadow: 0 0 0.5rem rgba(15, 15, 15, 0.15);
        margin-bottom: $space-unit;
        text-transform: uppercase;
      }
      &__title {
        font-family: BlockGothicRR, Impact, sans-serif;
        font-weight: bold;
        $mobile-font-data: map-get($genius-mobile, 's');
        font-size: nth($mobile-font-data, 1);
        line-height: nth($mobile-font-data, 2);
        letter-spacing: nth($mobile-font-data, 3);
        text-transform: uppercase;
      }
    }
  }

  .main-submenu,
  .highlights-submenu {
    list-style: none;
    padding: 0;
  }

  .visual-submenu__item {
    flex: 1 0 25%;
    display: block;
    max-width: 25%;
  }

  .collection-submenu__item {
    display: flex;
    flex: 1;
  }

  .wishlist-icon-animated {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 2.4rem;
    height: 2.4rem;
    opacity: 0;
    // transition: all ease-out 100ms;
    // transform: translateY(100%);
    span {
      // position: absolute;
      // right: 0;
      font-size: 12px;
      // transform: translateX(50%);
    }
  }

  .account-menu {
    display: flex;
  }

  .menu-actions {
    display: flex;
    align-items: center;

    .newsletter-flag {
      column-gap: initial;
      .checkbox {
        flex-basis: 50%;
      }
    }

    > .icon--client-service {
      display: none;
    }

    .account-wrapper {
      .account-cta {
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        background-color: transparent;
        overflow: hidden;
        .account-cta__label {
          @extend %primary-s;
          margin-left: 0.2rem;
        }
      }

      .account-logout {
        @extend %primary-s;
        display: none;
      }
      &__item {
        display: flex;
      }
    }

    // > div {
    //   display: flex;
    // }

    .icon--bag {
      display: flex;
      position: relative;

      span {
        @extend %primary-xxs;
        position: absolute;
        left: 100%;
        top: 0.7rem;
      }
    }
    .account-disclosure {
      display: flex;
      align-items: center;
    }
    [data-react='wishlist-button'] {
      max-height: 24px;
    }
    [data-react='search-app'] {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0); //Prevents weird greybox on safari OE-29035
    }
  }

  &.search-scrolled {
    .logo-header {
      opacity: 0;
      visibility: hidden;
    }
    // .search-container .search-overlay {
    //   padding-top: 0;
    //   .no-results-container,
    //   .refine {
    //     padding-top: 0;
    //   }
    //   @include z(logo);
    // }
  }
  &.bag-scrolled {
    transform: translateY(-101%);
    .logo-header {
      opacity: 0;
      visibility: hidden;
    }
  }

  .contextual-nav {
    position: fixed;
    top: $header-height-mobile;
    left: 0;
    width: 100%;
    height: $contextual-navigation-m;
    background: $white;
    border-top: 1px solid $grey_line;
    border-bottom: 1px solid $grey_line;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1.6rem;
    padding-bottom: 0.8rem;
    z-index: -2;
  }
  &.hide-on-scroll {
    transition-duration: 0.8s;
    .navigation {
      @include animate(0.5);
      &:hover {
        @include animate(0);
      }
    }
    &:not(.video-full-screen)[data-scroll='up'] {
      transform: translateY(0);
      .logo-header {
        opacity: 1;
        visibility: visible;
      }
    }
    &[data-scroll='down'] {
      transform: translateY(-100%);
      .logo-header {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}

body {
  &.overlay {
    header {
      @include z(headerOpen);
    }
  }
  &.checkout.overlay {
    header {
      z-index: 3;
    }
  }
  &.is-fixed {
    position: fixed;
    height: 100%;
    width: 100%;
    overflow: auto;
  }

  .header--transparent-white,
  .header--transparent-black {
    + main {
      padding-top: 0;
    }
  }
}

body.has-ticker {
  main {
    padding-top: $header-height-mobile + $ticker-bar-m;
    background-position-y: $header-height-mobile + $ticker-bar-m;
  }
  .header--transparent + main,
  .header--transparent-white + main {
    padding-top: $ticker-bar-m;
    background-position-y: $ticker-bar-m;
  }
  .contextual-nav {
    top: ($header-height-mobile + $ticker-bar-m);
  }
}

@keyframes pulse {
  from {
    transform: translateY(-50%) scale(0.6);
  }

  to {
    transform: translateY(-50%) scale(1.2);
  }
}

body[id^='account'],
body[id^='order'] {
  .header--transparent-black {
    background-color: $white;
  }
}

body.black-page {
  background-color: $black;
  overflow-x: hidden;
  overscroll-behavior: none;

  header.scrolled,
  header.scrolled.header--transparent-white {
    .navigation {
      background-color: $black;
      .menu-wrapper-list {
        .menu-item {
          a,
          button {
            color: $white;
          }
        }
      }
      .menu-actions {
        [class^='icon'] {
          filter: invert(1);
        }
      }
      &:hover {
        [data-whatinput='touch'] & {
          background-color: $black;
        }
        background-color: $white;
        .menu-wrapper-list {
          .menu-item {
            a,
            button {
              color: $black;
            }
          }
        }
        .menu-actions {
          [class^='icon'] {
            filter: invert(0);
          }
        }
      }
    }
  }
  main {
    background-color: $black;
    border-bottom: 1px solid $white_inactive;

    .button--tertiary-inverted {
      @include breakpoint(md, max) {
        font-size: 1.4rem !important;
      }
    }
    button {
      outline-color: $white;
    }
  }
  header + main {
    padding-top: 0;
  }
  footer {
    .service-bar {
      background-color: $black;
      border-bottom: 1px solid $white_inactive;
      .service-bar__service {
        img {
          filter: invert(1);
        }
        span {
          color: $white;
        }
      }
      &::after {
        background: $black;
      }
      .btn-playpause {
        filter: invert(0);
      }
    }
    .footer-newsletter {
      border-bottom: 1px solid $white_inactive;
      .newsletter-container {
        .form-group {
          .form-group__label {
            color: $white;
          }
          input {
            border-bottom: 1px solid $white_inactive;
            color: $white;
            &::placeholder {
              color: $white_secondary;
            }
            // on autocomplete chrome background color is black
            &:focus {
              background-color: $black;
            }
          }
        }
        button {
          i {
            filter: invert(1);
          }
        }
      }
    }
    .footer-menu {
      color: $white;
      .footer-menu__section {
        .footer-menu__section__menuitem {
          a,
          button {
            color: $white;
            img,
            &::before {
              filter: invert(1);
            }
          }
        }
      }
      .accordion__group {
        .footer-accordion__item-header {
          color: $white;
          i {
            filter: invert(1);
          }
        }
        .footer-accordion__item.accordion__item:not(:first-child) {
          border-top: 1px solid $white_inactive;
        }
        .footer-accordion__item:last-child {
          border-bottom: 1px solid $white_inactive !important;
        }
      }
    }
    .footer-countryselector {
      .footer-menu__menuitem {
        color: $white;
        @include breakpoint(md, max) {
          border-bottom: 1px solid $white_inactive;
        }
      }
      .footer-menu__menuitem__current {
        color: $white;
        @include breakpoint(md, max) {
          border-bottom: 0;
        }
      }
    }
    .color-contrast-switch {
      button {
        color: rgba(white, 0.55);
        &.active {
          color: $white;
          border-bottom: 1px solid $white;
        }
      }
    }
    .social-legals {
      .social-wrapper {
        a {
          i {
            filter: invert(1);
          }
        }
      }
      .copyright {
        background-color: $black;
        color: $white_secondary;
        p {
          text-align: center;
          button {
            color: $white_secondary;
          }
        }
      }
      .version {
        color: $white_secondary;
      }
    }
  }
}
