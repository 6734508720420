.lookbook-component {
  .gallery-thumbs {
    position: relative;
    display: block;
    margin-top: $space-unit;
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      width: 0.2rem;
      background: $white;
      z-index: 3;
      pointer-events: none;
    }
  }
}
