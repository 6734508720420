.sub-headline {
  &--long {
    .sub-headline__inner-container {
      width: 32.7;
    }
  }
}

.centered-text-block {
  &__inner-container {
    width: 83.33%;
  }
}

.two-column-text-block {
  &__inner-container {
    width: 100%;
  }
}

.text-column {
  &__inner-container {
    width: 66.67%;
  }
}

.text-assets {
  padding: ($space-unit * 16) 2rem;
}
