.curator-music-player {
  display: flex;
  width: 100%;
  .curator-music-player-img,
  .curator-music-player-img-dx {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .curator-music-player-img-dx {
    position: relative;
    .music-player {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      padding: 0 6rem;
    }
  }
  .img-mobile {
    display: none;
  }
}
