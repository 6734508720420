[data-whatintent='touch'] {
  header {
    .wrapper-submenu {
      transition: none;
    }
  }
}
header {
  button.menu-heading {
    display: none;
  }
  a.menu-heading {
    display: inline-block;
  }
  &.header--transparent {
    &.no-transition {
      .navigation {
        transition: all 0.1s 0s cubic-bezier(0.25, 1, 0.5, 1);
      }
      .menu-heading {
        transition: all 0.1s 0s cubic-bezier(0.25, 1, 0.5, 1);
      }
      .menu-actions {
        [class^='icon'] {
          transition: all 0.1s 0s cubic-bezier(0.25, 1, 0.5, 1);
        }
        // [data-wishlist-count]::after {
        //   transition: all 0.1s 0s cubic-bezier(0.25, 1, 0.5, 1);
        // }
      }
    }
    &.delay-transition {
      .navigation {
        transition-delay: 0.3s;
      }
      .menu-heading {
        transition-delay: 0.3s;
      }
      .menu-actions {
        [class^='icon'] {
          transition-delay: 0.3s;
        }
        // [data-wishlist-count]::after {
        //   transition-delay: 0.3s;
        // }
      }
    }
    .overlay & {
      .menu-heading {
        color: $black;
      }
    }
    &:hover,
    &:focus-within {
      .navigation {
        background-color: $white;
      }
      .menu-heading {
        color: $black;
      }
      .menu-actions {
        [class^='icon'] {
          filter: invert(0);
        }
        // [data-wishlist-count]::after {
        //   filter: invert(0);
        // }
      }
    }
  }
  .submenu-item {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
  .menu-wrapper,
  .menu-wrapper-list {
    height: inherit;
    &:focus-within {
      .menu-item:not(:focus-within) {
        .menu-heading {
          color: $black_secondary;
        }
      }
    }

    &.expanded {
      .menu-item:not(:hover) {
        .menu-heading {
          color: $black_secondary;
        }
      }
    }
  }
  .menu-wrapper-list {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu-heading {
    height: inherit;
    display: flex;
    align-items: center;
  }

  .menu-item {
    height: inherit;
    &.open {
      .wrapper-submenu {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        pointer-events: all;
        z-index: 1;
        // & > * {
        //   opacity: 1;
        // }
      }
    }
  }

  .menu-actions {
    // .account-wrapper {
    //   .account-cta {
    //     &.small-only {
    //       display: none;
    //     }
    //   }
    // }
    > .icon--client-service {
      display: block;
    }
    .icon--client-service,
    .account-wrapper,
    [data-react='search-app'] {
      margin-right: ($space-unit * 2);
    }

    .account-cta {
      // &.with-wishlisticon .icon--account {
      //   position: relative;
      //   &::after {
      //     content: '';
      //     @include sprite('heart-filled-black');
      //     width: 1rem;
      //     height: 1rem;
      //     display: inline-block;
      //     position: absolute;
      //     top: -2px;
      //     right: -2px;
      //   }
      // }
      .account-cta__label {
        @include visually-hidden;
      }
    }
  }
  .submenu-group,
  .submenu-column {
    ul li {
      margin-bottom: ($space-unit * 3);
    }
    &.coming-soon {
      .submenu-heading {
        font-size: 1.2rem;
        line-height: 1.6rem;
      }
    }
  }
  .banner-wrapper {
    flex-basis: 25%;
    max-width: 25%;
    margin-left: auto;
    &.wide {
      flex-basis: 33.3333%;
      max-width: 33.3333%;
    }
  }

  .wrapper-submenu.has-banner {
    .container-submenu {
      align-items: flex-start;

      &.with-3-items {
        .banner-wrapper {
          margin-left: 11.5rem;
          flex: 2;
        }
      }

      .banner-wrapper {
        position: relative;
        flex: 1;
        aspect-ratio: 16/9;
        > div {
          height: 100%;
          .menu-assets.promo-image {
            max-width: 100%;
            width: 100%;
            height: 100%;
            margin-left: 0;
            margin-bottom: 0;
            img {
              object-fit: cover;
              position: absolute;
              top: 0;
              right: 0;
              width: 100%;
              height: 100%;
              object-position: center;
            }
          }
          .button-wrapper {
            div,
            a {
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
  .container-submenu {
    max-height: calc(var(--viewport-height) - 112px);
    padding-top: 5.8rem;
    padding-bottom: 2.8rem;
    overflow-y: auto;
    // justify-content: flex-start;
    column-gap: 0.4rem;
    .main-submenu,
    .main-submenu-wrapper {
      .submenu-group,
      .submenu-column {
        width: 16.7rem;
      }
    }

    .highlights-submenu {
      width: 16.7rem;
    }
    // calculation of columns to use in the grid layout
    .main-submenu {
      @for $i from 2 through 4 {
        &.with-#{$i}-items {
          display: grid;
          flex: 3;
          grid-template-columns: repeat($i, 1fr);
          gap: 1.6rem;
        }
      }
    }

    // with highlights-submenu column
    .main-submenu-wrapper {
      display: flex;
      flex: 3;
      .highlights-submenu {
        // management highlight column margin right to align it with the main submenu column gap
        margin-right: clamp(1.6rem, 2vw, 5rem);
        padding-right: 0.8rem;
      }
      // if there is the highlight column, the main submenu can have max 3 columns (max four columns total)
      .main-submenu {
        @for $i from 1 through 3 {
          &.with-#{$i}-items {
            display: grid;
            flex: 3;
            grid-template-columns: repeat($i, 1fr);
            gap: 1.6rem;
          }
        }
      }
    }

    &.with-2-items {
      .main-submenu,
      .main-submenu-wrapper {
        display: flex;
        flex: 2;
        justify-content: center;
      }
    }
    &.with-3-items {
      .main-submenu,
      .main-submenu-wrapper {
        flex: 2;
      }
    }

    .banner-wrapper {
      .button-wrapper {
        div,
        a {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .submenu-group,
    .highlights-submenu {
      a:hover {
        text-decoration: underline;
      }
      a.submenu-heading {
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .submenu-item {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .submenu-group {
    padding-bottom: 4rem;
  }
  .submenu-column {
    > .submenu-heading {
      margin-bottom: 2.8rem;
    }
    .main-submenu h4.submenu-heading {
      &,
      a {
        font-size: 1.2rem;
      }
    }
  }

  .collection-card-tab {
    display: none;
  }

  .contextual-nav {
    top: $header-height-desktop;
  }
  .menu-assets {
    display: block;
    width: 20.4rem;
    .landscape-asset & {
      width: 37rem;
    }
    &.promo-image {
      img {
        height: 100%;
      }
      .button {
        width: 100%;
        span {
          margin-top: 0;
        }
      }
    }
  }

  @keyframes animateWishlistIcon {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    60% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(100%);
      opacity: 0;
    }
  }

  @keyframes animateAccountIcon {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    60% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .account-menu {
    &.animate-wishlist-add {
      overflow: hidden;
      .wishlist-icon-animated {
        animation: animateWishlistIcon 1s ease-in forwards;
      }
      .account-cta {
        animation: animateAccountIcon 1s ease-in forwards;
      }
    }
  }

  .banners-submenu {
    .menu-assets {
      .banner_text {
        .button-wrapper {
          a {
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

.collection-submenu {
  &__item {
    .collection-card {
      span {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

body.has-ticker {
  main {
    padding-top: $header-height-desktop + $ticker-bar;
    background-position-y: $header-height-desktop + $ticker-bar;
  }
  .header--transparent + main {
    padding-top: $ticker-bar;
    background-position-y: $ticker-bar;
  }
  .contextual-nav {
    top: ($header-height-desktop + $ticker-bar);
  }
}
