.themed-products {
  &__image {
    height: 33rem;
  }

  &__select {
    margin: 0 auto 2.4rem auto;
    width: 34rem;
  }
  .button {
    width: auto;
    min-width: 14rem;
    display: inline-flex;
    margin: 0 0.8rem;
  }
  &__carousel-wrapper {
    &:before {
      padding-top: 46.5%;
    }
    &:after {
      padding-top: 46.5%;
    }
  }
  &__navigation {
    padding-top: 46.5%;
  }
  .product-tile {
    .product-tile__name {
      max-width: 17rem;
    }
  }
}
