.intro-plp {
  flex-direction: column;
  position: relative;

  &.results-row.image-bg {
    min-height: 42.4rem;
    background: transparent;
    .breadcrumb,
    .intro-plp__title {
      filter: invert(1);
    }
    .plp-heading {
      justify-content: center;
    }
    .plp-heading__collection-logo {
      height: 6rem;
      width: auto;
      margin-bottom: 2.4rem;
    }
    .intro-plp__breadcrumbs {
      transform: translateX(-1.2rem);
    }
    .breadcrumb {
      justify-content: center;
    }
    .intro-plp__description,
    .intro-plp__read-more-container {
      color: $white;
    }
    .intro-plp__text-tags-wrapper {
      .intro-plp__text-container {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        max-width: none;

        .intro-plp__title {
          @extend %primary-xxl;
          margin: 0.4rem 0 0;
          text-align: center;
        }
        .intro-plp__description {
          display: flex;
          max-width: 50rem;
          text-align: center;
        }
        .intro-plp__read-more-container {
          display: none;
        }
      }
      .intro-plp__tags-container {
        display: none;
      }
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 4.8rem);
    object-fit: cover;
    z-index: -1;
    pointer-events: none;
    filter: brightness(58%);
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__logo-link {
    margin-right: ($space-unit);
  }

  &__text-container {
    margin-bottom: ($space-unit * 3);
  }

  &__title {
    @extend %primary-m-u;
    font-size: 2.4rem;
    margin: ($space-unit * 5) 0 ($space-unit * 6);
  }
  &__description {
    @extend %primary-s;
    color: $grey_bottom;
    display: inline;
    margin: ($space-unit * 3) 0;
    p {
      display: inline;
      & + p {
        display: block;
      }
    }
  }
  &__read-more-container {
    display: inline;
    color: $grey_bottom;
    p {
      display: inline;
      & + p {
        display: block;
      }
    }
    .intro-plp {
      &__read-more-text {
        @extend %primary-s;
        display: inline;
      }
      &__read-more-btn {
        margin-left: 0.4rem;
        color: inherit;
        @extend %primary-s;
        display: none;
        text-decoration: underline;
      }
    }
  }
  &__text-tags-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: ($space-unit * 6);

    width: 100%;
    flex-direction: column;
  }
  &__tags-container {
    display: flex;

    &::after {
      content: '';
      position: absolute;
      width: 2rem;
      top: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(270deg, #ffffff 0%, rgb(255 255 255 / 0%) 100%);
    }
  }
  &__tags-list {
    flex-wrap: nowrap;
    white-space: nowrap;
    justify-content: normal;
    list-style-type: none;
    padding: 0;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .intro-plp {
      &__tag__item {
        display: inline-block;
      }
    }
    .tag__link {
      text-decoration: none;
      display: block;
      padding: ($space-unit * 2) ($space-unit * 3);
    }
  }
  &__tag__item {
    @extend %primary-xxs-u;
    // padding: ($space-unit * 2) ($space-unit * 3);
    padding: 0;
    border: 1px solid #e4e4e4;
    margin: ($space-unit) ($space-unit * 2);
    &:first-child {
      margin-left: 0;
    }
  }
  .breadcrumb {
    align-items: center;
    flex: none;
  }
  .breadcrumb__item {
    @extend %primary-xxs;
  }

  .plp-heading {
    width: 100%;
  }

  .wrapper-refine-button {
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 5.2rem;
      bottom: 0;
      left: 0;
      background-color: $grey_gradient;
    }
  }
}
