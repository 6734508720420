// SIZE SELECTOR USED IN: PDP, PLP Tile, Reserve in boutique, Shop by Look

// shop the look PDP
.product-look__tile {
  .product-tile__info.single-slide__labels.lookinfo {
    .selector-wrapper {
      .selector__menu {
        &__item {
          &:hover:not(.selected) {
            .selector__menu__item__label {
              &::after {
                transform: translateX(-50%) scaleX(1);
              }
            }
          }
          &__label {
            @extend %primary-s-u;
            position: relative;
            display: inline-block;
            &::after {
              content: '';
              height: 0.1rem;
              bottom: 0;
              background-color: $grey_line;
              width: 100%;
              min-width: 1.6rem;
              @include center('horizontal');
              @include animate(0.3);
              visibility: visible;
              transform: translateX(-50%) scaleX(0);
            }
          }
          &.selected {
            .selector__menu__item__label {
              &::after {
                background-color: $black;
                transform: translateX(-50%) scaleX(1);
              }
            }
          }
          &.is-disabled {
            &::after {
              background-color: $black;
            }
          }
          &:focus-visible {
            outline: 1px solid $black;
          }
        }
      }
    }
  }
}

.selector-size {
  display: flex;
  align-items: flex-start;
  position: relative;
  width: 100%;
  @include border('top');
  color: $black;
  height: $selector-size-inpage-height-desktop;
  @include animate(0.5, height);
  margin-bottom: $space-unit;

  .selector__button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.8rem 0;
    @include animate(0.5, 'opacity'); // alert: do not change, it may affect focus management

    & > span {
      @extend %primary-s-u;
    }

    &.expanded {
      opacity: 0;
      visibility: hidden;

      + .selector__menu {
        opacity: 1;
        visibility: visible;
      }
    }

    &:disabled {
      opacity: 1;
      color: inherit;
      cursor: inherit;
      &::after {
        display: none;
      }
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      @include fixedSprite('chevron-right');
      // @include center('vertical');
    }

    .errormessage {
      display: block;
      text-transform: none;
      position: absolute;
      right: 2rem;
      color: $status_error;
    }

    .selector__button__value {
      margin-left: ($space-unit * 4);
      text-transform: capitalize;
    }

    &__value--outofstock {
      color: $black_secondary;
    }

    &__info {
      margin-left: ($space-unit * 2);
      color: $black_secondary;
      text-transform: initial;
    }

    &.show-format {
      &::after {
        content: initial;
      }
    }
  }

  .selector__menu {
    display: flex;
    flex-direction: column;
    // align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: initial;
    left: 0;
    padding: 0.8rem 0; // pixel perfect ehm
    opacity: 0;
    visibility: hidden;
    @include animate(0.5, 'opacity'); // alert: do not change, it may affect focus management

    .label {
      @extend %primary-xs-u;
      padding-top: 0.4rem;
      padding-bottom: 1.6rem;
    }

    .selector-wrapper {
      @include reset-list;
      display: flex;
      flex-wrap: wrap;
      bottom: initial;
      min-width: 100%;
      row-gap: $selector-size-inpage-rowgap-desktop;
      column-gap: 2rem;
      padding-bottom: 1.2rem;
    }

    &__item {
      & > span {
        @extend %primary-xs-u;
      }

      &.is-disabled {
        color: $black_secondary;
      }

      &:hover {
        // ONE-23809
        text-decoration: underline;
      }

      &.is-disabled {
        color: $white;
        position: relative;
        // line stroke is-disabled
        &::after {
          content: '';
          position: absolute;
          width: 2.2rem;
          height: 0.1rem;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
          background-color: $white;
        }
      }

      &:focus {
        @include touchtarget-focus();
      }
    }
  }

  //INVERTED VARIANT

  &.inverted {
    border-color: $white_inactive;

    .selector__button {
      .selector__button__value--outofstock,
      .selector__button__info {
        color: $white_secondary;
      }
      color: $white;
      &::after {
        filter: invert(1);
      }
    }

    .selector__menu {
      background-color: $black;
    }

    .selector__menu__item {
      color: $white;

      &.is-disabled {
        color: $white_secondary;
      }

      &[aria-pressed='true'] {
        .swatch {
          outline-color: $white_inactive;
        }
      }

      &:focus {
        @include touchtarget-focus($outline-color-inverted);
      }
    }
  }

  //INTILE VARIANT

  &.intile {
    height: $selector-size-intile-height;
    .selector__menu {
      padding: $selector-size-intile-padding;
      ul {
        row-gap: $selector-size-intile-rowgap;
        grid-template-columns: repeat($selector-size-intile-cols, 1fr);
      }
    }
  }
}

.bag-product__details .selector-size-inline .selector__menu:focus-visible {
  // Custom outline because otherwise would be overlapped with size underline or cut by some overflow
  &::before {
    content: '';
    display: block;
    pointer-events: none;
    border: 0.1rem solid $black;
    position: absolute;
    top: -0.5rem;
    bottom: -0.5rem;
    left: -0.2rem;
    right: 0;
  }
}

// RADIO SIZE SELECTOR

.selector__item__radio {
  input[type='radio'] {
    @include hidden-input();
    & + label {
      cursor: pointer;
    }
  }
  [data-whatintent='keyboard'] & {
    &:focus-within {
      outline: $outline-width solid $outline-color;
      outline-offset: 0.4rem;
    }
  }
}
