.monclernow-hero {
  &.portal {
    &::before {
      padding-top: 46.93%;
    }
  }
  &--text-before-img {
    .monclernow-hero__text {
      padding-top: 4rem;
    }
  }
  &__text {
    bottom: $space-unit * 10;
  }
}
