.video-module.simplified.genius {
  &:not(.no-padding) {
    padding: 1.6rem 0;
  }
  &:not(.no-padding):not(.full-screen) {
    .video-module__inner-container {
      width: 83.333333333333%; // 10:12
    }
  }
  &.not-padding {
    .video-module__inner-container {
      width: 100%;
    }
  }
  .video-module__inner-container {
    transition: width 0.3s ease-in;
    margin: 0 auto;
  }
}
