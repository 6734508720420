.themed-products {
  position: relative;

  &__title {
    margin-top: 2.4rem;
    margin-bottom: 3.2rem;
  }
  &__inner-container {
    padding: 3.2rem 0;
  }
  &__image-column {
    padding: 0;
    position: absolute;
    width: 50%;
    height: 100%;
    left: 0;
    top: 0;
  }
  &__select {
    margin-bottom: 8rem;
  }
  &__image {
    max-height: none;
    height: 100%;
  }

  &__carousel-wrapper {
    &:after {
      left: 0;
      padding-top: 46.5%;
    }
    &:before {
      right: 0;
      padding-top: 46.5%;
    }
  }
  &__navigation {
    padding-top: 46.5%;
  }
  &__ctas-wrapper {
    margin-top: 6rem;
  }
}

.component-themed-products {
  .my-feed-exclusive-products-wrapper {
    .themed-products {
      aspect-ratio: 1440/595;
      .themed-products__inner-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        padding: 0;
        .themed-products__title,
        .themed-products__select,
        .themed-products__ctas-wrapper {
          margin: 0 auto;
        }
      }
      .themed-products__image-column {
        aspect-ratio: 718/595;

        .themed-products__image {
          width: 100%;
          aspect-ratio: 718/595;
        }
      }
    }
  }
  .themed-products {
    aspect-ratio: 1440/810;

    .themed-products__image-column {
      aspect-ratio: 720/810;

      .themed-products__image {
        width: 100%;
        aspect-ratio: 720/810;
      }
    }

    .themed-products__inner-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-evenly;
      padding: 4.1rem 0;

      .themed-products__title,
      .themed-products__ctas-wrapper {
        margin: 0 auto;
      }

      .themed-products__select {
        margin-top: 0;
        margin-bottom: 5.6rem;
      }
    }
  }
}
