.component_product_key_technical_details {
  $viewportHeight: calc(var(--viewport-height) - #{$contextual-navigation});
  // margin-bottom: $viewportHeight;
  .product-key-technical-details-hero {
    padding-top: 7rem;
    .key-details-container {
      margin-top: 7rem;
    }
  }
  .grenoble-details.two-rows {
    justify-content: space-between;
    .grenoble-details__item {
      width: auto;
    }
  }
  .key-step {
    height: $viewportHeight;
  }
}
