.menu-mobile-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.4rem;
  min-height: 19.3rem;
  text-align: center;
  margin-top: 2.6rem;
  background-position: center;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(63, 63, 63, 0) 0%, rgba(63, 63, 63, 0.7) 100%);
  }
  &__title {
    color: $white;
    margin-bottom: 2.4rem;
    position: relative;
    font-size: 18px;
  }
  &__cta {
    &--button,
    &--text {
      font-size: 16px !important;
    }
    &--button {
      margin-bottom: 1.4rem;
      position: relative;
    }
  }
}
