.curator-video {
  .video-container {
    height: 8.2rem;
    .time-text {
      font-family: $silkSerifLight;
      font-weight: 300;
      font-size: 150px;
      line-height: 128px;
      position: absolute;
      left: 16%;
      color: white;
      z-index: 3;
    }
  }
  .video-module__inner-container {
    height: 55vw;
    .video-wrapper {
      opacity: 1;
      pointer-events: visible !important;
      .th-button {
        .th-svg-container {
          pointer-events: visible !important;
        }
      }
      .th-controls {
        .th-control-bar {
          .th-group {
            .th-button-color {
              &:hover {
                color: white;
                fill: white;
              }
            }
            .th-time-rail {
              background-color: transparent;
              pointer-events: visible !important;
              .th-slider-bg-color {
                background-color: $grey_loader;
                stroke: $grey_loader;
                .th-time-current {
                  background-color: white;
                  stroke: white;
                }
              }
            }
            .th-hd-button {
              display: none;
            }
            .th-speed-button {
              display: none;
            }
            .th-downloadable-button {
              display: none;
            }
            .th-time-info-text {
              display: none;
            }
          }
        }
      }
    }
    .th-control-bg-color {
      background-color: transparent;
    }
  }
}
