.size-selection {
  &.modal {
    height: auto;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
    max-width: none;
    padding: 0 0 4rem;
    overflow: hidden;
    .modal__header {
      padding: 0 2rem;
      .dialog__title {
        height: auto;
        padding: 1.4rem 0 0.2rem;
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
    .size-selector-line {
      padding: 1.1rem 0 1rem;
    }
    .size-selection {
      --scroll-top: 0;
    }
    #size-selector-container {
      padding: 0 2rem;
      position: relative;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      &::before {
        content: '';
        display: block;
        width: 0.2rem;
        height: var(--scrollbar-height);
        background: $black;
        position: absolute;
        top: var(--scroll-top);
        right: 0;
      }
      &.hide__scrollbar::before {
        display: none;
      }
    }
  }
}
