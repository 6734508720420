.refine {
  top: $header-height-desktop - 0.1rem;
  .results-row {
    padding-top: 4rem;
    padding-bottom: 2.2rem;
    height: auto;
  }
  .refine__options {
    overflow: visible;
  }
  .refine__container {
    align-items: flex-start;
    // background: $gradient-separator;
    // background-size: 100% $refine-options-height;
    // background-repeat: no-repeat;
    background-color: $grey_gradient;
    // background-position-y: -$refine-options-height;
    overflow: visible;
    @include animate(0.3);
    transform: translateZ(0);
    &:hover,
    &:focus-within {
      background-position-y: 0;
    }
  }
  .search-maincategories {
    fieldset label {
      padding: 0 2rem 0.9rem;
    }
  }
}

body.has-ticker {
  .refine {
    top: $header-height-desktop - 0.1rem + $ticker-bar;
  }
}
