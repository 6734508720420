.editorial-multi-text__text-column {
  width: 100%;
  padding: ($space-unit * 10) ($space-unit * 4);
  &.on-top {
    display: none;
  }
  &.at-top {
    padding-bottom: 0;
  }
  &.mbm-promo {
    padding-top: 2.4rem;
    padding-bottom: 8.4rem;
    i {
      width: 16rem;
      height: 3.6rem;
      margin-bottom: 4.4rem;
      position: relative;
      left: initial;
      transform: initial;
      top: initial;
    }
    .editorial-multi-text__title {
      font-size: 32px;
      line-height: 38px;
    }
  }
}

.editorial-multi-text {
  flex-direction: column;
  .editorial-multi-text__img-column {
    &.on-image {
      position: relative;
    }
  }
  &.editorial-multi-text--inverted-mobile {
    flex-direction: column-reverse;
  }
}

.editorial-multi-text__img-column {
  width: 100%;
  .editorial-multi-text__head {
    position: absolute;
    z-index: 1;
    color: $white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    text-align: center;
  }
  .editorial-multi-text__title {
    padding-bottom: 0;
  }
}

.editorial-multi-text__inner-container {
  width: 48.5rem;
  margin: 0 auto;
  max-width: 100%;
}

.square-banner {
  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 100%;
  }
}

.cta-position-- {
  &on-top,
  &on-image {
    display: block;
    margin-top: 2rem;
  }
}

.title-position-- {
  &on-top,
  &on-image {
    display: none;
  }
}

.editorial-multi-text__title-on-top {
  display: block;
  padding-top: 4rem;
  text-align: center;
}

.editorial-multi-text__title-on-image {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
}
