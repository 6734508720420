.product-mbm-notify {
  padding: 4rem 3.2rem;
  .mbm-finish__buttons {
    flex-direction: row;
    .button {
      flex: 1 1 50%;
      min-width: 230px;
    }
  }
}
