.my-feed-exclusive-products-wrapper {
  .tabs {
    .react-tabs__tab-panel {
      .themed-products {
        &__carousel-wrapper{
          .product-tile{
            &__info{
              .product-tile__name {
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                white-space: normal;
              }
            }
          }
        }
      }
    }
  }

  &.my-feed-exclusive-products-wrapper__prod_btn__bottom {
    @media (max-width: 1400px) {
      .tabs {
        .react-tabs__tab-panel {
          .themed-products {
            &__carousel-wrapper{
              .product-tile{
                &__info{
                  .product-tile__quick-buy {
                    bottom: 15px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}