.grenoble-details {
  display: flex;
  flex-direction: column;
  gap: 3.6rem;

  &_characteristics {
    width: 100%;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding: 0;
    list-style: none;
    margin-top: 2rem;
    gap: 1.6rem 1.2rem;

    @include breakpoint(sm, min) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint(md, min) {
      grid-template-columns: repeat(1, 1fr);
    }

    @include breakpoint(lg, min) {
      grid-template-columns: repeat(2, 1fr);
    }

    &.materials {
      .icon-wrapper {
        width: 9.7rem;
        display: flex;
        justify-content: center;
        [class*='icon--'] {
          width: 9.7rem;
          height: 4rem;
          background-position: top left;
        }
        .icon--grenoble-dyneema {
          background-size: cover;
          height: 4rem;
          background-position: left;
        }
        .icon--grenoble-polartec {
          width: 6rem;
          height: 4rem;
        }
      }
    }
    &.activity {
      .grenoble-details__list__item {
        align-items: center;
      }
    }

    &__item {
      // flex-basis: 50%;
      display: flex;
      gap: 1.2rem;
      [class*='icon--'] {
        width: 4rem;
        height: 4rem;
      }
      .text-wrap {
        flex: 1;
        padding-right: 1.2rem;
        word-break: break-word;
        .title {
          font-size: 1.4rem;
          font-weight: 300;
          margin-bottom: 0.4rem;
        }
        .description {
          font-size: 1.4rem;
          font-weight: 300;
          color: $black_secondary;
        }
      }
    }
  }
}
