.row-info__column {
  width: 100%;
}
.bag-product__image {
  max-width: 12.2rem;
}

.myaccount-wrapper {
  .orders-listing {
    .bag-product__content {
      max-width: calc(100% - 13.6rem);
      flex-basis: calc(100% - 13.8rem);
    }
  }
}

$boxPadding: 40px 20px;

.order-history-info-box,
.order-history-info-listing {
  padding: $boxPadding;
}

.orders-listing__order-detail{
  flex-direction: column;
}

.order-detail__column {
  &-sm{
    width: 100%;
  }

  &-lg{
    width: 100%;
  }

  &-vertical{    display: flex;
    flex-direction: column;
    justify-content: center;

    &-sm{
      width: 100%;
    }
  }

  &-map{
    width: 100%;
  }
}

.order-history-multiship{
  flex-direction: column;
  justify-content: center;

  &-group{
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    margin-left: 0;
    text-align: center;
    width: 100% !important;
    max-width: 100% !important;
  }
}

.order-history-section{

  &-listing-title{
    margin: 0;
  }

  &-listing-subtitle{
    margin-left: 0;
  }

  &-map{
    justify-content: center;
  }
}
