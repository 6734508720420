.editorial-page-title {
  @extend %primary-l;
  text-align: center;
  text-transform: uppercase;
  padding: 5.6rem ($space-unit * 4) ($space-unit * 4);
  background: $gradient-separator;
  @include appearAnimation(0.4, 0.15);
}

.text-color--black {
  .promo-column__title {
    color: #000;
  }
}

.new-hover-animations {
  img {
    transform: scale(1);
    @include animate(1);
  }
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}
