.search-container {
  .results-row {
    align-items: flex-end;
    padding-bottom: 1.2rem;
  }
  .search-overlay-close {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .search-overlay {
    top: $header-height-desktop;
    z-index: -1;
    &.stick-top {
      top: 0;
      z-index: 2;
    }
    &.minicart-overlay {
      &:before {
        content: '';
        position: fixed;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100vh;
        z-index: 3;
      }
    }
    .row {
      justify-content: center;
    }
  }
  input[type='search'] {
    padding: $space-unit 0;
  }
  .reset-button {
    padding: 0 12px;
    border-right: 1px solid $grey_line;
    margin-right: 8px;
  }
  .search-suggestions {
    display: flex;
    justify-content: center;
    gap: 2.4rem;
    li {
      padding-top: 0.8rem;
    }
  }
  .search-icons {
    top: 0.2rem;
  }
  .stick-top .search-input-container {
    top: -8rem;
  }
  .suggested-container {
    .center-text {
      text-align: center;
    }
  }
  .text-content-noresults {
    padding: 2.4rem 1.6rem 3.2rem;
  }
  .no-result-content {
    .tabs [role='tab'] {
      padding: 0.9rem 2rem;
    }
    .collection-submenu {
      padding: 0 2.8rem;
      .collection-submenu__item {
        &:before {
          padding-bottom: 133.333%; // Aspect ratio 3:4
        }
      }
      .collection-card {
        .collection-card__inner-content {
          padding-bottom: 4.1rem;
          .collection-card__title {
            padding-bottom: 2.4rem;
          }
          .collection-card__description {
            display: block;
            height: 4rem;
          }
          img {
            margin-bottom: 4.4rem;
          }
          .button {
            margin-top: 4.2rem;
          }
        }
      }
    }
    .container {
      .swiper-initialized {
        padding: 0 2.8rem;
      }
    }
  }
}

body.has-ticker .search-container .search-overlay {
  // top: $ticker-bar;
  top: calc(#{$header-height-desktop} + #{$ticker-bar});
  &.stick-top {
    top: 0;
  }
}
