.account-dashboard {
  p {
    @extend %primary-xs;
  }
  .new-registration {
    padding-top: ($space-unit * 16);
    padding-bottom: ($space-unit * 9);
    &__title {
      @extend %primary-m-u;
      margin-bottom: ($space-unit * 6);
    }
    &__text-wrapper {
      margin-bottom: ($space-unit * 8);
    }
  }
  .subtitle_clothing {
    text-align: center;
    padding-top: 4rem;
    padding-bottom: 1.2rem;
  }
  .image-wrapper {
    padding-top: ($space-unit * 7);
  }
  .image-box {
    a {
      display: block;
      position: relative;
      @include hover(0.2);

      &:focus {
        outline: none;
        .image-box__title {
          @include focus-underline;
        }
      }
    }
    &__title {
      @extend %primary-xxl;
      color: $white;
      text-align: center;
      text-transform: uppercase;
      width: 80%;
      max-height: 85%;
      overflow: hidden;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      // @include text-shorten;
      @include z;
    }
    &__image {
      width: 100%;
    }
  }
  .link-section {
    padding: ($space-unit * 16) 0;
  }
  .link-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    &__title {
      @extend %primary-l-u;
      margin-bottom: ($space-unit * 4);
    }
    &__desc {
      padding: 0 5%;
      margin-bottom: ($space-unit * 4);
    }
    &__button.button {
      width: auto;
      padding-left: 4.2rem;
      padding-right: 4.2rem;
    }
  }
}
