.footer {
  &.checkout {
    .wrapper-footer {
      grid-template-columns: 100%;
      column-gap: 0;
      row-gap: 0;
    }
    .footer-item {
      padding-bottom: ($space-unit * 10);
      &:nth-child(1),
      &:nth-child(3) {
        height: initial;
      }
      &:nth-child(2) {
        grid-column-start: initial;
        grid-row-start: initial;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
  &.clothing-footer {
    &::before {
      content: none;
    }
    .social-legals {
      .social-wrapper {
        margin-left: 3px;
        width: 100%;
      }
    }
  }
}
