.wishlist {
  .wishlist-login-box {
    padding-left: 16px;
    padding-right: 16px;

    .wrapper-buttons {
      padding-left: 16px;
      padding-right: 16px;

      button {
        max-width: 100%;
        width: 100%;
      }
    }
  }
  .wishlist-wrapper {
    margin-left: -0.2rem;
    margin-right: -0.2rem;
  }
  .selector-size {
    height: auto;
    min-height: 3.6rem;
  }
  // &__single-product {
  //   max-width: calc(50% - #{$space-unit / 2});
  // }
}
