.shop-by-look-module {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100vw;
  overflow: hidden;
  height: auto;
  background-color: $black;
  // z-index: 0;
  @include animate;
  .close-shopbylook {
    position: absolute;
    top: ($space-unit * 3);
    right: ($space-unit * 6);
    filter: invert(1);
    @include z;
  }
  .look-products {
    color: $white;
  }
  .preview-image {
    display: flex;
    align-items: center;
    .product-image {
      height: auto;
      img {
        height: 100%;
      }
    }
  }
  .product-selection {
    padding-top: 0;
    background: transparent;
    height: auto;
    // .product-selection__heading {
    //   padding-top: 0;
    // }
    .product-selection__description {
      display: -webkit-box;
      line-height: 1.6rem;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      min-height: ($space-unit * 12);
    }
    .product-selection__ctas {
      margin-bottom: ($space-unit * 6);
    }
    .button--arrow-right::after {
      filter: invert(1);
    }
  }
}
.products-selector {
  padding-top: ($space-unit * 6);
  display: flex;
  margin: 0 - ($space-unit * 0.5);
  overflow: auto;
  &--carousel {
    padding-top: ($space-unit * 6);
    width: 100%;
    &.right-overlay::after {
      content: '';
      width: 60px;
      height: 100%;
      position: absolute;
      z-index: 1;
      right: 0;
      top: 0;
      background: linear-gradient(270deg, #000000 0.87%, rgba(0, 0, 0, 0) 99.12%);
      pointer-events: none;
    }
    &.left-overlay::before {
      content: '';
      width: 60px;
      height: 100%;
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      background: linear-gradient(90deg, #000000 0.87%, rgba(0, 0, 0, 0) 99.12%);
      pointer-events: none;
    }
  }
  .products-selector__slide {
    width: calc(20% - #{$space-unit});
    margin: 0 ($space-unit * 0.5);
    flex-shrink: 0;
  }
}
.products-selector__slide {
  width: 100%;
  padding-bottom: ($space-unit * 2);
  border-bottom: $space-line solid $white_inactive;
  @include animate(0.2);
  &:focus {
    outline: 1px solid $white;
  }
  &.selected {
    border-bottom-color: $white;
  }
}
