.order-detail-page{
  $boxPadding: 40px 60px;

  .bag-product{
    padding: $boxPadding;
  }

  .product-wrapper{

    .detail-shipment{
      padding: $boxPadding;
    }

    .bag-product{
      padding: $boxPadding;
    }
  }

  .price-wrapper,
  .total-wrapper{
    padding: 32px 60px; //$boxPadding;
  }
}
