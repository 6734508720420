.discovery-layout {
  .text-region {
    padding: 8rem 0;
  }
  .gallery-region {
    .picture-wrapper {
      height: -webkit-fit-content;

      &:focus-visible {
        position: relative;
        outline: none;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          box-shadow: inset 0 0 0 4px white;
        }
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          box-shadow: inset 0 0 0 2px black;
        }
      }
    }
    .actions-bar {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 2.8rem;
      gap: 0.8rem;
      button {
        opacity: 42%;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0;
          height: 1px;
          background-color: $black;
          transition: all 0.2s ease-in-out;
        }

        &.active {
          opacity: 1;

          &::after {
            width: 100%;
          }
        }
      }
    }
    .gallery-section {
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
      &.loading {
        opacity: 0;
      }
      &.fluid {
        display: flex;
        flex-direction: column;
        & > * {
          padding-bottom: 8rem;
          &:last-child {
            padding-bottom: 0;
            &.container {
              padding-bottom: 8rem;
            }
          }
        }
      }
      &.grid {
        display: flex;
        flex-direction: column;
        // flex-wrap: wrap;
        // gap: 1px;
        padding-bottom: 2rem;

        .grid-section {
          display: grid;
          gap: 1px;
          grid-template-columns: repeat(4, calc(100% / 4 - 3px / 4));
          .grid-tile {
            aspect-ratio: 4 / 5;
            height: -webkit-fit-content;
            animation: skeleton 0.7s ease-out infinite alternate;

            &:has(.lazyloaded) {
              animation: none;
            }

            img {
              object-fit: cover;
              height: 100%;
              width: 100%;
            }

            & > * {
              height: 100%;
            }
          }
        }
      }

      .load-more-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .load-more-button {
          margin: 2rem 0;
          width: auto;
        }
      }
    }
  }
}

.intro-flp-discovery {
  .row {
    display: flex;
    justify-content: center;
    flex-direction: row;
    .content {
      display: flex;
      flex-direction: column;
      gap: 2.8rem;
      .title {
        text-transform: uppercase;
        text-align: center;
      }
      p {
        text-align: center;
      }
    }
  }
}

.discover-image-wrapper {
  .picture-wrapper {
    animation: skeleton 0.7s ease-out infinite alternate;
    &:has(.lazyloaded) {
      animation: none;
    }
  }
  // theme
  &.dark {
    background-color: $black;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  // VARIATIONS - LAYOUT FOR MOBILE
  &.single {
    .picture-wrapper {
      display: flex;
      width: 100%;
      aspect-ratio: 4 / 5;
      object-fit: cover;
      picture {
        width: 100%;
      }
    }
    &.squared {
      .picture-wrapper {
        aspect-ratio: 9 / 16;
      }
    }
  }

  &.double {
    display: flex;
    flex-direction: row;
    &.container {
      gap: 0.8rem;
    }
    &.column {
      flex-direction: column;
    }
    & > * {
      flex-basis: 100%;
    }
    .picture-wrapper {
      aspect-ratio: 4 / 5;
    }
  }

  &.triple {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content;

    .picture-wrapper:nth-child(1) {
      grid-column-start: 1;
      grid-column-end: 3;
      aspect-ratio: 4 / 5;
    }
    .picture-wrapper:nth-child(2),
    .picture-wrapper:nth-child(3) {
      img {
        aspect-ratio: 4 / 5;
      }
    }

    &.horizontal {
      .picture-wrapper:nth-child(1) {
        aspect-ratio: 1 / 1;
      }
    }
  }

  &.quad {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content;
    gap: 0.8rem;

    .picture-wrapper {
      aspect-ratio: 4 / 5;
    }
  }
}
