// container
.radio {
  display: flex;
  min-height: 2.4rem;
  align-items: center;
  // &:not(:last-child) {
  //   margin-bottom: ($space-unit * 7);
  // }
  break-inside: avoid-column;
  position: relative;
  cursor: pointer;

  .inner-label {
    @extend %primary-xs;
    display: flex;
    padding-left: 1.4rem;
    position: relative;
    width: 100%;

    &::first-letter {
      text-transform: uppercase;
    }
    &::before {
      // focus visible
      content: '';
      display: inline-block;
      box-shadow: 0 0 0 $outline-width transparent;
      position: absolute;
      width: $outline-radio-size;
      height: $outline-radio-size;
      left: -1.9rem;
      top: -0.5rem;
      @include animate;
      border-radius: 100%;
      z-index: 0;
    }
  }

  input[type='radio'] {
    @include radio();
    top: 0;
    position: relative;

    &:disabled,
    &:disabled + .inner-label {
      opacity: 0.55;
      cursor: initial;
    }

    [data-whatintent='keyboard'] & {
      &:focus {
        outline-offset: 0.2rem;
      }
    }
  }

  &__icon {
    @extend %primary-s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    row-gap: ($space-unit * 2);

    [class^='icon']:not(:last-child) {
      margin-right: ($space-unit * 2);
    }

    > span {
      display: flex;
    }
  }

  &--outline {
    &:not(:last-child) {
      margin-bottom: ($space-unit * 2);
    }
    .inner-label {
      border: $space-line solid $black_inactive;
      padding: ($space-unit * 4);
      padding-left: 4rem;
      align-items: baseline;
      &::before {
        content: initial;
      }
    }
    input[type='radio'] {
      position: absolute;
      top: 2rem;
      left: 1.4rem;
      &:checked + .inner-label {
        border: $space-line solid $black;
      }
    }
  }
}

.is-invalid {
  .radio {
    color: $status_error;
  }
}
