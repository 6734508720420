.plp-heading {
  > p {
    margin-bottom: 0;
  }

  &__category-title,
  &__subcategory-title {
    font-size: 2.4rem;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    margin-top: $space-unit * 2;
    @include animate(0.3);
    &.hide {
      opacity: 0;
      transition-duration: 0s;
      transform: translateY(0.25em);
    }
  }
}
