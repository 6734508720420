.wishlist {
  &__single-product {
    .swiper-container .swiper-pagination-bullets {
      --bullets-bottom-offset: 3.2rem;
    }
  }
  &__share-button {
    right: 3.2rem;
  }
}
