.monclernow-filter {
  &__row {
    position: relative;
    overflow: hidden;
    height: 56px;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      @include border('bottom');
      background: transparent;
      z-index: 1;
      width: 100%;
      height: 1rem;
    }
  }

  &__date-filter {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    @include animate(0.4);
    z-index: 2;
  }

  &__second-level {
    overflow: auto hidden;
  }

  &__first-level {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto hidden;
    white-space: nowrap;
    &::before,
    &::after {
      content: '';
      display: inline-block;
      width: 0.1rem;
      height: 1rem;
      min-width: 0.1rem;
    }
  }

  &--active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}

.first-level {
  &__filter-item {
    padding: 2rem 0;
    margin: 0 1.6rem;
    text-decoration: none;
    background: none;
    cursor: pointer;
    color: #000;
    position: relative;
    z-index: 2;
    // text-transform: capitalize;
    &:nth-child(1) {
      text-transform: none;
    }

    &.filter-item {
      &--active {
        &::before {
          display: block;
        }
      }
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 1px;
      left: 0;
      right: 0;
      width: 100%;
      height: $space-line;
      background: #000;
      display: none;
    }
    &:focus {
      outline: none;
      // @include focus-underline;
    }
  }
}

.moncler-filter__result {
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    margin: 0 0.8rem;
  }
  .result__clear-button {
    color: $black_secondary;
  }
}
