.modal {
  &.shop-the-look-modal {
    min-width: 100%;
    .container-shop-the-look {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 4rem;
      background-color: $black;
      max-width: 60rem;
    }
    .modal__close {
      top: 7.2rem;
      right: 3.2rem;
    }
  }
  &.modal-padding-remove {
    .container-shop-the-look {
      padding: 4rem 0;
    }
  }
}
.curator-image-shop-the-look-container {
  .container-shop-the-look {
    .button-shop-the-look-open {
      top: 3.2rem;
      right: 3.2rem;
      span {
        display: block;
      }
      &::after {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }
}
