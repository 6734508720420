.filters {
  width: 100%;
  .filters__menu__menuitem {
    input {
      & + label {
        white-space: normal;
        align-items: center;
      }
    }
  }
}

.filters__menu {
  position: relative;
  padding: 0;
  display: none;
  top: auto;
  &__menuitem {
    &:hover {
      opacity: 1;
    }
  }
  .filters__menu__wrapper-group {
    column-gap: 3.2rem;
  }
  &--eCommerceCategory,
  &--microTaxonomy,
  &--refinementColor {
    ul {
      column-gap: 3.2rem;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}

.filters__menuitem {
  @include border('bottom');
  height: 4.2rem;
  padding: 1.4rem 0 1.2rem;
  margin: 0;
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  &:hover {
    opacity: 1;
  }
  &::after {
    @include fixedSprite('plus');
  }

  &[aria-expanded='true'] {
    border-bottom-color: transparent;
    + .filters__menu {
      @include border('bottom');
      display: block;
    }
    &::after {
      @include fixedSprite('minus');
      transform: translateY(-50%) rotate(0);
    }
  }
}
