// COLOR SELECTOR USED IN: PDP, Reserve in boutique

.selector-color-expanded {
  .selector__menu {
    &__item {
      margin-right: $swatch-margin-mobile;
      .swatch {
        width: $swatch-size-mobile;
        height: $swatch-size-mobile;
        margin-right: 0;
        @include touchtarget-area($target-size-mobile);
        &::after {
          width: $swatch-size-mobile;
        }
      }
    }
  }
}
