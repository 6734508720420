.interactive-cards {
  .scrollable-selector-swiper {
    .swiper-slide {
      .scrollable-selector {
        font-size: 4.2rem;
      }
    }
  }
  &__container {
    padding: 11.4rem;
  }
  .cards-swiper {
    .swiper-slide {
      width: 45.6rem;
    }
  }
}
