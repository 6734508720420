.product-slide__img {
  height: 33.7rem;
}
.product-highlight__text-holder {
  padding: 4.6rem ($space-unit * 4) ($space-unit * 8);
}

.product-highlight__cta {
  margin-bottom: ($space-unit * 8);
}

.img-holder--external-border {
  img {
    padding: ($space-unit * 7);
  }
}
