// COLOR SELECTOR USED IN: PDP, Reserve in boutique

.selector-color-expanded {
  position: relative;
  @include border('top');
  color: $black;
  .selector__menu__item {
    padding: 0.5rem;
    margin-right: 1rem;
    input {
      -webkit-appearance: initial;
      margin: initial;
      border: initial;
      outline: none;
      width: 0;
      height: 0;
    }
    [data-whatintent='keyboard'] & {
      &:focus-within {
        outline: 1px solid;
      }
    }
  }

  .selector__label {
    display: flex;
    align-items: center;
    padding: 1.1rem 0 0;

    &__name {
      @extend %primary-xs-u;
      margin-right: ($space-unit * 4);
      display: inline-block;
    }

    &__value {
      @extend %primary-xs;
      display: inline-block;
    }

    .errormessage {
      display: block;
      text-transform: none;
      position: absolute;
      right: 2rem;
      color: $status_error;
    }

    .selector__button__info {
      margin-left: ($space-unit * 2);
      opacity: 0.5;
      text-transform: initial;
    }
  }

  .selector__menu {
    display: flex;
    align-items: center;
    padding: 1.4rem 0 1.7rem;

    .label {
      @extend .visually-hidden;
    }

    ul {
      @include reset-list;
      display: flex;

      li:first-child {
        margin-left: 0.1rem;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      margin-right: 1rem;
      &:hover:not(.selected) {
        .swatch {
          &::after {
            transform: translateX(-50%) scaleX(1);
          }
        }
      }

      &__label {
        @extend .visually-hidden;
      }

      .swatch {
        margin-right: 0;
        @include touchtarget-area();
        &::after {
          content: '';
          height: 0.1rem;
          bottom: -0.5rem;
          background-color: $grey_line;
          width: $swatch-size-desktop;
          @include center('horizontal');
          @include animate(0.3);
          transform: translateX(-50%) scaleX(0);
        }
      }

      &.is-disabled {
        position: relative;
        .stroke {
          content: '';
          position: absolute;
          width: 3.2rem;
          height: 0.1rem;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
          background-color: $white;
          mix-blend-mode: difference;
        }
      }

      // &.is-disabled:not(.selected) {
      //   .swatch--blue,
      //   .swatch--black {
      //     & + .stroke {
      //       background-color: $white;
      //     }
      //   }
      // }

      &:focus {
        outline: none;

        .swatch {
          @include touchtarget-focus();
        }
      }

      &.selected {
        .swatch {
          &::after {
            background-color: $black;
            transform: translateX(-50%) scaleX(1);
          }
        }
      }
    }
  }

  &.bottom-divider {
    @include border('bottom');
  }

  //INVERTED VARIANT

  &.inverted {
    border-color: $white_inactive;
    color: $white;

    .selector__menu__item {
      color: inherit;
      &.selected {
        .swatch {
          &::after {
            background-color: $white;
          }
        }
      }
      &:focus {
        .swatch {
          @include touchtarget-focus($outline-color-inverted);
        }
      }
    }
  }

  //INTILE VARIANT

  &.intile {
    height: $selector-size-intile-height;
  }
}
