.book-appointment-trebaseleghe {
  margin-bottom: 8rem;
  &-wrapper {
    .optionbox {
      border-bottom: 1px solid $grey_input;
      width: auto;
      &__item {
        min-width: auto;
        flex: 1;
      }
    }
    .baa-login-form,
    .baa-book-form,
    .baa-edit-form,
    .thank-you-wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 3.2rem;
      gap: 3.2rem;
      &-title {
        font-size: 1.6rem;
        text-transform: uppercase;
      }
      .ctas-wrapper {
        flex-direction: column;
        gap: 2.4rem;
        margin-top: 0.8rem;
        justify-content: center;
        .button {
          flex-basis: 100%;
          max-width: 100%;
        }
      }
    }

    .baa-book-form,
    .baa-edit-form {
      .date-selector-section {
        .row {
          flex-direction: column;
          gap: 2.4rem;
          [class^='col'] {
            flex-basis: 100%;
            max-width: 100%;
          }
        }
      }
      .family-members-wrapper {
        .row {
          [class^='col'] {
            gap: 2.4rem;
          }
        }
      }
      .name-fields {
        .row {
          [class^='col']:last-child {
            margin-top: 2.4rem;
          }
        }
      }
      .ctas-wrapper {
        flex-direction: column-reverse;
        align-items: center;
        gap: 2.4rem;
      }
    }

    .baa-edit-form {
      border-bottom: 0;
      padding-top: 3.2rem;
      border-top: 1px solid #e4e4e4;
      .detail-grid {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
        margin-bottom: 0;
        .detail-cell {
          gap: 0.8rem;
          .title {
            font-size: 1.6rem;
            margin-bottom: 0.4rem;
          }
        }
      }
      .ctas-wrapper {
        flex-direction: column;
        &.cancel-or-save {
          border-bottom: 0;
          padding-bottom: 0;
          .button--tertiary {
            width: 100%;
            margin-bottom: 1.6rem;
            padding-bottom: 2.4rem;
            border-bottom: 1px solid #e4e4e4;
          }
        }
        &.no-flex {
          display: flex;
        }
      }
    }

    .thank-you-wrapper {
      .booking-number {
        border-top: 1px solid #e4e4e4;
        padding-top: 2.4rem;
        margin-bottom: -3.2rem;
      }
      .detail-grid {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
        .detail-cell {
          gap: 0.8rem;
          .title {
            font-size: 1.6rem;
            margin-bottom: 0.4rem;
          }
        }
      }
      .ctas-wrapper {
        margin-top: 0;
        gap: 0.8rem;
        &.no-flex {
          margin: 0 auto;
        }
      }
    }

    .manage-appointments-wrapper {
      .appointments-list {
        margin: 6.4rem 0 3.2rem;

        .appointment-item {
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 0.8rem;
          .info {
            min-width: none;
          }
          .view-button-wrapper {
            flex: none;
            padding: 0;
          }
          .edit-button-wrapper {
            width: 100%;
          }
        }
      }
    }
  }
}
