#mbmNotifymeModal {
  max-width: 916px;
  min-width: initial;
  .modal__body {
    padding-left: 10rem;
    padding-right: 10rem;
  }
}

.product-mbm-notify {
  .form-group {
    width: 34rem;
  }
}
