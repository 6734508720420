.checkoutpage {
  .radio--outline {
    .inner-label {
      padding-left: 3.2rem;
    }
  }
  .radio__icon {
    [class^='icon']:not(:last-child) {
      margin-right: ($space-unit * 1);
    }
  }
}
.checkout-packaging {
  .optionbox__item {
    width: 100%;
    flex: auto;
    .option-packaging__detail {
      width: 100%;
      justify-content: space-between;
    }
  }
}
