.perfume-product-highlight {
  .swiper-pagination--bars {
    left: 50%;
  }

  .btn--pause {
    position: absolute;
    left: 2rem;
    bottom: 2rem;
    z-index: 2;
  }
}
