.intro-plp {
  &__text-container {
    margin-bottom: ($space-unit * 4);
  }
  &__title {
    @extend %primary-m-u;
    font-size: 2.4rem;
    margin: ($space-unit * 2) 0 ($space-unit * 4);
  }
  &__tags-container {
    position: relative;
    margin-right: -1.6rem;
    margin-left: -1.6rem;
  }
  &__tags-list {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  &__read-more-container {
    .intro-plp {
      &__read-more-text {
        @extend %primary-s;
        display: none;
        &.open {
          display: inline;
        }
      }
      &__read-more-btn {
        display: inline;
      }
    }
  }
  &__breadcrumbs {
    .breadcrumb {
      li {
        &:not(:last-child) {
          display: none;
        }
        &:last-child {
          .breadcrumb__item {
            display: inline-flex;
            align-items: center;
            &::before {
              content: '';
              margin: 0 ($space-unit * 2);
              @include fixedSprite('arrow-right-breadcrumbs');
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}
