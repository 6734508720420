.curator-flp-video {
  .btn--pause {
    left: 2.4rem;
  }
  .curator-video__text-wrapper {
    width: auto;
    .date {
      font-size: 1.6rem;
      line-height: 2rem;
      margin-bottom: 2.4rem;
    }
    .curator-video__title {
      font-size: 9.2rem;
      line-height: 8.4rem;
      margin-bottom: 2.4rem;
    }
    .curator-video__quote {
      font-size: 1.6rem;
      line-height: 2rem;
      margin-bottom: 2.4rem;
    }
  }
  .curator-video-wrapper {
    max-height: calc(var(--viewport-height) - #{$header-height-mobile});
  }
}
