.orders-listing__order-detail {
  display: flex;
  justify-content: space-between;
}
.order-detail__column {
  width: 50%;
  margin-bottom: ($space-unit * 4);
  &:last-child {
    margin-bottom: 0;
  }

  &-sm{
    width: 19%;
  }

  &-lg{
    width: 27%;
  }

  &-vertical{
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-sm{
      width: 19%;
    }
  }

  &-map{
    width: 100%;
  }
}

.order-history-multiship{
    
  &-group{
    margin-top: 0;
  }

  &.flex-wrap {
    flex-wrap: wrap;
  }
}

.row-info__column {
  width: 50%;
}
.clothing-details {
  .order-detail__column {
    width: 50%;
  }
  .header__open,
  .header__close {
    top: 0;
    transform: translateY(50%);
    .text {
      display: block;
    }
  }
}

$boxPadding: 32px 40px;

.order-history-info-box,
.order-history-info-listing {
  padding: $boxPadding;
}