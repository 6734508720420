.store__summary__directions.get-directions {
  width: 70%;
}
.search-store {
  &__resultslist {
    ul {
      padding: 0 2rem;
      li {
        padding-top: 2.4rem;
        padding-bottom: 3.2rem;
      }
    }
  }
  &__regions {
    .wrapper-regions {
      padding: 0 2rem;
    }
  }
}
.store-in-cluster {
  padding: 2.4rem 0 3.2rem;
  button.button {
    width: 100%;
  }
}
