.page-template-mbm_configurator {
  display: flex;
  flex-direction: column;
  main {
    flex-grow: 1;
    border-bottom: initial;
  }
}
.welcome-step {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1;
  > div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .btn--pause {
      position: absolute;
      bottom: 2rem;
      left: 2rem;
      z-index: 1;
      &:focus {
        z-index: 3;
      }
    }
  }
}
.image-big {
  position: absolute;
  height: 90%;
  width: 100%;
  top: 10%;
  left: 50%;
  z-index: 1;
  object-fit: cover;
  object-position: top;
  // border: 1px solid red;
  max-width: initial;
  transform: translateX(-50%);
}
.mbm-box {
  position: relative;
  display: flex;
  flex-direction: column;
  background: white;
  z-index: 2;
  padding: 1.6rem;
  margin-bottom: 2.4rem;
  text-align: center;
  h2 {
    @extend %primary-m-u;
    margin-bottom: ($space-unit * 4);
    margin-top: $space-unit;
  }
  p {
    @extend %primary-s;
    margin-bottom: 3.1rem;
  }
  &.first-block {
    .mbm-logo-big {
      margin-bottom: 0.8rem;
    }
    p {
      margin-bottom: 4.4rem;
    }
  }
}
.mbm-go-to {
  position: absolute;
  top: 1.2rem;
  @include sprite('chevron-left');
  height: 3.2rem;
  width: 3.2rem;
  padding: 0.8rem;
  background-size: 1.6rem;
  &.next {
    right: 0.8rem;
    transform: rotate(180deg);
  }
  &.prev {
    left: 0.8rem;
  }
}
.mbm-logo-big {
  height: 48px;
  object-fit: contain;
  margin-bottom: ($space-unit * 4);
  margin-top: ($space-unit * 2);
}
.mbm-onboarding,
.mbm-finish {
  &::before,
  &::after {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    // z-index: -1;
  }
  &::before {
    top: 0;
    left: 0;
    height: 20%;
    background: $gradient-separator;
  }
  &::after {
    bottom: 0;
    left: 0;
    height: 80%;
    background: linear-gradient(0deg, #f9f9f9 0%, #ffffff 100%);
  }
  .mbm-dark-mode & {
    color: $white;
  }
}
#react-root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
.model-selection-container {
  display: flex;
  flex-grow: 1;
}
.mbm-row-wrapper {
  flex-grow: 1;
}
.mbm-col-wrapper {
  display: flex;
  flex-direction: column;
}
.mbm-tabs {
  position: relative;
  z-index: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  // margin-bottom: 6.4rem;
  .mbm-dark-mode & {
    background-color: $black;
    .react-tabs__tab-list {
      &:before {
        border-bottom: 1px solid rgba($white, 0.25);
      }
      .react-tabs__tab--selected {
        &:after {
          background-color: $white;
        }
      }
    }
  }

  .models-selection-panel {
    display: none;
  }

  .react-tabs__tab {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
  .react-tabs__tab-panel--selected {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    &.models-selection-panel {
      display: flex;
    }
  }
}
.model-selection-row {
  flex-grow: 1;
  width: 100%;
  align-items: center;
}
.mbm-palettes {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  align-items: center;
  overflow: hidden;
  .swiper-wrapper {
    flex-grow: 1;
    align-items: center;
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    -webkit-transform: translateZ(0);
  }
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.5s cubic-bezier(0.25, 1, 0.25, 1);
    will-change: opacity;
    &:not(.swiper-slide-active) {
      opacity: 0.55;
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    @include fixedSprite('chevron-left-big');
    position: absolute;
    top: 50%;
    width: 3.2rem;
    height: 3.2rem;
    background-color: $white;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    transition: all 0.4s cubic-bezier(0.25, 1, 0.25, 1);
    cursor: pointer;
    z-index: 2;
    .mbm-dark-mode & {
      filter: invert(1);
      border: 1px solid $black;
    }
    &.swiper-button-disabled {
      opacity: 0;
      visibility: hidden;
    }
  }
  .swiper-button-next {
    right: 3.2rem;
    transform: translateY(-50%) rotate(180deg);
  }
  .swiper-button-prev {
    left: 3.2rem;
    transform: translateY(-50%);
  }
}
.mbm-header {
  height: $header-height-mobile;
  background: initial;
  &::before {
    content: initial;
  }
  .mbm-dark-mode & {
    // background-color: $black;
    color: $white;
  }
  > .container {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .mbm-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 2.4rem;
  }
}

.mbm-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  img {
    width: 100%;
    display: block;
  }
  span {
    margin-top: 1.2rem;
  }
}

.mbm-loader-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: white;
  pointer-events: none;
  opacity: 0;
  @include animate();
  .mbm-dark-mode & {
    background-color: $black;
  }
  &.show {
    opacity: 1;
  }
  .mbm-loader {
    position: absolute;
    text-align: center;
  }
}
.mbm-container-title {
  padding-top: 6rem;
  z-index: 1;
  .mbm-dark-mode & {
    background-color: $black;
  }
}
.mbm-title {
  text-transform: uppercase;
  text-align: center;
  font-size: 1.8rem;
  line-height: 2.2rem;
  margin-top: ($space-unit * 2);
  margin-bottom: $space-unit;
}
.mbm-stepper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}
.mbm-step {
  padding: 0.8rem 0.2rem;
  > span {
    width: 3.2rem;
    height: 0.1rem;
    background-color: rgba(0, 0, 0, 0.42);
    display: block;
  }
  &.active {
    > span {
      height: 0.2rem;
      background-color: black;
    }
  }
}

.mbm-finish {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding-top: 4rem;
  padding-bottom: 4rem;
  text-align: center;
  max-height: calc(var(--viewport-height) - #{$header-height-mobile});
  > .container {
    z-index: 1;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__title {
    p {
      @extend %primary-s-u;
      margin-bottom: 1.2rem;
    }
    img {
      margin: 0 auto;
      margin-bottom: 3.2rem;
      height: 5rem;
    }
  }
  &__image {
    flex-grow: 1;
    max-height: 490px;
    .model-selection {
      &::before {
        content: initial;
      }
      .model-image {
        width: 100%;
        height: 100%;
      }
    }
  }
  &__buttons {
    margin-top: 4rem;
    > :first-child {
      order: 1;
    }
    .button {
      margin-bottom: 1.2rem;
    }
  }
}

.mbm-exit {
  display: flex;
  align-items: center;
  .mbm-dark-mode & {
    color: $white;
    .icon--exit {
      filter: invert(1);
    }
    &:focus {
      outline-color: $white;
    }
  }
  span {
    text-transform: uppercase;
    margin-left: ($space-unit * 2);
  }
}

.mbm-dark-mode {
  [data-react='mbm-configurator'] {
    background: $black;
  }
  .mbm-loader {
    filter: invert(1);
  }
  *:focus {
    outline-color: white;
  }
}

body.has-ticker {
  .mbm-finish {
    max-height: calc(var(--viewport-height) - #{$header-height-mobile} - #{$ticker-bar});
  }
}
