.accordion-component {
  .accordion-actions {
    padding-top: $space-unit * 10;
    align-items: center;
    p {
      text-align: right;
    }
  }

  .accordion__header {
    height: ($space-unit * 12);
  }
  .wrapper-text {
    padding-bottom: ($space-unit * 6);
    p {
      padding-bottom: ($space-unit * 4);
    }
  }
}
