.lunettes__component {
  .lunettes__image-wrapper {
    &:before {
      padding-top: 60%;
    }
  }
  .lunettes__product-slider {
    .swiper-button-prev {
      position: absolute;
      width: 10rem;
      height: 100%;
      top: 0;
      background-color: $black;
      z-index: 2;
      left: 0;
      @include animate;
      &.swiper-button-disabled {
        left: -10rem;
      }
    }
    .swiper-button-next {
      @include animate;
      position: absolute;
      width: 10rem;
      height: 100%;
      top: 0;
      background-color: $black;
      z-index: 2;
      right: 0;
      &.swiper-button-disabled {
        right: -10rem;
      }
    }
  }
}
