.video-module.simplified.genius {
  &:not(.full-screen):not(.no-padding) {
    padding: 4rem 1.6rem;
  }
  &:not(.full-screen) {
    .video-module__inner-container {
      height: auto;
      width: 100%;
      aspect-ratio: 2 / 3;
      // @include breakpoint(sm, min) {
      //   // aspect-ratio: 2 / 3;
      //   height: 84.9rem;
      //   width: 127.35rem;
      // }
      // @include breakpoint(md, min) {
      //   // aspect-ratio: 16 / 9
      //   height: 45.3rem;
      //   width: 25.48125;
      // }
      // @include breakpoint(lg, min) {
      //   // aspect-ratio: 16 / 9;
      //   height: 64.4rem;
      //   width: 36.225rem;
      // }
    }
  }
  .video-module__title {
    font-family: 'BlockGothicRR';
    font-weight: 700;
    font-size: 4.2rem;
    line-height: 3.36rem;
    margin-bottom: 1.6rem;
  }
  .video-module__cta button {
    font-size: 1.4rem;
  }
}
