.product-carousel {
  .swiper-slide {
    max-width: calc(33.34% - 2.67px);
  }
}

.skeleton-carousel {
  .swiper-slide {
    width: calc(33.34% - 2.67px);
  }
}
