.sticky-tray {
  display: none;
  &.show {
    display: block;
  }
  &__wrapper {
    margin: 0;
  }
  &__button {
    padding: 0;
  }
  &__name {
    position: absolute;
    top: 24px;
    left: 16px;
    display: none;
  }
  &__name-price {
    padding: 0;
    .sticky-tray__price {
      padding: 0 1.8rem;
      font-size: 1.6rem;
      text-align: center;
    }
  }
  .btn-express-checkout {
    button {
      border: none;
      border-left: 1px solid black;
    }
  }
}
