.collections-footnote {
  padding: 3rem 3.4rem;
  &__block {
    position: relative;
    text-align: center;
    padding: ($space-unit * 8.5) ($space-unit * 7.5);
    border: ($space-unit * 4) solid;
    border-color: $black;
  }
  &__block.pd-no__padding {
    padding-block: 0;
  }
  &__block.pd-no__border {
    border-block: none;
  }
  &__inverted {
    color: $white;
    background-color: $black;
    border-color: $white;
  }
  &__logo {
    width: 6.4rem;
    height: 6.4rem;
    object-fit: contain;
    margin: 0 auto ($space-unit * 6);
    &.logo-as-title {
      width: auto;
      max-width: 250px;
      height: auto;
    }
  }
  &__title {
    text-transform: uppercase;
    letter-spacing: normal;
    margin-bottom: ($space-unit * 2);
    &.BlockGothicRR {
      font-family: 'BlockGothicRR';
      font-size: 4rem;
      font-weight: bold;
      line-height: 3.2rem;
    }
  }
  &__description {
    letter-spacing: normal;
    margin-bottom: ($space-unit * 6.5);
  }
  &__link {
    display: inline-block;
    text-decoration: none;
    position: relative;
  }
}
