.product-selection {
  .row {
    .wrapper-body-detail {
      width: calc((100vw - 64px) / 12 * 4);
      &.mq-large-case-width {
        max-width: 72.5rem;
      }
      &.mq-large-case-height {
        min-height: auto;
      }
    }
  }
}
