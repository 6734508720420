.autocomplete {
  @extend %primary-xs;
  background-color: $white;
  position: absolute;
  z-index: 100;
  width: 100%;
  top: 100%;
  @include border();
  ul {
    padding: 0;
    list-style-type: none;
    li {
      &:first-child {
        border-top: initial;
      }
      min-height: 3.6rem;
      @include border('top');
      display: flex;
      align-items: stretch;
      padding: 0;
      &.focused {
        background-color: #ebf2fe;
      }
      &:hover {
        background-color: #fafafa;
      }
      button {
        width: 100%;
        padding: 0 ($space-unit * 2);
        cursor: pointer;
        text-align: left;
        @include text-shorten;
        @extend %primary-xs;
      }
    }
  }
  .attribution {
    @include border('top');
    padding: $space-unit;
    display: flex;
    justify-content: flex-end;
    img {
      height: 18px;
      width: 144px;
    }
  }
}

.address-search {
  position: relative;
}
