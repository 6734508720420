[data-react='hero-genius'] {
  @include geniusRatio();
}

.hero.genius {
  @include geniusRatio();
  &.hero--with-border {
    margin: 1.6rem;
  }
  .hero-column__inner-container {
    align-items: center;
    padding: 7.8rem 2rem;
    justify-content: center;
    &.title-only {
      justify-content: end;
    }
  }
  .hero-column__title,
  .hero-column__headline.spacing {
    text-align: center;
  }
  .hero-column__title {
    font-size: 6.2rem;
    font-weight: 500;
    line-height: 86%;
    text-transform: none;
    font-family: 'BlockGothicRR';
  }

  .hero-column__headline.spacing {
    @include geniusTitle();
  }
  .hero-column__headline.spacing {
    text-align: center;
  }
  .hero-column__label {
    text-transform: none;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.6rem;
    margin-bottom: 1rem;
  }
  .btn--pause {
    left: 1.6rem;
    bottom: 1.6rem;
  }
}
