.component-page-title {
  text-transform: uppercase;
  font-size: 3.2rem;
  line-height: 3.8rem;
  text-align: center;
  padding: 4rem 0;

  // OE-16934 - Optimize Editorial Module Borders/Paddings
  &.pd-no__padding {
    padding-block:0;
  }
}
