.collection-card {
  padding-bottom: 130%;
  &__inner-content {
    width: 100%;
    padding: 0 ($space-unit * 6) 4.5rem;
    .button {
      font-size: 1.6rem;
      line-height: 2rem;
      padding-bottom: 0.2rem;
      margin-top: 5rem;
    }
    img {
      max-height: 7.2rem;
    }
  }
  &__title {
    height: 4.8rem;
  }
  &__description {
    display: block;
    font-size: 1.4rem;
    line-height: 1.6rem;
    height: 3.2rem;
  }
}
header .collection-submenu {
  flex-direction: row;
}
