.btn--pause {
  @include fixedSprite('pause');

  &[aria-pressed='true'] {
    @include fixedSprite('play');
  }

  &-white {
    @include fixedSprite('pause-white');
    &:focus {
      outline-color: white;
    }
    &[aria-pressed='true'] {
      @include fixedSprite('play-white');
    }
  }
}

.btn--play {
  @include fixedSprite('play');

  &[aria-pressed='true'] {
    @include fixedSprite('pause');
  }

  &-white {
    @include fixedSprite('play-white');
    &:focus {
      outline-color: white;
    }
    &[aria-pressed='true'] {
      @include fixedSprite('pause-white');
    }
  }
}

.btn--volume {
  &:focus {
    outline-color: white;
  }
  &[aria-pressed='true'] {
    @include fixedSprite('volume-on');
  }
  &[aria-pressed='false'] {
    @include fixedSprite('volume-off');
  }
}

.btn--forward {
  @include fixedSprite('double-arrow-right');
  @include fix-font-space(0.1rem);
  &-white {
    @include fixedSprite('double-arrow-right-white');
  }
}
.btn--backward {
  @include fixedSprite('double-arrow-left');
  @include fix-font-space(0.1rem);
  &-white {
    @include fixedSprite('double-arrow-back-white');
  }
}

.btn--next {
  @include fixedSprite('chevron-left-big');
  @include fix-font-space(0.1rem);
  i {
    transform-origin: center;
    transform: rotate(180deg);
  }
  &-white {
    @include fixedSprite('chevron-left-big-white');
  }
}

.btn--prev {
  @include fixedSprite('chevron-left-big');
  @include fix-font-space(0.1rem);
  &-white {
    @include fixedSprite('chevron-left-big-white');
  }
}
