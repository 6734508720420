.promo-showcase {
  color: $white;
  &__carousel {
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: hidden;
  }
  &__container {
    padding: 0;
  }
  &__text-wrapper {
    padding-top: 8.6rem;
    padding-bottom: 2rem;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .single-card & {
      padding-top: 3.2rem;
    }
  }
  &__cards-swiper {
    height: 100%;
    width: 100%;
  }
  &__slides-number {
    position: absolute;
    top: 5rem;
    z-index: 1;
  }
  .swiper-pagination--bars {
    position: absolute;
    top: 0;
    z-index: 2;
    justify-content: flex-start;
    margin-top: ($space-unit * 8);
    // left: 50%;
    // transform: translateX(-50%);

    // &::before {
    //   content: '';
    //   position: absolute;
    //   left: 0;
    //   right: 0;
    //   height: 6rem;
    //   width: 100%;
    //   z-index: 1;
    //   top: 50%;
    //   transform: translateY(-50%);
    // }
    .swiper-pagination-bullet {
      background: rgba(255, 255, 255, 0.25);
      z-index: 2;
      transition: initial;
      width: 20%;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: $white;
        transform: scaleX(0);
        transform-origin: left;
      }

      &.swiper-pagination-bullet-active {
        background: rgba(255, 255, 255, 0.25) !important;
      }
    }
  }

  &__link {
    height: 100%;
  }

  &__title {
    @extend %primary-l-u;
  }

  &__cta-wrapper {
    text-align: right;
  }
  &__picture {
    &:before {
      content: '';
      position: relative;
      padding-top: 162.24%;
      display: block;
    }
    &--gradient {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        // background: linear-gradient(0deg, rgba(63, 63, 63, 0.7) 0%, rgba(63, 63, 63, 0) 100%);
        background: rgb(0 0 0 / 20%);
      }
    }
  }
  .promo-showcase__link {
    .promo-showcase__picture {
      img {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.listing-container:not(.loading) {
  .promo-showcase {
    .swiper-pagination--bars .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
      transition: all 5s linear;
      transform: scaleX(1);
    }
  }
}
