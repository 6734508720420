@use "sass:math";

$gutter-compensation: $grid-gutter-width * 0.5 * -1;
$half-gutter-width: $grid-gutter-width * 0.5;

.container {
  position: relative;
  margin: 0 auto;
  width: 100%;

  // @each $breakpoint, $max-width in $grid-max-width {
  //   @include breakpoint($breakpoint, min) {
  //     max-width: $max-width;
  //   }
  // }
  @each $breakpoint, $padding in $grid-container-padding {
    @if $breakpoint== 'xs' {
      padding: 0 $padding;
    } @else {
      @include breakpoint($breakpoint, min) {
        padding: 0 $padding;
      }
    }
  }

  &--neg {
    @each $breakpoint, $padding in $grid-container-padding {
      @if $breakpoint== 'xs' {
        margin: 0 (-$padding);
      } @else {
        @include breakpoint($breakpoint, min) {
          margin: 0 (-$padding);
        }
      }
    }
  }

  &--halfright {
    padding-left: $space-unit * 0.5;
  }

  &--halfleft {
    padding-right: $space-unit * 0.5;
  }

  &--fluid {
    position: relative;
    width: 100%;

    @each $breakpoint, $padding in $grid-container-padding {
      @if $breakpoint== 'xs' {
        padding: 0 $padding;
      } @else {
        @include breakpoint($breakpoint, min) {
          padding: 0 $padding;
        }
      }
    }
  }
}

.row {
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  margin-right: $gutter-compensation;
  margin-left: $gutter-compensation;

  &.reverse {
    flex-direction: row-reverse;
  }

  &--full-bleed {
    margin-left: 0;
    margin-right: 0;

    [class^='col-'] {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

//alignment ulitilities

.justify-start {
  justify-content: flex-start;
  text-align: left;
}

.justify-center {
  justify-content: center;
  // text-align: center;
}

.justify-end {
  justify-content: flex-end;
  text-align: right;
}

.justify-around {
  justify-content: space-around;
}

.justify-between {
  justify-content: space-between;
}

.align-top {
  align-items: flex-start;
}

.align-middle {
  align-items: center;
}

.align-bottom {
  align-items: flex-end;
}

// default grid

.col,
[class^='col-'] {
  padding-right: $half-gutter-width;
  padding-left: $half-gutter-width;
  flex: 1 0 auto;
  width: 100%;
}

@for $i from 1 through $grid-columns {
  .col-#{$i} {
    flex-basis: math.div(100%, $grid-columns) * $i;
    max-width: math.div(100%, $grid-columns) * $i;
  }
}

@for $i from 0 through $grid-columns {
  .offset-#{$i} {
    @if $i==0 {
      margin-left: 0;
    } @else {
      margin-left: math.div(100%, $grid-columns) * $i;
    }
  }
}

// @for $i from 0 through $grid-columns {
//   .col-push-#{$i} {
//     @if $i==0 {
//       margin-right: 0;
//     } @else {
//       margin-right: 100% / $grid-columns * $i;
//     }
//   }
// }

@each $breakpoint, $media in $grid-breakpoints {
  @include breakpoint($breakpoint, min) {
    .col-#{$breakpoint} {
      flex-grow: 1;
      max-width: 100%;
    }

    @for $i from 1 through $grid-columns {
      .col-#{$breakpoint}-#{$i} {
        flex-basis: math.div(100%, $grid-columns) * $i;
        max-width: math.div(100%, $grid-columns) * $i;
      }
    }

    @for $i from 0 through $grid-columns {
      .offset-#{$breakpoint}-#{$i} {
        @if $i==0 {
          margin-left: 0;
        } @else {
          margin-left: math.div(100%, $grid-columns) * $i;
        }
      }
    }
  }
}

%neg-container {
  @each $breakpoint, $padding in $grid-container-padding {
    @if $breakpoint== 'xs' {
      margin: 0 (-$padding);
    } @else {
      @include breakpoint($breakpoint, min) {
        margin: 0 (-$padding);
      }
    }

    > * {
      @if $breakpoint== 'xs' {
        padding: 0 $padding;
      } @else {
        @include breakpoint($breakpoint, min) {
          padding: 0 $padding;
        }
      }
    }
  }
}

@mixin col($i: 12, $grid-columns: 12) {
  padding-right: $half-gutter-width;
  padding-left: $half-gutter-width;
  flex: 1 0 auto;
  width: 100%;
  flex-basis: math.div(100%, $grid-columns) * $i;
  max-width: math.div(100%, $grid-columns) * $i;
}

@mixin col-width($i: 12, $grid-columns: 12) {
  width: 100%;
  max-width: math.div(100%, $grid-columns) * $i;
}

@mixin modal-width($i: 12, $grid-columns: 12) {
  min-width: math.div(100%, $grid-columns) * $i;
  // to do: calcolare anche i gutter del container
}
