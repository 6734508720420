.curator-music-player {
  display: flex;
  flex-direction: column;
  width: 100%;
  .curator-music-player-img-dx {
    .music-player {
      padding: 0 0;
      width: 80%;
    }
    .container-without-image {
      height: 100vw;
      min-height: 40rem;
    }
  }
}
