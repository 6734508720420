.checkout-shipping {
  &__mode-selector {
    display: flex;
    margin-bottom: $space-unit * 6;

    button:not(:last-child) {
      margin-right: $space-unit;
    }
  }

  &__address {
    &__address-book {
      margin-top: $space-unit * 6;
      margin-bottom: $space-unit * 8;
      .subtitle {
        margin-bottom: $space-unit * 3;
      }

      &__list {
        display: grid;
        grid-auto-rows: 8.2rem;
        grid-template-columns: repeat(3, 1fr);
        gap: $space-unit;
        margin-top: $space-unit * 3;

        button {
          border: $space-line solid black;
          padding: $space-unit * 3;
          @extend %primary-s;
          text-align: left;
          display: flex;
        }
      }

      &__new {
        margin-top: $space-unit * 6;
      }
    }
  }

  &__options {
    margin-bottom: $space-unit * 4;

    &__title {
      margin-bottom: $space-unit * 3;
      @extend %primary-s;
    }

    &__deliveryinfo {
      margin-top: $space-unit * 1;
      margin-bottom: $space-unit * 5;
      button {
        text-decoration: underline;
        text-underline-offset: 1px;
        text-align: left;
        margin-left: 2px;
      }
    }

    .fieldset--plain {
      .radio {
        align-items: flex-start;
        input {
          margin-top: 0.3rem;
        }
      }
    }

    &__option {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      text-align: left;

      &:not(:last-child) {
        margin-bottom: $space-unit * 4;
      }
    }

    &__option-name {
      display: block;
      @extend %primary-xs;
      margin-bottom: 0.4rem;
    }
    &__option-cost {
      display: block;
      @extend %primary-xs;
      text-align: end;
    }

    &__option-description {
      display: block;
      @extend %primary-xxs;
    }
    .radio {
      margin-bottom: ($space-unit * 5);
    }
    .is-disabled {
      cursor: none;
      pointer-events: none;
      opacity: 0.25;
    }
  }
  .checkoutcountryselector {
    margin-bottom: $space-unit * 7;
    button {
      text-align: left;
    }
  }

  .address_form {
    text-align: left;
    .mandatory-fields {
      margin-bottom: 1.6rem;
      color: $black_secondary;
    }
    .form-title {
      margin-bottom: 1.2rem;
    }
    .back-button {
      align-self: center;
    }
  }
}

.address-buttons {
  align-items: center;
  margin-top: 1.2rem;
  row-gap: 1.2rem;
  &.edit-active {
    flex-direction: column-reverse;
  }
}

.shipping-tabs {
  border-top: 1px solid $grey_line;
  padding-top: 1.6rem;
  .shipping-tabs-tablist.react-tabs__tab-list {
    margin-bottom: 2.4rem;
    column-gap: initial;
    display: flex;
    padding-left: 0;
    text-align: center;
  }
  .react-tabs__tab[role='tab'] {
    flex: 1 0 50%;
    @extend %primary-xxs;
    letter-spacing: 0.05em;
  }

  .container-shipping-detail {
    padding: 0;
  }
}

.holidaycalendar {
  .modal__header {
    max-width: 69rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding-top: $space-unit * 20;
    padding-bottom: $space-unit * 4;
  }
  .modal__body {
    max-width: 69rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: $space-unit * 10;
  }
  .modal__body {
    max-width: 69rem;
    ul {
      list-style-type: none;
      margin-top: $space-unit * 4;
      padding-top: 0;
      padding-bottom: 0;
      li {
        text-align: left;
        @include border('bottom');
        padding-top: $space-unit * 2;
        padding-bottom: $space-unit * 2;
        display: flex;
        justify-content: space-between;
        span {
          text-align: right;
        }
      }
    }
  }
}
.cta--remove-address {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 0.4rem 0;
  column-gap: 0.4rem;
  &::after {
    content: '';
    display: block;
    @include fixedSprite('arrow-right-black');
  }
}
.delete-address-ask {
  margin-bottom: 2.4rem;
}
.two-buttons {
  display: flex;
  column-gap: 0.4rem;
}
.info-standard-estimated-delivery {
  padding: 1rem 0;
}
.checkbox {
  &.mbm-checkbox {
    .checkbox__field {
      align-items: baseline;
    }
  }
}
.shipping-tabs {
  .shipping-tabs-tablist {
    align-items: center;
  }
  .react-tabs__tab[role='tab'] {
    white-space: pre-wrap;
  }
}
