.checkout-packaging {
  // background-color: $white;
  // padding: ($space-unit * 2) 0 ($space-unit * 16);
  &__intro {
    margin-bottom: 2.4rem;
  }

  .back-button {
    align-self: center;
    display: none;
  }

  &__message {
    margin-top: 3.6rem;
    &__aspect-ratio {
      z-index: -1;
      pointer-events: none;
      padding-bottom: 53.4%;
    }

    &__field {
      position: relative;
      // margin: 0 auto ($space-unit * 10);
      // width: calc(100% - 0.2rem);
      margin-bottom: 2.4rem;

      // &::before {
      //   content: '';
      //   @include fixedSprite('logo-white');
      //   position: absolute;
      //   left: ($space-unit * 3);
      //   top: ($space-unit * 3);
      //   width: 2.5rem;
      //   height: 2.3rem;
      //   @include z('filters');
      // }

      // &::after {
      //   content: '';
      //   background-image: url(./../../media/gift-bg.png);
      //   background-size: contain;
      //   position: absolute;
      //   left: 0.2rem;
      //   top: 0.2rem;
      //   width: 100%;
      //   height: 100%;
      // }
      &__background {
        display: flex;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 100%;
        align-items: center;
        justify-content: center;
        // background-color: white;
        position: absolute;
        // border: $space-line solid $black;
        @include z;
        .message-button {
          @extend %primary-xs;
          padding: $space-unit * 4;
          padding-top: 6rem;
          height: 100%;
          width: 100%;
          word-break: break-word;
          position: absolute;
          overflow-y: auto;

          &:focus {
            @include focus-underline;
          }
        }
        .message-button-row {
          margin-top: ($space-unit * 2);
          &__right-button {
            text-align: right;
          }
        }
      }

      .form-control {
        text-align: center;
        position: relative;
        input,
        textarea {
          padding: 1.1rem 2rem 0.9rem;
        }
        textarea {
          // margin-bottom: 0;
          height: 6.8rem;
        }
      }
    }
    .checkbox {
      margin-bottom: $space-unit;
    }
    input[name='emailGift'] {
      margin-bottom: 0;
    }
    &__box {
      margin-top: $space-unit;
      padding-right: 1.6rem;
    }
    .button--secondary {
      margin-bottom: 2.4rem;
    }
  }

  .option-packaging {
    display: flex;
    height: 100%;
    width: 100%;
    &__name {
      @extend %primary-s;
      // margin-bottom: $space-unit * 2;
    }

    &__img {
      margin-bottom: 1.6rem;
      // height: 233px;
      background: #f7f5f4;
      width: 100%;
      object-fit: contain;
    }

    &__detail {
      position: relative;
      padding-left: 2.8rem;
      display: flex;
      &::before {
        content: '';
        height: 1.2rem;
        width: 1.2rem;
        border-radius: 50%;
        border: 1px solid $black;
        position: absolute;
        left: $space-unit;
        top: $space-unit;
      }
    }
  }

  .mini-link {
    @extend %primary-xxs;
    text-decoration: underline;
  }

  .optionbox__item {
    // margin-right: auto;
    width: auto;
    flex-grow: 0;
    flex-basis: initial;

    // margin-bottom: 0;
  }

  .optionbox__item__radio {
    opacity: 0;
    top: 0;
    left: 0;
    right: initial;
    height: 2.4rem;
    width: 2.4rem;
    &:checked + .option-packaging {
      .option-packaging__detail::after {
        content: '';
        height: 0.2rem;
        width: 0.2rem;
        position: absolute;
        left: 1rem;
        top: 1rem;
        background-color: $black;
      }
    }
  }

  .optionbox__wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 0.8rem;
    }
    .checkbox {
      padding-left: 1.8rem;
      margin-bottom: 3.2rem;
      margin-top: 0.8rem;
    }
  }

  .gift-card-image {
    width: 100%;
  }

  .charleft {
    display: block;
    margin-bottom: 1.6rem;
    color: $black_secondary;
  }

  .packaging-info {
    padding-left: 0.6rem;
  }
}
.checkout-packaging-title {
  margin-bottom: 1.6rem;
}
.message-form {
  padding-top: 6%;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  .form-group {
    margin-bottom: $space-unit;
    input,
    textarea {
      text-align: center;
      // background-color: transparent;
    }
  }
}

[data-whatinput='keyboard'] {
  .message-button {
    &:focus {
      outline: 1px solid black;
      outline-offset: 10px;
    }
  }
  .checkout-packaging {
    [role='radio'] {
      &:focus {
        .option-packaging__name {
          text-decoration: underline;
        }
      }
    }
    .optionbox__item__radio {
      &:focus + .option-packaging {
        .option-packaging__name::before {
          content: '';
          height: 2rem;
          width: 2rem;
          border-radius: 50%;
          border: 1px solid $black;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
}
.overlay-modal.packaging-modal {
  overflow: auto;
}
