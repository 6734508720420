.video-module {
  padding: 9.6rem 14.7rem;
  // OE-16934 - Optimize Editorial Module Borders/Paddings
  &.pd-no__padding {
    padding-block: 0;
  }
  @include appearAnimation();
}

.video-module__inner-container {
  height: 64.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  .video-wrapper {
    opacity: 0;
    @include animate();
    z-index: 2;
  }

  &.video-opened {
    .video-wrapper {
      opacity: 1;
    }
  }
}

.video-module__title {
  color: $white;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-weight: 300;
  position: relative;
  z-index: 2;
  text-align: center;
  text-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.2);
}

.video-module__cta {
  position: relative;
  z-index: 3;
  .button {
    color: $white;
  }

  .button--secondary {
    border-color: $white;
    color: $white;
  }
}

.video-module__video {
  width: 100%;
}

// .video-module {
//   &.simplified {
//     transition: padding 0.3s ease-in;
//     &.no-padding {
//       padding: 0;
//     }

//     &.full-screen {
//       z-index: 6;
//       height: 100vh;

//       .video-container {
//         height: 100vh;
//         width: 100vw;
//         z-index: 6;
//         position: fixed;
//         top: 0;
//       }
//     }

//     .video-wrapper {
//       opacity: 1;
//     }
//     .video-module__title,
//     .module__cta,
//     .video-module__controls,
//     .close-icon {
//       transition: all 0.5s ease-in-out;
//       &.hidden {
//         opacity: 0;
//         pointer-events: none;
//       }
//     }
//     .video-module__controls {
//       position: absolute;
//       display: flex;
//       align-items: center;
//       z-index: 2;
//       bottom: 2.4rem;
//       left: 0;
//       width: 100%;
//       gap: 0.8rem;
//       padding: 0 2rem;
//       .play-pause-button {
//         &[aria-pressed='false'] {
//           &::before {
//             content: '';
//             @include fixedSprite('pause-white');
//             display: block;
//           }
//         }
//         &::before {
//           content: '';
//           @include fixedSprite('play-white');
//           display: block;
//         }
//       }
//       .volume-button {
//         &[aria-pressed='false'] {
//           &::before {
//             content: '';
//             @include fixedSprite('volume-off');
//             display: block;
//           }
//         }
//         &::before {
//           content: '';
//           @include fixedSprite('volume-on');
//           display: block;
//         }
//       }
//       .progress-bar-wrapper {
//         padding: 1rem 0;
//         width: 100%;
//         cursor: pointer;
//         position: relative;
//         .progress-bar {
//           position: relative;
//           flex: 1;
//           height: 0.2rem;
//           background-color: rgba(255, 255, 255, 0.25);
//           .progress {
//             position: absolute;
//             pointer-events: none;
//             transition: all 0.3s linear;
//             top: 0;
//             left: 0;
//             height: 100%;
//             width: 33%;
//             background-color: white;
//           }
//         }
//       }
//     }
//     .close-icon {
//       position: absolute;
//       right: 1.2rem;
//       top: 1.2rem;
//       z-index: 3;
//       width: 2.4rem;
//       height: 2.4rem;
//       &::before {
//         content: '';
//         @include fixedSprite('close-white');
//         display: block;
//       }
//     }
//     .video-module__inner-container {
//       &::after {
//         transition: all 0.5s ease-in-out;
//         z-index: 2;
//       }
//       &.video-opened {
//         &::after {
//           opacity: 0;
//         }
//       }
//     }
//   }
// }
