.component-client-service {
  .category_tiles_section {
    text-align: center;
    overflow: hidden;
    padding-top: 1.6rem;
    padding-bottom: 8rem;
    box-sizing: border-box;

    .client-service-module_single-category-tile {
      list-style: none;
      .client-service-category-tile {
        display: block;
        text-decoration: none;
        position: relative;
      }
    }
    .client-service-card {
      display: block;
      position: relative;
      padding-top: 56.25%;
      .client-service_image {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.55);
        width: 100%;
        height: 100%;
      }
    }

    .client-service-description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 4.4rem 1.6rem;
      color: $white;

      .client-service_title {
        font-size: 2.4rem;
        line-height: 2.8rem;
        text-transform: uppercase;
        margin-bottom: 0.8rem;
      }

      .client-service_subtitle {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
  }
}
