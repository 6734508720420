.breadcrumb {
  display: flex;
  flex-grow: 1;
  padding-top: ($space-unit * 16);
  padding-bottom: ($space-unit * 4);

  &.listing {
    padding-top: $space-unit * 2;
    padding-bottom: 0;
    .breadcrumb__item {
      font-size: 1.2rem;
      text-transform: uppercase;
    }
  }

  .plp-title {
    flex-grow: 1;
  }

  ol {
    @include reset-list;
  }

  li {
    display: inline;
    &:not(:last-child) {
      .breadcrumb__item {
        &::after {
          content: '';
          margin: 0 ($space-unit * 3);
          @include fixedSprite('arrow-right-breadcrumbs');
        }
      }
    }
  }

  &__item {
    @extend %primary-s-u;
    text-decoration: none;
    display: inline-block;
  }
  &.cs-detail {
    background: $white;
  }
}

.hero-breadcrumb {
  height: 8.5rem;
  align-items: flex-end;
  background-color: $grey_disabled;
  flex-grow: 0;

  li {
    display: inline-flex;
    align-items: center;
    &:only-child {
      &:before {
        content: '';
        margin: 0 ($space-unit * 2);
        @include fixedSprite('arrow-right-breadcrumbs');
        transform: rotate(180deg);
      }
    }
  }
  .breadcrumb__item {
    display: inline-flex;
    align-items: center;
  }
}
