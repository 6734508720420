.product-value-proposition {
  text-align: center;
  &__container {
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
  }
  &__image {
    display: block;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-image: var(--background-mobile);
  }
  &__content {
    color: $white;
    text-align: left;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    padding: 8% 7%;

    h2 {
      @extend %primary-xxl;
      text-transform: uppercase;
      margin-bottom: 4.5rem;
      line-height: 1;
    }
    p {
      font-size: 1.6rem;
    }
  }
  .th-video .th-video-player .th-video-element video {
    object-fit: cover;
  }
}
