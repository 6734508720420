.plppromo {
  &.plppromo {
    &__promo-medium,
    &__promo-large {
      .plppromo__div {
        .plppromo {
          &__text-wrapper {
            padding: ($space-unit * 8);
          }
          &__collection-name {
            margin-bottom: ($space-unit * 8);
          }
          &__description {
            font-size: 2rem;
            line-height: 2.4rem;
          }
          &__cta-wrapper {
            margin-top: ($space-unit * 12);
          }
          &__cta {
            width: auto;
            margin: $space-unit;
            font-size: 1.4rem;
            line-height: 1.6rem;
          }
        }
      }
    }
    &__promo-banner {
      .plppromo__div {
        .plppromo__text-wrapper {
          padding: 6.4rem;
          .plppromo__logo {
            width: 6.4rem;
            margin-bottom: 3.2rem;
          }
          .plppromo__title {
            font-size: 5.4rem;
            line-height: 6.4rem;
            margin-bottom: 0.8rem;
          }
          .plppromo__description {
            font-size: 2rem;
            line-height: 2.4rem;
            margin-bottom: 4.8rem;
          }
          .plppromo__cta-wrapper {
            .button {
              display: inline-flex;
              margin-bottom: 0.8rem;
              width: auto;
              min-width: 15.8rem;
              margin: 0 0.4rem;
            }
          }
        }
        .plppromo__picture-container {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          .plppromo__picture {
            &:after {
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              right: 0;
              left: 0;
              background: linear-gradient(0deg, rgba(63, 63, 63, 0.7) 0%, rgba(63, 63, 63, 0) 100%);
            }
            img {
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        .video-container {
          .btn--pause {
            bottom: 3.2rem;
          }
        }
        .plppromo__bottom-cta {
          bottom: 3.4rem;
        }
      }
    }
    &__promo-small {
      .plppromo {
        &__cta-wrapper {
          // display: flex;
          // justify-content: center;
          margin-top: ($space-unit * 7);
        }
      }
    }
    &__promo-medium {
      .plppromo__div {
        .plppromo {
          &__picture {
            &:before {
              padding-top: 70.313%;
            }
          }
          &__cta {
            min-height: 3.6rem;
          }
        }
      }
    }
  }
  &.plppromo__promo-small,
  &.plppromo__promo-medium,
  &.plppromo__promo-large {
    .plppromo__div {
      .plppromo {
        &__title {
          margin-bottom: ($space-unit * 2);
          &.BlockGothicRR {
            font-family: 'BlockGothicRR';
            font-weight: bold;
            $mobile-font-data: map-get($genius-mobile, 'm');
            font-size: nth($mobile-font-data, 1);
            line-height: nth($mobile-font-data, 2);
            letter-spacing: nth($mobile-font-data, 3);
          }
        }
      }
    }
  }
}
