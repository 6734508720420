.product-recommended {
  width: 100%;
  padding-bottom: 3.2rem;
  overflow: hidden;
  &.has-autoplay {
    .swiper-wrapper {
      transition-timing-function: linear;
      -webkit-backface-visibility: hidden;
    }
  }

  .arrows-container {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.8rem;
    gap: 2.4rem;
    bottom: 0;
    top: auto;
    left: 0;
    right: 0;
    z-index: 2;

    button {
      display: flex;
    }
    [disabled] {
      opacity: 0.35;
    }
  }

  .carousel-container__navigation-button {
    display: none;
  }
  .product-recommended__title-wrapper {
    position: relative;

    > button[aria-pressed='true'] {
      @include fixedSprite('play');
    }

    > button[aria-pressed='false'] {
      @include fixedSprite('pause');
    }
  }
  .product-recommended__title {
    @extend %primary-l-u;
    text-align: center;
    word-wrap: break-word;
    margin: 3.2rem 0 1rem;
  }
  .btn--play,
  .btn--pause {
    margin-bottom: 1.6rem;
  }
  &:not(.has-autoplay) {
    .product-recommended__title {
      margin-bottom: 3.2rem;
    }
  }

  &.account {
    margin: 0;
    .product-recommended__title {
      margin: 0;
      padding-top: ($space-unit * 12);
      padding-bottom: ($space-unit * 8);
      @extend %neg-container;
    }
  }

  // .product-tile {
  //   .product-tile__quick-buy {
  //     .button {
  //       max-width: 50%;
  //       span {
  //         overflow: hidden;
  //         text-overflow: ellipsis;
  //         white-space: nowrap;
  //       }
  //     }
  //   }
  //   .product-tile__name {
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     white-space: nowrap;
  //     max-width: 50%;
  //   }
  // }

  .swiper-wrapper.no-transition {
    transition-duration: 100ms !important;
  }
  .swiper-slide {
    width: 58%;
    box-sizing: border-box;
  }
}

// fix to avoid firefox flickering on carousel motion
@supports (-moz-appearance: none) {
  .product-recommended {
    .product-image {
      left: -1px;
    }
  }
}
