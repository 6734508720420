.component_materials_and_product_design {
  $viewportHeight: calc((var(--viewport-height) - #{$contextual-navigation-m}) * 2);
  height: $viewportHeight;
  padding-bottom: calc(var(--viewport-height) - #{$contextual-navigation-m});
  position: relative;
  overflow: hidden;
  color: $white;
  // margin-bottom: calc((var(--viewport-height) - #{$contextual-navigation-m}) / 2);
  .page-template-flp &,
  .page-template-homepage & {
    $viewportHeight: calc((var(--viewport-height) - #{$contextual-navigation-m}));
    height: $viewportHeight;
    padding-bottom: 0;
  }
  &__container {
    display: flex;
    height: calc(var(--viewport-height) - #{$contextual-navigation-m});
  }
  &__content {
    @include animate(1);
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    padding: 4rem 1.6rem;
    text-align: center;
    width: 100%;
    pointer-events: none;
    opacity: 1;
    .collection {
      @extend %primary-xs-u;
      margin-bottom: 3.2rem;
    }
    .text {
      font-size: 16px;
      line-height: 18px;
    }
    h2 {
      margin-bottom: 2.4rem;
    }
    img {
      display: inline-block;
      margin: 0 4px;
    }
  }
  &__image {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .technical-video-wrapper {
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: inherit;
    }
    &.video-played {
      .btn--play {
        @extend .icon--video-pause;
        &:hover {
          @extend .icon--video-pause;
        }
      }
      &:not(.show-controls) {
        &:after {
          background-color: rgba($black, 0);
        }
        .component_materials_and_product_design__content,
        .btn--play,
        .btn--volume {
          opacity: 0;
        }
      }
    }
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba($black, 0.4);
      @include animate(1);
    }
    .btn--play {
      @extend .icon--video-play;
      z-index: 1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      @include animate(1);
      opacity: 1;
      &:hover {
        @extend .icon--video-play-hover;
      }
    }
    .btn--volume {
      position: absolute;
      z-index: 1;
      left: 3.6rem;
      bottom: 3.6rem;
      opacity: 1;
      @include animate(1);
    }
  }
}
