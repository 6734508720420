.sub-category-tiles-component {
  text-align: center;
  box-sizing: border-box;

  .sub-category-tiles-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 6rem;
    padding-bottom: 4rem;
    .sub-category-tile {
      list-style: none;
      padding-bottom: 4rem;

      &_title {
        padding: 0 3.2rem 1.6rem 3.2rem;
      }

      &_description {
        padding: 0 3.2rem 2.4rem 3.2rem;
      }
    }
  }
}
