.modal {
  &.modal-clothing-doubleoptin {
    min-width: auto;
  }
  min-width: 70%;
  &.modal--small {
    @include modal-width(4);
  }
  &.continue-to-store {
    .stores-available {
      ul {
        column-count: 2;
      }
    }
  }
  &--fixed-small {
    min-width: 458px;
    max-width: 458px;
  }
}

.reserveinboutique {
  &.modal {
    min-height: 73.4vh;
    max-height: 73.4vh;
    min-width: 63.7vw;
    .product-reserve .product-image img {
      height: 100%;
    }
  }
}

.modal-width {
  width: 50%;
  min-width: 50%;
}
