.monclercurators-plp {
  .monclercurators-hero__logo {
    padding: 4.8rem 0 6.4rem;
  }
  .monclercurators__text {
    .monclercurators__title {
      font-size: 60px;
      line-height: 56px;
    }
  }
}
