.product-value-proposition {
  &__image {
    background-image: var(--background-desktop);
  }
  &__content {
    top: 20%;
    left: 60%;
    max-width: 20%;
    padding: 0;
    // p {
    //   @extend %primary-l;
    // }
  }
}
