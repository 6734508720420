.look-modal {
  .product-selection {
    padding-top: 0;
    background: transparent;
    height: auto;
    .product-selection__description {
      display: -webkit-box;
      line-height: 1.6rem;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      min-height: ($space-unit * 12);
    }
    .product-selection__ctas {
      margin-bottom: ($space-unit * 6);
    }
    .product-selection__actions-shipping {
      &:not(.on-pdp) {
        > .button {
          color: $black;
        }
      }
    }
  }
}
