.curator-promo {
  &__logo {
    width: 30rem;
    height: 3.2rem;
  }
  &__title {
    font-size: 9.2rem;
    line-height: 8.4rem;
  }
}
