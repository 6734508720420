.modal.a11y-modal {
  max-width: 60rem;
  .modal__header {
    .dialog1_label {
      height: 4rem;
    }
  }
  .accessibility-modal-wrapper {
    display: flex;
    flex-direction: column;
    .modal-intro {
      border-bottom: 1px solid $grey_background;
      .intro-title {
        font-size: 2rem;
        margin-bottom: 0.8rem;
      }
      .intro-description {
        margin-bottom: 1.6rem;
      }
    }
    .options-list {
      flex: 1;
      list-style: none;
      padding: 1.2rem 0;
      .option-a11y {
        min-height: 24rem;
        .name-and-switch {
          display: flex;
          padding: 1.2rem 0;
          .option-name {
            flex: 1;
            font-size: 1.6rem;
          }
          .switch {
            min-width: 7rem;
            display: flex;
            justify-content: space-between;
            .switch-element {
              position: relative;
              height: 1.8rem;
              width: 3.2rem;
              background-color: $grey_background;
              border-radius: 10rem;
              outline-offset: 0.5rem;
              &::after {
                content: '';
                position: absolute;
                transition: all 0.2s ease-in-out;
                top: 2px;
                left: 2px;
                border-radius: 10rem;
                background-color: $white;
                height: 1.4rem;
                width: 1.4rem;
              }

              &.is-on {
                background-color: $black;
                &::after {
                  transform: translateX(1.4rem);
                }
              }
            }
          }
        }
        .description {
          font-size: 1.2rem;
        }
      }
    }
    .ctas-wrapper {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
      .button--tertiary {
        width: fit-content;
        margin: 0 auto;
        padding-left: 2rem;
      }
    }
  }
}
