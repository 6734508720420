.accordion__group {
  text-align: left;
  position: relative;
  .accordion__item {
    &:last-child {
      @include border('bottom');
      .accordion__header {
        border-bottom: initial;
      }
    }
  }
}
.accordion__header {
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $black;
  @include border('top');
  min-height: 4.2rem;
  width: 100%;

  & > span {
    text-align: left;
    padding: 1.2rem 0;
    // white-space: nowrap;
    width: calc(100% - 12px);
  }

  &:focus {
    outline: transparent;

    .header__open,
    .header__close {
      [data-whatintent='keyboard'] & {
        outline: $outline-width solid $outline-color;
      }
    }
  }

  &--uc {
    & > span {
      @extend %primary-xs-u;
    }
  }

  &--lc {
    & > span {
      @extend %primary-s;
    }
  }

  .header__open {
    &::after {
      @include fixedSprite('plus');
    }
  }

  .header__close {
    &::after {
      @include fixedSprite('minus');
    }
  }

  .header__open,
  .header__close {
    position: absolute;
    right: -0.8rem;
    top: 50%;
    transform: translateY(-50%);
    min-width: ($space-unit * 6);
    min-height: ($space-unit * 6);
    width: auto;
    height: auto;
    display: none;
    align-items: center;

    .text {
      @extend %primary-xs;
      display: none;
    }

    &::after {
      content: '';
      display: block;
      width: ($space-unit * 6);
      height: ($space-unit * 6);
    }
  }

  &--cta-text {
    & > span {
      width: calc(100% - 54px);
    }
    .header__open,
    .header__close {
      .text {
        display: block;
      }
      .text,
      &::after {
        opacity: 0.55;
      }
    }
  }

  &[aria-expanded='false'] {
    .header__open {
      display: flex;
    }

    & + .accordion__panel {
      height: 0;
      visibility: hidden;
    }
  }

  &[aria-expanded='true'] {
    .header__close {
      display: flex;
    }
  }

  &--payments {
    align-items: flex-start;
    .header__open,
    .header__close {
      top: 1rem;
      transform: initial;
    }
    > span {
      white-space: initial;
      line-height: 1.6;
    }
  }

  &--mini{
    display: flex;
    align-items: center;
  }

  &--underline{
    text-decoration: underline;
  }

  &--arrow-down{
    padding-top: 6px;
    &::after {
      content: '';
      width: ($space-unit * 2);
      height: ($space-unit * 2);
      @include fixedSprite('arrow-down-black');
    }
  }

  &--arrow-up{
    padding-top: 6px;
    &::after {
      content: '';
      transform: rotate(-180deg);
      width: ($space-unit * 2);
      height: ($space-unit * 2);
      @include fixedSprite('arrow-down-black');
    }
  }
}

.accordion__panel {
  overflow: hidden;
  visibility: visible;
  // @include animate;

  li::marker {
    font-size: 1rem;
  }

  @include animate();
  > * {
    padding-top: $space-unit * 2;
  }

  &--grenoble {
    text-transform: uppercase;
  }
}
