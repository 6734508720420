.membership-module {
  &__frame {
    padding: 2.4rem 2rem;
  }
  &__container {
    padding: 0 0 4rem;
  }
  &__text-col {
    padding: 0 2.4rem;
  }
  &__img-col {
    display: flex;
    flex-direction: column;
    .video-container {
      &.aspect-ratio--16-9 {
        &::before {
          padding-top: 56.25%;
        }
      }
    }
  }
  &__long-title {
    order: 1;
    max-width: 52rem;
    padding: 0 2.4rem;
    margin: 3.4rem auto 1rem;
  }
  &__text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 52rem;
    margin: 0 auto;
    margin-top: 4rem;
  }
  &__title {
    font-size: 2.4rem;
  }
  &__divider {
    display: none;
  }
  &__description {
    margin-top: 2.4rem;
  }
  &__pillar {
    .pillar-title {
      font-size: 1.2rem;
    }
  }
  &__cta-wrapper {
    flex-direction: column;
    margin-top: 2.4rem;
  }
}
