.button {
  // PRIMARY & SECONDARY

  &[class*='primary'],
  &[class*='secondary'] {
    min-height: $button-height-desktop;
  }
}

.button--secondary,
.button--secondary:visited,
.button--secondary:link {
  &:hover {
    // box-shadow: inset 0 0 0 .1rem $black;
    text-decoration: none;
    color: rgba($black, 0.6);
    border-color: rgba($black, 0.6);

    &:focus {
      span {
        text-decoration-color: rgba($black, 0.6);
      }
    }
  }
}

.dw-apple-pay-button.apple-pay-button {
  height: $button-height-desktop;
}
