.myfeed-highlights {
  .no-scroolbar {
    justify-content: center;
    overflow-x: hidden;

    .optionbox__item {
      flex-grow: 0;
    }
  }

  &__wrapper {
    .wrapper__right {
      border-left: none;
      border-top: 1px solid $grey_line;
    }

    .wrapper__left {
      border-right: none;
      padding-block: 60px;
    }

    .hide-mobile {
      display: flex;
    }

    .orders-card, .appointment-card, .order-returns-card  {
      padding-inline: 41px;
    }

    .orders-card {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .col-md-6-4 {
        max-width: 54%;
        flex-basis: 54%;
      }

      .col-md-4-6 {
        max-width: 46%;
        flex-basis: 46%;
        padding-right: 0;
      }
    }

    .appointment-card {
      display: flex;
      // align-items: center;
      &__main {
        padding-block: 24px;
        padding-inline: 40px;
        border-right: none;
        .appointment-store {
          margin-block: 40px;
        }
      }
      &__secondary {
        padding-inline: 40px;
        border-top: 1px solid $grey_line;
        border-left: none;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .btn-group {
          flex-direction: row;
          column-gap: 8px;
        }
      }
    }

    .order-returns-card {
      align-items: normal;

      // &__secondary {
      // }
    }
  }
}
