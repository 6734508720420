.lookbook-component {
  // OE-16934 - Optimize Editorial Module Borders/Paddings
  &.pd-no__padding {
    padding-block:0;
  }
  .single-slide__labels {
    position: absolute;
    bottom: ($space-unit * 4);
    color: $white;
    left: ($space-unit * 4);
    z-index: 4;
  }
  .product-carousel__headline-hover {
    background: $white;
    color: $black;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    strong {
      display: block;
      font-size: 2.4rem;
      line-height: 2.8rem;
    }

    span {
      display: block;
      margin-bottom: 1rem;
      @extend %primary-xs;
    }
  }

  .single-slide__title {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .single-slide__img {
    &:hover {
      .product-carousel__headline-hover {
        display: flex;
      }
    }
  }
  .gallery-thumbs {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    pointer-events: none;
    .swiper-slide {
      width: 5.7rem;
      opacity: 0.75;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      &.swiper-slide-active {
        opacity: 1;
      }
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
