.form-control {
  position: relative;
  input,
  textarea {
    @extend %primary-xs;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: $input-height;
    color: $black;
    padding: 0.9rem 0.7rem;
    background-color: $grey_input;
    width: 100%;
    border: $space-line solid $grey_input;
    border-bottom: $space-line solid $grey_bottom;
    transition: all 0.5s $easeOutQuart, outline 0s, border 0s, border-color 0s;

    &:disabled {
      cursor: none;
      pointer-events: none;
      opacity: $input-disabled-opacity;
    }

    &[aria-invalid='true'] {
      border-bottom-color: $status_error;
    }
    // &:read-only {
    //   background: $grey_disabled;
    //   border-color: $grey_disabled;
    //   color: $black_secondary;
    // }

    &:focus {
      outline: none;
      border-bottom-color: $outline-color;
    }
  }

  input::-webkit-date-and-time-value {
    text-align: left;
  }

  &--outline {
    input {
      border: $space-line solid $input-outline;
      background-color: $white;
    }
  }

  &.has-showpassword {
    input {
      padding-right: calc(var(--toggle-lenght) + 2.2rem);
    }
    &.has-tick {
      .valid-tick {
        right: calc(var(--toggle-lenght) + 1.1rem + 0.8rem);
      }
      input {
        padding-right: calc(var(--toggle-lenght) + 1.1rem + 3.2rem);
      }
    }
  }

  &.has-tick {
    input {
      padding-right: 3.2rem;
    }
  }
}

.form-text-prefix {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $input-height;
  padding: 0.9rem 0 0.8rem 0.8rem;
  border-right: 0;
  border-bottom: $space-line solid $grey_bottom;
  background-color: $grey_input;
  color: #737373;

  & + input {
    border-left: 0;
    padding-left: 0.5rem;
  }

  &.focused {
    outline: none;
    border-bottom-color: $outline-color;
  }

  &.invalid {
    border-bottom-color: $status_error;
  }
}

textarea.form-control {
  display: block;
  height: ($space-unit * 7) * 4;
  margin-bottom: 0;
}
.disable-resize {
  resize: none;
}

.valid-tick {
  content: '';
  right: $space-unit * 2;
  width: ($space-unit * 2);
  height: ($space-unit * 2);
  @include fixedSprite('checkmark');
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}

.tick {
  content: '';
  @include fixedSprite('checkmark');
}

.is-valid {
  .valid-tick {
    display: inline-block;
  }
}

.form-button-container {
  display: flex;
  .button {
    margin-top: 0 !important;
  }
}

.show-password {
  @extend %primary-xxs;
  position: absolute;
  margin-top: 1.1rem;
  right: 1.1rem;
}
