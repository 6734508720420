.navigation {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: $overlay;
    opacity: 0;
    pointer-events: none;
    @include z('overlay-m');
    @include animate(0.4, 'opacity');
  }
  &.open {
    &::before {
      opacity: 1;
    }
  }
}
