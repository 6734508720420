.hero:not(.genius) {
  display: block;
  height: 100%;
  &.swiper-initialized {
    height: 100%;
  }
  // label property
  .hero-column__label {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  &.rpy {
    display: flex;
    margin-top: 60px;
    .hero-text-wrapper {
      justify-content: center;
      .logout {
        margin-top: 35px;
      }
      .welcome-msg {
        line-height: 22px;
        font-size: 18px;
        .guest-msg {
          line-height: 28px;
          font-size: 24px;
        }
      }
      .logoPeaks {
        height: 28px;
        background-size: 297px auto;
      }
    }
  }
  &.hero--app-page {
    .hero-column__inner-container {
      padding-top: 8rem;
      justify-content: flex-start;
      align-items: center;
      .hero-column__headline {
        .mobile-text {
          span {
            p {
              margin-top: 2.4rem;
            }
          }
        }

        .badge-row {
          margin-top: 3.2rem;
        }

        .hero-column__cta {
          justify-content: center;
        }

        .download-app-btn-wrapper {
          display: none;
        }
      }
    }
  }
}

// Margin property
.hero--with-border {
  margin: 1.4rem ($space-unit * 4);
  .hero__hero-column {
    margin: 0.2rem 0;
  }
}

.hero-column__inner-container {
  padding: ($space-unit * 4);
  .hero-column__img {
    .img-desk-none {
      display: block;
    }
    .img-mobile-none {
      display: none;
    }
  }
}

.hero--center-bottom {
  .hero-column__inner-container {
    padding-bottom: 8.2rem;
  }
}

// .hero__hero-column:nth-child(2) {
//   .hero-column__img {
//     transition-delay: 0.1s !important;
//   }
// }

// .hero__hero-column:nth-child(3) {
//   .hero-column__img {
//     transition-delay: 0.2s !important;
//   }
// }

.hero-column__title--bigger {
  font-size: 7.5rem;
  line-height: 5.8rem;
  b {
    width: 2.1rem;
  }
}

.hero-column__cta {
  &--mobile_block {
    // OE-15593
    // width: 100%;
    display: block;
    margin-top: 4.4rem;
    .button {
      width: 100%;
      max-width: 26.4rem;
      &:not(:first-child) {
        margin-left: 0;
        margin-top: 1rem;
      }
      &.button--arrow-right {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 0;
        margin-right: auto;
        margin-left: auto;
        &:after {
          position: static;
          transform: none;
        }
      }
    }
  }
  // OE-14848
  &--mobile-vertical-alignment {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    > .button:not(:first-child) {
      margin-left: 0;
    }
    > .button {
      width: 31.1rem;
    }
  }
}
.countdown {
  b {
    width: 2.1rem;
  }
}

.myaccount-hero {
  &__title {
    top: 20%;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
}

.hero-skinny {
  height: 28rem;

  .hero-search {
    &__title {
      padding: 0 0 2.4rem;
    }
  }
}
