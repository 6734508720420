.gc-config-modal {
  .modal__body {
    .giftcard-form {
      height: 637px;
    }
    .product-notify__detail {
      // moved from gift_card.default, was used for OE-25329
      .invalid-feedback:not(#delayedTime-error) {
        white-space: nowrap;
      }
    }
  }
}
