.more-curators {
  .component-curator-card {
    .curators-card {
      // margin-bottom: 1.2rem;
      &::before {
        padding-bottom: 100%;
      }
      .image-wrapper {
        margin: auto;
        width: calc(100% - 4rem);
        height: calc(100% - 4rem);
      }
      .curators-card__inner-container {
        padding: 6.4rem;
        .date {
          font-size: 1.6rem;
          line-height: 2rem;
        }
        .curator-card__title {
          font-size: 9.2rem;
          line-height: 8.4rem;
          margin: 1.6rem 0;
        }
        .curator-card__quote {
          font-size: 1.8rem;
          line-height: 2.2rem;
          margin-bottom: 3.4rem;
        }
      }
    }
  }
}
