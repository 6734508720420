.client-service-content {
  .link-box {
    &:not(:last-child) {
      margin-bottom: $space-unit * 16;
    }
  }
}

.myaccount-content {
  .return-logged {
    padding-top: 40px;
  }

  .back-btn-wrapper {
    text-align: center;
    position: static;
  }
}
