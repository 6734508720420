.bag-summary {
  padding: 4.4rem 0 4rem;
  position: sticky;
  top: 7.4rem;
  &.checkout {
    position: sticky;
    margin: 0;
    .checkout-products {
      padding-right: 0;
      width: calc(100% + 1.6rem);
    }
    .container-bag-summary {
      padding: 1.6rem;
      border: 1px solid $grey_line;
    }
    .checkout--summary__block {
      padding-bottom: 1.2rem;
      border-bottom: 1px solid $grey_line;
    }
    .wrapper-info-total {
      padding-bottom: 0;
    }
    .minibag__product__columns {
      justify-content: initial;
    }
    .minibag__product__price {
      margin-left: 1.6rem;
      margin-right: 1.4rem;
    }
  }
  .wrapper-sticky-desktop {
    border: 1px solid $grey_line;
    padding: 0 1.6rem 1.6rem;
    // margin-bottom: 2.4rem;
  }
}
