.product-recommended {
  &__content {
    margin-top: 2.4rem;
  }

  .arrows-container {
    top: 0;
    right: 2.8rem;
    padding: 0 0.8rem;
    gap: 2.4rem;
    bottom: auto;
    left: auto;
  }

  .product-recommended__title {
    margin: 3.2rem 0 -2.4rem;
  }

  &.carousel-container__navigation-button {
    display: flex;
  }
  &.pdp-recommendation {
    .carousel-container__navigation-button {
      z-index: 1;
    }
  }
}
