body[class] {
  &.no-overflow {
    header {
      transition-duration: 0s;
      background-color: $white;
    }
  }
}

body {
  &.overlay::before {
    height: calc(100% + var(--footer-height));
  }
}

main {
  z-index: 2;
}
