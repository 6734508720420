.cookie-bar {
  .cookie-msg {
    margin-bottom: 0;
    padding-right: 6%;
  }
  .row {
    align-items: flex-end;
  }
  .button {
    margin-top: 1.2rem;
    &--primary {
      margin-top: 2.4rem;
    }
  }
}
.cookie-center {
  .cookie-center__columns {
    display: flex;
    gap: 6rem;
    .cookie-center__column {
      width: 50%;
    }
  }
}
