.component_materials_and_product_design {
  $viewportHeight: calc((var(--viewport-height) - #{$contextual-navigation}) * 2);
  padding-bottom: calc(var(--viewport-height) - #{$contextual-navigation});
  height: $viewportHeight;
  &__container {
    height: calc(var(--viewport-height) - #{$contextual-navigation});
  }
  &__content {
    .text {
      width: 60%;
      margin: 0 auto;
    }
  }
}
