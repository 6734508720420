.checkout-shipping {
  min-height: 1000px;
  &.clothing-checkout-shipping {
    min-height: auto;
  }
  .cta--remove-address {
    justify-content: flex-start;
    margin-top: 0;
  }

  .address-buttons {
    &.edit-active {
      flex-direction: initial;
    }
  }
}
