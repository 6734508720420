.product-image--animate {
  position: relative;
  background-color: $black;
  overflow: hidden;
}
.product-image {
  @include fixedRatio();
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: $black;
  img {
    height: 100%;
    object-fit: cover;
  }
}
