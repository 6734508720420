.curators-look__component-wrapper {
  padding: 12rem 0;
  .curator-looks__title {
    font-size: 9.2rem;
    line-height: 8.4rem;
    margin-bottom: 6.4rem;
  }
  .curator-look-card {
    overflow: hidden;
    img {
      transform: scale(1);
      @include animate(1);
    }
    &:hover {
      img {
        transform: scale(1.05);
      }
    }
    .curator-card__title {
      margin-top: 1.6rem;
      font-size: 4.2rem;
      line-height: 5rem;
      border-bottom: 1px solid $white;
      padding-bottom: 2.4rem;
      margin-bottom: 1.6rem;
    }
  }
}
