.product-tile {
  &.white-bg {
    .selector-color-link {
      .selector__menu {
        &__item {
          &__label {
            color: $white;
          }
        }
      }
    }
  }
}
