.checkbox {
  // margin-bottom: ($space-unit * 3);
  break-inside: avoid-column;
  position: relative;
  &--flex {
    display: flex;
  }

  input[type='checkbox'] {
    @include checkbox();

    & + label {
      &::before {
        // focus visible
        content: '';
        display: inline-block;
        box-shadow: 0 0 0 $outline-width transparent;
        position: absolute;
        width: $outline-checkbox-size;
        height: $outline-checkbox-size;
        left: -0.5rem;
        top: -0.5rem;
        @include animate;
        z-index: 0;
      }
    }

    [data-whatintent='keyboard'] & {
      &:focus {
        outline-offset: 0.2rem;
      }
    }

    & + label {
      @extend %primary-xs;
      display: inline-block;
      padding: 0.4rem 0 0.4rem 0.8rem;
      cursor: pointer;
    }
  }

  // container
  .checkbox__field {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.is-invalid {
  .checkbox {
    color: $status_error;
  }
}
