.wishlist {
  &__single-product {
    width: calc(50% - 2px);
    .selector-size {
      .selector__button {
        flex-wrap: wrap;
        margin-bottom: 0.8rem;
        align-items: flex-start;
        &:after {
          top: 0.9rem;
        }
        .selector__button__info {
          width: 100%;
          padding-right: 0;
          justify-content: flex-start;
          margin-left: 0;
        }
        &.with-btn-info {
          margin-bottom: 0;
        }
      }
    }
    .wishlist__single-product__product-info {
      justify-content: space-between;
      &__container {
        margin-top: 0;
      }
    }
    &.absolute-info {
      .wishlist__single-product__product-info {
        height: var(--wishlist-height);
      }
    }
  }
  .wishlist-wrapper {
    margin-left: -1.6rem;
    margin-right: -1.6rem;
  }
  &__products-listing {
    column-gap: 0;
    row-gap: 0;
    justify-content: space-between;
  }
}
