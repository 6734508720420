.editorial-multi-text__text-column {
  padding: ($space-unit * 8) ($space-unit * 4);
  &.mbm-promo {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    i {
      width: 16rem;
      height: 3.6rem;
      margin-bottom: 2rem;
    }
    .editorial-multi-text__btn-container {
      flex-direction: column;
      row-gap: 2rem;
      // .button--secondary {
      //   display: none;
      // }
      .button--primary,
      .button--secondary {
        width: 100%;
      }
    }
  }
}
