header {
  .collection-submenu {
    flex-direction: row;
    overflow-x: auto;
    height: auto;
    width: auto;
    display: flex;
    justify-content: start;
    .collection-submenu__item {
      .collection-card__inner-content {
        img {
          margin-bottom: 12rem;
        }
      }
      .collection-card {
        margin: 1.6rem 1.6rem 1.6rem 0;
        width: 80vw;
        height: calc(100svh - 3.2rem - 9.2rem); /* margins and header  */
      }
      &:last-child .collection-card {
        margin-right: 0;
      }
    }
  }
}
