.curator-image-shop-the-look-container {
  position: relative;
  .image {
    img {
      width: 100%;
    }
    .img-mobile {
      display: none;
    }
  }
  .large-quote,
  .long-quote {
    position: absolute;
    bottom: 0;
    padding: 0 10.5% 0 34.5%;
    padding-bottom: 6rem;
    &.white {
      color: white;
    }
  }
  .large-quote {
    font-family: $silkSerifLight;
    font-style: normal;
    font-weight: 300;
    font-size: 92px;
    line-height: 84px;
  }
  .long-quote {
    font-family: $silkSerifLight;
    font-style: normal;
    font-weight: 300;
    font-size: 42px;
    line-height: 50px;
  }
}
