.collection-intro {
  padding-bottom: ($space-unit * 4);
  // OE-16934 - Optimize Editorial Module Borders/Paddings
  &.pd-no__padding {
    padding-block: 0;
  }
  &__img-wrapper {
    position: relative;
    .collection-intro__img {
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      img {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        bottom: 0;
        top: 0;
        object-fit: cover;
        max-width: 100%;
        height: 100%;
      }
    }
  }
  &__content {
    text-align: center;
    padding: 3.2rem 1.6rem;
    background-color: $white;
    overflow: hidden;
    .with-image & {
      border: 1px solid $grey_line;
      position: relative;
      margin-top: -8rem;
      z-index: 2;
    }
  }

  &__logo {
    max-height: 5.4rem;
    width: auto;
    margin: 0 auto 2.6rem auto;
  }
  &__name {
    text-transform: uppercase;
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-bottom: 0.8rem;
  }
  &__description {
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: 3.2rem;
  }

  &__links {
    list-style: none;
    padding: 0;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      display: none;
    }
    &.tabs {
      padding-bottom: 4rem;
    }
    .collections-intro__tab {
      list-style: none;
      display: inline-flex;
      border-bottom: 1px solid $grey_line;
      padding: 0 0 1px 0;
      padding-right: 2.4rem;
      &.active {
        .collection-intro__tab-name {
          font-weight: bold;
          &:before {
            content: '';
            width: 100%;
            height: 1px;
            background-color: $black;
            position: absolute;
            bottom: -1px;
          }
        }
        .collection-intro__link-wrapper {
          display: block;
          .collections-intro__link {
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      .collection-intro__tab-name {
        text-transform: uppercase;
        padding-bottom: 1.6rem;
        position: relative;
      }
      &:last-child {
        padding-right: 0;
      }
      .collection-intro__link-wrapper {
        padding: 0;
        position: absolute;
        width: 100vw;
        left: 0;
        bottom: 2.2rem;
        display: none;
        .with-image & {
          width: calc(100vw - 3.8rem);
        }
      }
    }
  }
  &__tab-name {
    text-decoration: none;
  }
}

.collections-intro__link {
  list-style: none;
  display: inline-flex;
  text-transform: uppercase;
  margin-right: 2rem;
  font-weight: bold;
}

.collection-intro-sticky {
  position: fixed;
  width: 100%;
  background-color: white;
  top: 0;
  left: 0;
  z-index: 4;
  // padding: 1.6rem 0;
  visibility: hidden;
  transform: translateY(-100%);
  border-bottom: 1px solid $grey_line;
  transition-property: background, transform;
  transition-duration: 0.4s;
  transition-timing-function: $easeOutQuart;
  .page-scrolled & {
    transform: translateY(0);
    visibility: visible;
  }
  .page-scrolled-up & {
    // border-top: 1px solid $grey_line;
    margin-top: 1px;
    &:after {
      content: '';
      height: 1px;
      width: 100%;
      background: $grey_line;
      position: absolute;
      top: -1px;
    }
    visibility: visible;
    transform: translateY(var(--headerHeight));
  }
  .sticky-intro-disabled & {
    transform: translateY(-100%);
  }
  &__heading {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    &--desktop {
      display: none;
      align-items: center;
      justify-content: space-between;
      padding: 1.6rem 0;
    }
  }
  &__btn {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1.6rem 0;
    .collection-intro-sticky__name {
      flex-grow: 1;
    }
  }
  &__logo {
    width: auto;
    max-height: 3.4rem;
    display: inline-flex;
    margin-right: 1rem;
  }
  &__name {
    display: inline-flex;
    text-transform: uppercase;
    font-size: 1.8rem;
    line-height: 2.2rem;
    text-decoration: none;
  }
  &__toggle-icon {
    @extend .icon--arrow-down-black;
  }
  &__links {
    list-style: none;
    padding: 0;
    transition-property: transform;
    transition-duration: 0.4s;
    transition-timing-function: $easeOutQuart;
    li {
      @extend .button;
      @extend .button--tertiary;
      @extend .button--arrow-right;
      display: inline-flex;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 2rem;
      .button {
        font-size: 1.6rem;
        line-height: 2rem;
        width: 100%;
        padding: 0.8rem 0;
        justify-content: flex-start;
        &:after {
          content: none;
        }
      }
      ul {
        display: none;
      }
      .collection-intro {
        &__tab-name {
          display: block;
          width: 100%;
          padding: 0.8rem 0;
        }
      }
    }
  }
}
.collection-intro-simplified {
  padding-bottom: 0;
  &__content {
    text-align: center;
  }
  .collection-intro__links.tabs {
    padding: 6.4rem 0 4rem;
  }
  .collection-intro__link-wrapper {
    &:not(.react-tabs__tab-panel--selected) {
      padding-bottom: 0;
    }
  }
}
[data-react='collection-intro-header'] {
  .placeholder::before {
    display: block;
    content: '';
    width: 100%;
    min-height: 48rem;
    @include animate-skeleton();
  }

  &.with-menu {
    .placeholder::after {
      display: block;
      content: '';
      width: 100%;
      min-height: 59.6rem;
    }
  }
}
