.myfeed-highlights {
  &__wrapper {
    .wrapper__left {
      .left__details {
        &__flex {
          flex-direction: row;
        }
      }
    }
  }
}

// controls the graphical tracking
.myfeed-order-track {
  width: 100%;
  padding-inline: 64px;
  margin-bottom: 42px;
  &__legend {
    p {
      text-align: center;
      text-wrap: nowrap;
    }
  }
}
