.share-article {
  padding: 0 3.2rem;
  margin: -0.4rem 0 3.6rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &__icons-wrapper {
    display: inline-block !important;
    vertical-align: middle !important;
  }
  &__text {
    margin-right: 1.6rem;
  }
}

.at-resp-share-element {
  .at-share-btn {
    .at-label {
      font-size: 1.4rem !important;
      padding: 0 !important;
      margin-left: 0.8rem;
      display: inline-block !important;
      margin: 0 0 0 0.8rem !important;
    }
  }
}

.at-style-responsive {
  .at-share-btn {
    padding: 0 !important;
    margin: 0 1.6rem 0 0 !important;
    display: inline-flex !important;
    align-items: center;
  }
}

.at-icon-wrapper:not(a) {
  &,
  svg {
    width: 24px !important;
    height: 24px !important;
  }
}
