.stories-carousel {
  align-items: center;
  // padding: 4rem 0;
  overflow-x: hidden;

  .button--tertiary--inverted {
    color: $white;
  }

  // .swiper-slide {
  //   background-color: $white;
  // }
  // .cards-swiper {
  //   transition: all 0.5s linear;
  // }
  // .isActiveState {
  //   // transition: all 0.5s linear;
  //   transition-duration: 0.5s !important;
  //   transition-timing-function: linear !important;
  //   // transform: translateX(calc((((100% - (2px * 2)) / 3) - 62.639vw) / 2));
  //   .swiper-slide {
  //     &:not(.open-slide) {
  //       transform: scaleY(0.9);
  //       // height: 54.1rem;
  //       .stories-card {
  //         &__image {
  //           img {
  //             transform: scaleY(1.1);
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  &__btn-pause {
    position: absolute;
    left: 1.2rem;
  }

  &.row {
    margin-right: 0;
    margin-left: 0;
  }
  .card-swiper-container {
    justify-content: center;
  }
  &.inverted-color {
    background-color: $black;
    color: $white;
    .swiper-slide {
      &.open-slide {
        &:before {
          @extend .icon--plus-big-black;
        }
      }
    }
    .stories-card {
      background-color: $white;
      color: $black;
    }

    .stories-card__arrow-txt {
      color: $white;
      margin: 0 1rem;
    }
  }
  &__intro {
    padding: 0 1.6rem;
    margin-bottom: 4rem;
    p {
      text-align: center;
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
  &__title {
    // text-transform: uppercase;
    // font-size: 2.4rem;
    // line-height: 2.8rem;
    text-align: center;
    margin-bottom: 2.4rem;
  }

  // &__top-column {
  //   position: relative;
  //   .inverted-color & {
  //     &:after {
  //       border-color: transparent transparent $white transparent;
  //     }
  //   }
  //   &:after {
  //     content: '';
  //     width: 0;
  //     height: 0;
  //     border-style: solid;
  //     border-width: 0 11px 11px 11px;
  //     border-color: transparent transparent $black transparent;
  //     position: absolute;
  //     left: calc(50% - 11px);
  //     bottom: 0;
  //   }
  // }

  .stories-card__arrow-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3rem 0;
  }

  &__container {
    // background-color: #1e1e1e;
    padding: 6.4rem 0;
    // overflow-x: hidden;
    position: relative;

    // height: 733px;
    // height: calc(46.682vw + 200px);
    justify-content: center;
    // align-items: center;
    // display: flex;

    // &:before {
    //   content: '';
    //   position: absolute;
    //   width: 100%;
    //   height: 20%;
    //   background: linear-gradient(0, rgba(0, 0, 0, 0) 25.89%, rgba(0, 0, 0, 0.5) 100%);
    //   top: 0;
    //   left: 0;
    // }
    // &:after {
    //   content: '';
    //   position: absolute;
    //   width: 100%;
    //   height: 20%;
    //   background: linear-gradient(180deg, rgba(0, 0, 0, 0) 25.89%, rgba(0, 0, 0, 0.5) 100%);
    //   bottom: 0;
    //   left: 0;
    // }
  }

  .cards-swiper {
    .swiper-wrapper {
      align-items: center;
    }
    .swiper-slide {
      transition: all 0.5s linear !important;
      &:before {
        content: '';
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 3;
        @extend .icon--plus-big-white;
        transition: transform 0.5s linear;
      }
      width: auto;
      // height: 605px;
      height: 136.12vw;
      overflow: hidden;
      &.swiper-slide-duplicate-active {
        opacity: 1;
        z-index: 2;
        &.open-slide {
          &:before {
            transform: rotate(45deg);
          }
          width: 62.639vw !important;
          // background-color: yellowgreen;
          .stories-card {
            // padding: 7.6rem 5.6rem;
            &__image {
              width: 340px;
            }
            &__text-column {
              opacity: 1;
            }
            &__external-title {
              opacity: 0;
            }
            &::before {
              opacity: 0;
            }
          }
        }
        &.noTransition {
          transition: none !important;
          // transition: all 0.5s linear, width 0s, opacity 0s !important;
          .stories-card {
            transition: none !important;
            &__image {
              transition: none !important;
              img {
                transition: none !important;
              }
            }
            &__text-column {
              transition: none !important;
            }
          }
        }
      }

      &.swiper-slide-active:not(.swiper-slide-duplicate-active) {
        transition: all 0.5s linear !important;
        opacity: 1;
        z-index: 2;
        &.open-slide {
          &:before {
            transform: rotate(45deg);
          }
          .stories-card {
            // padding: 7.6rem 5.6rem;
            &__image {
              width: 340px;
            }
            &__text-column {
              opacity: 1;
            }
            &__external-title {
              opacity: 0;
            }
            &::before {
              opacity: 0;
            }
          }
        }
        &.noTransition {
          transition: none !important;
        }
      }
    }
  }

  .stories-card {
    padding: 0;
    background-color: $black;
    color: $white;
    display: flex;
    align-items: center;
    // max-width: 33vw;
    // width: 33vw;
    overflow: hidden;
    // height: 605px;
    height: 136.12vw;
    transition: all 0.5s linear;
    &::before {
      content: '';
      height: 21%;
      min-height: 12.8rem;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 2;
      opacity: 1;
      background: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      transition: opacity 0.5s linear;
    }
    &__cta {
      margin-top: 0.6rem;
    }

    &.swiper-slide-active.cursor-pointer {
      .stories-card__image {
        cursor: pointer;
      }
    }
    &__text-column {
      transition: all 0.5s linear;
      padding: 0 5.8rem;
      // max-width: 50.5rem;
      width: 35vw;
      // position: absolute;
      position: static;
      left: 400px;
      opacity: 0;
      text-align: left;
      z-index: 2;
    }
    &__image {
      display: flex;
      position: relative;
      // width: 100%;
      // width: 454px;
      height: 100%;
      width: calc(100vw - 2.8rem);
      flex-shrink: 0;
      z-index: 1;
      transition: all 0.5s linear;
      &:before {
        content: '';
        padding-top: 133.236%;
        display: block;
      }
      img {
        position: absolute;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transform: scaleY(1);
        transition: transform 0.5s linear;
      }
    }
    &__title {
      text-transform: uppercase;
      font-size: 2.4rem;
      line-height: 2.8rem;
      margin-bottom: 0.8rem;
    }
    &__external-title {
      @extend %primary-l-u;
      position: absolute;
      bottom: 2rem;
      left: 0;
      z-index: 2;
      color: $white;
      text-align: left;
      width: 100%;
      padding: 2rem;
      transition: all 0.5s linear;
    }
    &__data {
      text-transform: uppercase;
      font-size: 2rem;
      line-height: 2.4rem;
      margin-bottom: 1.6rem;
      font-weight: bold;
    }
    p {
      font-size: 1.4rem;
      line-height: 1.6rem;
      margin-bottom: 2rem;
    }
    .button-row {
      display: flex;
      justify-content: center;
      margin-top: 3.2rem;
      .button {
        display: inline-flex;
        margin: 0 0.4rem;
        width: auto;
        flex-grow: 1;
        flex-basis: 0;
      }
    }
  }
}
