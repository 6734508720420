.modal__close {
  &.modal__close--inverted-sm {
    &::before {
      @include fixedSprite('close-white');
    }
  }
  &.is__sticky {
    position: sticky;
    margin-right: 0;
    padding-right: 1.6rem;
  }
}
.country-modal {
  .accordion__panel {
    ul,
    ol {
      padding-bottom: ($space-unit * 10);
    }
  }
}
.modal {
  &.login-modal {
    .login-modal-buttons > div:not(:last-child):not(.login-registration__login__social) {
      margin-bottom: 0.8rem;
    }
  }
  &.discover-more {
    .withimage__body {
      grid-template-rows: auto;
    }
    .withimage__left {
      display: block;
      max-width: 80%;
      margin: 0 auto;
      padding: 1.6rem 0;
    }
  }
  &.suggested-country-modal {
    height: auto;
    top: auto;
    bottom: 0;
    left: 0;
    transform: none;
    width: 100%;
    max-width: none;
  }
}

.modal {
  &.rpy {
    min-width: 100%;
    max-height: 100vh;
    height: 100%;
  }

  &.modal--bottom-small {
    &.delete-account,
    &.delete {
      bottom: 0;
      top: auto;
      left: 0;
      width: 100vw;
      max-width: 100vw;
      height: auto;
      transform: none;
    }
  }
}

.modal--button-spacing {
  top: 2px;
  right: 0px;
}

.modal-mobile--full-height {
  height: 100vh;
}
