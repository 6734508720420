.product-details {
  padding-top: ($space-unit * 12);
  padding-bottom: ($space-unit * 10);
  position: sticky;
  top: $header-height-desktop;
  transition: all 1s ease;
  transition-delay: 0.3s;
  white-space: pre-line;
  &.landing {
    opacity: 0;
  }
  .btn-label-find-size {
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
    text-transform: uppercase;
  }
  .made-in {
    @include reset-list;
  }
  .accordion__panel {
    line-height: 1.6rem;
    @extend %primary-xs;
    > div {
      padding-bottom: ($space-unit * 10);
    }
    &#composition-care-panel {
      img {
        width: 3rem;
        height: 3rem;
        filter: invert(100%);
        display: inline-block;
        margin: 1rem 1rem 1rem 0;
      }
    }
    .findmysize {
      margin-top: $space-unit * 4;
    }
  }
  &__category {
    margin-bottom: 1em;
    text-transform: uppercase;
  }
  &__short-description {
    margin-bottom: 3.2rem;
  }
}

.led-packaging {
  img {
    display: inline-block;
  }
  .packaging-row {
    align-items: flex-start;
    display: flex;
    padding: 3.2rem 0;
    gap: 1.6rem;
    .container_packaging__col {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      gap: 1.6rem;
      &#led-refill {
        .led-packaging__img {
          padding: 0 0.55rem;
        }
      }
      .led-packaging__img {
        img {
          height: 10rem;
          object-fit: contain;
          object-position: center bottom;
          width: auto;
        }
      }
      h5 {
        @extend %primary-xs-u;
      }
    }
  }
  #led-packaging-app-badges {
    p {
      max-width: 40rem;
    }
    img {
      max-height: 3.4rem;
      margin-right: 1.6rem;
    }
  }
}

.product-cm-warning {
  font-size: 12px;
  color: #0000008c;
}

.drawers {
  &.editmode {
    position: relative;
    max-width: 80rem;
    margin: auto;
    height: auto;
    width: auto;

    .product-details-tab {
      height: auto;
      overflow: unset;
    }

    .product-details {
      white-space: unset;
    }
  }
  &-editmode-overlay {
    position: relative;
    padding: 4rem;
  }
  &-editmode-header {
    max-width: 80rem;
    padding: 1.2rem;
    margin: auto;
    margin-top: 4rem;
    background-color: black;
    text-align: center;
    color: white;
    width: 100%;
  }

  .tabs {
    .wrapper-tab-list {
      overflow-x: auto;
      width: 100%;
      border-bottom: 1px solid #e4e4e4;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .wrapper-tab-panels {
      .product-details.container {
        min-height: 100%;
        display: grid;
        &.min__height {
          min-height: max-content;
        }
      }
    }
    .react-tabs__tab-list {
      column-gap: 1.6rem;
      display: flex;
      &::before {
        content: none;
      }
      & > li {
        justify-content: unset;
      }
    }
  }

  .product-details {
    &-shipping {
      &-link {
        text-decoration-thickness: 0.1rem;
        text-underline-offset: 0.2rem;
        font-variant: small-caps;
      }
    }

    &-faqs {
      .accordion__panel > div {
        padding: 0;
      }

      .accordion__header {
        span {
          @extend %primary-xs-u;
        }
      }

      .faq--button {
        margin-top: 3.2rem;
      }
    }

    &-packaging {
      &-image {
        margin-bottom: 3.2rem;
        img {
          margin-bottom: 0.8rem;
          width: 100%;
          height: auto;
        }
      }
    }

    &-shipments {
      display: flex;
      flex-direction: column;
      list-style: none;
      padding: 0;
    }

    &-shipment {
      border-bottom: 0.1rem solid $grey_line;
      padding: 2.4rem 0;
      &:first-child,
      &.first-child {
        border-top: 0.1rem solid $grey_line;
      }

      &__header {
        display: flex;
        gap: 1.2rem;
        justify-content: space-between;
        margin-bottom: 0.4rem;
      }

      &__body {
        color: $black-secondary;
        .fre-express {
          margin-top: 0.4rem;
          color: black;
          font-weight: bold;
        }
      }
    }
  }

  &.measmerize-drawer {
    $content_inline_space: 64px;

    @supports (height: 100dvh) {
      height: 100dvh;
    }

    .product-details-tab {
      padding: 0;
    }

    .sizechart-tabs {
      display: flex;
      flex-direction: column;
    }

    .wrapper-tab-list {
      padding-top: 49px;
      padding-inline: $content_inline_space;
    }

    .wrapper-tab-panels {
      height: calc(100% - 94px);
    }

    .sizechart,
    .sizechart--measure {
      margin-inline: $content_inline_space;
    }

    #loaderContainer {
      background: white;
      height: 100%;

      img {
        height: 35px;
        filter: invert(0);
      }
    }
  }
}
