.loginsplit {
  justify-content: center;
  margin-bottom: $space-unit * 2;
  margin-right: 0;
  margin-left: 0;
  .loginsplit__member,
  .loginsplit__guest {
    height: 100%;
    form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
  }
  .paneleft {
    padding-right: $space-unit * 8;
    padding-left: 0;
  }
  .paneright {
    padding-right: 0;
    padding-left: $space-unit * 8;
  }
  &__title {
    @extend %primary-s-u;
    margin-bottom: $space-unit * 4;
  }
  &__subtitle {
    @extend %primary-xs;
    margin-bottom: $space-unit * 2;
  }
  .forgotpwd-section {
    margin-bottom: $space-unit * 8;
  }
}

.loginsplitsingle {
  .forgotpwd-section {
    margin-bottom: $space-unit * 8;
  }
  .notamember {
    margin-top: $space-unit * 4;
    text-align: center;
    &--link {
      display: inline;
      text-decoration: underline;
    }
  }
}
