.product-carousel {
  padding: ($space-unit * 8) 0 ($space-unit * 4);
  &.skeleton-loading[data-option-mobile-extra-small-tiles='true'] {
    .swiper-slide {
      max-width: calc(60% - 2.67px);
    }
  }
}

.product-carousel__carousel-container {
  .single-slide__labels {
    margin-bottom: $space-unit * 2;
  }
}

.product-carousel__text {
  text-align: left;
  margin-top: $space-unit * 8;
  margin-bottom: $space-unit * 6;
}
