.featured-products-carousel {
  &.product-carousel {
    .has-gutter {
      .swiper-slide {
        margin-right: ($space-unit * 8);
      }
    }
    .swiper-slide {
      max-width: calc(33.3333% - 21px);
    }

    .product-carousel {
      &__carousel-container {
        max-width: 114.6rem;
      }
    }
  }
}
