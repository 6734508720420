.hero__main-title {
  width: 78.8%;
}

.hero-column__title--bigger {
  font-size: 13.5rem;
  line-height: 10.4rem;
  // for countdown
  // b {
  //   width: 3.7rem;
  // }
}
// .countdown {
//   b {
//     width: 3.3rem;
//   }
// }
