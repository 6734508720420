.skeleton-carousel {
  .swiper-slide {
    width: calc(25% - 3px);
  }
}
.show-left-arrow .swiper-button-prev,
.show-right-arrow .swiper-button-next {
  .carousel-container__button__wrapper {
    transform: translateX(0);
  }
}
.products-carousel__arrows {
  gap: 2.4rem;
  position: absolute;
  top: -2.4rem;
  transform: translateY(-100%);
  right: 2.8rem;
}
