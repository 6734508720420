.visual-navigation {
  padding: 2.8rem 0;
  &__wrapper {
    display: flex;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    &::before,
    &::after {
      content: '';
      width: 2.8rem;
      min-width: 2.8rem;
    }
  }
  .aspect-ratio--2-3 {
    min-width: 23.9rem;
  }
  .visual-card {
    span {
      font-size: 2.4rem;
      line-height: 2.8rem;
    }
  }
}
