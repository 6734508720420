.component-two-column-promo {
  text-align: center;
  overflow: hidden;
  box-sizing: border-box;
  padding-top: 1.6rem;
  .two-column-promo {
    &-container {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;
      padding: 0;
    }
    &-item {
      text-align: center;
      padding: 0;
      width: 100%;
      position: relative;
    }

    &-card {
      display: block;
      position: relative;
      padding-bottom: 66.6666%;
      text-align: center;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }

    &-background-image {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-label {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      text-shadow: 0 0 8px rgba(0, 0, 0, 0.5), 0 0 2px rgba(0, 0, 0, 0.25);
      color: $white;
      width: 100%;
      padding: 0 2.75rem;
    }
    &-title {
      font-size: 2.4rem;
      line-height: 2.8rem;
      text-transform: uppercase;
      padding-bottom: 0.8rem;
    }
    &-description {
      font-size: 1.6rem;
      line-height: 2rem;
      padding-bottom: 2.6rem;
    }
    &-cta.button--arrow-right {
      font-size: 1.6rem;
      line-height: 2rem;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}
