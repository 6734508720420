// RADIO BUTTON SIZE SELECTOR

fieldset {
  margin: 0;
  &::before {
    content: none;
  }
  &.selector__menu--size {
    &--radio-btn {
      padding-top: 0;
      padding-left: 0;
      display: flex;
      flex-wrap: wrap;
      bottom: initial;
      min-width: 100%;
      row-gap: 2rem;
      column-gap: 2rem;
      padding-bottom: 1.2rem;
    }
  }
}
