.monclernow-listing {
  padding: 1.6rem 1.6rem;
  &__article {
    width: 100%;
  }
}

.article {
  &__info-wrapper {
    padding: 1.6rem 0 0;
  }
  &--two-images {
    .image-wrapper {
      &__img {
        width: 100%;
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }
  &--landscape,
  &--two-images {
    .image-wrapper__img {
      &::before {
        padding-top: 133% !important;
      }
    }
  }
}
