// shared general rules for components to extend

.component {
  margin-top: ($space-unit * 6);
}

.component__title {
  @extend %primary-l-u;
  text-align: center;
  margin: ($space-unit * 12) 0 ($space-unit * 6); // to do verificare
  word-wrap: break-word;
}
