// LAYOUT FOR DESKTOP
.discovery-layout {
  .text-region {
    padding: 12rem 0;
  }
  .discover-image-wrapper {
    &.double {
      &.container {
        gap: 1.6rem;
        &:not(.no-padding) {
          padding-right: 20vw;
          padding-left: 20vw;
        }
      }
    }

    &.quad {
      gap: 1.6rem;
      padding-right: 20vw;
      padding-left: 20vw;
    }
  }
}
