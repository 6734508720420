.component-text-squares {
  padding: 4rem 0;

  .section-text-squares {
    .text-square {
      &-content {
        border-left: 0;
      }
    }

    &.four-slides {
      .text-square-content {
        &::before {
          content: '';
          display: block;
          padding-top: 100%;
        }
      }
    }
    &.three-slides {
      .text-square-content {
        &::before {
          content: '';
          display: block;
          padding-top: 75%;
        }
      }
    }
  }

  .text-squares-carousel_arrow-container {
    display: none;
  }
}
