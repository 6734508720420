.modal {
  min-width: 920px;
  .dialog__description {
    width: 55%;
  }

  &.modal--small {
    @include modal-width(6);
  }

  &.subscribe-overlay,
  &.countdown-modal {
    // min-width: auto;
    min-width: 91.8rem;
    min-height: 66rem;
  }
  &.black {
    &.subscribe-overlay,
    &.countdown-modal {
      min-height: 63.7rem;
    }
    .product-notify__detail {
      .modal__header-sticky {
        padding-top: 2.7rem;
      }
      fieldset {
        margin-bottom: 3.6rem;
      }
    }
  }

  &__header-sticky {
    padding-top: 3.2rem;
  }

  &.size-modal {
    min-height: initial;
    min-width: initial;
    width: 92rem;
    // height: 80rem;
    overflow-y: hidden;
    padding-bottom: 0;
    .sizechart-tabs {
      .wrapper-container {
        overflow-y: scroll;
        height: 100vh;
      }
    }
  }
  &.measmerizemodal {
    .modal__body {
      .tabs {
        height: 77vh;
      }
    }
  }
  &--fixed-small {
    min-width: 458px;
    max-width: 458px;
    .dialog__description {
      width: auto;
    }
  }
  &.vto-modal {
    min-width: 920px;
    .modal__body {
      grid-template-columns: repeat(2, 1fr);
      > img {
        display: block;
      }
    }
  }
  &.nft-modal {
    .url-copy-box {
      .copied-label {
        position: absolute;
        right: 0;
        top: -0.2rem;
      }
    }
  }

  &.login-modal {
    max-width: 90rem;
    width: 100%;
    min-width: auto;
    overflow-y: hidden;
    .withimage {
      &__left {
        display: block;
        width: 50%;
        img {
          width: 100%;
        }
      }
    }
  }
  &.curator-modal {
    min-width: 91.7rem;
    .product-notify__detail {
      form {
        .newsletter-modal__gender-wrapper {
          margin-bottom: 3.2rem;

          fieldset {
            margin-bottom: 0;
            .form-group {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  &.privacy-confirm-modal {
    &,
    &.modal--small {
      min-width: unset;
    }
    width: 35.2rem + 6.4rem;
    padding: 0 3.2rem 3.6rem;
    .modal {
      &__body {
        gap: 1.6rem;
        margin-top: 0.8rem;
      }
      &__header {
        padding: 3.6rem 0 2.4rem;

        h2 {
          padding: 0;
        }
      }
      &__footer {
        margin-top: 0.8rem;
        flex-direction: row-reverse;
        gap: 0.8rem;
        button {
          flex: 1;
        }
      }
    }
  }

  &#notifyme {
    min-width: 91.7rem;
    &.notifyme-modal-class {
      height: 100%;
    }
  }
}

// .modal__close {
//   padding: 1.4rem;
// }

.country-modal {
  .card-header {
    line-height: 1.6rem;
    margin-bottom: ($space-unit * 2) + 2;
  }
  .col-md-6 {
    ul,
    ol {
      column-count: 2;
    }
  }
}

.palette-modal {
  &__image {
    width: 50%;
  }
  &__body {
    width: 50%;
    padding: 4rem 3.2rem;
    h3 {
      margin-bottom: 4rem;
    }
  }
}

.withimage {
  &__right {
    padding: 3.2rem;
    position: absolute;
    right: 0;
    width: 50%;
    height: 100%;
  }
  &__left {
    display: block;
    width: 50%;
  }
}

.modal-width {
  width: 50%;
  min-width: 50%;
}

.drawers {
  width: 50.5%;
}
