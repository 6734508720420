.wishlist {
  &__share-button {
    margin-top: 0;
    float: right;
  }
  &__single-product {
    width: calc(25% - $space-unit + ($space-unit / 4));

    &__img-holder {
      .product-image {
        &:before {
          padding-top: 150%;
        }
      }
    }
    .wishlist__single-product__product-info__container {
      height: var(--siblings-height);
    }
    .wishlist__single-product__share-btn {
      opacity: 0;
    }
    &:hover,
    &:focus-within {
      .wishlist__single-product__share-btn {
        opacity: 1;
      }
    }

    .swiper-container .swiper-pagination-bullets {
      --bullets-bottom-offset: 1.5rem;
    }
  }
}
