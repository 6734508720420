.specialservice-block {
  &__title {
    @extend %primary-m-u;
    text-align: center;
  }
  &__text {
    margin-top: 1.4rem;
  }
  &__ctarow {
    margin-top: 1.6rem;
  }
}
