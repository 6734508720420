.selector-quantity {
  .minus-button,
  .plus-button {
    @include touchtarget-area($target-size-mobile);
  }

  &__value {
    margin-right: $space-unit * 2;
    margin-left: $space-unit * 2;
  }

  &__msg {
    margin-left: 0;
  }
}
