.checkoutpage {
  .wrapper-tab-list-checkout {
    .react-tabs__tab-list {
      justify-content: center;
      gap: 3.2rem;
      .react-tabs__tab {
        flex: initial;
      }
    }
  }
  .checkout-login {
    margin-top: calc(9.6rem - 3.2rem);
  }
  .login-tab {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
  }
  .wrapper-tab-panels-checkout {
    padding-top: 6rem;
  }
  .paymentmethods {
    .radio__icon {
      &.devmode {
        padding: 1.6rem 0 1.6rem 4rem;
      }
    }
  }
  .radio--outline {
    input[type='radio'] {
      z-index: 2;
    }
  }
  .radio.radio--outline {
    .inner-label {
      padding: 0;
      .radio__icon {
        span {
          padding: 1.6rem;
          padding-left: 4rem;
        }
        label {
          padding: 1.6rem;
          padding-left: 4rem;
          width: 100%;
        }
        & > [class^='icon--payment'] {
          pointer-events: none;
          position: absolute;
          right: 1.6rem;
        }
        .paymentmethods-cards {
          position: absolute;
          right: 1.6rem;
          & > [class^='icon--payment'] {
            pointer-events: none;
          }
        }
      }
    }
  }
}
.overlay-mobile-order-summary {
  display: none;
}
.checkout-login-button {
  margin-top: 1.8rem;
  .button {
    min-height: 3.6rem;
  }
}
