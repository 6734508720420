.page-template-technicaldetails {
  main {
    padding-top: $contextual-navigation + $header-height-mobile;
  }
  section {
    $viewportHeight: calc(var(--viewport-height) - #{$contextual-navigation});
    min-height: $viewportHeight;
  }
  .contextual-nav {
    #shop-now-button {
      padding: 0.5rem 1.5rem;
    }
    > .button:not(:last-child) {
      margin-right: 2.4rem;
    }
  }
  .custom-anchor {
    top: -#{$contextual-navigation};
    height: $contextual-navigation;
  }
  .technical-details__wrapper {
    .section-wrapper:last-child {
      section:not(.component_complete_the_look) {
        max-height: calc(var(--viewport-height) - #{$contextual-navigation});
      }
    }
  }
}
body.has-ticker {
  &.page-template-technicaldetails {
    main {
      padding-top: $contextual-navigation + $header-height-mobile + $ticker-bar-m;
    }
  }
}
