.carousel-slide__text-holder {
  font-size: 1.8rem;
  line-height: 2.2rem;
}
.editorial-carousel {
  padding: ($space-unit * 8) 0 ($space-unit * 20);
}

.editorial-carousel {
  &__swiper-container {
    padding: 0 4rem 0 0;
  }
  &__carousel-slide {
    &.swiper-slide-active,
    &.swiper-slide-next {
      margin-right: ($space-unit * 4) !important;
    }
  }
  .carousel-slide__inner-slide {
    &.aspect-ratio--16-9 {
      &::before {
        padding-top: 56.25%;
      }
    }
    &.aspect-ratio--3-2 {
      &::before {
        padding-top: 66.66%;
      }
    }
    &.aspect-ratio--4-3 {
      &::before {
        padding-top: 75%;
      }
    }
  }
  .editorial-carousel__carousel-wrapper {
    &.aspect-ratio--16-9 {
      &::before {
        padding-top: 56.25%;
      }
    }
  }
  .swiper-slide--mobile-gab {
    display: none;
  }
  .editorial-carousel__carousel-slide {
    &:not(.carousel-slide--text-slide) {
      width: auto;
    }
  }
}
