.monclercurators-hero {
  position: relative;
  padding-bottom: 1px;
  .monclercurators-hero-container {
    margin: 0 auto;
  }
  .d-none-desk {
    display: block;
  }
  .d-none-mobile {
    display: none;
  }
  .monclercurators-hero__logo {
    padding: 6.4rem 0 4rem;
    display: flex;
    justify-content: center;
    i {
      height: 2.4rem;
      width: 22rem;
    }
  }
  .monclercurators-hero__intro {
    padding: 0 1.6rem;
    color: $white;
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: 8rem;
  }
  .monclercurators__img {
    img {
      width: 100%;
    }
  }
  .monclercurators__container_down {
    .monclercurators__text {
      position: absolute;
      text-align: center;
      bottom: 4rem;
      width: 100%;
      right: 50%;
      display: flex;
      flex-direction: column;
      row-gap: 4rem;
      transform: translate(50%, 0);
      color: white;
      .monclercurators__title {
        font-size: 42px;
        line-height: 50px;
        text-transform: uppercase;
        letter-spacing: 0.04em;
      }
      .monclercurators__message {
        font-family: $silkSerifLight;
        font-style: normal;
        font-size: 150px;
        line-height: 128px;
        text-transform: uppercase;
        padding: 0 4rem;
      }
    }
  }
}
