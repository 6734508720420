.curator-promo {
  position: relative;
  overflow: hidden;
  width: 100%;
  &::after {
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    position: absolute;
    z-index: 3;
  }
  &__inner-container {
    height: 100%;
    filter: grayscale(1);
    > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__logo {
    width: 18rem;
    height: 2rem;
    display: inline-block;
    margin-bottom: 2rem;
  }
  &__title {
    font-family: $silkSerifLight;
    font-size: 6rem;
    line-height: 5.6rem;
    margin-bottom: 3.2rem;
  }
  &__text-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 4;
    text-align: center;
    // width: 100%;
    .button {
      width: 20rem;
    }
  }
}
