@charset 'utf-8';

@import 'base/reset';
@import 'base/fonts';
@import 'helpers'; // utility sass che non compilano css (es. var e mixin)
@import 'base/icons'; // genera le classi per le icone
@import 'base/typography-classes';
@import 'base/aspect-ratio';
@import 'vendors/aos';
@import 'base/utils';
@import 'base/grid'; // sistema griglia tipo bootstrap
@import 'base/gridoverlay'; // grid overlay (Ctrl + G)
@import 'base/debug'; // classi per debug
@import 'base/print'; // mini gestione stampa

@import '@adyen/adyen-web/dist/adyen.css'; // imported here because importing from react component breaks SSR

// @import 'mq-layouts';
// @import 'mq-components';
@import 'mq-old';

@import 'base/app';

.framer-test {
  height: 200px;
  width: 200px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: aqua;
}

@keyframes moveUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

#onetrust-consent-sdk #onetrust-banner-sdk.otFlat {
  bottom: 0 !important;
  transform: translateY(100%);
  animation: moveUp 0.3s linear forwards;
}
