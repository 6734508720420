.mbm-entrypoint {
  .container {
    top: 5rem;
  }
  &::after {
    background-position-x: 4%;
  }
  .big-image {
    max-height: 60rem;
  }
}
