// SIZE SELECTOR USED IN: cart

.selector-size-inline {
  width: 100%;
  .selector__menu {
    .label {
      margin-right: $space-unit * 2;
    }
    ul {
      grid-template-columns: repeat(5, 1fr);
      row-gap: 0.8rem;
      padding-bottom: 0.8rem;
      column-gap: 1.2rem;
    }
    &__item {
      margin-right: 0;
    }
  }
  .selector__button {
    &::after {
      content: '';
      right: 0;
      @include fixedSprite('chevron-right');
      position: absolute;
      top: -0.1rem;
      transform: rotate(90deg);
    }
    &.selector__button__mobile {
      display: flex;
      flex-wrap: wrap;
      .selector__button__info {
        order: 4;
        white-space: nowrap;
        margin: 0.5rem 1.2rem 0.5rem 0;
      }
      &::after {
        position: unset;
        width: 3.2rem;
      }
    }
  }
}
