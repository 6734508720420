.drawers {
  .product-details {
    padding-right: $space-unit * 0.5;

    &-packaging {
      &-image {
        img {
          height: 20rem;
        }
      }
    }
  }
}
