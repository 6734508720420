.preferences-marketing {
  .radio-wrapper {
    .form-group--cols {
      column-count: 4;
    }
  }
}

.preferences-privacysettings_rpy {
  //max-width: 50%;
}
