.bookanappointment {
  .scrollable {
    padding-right: $space-unit * 8;
    margin-right: -($space-unit * 8);
  }
  &__right {
    padding: $space-unit * 8;
    padding-bottom: 0;
  }

  .bookanappointment__right {
    .product-reserve__detail__selected-store {
      .option-store__live-boutique {
        .live-boutique-info {
          .icon--info {
            & + .tooltip__box {
              position: fixed;
              transform: translate(-9%, -115%);
              left: auto;
              right: auto;
              bottom: auto;
              text-transform: initial;
            }
          }
        }
      }
    }
  }
  &.modal {
    min-height: 80vh;
    max-height: 80vh;
    min-width: 90vw;

    &.modal--live-boutique {
      max-height: 66rem;
      min-height: 66rem;
      min-width: 91.6rem;
    }
  }
}
