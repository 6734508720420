.hero-carousel {
  &__slider {
    width: 100%;
    transition: background-color 0.2s ease-in 0.2s;
    background-color: $black;
    // display: block;
    &-content {
      &.swiper-wrapper {
        .swiper-slide {
          &:not(.swiper-slide-active) {
            visibility: hidden;
          }
          &.swiper-slide-active {
            visibility: visible;
          }
        }
      }
    }
  }

  .swiper-pagination--bars {
    position: absolute;
    bottom: ($space-unit * 10);
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    /* width: calc(100% - 16rem); */
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 6rem;
      width: 100%;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
    }
    .swiper-pagination-bullet {
      background: rgba(255, 255, 255, 0.25);
      z-index: 2;
      transition: initial;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 0;
        height: 100%;
        z-index: 1;
        background: $white;
      }

      &.swiper-pagination-bullet-active {
        background: rgba(255, 255, 255, 0.25) !important;
      }
    }
  }

  .swiper-slide-active {
    transition-delay: 400ms;
    position: relative;
    z-index: 9;
    &.swiper-slide--custom-active {
      .hero-column__headline,
      .hero-column__cta {
        transition: transform 0.4s 0.4s $pseudoOutQuart;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    @include fixedSprite('arrow-right-white');
    position: absolute;
    top: 50%;
    // for default ovverride
    margin-top: initial;
    width: 2.4rem;
    height: 2.4rem;
    z-index: 2;
    outline-color: $white;
    &:focus-visible {
      background-color: $black;
    }
  }

  .swiper-button-next {
    right: 0.8rem;
    transform: translateY(-50%);
  }
  .swiper-button-prev {
    left: 0.8rem;
    transform: translateY(-50%) rotate(180deg);
  }
  .swiper-container.initialized.resizing {
    .swiper-slide {
      transition: initial !important;
    }
  }

  .swiper-container {
    &:not(.initialized) {
      .swiper-slide {
        .hero-column__headline,
        .hero-column__cta {
          transform: translate3d(0, 0, 0);
          transition: initial;
        }
      }
    }
    &.initialized {
      .swiper-slide {
        transition-property: all;
        transition-timing-function: linear;
        transition-duration: 400ms !important;
      }
      .swiper-pagination--bars .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
        width: 100%;
      }
    }
    &.swiper-autoplay {
      .swiper-pagination--bars .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
        animation-timing-function: linear;
        animation-name: bullet-loading;
        animation-duration: 4.5s;
      }
    }
  }

  .btn--pause {
    position: absolute;
    right: 3.2rem;
    bottom: 3.2rem;
    z-index: 2;
    &:focus-visible {
      outline-color: $white;
      background-color: $black;
    }
  }
}

// To center img in curator carousel modal
.curator-carousel-fullHeight {
  height: 100%;
}
/* The animation code */
@keyframes bullet-loading {
  from {
    width: 0;
  }
  15% {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.hero-carousel__main-wrapper {
  position: relative;
  .hero-carousel__content-carousel-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: visible;
    display: flex;
    align-items: flex-end;

    .hero-carousel__content-carousel {
      position: sticky;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      max-height: 100%;
      .has-ticker & {
        height: calc(100vh - #{$ticker-bar});
      }
      &.hero--left-top {
        .swiper-slide {
          padding: 8rem;
          align-items: flex-start;
          justify-content: flex-start;
        }
      }
      &.hero--left-bottom {
        .swiper-slide {
          padding: 8rem;
          align-items: flex-end;
          justify-content: flex-start;
        }
      }
      &.hero--center-bottom {
        .swiper-slide {
          align-items: flex-end;
        }
      }
      &.hero--center-top {
        .swiper-slide {
          align-items: flex-start;
          justify-content: center;
        }
      }
      &.hero--centered {
        .swiper-slide {
          align-items: center;
        }
      }
      &.hero--center-left {
        .swiper-slide {
          padding: 8rem;
          align-items: center;
          justify-content: flex-start;
        }
      }
      &.hero--center-right {
        .swiper-slide {
          padding: 8rem;
          align-items: center;
          justify-content: flex-end;
        }
      }
      &.hero--right-top {
        .swiper-slide {
          padding: 8rem;
          align-items: flex-start;
          justify-content: flex-end;
        }
      }
      &.hero--right-bottom {
        .swiper-slide {
          padding: 8rem;
          align-items: flex-end;
          justify-content: flex-end;
        }
      }
      .hero-column__content-container {
        text-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.2);
      }
      .swiper-slide {
        display: flex;

        justify-content: center;
        padding: 8rem 0;
        .hero-column__cta {
          justify-content: center;
          &.text-black {
            span {
              background-color: $black;
              color: $white;
            }
            .button {
              background-color: $black;
              color: $white;
            }

            .button--arrow-right {
              &::after {
                @include fixedSprite('arrow-right-black');
              }
            }
          }
        }
        // @TODO: check style

        // .hero--center-bottom {
        //   .hero-column__inner-container {
        //     padding-bottom: 9rem;
        //   }
        // };
        // .hero-column__headline,
        // .hero-column__cta {
        //   transform: translate3d(-2.35vw, 0, 0);
        //   transition: transform 0.4s $pseudoInQuart;
        // }
      }
    }
  }
}
@media screen and (max-width: 667px) {
  // Only for full-height: when parent has fixed aspect-ratio, safari can't handle properly the height 100%
  .hero-carousel.hero--hero-with-columns.full-height .hero-carousel__main-wrapper {
    height: 100%;
  }
}

.hero-carousel:not(.img-loaded):not(.video-loaded) {
  .hero-carousel__slider {
    background-color: transparent;
  }
  .hero-column__img,
  .swiper-slide {
    &::after {
      // Too much nesting for properly overriding
      opacity: 0 !important;
    }
  }
}
