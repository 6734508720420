.myaccount-nft {
  padding-bottom: 40px;

  .breadcrumb {
    padding-top: 0;
  }
  .nft-card {
    background-color: $black;
    margin-bottom: 16px;
    color: $white;
    padding: 1.6rem 1.6rem 4rem 1.6rem;
    &__image {
      margin-bottom: 2rem;
    }
    &__name {
      text-align: left;
    }
    &__author {
      text-align: left;
    }
  }

  .certificate-detail {
    &__right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    margin-left: auto;
    margin-right: auto;
    &__backTitle {
      text-align: center;
      margin-top: 80px;
      margin-bottom: 40px;
    }
    &__name {
      margin-left: auto;
      margin-right: auto;
      font-size: 2rem;
      line-height: 2.4rem;
    }
    &__author {
      color: $black_secondary;
    }
    &__artist {
      text-transform: uppercase;
    }
    &__ctas {
      border-top: 1px solid $grey_line;
      ul {
        list-style: none;
        padding: 0;
        li {
          border-bottom: 1px solid $grey_line;
          .button {
            width: 100%;
            text-align: left;
            display: block;
            text-transform: initial;
            padding: 1.2rem 0;
          }
        }
      }
    }
  }
}
