.search-container {
  .no-results-container {
    .collection-submenu {
      gap: initial;
      padding: 0 2rem;
      .collection-submenu__item {
        flex-basis: initial;
      }
    }
    .container {
      .swiper-initialized {
        padding: 0 2rem;
        .swiper-slide {
          max-width: unset;
        }
      }
    }
  }
}
