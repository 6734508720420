html {
  // scroll-behavior: smooth;
  /* evita gli spostamenti quando appare il menu che fa sparire la scrollbar */
  // disattivo temporaneamente per testare il fix sull'ovcerflow hidden sul body
  // overflow-y: scroll;
  font-size: 62.5%;
  --main-section-height: calc(var(--viewport-height) - var(--header-height));

  // height: 100%; // Rompe il footer
  &.initial-scroll {
    scroll-behavior: initial;
  }
  &.smooth-scroll {
    scroll-behavior: smooth;
  }
  &.no-overflow-ios {
    &,
    body {
      overflow: hidden;
      height: 100%;
    }
  }
  &.mbm-html {
    height: 100%;
    body {
      height: 100%;
      min-height: initial;
      overflow: hidden;
    }
  }
  &.fixed-footer {
    #maincontent {
      margin-bottom: var(--footer-margin);
    }
    .footer {
      position: fixed;
    }
  }
}

body {
  @extend %primary-xs;
  color: $black;
  background-color: $white;
  position: relative;
  min-height: 100vh;
  //height: 100%; Rompeva footer
  height: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // fallback
  --scrollbar-width: 0px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $overlay;
    opacity: 0;
    pointer-events: none;
    @include z(overlay);
    // @include animate(0.15);
    @include animate(0.3, opacity);
  }

  &.ReactModal__Body--open,
  &.remove-live-chat-icon,
  &.overlay {
    #spr-live-chat-app {
      display: none;
    }
  }

  &.overlay {
    &::before {
      opacity: 1;
      pointer-events: initial;
    }
  }

  &.no-overflow,
  &.ReactModal__Body--open {
    overflow: hidden;
    header,
    .footer:not(.checkout),
    #maincontent,
    .sticky-tray,
    .cookie-bar,
    .contextual-nav {
      padding-right: var(--scrollbar-width);
    }
  }

  &.hide-scroll {
    overflow: hidden;
  }

  &.page-template-ai_plp {
    main {
      padding-top: 0;
    }
  }
  &.page-scrolled {
    header {
      transform: translateY(-100%);
      .logo-header {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}

#top-page {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.1rem;
}

main {
  // min-height: var(--main-section-height);
  // @include gray-gradient;
  background: #fff;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  position: relative;
  @include border('bottom');

  &.no-padding {
    padding-top: 0;
  }

  &.main--autoheight {
    min-height: calc(100vh - #{$footer-clientservice-height-desktop} - #{$header-height-desktop});
    padding-bottom: $space-unit * 9;
  }

  &.main--checkout {
    min-height: calc(100vh - #{$footer-clientservice-height-desktop} - #{$header-height-desktop});
    padding-bottom: $space-unit * 9;
    padding-top: 3.2rem;
    background: initial;
    .checkout-container {
      min-height: 20rem;
    }
  }
}

img {
  max-width: 100%;
  margin: 0;
  padding: 0;
  display: block;
  height: auto;
}

ul,
ol {
  padding-top: $space-unit * 3;
  padding-bottom: $space-unit * 3;
  list-style-position: outside;
  list-style-type: square;
  padding-left: 1.3rem;

  li {
    padding: $space-unit 0;
  }
}

a,
a:visited {
  color: inherit;
}

button,
[role='button'],
[role='option'] {
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  font: inherit;
  margin: 0;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

input[type='search']::-webkit-search-cancel-button {
  /* Remove default cancel button */
  -webkit-appearance: none;
}

:root {
  --viewport-height: 100vh;
}

b {
  font-weight: bold;
}

em {
  font-style: italic;
}
