.modal {
  padding: 0 ($space-unit * 8) ($space-unit * 8);
  width: auto;
  min-width: 70%;
  height: auto;
  max-height: 90vh;
  max-width: 90vw;

  &.modal--small {
    @include modal-width(8);
  }
  &.confirm-changepassword {
    .modal__body {
      > p {
        padding-top: 0;
        padding-bottom: 2.4rem;
      }
    }
  }

  &--fixed-small {
    min-width: 458px;
    max-width: 458px;
  }

  &.continue-to-store {
    .image-share-circle {
      width: 38rem;
      height: 38rem;
    }
  }

  &.vto-modal {
    .vto-modal__detail {
      padding: 3.2rem;
    }
  }
  &.measmerizemodal .accordion__group {
    margin-top: 1.6rem;
  }
  &.black {
    .product-notify__detail {
      fieldset {
        margin-bottom: 5.6rem;
      }
    }
    &.subscribe-overlay,
    &.countdown-modal {
      min-height: 65.8rem;
    }
  }

  &.privacy-confirm-modal {
    min-width: unset;
    width: 34.3rem + 3.2rem;
    padding: 0 1.6rem 3.2rem;
    .modal {
      &__body {
        padding: 2.4rem 0 0;
      }

      &__header {
        padding: 1.8rem 0;

        h2 {
          padding: 0;
        }
      }
    }
  }
}

.palette-modal {
  &__body {
    padding: 3.6rem 3.2rem;
    h2 {
      margin-top: 4rem;
    }
    h3 {
      margin-bottom: 3.2rem;
    }
  }
}
.ReactModal__Content {
  &.drawers {
    right: 0;
    transform: translateX(100%);
    transition: all 0.75s 0s cubic-bezier(0.25, 1, 0.5, 1);
    &.ReactModal__Content--after-open {
      right: 0;
      opacity: 1;
      visibility: visible;
      pointer-events: initial;
      transform: translateX(0);
      transition-property: transform;
    }
    &.ReactModal__Content--before-close {
      right: 0;
      visibility: hidden;
      transform: translateX(100%);
      transition: all 0.75s 0s cubic-bezier(0.25, 1, 0.5, 1);
    }
  }
}
