.product-tile {
  $product-tile-info-padding-bottom: $space-unit * 8;
  &__info {
    padding: ($space-unit * 4) 0 $product-tile-info-padding-bottom;
  }
  &__quick-buy {
    .button {
      height: ($space-unit * 6);
      background-color: transparent;
    }
  }
  &.firstcol {
    .selector-color-link {
      padding-left: 2.8rem;
    }
  }
  &.product-tile--simplified {
    .swiper-pagination-horizontal {
      opacity: 0;
      transition: opacity 166ms $easeOutQuart;
    }
    &.product-tile__with-carousel .product-tile--carousel {
      .product-tile-carousel__button.swiper-button-disabled {
        opacity: 0;
      }
      &:hover {
        .product-tile-carousel__button.swiper-button-disabled {
          opacity: 0.3;
        }
      }
    }
    &:hover {
      .swiper-pagination-horizontal {
        opacity: 1;
      }
      .product-tile-carousel__button.swiper-button-disabled {
        opacity: 0.3;
      }
    }
  }
  &.product-tile--simplified {
    .product-tile__info {
      padding-left: 0.8rem;
      padding-right: 0.8rem;
    }
  }
}
